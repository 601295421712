import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    NumberField, useTranslate, useLocale,
    ReferenceField, AutocompleteInput, ReferenceInput,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp} from "../../_common/CompReact";

// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
// import {validateReq} from "../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
// import {useForm} from "react-final-form";
//import get from 'lodash/get';
import CroppedLongField from "../../_common/CroppedLongField";
import {validateReq} from "../../_constants/validateTable";

/*const transform = (data) => {
    data.article = data.article2;
    delete data.article2;
    data.index = data.index2;
    delete data.index2;
    data.variant_color = data.variant_color2;
    delete data.variant_color2;
    data.source = data.source2;
    delete data.source2;


    return data;
}*/
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {related_id, size_chart} = props;
    const translate = useTranslate();
    // const locale = useLocale();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    product: related_id,
                    source: localStorage.getItem('username'),
                }}
                // transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <ReferenceInput
                    label={translate('Size')}
                    source={'size_specification'}
                    reference={'dSizeSpecification'}
                    filter={{size_chart: size_chart}}
                    validate={validateReq}
                    link={false}
                >
                    <AutocompleteInput optionText={`value`} resettable
                                       fullWidth/>
                </ReferenceInput>

            </RowForm>
        </>
    )
};
const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        size_specification:
            <ReferenceField
                label={translate('Size')}
                source={'size_specification'}
                reference={'dSizeSpecification'}
                link={false}
            >
                <TextField source={`value`}/>
            </ReferenceField>,

        source: /*<TextField source="source" label={translate('Source')}/>,*/
            <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
            /*cellClassName={classes.date_st}*/ showTime={true}/>,

    }
    return columns;
};

const SizeProduct = props => {
    const locale = useLocale();
    const {related_id, record} = props;
    const {size_chart} = record || {};
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'SizeProduct'}list.columns`,
        columns: ColumnsForList('SizeProduct', classes, locale),
        omit: ["nb_views"],
    });

    console.log('size_chart========>', size_chart)
    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Size Products"
                reference="dSizeProduct"
                target="product"

            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                             size_chart={size_chart} related_id={related_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            size_chart={size_chart} related_id={related_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default SizeProduct;