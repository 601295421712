import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    useTranslate, useLocale,
    TextInput,
} from 'react-admin';


import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
// import {validateReq} from "../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    data.rgb_code = data.rgb_code2;
    delete data.rgb_code2;

    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {related_id, record} = props;
    const translate = useTranslate();
    // const locale = useLocale();

    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    color: related_id,
                    source2: localStorage.getItem('username'),
                    // name2: record.name,
                    rgb_code2: record.rgb_code
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <MyTranslatableInputs
                        //prompt={promptData.find(x => x.field === 'name')}
                        fullWidth
                        source={'name'}
                        label={translate('Name')}
                    />
                <MyTranslatableInputs
                        //prompt={promptData.find(x => x.field === 'name')}
                        fullWidth
                        source={'url'}
                        label={translate('Url')}
                    />
                <TextInput
                        variant={"outlined"}
                        source={'rgb_code2'}
                        label={translate('Rgb Code')}
                        fullWidth
                    />
                <TextInput
                        variant={"outlined"}
                        source={'item_code_in_accounting_system'}
                        label={translate('Code in accounting system')}
                        fullWidth
                    />

            </RowForm>
        </>
    )
};

const VariantsColors = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {related_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="VariantsColors"
                reference="dVariantsColors"
                target="color"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable

                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              related_id={related_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            related_id={related_id}/>}
                    //rowStyle={postRowStyle}
                >

                    <TextField source={`name[${locale}]`} label={translate('Name')}/>
                    <TextField source={`url[${locale}]`} label={translate('Url')}/>
                    <TextField source={`rgb_code`} label={translate('Rgb Code')}/>
                    <TextField source={`item_code_in_accounting_system`} label={translate('Item code in accounting system')}/>

                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default VariantsColors;