//import moment from "moment";
import get from "lodash/get";
import {useRecordContext} from "react-admin";


export const inputHrefToString = (value, bk = false) => {
    let valueOri = value;
    /*let arHref = [];
    let valueMod = value.replace(/\s+|[[:^print:]]/gm, " "); //Убирает непечатные символы
    let arValue = valueMod.split(' ');
    arValue.map((it) => {
        if (it.indexOf('http') === 0 && arHref.indexOf(it)<0)
        {
            arHref.push(it);
            valueOri = valueOri.replaceAll(it, '<a target="_blank" style="color: #6870FF" href="' + it + '"  rel="noreferrer">' + it + '</a> ');
        }
    });
    if(bk) valueOri = valueOri.replaceAll("\n", '<br/>');*/

    /*let res = [];
    let test = str.replace(/((?:https?:\/\/|ftps?:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,})|(\n+|(?:(?!(?:https?:\/\/|ftp:\/\/|\bwww\.)(?:(?![.,?!;:()]*(?:\s|$))[^\s]){2,}).)+)/gim, (m, link, text) => {
        res.push(link ? <a href={(link[0] === "w" ? "//" : "") + link} key={res.length}>{link}</a> : text)
    });
    console.log('====>', res);*/
    if (valueOri) {
        valueOri = valueOri.replace(/\b(http?\S+|[[:^print:]])/mg, '<a target="_blank"  href="$1">$1</a>');
        if (bk) valueOri = valueOri.replaceAll("\n", '<br/>');
    } else valueOri = '';
    return valueOri;
};

export const formatSpacerNumber = (str, delim = ',', fix = 2) => {//\u00a0
    if (str) {
        let st = parseFloat(str).toFixed(fix)
        if (st >= 1000) return st.toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + delim);
        else return st;
    } else return '';
};

export const FloatNumber = (props) => {
    const {source, fix = 2, delim = ','} = props;
    const record = useRecordContext(props);
    let par = get(record, source, '');
    if (par) par = formatSpacerNumber(par, delim, fix);
    return (
        <span>{par ? par : null}</span>
    );
}

export const GetString = (props) => {
    const {source, title, titleText = false} = props;
    const record = useRecordContext(props);
    let src = get(record, source, '---');
    let tit = get(record, title, '---');
    return (
        <span title={titleText ? titleText : tit}>{src}</span>
    );
}

export const formatSpacerNumber2 = (str, delim = ',') => {//delim = '\u00a0'
    let val = str >= 1000
        ? str.toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1' + delim)
        : str;
    val = '$\u00a0' + val;
    return val;

};

export const inputFormatStr = (str, type, fixTo = 2) => {
    // console.log('str, type===>', str, type);
    let result = '';
    switch (type) {
        case 'del_space_all':
            result = str.replaceAll(' ', '');
            break;
        case 'del_space':
            result = str.replace(/^\s+/, '');
            break;
        case 'float':
            //.replace(/^0+/, ''); --- убивает нули в начале
            //.replace(/\b(0(?!\b))+/g, "") --- убивает нули в начале кроме одного ???
            //.replace(/\.(?=.*\.)/ig, '')--- убивает двойные точки
            if (str === '0') {
                result = '0';
            } else {
                result = str.replace(/[^\d,.]/ig, '').replace(',', '.').replace(/\.(?=.*\.)/ig, '').replace(/^0+/, '');
                if (result[0] === '.') result = '0' + result;
                if (result.indexOf('.') < 0) result = result.substring(0, result.length);
                else result = result.substring(0, result.indexOf('.') + (fixTo + 1));
                if (result.length > 13) {
                    let ttt = result.split('.');
                    ttt[0] = ttt[0].substr(0, 13);
                    if (ttt[1] !== undefined) result = ttt.join('.');
                    else result = ttt[0];
                }
            }
            break;
        case 'int':
            result = str.replace(/[^\d]/ig, '');
            if (result !== '') {
                result = String(parseInt(result.substr(0, 12)));
            }
            break;
        case 'AZ':
            result = str.replace(/[^\sA-Za-z]/ig, '').replace(/^\s+/, '').toUpperCase();
            break;
        case 'az_09':
            result = str.replace(/[^\dA-Za-z]/ig, '');
            break;
        case 'phone': //qiwi
            result = str.replace(/[^\d+]/ig, '').substring(0, 16);
            break;
        /*
                case 'webmoney': //webmoney
                    result = str.replace(/[^\w]/ig, '').substring(0, 16).toUpperCase();
                    break;
                case 'yandex': //yandex
                    result = str.replace(/[^\w]/ig, '').substring(0, 16).toUpperCase();
                    break;
                case 'mc': //mc
                    result = str.replace(/[^\d+]/ig, '').substring(0, 16);
                    break;
        */
        default :
            result = str;
            break;
    }

    return result;
};

export const getPromptForTable = (dataPrompts, resource) => {
    let dt = [];
    let dtNoTable = [];
    Object.values(dataPrompts).forEach(it => {
        let dt2 = get(it, 'config.fields', null)
        if (dt2) {
            //console.log('dt2=======>', dt2);
            dt2.forEach(it2 => {
                if (('d' + it2.table) === resource) {
                    dt.push({
                        response_example: it?.response_example,
                        prompt: it.prompt,
                        field: it2.field,
                        info_fields: it2.info_fields,
                    })
                } else if (!it2.table || it2.table === '') {
                    dtNoTable.push({
                        response_example: it?.response_example,
                        prompt: it.prompt,
                        field: it2.field,
                        info_fields: it2.info_fields,
                    })
                }
            })
        }
    })
    dt = dt.concat(dtNoTable)
    return dt
};

