import * as React from 'react';
/*import {
    useRedirect,
} from 'react-admin';*/

import EditComponentAdmin from "../../_common/EditComponentAdmin";
import CustomersEditCreate from "./CustomersEditCreate";
import {useRedirect} from "react-admin";
import {localesConfig} from "../../utils/config";

export function transformCustomers(data) {
    //console.log('transformCustomers============>', data)
    localesConfig.locales.forEach(it => {
        if (data?.official_name)
            data.official_name[it] = data.official_name[it] ? data.official_name[it] : ''
        if (data?.delivery_address)
            data.delivery_address[it] = data?.delivery_address[it] ? data.delivery_address[it] : ''
        if (data?.billing_address)
            data.billing_address[it] = data.billing_address[it] ? data.billing_address[it] : ''
        if (data?.address)
            data.address[it] = data.address[it] ? data.address[it] : ''
    })
    return data
}

export const CustomersEdit = props => {
    const {location} = props;
    const {search} = location || {};
    let idSource = '';
    if (search) {
        idSource = search.split('idSource=')[1] ? search.split('=')[1] : '';
    }
    const redirect = useRedirect()

    return (
        <EditComponentAdmin
            {...props}
            transform={(event) => transformCustomers(event)}
            title={''}
            //editDialog={idSource}
            onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            /*onSuccess={() => idSource
                ? redirect(`/locating/${idSource}/2`)
                : redirect('list', props.basePath)
            }*/
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <CustomersEditCreate idSource={idSource}/>
        </EditComponentAdmin>
    )
};