//import * as React from 'react';
import {Card as MuiCard} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';

const CardCompWidth = withStyles(theme => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            order: -1,
            width: '18em',
            minWidth: '18em',
            marginRight: '1em',
        },
        [theme.breakpoints.down('sm')]: {
            order: -1,
            width: 200,
            // marginRight: '1em',
            // marginTop: '3em',
            display: 'none',
            position: 'fixed',
            top: 48,
            right: 8,
            zIndex: 4,
            backgroundColor: '#fefefefe',
        },
    },
}))(MuiCard);

export default CardCompWidth;
