import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import { goBack } from 'react-router-redux';

class BackButton extends Component {
    handleClick = () => {
        this.props.goBack();
    };

    render() {
        // return <Button variant="contained" color="primary" onClick={this.handleClick}>Go Back</Button>;
        return <Button variant="contained" onClick={this.handleClick}>{this.props?.name}</Button>;
    }
}
export class Reload extends Component {
    handleClick = () => {
        localStorage.removeItem('fdsBookingForm');
        window.location.reload();
    };

    render() {
        // return <Button variant="contained" color="primary" onClick={this.handleClick}>Go Back</Button>;
        return <Button variant="contained" onClick={this.handleClick}>Reload</Button>;
    }
}

export default connect(null, {
    goBack,
})(BackButton);