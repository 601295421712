import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    NumberField, useTranslate, useLocale,
    Datagrid,
} from 'react-admin';

import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp} from "../../_common/CompReact";

import {useSelectedColumns} from "@react-admin/ra-preferences";
import PostPagination from "../../_common/PostPagination";
import CroppedLongField from "../../_common/CroppedLongField";

const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        products: <TextField source={`name[${locale}]`} label={translate('Name')}/>,

        source: /*<TextField source="source" label={translate('Source')}/>,*/
            <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
            /*cellClassName={classes.date_st}*/ showTime={true}/>,

    }
    return columns;
};

const ProductsForCategory = props => {
    const locale = useLocale();
    const classes = useMyStyles();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'ProductsForCategory'}productslist.columns`,
        columns: ColumnsForList('ProductsForCategory', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Products"
                reference="dProducts"
                target="product_group"

            >
                <Datagrid
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                >
                    {columns}
                </Datagrid>
            </ReferenceManyField>

        </div>
    )
}
export default ProductsForCategory;