import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    useTranslate, useLocale,
} from 'react-admin';

import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {DateFieldComp, DateInputComp} from "../../_common/CompReact";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {country_id} = props;
    const translate = useTranslate();

    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    country: country_id,
                    source2: localStorage.getItem('username'),
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <DateInputComp
                    source={"holiday"}
                    //time={true}
                    label={translate("Holiday")} validate={validateReq}
                    //defaultValue={moment(new Date()).format()}
                />

            </RowForm>
        </>
    )
};

const Holiday = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {country_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Holiday"
                reference="dHoliday"
                target="country"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              country_id={country_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            country_id={country_id}/>}
                    //rowStyle={postRowStyle}
                >
                    <TextField source='holiday' label={translate('Holiday')}/>
                    <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default Holiday;