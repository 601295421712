import * as React from 'react';
import {
    ReferenceManyField,
    useTranslate, useLocale, SelectInput, TextInput, TextField
} from 'react-admin';

import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
//import {validateReq} from "../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {choicesWeekday} from "../../_constants/choices";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {branch_id} = props;
    const translate = useTranslate();

    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    branch: branch_id,
                    source2: localStorage.getItem('username'),
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <SelectInput source={'weekday'} choices={choicesWeekday} resettable
                             label={translate('Weekday')} fullWidth/>
                <TextInput source='from_hour' label={translate('From Hour')} fullWidth/>
                <TextInput source='to_hour' label={translate('To Hour')} fullWidth/>

            </RowForm>
        </>
    )
};

const CompanyBranchWorkingHours = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {branch_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Company Branch Working Hours"
                reference="dCompanyBranchWorkingHours"
                target="branch"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              branch_id={branch_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            branch_id={branch_id}/>}
                    //rowStyle={postRowStyle}
                >
                <TextField source={'weekday'} label={translate('Weekday')}/>
                <TextField source='from_hour' label={translate('From Hour')}/>
                <TextField source='to_hour' label={translate('To Hour')}/>
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default CompanyBranchWorkingHours;