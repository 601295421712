import {useCreate, useDataProvider, useDelete, useGetList, useUpdate} from 'react-admin';
import {head, isEmpty} from 'lodash';

export default function useCategoriesTreeActions() {
  const dataProvider = useDataProvider();

  const [remove] = useDelete();
  const [update] = useUpdate();
  const [create] = useCreate();

  const handleRemoveAlternateParent = async (source, target) => {
    const relationsData = await
      dataProvider.getList(
        'dProductsGroupRelations',
        {
          pagination: {
            page: 1, perPage: 999
          },
          sort: {
            field: 'id',
            order: 'DESC'
           },
          filter: {product_group_parent: target?.product_group_id, product_group_child: source?.product_group_id}}, {})

    if (isEmpty(relationsData?.data)) return;

    const firstItem = head(relationsData?.data);

    if (!firstItem) return;

    remove('dProductsGroupRelations', firstItem?.id)
  }

  const removeDirectParent = async (source) => {
    if (isEmpty(source)) return;

    return update('dProductsGroup', source?.product_group_id, {parent: null}, {})
  }

  const handleUpdateRelations = async (source, target, isDirect) => {
    if (isEmpty(source)) return;

    if (isDirect) {
      return update('dProductsGroup', source?.product_group_id, {parent: target?.product_group_id}, {})
    }

    if (!isDirect) {
      return create('dProductsGroupRelations', {
        product_group_parent: target?.product_group_id,
        product_group_child: source?.product_group_id
      } )
    }
  }

  const replaceDirectToAlternateParent = async (source, target) => {
    if (isEmpty(source)) return;

    await removeDirectParent(source);
    await handleUpdateRelations(source, target, false)
  }

  return {
    handleRemoveAlternateParent,
    handleUpdateRelations,
    removeDirectParent,
    replaceDirectToAlternateParent
  }
}