import * as React from 'react';
import {
    AutocompleteInput,
    FilterLiveSearch,
    NumberField, ReferenceField, ReferenceInput,
    TextField,
    useLocale,
    useTranslate,
} from 'react-admin';
import {CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../../index.css';
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp, FilterDataAside} from "../../_common/CompReact";
import CardComp from "../../_common/Asside/CardComp";
import CroppedLongField from "../../_common/CroppedLongField";
//import get from 'lodash/get';
import MySimpleList from "../../_common/MySimpleList";
import {Box} from "@mui/material";
import {FloatNumber} from "../../_common/CompFormat/utils";

const Aside = (props) => {
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch/>
                {/*<TrueFalseFilters field={'auto_export_orders'} label={'Auto export orders'}/>*/}
                <FilterDataAside fields={'updated'} name={'update'}/>
                {/*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*/}
                {/*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*/}
                {/*<TrueFalseFilters field={'is_load'} label={'Exported to Xero'}/>*/}
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
            </CardContent>
        </CardComp>
    )
};
const TopFilters = (resource, locale) => {
    let dt = [];
    dt.push(<ReferenceInput source="payment_provider" reference="dPaymentProvider" alwaysOn>
        <AutocompleteInput optionText="name" resettable emptyText={'All providers'}/>
    </ReferenceInput>);

    return dt;
}

const ColumnsForList = (resource, locale, classes, isSmall) => {
    /*
    {
    "additional": null,
    "id": 1,
    "date": "2024-01-01T01:00:00",
    "updated": "2024-01-01T01:00:00",
    "source": "admin",

    "order": 1,
    "payment_provider": 2,
    "amount": 123,
    "currency": 1,
    "transaction_id": "123",
    "status": "approved"
}
     */

    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        date: <DateFieldComp source="date" label={translate('Date')} textAlign={'center'} showTime={true}
                             locale={'us'} cellClassName={classes.date_st}/>,


        transaction_id: <TextField source={'transaction_id'} label={translate('Transaction ID')}/>,
        status: <TextField source={'status'} label={translate('Status')}/>,

        payment_provider:
            <ReferenceField
                // link={false}
                sortable={false}
                label={translate('Payment provider')}
                source={'payment_provider'}
                reference={'dPaymentProvider'}
            >
                <TextField source={`${'name'}`}/>
            </ReferenceField>,
        order:
            <ReferenceField
                textAlign={'center'}
                sortable={false}
                label={translate('Order')}
                source={'order'}
                reference={'dOrders'}
            >
                <Box display={'grid'}>
                    <ReferenceField
                        source={'customer'}
                        reference={'dCustomers'}
                    >
                        <TextField source={`${'name'}`}/>
                    </ReferenceField>
                    <DateFieldComp source="created" showTime={true}
                                   locale={'us'} cellClassName={classes.date_st}/>
                </Box>
            </ReferenceField>,
        amount:
            <Box sortBy={'amount'} label={translate('Amount')} textAlign={'right'}>
                <ReferenceField
                    link={false}
                    source={'currency'}
                    reference={'dCurrencies'}
                >
                    <TextField source={`${'abbr'}`}/>
                </ReferenceField>
                {' '}
                <FloatNumber source={'amount'}/>
            </Box>,

        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

export const PaymentList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={false}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                permissions={permissions}
                asideFilter={false}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
            is_bulkActionButtons={true}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin
                    {...props}
                    // rowClick="edit"
                    rowStyle={postRowStyle}
                >
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}