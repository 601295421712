//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
import {
    //AutocompleteInput,
    //ReferenceInput,
    FilterLiveSearch,
    NumberField,
    SimpleList,
    TextField,
    useLocale,
    useTranslate,
    // EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {Box, CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../../index.css';
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
//import {exporterPassenger} from "../utils/exporterFile";
import {DateFieldComp, FilterDataAside} from "../../_common/CompReact";
import CardComp from "../../_common/Asside/CardComp";

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    // const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch/>
                <FilterDataAside fields={'created_at'} name={'created'}/>
                <FilterDataAside fields={'updated_at'} name={'updated'}/>
                {/*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*/}
                {/*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*/}
                {/*<TrueFalseFilters field={'is_load'} label={'Exported to Xero'}/>*/}
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
            </CardContent>
        </CardComp>
    )
};

const TopFilters = (resource, locale) => {
    let dt = [];
    /*dt.push(<ReferenceInput source="discountProperty_id" reference="discountProperty" alwaysOn label={'Discount Property'}>
            <AutocompleteInput optionText="name" resettable emptyText={'All'}/>
        </ReferenceInput>);
    dt.push(<ReferenceInput source="discountPropertyValue_id" reference="discountPropertyValue" alwaysOn label={'Discount Property Value'}>
            <AutocompleteInput optionText="name" resettable emptyText={'All'}/>
        </ReferenceInput>);*/
    return dt;
}

const ColumnsForList = (resource, locale, classes) => {

    const translate = useTranslate();
    let columns = {
        id: <NumberField
            source="id"
            //headerClassName={classes.headerShortWidth}
            cellClassName={classes.long_id_uuid}
        />,
        //id: <CroppedLongField  source="id" long={20}/>,
        article: <TextField source={'article'} label={translate('article')}/>,
        name: <TextField source={'name'} label={translate('Name')}/>,
        sku: <TextField source={'sku'} label={translate('SKU')} cellClassName={classes.long_id_uuid}/>,
        created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'} cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'} cellClassName={classes.date_st}/>,
    }
    return columns;
};

export const ProductList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={false}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes)}
                permissions={permissions}
                // asideFilter={true}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
            is_bulkActionButtons={false}
        >
            {isSmall
                ?
                <SimpleList
                    rowClick={false}
                    primaryText={<TextField source="name"/>}
                    secondaryText={null}
                    tertiaryText={
                        <Box
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '16px',
                                display: 'grid',
                            }}
                        >
                            {columns}
                        </Box>
                    }
                    linkType={false}//{record => record.canEdit ? "edit" : "show"}
                    rowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}