import * as React from 'react';
import {useCallback} from 'react';
//import DownloadIcon from '@material-ui/icons/GetApp';
import {
    //fetchRelatedRecords,
    useNotify,
    useListContext,
} from 'ra-core';
import {Button} from "@material-ui/core";
import {fetchJson as httpClient} from "../../utils/keycloak";
import DownloadIcon from "@material-ui/icons/GetApp";
// import {saveAs} from 'file-saver';
import moment from "moment";
import config from "../../utils/config";
import {saveCustomersToExcel} from "../../_common/CompExcel/exlel";

const defaultIcon = <DownloadIcon/>;

const HandlerCreateCsvFile = (props) => {
    const {
        onClick,
        label,
        table,
        //icon=false,
    } = props;
    const {
        resource,
        selectedIds,
        // filterValues,
    } = useListContext(props);

    //const exporter = customExporter || exporterFromContext;
    const notify = useNotify();
    // const refresh = useRefresh();

    const getData = async () => {
        const url = config.apiPrices
        //let report_url = `booking/admin/currency`;

        let report_url = `api/pricing/admin/export-customer-properties`;

        /*let data = {
            selectedIds,
            filterValues
        }*/
        try {
            const response = await httpClient(`${url}${report_url}`);
            /*const response = await httpClient(`${url}${report_url}`, {
                method: 'post',
                body: JSON.stringify(data.selectedIds)
                /!*body: JSON.stringify({
                    b64: base64
                })*!/
            })*/
            if (response.status >= 200 && response.status <= 300) {
                const {json: dt} = response
                const {message} = dt || {}
                console.log('response=========>', response);

                if (message) {
                    //alert(response.json.message)
                    notify(message, {type: 'info', autoHideDuration: 8000,});
                } else {
                    let filename = table + moment(new Date()).format('___YYYYMMDD_HH_mm')// + '.xlsx'
                    if (dt.length > 0) {
                        saveCustomersToExcel(dt, filename)
                        notify(`File ${filename} successfully created`, {type: 'info', autoHideDuration: 8000,});
                    } else {
                        notify(`Result not found`, {type: 'info', autoHideDuration: 8000,});
                    }
                }
            } else {
                notify(`ERROR ${response.status}`, {type: 'error', autoHideDuration: 8000,});
            }
        } catch (err) {
            console.log('err=======>', err);
            notify(err, {type: 'error',});
        } finally {
            //console.log('finally=======>', 'finally');
        }
    }

    /*useEffect(() => {
         if (result) {
         }
     }, [result]);// eslint-disable-line*/

    const handleClick = useCallback(
        event => {
            getData();
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [notify, onClick, resource, selectedIds]
    );

    return (
        <>
            <Button
                style={{marginLeft: selectedIds.length > 0 && 24, marginTop: selectedIds.length > 0 && -4}}
                color={"primary"}
                onClick={handleClick}
                label={label}
            >
                {defaultIcon}{label}
            </Button>
        </>
    );
};

export default HandlerCreateCsvFile;
