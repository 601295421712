import DefaultTable from '../DefaultTable/DefaultClass'

class PersonalDiscountsByProduct extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                name: 'product',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Products', optionText: 'name', locale: true},
            },
            {
                name: 'fix_price_currency',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Currencies', optionText: 'name', locale: true},
            },
            {
                name: 'customer',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Customers', optionText: 'name'},
            },

        ]


        this.filters_aside = []

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]


        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'product',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Products', optionText: 'name', locale: true},
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'customer',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Customers', optionText: 'name'},
                                read_only: false,
                                flex: 1,
                            },

                            /*                          {
                                                          name: 'customer',
                                                          type: 'text',
                                                          show_type: 'NumberField',
                                                          edit_type: 'NumberInput',
                                                          type_ext: {},
                                                          //formTab: 'Common2222',
                                                          read_only: false
                                                      },
                          */
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'value',
                                type: 'float',
                                show_type: 'NumberField',
                                edit_type: 'NumberInputFloatComp',
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'fix_price',
                                type: 'float',
                                show_type: 'NumberField',
                                edit_type: 'NumberInputFloatComp',
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'fix_price_currency', //?????
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Currencies', optionText: 'name', locale: true},
                                read_only: false,
                            },
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]


    }
}

export default PersonalDiscountsByProduct