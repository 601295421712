export const transEn = {
    myLocal: {
        columns: 'Columns',
        hide_filter: 'Pre-Filters', //'Hide Filter',
        show_filter: 'Pre-Filters', //'Show Filter',
    },
    dbFields: {},
    remove_alternate_parent: "Remove alternative relationship to %{parent_name}",
    replace_alternate_to_parent: "Make %{parent_name} the direct parent and make %{alternate_parent_name} the alternate relationship",
    replace_parent: "Make %{parent_name} the direct parent and remove the relationship with %{alternate_parent_name}",
    remove_parent: "Remove direct relationship to %{parent_name}",
    make_alternative: "Make %{parent_name} an alternate parent",
    make_direct: "Make %{parent_name} a direct parent",
    undo_action: 'Undo action'
}
