import CarBrands from '../tablesConfig/CarBrands/CarBrands'
// import UnitsOfMeasurement from "../tablesConfig/UnitsOfMeasurements/UnitsOfMeasurement";
import Currencies from "../tablesConfig/Currencies/Currencies";
import Brands from "./Brands/Brands";
// import CurrencyFilter from "./Currencies/CurrencyFilter";
import CurrencyRates from "./Currencies/CurrencyRates";
// import CurrencyCrossRates from "./Currencies/CurrencyCrossRates";
import CurrencyForeignName from "./Currencies/CurrencyForeignName";
import BrandsSynonyms from "./Brands/BrandsSynonyms";
import Prefixes from "./Brands/Prefixes";
import Suffixes from "./Brands/Suffixes";
import BrandsReplacement from "./Brands/BrandsReplacement";
import DiscountCustomerBrand from "./Brands/DiscountCustomerBrand";
import BrandImage from "./Brands/BrandImage";
import ProductsGroup from "./Products/ProductsGroup";
import Products from "./Products/Products";
import PersonalDiscountsByProduct from "./Products/PersonalDiscountsByProduct";
import ProductToProductGroup from "./Products/ProductToProductGroup";
import PromotionDiscountByProduct from "./Products/PromotionDiscountByProduct";
import AssortimentMarkupByQuantity from "./Products/AssortimentMarkupByQuantity";
import PriceRows from "./Products/PriceRows";
import ComponentGood from "./Products/ComponentGood";
import RelatedProducts from "./Products/RelatedProducts";
import MonthsOfActiveSales from "./Products/MonthsOfActiveSales";
import Customers from "./Customers/Customers";
//import {commonTrueFalse} from "../utils/choices";
import DiscountGroups from "./Customers/DiscountGroups";
import PriceTypes from "./Customers/PriceTypes";
import Delivery from "./Delivery/Delivery";
import {JustRow} from "./tablePattern"
import CustomerPhone from "./Customers/CustomerPhone";

export const tableConfig = {

    //tablesList2: [],
    tablesList: [
        JustRow('Customers', 'Customers', new Customers()),
        JustRow('PriceTypes', 'Customers', new PriceTypes()),
        JustRow('DiscountGroups', 'Customers', new DiscountGroups()),
        JustRow('CustomerPhone', 'Customers', new CustomerPhone()),
        JustRow('CustomerUser', 'Customers'),
        JustRow('Countries', 'Customers'),
        JustRow('Regions', 'Customers'),
        JustRow('SuppliersWarehouses', 'Customers'),


        JustRow('Delivery', 'Delivery', new Delivery()),
        JustRow('DeliverySettings', 'Delivery'),
        JustRow('DeliveryServices', 'Delivery'),



        JustRow('Promotion', 'Def', {}, 'api/react-admin/promotion/'),
        JustRow('PromotionBadge', 'Def', {}, 'api/react-admin/promotion/'),
        JustRow('DiscountConditionsDocument', 'Def', {}, 'api/react-admin/promotion/'),
        JustRow('PromotionalGood', 'Def', {}, 'api/react-admin/promotion/'),

        JustRow('Suppliers', 'Def'),
        JustRow('SuppliersWarehouses', 'Def'),
        JustRow('News', 'Def'),
        JustRow('CommonReview', 'Def'),
        JustRow('Order', 'Def'),
        JustRow('OrderItems', 'Def'),
        JustRow('ShoppingCart', 'Def'),
        JustRow('OrderStatus', 'Def'),
        JustRow('Manager', 'Def'),
        JustRow('PhoneMessenger', 'Def'),

        JustRow('BlogArticle', 'Def'),
        JustRow('BlogArticleParts', 'Def'),
        JustRow('MenuItems', 'Def'),

        JustRow('CompanyBranch', 'Def'),
        JustRow('CompanyBranchPhone', 'Def'),
        JustRow('CompanyBranchWorkingHours', 'Def'),

        JustRow('VariantProduct', 'Def',{}, 'api/react-admin/product_page/'),
        JustRow('VariantProductImage', 'Def',{}, 'api/react-admin/product_page/'),
        JustRow('ProductsPrice', 'Def',{}, 'api/react-admin/product_page/'),

        JustRow('PersonalDiscountsByProduct', 'Def',{}, 'api/react-admin/main/'),
        JustRow('ProductToProductGroup', 'Def',{}, 'api/react-admin/main/'),
        JustRow('PriceTypes', 'Def',{}, 'api/react-admin/main/'),
        JustRow('Holiday', 'Def',{}, 'api/react-admin/main/'),
        JustRow('AvailableLanguage', 'Def',{}, 'api/react-admin/main/'),

        JustRow('CharacteristicProduct', 'Def',{}, 'api/react-admin/product_page/'),
        JustRow('Color', 'Def',{}, 'api/react-admin/product_page/'),
        JustRow('VariantColor', 'Def',{}, 'api/react-admin/product_page/'),
        JustRow('FeatureType', 'Def',{}, 'api/react-admin/product_page/'),
        JustRow('FeatureValidValue', 'Def',{}, 'api/react-admin/product_page/'),
        JustRow('ProductsFeatureValue', 'Def',{}, 'api/react-admin/product_page/'),



        JustRow('CarBrands', '', new CarBrands()),
        // JustRow('UnitsOfMeasurement', '', new UnitsOfMeasurement()),



        JustRow('Products', 'Products', new Products()),
        JustRow('ProductsGroup', 'Products', new ProductsGroup()),
        JustRow('PersonalDiscountsByProduct', 'Products', new PersonalDiscountsByProduct()),
        JustRow('ProductToProductGroup', 'Products', new ProductToProductGroup()),
        JustRow('PromotionDiscountByProduct', 'Products', new PromotionDiscountByProduct()),
        JustRow('RelatedProducts', 'Products', new RelatedProducts()),
        JustRow('AssortimentMarkupByQuantity', 'Products', new AssortimentMarkupByQuantity()),
        JustRow('ComponentGood', 'Products', new ComponentGood()),
        JustRow('MonthsOfActiveSales', 'Products', new MonthsOfActiveSales()),
        JustRow('Product2TDCharacteristics', 'Products'),
        JustRow('PriceRows', 'Products', new PriceRows()),
        JustRow('ProductsSynonyms', 'Products'),
        JustRow('ProductsUpdateHistory', 'Products'),

        JustRow('Currencies', 'Currencies', new Currencies()),
        JustRow('CurrencyRates', 'Currencies', new CurrencyRates()),
        JustRow('CurrencyForeignName', 'Currencies', new CurrencyForeignName()),

        JustRow('Brands', 'Brands', new Brands()),
        JustRow('BrandsSynonyms', 'Brands', new BrandsSynonyms()),
        JustRow('Prefixes', 'Brands', new Prefixes()),
        JustRow('Suffixes', 'Brands', new Suffixes()),
        JustRow('BrandsReplacement', 'Brands', new BrandsReplacement()),
        JustRow('DiscountCustomerBrand', 'Brands', new DiscountCustomerBrand()),
        JustRow('BrandImage', 'Brands', new BrandImage()),

        JustRow('DeliveryBetweenBranch'),
        JustRow('DeliveryServicesCities'),
        JustRow('DeliveryServicesDepartment'),
        JustRow('DeliveryRecipients'),
        JustRow('FavoriteTable'),
        JustRow('AvailableLanguage'),
        JustRow('Holiday'),
        JustRow('Suppliers'),
        JustRow('CurrencyFilter'),
        JustRow('CurrencyCrossRates'),
        JustRow('SupplierWarehouseWorkingHours'),
        JustRow('SpecialDiscountSupplierCustomer'),
        JustRow('PriceBrands'),
        JustRow('PriceArticles'),
        JustRow('PriceFilterIntervals'),
        JustRow('DiscountTypes'),
        JustRow('DiscountIntervals'),
        JustRow('DiscountIntervalsValues'),
        JustRow('DiscountValues'),
        JustRow('Tag'),
        JustRow('PayType'),
        JustRow('ShoppingCart'),
        JustRow('CustomerCart'),
        JustRow('OrderStatus'),
        JustRow('OrderStatusMessages'),
        JustRow('UserStatusNotification'),
        JustRow('OrderItems'),
        JustRow('CompanyPhone'),
        JustRow('CompanyBranch'),
        JustRow('CompanyBranchWorkingHours'),
        JustRow('CompanyBranchPhone'),
        JustRow('PricePreprocessingResult'),
        JustRow('ManagerCustomer'),
        JustRow('UnitsOfMeasurementSynonyms'),
        JustRow('NewsType'),
        JustRow('SpecialDiscountSupplierDiscountGroup'),
        JustRow('ConfigProductCart'),
        JustRow('CrossCodesUpdateHistory'),
        JustRow('DiscountMapOptions'),
        JustRow('UserProfile'),
        JustRow('TmpPhoneKey'),
        JustRow('SubscriptionEmailAddress'),
        JustRow('GroupProfile'),
        JustRow('PermissionProxy'),
        JustRow('BlogMetaTagsRoot'),
        JustRow('Repayment'),
        JustRow('ReturnMethod'),
        JustRow('MoneyBackStatus'),
        JustRow('OrderStatusGroup'),
        JustRow('OrderStatusToOrderStatusGroup'),
        JustRow('OrderItemStatusGroup'),
        JustRow('OrderStatusToOrderItemStatusGroup'),
        JustRow('UnconnectedUser'),
        JustRow('TecDocCategory'),

        JustRow('DeliveryCustomer', 'Empty'),
        JustRow('DeliveryWarehouse', 'Empty'),
        JustRow('DeliveryType', 'Empty'),
        JustRow('DeliveryConditionWarehouses', 'Empty'),
        JustRow('DeliveryConditionCustomers', 'Empty'),
        JustRow('DeliveryScheduler', 'Empty'),
        JustRow('DeliveryException', 'Empty'),
        JustRow('DeliverySchedulerCalculate', 'Empty'),
        JustRow('DeliverySuppliersWarehousesToBranch', 'Empty'),
        JustRow('DeliveryScheduler', 'Empty'),
        JustRow('DeliveryPointOfSale', 'Empty'),
        JustRow('PriceAttribute', 'Empty'),
        JustRow('PriceAttributeValue', 'Empty'),
        JustRow('SupplierArticleReplacement', 'Empty'),
        JustRow('ExcludedBrands', 'Empty'),
        JustRow('CustomerSupplierInclude', 'Empty'),
        JustRow('CustomerSupplierExclude', 'Empty'),
        JustRow('QuantityRepresentationInSupplierPrice', 'Empty'),
        JustRow('SpecialDiscountSupplierContract', 'Empty'),
        JustRow('Price', 'Empty'),
        JustRow('CrossCodes', 'Empty'),
        JustRow('DiscountWareHouse', 'Empty'),
        JustRow('DiscountSupplierBrand', 'Empty'),
        JustRow('DiscountCustomerContractBrand', 'Empty'),
        JustRow('TDCharacteristics', 'Empty'),
        JustRow('StatusNotification', 'Empty'),
        JustRow('CompanyMessenger', 'Empty'),
        JustRow('SupplierPriceGroups', 'Empty'),
        JustRow('SupplierAPIs', 'Empty'),
        JustRow('SupplierApiCart', 'Empty'),
        JustRow('SupplierApiOrderItems', 'Empty'),
        JustRow('SupplierApiOrder', 'Empty'),
        JustRow('CurrentBalance', 'Empty'),
        JustRow('SupplierArticleReplacementUpdateHistory', 'Empty'),
        JustRow('PopularCategories', 'Empty'),
        JustRow('SuppliersSKUCross', 'Empty'),
        JustRow('SearchBySuppliersConfig', 'Empty'),
        JustRow('ExcludedArtBrand', 'Empty'),
        JustRow('RepaymentPeriod', 'Empty'),
        JustRow('MoneyBackData', 'Empty'),
        JustRow('MoneyBackProductsData', 'Empty'),
        JustRow('Forfeit', 'Empty'),
        JustRow('ScaleForfeit', 'Empty'),
        JustRow('PricesByPriceType', 'Empty'),
        JustRow('CustomerContractDiscountsByProduct', 'Empty'),
        JustRow('UserEmailTemplate', 'Empty'),
        JustRow('RegionsWorkingHours', 'Empty'),
        JustRow('TecDocCategoryToProductGroup', 'Empty'),
        JustRow('PointOfSale', 'Empty'),
        JustRow('DocumentCategory', 'Empty'),
        JustRow('BlogArticlesPreview', 'Empty'),
        JustRow('BlogTabSub', 'Empty'),
        JustRow('BlogTab', 'Empty'),
        JustRow('BlogArticleParts', 'Empty'),
        JustRow('TecDocGenericCategoryToProductGroup', 'Empty'),
        JustRow('CompanyEmails', 'Empty'),

        JustRow('Messenger', 'Error'),
        JustRow('PhoneMessenger', 'Error'),
        JustRow('CustomersDelivery', 'Error'),
        JustRow('City', 'Error'),
        JustRow('Address', 'Error'),
        JustRow('ExclusionDeliveryTime', 'Error'),
        JustRow('Config', 'Error'),
        JustRow('QRCode', 'Error'),
        JustRow('CustomersFiles', 'Error'),
        JustRow('ProductGroupConfig', 'Error'),
        JustRow('News', 'Error'),
        JustRow('MenuItems', 'Error'),
        JustRow('MenuItemBlock', 'Error'),
        JustRow('TradeMarks', 'Error'),
        JustRow('CacheFlags', 'Error'),
        JustRow('DirectoryFiles', 'Error'),
        JustRow('MenuItemImage', 'Error'),
        JustRow('CommonReview', 'Error'),
        JustRow('DocumentItem', 'Error'),
        JustRow('BlogArticle', 'Error'),

        JustRow('PriceSearchByProductGroup', 'Abend'), //????  УЛЕТ
        JustRow('TaggedWhatever', 'Abend'), //????  УЛЕТ
        JustRow('PriceExportParamsProfiles', 'Abend'),
        JustRow('SearchQueries', 'Abend'),
        JustRow('SearchQueriesArtBrand', 'Abend'),
        JustRow('TecDocGenericCategory', 'Abend'),

    ]
}
