import * as React from 'react';
import {
    FilterLiveSearch, NumberField,
    RecordContextProvider, ReferenceField, TextField,
    useListContext, useLocale, useTranslate,
} from 'react-admin';

//import inflection from 'inflection';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
} from '@mui/material';

import LinkToRelatedProducts from './LinkToRelatedProducts';
import TableActions from "../../_common/TableActions";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import {Link} from "react-router-dom";
import {MyTree} from "../../_common/MyTree";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {DateFieldComp, ImageInfo} from "../../_common/CompReact";
import CroppedLongField from "../../_common/CroppedLongField";
import useMyStyles from "../../utils/useMyStyles";
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import {useEffect} from "react";
import CardCompWidth from "../../_common/Asside/CardCompWidth";
import {useMediaQuery} from "@material-ui/core";

const Aside = (props) => {
    const {handleChangeParent, parentFilter, table} = props
    return (
        <CardCompWidth id="aside_card">
            <CardContent>
                <FilterLiveSearch/>
                <MyTree
                    handleChangeParent={handleChangeParent}
                    parentFilter={parentFilter}
                    disabledClick={'end'}
                    table={'categoriesForProductsGroup'}
                />
                {table &&
                    <>
                        <TrueFalseFilters field={'hidden_for_user'} label={'Hidden for user'}/>
                        <TrueFalseFilters field={'show_on_menu'} label={'Site Menu'}/>
                        <TrueFalseFilters field={'show_on_main'} label={'Main page'}/>
                        <TrueFalseFilters field={'show_on_filter'} label={'Filter in listing'}/>
                    </>
                }
            </CardContent>
        </CardCompWidth>
    )
};
const TopFilters = (resource, locale) => {
    let dt = [];

    return dt;
}

const ColumnsForList = (resource, locale, classes) => {
    const translate = useTranslate();
    // const record = useRecordContext(props)

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        hidden_for_user:
            <SelectAndSaveChoices
                label={'A'}
                source="hidden_for_user"
                statuses={[
                    {id: true, name: 'Hidden on site'},
                    {id: false, name: 'Show on site'},
                ]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
                circle={'inv'}
            />,
        /*act: <BooleanFieldCompAct source="hidden_for_user" inv={true}
                                  label={translate("")} textAlign={"center"}/>,*/
        parent:
                <ReferenceField
                    sortable={false}
                    link={true}
                    label={translate('Parent')}
                    source={'parent'}
                    reference={'dProductsGroup'}
                    //filter={{question$id: id}}
                >
                    <TextField source={`name[${locale}]`}/>
                </ReferenceField>,
        image: <ImageInfo src={'image'} label={translate('Image')} type={'rounded'}/>,
        name: <CroppedLongField long={40} source={`name[${locale}]`} label={translate('Name')}/>,
        show_on_menu:
            <SelectAndSaveChoices
                label={'Site Menu'}
                source="show_on_menu"
                statuses={[
                    {id: true, name: 'Hidden'},
                    {id: false, name: 'Show'},
                ]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
                circle={'not inv'}
            />,
        show_on_main:
            <SelectAndSaveChoices
                label={'Main page'}
                source="show_on_main"
                statuses={[
                    {id: true, name: 'Hidden'},
                    {id: false, name: 'Show'},
                ]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
                circle={'not inv'}
            />,
        show_on_filter:
            <SelectAndSaveChoices
                label={'Filter in listing'}
                source="show_on_filter"
                statuses={[
                    {id: true, name: 'Hidden'},
                    {id: false, name: 'Show'},
                ]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
                circle={'not inv'}
            />,

        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')}
                                textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }

    return columns;
};

const ProductsGroupList = ({...props}) => {
    const classes = useMyStyles();
    const {resource} = props;
    const locale = useLocale();

    //const sm = useMediaQuery(theme.breakpoints.up('sm'));
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    const [nameCategory, setNameCategory] = React.useState('');
    const [parentFilter, setParentFilter] = React.useState('');
    const [table, setTable] = React.useState(true);

    const handleTableOrGrid = (par) => {
        setTable(par)
    }
    const handleChangeParent = (id, label) => {
        setParentFilter(id)
        setNameCategory(label)
    }

    useEffect(() => {
        let dt = localStorage.getItem('categoriesForProductsGroup')
        if (dt) {
            dt = JSON.parse(dt)
            setParentFilter(dt.id)
            setNameCategory(dt.label)
            // expanded: expanded,
        }
    }, []);// eslint-disable-line

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes),
        omit: ["nb_views"],
    });

    // const theme = useTheme();
    // document.title = `iAM-Trade : Categories ${nameCategory ? '(' + nameCategory + ')' : ''}`
    return (
        <>
            {/*<h3 style={{marginBottom: -4}}>Categories {nameCategory ? `(${nameCategory})` : ''}</h3>*/}
            <ListComponentAdmin
                {...props}
                //component={'div'}
                title={`Categories ${nameCategory ? '(' + nameCategory + ')' : ''}`}
                actions={<TableActions
                    handleTableOrGrid={handleTableOrGrid}
                    table={table}
                    create={true}
                    select={table ? true : false}
                    preference={`${resource}list.columns`}
                    columns={ColumnsForList(resource, locale, classes)}
                    //permissions={permissions}
                    asideFilter={false}
                    // filter={false}
                    //exportCsv={true}
                    //exporterFile={exporterPassenger}
                    // sortButton={[`name.${locale}`, 'updated', 'id']}
                />}
                filters={TopFilters(resource, locale)}
                filter={{parent: parentFilter}}
                //filterDefaultValues={{viewed: true}}
                //aside={sm ? <Aside/> : false}
                aside={<Aside handleChangeParent={handleChangeParent} parentFilter={parentFilter} table={table}/>}
                is_bulkActionButtons={table ? true : false}

                sort={{field: 'id', order: 'ASC'}}
                perPage={24}
                //listBase={true}
            >
                {table && isSmall === false
                    ?
                    <DatagridComponentAdmin {...props} rowClick="edit">
                        {columns}
                    </DatagridComponentAdmin>
                    :
                    <CategoryGrid {...props}/>
                }
            </ListComponentAdmin>
        </>
    );
}
// "react-admin": "^3.19.3",
const CategoryGrid = ({...props}) => {

    //console.log('props===========>', props)

    const {data, isLoading} = useListContext();
    const locale = useLocale();
    if (isLoading) {
        return null;
    }
    return (
        <Grid container spacing={2} sx={{mt: 0, padding: '16px'}}>
            {Object.values(data).map(record => (
                <OneRecordContextProvider record={record} locale={locale}/>
            ))}
        </Grid>
    );
};

const OneRecordContextProvider = (props) => {
    const {record, locale} = props;
    let ava = "https://amiel.club/uploads/posts/2022-03/1647759888_27-amiel-club-p-kartinki-dlya-internet-magazina-odezhdi-31.jpg"

    // console.log('OneRecordContextProvider==========>', record)
    return (
        <RecordContextProvider key={record.id} value={record}>
            <Grid
                key={record.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                item
            >
                <Card>
                    <CardMedia
                        component={Link}
                        to={`/dProductsGroup/${record.id}`}
                        //color={'red'}
                        style={{opacity: record?.image ? 1 : 0.1}}
                        /*image={`https://marmelab.com/posters/${record.name}-1.jpeg`}*/
                        image={`${record?.image ? record?.image : ava}`}
                        //image={`${ava}`}
                        sx={{height: 320}}
                        // sx={{ width: 120 }}
                    />
                    <CardContent
                        sx={{
                            paddingBottom: '0.5em',
                            maxHeight: 56,
                            height: 56,
                            overflowY: 'hidden'
                        }}>
                        <a
                            style={{
                                color: 'currentColor',
                                textDecoration: 'none',
                            }}
                            href={`#/dProductsGroup/${record.id}`}
                        >
                            <Typography
                                // variant="h5"
                                variant="h6"
                                component="h2"
                                align="center"
                            >
                                {/*{inflection.humanize(record.name[locale] !== undefined ? record.name[locale] : '')}*/}
                                {record.name[locale]}
                            </Typography>
                        </a>
                    </CardContent>
                    <CardActions
                        sx={{
                            justifyContent: 'center',
                            '.MuiCardActions-spacing': {
                                display: 'flex',
                                // justifyContent: 'space-around',

                            },
                        }}
                    >
                        <LinkToRelatedProducts/>
                        {/*<EditButton label={'Edit'} to={`/dProductsGroup/${record.id}`}/>*/}
                    </CardActions>
                </Card>
            </Grid>
        </RecordContextProvider>
    )
}

export default ProductsGroupList;
