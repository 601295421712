import React, {
    isValidElement,
    cloneElement,
    useEffect,
} from 'react';
import {Form} from 'react-final-form';
import {
    ExpandRowButton,
    useSaveContext,
} from 'react-admin';
import {TableRow, TableCell, Checkbox, makeStyles} from '@material-ui/core';

import {SaveRowButtonCustom} from "./SaveRowButtonCustom";
import {CancelEditButtonCustom} from "./CancelEditButtonCustom";

/**
 * A form to be rendered as a table row in an <EditableDatagrid>.
 *
 * All the props it expects are injected by <EditableDatagrid>. You should only
 * provide children to be rendered in each table cell.
 *
 * The children should be Input components, just like in a <SimpleForm>. You
 * can also pass a <Field> component as child.
 *
 * <RowForm> should have as many children as the <EditableDatagrid> that calls
 * it, or there will be a colSpan issue.
 *
 * @example
 *
 *     const ArtistForm: FC = props => (
 *         <RowForm {...props}>
 *             <TextField source="id" />
 *             <TextInput source="firstname" validate={required()} />
 *             <TextInput source="name" validate={required()} />
 *             <DateInput source="dob" label="born" validate={required()} />
 *             <SelectInput
 *                 source="prof"
 *                 label="Profession"
 *                 choices={professionChoices}
 *             />
 *         </RowForm>
 *     );
 *
 * @see EditableDatagrid
 */
const RowFormCustom = (props) => {//"typescript": "^5.5.3", (77)
    const {
        is_save = true,
        children,
        record,
        id,
        className,
        quitEditMode,
        expand,
        hasBulkActions,
        initialValues,
        selectable,
        basePath,
        resource,
        save,
        saving,
        selected,
        submitOnEnter = true,
        undoable,
        onSuccess,
        onFailure,
        transform,
        mutationMode,
        ...rest
    } = props;
    const classes = useStyles();

    // handle submit by enter
    const onKeyDown =
        handleSubmit =>
            (event) => {
                if (event.key === 'Enter' && submitOnEnter) {
                    event.preventDefault();
                    event.stopPropagation();
                    handleSubmit();
                }
            };

    const saveContext = useSaveContext();

    useEffect(() => {
        if (onFailure) {
            saveContext.setOnFailure(onFailure);
        }
        if (onSuccess) {
            saveContext.setOnSuccess(onSuccess);
        }
        if (transform) {
            saveContext.setTransform(transform);
        }
    }, [saveContext, onFailure, onSuccess, transform]);

    return (
        <Form
            initialValues={{...initialValues, ...record}}
            onSubmit={save}
            {...rest}
        >
            {({handleSubmit, invalid, dirty}) => (
                <TableRow
                    className={className}
                    key={id}
                    onKeyDown={onKeyDown(handleSubmit)}
                >
                    {expand && (
                        <TableCell padding="none">
                            <ExpandRowButton
                                classes={rest.classes}
                                expanded={false}
                                disabled
                            />
                        </TableCell>
                    )}
                    {hasBulkActions && (
                        <TableCell padding="checkbox">
                            {selectable && (
                                <Checkbox
                                    color="primary"
                                    checked={selected}
                                    disabled
                                />
                            )}
                        </TableCell>
                    )}
                    {React.Children.map(children, (field, index) =>
                        isValidElement(field) ? (
                            <TableCell
                                key={index}
                                className={field.props.cellClassName}
                                align={field.props.textAlign}
                            >
                                {cloneElement(field, {
                                    record,
                                    basePath: field.props.basePath || basePath,
                                    resource,
                                })}
                            </TableCell>
                        ) : null
                    )}
                    <TableCell className={classes.actionColumn}>
                        <div style={{display: "grid"}}>
                            <CancelEditButtonCustom cancel={quitEditMode}/>
                            {is_save &&
                                <SaveRowButtonCustom
                                    dirty={dirty}
                                    handleSubmit={handleSubmit}
                                    invalid={invalid}
                                    quitEditMode={quitEditMode}
                                    saving={saving}
                                    undoable={undoable}
                                    mutationMode={mutationMode}
                                />
                            }
                        </div>
                    </TableCell>
                </TableRow>
            )}
        </Form>
    );
};


const useStyles = makeStyles(
    {
        actionColumn: {
            whiteSpace: 'nowrap',
            width: '5em',
        },
    },
    {
        name: 'RaRowForm',
    }
);

export default RowFormCustom;
