import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    //AutocompleteInput,
    //ReferenceInput,
    ReferenceField,
    NumberField, useTranslate, useLocale, NumberInput,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp} from "../../_common/CompReact";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
// import {useForm} from "react-final-form";
import {FloatNumber} from "../../_common/CompFormat/utils";
import {Box} from "@mui/material";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {order_id, source} = props;
    const translate = useTranslate();

    console.log('===========================>', source);

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    order: order_id,
                    source: source,
                }}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <TextField source="brand" label={'Brand'}/>
                <Box sx={{display: 'grid'}} label={'Article / Name'}>
                    <TextField source="article" label={'Article'}/>
                    <TextField source="name" label={'Name'}/>
                </Box>
                <NumberInput source={'quantity'} label={translate('Quantity')}
                                             validate={validateReq}/>
                <Box label={'Price'} textAlign={"right"}>
                    <NumberInput source={'price_sell'} label={translate('Price Sell')}
                                             validate={validateReq}/>
                    {' '}
                    <ReferenceField source="currency_sell" reference="Currencies"
                                    label={translate('Currencies')} link={false}
                    >
                        <TextField source={`simbol`}/>
                    </ReferenceField>
                </Box>

            </RowForm>


        </>
    )
};

const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        brand: <TextField source="brand" label={'Brand'}/>,
        article: <Box sx={{display: 'grid', maxWidth: '20em'}} label={'Article / Name'}>
            <TextField source="article" label={'Article'}/>
            <TextField source="name" label={'Name'}/>
        </Box>,
        quantity: <FloatNumber fix={0} delim={'\u00a0'} source={`quantity`} label={'Quantity'}
                               cellClassName={classes.quantity_st} textAlign={"right"}/>,
        price: <Box label={'Price'} textAlign={"right"}>
            <FloatNumber fix={2}
                         source={`price_sell`} label={'Price Sell'}
            />
            {' '}
            <ReferenceField source="currency_sell" reference="Currencies"
                            label={translate('Currencies')} link={false}
            >
                <TextField source={`simbol`}/>
            </ReferenceField>
        </Box>,
        updated: <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                                cellClassName={classes.date_st} showTime={true}/>,

    }
    return columns;
};

const OrderItems = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const {order_id, source} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'OrderItems'}list.columns`,
        columns: ColumnsForList('OrderItems', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Order Items"
                reference="OrderItems"
                target="order"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    // createForm={<ListEditForm submitOnEnter={false} locale={locale}
                    //                         source={source}  order_id={order_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                         source={source}   order_id={order_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default OrderItems;