import * as React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {FilterList, FilterListItem} from 'react-admin';

const DynamicFiltersChoices = (props) => {
    const {reference, fieldsSearch, fieldsNameForFilter, label = null} = props;

    return (
        <FilterList
            label={label ? label : fieldsSearch}
            icon={<CheckCircleOutlineIcon/>}
        >
            {reference.map(v => {
                // console.log('vvv===>', v)
                if(v.filter === undefined || v.filter === true)
                    return (
                        <FilterListItem
                            key={v.id}
                            label={v[fieldsNameForFilter]}
                            value={{
                                [fieldsSearch]: v.id,
                            }}
                        />
                    )
                else return null
                }
            )
            }
        </FilterList>
    )
}

export default DynamicFiltersChoices
