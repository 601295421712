import DefaultTable from '../DefaultTable/DefaultClass'
import {commonTrueFalse, currencyRounding} from "../../_constants/choices";

class Currencies extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_aside = [
            {
                name: 'hidden',
                type: "TrueFalseFilters",
                parameters: [],
            },
        ]

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]

        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'name',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                //formTab: 'Common2222',
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'simbol',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                type_ext: {locale: false},
                                //formTab: 'Common2222',
                                read_only: false,
                                flex: 1,
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'hidden',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'BooleanInput',
                                type_ext: {choices: commonTrueFalse, reference: 'Currencies', width: '3em'},
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'abbr',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'iso_code',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'rounding',
                                type: 'text',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'SelectChoices',
                                type_ext: {choices: currencyRounding, reference: 'Currencies', width: '12em'},
                                read_only: false,
                                flex: 1,
                            },
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]

        this.related_table = [
            {
                name: 'CurrencyRates',
                filters: 'currency', //Фильтруем 'CurrencyRates' по этому полю (currency = Currencies.id)
            },
            {
                name: 'CurrencyFilter',
                filters: 'currency', //Фильтруем 'CurrencyFilter' по этому полю (currency = Currencies.id)
            },
            {
                name: 'CurrencyCrossRates',
                filters: 'base_currency', //Фильтруем 'CurrencyCrossRates' по этому полю (base_currency = Currencies.id)
            },
            {
                name: 'CurrencyForeignName',
                filters: 'currency', //Фильтруем 'CurrencyForeignName' по этому полю (currency = Currencies.id)
            },
        ]

    }
}

export default Currencies