import * as React from 'react';
import {
    ReferenceManyField,
    useTranslate, useLocale,
    SelectInput, TextField,
    TextInput,
    EmailField,
    PasswordInput,
} from 'react-admin';

import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {validateEmailReq, validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {choicesTypeEmail} from "../../_constants/choices";
import CroppedLongField from "../../_common/CroppedLongField";
import {DateFieldComp} from "../../_common/CompReact";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {config_id} = props;
    const translate = useTranslate();

    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    config: config_id,
                    source2: localStorage.getItem('username'),
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <TextInput source={'email'} label={translate('E-mail')} validate={validateEmailReq} fullWidth/>
                <SelectInput source={'email_type'} choices={choicesTypeEmail} validate={validateReq}
                             label={translate('Email Type')} resettable fullWidth/>
                <PasswordInput source={'password'} label={translate('Password')} fullWidth/>
            </RowForm>
        </>
    )
};

const CompanyEmail = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {config_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Company Emails"
                reference="dCompanyEmails"
                target="config"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              config_id={config_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            config_id={config_id}/>}
                    //rowStyle={postRowStyle}
                >
                    <EmailField source={'email'} label={translate('E-mail')}/>
                    <TextField source={'email_type'} label={translate('Email Type')}/>
                    {/*<TextField source={'password'} label={translate('Password')}/>*/}
                    <span label={translate('Password')}>****</span>

                    <CroppedLongField source="source" long={10} label={translate('Source')}/>
                    <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                   locale={'us'} cellClassName={classes.date_st}/>
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default CompanyEmail;