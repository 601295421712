import PermIdentity from '@material-ui/icons/PermIdentity';
import {PromptsList} from "./PromptsList";
import {PromptsCreate} from "./PromptsCreate";
import {PromptsEdit} from "./PromptsEdit";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: PromptsList,
    create: PromptsCreate,
    edit: PromptsEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;