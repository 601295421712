import * as React from 'react';
import {
    DateField,
    Labeled,
    //PrevNextButtons,
    ReferenceField,
    useRecordContext,
    useTranslate, AutocompleteInput, ReferenceInput, useLocale,
    RichTextField, TextInput, TabbedForm, FormTab,
} from 'react-admin';
import {Link as RouterLink} from 'react-router-dom';
import {Box, Grid, Typography, Link} from '@mui/material';

import {validateReq} from "../../_constants/validateTable";
import OrderItems from "./OrderItems";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
import FootherEdit from "../../_common/Headers/FootherEdit";

const OrderEditCreate = (props) => {
    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};
    return (
        <Box>
            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <OrderForm {...props}/>
                </FormTab>
                <FormTab label={translate('Address')}>
                    <Box display="flex" alignItems={"center"}  style={{width: '100%'}}>
                        <Box flex={1} mr={"1em"} display={'grid'}>
                            <Address title={'Address delivery'} source={'address_delivery'}/>
                        </Box>
                        <Box flex={1}>
                            <Address title={'Address billing'} source={'address_billing'}/>
                        </Box>
                    </Box>
                </FormTab>
            </TabbedForm>

            {id && <FootherEdit source={record.source}/>}
        </Box>
    )
};

/*const OrderTitle = () => {
    const translate = useTranslate();
    const record = useRecordContext();
    return record ? (
        <span>
            {translate('resources.commands.title', {
                reference: record.reference,
            })}
        </span>
    ) : null;
};*/

const CustomerDetails = () => {
    const record = useRecordContext();
    return (
        <div>
            <Typography
                component={RouterLink}
                color="primary"
                to={`/dCustomers/${record?.id}`}
                style={{textDecoration: 'none'}}
            >
                {record?.name}
            </Typography>
            <br/>
            <Typography
                component={Link}
                color="primary"
                //href={`mailto:${record?.email}`}
                style={{textDecoration: 'none'}}
            >
                {record?.phone}
            </Typography>
        </div>
    );
};

const Remark = () => {
    //const record = useRecordContext();
    return (
        <div>
            {/*<Typography>
                {record?.first_name} {record?.last_name}
            </Typography>*/}
            <Typography>
                {/*{record?.remark}*/}
                <RichTextField source="remark"/>
            </Typography>
            {/*<Typography>
                {record?.city}, {record?.stateAbbr} {record?.zipcode}
            </Typography>*/}
        </div>
    );
};

const Address = (props) => {
    const translate = useTranslate();
    const {source, title} = props
    return (
        <>
            <Typography variant="h6" gutterBottom>
                {translate(title)}
            </Typography>

            <TextInput
                variant={"outlined"}
                source={`${source}.zip`}
                label={translate('Zip')}
                fullWidth
            />
            <TextInput
                variant={"outlined"}
                source={`${source}.province`}
                label={translate('Province')}
                fullWidth
            />
            <TextInput
                variant={"outlined"}
                source={`${source}.city`}
                label={translate('City')}
                fullWidth
            />
            <TextInput
                variant={"outlined"}
                source={`${source}.street`}
                label={translate('Street')}
                fullWidth
            />
            <TextInput
                variant={"outlined"}
                source={`${source}.apartment`}
                label={translate('Apartment')}
                fullWidth
            />
        </>
    );
};
const Spacer = () => <Box mb={1}>&nbsp;</Box>;

const OrderForm = (props) => {
    const translate = useTranslate();
    const locale = useLocale();
    const record = useRecordContext();
    return (
        <Box style={{width: '100%'}}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="h6" gutterBottom>
                        {translate('Order')}
                    </Typography>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6}>
                            <Labeled source="date">
                                <DateField source="created"/>
                            </Labeled>
                        </Grid>
                        {/*<Grid item xs={12} sm={12} md={6}>
                                        <Labeled source="reference">
                                            <TextField source="reference"/>
                                        </Labeled>
                                    </Grid>*/}
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={6}>
                            <ReferenceInput source="status" reference="OrderStatus"
                                            label={translate('Order Status')}
                                            validate={validateReq}>
                                <AutocompleteInput resettable optionText={`name[${locale}]`} fullWidth/>
                            </ReferenceInput>
                        </Grid>
                        {/*<Grid item xs={12} sm={12} md={6}>
                                        <Box mt={2}>
                                            <BooleanInput
                                                row={true}
                                                source="returned"
                                            />
                                        </Box>
                                    </Grid>*/}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                    <Typography variant="h6" gutterBottom>
                        {translate('Customer')}
                    </Typography>
                    <ReferenceField
                        source="customer"
                        reference="dCustomers"
                        link={true}
                    >
                        <CustomerDetails/>
                    </ReferenceField>
                    <Spacer/>

                    <Typography variant="h6" gutterBottom>
                        {translate('Remarks')}
                    </Typography>
                    <Remark/>
                </Grid>
            </Grid>


            <Spacer/>

            <Typography variant="h6" gutterBottom>
                {translate('Items')}
            </Typography>
            <div>
                <OrderItems
                    {...props}
                    order_id={record.id}
                    source={record.source}
                />
            </div>
            <Spacer/>

            {/*<Typography variant="h6" gutterBottom>
                            {translate('Total')}
                        </Typography>
                        <div>
                            <Totals/>
                        </div>*/}
        </Box>
    );
};

export default OrderEditCreate;
