import * as React from 'react';
import {
    ReferenceManyField,
    useTranslate, useLocale,
    TextField,
    TextInput,
    BooleanInput,
    ReferenceArrayInput,
    ReferenceArrayField,
    AutocompleteArrayInput, ChipField,
    SingleFieldList,
} from 'react-admin';

import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {BooleanFieldComp} from "../../_common/CompReact";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {branch_id} = props;
    const translate = useTranslate();

    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    branch: branch_id,
                    source2: localStorage.getItem('username'),
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                {/*<NumberInputFloatComp source="number" label={translate('Number')}
                                      validate={validateReq}
                                      fullWidth
                />*/}
                <TextInput source="number" label={translate('Number')}
                                      validate={validateReq}
                                      fullWidth
                />
                <ReferenceArrayInput
                    label={translate('Phone Messenger')}
                    source={'phone_messenger'}
                    reference={'dPhoneMessenger'}
                    //filter={{country: id}}
                >
                    <AutocompleteArrayInput optionText={`name`} resettable fullWidth/>
                </ReferenceArrayInput>

                <BooleanInput source="is_visible"
                              label={translate('Is visible')} defaultValue={false}/>
            </RowForm>
        </>
    )
};

const CompanyBranchPhone = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {branch_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Company Branch Phone"
                reference="dCompanyBranchPhone"
                target="branch"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              branch_id={branch_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            branch_id={branch_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {/*<NumberField source='number' label={translate('Holiday')}/>*/}
                    <TextField source='number' label={translate('Number')}/>
                    <ReferenceArrayField source="phone_messenger" reference="dPhoneMessenger"
                                         link={false} label={translate('Phone Messenger')}>
                        <SingleFieldList>
                            <ChipField source="name"/>
                        </SingleFieldList>
                    </ReferenceArrayField>
                    <BooleanFieldComp source="is_visible" label={translate("Is visible")}
                                      textAlign={"center"}/>,
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default CompanyBranchPhone;