import {
    minValue,
    maxValue,
    email,
    maxLength,
    minLength,
    //regex,
    required,
} from "react-admin";

/*
Startup:

Name: min 1 - max 70 characters.
Short description: min 1 - max 270 characters
Subtitle - max 250 characters
Full description - max 5000
Key highlights - max 2500
Why investing - max 5000

Profile:

First and last name: 1 - 150
Address 1: max 300
Address 2: max 300
State, city, zip - 255
*/

export const validateReq = [required()];

export const validateStatusName = [required(), minLength(2), maxLength(300)];
export const validateFirstName = [required(), minLength(2), maxLength(50)];
export const validateNameRec = [required(), minLength(2), maxLength(100)];
export const validateFullNameRec = [required(), minLength(2), maxLength(100)];

export const validateAddressReq = [required(), minLength(2), maxLength(300)];
export const validateAddress = [minLength(2), maxLength(300)];

export const validatePositionRec = [required(), minLength(2), maxLength(100)];
export const validateTitleRec = [required(), minLength(2), maxLength(250)];
export const validateSubtitle = [minLength(2), maxLength(250)];
export const validateDescription = [minLength(2), maxLength(270)];


// export const validatePhoneRec = [required(), minLength(2), maxLength(70)];

export const validateCountry = [minLength(2), maxLength(50)];
export const validateState = [minLength(2), maxLength(50)];
export const validateCity = [minLength(2), maxLength(50)];

export const validateEmailReq = [required(), email()];
export const validateEmail = [email()];

export const validateSumaRec = [required(), minValue(1), maxValue(10000000000)];
// const validateAge = [number(), minValue(18), maxValue(118)];
//export const validateZipCode = [regex(/^\d{5}$/, 'Must be a valid Zip Code (5 digits)')];
export const validateZipCode = [minLength(3), maxLength(50)];
// const validateSex = choices(['m', 'f'], 'Must be Male or Female');

