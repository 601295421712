import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField,
    TextField,
    useLocale,
    useTranslate,
} from 'react-admin';
import {CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../../index.css';
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp, FilterDataAside} from "../../_common/CompReact";
import CardComp from "../../_common/Asside/CardComp";
import CroppedLongField from "../../_common/CroppedLongField";
//import get from 'lodash/get';
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import MySimpleList from "../../_common/MySimpleList";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {choicesPaymentSystemCode} from "../../_constants/choices";
import DynamicFiltersChoices from "../../_common/DynamicFiltersChoices";

const Aside = (props) => {
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch/>
                <TrueFalseFilters field={'is_active'} label={'Active'}/>
                <DynamicFiltersChoices
                    reference={choicesPaymentSystemCode}
                    fieldsSearch={'payment_system_code'}
                    fieldsNameForFilter={'name'}
                    label={'Payment system code'}
                />
                <FilterDataAside fields={'updated'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};
const TopFilters = (resource, locale) => {
    let dt = [];

    return dt;
}

const ColumnsForList = (resource, locale, classes, isSmall) => {
    /*
    {
    "additional": null,
    "id": 2,
    "updated": "2024-01-01T01:00:00",
    "source": "stripe",
    "name": "stripe",
    "public_name": "stripe",
    "payment_system_code": "stripe",
    "is_active": true,
    "description": "stripe",
    "auth_data": {
        "api_key": "djhakd"
    }
}
     */

    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        is_active:
            <SelectAndSaveChoices
                label={'Active'}
                source="is_active"
                statuses={[
                    {id: true, name: 'Active'},
                    {id: false, name: 'Inactive'},
                ]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
                circle={'no inv'}
            />,
        name: <TextField source={'name'}  label={translate('Name')}/>,
        public_name: <TextField source={'public_name'}  label={translate('Public name')}/>,
        payment_system_code:
        <SelectAndSaveChoices
                label={'Payment system code'}
                source="payment_system_code"
                statuses={choicesPaymentSystemCode}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'8em'}
                edit={true}
            />,

        auth_data: <TextField source={'auth_data'}  label={translate('Auth data')}/>,
        description: <TextField source={'description'}  label={translate('Description')}/>,

        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

export const PaymentProviderList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                permissions={permissions}
                asideFilter={false}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
            is_bulkActionButtons={true}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}