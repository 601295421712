import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    useTranslate, useLocale,
    ReferenceField, AutocompleteInput, ReferenceInput,
} from 'react-admin';


import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
// import {validateReq} from "../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    data.name = data.name2;
    delete data.name2;
    data.country = data.country2;
    delete data.country2;

    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {supplier_id, record} = props;
    const translate = useTranslate();
    const locale = useLocale();

    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    supplier: supplier_id,
                    source2: localStorage.getItem('username'),
                    name2: record.name,
                    country2: record.country2
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <MyTranslatableInputs
                    // prompt={promptData.find(x => x.field === 'name')}
                    fullWidth
                    source={'name2'}
                    label={translate('Name')}
                />
                <ReferenceInput
                    label={translate('Country')}
                    source={'country2'}
                    reference={'dCountries'}
                    //filter={{country: id}}
                >
                    <AutocompleteInput optionText={`name[${locale}]`} resettable
                                       variant={"outlined"} fullWidth/>
                </ReferenceInput>

            </RowForm>
        </>
    )
};

const SuppliersWarehouses = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {supplier_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Suppliers Warehouses"
                reference="SuppliersWarehouses"
                target="supplier"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable

                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              supplier_id={supplier_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            supplier_id={supplier_id}/>}
                    //rowStyle={postRowStyle}
                >

                    <TextField source={`name[${locale}]`} label={translate('Name')}/>
                    <ReferenceField
                        link={false}
                        label={translate('Country')}
                        source={'country'}
                        reference={'dCountries'}
                        //filter={{question$id: id}}
                    >
                        <TextField source={`name[${locale}]`}/>
                    </ReferenceField>

{/*
                    import EditInDialogButton from "@react-admin/ra-form-layout";
                    <ReferenceManyField target="company_id" reference="employees">
                        <Datagrid>
                            <TextField source="first_name"/>
                            <TextField source="last_name"/>
                            <EditInDialogButton>
                                <SimpleForm>
                                    <TextInput source="first_name"/>
                                    <TextInput source="last_name"/>
                                </SimpleForm>
                            </EditInDialogButton>
                        </Datagrid>
                    </ReferenceManyField>
*/}

                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default SuppliersWarehouses;