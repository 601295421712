import * as React from 'react';
import {Box, TableBody, TableCell, TableRow, Typography} from "@material-ui/core";
import {useTranslate} from "react-admin";
import {GetArrayFieldFromResource, GetFieldFromResource} from "./Partner";
import Button from "@material-ui/core/Button";
// import {Chip} from "@mui/material";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import Asynchronous2 from "../../_common/AsynchronousRequests2";

export const CircleTrueFalse = props => {
    const {item} = props
    return (
        <Box
            style={{
                width: 15,
                height: 15,
                borderRadius: '50%',
                backgroundColor: item ? '#a4e87d' : '#e88b7d'
            }}
        />
    )
}
export const PartnerAddress = props => {
    const {
        setPartnerAddress,
        partnerAddress,
        edit = false,
        mixCustomers = [],
    } = props
    const translate = useTranslate();
    // console.log('partnerAddress===========>', partnerAddress)

    const handleChangeCustomer = (index, value) => {
        // console.log('index===========>', index)
        let tmp = JSON.parse(JSON.stringify(partnerAddress))
        tmp[index].customer_cross = value
        setPartnerAddress(tmp)
    };

    //console.log('mixCustomers==========>', mixCustomers.filter(x => x.new && x.name))
    return (
        <TableContainer>
            <Table aria-label="spanning table" style={{width: '100%'}}>
                <TableHead>
                    <TableRow>
                        <TableCell align={'center'} style={{padding: 6}}>
                            <Typography variant="body" color="textPrimary">
                                {translate('Auto')}
                            </Typography>
                        </TableCell>
                        <TableCell align={'left'} style={{padding: 6}}>
                            <Typography variant="body" color="textPrimary">
                                {translate('Name')}
                            </Typography>
                        </TableCell>
                        <TableCell align={'left'} style={{padding: 6}}>
                            <Typography variant="body" color="textPrimary">
                                {translate('Partners delivery address')}
                            </Typography>
                        </TableCell>
                        <TableCell align={'center'} style={{padding: 6}}>
                            <Typography variant="body" color="textPrimary">
                                {translate('Confirm')}
                            </Typography>
                        </TableCell>
                        <TableCell align={'center'} style={{padding: 6}}>
                            <Typography variant="body" color="textPrimary">
                                {translate('Disable')}
                            </Typography>
                        </TableCell>
                        <TableCell align={'left'} style={{padding: 6}}>
                            <Typography variant="body" color="textPrimary">
                                {translate('Customers')}
                            </Typography>
                        </TableCell>
                        {!edit &&
                            <TableCell align={'left'} style={{padding: 6}}>
                                <Typography variant="body" color="textPrimary">
                                    {translate('Routes')}
                                </Typography>
                            </TableCell>
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {partnerAddress && partnerAddress.map((it, ind) => {
                        return (
                            <TableRow key={it.id}>
                                <TableCell align={'center'} style={{padding: 4, textAlign: "-webkit-center"}}>
                                    <CircleTrueFalse item={it.auto_address}/>
                                </TableCell>
                                <TableCell align={'left'} style={{padding: 4}}>
                                    <Typography variant="body" color="textPrimary">
                                        {it.name}
                                    </Typography>
                                </TableCell>
                                <TableCell align={'left'} style={{padding: 4}}>
                                    <Typography variant="body" color="textPrimary">
                                        {it.address}
                                    </Typography>
                                </TableCell>
                                <TableCell align={'center'} style={{padding: 4, textAlign: "-webkit-center"}}>
                                    <CircleTrueFalse item={it.confirmed}/>
                                </TableCell>
                                <TableCell align={'center'} style={{padding: 4, textAlign: "-webkit-center"}}>
                                    <CircleTrueFalse item={it.disabled}/>
                                </TableCell>
                                <TableCell align={'left'} style={{padding: 4, width: edit && '50%'}}>
                                    <Box display={'grid'}>
                                        <GetArrayFieldFromResource
                                            resource={'zCustomers'}
                                            field={'name'}
                                            id={it.customers}
                                            type={edit ? 'text' : 'chip'}
                                        />
                                        {edit &&
                                            <Asynchronous2
                                                multiple={true}
                                                label={translate('Add customer')}
                                                value={it?.customer_cross ? it.customer_cross : []}
                                                setValue={(value) => handleChangeCustomer(ind, value)}
                                                // domain={config.apiPrices}
                                                //url={'api/react-admin/main/Delivery'}
                                                choices={mixCustomers
                                                    ? mixCustomers.filter(x => x.new && x.name)
                                                    : []
                                                }
                                                // outputField='id'
                                                //searchField={'search'}
                                                getOptionLabel={(option) => `${option.name}`}
                                                //error={form.address[index].error?.customers}
                                            />
                                        }
                                    </Box>
                                </TableCell>
                                {!edit &&
                                    <TableCell align={'left'} style={{padding: 4}}>
                                        <GetArrayFieldFromResource
                                            resource={'delivery'}
                                            field={'name'}
                                            id={it.deliveries}
                                        />
                                    </TableCell>
                                }
                            </TableRow>
                        )
                    })
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export const PartnerData = props => {

    const {
        partner,
        partnerAddress,
        setActiveStep,
    } = props

    const translate = useTranslate();

    return (
        <>
            <Box mt={2}>
                <Typography variant="body" color="textPrimary">
                    <b>{translate("Partner information")}</b>
                </Typography>
            </Box>
            <Box display="flex" alignItems={"start"} mt={1}>
                <Box flex={1} display="grid" alignItems={"start"} mr={1}>
                    <Typography variant="body" color="textSecondary">
                        {translate("Name")}: <b>{partner.name}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Email")}: <b>{partner.email}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Phone")}: <b>{partner.phone}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Accounting email")}: <b>{partner.accounting_email}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Accounting phone")}: <b>{partner.accounting_phone}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Warehouse phone")}: <b>{partner.warehouse_phone}</b>
                    </Typography>
                </Box>
                <Box flex={1} display="grid" alignItems={"start"} style={{flexWrap: 'wrap'}}>
                    <Typography variant="body" color="textSecondary">
                        {translate("Manager")}:{' '}
                        <b>
                            <GetFieldFromResource
                                resource={'dManager'}
                                field={'name'}
                                id={partner.manager}
                            />
                        </b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Sales representative SNG")}:{' '}
                        <b>
                            <GetFieldFromResource
                                resource={'dManager'}
                                field={'name'}
                                id={partner.sales_representative_1}
                            />
                        </b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Sales representative Ino")}:{' '}
                        <b>
                            <GetFieldFromResource
                                resource={'dManager'}
                                field={'name'}
                                id={partner.sales_representative_2}
                            />
                        </b>
                    </Typography>
                </Box>
            </Box>

            <Box mt={2} display={'flex'}>
                <Box flex={2}>
                    <Typography variant="body" color="textPrimary">
                        <b>{translate("Partner customers")}</b>
                    </Typography>
                </Box>
                <Box ml={2}>
                    <Button
                        style={{width: 190}}
                        //disabled={!isValidStep(activeStep)}
                        variant="contained"
                        color="primary"
                        onClick={(event) => {
                            setActiveStep(1)
                        }}
                        //className={classes.button}
                    >
                        {translate('Create customers')}
                    </Button>
                </Box>
            </Box>
            <Box alignItems={"start"} mt={1}>
                <GetArrayFieldFromResource
                    resource={'zCustomers'}
                    field={'name'}
                    id={partner.customers}
                />
            </Box>

            <Box mt={2} display={'flex'}>
                <Box flex={2}>
                    <Typography variant="body" color="textPrimary">
                        <b>{translate("Partners delivery addresses")}</b>
                    </Typography>
                </Box>
                <Box ml={2}>
                    {/*<Button
                        style={{width: 190}}
                        //disabled={!isValidStep(activeStep)}
                        variant="contained"
                        color="primary"
                        onClick={(event) => {
                            setActiveStep(3)
                        }}
                        //className={classes.button}
                    >
                        {translate('SAVE')}
                    </Button>*/}
                </Box>
            </Box>
            <Box mt={1}>
                <PartnerAddress
                    partnerAddress={partnerAddress}
                />
            </Box>
        </>
    )
};