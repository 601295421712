import * as React from "react";
import DashboardInfo from "./dashboard/DashboardInfo";
import {canAccess2} from "./utils/canAccess2";
import Welcome from "./dashboard/Welcome";

const Dashboard = () => {
    if (canAccess2({resource: 'Dashboard', action: 'list'}))
        return (
            <DashboardInfo/>
        )
    else
        return (<Welcome simple={true}/>)
};

export default Dashboard