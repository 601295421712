import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    useTranslate,
    AutocompleteInput, ReferenceInput,
    useLocale, BooleanInput,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp, getDt, NumberInputFloatComp} from "../../_common/CompReact";
import {useMemo} from "react";

//import {validateReq} from "../utils/components/validateTable";

//import { JsonSchemaForm } from "@react-admin/ra-json-schema-form";


const BlockEdit = props => {
    const translate = useTranslate();
    const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Promotion')}
                        source={'promotion'}
                        reference={'dPromotion'}
                        variant={"outlined"}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                           fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}>
                    <ReferenceInput
                        label={translate('Discount conditions document')}
                        source={'discount_conditions_document'}
                        reference={'dDiscountConditionsDocument'}
                        variant={"outlined"}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput
                            optionText={useMemo(() => {
                                return ((record) => `${record?.document_number} (${getDt(record?.document_date)})`)
                            }, [])}
                            // optionText={`${'name'}[${locale}]`}
                            resettable
                            fullWidth
                        />
                    </ReferenceInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Product')}
                        source={'product'}
                        reference={'dProducts'}
                        variant={"outlined"}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                           fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <BooleanInput
                        source={'cashback'} label={translate('Cashback')}
                        defaultValue={false}
                        //fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <NumberInputFloatComp
                        source="quantity" label={translate('Quantity')}
                        variant={"outlined"}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <NumberInputFloatComp
                        source="discount" label={translate('Discount')}
                        variant={"outlined"}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <NumberInputFloatComp
                        source="fix_price" label={translate('Fix price')}
                        variant={"outlined"}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <ReferenceInput
                        label={translate('Currency')}
                        source={'fix_price_currency'}
                        reference={'dCurrencies'}
                        variant={"outlined"}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`abbr`} resettable fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>

        </Box>
    )
}
export const PromotionalGoodEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                    />
                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default PromotionalGoodEditCreate
