import config from "./config";
import {PermissionZenit} from "../_constants/roleZenitTable";


const PermissionIam = [//REACT_APP_API_URL=https://api-dev.zenit-auto.com/
    {
        role: 'Superuser',
        permissions: [
            /*{action: "*", resource: "dUser"},
            {action: "*", resource: "dInterfaces"},
            {action: "*", resource: "dUsersInterfaces"},

            {action: "*", resource: "Dashboard"},
            {action: "*", resource: "dCustomers"},
            {action: "*", resource: "dProducts"},
            {action: "*", resource: "dProductsGroup"},
            {action: "*", resource: "dVariantProduct"},
            {action: "*", resource: "dProductsPrice"},
            {action: "*", resource: "dPrompts"},
            {action: "*", resource: "dPromptIndustries"},
            {action: "*", resource: "dPromptTypes"},
            {action: "*", resource: "dOrders"},
            {action: "*", resource: "dOrdersForChart"},
            {action: "*", resource: ""},*/

            /*{action: "", resource: "Dashboard"},*/

            {action: "", resource: "related_table"},
            {action: "", resource: "customer_z"},
            {action: "", resource: "discountProperty"},
            {action: "", resource: "discountPropertyValue"},
            {action: "", resource: "currency_z"},
            {action: "", resource: "customer_property"},
            {action: "", resource: "product_property"},
            {action: "", resource: "discount_relationship"},
            {action: "", resource: "discount_rules"},
            {action: "", resource: "brand_discount_by_rule"},
            {action: "", resource: "brand_z"},
            {action: "", resource: "price_row_z"},
            {action: "", resource: "product_discount_by_rule"},
            {action: "", resource: "product_z"},
            {action: "", resource: "discount_values_by_rule"},
            {action: "", resource: "complicated_discount_by_rule"},
            {action: "", resource: "customer_discount_by_rule"},
            {action: "", resource: "gift_pool"},
            {action: "", resource: "gift"},
            {action: "", resource: "test_price"},
            {action: "*", resource: "createPartner"},
            {action: "", resource: "edit_sheduler"},

            {action: "", resource: "CarBrands"},
            {action: "", resource: "UnitsOfMeasurements"},

            {action: "*", resource: "dPromotion"},
            {action: "*", resource: "dPromotionBadge"},
            {action: "*", resource: "dDiscountConditionsDocument"},
            {action: "*", resource: "dPromotionalGood"},

            {action: "*", resource: "*"},
        ]
    },
    {
        role: 'Admin',
        permissions: [
            {action: "*", resource: "googleMerchant"},
            {action: "*", resource: "localization"},
            {action: "*", resource: "translateOnBackEnd"},

            {action: "*", resource: "dUser"},
            {action: "*", resource: "dInterfaces"},
            {action: "*", resource: "dUsersInterfaces"},

            {action: "*", resource: "dPrompts"},
            {action: "*", resource: "dPromptIndustries"},
            {action: "*", resource: "dPromptTypes"},

            {action: "*", resource: "Dashboard"},

            {action: "*", resource: "dBrands"},
            {action: "*", resource: "dCustomers"},
            {action: "*", resource: "dProducts"},
            {action: "*", resource: "dProductsGroup"},
            {action: "*", resource: "dCharacteristicProduct"},
            {action: "*", resource: "dVariantProduct"},
            {action: "*", resource: "dProductsPrice"},
            {action: "*", resource: "dOrders"},
            {action: "*", resource: "dOrdersForChart"},
            {action: "*", resource: "dFeatureType"},

            {action: "*", resource: "dConfig"},
            {action: "*", resource: "dCountries"},
            {action: "*", resource: "dManager"},
            {action: "*", resource: "dCompanyBranch"},
            {action: "*", resource: "dCurrencies"},
            {action: "*", resource: "dPhoneMessenger"},
            {action: "*", resource: "dMessenger"},
            {action: "*", resource: "dSuppliers"},
            {action: "*", resource: "dExternalSuppliers"},
            {action: "*", resource: "dProcessing"},
            {action: "*", resource: "dLoadPrice"},
            {action: "*", resource: "dMenuItems"},
            {action: "*", resource: "dBlogArticle"},
            {action: "*", resource: "dNews"},
            {action: "*", resource: "dSeoKeyWords"},

            {action: "*", resource: "dSizeChart"},
            {action: "*", resource: "dColor"},
            {action: "*", resource: "dUnitsOfMeasurement"},

            {action: "*", resource: "dPromotion"},
            {action: "*", resource: "dPromotionBadge"},
            {action: "*", resource: "dDiscountConditionsDocument"},
            {action: "*", resource: "dPromotionalGood"},

            {action: "*", resource: "dPaymentProvider"},
            {action: "*", resource: "dPayment"},

            // {action: "*", resource: "*"},

            /*{action: "", resource: "related_table"},
            {action: "", resource: "customer_z"},
            {action: "", resource: "discountProperty"},
            {action: "", resource: "discountPropertyValue"},
            {action: "", resource: "currency_z"},
            {action: "", resource: "customer_property"},
            {action: "", resource: "product_property"},
            {action: "", resource: "discount_relationship"},
            {action: "", resource: "discount_rules"},
            {action: "", resource: "brand_discount_by_rule"},
            {action: "", resource: "brand_z"},
            {action: "", resource: "product_discount_by_rule"},
            {action: "", resource: "product_z"},
            {action: "", resource: "discount_values_by_rule"},
            {action: "", resource: "complicated_discount_by_rule"},
            {action: "", resource: "customer_discount_by_rule"},
            {action: "", resource: "gift_pool"},
            {action: "", resource: "gift"},
            {action: "", resource: "test_price"},
            {action: "*", resource: "*"},*/
        ]
    },
    {
        role: 'Manager',
        permissions: [
            {action: "*", resource: "Dashboard"},

            {action: "*", resource: "dBrands"},
            {action: "*", resource: "dCustomers"},
            {action: "*", resource: "dProducts"},
            {action: "*", resource: "dProductsGroup"},
            {action: "*", resource: "dCharacteristicProduct"},
            {action: "*", resource: "dVariantProduct"},
            {action: "*", resource: "dProductsPrice"},
            // {action: "*", resource: "dPrompts"},
            // {action: "*", resource: "dPromptIndustries"},
            // {action: "*", resource: "dPromptTypes"},
            {action: "*", resource: "dOrders"},
            {action: "*", resource: "dOrdersForChart"},
            {action: "*", resource: "dFeatureType"},

            {action: "*", resource: "dConfig"},
            {action: "*", resource: "dCountries"},
            {action: "*", resource: "dManager"},
            {action: "*", resource: "dCompanyBranch"},
            {action: "*", resource: "dCurrencies"},
            {action: "*", resource: "dPhoneMessenger"},
            {action: "*", resource: "dMessenger"},
            {action: "*", resource: "dSuppliers"},
            {action: "*", resource: "dExternalSuppliers"},
            {action: "*", resource: "dProcessing"},
            {action: "*", resource: "dLoadPrice"},
            {action: "*", resource: "dMenuItems"},
            {action: "*", resource: "dBlogArticle"},
            {action: "*", resource: "dNews"},
            {action: "*", resource: "dSeoKeyWords"},

            {action: "*", resource: "dSizeChart"},
            {action: "*", resource: "dColor"},
            {action: "*", resource: "dUnitsOfMeasurement"},

            {action: "*", resource: "dPaymentProvider"},
            {action: "*", resource: "dPayment"},

            // {action: "*", resource: "*"},
        ]
    },
];
const PermissionErr = [//REACT_APP_API_URL=https://api-dev.zenit-auto.com/
    {
        role: 'Superuser',
        permissions: [
            {action: "*", resource: "dUser"},
            {action: "*", resource: "dInterfaces"},
            {action: "*", resource: "dUsersInterfaces"},
        ]
    }
];

export function canAccess2(params) {

    const {resource, action} = params;
    let Permission;
    switch (config.permission) {
        case 'zenit':
            Permission = PermissionZenit
            break
        case 'swisslinker':
            Permission = PermissionIam
            break
        default:
            Permission = PermissionErr
            break
    }
    let group = localStorage.getItem('ETradeGroups');
    //console.log('ETradeGroups====>', group)
    let ret = false;
    if (group) {
        let res = Permission.find(x => x.role === group);
        if (!res) {
            res = Permission.find(x => x.role === 'Manager');
            //res = {permissions: [{action: ['list'], resource: "*"}]};
        }
        if (res) {
            let permAll = res.permissions.find(x => x.resource === '*');
            let permRes = res.permissions.find(x => x.resource === resource);
            if (permRes) {
                if (permRes.action === '*' || permRes.action.indexOf(action) >= 0) ret = true;
            } else if (permAll) {
                if (permAll.action === '*' || permAll.action.indexOf(action) >= 0) ret = true;
            }
        }
    }
    return ret;

}

