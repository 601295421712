import * as React from 'react';
import {
  useLocale
} from 'react-admin';

import {
  Grid,
  Box,
  Paper
} from '@mui/material';

import useMyStyles from "../../utils/useMyStyles";

import {HTML5Backend} from 'react-dnd-html5-backend';
import {DndProvider, MouseTransition, TouchTransition} from 'react-dnd-multi-backend';
import {TouchBackend} from 'react-dnd-touch-backend';
import CategoriesDnd from '../../_common/CategoriesDnd';
import CategoriesTreeProvider from '../../_common/_providers/CategoriesTreeProvider';


const ProductsGroupList = ({...props}) => {
    const classes = useMyStyles();
    const {resource} = props;
    const locale = useLocale();


  const HTML5toTouchCustom = {
    backends: [
      {
        id: 'html5',
        backend: HTML5Backend,
        transition: MouseTransition,
        preview: true,
      },
      {
        id: 'touch',
        backend: TouchBackend,
        options: {
          enableMouseEvents: true,
          touchSlop: 5,
          delayTouchStart: 100,
        },
        preview: true,
        transition: TouchTransition,
      },
    ],
  };

    return (
        <Box mt={4}>
          <DndProvider options={HTML5toTouchCustom}>
            <CategoriesTreeProvider>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Paper sx={{maxHeight: 700, overflow: 'auto'}}>
                    <Box p={2}>
                      <CategoriesDnd/>
                    </Box>
                  </Paper>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Paper sx={{maxHeight: 700, overflow: 'auto'}}>
                    <Box p={2}>
                      <CategoriesDnd/>
                    </Box>
                  </Paper>
                </Grid>

              </Grid>
            </CategoriesTreeProvider>
          </DndProvider>
        </Box>
    );
}

export default ProductsGroupList;
