import * as React from 'react';

import {
    useTranslate,
} from 'react-admin';
import {Typography} from "@material-ui/core";

const SectionNameInEdit = ({name, mb=null, mt=null}) => {
    const translate = useTranslate();
    return (
        <Typography
            style={{
                marginTop: mt && mt,
                marginBottom: mb && mb,
            }}
            variant="h6"
            gutterBottom
        >{translate(name)}</Typography>
    )
};
export default SectionNameInEdit;
