import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    //AutocompleteInput,
    //ReferenceInput,
    useTranslate, useLocale,
    //SingleFieldList,
    ReferenceField, AutocompleteInput,
    ReferenceInput,
} from 'react-admin';

import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";

const transform = (data) => {
    data.product_group = data.product_group2;
    delete data.product_group2;
    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {product_id, productGroup, record} = props;
    const translate = useTranslate();
    const locale = useLocale();

    const [filterGr, setFilterGr] = useState([])

    const tbl = useSelector((state) => state.admin.resources.dProductToProductGroup)
    const {data = {}} = tbl;

    useEffect(() => {
        let dt = Object.values(data)
        if(dt.length > 0 ){
            let tmp = []
            if(productGroup) tmp = [productGroup]
            dt.forEach(it => {
                if(product_id === it.product) tmp.push(it.product_group)
            })
            setFilterGr(tmp)
        } else setFilterGr([productGroup])
    }, [data, productGroup]);// eslint-disable-line

    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    const matchSuggestion = (filter, choice) => {
        if(filterGr.indexOf(choice.id)>=0) {
            return false
        }
        else {
            return true
        }
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    product: product_id,
                    source2: localStorage.getItem('username'),
                    product_group2: record.product_group,
                }}
                transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <ReferenceInput
                    label={translate('Other Product Groups')}
                    source={'product_group2'}
                    reference={'dProductsGroup'}
                    //filter={{product_group: `-34`}}
                    validate={validateReq}
                >
                    <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                       variant={"outlined"} fullWidth
                                       matchSuggestion={matchSuggestion}
                    />
                </ReferenceInput>,

            </RowForm>
        </>
    )
};

const AdditionalProductsGroup = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {product_id, productGroup} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                //perPage={12}
                fullWidth
                label="Product To Product Group"
                reference="dProductToProductGroup"
                target="product"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              productGroup={productGroup} product_id={product_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            productGroup={productGroup} product_id={product_id}/>}
                    //rowStyle={postRowStyle}
                >
                    <ReferenceField source="product_group" reference="dProductsGroup"
                                    label={translate('Other Product Groups')} link={true}
                    >
                        <TextField source={`${'name'}[${locale}]`}/>
                    </ReferenceField>
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default AdditionalProductsGroup;