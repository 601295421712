import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    useTranslate,
    //useLocale,
    TextInput, BooleanInput, useGetList,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
// import {validateEmail} from "../utils/components/validateTable";
import {ImageInputComp, NumberInputFloatComp} from "../../_common/CompReact";
import {MyTranslatableInputsRich} from "../../_common/MyTranslatableInputsRich";
import Seo from "../../_common/Seo";
import {useEffect, useState} from "react";
import {getPromptForTable} from "../../_common/CompFormat/utils";

const BlockEditImg = props => {
    // const translate = useTranslate();
    //const {related_id} = props
    // const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box mb="1em" flex={1} display="flex" alignItems={"top"}>
                <Box flex={1} alignItems={"top"} mr="1em"
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'image_desktop'}
                        /*label={`${'New'} ${'image'}`}*/
                        label={'Image Desktop - used in the Desktop of the site'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Image Desktop</b> here (500kb max)</p>}
                    />
                </Box>

                <Box flex={1} alignItems={"top"} mr="1em"
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'image_mobile'}
                        // label={`${'New'} ${'image'}`}
                        label={'Image Mobile - used in the Mobile of the site'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Image Mobile</b> here (500kb max)</p>}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'image_tablet'}
                        // label={`${'New'} ${'image'}`}
                        label={'Image Tablet'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Image Tablet</b> here (500kb max)</p>}
                    />
                </Box>
            </Box>
        </Box>
    )
}
const BlockEdit = props => {
    const translate = useTranslate();
    const {promptData} = props
    //const locale = useLocale();
    // const formEdit = useForm();
    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source="show" label={translate('Show')} defaultValue={false}/>
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <NumberInputFloatComp
                        variant={"outlined"}
                        fullWidth
                        source="view_count"
                        label={translate('View Count')}
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'name'}
                        label={translate('Name')}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'title'}
                        label={translate('Title')}
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'url'}
                        label={translate('Url')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <TextInput
                        variant={"outlined"}
                        source={'uuid'}
                        label={translate('Uuid')}
                        fullWidth
                    />
                </Box>
            </Box>

            {/*<Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'meta_tag_description'}
                        label={translate('Meta tag description')}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'meta_tag_title'}
                        label={translate('Meta tag title')}
                    />
                </Box>
            </Box>*/}

            <Box display="flex" alignItems={"top"} mt={'-4em'}>
                <Box flex={1}>
                    <MyTranslatableInputsRich
                        prompt={promptData.find(x => x?.field === 'html_text')}
                        fullWidth
                        source={'html_text'}
                        label={translate('Html Text')}
                    />
                </Box>
            </Box>
        </Box>
    )
}
export const BlogArticleEditCreate = props => {//Не работает

    const translate = useTranslate();
    const {record, resource} = props;
    const {id} = record || {};

    const [promptData, setPromptData] = useState([]);
    const {data: dataPrompts, loading: isLoading} = useGetList(
        "dPrompts",
        {page: 1, perPage: 100},
        {field: 'id', order: 'ASC'},
    );
    useEffect(() => {
        if (dataPrompts) {
            let dt = getPromptForTable(dataPrompts, resource)
            //console.log('getPromptForTable============>', dt)
            setPromptData(dt)
        }
    }, [dataPrompts, isLoading]);// eslint-disable-line

    return (
        <Box>

            <TabbedForm
                {...props}
                //initialValues={{source: localStorage.getItem('username')}}
                defaultValue={{source: localStorage.getItem('username')}}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        related_id={id}
                        promptData={promptData}
                    />
                </FormTab>
                <FormTab label={translate('Media')}>
                    <BlockEditImg
                        {...props}
                        related_id={id}
                        promptData={promptData}
                    />
                </FormTab>
                {id &&
                    <FormTab label={translate('Seo')}>
                        <Seo
                            {...props}
                            resource={'dBlogArticle'}
                            id={id}
                            promptData={promptData}
                        />
                    </FormTab>
                }
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        prompt={promptData.find(x => x.field === 'description')}
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>
                {id &&
                    <FormTab label={translate('Prices')}>
                        <Box p="1em" display="flex" fullWidth>
                            <Prices
                                {...props}
                                product_id={id}
                            />
                        </Box>
                    </FormTab>
                }
                {id &&
                    <FormTab label={translate('Variants')}>
                        <Box p="1em" display="flex" fullWidth>
                            <Variants
                                {...props}
                                product_id={id}
                            />
                        </Box>
                    </FormTab>
                }*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default BlogArticleEditCreate
