import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    NumberField, useTranslate, Datagrid,
} from 'react-admin';
import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp} from "../../_common/CompReact";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {discount_property_id} = props;
    const translate = useTranslate();

    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    /*
    "id": 0,
      "product_id": 0,
      "order_quantity": 0,
      -"created_at": "2023-08-29T10:49:57.073Z",
      -"updated_at": "2023-08-29T10:49:57.073Z",
      -"discount_rule_id": 0,
      "discount": 0,
      "use_fix_price": true,
      "fix_price": 0,
      "fix_price_currency_id": 0
    */
    return (
        <>
            <RowForm
                {...props}
                initialValues={{discount_property_id: discount_property_id}}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>

                <ReferenceInput source="customer_id" reference="customer_z" label={translate('Customer')}
                                validate={validateReq}>
                    <AutocompleteInput resettable optionText="name" fullWidth/>
                </ReferenceInput>
                <ReferenceInput source="discount_property_value_id" reference="discountPropertyValue"
                                label={translate('Discount property value')}
                                filter={{discount_property_id: discount_property_id}}
                                validate={validateReq}>
                    <AutocompleteInput resettable optionText="name" fullWidth/>
                </ReferenceInput>
            </RowForm>
        </>
    )
};

const ColumnsForList = (resource, classes) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        customer_id:
            <ReferenceField source="customer_id" reference="customer_z" label={translate('Customer')}
                            link={true}
                            sortBy={'customer:name'}
                            cellClassName={classes.long_name}
            >
                <TextField source="name" />
            </ReferenceField>,
        discount_property_value_id:
            <ReferenceField source="discount_property_value_id" reference="discountPropertyValue"
                            label={translate('Discount property value')}
                            link={false}
                            sortBy={'discount_property_valid_value:name'}>
                <TextField source="name"/>
            </ReferenceField>,

        created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
    }
    return columns;
};

const CustomerPropertyValue = props => {
    // const {customer_id, record, isSave = true} = props;
    const {discount_property_id, dataProtected} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    // console.log('=======>', invoiceBooking);

    const columns = useSelectedColumns({
        preferences: `${'discount_property_value2'}list.columns`,
        columns: ColumnsForList('discount_property_value2', classes),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "fit-content", minWidth: 500, marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Customer Property Value"
                reference="customer_property"
                target="discount_property_id"
            >
                {dataProtected
                ?
                <Datagrid
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                >
                    {columns}
                </Datagrid>
                :
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false}
                                              discount_property_id={discount_property_id}/>}
                    editForm={<ListEditForm submitOnEnter={false}
                                            discount_property_id={discount_property_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
                }
            </ReferenceManyField>

        </div>
    )
}
export default CustomerPropertyValue;