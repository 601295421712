import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    TextInput,
    useTranslate,
    AutocompleteInput,
    ReferenceInput,
    //useLocale,
    ReferenceArrayInput,
    AutocompleteArrayInput, FormDataConsumer
} from 'react-admin';

import {Box, TextField, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";

import {validateEmail, validateNameRec, validateReq} from "../../_constants/validateTable";
import RelatedPartnerAddress from "./RelatedPartnerAddress";
import FootherEdit from "../../_common/Headers/FootherEdit";
import InputMask from "react-input-mask";
import {useForm} from "react-final-form";
//import {useEffect} from "react";


/*const MaskedInput = (props) => (
    <InputMask mask="+380(99) 999-99-99" {...props}>
        {(inputProps) => <TextInput {...inputProps} />}
    </InputMask>
);*/
const TextInputMask = (props) => {

    const {
        marginTop=0,
        variant = "outlined",
        source, formData, formEdit, label,
        validate = null
    } = props

    const [start, setStart] = React.useState(false);
    const handleChangeText = (event) => {
        setStart(true)
        formEdit.change(source, event.target.value)
    };

    return (
        <InputMask
            style={{marginTop: marginTop && marginTop}}
            mask="+380 (99) 999-99-99"
            fullWidth
            label={label}
            value={formData[source]}
            //name={'phone'}
            onChange={(event) => handleChangeText(event)}
            size={"small"}
            variant={variant}
            //error={form.partner.error?.phone}
            //InputProps={{classes: {input: classes.input1}}}
        >
            {(inputProps) =>
                <TextField
                    {...inputProps}
                    variant="outlined"
                    type="tel"
                    // disableUnderline
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    error={validate && start
                        ? !formData[source] || formData[source].indexOf('_') >= 0
                        : false
                    }
                    //helperText={hasError('phone') ? formState.errors.phone[0] : null}
                />}
        </InputMask>
    )
};
const BlockEditCreate = props => {

    // const locale = useLocale();
    //const formEdit = useForm();
    const translate = useTranslate();
    const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={'1em'}>
                    <TextInput source={`name`} label={translate('Name')}
                               variant={"outlined"}
                               validate={validateNameRec}
                               fullWidth/>
                </Box>
                <Box flex={1}>
                    <ReferenceInput
                        label={translate('Manager')}
                        source={'manager'}
                        reference={'dManager'}
                        //filter={{product: productId}}
                    >
                        <AutocompleteInput optionText={`${'name'}`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={'1em'}>
                    <TextInput source={`email`} label={translate('Email')}
                               variant={"outlined"}
                               validate={validateEmail}
                               fullWidth/>
                </Box>
                <Box flex={1}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            return (
                                <TextInputMask
                                    marginTop={8}
                                    source={`phone`}
                                    label={translate('Phone')}
                                    variant={"outlined"}
                                    formData={formData}
                                    formEdit={formEdit}
                                    validate={validateNameRec}
                                />
                            )
                        }}
                    </FormDataConsumer>
                    {/*<TextInput source={`phone`} label={translate('Phone')}
                               variant={"outlined"}
                               validate={validateNameRec}
                               fullWidth
                    />*/}
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <ReferenceArrayInput
                        label={translate('Customers')}
                        source="customers"
                        reference="zCustomers"
                        perPage={100}
                        validate={validateReq}
                        filter={{filter_customers_no_attached_to_partner: 'true'}}
                    >
                        <AutocompleteArrayInput optionText={`${'name'}`} resettable
                                                variant={"outlined"} fullWidth/>
                    </ReferenceArrayInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={'1em'}>
                    <ReferenceInput
                        label={translate('Sales representative SNG')}
                        source={'sales_representative_1'}
                        reference={'dManager'}
                        //filter={{product: productId}}
                    >
                        <AutocompleteInput optionText={`${'name'}`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}>
                    <ReferenceInput
                        label={translate('Sales representative Ino')}
                        source={'sales_representative_2'}
                        reference={'dManager'}
                        //filter={{product: productId}}
                    >
                        <AutocompleteInput optionText={`${'name'}`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"center"} mt={2}>
                <Box flex={2} mr={"1em"}>
                    <TextInput
                        source={`accounting_email`}
                        label={translate('Accounting email')}
                        variant={"outlined"}
                        validate={validateEmail}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            return (
                                <TextInputMask
                                    marginTop={-19}
                                    source={`accounting_phone`}
                                    label={translate('Accounting phone')}
                                    variant={"outlined"}
                                    formData={formData}
                                    formEdit={formEdit}
                                />
                            )
                        }}
                    </FormDataConsumer>
                    {/*<TextInput
                        source={`accounting_phone`}
                        label={translate('Accounting phone')}
                        variant={"outlined"}
                        fullWidth
                    />*/}
                </Box>
                <Box flex={1}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            return (
                                <TextInputMask
                                    marginTop={-19}
                                    source={`warehouse_phone`}
                                    label={translate('Warehouse phone')}
                                    variant={"outlined"}
                                    formData={formData}
                                    formEdit={formEdit}
                                />
                            )
                        }}
                    </FormDataConsumer>
                    {/*<TextInput
                        source={`warehouse_phone`}
                        label={translate('Warehouse phone')}
                        variant={"outlined"}
                        fullWidth
                    />*/}
                </Box>
            </Box>
        </Box>
    )
}
export const PartnerEditCreate = props => {//Не работает
    // const classes = useMyStyles();
    const {record} = props;
    const {id} = record;
    const translate = useTranslate();
    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)

    return (
        <Box fullWidth>
            <TabbedForm
                {...props}
                /*toolbar={<PostEditDeleteActions goBack={true} saveAndClose={'/invoice'} reload={true}/>}*/
                toolbar={<PostEditDeleteActions
                    // goBack={true}
                    saveAndClose={id ? '' : '/partner'}
                    //reload={true}
                />
                }
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEditCreate {...props}/>
                </FormTab>
            </TabbedForm>
            <Box p="1em" fullWidth>
                {id &&
                    <>
                        <Typography variant="h6">{translate('Partners delivery addresses')}</Typography>
                        <Box display="flex" alignItems={"center"}>
                            <RelatedPartnerAddress
                                {...props}
                                relaited_id={id}
                                formData={{}}
                                record={record}
                                isSave={false}
                            />
                        </Box>
                    </>
                }
            </Box>
            {id && <FootherEdit source={record.source}/>}
        </Box>
    )
};

export default PartnerEditCreate
