import * as React from 'react';

import {
    Datagrid,
} from 'react-admin';
import useMyStyles from "../utils/useMyStyles";

/*const postRowStyle = (record, index) => ({
    backgroundColor: record.content !== '' ? '#efe' : 'red',
    color: "red",
});*/


const DatagridComponentAdmin = (props) => {
    const {children} = props;
    const classes = useMyStyles();
    return (
        <Datagrid {...props}
            classes={{
                headerCell: classes.headerCell,
                row: classes.row,
                rowCell: classes.rowCell
        }}
            //rowStyle={postRowStyle}
                  /*sx={{
                      '& .RaDatagrid-headerCell-80': {
                          backgroundColor: '#00ff00',
                          fontWeight: 'bold',
                          color: 'ff0000',
                      },
                      '& td.column-content': {backgroundColor: '#fee', color: '#ff0000'},
                      '& .RaDatagrid-rowCell':{backgroundColor: '#fee'}
                  }}*/
        >
            {children}
        </Datagrid>
    )
};
export default DatagridComponentAdmin;
