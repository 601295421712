import DefaultTable from '../DefaultTable/DefaultClass'
import {JustBool, JustText} from "../tablePattern";

class Delivery extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                name: 'Currencies',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Currencies', optionText: 'name', locale: true},
            },
        ]


        this.filters_aside = []

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]


        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            JustText('name'),
                            JustText('item_code_in_accounting_system'),
                        ]
                    },
                   {
                        box: [
                            JustText('time_dispatch'),
                            JustText('minimum_free_shipping'),
                            JustBool('free_shipping'),
                        ]
                    },
                   {
                        box: [
                            JustText('weight'),
                            JustText('quantity'),
                            JustText('control_time'),
                            JustBool('hidden_for_user'),
                        ]
                    },
                    {
                        box: [
                            JustText('delivery_service'),
                            JustText('storage'),//'DeliveryWarehouse'),
                            JustText('company_branch'),
                            JustText('delivery_type'),
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]
    }
}

export default Delivery