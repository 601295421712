import * as React from 'react';
import {
    Create, useRedirect,
} from 'react-admin';

import CountriesEditCreate from "./CountriesEditCreate";
import PostEditActions from "../../_common/PostEditActions";

export const CountriesCreate = props => {

    const {location, resource} = props;
    const {search} = location;
    const redirect = useRedirect()

    let idSource = '';
    //let bookingId = '';
    if(search){
        let url = new URL('https://test/' + search);
        idSource = url.searchParams.get('idSource')
    }

    const onSuccessData = (data) => {
        //notify(`Changes saved`);
        redirect(`/${resource}/${data.data.id}`);
    };

    return (
        <Create
            {...props}
            title={''}
            //onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            //onSuccess={() => props.history.goBack()}
            onSuccess={onSuccessData}
            actions={<PostEditActions/>}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <CountriesEditCreate idSource={idSource}/>
        </Create>
    )
};