import ProductIcon from '@material-ui/icons/Collections';

import ProductList from "./ProductList";
import {ProductCreate} from "./ProductCreate";
import {ProductEdit} from "./ProductlEdit";

const resource = {
    list: ProductList,
    create: ProductCreate,
    edit: ProductEdit,
    icon: ProductIcon,
};
export default resource;
