import DefaultTable from '../DefaultTable/DefaultClass'
import {commonTrueFalse} from "../../_constants/choices";

class ProductsGroup extends DefaultTable {
    constructor(props) {
        super(props)

                this.filters_top = [
            {
                name: 'parent',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'ProductsGroup', optionText: 'name', locale: true},
            },
        ]

        this.filters_aside = [
            {
                name: 'hidden_for_user',
                type: "TrueFalseFilters",
                parameters: [],
            },
            {
                name: 'show_on_filter',
                type: "TrueFalseFilters",
                parameters: [],
            },
            {
                name: 'show_on_main',
                type: "TrueFalseFilters",
                parameters: [],
            },
            {
                name: 'show_on_menu',
                type: "TrueFalseFilters",
                parameters: [],
            },
        ]
        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]

        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'image',
                                type: 'img',
                                show_type: 'ImageField',
                                edit_type: 'ImageInput',
                                type_ext: {widthImg: 64},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'name',
                                type: 'text',
                                show_type: 'TextFieldBold',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                            {
                                name: 'title',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'parent',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'ProductsGroup', optionText: 'name', locale: true},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                            {
                                name: 'search_phrase',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                //type_ext: {locale: true},
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'HTML',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                            {
                                name: 'url_repr',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'breadcrumbs_name',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                            {
                                name: 'description',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'hidden_for_user',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'BooleanInput',
                                type_ext: {choices: commonTrueFalse, reference: 'Brands', width: '3em'},
                                read_only: false
                            },
                            {
                                name: 'show_on_filter',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'BooleanInput',
                                type_ext: {choices: commonTrueFalse, reference: 'Brands', width: '3em'},
                                read_only: false
                            },
                            {
                                name: 'show_on_main',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'BooleanInput',
                                type_ext: {choices: commonTrueFalse, reference: 'Brands', width: '3em'},
                                read_only: false
                            },
                            {
                                name: 'show_on_menu',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'BooleanInput',
                                type_ext: {choices: commonTrueFalse, reference: 'Brands', width: '3em'},
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'column_on_main',
                                type: 'bool',
                                // show_type: 'NumberField',
                                show_type: 'EditFieldFromList',
                                type_ext: {width: "50px"},
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                            {
                                name: 'index',
                                type: 'bool',
                                //show_type: 'NumberField',
                                show_type: 'EditFieldFromList',
                                type_ext: {width: "50px"},
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                            {
                                name: 'order_on_filter',
                                type: 'bool',
                                //show_type: 'NumberField',
                                show_type: 'EditFieldFromList',
                                type_ext: {width: "50px"},
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                            {
                                name: 'order_on_main',
                                type: 'bool',
                                //show_type: 'NumberField',
                                show_type: 'EditFieldFromList',
                                type_ext: {width: "50px"},
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                            {
                                name: 'price_rows_count',
                                type: 'bool',
                                //show_type: 'NumberField',
                                show_type: 'EditFieldFromList',
                                type_ext: {width: "50px"},
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'uuid',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                //type_ext: {locale: true},
                                read_only: false
                            },
                        ]
                    },
                ]
            },
            {
                tab: "META",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'meta_desc',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                            {
                                name: 'meta_keywords',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                            {
                                name: 'meta_title',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]

        /*this.related_table = [
            {
                name: 'BrandsReplacement',
                filters: 'brand',
            },
        ]*/

    }
}

export default ProductsGroup