import * as React from 'react';
import {
    // FilterLiveSearch,
    NumberField, TextField,
    useLocale,
    useTranslate, ReferenceField, ReferenceInput, AutocompleteInput,
} from 'react-admin';

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import useMyStyles from "../../utils/useMyStyles";
// import {universalTopFilters} from "../universalView/universalTopFilters";
// import {CardContent, useMediaQuery} from "@material-ui/core";
import {CardContent} from "@material-ui/core";
import CardComp from "../../_common/Asside/CardComp";
import {
    DateFieldComp,
    FilterDataAside,
    //NumberInputFloatComp,
} from "../../_common/CompReact";
import {validateReq} from "../../_constants/validateTable";

const universalTopFilters = (resource, locale) => {
    let dt = [];
    dt.push(<ReferenceInput source="user" reference="dUser" alwaysOn>
        <AutocompleteInput
            optionText={`username`}
            resettable emptyText={'All Users'}/>
    </ReferenceInput>);
    dt.push(<ReferenceInput source="interface" reference="dInterfaces" alwaysOn>
        <AutocompleteInput
            optionText={`name[${locale}]`}
            resettable emptyText={'All Interfaces'}/>
    </ReferenceInput>);
    // dt.push(<TextInput label="Search" source="q" alwaysOn/>)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!"/>);
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch/>*/}
                {/*<FilterDataAside fields={'created_at'} name={'create'}/>*/}
                <FilterDataAside fields={'updated'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};

const ListEditForm = (props) => {
    const {classes, locale} = props;
    // const {classes} = props;
    const translate = useTranslate();
    return (
        <RowForm {...props}>
            <NumberField source="id" headerClassName={classes.headerShortWidth}/>
            <ReferenceInput source="user" reference="dUser" label={translate('User')}
                            validate={validateReq}>
                <AutocompleteInput resettable optionText="username" fullWidth/>
            </ReferenceInput>
            <ReferenceInput source="interface" reference="dInterfaces" label={translate('Interface')}
                            validate={validateReq}>
                <AutocompleteInput resettable optionText={`name[${locale}]`} fullWidth/>
            </ReferenceInput>

            <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'}
                           locale={'us'} cellClassName={classes.date_st}/>
        </RowForm>
    )
};

/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/

export const UsersInterfaces = props => {
    const classes = useMyStyles();
    const {resource} = props;

    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();
    const translate = useTranslate();
    return (
        <ListComponentAdmin
            {...props}
            //hasCreate={true}
            style={{maxWidth: '100em'}}
            title={''}
            actions={<TableActions
                preference={`${resource}${".list.columns"}`}
                select={false}
                create={true}
                filter={true}
            />}
            // is_bulkActionButtons={false}
            filters={universalTopFilters(resource, locale)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
        >
            <EditableDatagrid
                classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                //noDelete={true}
                // initialRowMode="edit"
                rowClick="edit"
                mutationMode="pessimistic" //Подтвердить удаление
                undoable
                createForm={<ListEditForm locale={locale} classes={classes} {...props} submitOnEnter={false}/>}
                editForm={<ListEditForm locale={locale} classes={classes} {...props} submitOnEnter={true}/>}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <ReferenceField source="user" reference="dUser"
                                label={translate('User')} link={false}
                >
                    <TextField source={`username`}/>
                </ReferenceField>
                <ReferenceField source="interface" reference="dInterfaces"
                                label={translate('Interface')} link={false}
                >
                    <TextField source={`name[${locale}]`}/>
                </ReferenceField>
                <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'}
                               locale={'us'} cellClassName={classes.date_st}/>
            </EditableDatagrid>
        </ListComponentAdmin>
    )
};