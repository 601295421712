import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    useTranslate,
    AutocompleteInput, ReferenceInput, useLocale, TextInput, BooleanInput, useGetList,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
// import {validateEmail} from "../utils/components/validateTable";
import {ImageInputComp, NumberInputFloatComp} from "../../_common/CompReact";
import {MyTranslatableInputsRich} from "../../_common/MyTranslatableInputsRich";
import Seo from "../../_common/Seo";
import {useEffect, useState} from "react";
import {getPromptForTable} from "../../_common/CompFormat/utils";

const BlockEdit = props => {
    const translate = useTranslate();
    //const {related_id} = props
    const {record, promptData} = props;
    const {reserved} = record;
    const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} alignItems={"top"} mr={'1em'}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'image'}
                        // label={`${'New'} ${'image'}`}
                        label={'Image'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Image</b> (500kb max)</p>}
                    />
                </Box>
                <Box flex={2} display={'grid'}>
                    <Box flex={1} display={'flex'}>
                        <Box flex={1} mr={'1em'}>
                            <BooleanInput source="archive" label={translate('Archive')} defaultValue={false}/>
                        </Box>
                        <Box flex={1} mr={'1em'}>
                            <BooleanInput source="show_in_header" label={translate('Show in header')}
                                          defaultValue={false}/>
                        </Box>
                        <Box flex={1}>
                            <BooleanInput source="show_in_footer" label={translate('Show in footer')}
                                          defaultValue={false}/>
                        </Box>
                    </Box>
                    <Box flex={1}>
                        <MyTranslatableInputs
                            fullWidth
                            source={'name'}
                            label={translate('Name')}
                        />
                    </Box>
                    <Box flex={1}>
                        <MyTranslatableInputs
                            fullWidth
                            source={'title'}
                            label={translate('Title')}
                        />
                    </Box>
                    <Box flex={1} display={'flex'}>
                        <Box flex={2} mr={'1em'}>
                            <ReferenceInput
                                label={translate('Parent')}
                                source={'parent'}
                                reference={'dMenuItems'}
                                //filter={{country: id}}
                            >
                                <AutocompleteInput optionText={`name[${locale}]`} resettable
                                                   variant={"outlined"} fullWidth/>
                            </ReferenceInput>
                        </Box>
                        <Box flex={1}>
                            <NumberInputFloatComp
                                variant={"outlined"}
                                fullWidth
                                source="priority"
                                label="priority"
                            />
                        </Box>
                    </Box>
                    <Box flex={1} display={'flex'}>
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            source={'jump_link'}
                            label={translate('Jump Link')}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        {reserved
                            ?
                            <Box style={{height: 32}}>
                                <span>{translate('Reserved')}: <b>{reserved}</b></span>
                            </Box>
                            :
                            <MyTranslatableInputs
                                fullWidth
                                slug={'name'}
                                source={'url'}
                                label={translate('Url')}
                            />
                        }
                    </Box>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"} mt={'1em'}>
                <Box flex={1}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'commentary'}
                        label={translate('Commentary')}
                    />
                </Box>
            </Box>
            <Box display="flex" alignItems={"top"} mt={'-4em'}>
                <Box flex={1}>
                    <MyTranslatableInputsRich
                        prompt={promptData.find(x => x.field === 'HTML')}
                        fullWidth
                        source={'HTML'}
                        label={translate('HTML')}
                    />
                </Box>
            </Box>

        </Box>
    )
}
export const MenuItemsEditCreate = props => {//Не работает

    const translate = useTranslate();
    const {record, resource} = props;
    const {id} = record || {};

    const [promptData, setPromptData] = useState([]);
    const {data: dataPrompts, loading: isLoading} = useGetList(
        "dPrompts",
        {page: 1, perPage: 100},
        {field: 'id', order: 'ASC'},
    );

    useEffect(() => {
        if (dataPrompts) {
            let dt = getPromptForTable(dataPrompts, resource)
            setPromptData(dt)
        }
    }, [dataPrompts, isLoading]);// eslint-disable-line


    return (
        <Box>

            <TabbedForm
                {...props}
                //initialValues={{source: localStorage.getItem('username')}}
                defaultValue={{source: localStorage.getItem('username')}}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        related_id={id}
                        promptData={promptData}
                    />
                </FormTab>
                {id &&
                    <FormTab label={translate('Seo')}>
                        <Seo
                            {...props}
                            resource={'dMenuItems'}
                            id={id}
                            promptData={promptData}
                        />
                    </FormTab>
                }
                {/*<FormTab label={translate('Media')}>
                    <BlockEditImg
                        {...props}
                        related_id={id}
                        //promptData={promptData}
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default MenuItemsEditCreate
