import * as React from 'react';
import {AppBar} from 'react-admin';
import {Box, Typography, useMediaQuery} from '@material-ui/core';
import {LanguageSwitcher} from "@react-admin/ra-preferences";
import config, {localesConfig} from "../utils/config";
import {useEffect} from "react";
// import ScanDialog from "../ScanDialog";

const styles = {
    fontXs: {fontSize: 14, lineHeight: 1.3},
    font: {},
};

let timer = 0;
const MyAppBar = props => {
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    useEffect(() => {
        let div = document.getElementById("react-admin-title");
        if (div) {
        clearInterval(timer)
        setInterval(() => {
            // console.log('===MyAppBar==========>', div.textContent)
            document.title = div.textContent
        }, 2000);
    }
    }, []);// eslint-disable-line

    return (
        <AppBar {...props}>
            <Box flex="1">
                <Typography
                    style={isSmall ? styles.fontXs : styles.font}
                    variant="h6"
                    id="react-admin-title"
                >
                    {config.permission === 'zenit' ? 'iAM-Zenit' : 'iAM-Trade'}{' '}
                </Typography>
            </Box>
            {/*<ScanDialog />*/}
            <LanguageSwitcher
                languages={localesConfig.languages}/>
        </AppBar>
    )
};

export default MyAppBar
