import {apiPathConfig} from "./config";
import config from "../utils/config";

const authProvider = {
    // called when the user attempts to log in
    login: async ({username, password}) => {
        let requestUrl = `${config.apiAuthUrl}api/react-admin/login`

        let amazon = 'https://sam-deployment-swiss-linker-localizations3bucket-zcduq6xfnwwe.s3.eu-central-1.amazonaws.com/admin_mapping_config.json'
        const response = await fetch(amazon)
        const host = document.location.hostname
        const res = await response.json()
        console.log('adminMappingConfig====>', res)
        if (res && res[`${host}`]) {
            // requestUrl = res[`${host}`] + '/api/e-trade/api/react-admin/login'
            requestUrl = res[`${host}`] + '/api-public/e-trade/api/react-admin/login'
            localStorage.setItem('adminMappingConfig', JSON.stringify({
                apiUrl: res[`${host}`],
                apiAuthUrl: res[`${host}`]
            }));
        }

        const request = new Request(requestUrl, {
            method: 'POST',
            body: JSON.stringify({username, password}),
            headers: new Headers({'Content-Type': 'application/json'}),
        });

        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(key => {
                console.log('AUTH====>', key)
                //debugger
                localStorage.setItem('auth', JSON.stringify(key));
                localStorage.setItem('username', username);
                const {interface: group, is_superuser} = key
                if (is_superuser) localStorage.setItem('ETradeGroups', 'Superuser');
                else if (group === 'default') localStorage.setItem('ETradeGroups', 'Manager');
                else {
                    // localStorage.setItem('ETradeGroups', 'Manager');
                    localStorage.setItem('ETradeGroups', group);
                }
                window.location.reload()
                // const refresh = useRefresh();
                // refresh()
            })
            .catch(() => {
                throw new Error('Network error')
            });


    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('username');
        localStorage.removeItem('ETradeGroups');
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({status}) => {
        //debugger
        console.log('status authProvider===> ', status)
        if (status === 401 || status === 403) {
            localStorage.removeItem('username');
            localStorage.removeItem('ETradeGroups');
            localStorage.removeItem('auth');
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        if (!apiPathConfig.getAuth()) return Promise.resolve();
        return localStorage.getItem('username')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    // getPermissions: () => Promise.resolve(),
    getPermissions: () =>
        Promise.resolve({
            permissions: [
                {action: ["read", "write"], resource: "*"},
            ],
            roles: ["reader"],
        }),
    getRoles: () =>
        Promise.resolve({
            admin: [{action: "*", resource: "*"}],
            reader: [{action: "*", resource: "*"}],
        }),
};

export default authProvider;