import * as React from 'react';
import {Box, Button} from '@mui/material';
// import {Avatar} from "@material-ui/core";
import CommentIcon from "@material-ui/icons/Comment";

import {Link} from 'react-router-dom';
import {
    ListBase,
    //WithListContext,
    SimpleList, TextField, useLocale,
    useTranslate,
} from 'react-admin';
import {subDays} from 'date-fns';

import CardWithIcon from './CardWithIcon';
import {stringify} from 'query-string';
import {Total} from "./DashboardInfo";


const OriNewCustomers = () => {
    const translate = useTranslate();
    const locale = useLocale();

    const aMonthAgo = subDays(new Date(), 30);
    aMonthAgo.setDate(aMonthAgo.getDate() - 30);
    aMonthAgo.setHours(0);
    aMonthAgo.setMinutes(0);
    aMonthAgo.setSeconds(0);
    aMonthAgo.setMilliseconds(0);

    return (
        <ListBase
            resource="dProducts"
            basePath={'/dProducts'}
            filter={{
                is_populate: true,
                //update_gte: aMonthAgo.toISOString(),
            }}
            sort={{field: 'updated', order: 'DESC'}}
            perPage={24}
            disableSyncWithLocation
        >
            <CardWithIcon
                to={{
                    pathname: '/dProducts',
                    search: stringify({
                        filter: JSON.stringify({is_populate: true}),
                    }),
                }}
                //to="/#/dCustomers"
                icon={CommentIcon}
                title={translate('Populate Products')}
                subtitle={
                    /*<WithListContext render={({ total }) => <>{total}</>} />*/
                    <Total/>
                }
            >
                <SimpleList
                    primaryText={
                        <>
                            <TextField source={`article`}/>
                            {' ('}<TextField source={`brand[${locale}]`}/>{')'}
                        </>
                    }
                    secondaryText={<TextField source={`name[${locale}]`}/>}
                    /*leftAvatar={product => (
                        <Avatar
                            variant="rounded"
                            src={`${product.avatar}?size=32x32`}
                            alt={`${product.name[locale]}`}
                            sx={{
                                bgcolor: 'background.paper',
                            }}
                        />
                    )}*/
                />
                <Box flexGrow={1}>&nbsp;</Box>
                <Button
                    sx={{borderRadius: 0}}
                    component={Link}
                    to="/dProducts"
                    size="small"
                    color="primary"
                >
                    <Box p={1} sx={{color: 'primary.main'}}>
                        {translate('All Products')}
                    </Box>
                </Button>
            </CardWithIcon>
        </ListBase>
    );
};

export default OriNewCustomers;
