import DefaultTable from '../DefaultTable/DefaultClass'
import {JustRef, JustText} from "../tablePattern";

class CustomerPhone extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                name: 'Currencies',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Currencies', optionText: 'name', locale: true},
            },
        ]


        this.filters_aside = []

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]


        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            JustText('phone_number'),
                            JustText('description'),
                        ]
                    },
                    {
                        box: [
                            JustRef('customer','',1,'name',false),
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]
    }
}

export default CustomerPhone