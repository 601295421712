import * as React from 'react';
import {
    ListItem,
    ListItemSecondaryAction,
    ListItemAvatar,
    ListItemText,
    //Avatar,
    Box,
} from '@mui/material';
import {Link} from 'react-router-dom';
import {
    // useTranslate,
    useReference,
    useGetManyReference, useLocale
} from 'react-admin';

import moment from "moment";
import {Avatar} from "@material-ui/core";
// import {useGetManyReference} from "ra-core";
import {formatSpacerNumber} from "../_common/CompFormat/utils";

export const PendingOrder = (props) => {
    const {order} = props;
    const locale = useLocale();
    // const translate = useTranslate();
    const {referenceRecord: customer, loaded} = useReference({
        reference: 'Customers',
        id: order.customer,
    });
    const {referenceRecord: status, loaded: loadedStatus} = useReference({
        reference: 'OrderStatus',
        id: order.status,
    });
    const {data: dataItems, loaded: loadedItems, total: totalItems} = useGetManyReference(
        'OrderItems',
        'order',
        order.id,
        {},
        {},
        {},
        // pagination: { page: 1, perPage: 10 },
        // sort: { field: 'published_at', order: 'DESC' }
    );
    /*const {data: dataItems, loaded: loadedItems, total: totalItems} = useGetList('OrderItems',
        {page: 1, perPage: 99},
         {field: 'updated', order: 'DESC'},
         {order: order.id},
        //{},
    );*/

    let primary = '';
    let sell = 0;
    let secondary = ''
    if (loaded) primary = moment(customer.updated).format("D-MMM-YY, h:mm\u00a0a")
    if (loadedStatus) primary += ' (' + status.name[locale] + ')'
    if (loadedItems) {
        Object.values(dataItems).forEach((x, index) => {
            sell += x.quantity * x.price_sell
        })
    }
    if (loaded) secondary = `by ${customer.name}, ${totalItems} items`
    //console.log(order.id,'OrderItems=========>', OrderItems)

    return (
        <ListItem button component={Link} to={`/dOrders/${order.id}`}>
            <ListItemAvatar>
                <Avatar
                    src={`${customer?.avatar}?size=32x32`}
                    sx={{bgcolor: 'background.paper'}}
                    alt={`${customer?.name}`}
                />
            </ListItemAvatar>
            <ListItemText
                //primary={new Date(order.update).toLocaleString('en-GB')}
                primary={primary}
                secondary={secondary}
                /*secondary={translate('pos.dashboard.order.items', {
                    smart_count: order.basket.length,
                    nb_items: order.basket.length,
                    customer_name: customer
                        ? `${customer.name}`
                        : '',
                })}*/
            />
            <ListItemSecondaryAction>
                <Box
                    component="span"
                    sx={{
                        marginRight: '1em',
                        color: 'text.primary',
                    }}
                >
                    {formatSpacerNumber(sell)} $
                </Box>
            </ListItemSecondaryAction>
        </ListItem>
    );
};
