import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    useTranslate,
    BooleanInput, SelectInput, TextInput,
} from 'react-admin';
import {Box} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
import FootherEdit from "../../_common/Headers/FootherEdit";
import {validateReq} from "../../_constants/validateTable";
import {choicesPaymentSystemCode} from "../../_constants/choices";

//import {JsonInput as RAJsonInput} from "react-admin-json-view";


const BlockEdit = props => {
    const translate = useTranslate();
    // const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"center"}>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput
                        source={'is_active'} label={translate('Active')}
                        defaultValue={false}
                        //fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <SelectInput
                        variant={"outlined"}
                        source={'payment_system_code'}
                        choices={choicesPaymentSystemCode}
                        validate={validateReq}
                        label={translate('Payment system code')} resettable fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"center"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        validate={validateReq}
                        variant={"outlined"}
                        source={'name'}
                        label={translate('Name')}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        source={'public_name'}
                        label={translate('Public name')}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        multiline={true}
                        source={'description'}
                        label={translate('Description')}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        multiline={true}
                        source={'auth_data'}
                        label={translate('Auth data')}
                        fullWidth
                    />
                    {/*<RAJsonInput
                        source={'auth_data'}
                        // validate={[required()]}
                        jsonString={false} // Set to true if the value is a string, default: false
                        reactJsonOptions={{
                            // Props passed to react-json-view
                            name: 'jkjdkfjkdfj',
                            collapsed: false,
                            enableClipboard: true,
                            displayDataTypes: false,
                        }}
                    />*/}
                </Box>
            </Box>
        </Box>
    )
}
export const PaymentProviderEditCreate = props => {//Не работает
    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                    />
                </FormTab>
            </TabbedForm>

            {id && <FootherEdit source={record.source}/>}

        </Box>
    )
};

export default PaymentProviderEditCreate
