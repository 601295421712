import * as React from 'react';
import {Box, makeStyles, Typography} from "@material-ui/core";
import {useNotify, useTranslate} from "react-admin";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

const useStyles = makeStyles(theme => ({
    clLink: {
        color: "blue",
        '&:hover': {
            textDecoration: 'underline',
            cursor: 'pointer',
        }
    },
    clNoLink: {color: "black"},
}))

const List = props => {
    const {
        dataForAlarm,
        setDataForAlarm,
        handleSetForm,
    } = props

    const classes = useStyles();
    const translate = useTranslate()

    const {
        resource,
        name_for_save,
        label,
        data = [],
        snap,
        index,
        name,
        value,
    } = dataForAlarm || {}

    if (resource === 'user' || resource === 'partner' || resource === 'customers')
        return (
            <Box display={'grid'}>
                <Box style={{marginBottom: 16}} display={'grid'}>
                    <Typography variant="body" color="textPrimary">
                        <b>{label}</b>
                    </Typography>
                    {name_for_save &&
                        <Typography variant="body" color="textPrimary">
                            <b>{translate('Select from list')}</b>
                        </Typography>
                    }
                </Box>
                {data.map((it, ind) => {
                    let name
                    switch (resource) {
                        case 'user':
                            name = it.email
                            break
                        case 'partner':
                            name = `${it.name} / ${it.email} / ${it.phone}`
                            break
                        case 'customers':
                            name = `${it.name} / ${it.client_code} / ${it.edrpou} / ${it.item_code_in_accounting_system}`
                            break
                        default:
                            name = it.id
                            break
                    }
                    return (
                        <a
                            className={name_for_save ? classes.clLink : classes.clNoLink}
                            // style={{cursor: name_for_save && "pointer"}}
                            key={it.id}
                            onClick={(event) => {
                                if (name_for_save) {
                                    setDataForAlarm(null)
                                    handleSetForm(snap, index, name_for_save, it)
                                }
                            }}
                        >
                            {ind + 1}. {name}
                        </a>
                    )
                })
                }
            </Box>
        )
    else return null
}
export const AlarmDialog = props => {

    const {
        dataForAlarm,
        setDataForAlarm,
        handleSetForm,
    } = props

    const {
        resource,
        name_for_save,
        label,
        data = [],
        snap,
        index,
        name,
        value,
    } = dataForAlarm || {}

    const translate = useTranslate();
    const notify = useNotify();

    // console.log('ALARM=====>', data)

    return (
        <Dialog
            open={dataForAlarm}
            onClose={(event) => {
                setDataForAlarm(null)
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {`${value}`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{width: 480}}>
                    <Box display={'grid'}>
                        <List
                            handleSetForm={handleSetForm}
                            dataForAlarm={dataForAlarm}
                            setDataForAlarm={setDataForAlarm}
                        />
                    </Box>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={(event) => {
                        setDataForAlarm(null)
                    }}
                    color="primary" autoFocus>
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog>
    )
};