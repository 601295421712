import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    NumberField,
    useTranslate,
    ReferenceArrayField,
    SingleFieldList,
    ChipField,
    TextInput,
    AutocompleteArrayInput,
    ReferenceArrayInput, BooleanInput, FormDataConsumer, SelectInput, AutocompleteInput,
} from 'react-admin';
/*import {
    RowForm,

} from "@react-admin/ra-editable-datagrid";*/
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp, NumberInputFloatComp} from "../../_common/CompReact";

// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateNameRec, validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
import {Box} from "@mui/material";
import CroppedLongField from "../../_common/CroppedLongField";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {commonTrueFalse} from "../../_constants/choices";
import Dialog from "@material-ui/core/Dialog";
// import {nominatimOpenStreetMap, nominatimOpenStreetMapLatLon} from "../../_common/CompForApi/utilsAPI";
import {nominatimOpenStreetMap} from "../../_common/CompForApi/utilsAPI";
import {useEffect, useMemo, useState} from "react";
import {getRequestOverpass} from "../../_constants/requestOverpass";
import {useForm} from "react-final-form";
import {Button, Typography} from "@material-ui/core";
import {LinearProgress} from "@mui/material";
import RowFormCustom from "../../_common/DependenciesComp/RowFormCustom";
import {canAccess2} from "../../utils/canAccess2";

const transform = (data) => {
    console.log('Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.name = data.name2;
    data.customers = data.customers2;

    data.flat = data.flat ? data.flat : ''
    data.comment = data.comment ? data.comment : ''
    data.placement = data.placement ? data.placement : ''


    if (data.auto_address) {
        data.area = data.area_st;
        data.region = data.region_st;
        data.point = data.point_st;
        data.street = data.street_st;
    } else {
        data.area = data.area_hand;
        data.region = data.region_hand;
        data.point = data.point_hand;
        data.street = data.street_hand;
    }

    delete data.customers2;
    delete data.name2;

    delete data.area_st;
    delete data.region_st;
    delete data.point_st;
    delete data.street_st;

    delete data.area_hand;
    delete data.region_hand;
    delete data.point_hand;
    delete data.street_hand;

    return data;
}

const ListEditForm = (props) => {//https://overpass-turbo.eu/index.html
    //const classes = useMyStyles();
    const {relaited_id, record} = props;
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    return (
        <Dialog
            //fullWidth={true}
            scroll={'body'}
            classes={{
                paper: 'modal_with_product_list',
            }}
            maxWidth={'lg'}
            // onClose={handleDialogCloseCart}
            open={true}
        >
            <RowFormCustom
                {...props}
                hasBulkActions={false}
                initialValues={{
                    partner: relaited_id,
                    name2: record.name,
                    customers2: record.customers,

                    area_st: record?.auto_address && record?.area ? record?.area : '',
                    region_st: record?.auto_address && record?.region ? record?.region : '',
                    point_st: record?.auto_address && record?.point ? record?.point : '',
                    street_st: record?.auto_address && record?.street ? record?.street : '',

                    area_hand: record?.area ? record?.area : '',
                    region_hand: record?.region ? record?.region : '',
                    point_hand: record?.point ? record?.point : '',
                    street_hand: record?.street ? record?.street : '',

                    area: '',
                    region: '',
                    point: '',
                    street: '',

                    //house: '',
                    //flat: '',
                }}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
                transform={transform}
            >
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                        return (
                            <ListEditForm2 {...props} relaited_id={relaited_id} formData={formData} record={record}/>
                        )
                    }}
                </FormDataConsumer>
            </RowFormCustom>
        </Dialog>
    )
}
const ListEditForm2 = (props) => {
    // const classes = useMyStyles();
    const {formData, relaited_id, record} = props;

    const formEdit = useForm();

    const translate = useTranslate();
    const [areas, setAreas] = useState([]);
    const [regions, setRegions] = useState([]);
    const [points, setPoints] = useState([]);
    const [pointsFromArea, setPointsFromArea] = useState([]);
    const [streets, setStreets] = useState([]);
    const [processing, setProcessing] = useState(false);
    const [iniAddress, setIniAddress] = useState(0);
    const [alarmMessage, setAlarmMessage] = useState(false);


    const arrAddress = ['street_st', 'house', 'flat', 'point_st', 'region_st', 'area_st', 'placement']
    const arrAddressHand = ['street_hand', 'house', 'flat', 'point_hand', 'region_hand', 'area_hand', 'placement']

    async function getShortData(type, req, setData) {
        setProcessing(true)
        let res = await nominatimOpenStreetMap(req)
        setProcessing(false)
        if (res) {
            let arr = []
            res.forEach(it => {
                // if (it.tags?.highway !== "trunk")
                let name = it.tags.old_name ? `${it.tags.name}(${it.tags.old_name})` : it.tags.name
                // name = it.lat ? name : `${name} (-)`
                if (arr.find(x => x.tags.name === it.tags.name)) {

                } else {
                    arr.push({
                        id: it.id,
                        lat: it?.lat,
                        lon: it?.lon,
                        name: name,
                        name_ori: it.tags.name,
                        tags: it.tags
                        //nameEn: it.tags['name:en']
                    })
                }
            })
            switch (type) {
                case 'getRegions':
                    break;
                case 'getPointsFromArea':
                case 'getPoints':
                    arr = arr.filter(x => x.lat && x.tags?.type !== "boundary" && x.tags?.place !== "locality")
                    break;
                case 'getStreets':
                    /*arr = arr.filter(x =>
                        x.tags?.highway !== "trunk" && //Єто можно смотреть
                        x.tags?.highway !== "steps" &&
                        x.tags?.highway !== "footway" &&
                        x.tags?.highway !== "path")*/
                    break;
                default:
                    break;
            }
            arr.sort(function (a, b) {
                if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                return 0;
            })
            console.log(`${type}==========>`, arr)
            setData(arr)
            if (type === 'getPointsFromArea') setPoints(arr)
        }
    }

    useEffect(() => {
        async function fetchData() {
            // You can await here
            setProcessing(true)
            let req = getRequestOverpass('getAreas', '', '', '')
            const getAreas = await nominatimOpenStreetMap(req);
            setProcessing(false)
            let arr = []
            if (getAreas) {
                getAreas.forEach(it => {
                    if (it?.tags['ISO3166-2']) {
                        if (it.tags['ISO3166-2'].indexOf('UA') >= 0) {
                            arr.push({
                                id: it.id,
                                name: it.tags.name,
                                name_ori: it.tags.name,
                                iso: it.tags['ISO3166-2'],
                                tag: it.tags
                            })
                        }
                    }
                })
                arr.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                })
            }
            console.log('getAreas========>', arr)
            setAreas(arr)
            if (formData.auto_address) setIniAddress(1)
            /*await nominatimOpenStreetMap('getAreas').then(dt => {
                console.log('getAreas========>', dt)
            })*/
        }

        fetchData();

    }, []);// eslint-disable-line

    useEffect(() => {
        if (iniAddress) {
            async function getRegion() {
                if (formData.area_st) {
                    let it = areas.find(x => x.name === formData.area_st)
                    if (it) {
                        formEdit.change('area', it.id)
                        let req = getRequestOverpass('getRegions', it.iso, '', '')
                        getShortData('getRegions', req, setRegions)
                        req = getRequestOverpass('getPointsFromArea', it.iso, '', '')
                        getShortData('getPointsFromArea', req, setPointsFromArea).then((dt) => {
                            console.log('==setIniAddress=========>')
                            setIniAddress(2)
                        })
                    } else formEdit.change('area', '')
                } else formEdit.change('area', '')
            }

            async function getPoint() {
                if (formData.region_st) {
                    let it = regions.find(x => x.name === formData.region_st)
                    if (it) {
                        formEdit.change('region', it.id)
                        let req = getRequestOverpass('getPoints', '', it.name_ori, '')
                        getShortData('getPoints', req, setPoints).then((dt) => {
                            setIniAddress(3)
                        })
                    } else formEdit.change('region', '')
                } else {
                    setIniAddress(3)
                    formEdit.change('region', '')
                }
            }

            async function getStreet() {
                if (formData.point_st) {
                    let it = points.find(x => x.name === formData.point_st)
                    if (it) {
                        formEdit.change('point', it.id)
                        let req = getRequestOverpass('getStreets', '', it.name_ori, it.nameEn)
                        getShortData('getStreets', req, setStreets).then((dt) => {
                            setIniAddress(4)
                        })
                    } else formEdit.change('point', '')
                } else formEdit.change('point', '')
            }

            async function getStreetId() {
                console.log(formData.street_st, '==getStreetId=========>', streets)
                if (formData.street_st) {
                    let it = streets.find(x => x.name_ori === formData.street_st)
                    console.log(formData.street_st, '==getStreetId=========>', it)
                    if (it) {
                        formEdit.change('street', it.id)
                    } else formEdit.change('street', '')
                } else formEdit.change('street', '')
            }

            console.log('==setIniAddress=========>', iniAddress)
            if (iniAddress === 1) getRegion()
            else if (iniAddress === 2) getPoint()
            else if (iniAddress === 3) getStreet()
            else if (iniAddress === 4) getStreetId()
        }
    }, [iniAddress]);// eslint-disable-line

    useEffect(() => {
        console.log(`formData==========>`, formData)
        let address = arrAddressHand
        if (formData.auto_address) address = arrAddress
        let arr = []
        //let count = 0
        address.forEach(x => {
            if (formData[x]) {
                //count += 1
                arr.push(formData[x])
            }
        })
        let adr = arr.join(', ')
        // if (count > 0)
        formEdit.change('address', adr)

    }, [formData]);// eslint-disable-line
    useEffect(() => {
        //console.log(record.address, '************>', formData.address)
        if (formData.address !== record.address) {
            formEdit.change('lat', 0)
            formEdit.change('lon', 0)
        }
    }, [formData.address]);// eslint-disable-line


    const heandleChangeSelect = async (event) => {
        console.log('event=!!!=====>', event)
        let req = ''
        let it = '';
        switch (event.target.name) {
            case 'area':
                setRegions([])
                setPoints([])
                setPointsFromArea([])
                setStreets([])
                formEdit.change('region', '')
                formEdit.change('region_st', '')
                formEdit.change('point', '')
                formEdit.change('point_st', '')
                formEdit.change('street', '')
                formEdit.change('street_st', '')
                if (event.target.value) {
                    it = areas.find(x => x.id === event.target.value)
                    formEdit.change('area_st', it.name_ori)
                    if (it) {
                        req = getRequestOverpass('getRegions', it.iso, '', '')
                        if (req) getShortData('getRegions', req, setRegions)
                        req = getRequestOverpass('getPointsFromArea', it.iso, '', '')
                        if (req) getShortData('getPointsFromArea', req, setPointsFromArea)
                    }
                } else formEdit.change('area_st', '')
                break;
            default:
                break;

        }
    }
    const heandleChangeAutoCompleteRegion = async (event) => {
        console.log(event, 'Region=!!!=====>', points)
        setPoints(pointsFromArea)
        setStreets([])
        formEdit.change('point', '')
        formEdit.change('point_st', '')
        formEdit.change('street', '')
        formEdit.change('street_st', '')
        if (event) {
            let it = regions.find(x => x.id === event)
            formEdit.change('region_st', it.name_ori)
            if (it) {
                let req = getRequestOverpass('getPoints', '', it.name_ori, '')
                if (req) getShortData('getPoints', req, setPoints)
            }
        } else formEdit.change('region_st', '')
    }
    const heandleChangeAutoCompletePoint = async (event) => {
        console.log(event, 'Point=!!!=====>', points)
        setStreets([])
        formEdit.change('street', '')
        formEdit.change('street_st', '')
        if (event) {
            let it = points.find(x => x.id === event)
            formEdit.change('point_st', it.name_ori)
            if (it) {
                let req = getRequestOverpass('getStreets', '', it.name_ori, it.nameEn)
                if (req) getShortData('getStreets', req, setStreets)
            }
        } else formEdit.change('point_st', '')
    }
    const heandleChangeAutoCompleteStreet = async (event) => {
        console.log('Street=!!!=====>', event)
        if (event) {
            let it = streets.find(x => x.id === event)
            formEdit.change('street_st', it?.name_ori)

        } else formEdit.change('street_st', '')
    }

    const handleClickLatLon = async () => {
        setAlarmMessage(true)
        setTimeout(function () {
            setAlarmMessage(false)
        }, 3000);
        /*setProcessing(true)
        let res = await nominatimOpenStreetMapLatLon(formData)
        setProcessing(false)
        console.log('LAT/LON=====>', res)
        if (res && res[0]) {
            const {lat, lon} = res[0]
            formEdit.change('lat', lat)
            formEdit.change('lon', lon)
        }*/
    }

    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <BooleanInput
                        style={{width: 380}}
                        source={'auto_address'}
                        label={translate('Automatic address filling')}
                        defaultValue={true}
                    />
                </Box>
            </Box>
            {/*<Box display="flex" alignItems={"top"} mb={'-20px'}>*/}
            <Box display="grid" alignItems={"top"} mt={'-16px'} mb={'10px'}>
                {record?.id &&
                    <Box flex={1}>
                        <Typography variant="body" style={{opacity: 0.4, fontSize: 16}}>
                            <b>{`${translate('Saved Address')}`}:</b> {record?.address}
                        </Typography>
                    </Box>
                }
                <Box flex={1}>
                    <Typography variant="body" style={{fontSize: 16}}>
                        <b>{translate('Address')}:</b> {formData.address}
                    </Typography>
                </Box>
            </Box>
            {formData.auto_address
                ?
                <>
                    {processing && <LinearProgress color="secondary"/>}
                    <Box display="flex" alignItems={"top"}>
                        <Box flex={1} mr={'1em'}>
                            <SelectInput
                                onChange={(event) => {
                                    heandleChangeSelect(event)
                                }}
                                source={'area'}
                                choices={areas}
                                validate={validateReq}
                                label={translate('Area')}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} mr={'1em'}>
                            <AutocompleteInput
                                suggestionLimit={50}
                                onChange={(event) => {
                                    heandleChangeAutoCompleteRegion(event)
                                }}
                                source={'region'}
                                choices={regions}
                                // validate={validateReq}
                                label={translate('Region')}
                                resettable
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} mr={'1em'}>
                            <AutocompleteInput
                                suggestionLimit={50}
                                onChange={(event) => {
                                    heandleChangeAutoCompletePoint(event)
                                }}
                                source={'point'}
                                choices={points}
                                validate={validateReq}
                                label={translate('Point')}
                                resettable
                                fullWidth
                            />
                        </Box>
                        <Box flex={1}>
                            <AutocompleteInput
                                suggestionLimit={50}
                                onChange={(event) => {
                                    heandleChangeAutoCompleteStreet(event)
                                }}
                                source={'street'}
                                choices={streets}
                                validate={validateReq}
                                label={translate('Street')}
                                resettable
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} mr={'1em'}>
                        </Box>
                        <Box flex={1} mr={'1em'}>
                            {/*<Button
                                disabled={!(formData.area && formData.point && formData.street && formData.house)}
                                style={{
                                    backgroundColor: '#3f51b5',
                                    color: 'white',
                                    padding: 12,
                                    marginTop: -19,
                                    opacity: formData.area && formData.point && formData.street && formData.house ? 1 : 0.5
                                }}
                                // color={"primary"}
                                onClick={handleClickLatLon}
                                label={'Get Lat/Lon'}
                                fullWidth
                            >
                                {'Отримати координати'}
                            </Button>*/}
                        </Box>
                        <Box flex={1} mr={'1em'}>
                            <TextInput
                                multiline={true}
                                // onChange={(event) => handleChange(event)}
                                source={`house`}
                                label={translate('House')}
                                //variant={"outlined"}
                                validate={validateReq}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1}>
                            <TextInput
                                multiline={true}
                                // onChange={(event) => handleChange(event)}
                                source={`flat`}
                                label={translate('Flat')}
                                //variant={"outlined"}
                                // validate={validateReq}
                                fullWidth
                            />
                        </Box>
                    </Box>
                </>
                :
                <>
                    <Box display="flex" alignItems={"top"}>
                        <Box flex={1} mr={'1em'}>
                            <TextInput
                                source={`area_hand`}
                                label={translate('Area')}
                                variant={"outlined"}
                                validate={validateReq}
                                fullWidth
                            />

                        </Box>
                        <Box flex={1} mr={'1em'}>
                            <TextInput
                                source={`region_hand`}
                                label={translate('Region')}
                                variant={"outlined"}
                                validate={validateReq}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1} mr={'1em'}>
                            <TextInput
                                source={`point_hand`}
                                label={translate('Point')}
                                variant={"outlined"}
                                validate={validateReq}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1}>
                            <TextInput
                                source={`street_hand`}
                                label={translate('Street')}
                                variant={"outlined"}
                                validate={validateReq}
                                fullWidth
                            />

                        </Box>
                    </Box>
                    <Box display="flex" alignItems={"center"}>
                        <Box flex={1} mr={'1em'}/>
                        <Box flex={1} mr={'1em'}/>
                        <Box flex={1} mr={'1em'}>
                            <TextInput
                                source={`house`}
                                label={translate('House')}
                                variant={"outlined"}
                                validate={validateReq}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1}>
                            <TextInput
                                source={`flat`}
                                label={translate('Flat')}
                                variant={"outlined"}
                                // validate={validateReq}
                                fullWidth
                            />
                        </Box>
                    </Box>
                </>
            }

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={'1em'}>
                    <TextInput source={`name2`} label={translate('Name')}
                               variant={"outlined"}
                               validate={validateNameRec}
                               fullWidth/>
                </Box>
                <Box display="flex" flex={1}>
                    <Box flex={1}>
                        <BooleanInput source={'confirmed'} label={translate('Confirm')} defaultValue={false}
                                      fullWidth/>
                    </Box>
                    <Box flex={1} mr={'1em'}>
                        <BooleanInput source={'disabled'} label={translate('Disable')} defaultValue={false}
                                      fullWidth/>
                    </Box>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box display="flex" flex={1} mr={'1em'}>
                    <Box flex={1} mr={'1em'}>
                        <Button
                            //disabled={!(formData.area && formData.point && formData.street && formData.house)}
                            style={{
                                backgroundColor: alarmMessage ? "lightgray" : '#3f51b5',
                                color: 'white',
                                padding: 11,
                                marginTop: 7,
                                fontSize: 11,
                                //opacity: formData.area && formData.point && formData.street && formData.house ? 1 : 0.5
                            }}
                            // color={"primary"}
                            onClick={handleClickLatLon}
                            label={'Get Lat/Lon'}
                            fullWidth
                        >
                            {alarmMessage
                                ? <span style={{color: 'red'}}>{translate('Functionality under development')}</span>
                                : <span>{translate('Check coordinates on the map')}</span>
                            }

                        </Button>
                    </Box>
                    <Box flex={1}>
                        <Button
                            //disabled={!(formData.area && formData.point && formData.street && formData.house)}
                            style={{
                                backgroundColor: alarmMessage ? "lightgray" : '#3f51b5',
                                color: 'white',
                                padding: 11,
                                marginTop: 7,
                                fontSize: 11,
                                //opacity: formData.area && formData.point && formData.street && formData.house ? 1 : 0.5
                            }}
                            // color={"primary"}
                            onClick={handleClickLatLon}
                            label={'Get Lat/Lon'}
                            fullWidth
                        >
                            {alarmMessage
                                ? <span style={{color: 'red'}}>{translate('Functionality under development')}</span>
                                : <span>{translate('Fill in coordinates manually')}</span>
                            }
                        </Button>
                    </Box>
                </Box>
                <Box display="flex" flex={1}>
                    <Box flex={1} mr={'1em'}>
                        <NumberInputFloatComp source={`lat`} label={translate('Lat')}
                                              validate={validateReq}
                                              variant={"outlined"}
                                              fullWidth/>
                    </Box>
                    <Box flex={1}>
                        <NumberInputFloatComp source={`lon`} label={translate('Lon')}
                                              validate={validateReq}
                                              variant={"outlined"}
                                              fullWidth/>
                    </Box>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <ReferenceArrayInput
                        label={translate('Customers')}
                        source="customers2"
                        reference="zCustomers"
                        perPage={100}
                        validate={validateReq}
                        filter={{filter_customers_partner_id: relaited_id}}
                    >
                        <AutocompleteArrayInput
                            optionText={useMemo(() => {
                                return ((record) => `${record?.name}`)
                            }, [])}
                            // optionText={`${'name'}`}
                            variant={"outlined"}
                            resettable fullWidth
                        />
                    </ReferenceArrayInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <ReferenceArrayInput
                        label={translate('Deliveries')}
                        source="deliveries"
                        reference="delivery"
                        perPage={100}
                        validate={validateReq}
                    >
                        <AutocompleteArrayInput
                            optionText={useMemo(() => {
                                return ((record) => `${record?.name} [${record?.time_dispatch}][${record?.additional?.week_days}]`)
                            }, [])}
                            // optionText={`${'name'}`}
                            variant={"outlined"}
                            resettable fullWidth
                        />
                    </ReferenceArrayInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={'1em'}>
                    <TextInput
                        multiline={true}
                        // onChange={(event) => handleChange(event)}
                        source={`comment`}
                        label={translate('Comment')}
                        variant={"outlined"}
                        // validate={validateNameRec}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        multiline={true}
                        // onChange={(event) => handleChange(event)}
                        source={`placement`}
                        label={translate('Placement')}
                        variant={"outlined"}
                        // validate={validateNameRec}
                        fullWidth
                    />
                </Box>
            </Box>
        </Box>
    )
};

const ColumnsForList = (resource, classes) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        confirmed:
            <SelectAndSaveChoices
                headerClassName={classes.clOverflowWrap}
                label={translate('Confirm')}
                source="confirmed"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                // edit={true}
                color={'green'}
                circle={'no inv'}
            />,
        disabled:
            <SelectAndSaveChoices
                headerClassName={classes.clOverflowWrap}
                label={translate('Disable')}
                source="disabled"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                // edit={true}
                color={'green'}
                circle={'no inv'}
            />,
        name: <TextField source={'name'} label={translate('Name')}/>,
        customers:
            <ReferenceArrayField reference="zCustomers" source="customers" label={translate('Customers')}>
                <SingleFieldList>
                    <ChipField source={'name'}/>
                </SingleFieldList>
            </ReferenceArrayField>,
        address:
            <Box label={translate('Address lat / lon')} sortBy={'address'} display={'grid'}>
                <TextField source={'address'} label={translate('Address')}/>
                <NumberField source={`lat`} label={translate('Lat')} textAlign={"right"}/>
                <NumberField source={`lon`} label={translate('Lon')} textAlign={"right"}/>
            </Box>,

        updated:
            <Box label={translate('Update')} sortBy={'updated'} display={'grid'}>
                <CroppedLongField source="source" long={10} label={translate('Source')}/>
                <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'}
                               locale={'us'} cellClassName={classes.date_st}/>
            </Box>,
    }

    return columns;
};

const RelatedPartnerAddress = props => {
    // const {customer_id, record, isSave = true} = props;
    const {relaited_id, resource: from_resource} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    //console.log('resource==!!!=====>', from_resource);

    const columns = useSelectedColumns({
        preferences: `${'partnerAddress'}list.columns`,
        columns: ColumnsForList('partnerAddress', classes),
        omit: ["nb_views"],
    });

    const canEdit = canAccess2({resource: `${from_resource}`, action: 'edit'});
    return (
        <div style={{width: "fit-content", minWidth: 500, marginBottom: 16, overflowX: "auto"}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Partner Address"
                reference="partnerAddress"
                target="partner"
            >

                <MyEditableDatagridForReference
                    canEdit={canEdit}
                    rowClick="edit"
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false}
                                              relaited_id={relaited_id}/>}
                    editForm={<ListEditForm submitOnEnter={false}
                                            relaited_id={relaited_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>

            </ReferenceManyField>

        </div>
    )
}
export default RelatedPartnerAddress;