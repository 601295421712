import DefaultTable from '../DefaultTable/DefaultClass'

class ComponentGood extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                name: 'component',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Products', optionText: 'name', locale: true},
            },
            {
                name: 'product',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Products', optionText: 'name', locale: true},
            },
        ]


        this.filters_aside = []

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]


        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'component',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Products', optionText: 'name', locale: true},
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'product',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Products', optionText: 'name', locale: true},
                                read_only: false,
                                flex: 1,
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'source',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                read_only: false,
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'quantity',
                                type: 'bool',
                                show_type: 'NumberField',
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                            {
                                name: 'share_of_the_cost',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                read_only: false,
                            },
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]
    }
}

export default ComponentGood