import config from '../../utils/config';
import {fetchJson} from '../../utils/keycloak';

export const default_result = {grid: [], storage_vertical: [], storage_horizontal: []};
export const getData = async (setRes, storageId = null) => {
    let url = `${config.apiUrl}api2/edit-storage-working-hours/get-shedule-grid/`;
    try {
        const response = await fetchJson(url, {
                method: 'post',
                body: JSON.stringify({
                    customer_id: 1173,
                    storage_ids_horizontal: storageId ? [storageId] : [],
                })
            }
        );

        if (response.status < 400) {
            const {json} = response;
            const {results} = json;

            // console.log(json);

            setRes(results);
        } else {
            console.log('Ошибка 2');
            setRes(default_result);
        }
    } catch (err) {
        console.log(['Ошибка', err, url]);
        console.trace();
        setRes(default_result);
    }
};
export const getDataEditWorkingHours = async (setRes, working_hours_table_id) => {
    let url = `${config.apiUrl}api2/edit-storage-working-hours/get-table-row/`;
    try {
        const response = await fetchJson(url, {
                method: 'post',
                body: JSON.stringify({
                    customer_id: 1173,
                    working_hours_table_id: working_hours_table_id,
                })
            }
        );

        if (response.status < 400) {

            const {json} = response;
            const {results} = json;
            setRes(results);

        } else {
            console.log('Ошибка 2');
            setRes(null);
        }
    } catch (err) {
        console.log(['Ошибка', err, url]);
        console.trace();
        setRes(null);
    }
};
export const editDataEditWorkingHours = async (setRes, formWorkingHoursEdit) => {

    let url = `${config.apiUrl}api2/edit-storage-working-hours/edit/`;

    try {
        const response = await fetchJson(url, {
                method: 'post',
                body: JSON.stringify(formWorkingHoursEdit)
            }
        );

        if (response.status < 400) {
            const {json} = response;
            const {results} = json;

            setRes(formWorkingHoursEdit);
            return Promise.resolve(results);

        } else {
            return Promise.reject(response);
        }

    } catch (err) {
        return Promise.reject(err);

    }
};


export const createDataEditWorkingHours = async (setRes, formWorkingHoursEdit) => {

    let url = `${config.apiUrl}api2/edit-storage-working-hours/create/`;

    try {
        const response = await fetchJson(url, {
                method: 'post',
                body: JSON.stringify(formWorkingHoursEdit)
            }
        );

        if (response.status < 400) {
            const {json} = response;
            const {results} = json;

            setRes(formWorkingHoursEdit);
            return Promise.resolve(results);

        } else {
            return Promise.reject(response);
        }

    } catch (err) {
        return Promise.reject(err);

    }
};


export const deleteDataEditWorkingHours = async (id) => {

    let url = `${config.apiUrl}api2/edit-storage-working-hours/delete/`;

    try {
        const response = await fetchJson(url, {
                method: 'post',
                body: JSON.stringify({id: id})
            }
        );

        if (response.status < 400) {
            const {json} = response;
            const {results} = json;

            return Promise.resolve(results);

        } else {
            return Promise.reject(response);
        }

    } catch (err) {
        return Promise.reject(err);

    }
};


export const updateStoragePriority = async (strage_id, priority, show_mode) => {

    let url = `${config.apiUrl}api2/edit-storage-working-hours/set-storage-priority/`;

    try {
        const response = await fetchJson(url, {
                method: 'post',
                body: JSON.stringify({storage_id: strage_id, priority_selection: priority, show_mode: show_mode}),
            }
        );

        if (response.status < 400) {
            const {json} = response;
            const {results} = json;

            return Promise.resolve(results);

        } else {
            return Promise.reject(response);
        }

    } catch (err) {
        return Promise.reject(err);

    }
};
