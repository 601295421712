import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, TextField,
    useLocale,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput, useTranslate, SelectInput,
} from 'react-admin';

import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import useMyStyles from "../../utils/useMyStyles";
// import {universalTopFilters} from "../universalView/universalTopFilters";
// import {CardContent, useMediaQuery} from "@material-ui/core";
import {Box, CardContent} from "@material-ui/core";
import CardComp from "../../_common/Asside/CardComp";
import {
    DateFieldComp,
    FilterDataAside,
} from "../../_common/CompReact";
import {validateReq} from "../../_constants/validateTable";
import {choicesDefaultRelation} from "../../_constants/choices";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {useSelector} from "react-redux";

const universalTopFilters = (resource, locale) => {
    let dt = [];
    // dt.push(<TextInput label="Search" source="q" alwaysOn/>)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!"/>);
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                <FilterLiveSearch/>
                <FilterDataAside fields={'created_at'} name={'create'}/>
                <FilterDataAside fields={'updated_at'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};

const ListEditForm = (props) => {
    // const {classes, locale} = props;
    const {classes} = props;
    const translate = useTranslate();
    return (
        <RowForm {...props}>
            <NumberField source="id" headerClassName={classes.headerShortWidth}/>
            <SelectInput source={'relation'} choices={choicesDefaultRelation}
                         label={translate('Relation')} validate={validateReq} fullWidth/>
            <ReferenceInput source="current_discount_rule_id" reference="discount_rules"
                            label={translate('Current Discount Rule')}
                            validate={validateReq}>
                <AutocompleteInput resettable optionText="name" fullWidth/>
            </ReferenceInput>
            <ReferenceInput source="related_discount_rule_id" reference="discount_rules"
                            label={translate('Relaited Discount Rule')}
                            validate={validateReq}>
                <AutocompleteInput resettable optionText="name" fullWidth/>
            </ReferenceInput>
            <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                           locale={'us'} cellClassName={classes.date_st}/>
            <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                           locale={'us'} cellClassName={classes.date_st}/>
        </RowForm>
    )
};

/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/

/*
"id": 0,
  "current_discount_rule_id": 0,
  "related_discount_rule_id": 0,
  "relation": "ignore",
  "created_at": "2023-08-29T13:10:16.333Z",
  "updated_at": "2023-08-29T13:10:16.333Z"
*/
export function compareIndex(rec, discount_rules) {
    //console.log(rec, 'rec====>', discount_rules)
    let r1 = discount_rules.find(x => x.id === rec.current_discount_rule_id)
    let r2 = discount_rules.find(x => x.id === rec.related_discount_rule_id)
    if (r1 && r2) {
        if (r1.index <= r2.index) return true;
    }
    return false
}

export const DiscountRelationship = props => {
    const classes = useMyStyles();
    const {resource} = props;
    const translate = useTranslate();
    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();

    let discount_rules = useSelector((state) => state.admin.resources.discount_rules.data)
    if (discount_rules) discount_rules = Object.values(discount_rules);

    const postRowStyle = (record, index) => ({
        // backgroundColor: (record?.current_discount_rule_id & 1) && '#efe',
        //backgroundColor: compareIndex(record) && '#efe',
        backgroundColor: compareIndex(record, discount_rules) && '#fff0f0',
        // display: "table-cell",
    });

    return (
        <ListComponentAdmin
            {...props}
            //hasCreate={true}
            title={''}
            actions={<TableActions
                preference={`${resource}${".list.columns"}`}
                select={false}
                create={true}
                filter={true}
            />}
            // is_bulkActionButtons={false}
            filters={universalTopFilters(resource, locale)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
        >
            <EditableDatagrid
                classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                //noDelete={true}
                // initialRowMode="edit"
                rowStyle={postRowStyle}
                rowClick="edit"
                mutationMode="pessimistic" //Подтвердить удаление
                undoable
                createForm={<ListEditForm locale={locale} classes={classes} {...props} submitOnEnter={false}/>}
                editForm={<ListEditForm locale={locale} classes={classes} {...props} submitOnEnter={true}/>}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <SelectAndSaveChoices
                    label={translate('Relation')}
                    source="relation"
                    statuses={choicesDefaultRelation}
                    resource={resource}
                    textAlign={'center'}
                    showTextValue={true}
                    //edit={true}
                    width={'10em'}
                />
                <ReferenceField source="current_discount_rule_id" reference="discount_rules"
                                label={`${translate('Current Discount Rule')} (index)`}
                                link={true}
                                sortBy={'discount_rules:name'}>
                    <Box><TextField source="name"/> (<TextField source="index"/>)</Box>
                </ReferenceField>
                <ReferenceField source="related_discount_rule_id" reference="discount_rules"
                                label={`${translate('Relaited Discount Rule')} (index)`}
                                link={true}
                                sortBy={'discount_rules:name'}>
                    <Box><TextField source="name"/> (<TextField source="index"/>)</Box>
                </ReferenceField>

                <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                               locale={'us'} cellClassName={classes.date_st}/>
                <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                               locale={'us'} cellClassName={classes.date_st}/>
            </EditableDatagrid>
        </ListComponentAdmin>
    )
};