import {useUpdate, useCreate, useRecordContext, useDelete} from "react-admin";
import * as React from "react";
import {Menu, MenuItem} from "@material-ui/core";
import get from 'lodash/get';
// import {useGetList} from "ra-core";
import {useEffect, useRef} from "react";
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
//import {useSelector} from "react-redux";

const SelectAndCreateOtherTable = (props) => {
    const {
        reference,
        fieldsForMenu,
        // filter,
        oriName,
        referenceForFeatureSave,
        dataForFeatureSave,
        featureTypeId,
    } = props;

    // const locale = useLocale();
    const record = useRecordContext(props);

    const featureButton = useRef();

    const [dataMenu, setDataMenu] = React.useState([]);
    const [isError, setIsError] = React.useState(false);
    const getData = async () => {
        setIsError(false)
        let url = `${apiPathConfig.getUrl(reference)}?feature_type=${featureTypeId}&limit=200&ordering=valid_value.en&skip=0`;
        try {
            //cache: only-if-cached
            const response = await httpClient(`${url}`, {cache: 'force-cache'});
            if (response.status >= 200 && response.status <= 300) {
                const {results = []} = response.json
                setIsError(false)
                setDataMenu(results)
            } else {
                setIsError(true)
                setDataMenu([])
            }
        } catch (err) {
            setIsError(true)
            setDataMenu([])
        } finally {
        }
    }
    // let feature = useSelector((state) => state.admin.resources[reference].data)

    /*const {data: dataMenu, loading: isLoading} = useGetList(
        reference,
        {page: 1, perPage: 100},
        {field: fieldsForMenu, order: 'ASC'},
        filter,
    );*/

    const [update, dtUpdate] = useUpdate();
    const [create, dtCreate] = useCreate()
    const [deleteOne, dtDelete] = useDelete()
    // const [getOne, {loading: load, error: err}] = useGetOne()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [nameFeature, setNameFeature] = React.useState(oriName);
    const [DataFeature, setDataFeature] = React.useState(dataForFeatureSave);

    useEffect(() => {
        if (dataMenu.length > 0) {
            setTimeout(function () {
                featureButton.current?.click()
            }, 30);

        }
    }, [dataMenu]);// eslint-disable-line

    useEffect(() => {
        if (dtUpdate.loaded) {
            setDataFeature(dtUpdate.data)
        }
    }, [dtUpdate]);// eslint-disable-line
    useEffect(() => {
        if (dtCreate.loaded) {
            setDataFeature(dtCreate.data)
        }
    }, [dtCreate]);// eslint-disable-line
    useEffect(() => {
        if (dtDelete.loaded) {
            setDataFeature(dtDelete.data)
        }
    }, [dtDelete]);// eslint-disable-line

    //const open = Boolean(anchorEl);

    const handleClick = (event) => {
        event.preventDefault();
        event.stopPropagation();
        //console.log('feature====$$$$========>', feature)
        if (!dataMenu || dataMenu.length === 0) getData()
        else setAnchorEl(event.currentTarget);
    };
    const handleClose = (event) => {
        setAnchorEl(null);
        event.preventDefault();
        event.stopPropagation();
    };
    const onClick = (event, v, name) => {
        handleClose(event);
        //const data = {id,}
        let data = {value: v,}
        if (DataFeature.id) {
            if (v < 0) deleteOne(referenceForFeatureSave, DataFeature.id)
            else update(referenceForFeatureSave, DataFeature.id, data)
        } else {
            data = {
                ...data,
                product: record.id,
                feature_type: featureTypeId,
                source: DataFeature.source,
            }
            create(referenceForFeatureSave, data)
        }
        setNameFeature(name)
    }

    if (isError) {
        return <p style={{color: "red"}}>ERROR</p>;
    }
    if (dtUpdate.loading || dtCreate.loading || dtDelete.loading) {
        return <p style={{color: "green"}}>Saving...</p>;
    }
    if (dtUpdate.error || dtCreate.error) {
        return <p style={{color: "red"}}>ERROR</p>;
    }
    if (dtDelete.error) {
        return <p style={{color: "red"}}>ERROR DEL</p>;
    }

    // console.log('err==dtUpdate========>', dtUpdate)
    return (
        <div>
            <div
                ref={featureButton}
                class={'featureTd'}
                /*style={{
                    '&: hover': {color: "red"},
                    textAlign: "center",
                    padding: 8,
                    borderRadius: 8,
                    // backgroundColor: '#eee',
                    cursor: "pointer",
                }}*/
                // title={`${'id: '}${status} / ${'name: '}${name}`}
                id="basic-button"
                // aria-controls={anchorEl ? 'basic-menu' : undefined}
                // aria-expanded={anchorEl ? 'true' : undefined}
                aria-controls={'basic-menu'}
                aria-expanded={'true'}
                aria-haspopup="true"
                onClick={handleClick}
                disabled={dtUpdate.loading || dtCreate.loading}
            >
                {DataFeature.id ? nameFeature : '---'}
            </div>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={anchorEl}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {DataFeature.id &&
                    <MenuItem onClick={(event) => onClick(event, -1, '---')}>{'---'}</MenuItem>
                }
                {Object.values(dataMenu).map(v => {
                    let st = get(v, fieldsForMenu, '???')
                    return (
                        <MenuItem key={v.id}
                                  onClick={(event) => onClick(event, v.id, st)}
                        >{st}
                        </MenuItem>
                    )
                })}
            </Menu>
        </div>
    )
}

export default SelectAndCreateOtherTable
