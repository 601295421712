import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    useTranslate, useLocale,
    ReferenceField, AutocompleteInput, ReferenceInput, NumberInput, useRecordContext,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp, DateInputComp} from "../../_common/CompReact";

// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
// import {validateReq} from "../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
// import {useForm} from "react-final-form";
import get from 'lodash/get';
import CroppedLongField from "../../_common/CroppedLongField";
import {validateReq} from "../../_constants/validateTable";
import {useMemo} from "react";
import {FloatNumber} from "../../_common/CompFormat/utils";
import {Box} from "@mui/material";

const ListEditForm = (props) => {
    // const classes = useMyStyles();
    const {related_id} = props;
    const translate = useTranslate();
    const locale = useLocale();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    product: related_id,
                    source: localStorage.getItem('username'),
                }}
                // transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                {/*<NumberField source="id" headerClassName={classes.headerShortWidth}/>*/}
                <ReferenceInput
                    label={translate('Characteristic')}
                    source={'characteristic'}
                    reference={'dCharacteristicProduct'}
                    filter={{product: related_id}}
                    validate={validateReq}
                >
                    <AutocompleteInput
                        //optionText={`${'name'}[${locale}]`}
                        optionText={useMemo(() => {
                            return ((record) =>
                                `#${record?.id}: ${record?.additional?.variant?.article},
                                 ${record?.barcode}, ${record?.size}`)
                        }, [])}
                        resettable
                        fullWidth
                    />
                </ReferenceInput>
                <ReferenceInput
                    label={translate('Storage')}
                    source={'storage'}
                    reference={'dSuppliersWarehouses'}
                    validate={validateReq}
                >
                    <AutocompleteInput
                        //optionText={`${'name'}[${locale}]`}
                        optionText={useMemo(() => {
                            return ((record) => `${record?.additional?.supplier?.name}: ${record?.name[locale]}`)
                        }, [])// eslint-disable-line
                        }
                        resettable
                        fullWidth
                    />
                </ReferenceInput>

                <NumberInput source={'quantity_int'} label={translate('Quantity')}
                             validate={validateReq}/>

                <NumberInput source="base_price" label={translate('Base Price')} validate={validateReq} fullWidth/>,

                <DateInputComp
                    source={"expected_date"}
                    time={false}
                    label={translate('Expected Date')}
                    // validate={validateReq}
                    //defaultValue={moment(new Date()).format()}
                />

                <ExternalProducts label={translate('External Products')}/>

            </RowForm>
        </>
    )
};

const ExternalProducts = (props) => {
    const record = useRecordContext(props);
    let par = get(record, 'additional.external_products', null)
    if (par)
        return (
            <Box display={'grid'}>
                <span>{par?.external_id}</span>
                <span>{par?.supplier}</span>
            </Box>
        )
    return null
}
const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        /*id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,*/
        characteristic:
            <ReferenceField
                label={translate('Characteristic')}
                source={'characteristic'}
                reference={'dCharacteristicProduct'}
                link={false}
            >
                <>
                    {'#'}<TextField source={`id`}/>: {' '}
                    <TextField source={`additional.variant.article`}/>{', '}
                    <TextField source={`barcode`}/>{', '}
                    <TextField source={`size`}/>
                </>

            </ReferenceField>,
        feature_type:
            <ReferenceField
                label={translate('Storage')}
                source={'storage'}
                reference={'dSuppliersWarehouses'}
                link={false}
            >
                <>
                    <TextField source={`additional.supplier.name`}/>: {' '}
                    <TextField source={`name[${locale}]`}/>
                </>

            </ReferenceField>,
        quantity_int: <TextField source={`quantity_int`} label={translate('Quantity')}
                                 textAlign={"right"}/>,

        base_price: <FloatNumber source={`base_price`} label={translate('Base Price')}
                                 textAlign={"right"}/>,

        expected_date: <DateFieldComp source="expected_date" label={translate('Expected Date')} textAlign={'center'}
            /*cellClassName={classes.date_st}*/ showTime={false}/>,

        external_products: <ExternalProducts label={translate('External Products')}/>,

        updated:
            <Box
                headerClassName={classes.rowCellCenter}
                sx={{display: 'grid', textAlign: 'center'}}
                label={translate('Updated')} sortby={'updated'}
            >
                <CroppedLongField source="source" long={10} label={translate('Source')}/>
                <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                    /*cellClassName={classes.date_st}*/ showTime={true}/>
            </Box>,

    }
    return columns;
};

/*const PostShow = (props) => {
    const {record} = props;
    let par = get(record, 'additional.external_products', null)
    return (
        <Box display={'grid'}>
            <span>id: <b>{par?.external_id}</b></span>
            <span>supplier: <b>{par?.supplier}</b></span>
            <span>additional_data: <b>{par.additional_data}</b></span>
        </Box>
    )
}*/
const ProductsFeatureValue = props => {
    const locale = useLocale();
    const {related_id} = props;
    const classes = useMyStyles();
    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'WarehouseStock'}productslist.columns`,
        columns: ColumnsForList('WarehouseStock', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Warehouse Stock"
                reference="dWarehouseStock"
                target="product"

            >
                <MyEditableDatagridForReference
                    /*isRowExpandable={row => {
                      return get(row, 'additional.external_products.external_id', null)
                    }}
                    expand={<PostShow/>}*/
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                              related_id={related_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            related_id={related_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default ProductsFeatureValue;