import * as React from 'react';
import {Fragment, useCallback, useEffect, useState} from 'react';
import {
    AutocompleteInput,
    NumberField,
    ReferenceField,
    ReferenceInput,
    //ReferenceManyField,
    TextField,
    useListContext, useLocale, useTranslate,
    //SimpleShowLayout,
    useRecordContext, useGetManyReference,
} from 'react-admin';
import {Divider, Tabs, Tab, Box} from '@mui/material';

import {CustomerInfo, DateFieldComp, MyCount} from "../../_common/CompReact";
import TableActions from "../../_common/TableActions";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {formatSpacerNumber} from "../../_common/CompFormat/utils";
//import PostBulkActionButtons from "../_common/PostBulkActionButtons";
import CroppedLongField from "../../_common/CroppedLongField";
import {useMediaQuery} from "@material-ui/core";
import MySimpleList from "../../_common/MySimpleList";

import get from "lodash/get";
/*const Aside = (props) => {
    /!*const { data, isLoading } = useListContext();*!/
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/!*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*!/}
                <FilterLiveSearch label={'Search'}/>
                <FilterDataAside fields={'updated'} name={'updated'}/>
                {/!*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*!/}
                {/!*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*!/}
                {/!*<TrueFalseFilters field={'is_load'} label={'Exported to Xero'}/>*!/}
                {/!*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*!/}
            </CardContent>
        </CardComp>
    )
};*/


const TopFilters = (resource, locale) => {
    let dt = [];
    dt.push(<ReferenceInput source="status" reference="OrderStatus" alwaysOn>
        <AutocompleteInput
            optionText={`name[${locale}]`}
            resettable emptyText={'All Status'}/>
    </ReferenceInput>);
    dt.push(<ReferenceInput source="customer" reference="dCustomers" alwaysOn>
        <AutocompleteInput optionText="name" resettable emptyText={'All customers'}/>
    </ReferenceInput>);
    return dt;
}
const GetAddress = (props) => {
    const {source} = props
    const record = useRecordContext();

    return (
        <Box display={'grid'}>
            <span title={'Zip'}>{get(record, `${source}.zip`, null)}{', '}</span>
            <span title={'Province, City'}>
                {get(record, `${source}.province`, null)}{', '}
                {get(record, `${source}.city`, null)}{', '}
            </span>
           <span title={'Street, apartment'}>
                {get(record, `${source}.street`, null)}{', '}
                {get(record, `${source}.apartment`, null)}
            </span>
       </Box>
    )
}
export const TotalItems = (props) => {
    const record = useRecordContext();
    const {total: totalItems} = useGetManyReference(
        'OrderItems', 'order', record.id,
        {},
        {},
        {},
    );
    // console.log('record=====>', record)
    return (<span>{totalItems}</span>)
}
export const TotalSum = (props) => {
    const record = useRecordContext();
    const {data: dataItems, loaded: loadedItems} = useGetManyReference(
        'OrderItems', 'order', record.id,
        {},
        {},
        {},
    );
    let sell = 0;
    if (loadedItems) {
        Object.values(dataItems).forEach((x, index) => {
            sell += x.quantity * x.price_sell
        })
    }
    return (<span><b>{formatSpacerNumber(sell)}{'\u00a0'}$</b></span>)
}
const ColumnsForList = (resource, locale, classes, isSmall) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        created: <DateFieldComp source="created" label={translate('Created')} textAlign={'center'}
                                cellClassName={classes.date_st} showTime={true}/>,
        customer: <ReferenceField
            source="customer"
            reference="dCustomers"
            label={translate('Customer')}
            link={true}
            sortBy={'dCustomers:name'}
            cellClassName={classes.customer_st}
        >
            <CustomerInfo name={'name'} src={'avatar'}/>
        </ReferenceField>,
        status:
            <ReferenceField source="status" reference="OrderStatus"
                            label={translate('Order Status')}
                            link={false}
                // sortBy={'discountProperty:name'}
                            sortable={false}
                            textAlign={"center"}
            >
                <TextField source={`name[${locale}]`}/>
            </ReferenceField>,
        items: <TotalItems label={'Items'} textAlign={"right"}/>,
        total: <TotalSum label={'Total'} textAlign={"right"}/>,

        remark:
            <CroppedLongField long={80} source={'remark'} label={translate('Remark')}
                              cellClassName={classes.remark_st}/>,
        address_delivery: <GetAddress source={'address_delivery'} label={translate('Address delivery')}/>,
        address_billing: <GetAddress source={'address_billing'} label={translate('Address billing')}/>
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

const OrderList = ({permissions, ...props}) => {
    const classes = useMyStyles();
    const {resource} = props;

    //console.log(resource, 'MyCount====>')
    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: ["nb_views"],
    });

    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={false}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                permissions={permissions}
                //asideFilter={true}
                filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            // filter={{status: 2}}
            sort={{field: 'id', order: 'DESC'}}
            //aside={<Aside/>}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <TabbedDatagrid columns={columns} postRowStyle={postRowStyle} {...props}/>
            }

        </ListComponentAdmin>
    )
};

const tabs = [
    {id: 1, name: 'All Orders', status: ''},
    {id: 2, name: 'Add to Card', status: 1},
    {id: 3, name: 'Accepted', status: 2},
    {id: 4, name: 'In Processing', status: 3},
];

const TabbedDatagrid = (props) => {
    const {columns, postRowStyle} = props
    const listContext = useListContext();
    const {filterValues, setFilters, displayedFilters} = listContext;
    const [tb, setTb] = useState(1);

    useEffect(() => {
        setFilters(
            {...filterValues, status: tabs[tb - 1].status},
            displayedFilters,
            false // no debounce, we want the filter to fire immediately
        );
    }, []);// eslint-disable-line

    const handleChange = useCallback(
        (event, value) => {
            setTb(value)
            setFilters &&
            setFilters(
                {...filterValues, status: tabs[value - 1].status},
                displayedFilters,
                false // no debounce, we want the filter to fire immediately
            );
        },
        [displayedFilters, filterValues, setFilters]
    );

    //console.log(tb, 'filterValues=====>', filterValues)
    return (
        <Fragment>
            <Tabs
                variant="fullWidth"
                centered
                value={tb}
                indicatorColor="primary"
                onChange={handleChange}
            >
                {tabs.map(choice => (
                    <Tab
                        key={choice.id}
                        label={
                            <span>
                                {choice.name} (
                                    <MyCount
                                        resource={'dOrders'}
                                        filter={{...filterValues, status: choice.status}}
                                        sx={{lineHeight: 'inherit'}}
                                    />
                                )
                            </span>
                        }
                        value={choice.id}
                    />
                ))}
            </Tabs>
            <Divider sx={{marginTop: '-2px'}}/>
            {/*{filterValues.status === 4 && (
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {columns}
                </DatagridComponentAdmin>
            )}*/}
            <DatagridComponentAdmin
                {...props}
                rowClick="edit"
                rowStyle={postRowStyle}
                //expand={<PostShow/>}
            >
                {columns}
            </DatagridComponentAdmin>
        </Fragment>
    );
};

export default OrderList;
