import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {makeStyles, withStyles} from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';

// import SettingsIcon from '@material-ui/icons/Settings';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
// import VideoLabelIcon from '@material-ui/icons/VideoLabel';

import HandshakeIcon from '@mui/icons-material/Handshake';
//import GroupIcon from '@material-ui/icons/Group';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SaveIcon from '@mui/icons-material/Save';
import StepConnector from '@material-ui/core/StepConnector';
import Button from '@material-ui/core/Button';

import {Partner} from "./Partner";
import {Customer} from "./Customer";
import {Address} from "./Address";
import {useTranslate} from "react-admin";

import {getCustomerPricingProperty, getUserSearch, nominatimOpenStreetMap} from "../../_common/CompForApi/utilsAPI";
import {AlarmDialog} from "./AlarmDialog";
import {getRequestOverpass} from "../../_constants/requestOverpass";
import {SavePartner} from "./SavePartner";
import dataProvider from "../../utils/dataProvider";

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#784af4',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: '#784af4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const {active, completed} = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed}/> : <div className={classes.circle}/>}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 136deg, rgb(33,150,243) 0%, rgb(1,105,255) 50%, rgb(1,45,255) 100%)',
            /*backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',*/
        },
    },
    completed: {
        '& $line': {
            backgroundImage:
                'linear-gradient( 136deg, rgb(33,150,243) 0%, rgb(1,105,255) 50%, rgb(1,45,255) 100%)',
            /*backgroundImage:
                'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',*/
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
    },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
    root: {
        backgroundColor: '#ccc',
        zIndex: 1,
        color: '#fff',
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    active: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(33,150,243) 0%, rgb(1,105,255) 50%, rgb(1,45,255) 100%)',
        /*backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',*/
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundImage:
            'linear-gradient( 136deg, rgb(33,150,243) 0%, rgb(1,105,255) 50%, rgb(1,45,255) 100%)',
        /*backgroundImage:
            'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',*/
    },
});

function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const {active, completed} = props;

    const icons = {
        1: <HandshakeIcon/>, //<SettingsIcon/>,
        2: <GroupAddIcon/>,
        3: <ContactMailIcon/>, //<VideoLabelIcon/>,
        4: <SaveIcon/>, //
    };

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        padding: 16,
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    const translate = useTranslate();
    return [
        translate('Partner'),
        translate('Customers'),
        translate('Address'),
        translate('Save')
    ];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return 'Create client';
        case 1:
            return 'Create Customers';
        case 2:
            return 'Create Address';
        case 3:
            return 'Save';
        default:
            return 'Unknown step';
    }
}

const iniPartner = {
    values: {
        id: null,
        name: '',
        phone: '',
        // phone_clean: '',
        email: '',
        manager: null,
        sales_representative_1: null,
        sales_representative_2: null,
        // customers: [],
        accounting_phone: '',
        accounting_email: '',
        warehouse_phone: '',
    },
    is_valid: false,
    touched: {},
    error: {},
    error_double: {},
}
const iniCustomers = {
    expanded: true,
    values: {
        customer_pricing_property: [],
        user_id: null,
        user_email: '',
        official_name: '',
        name: '',
        item_code_in_accounting_system: '',
        client_code: '',
        phone: '',
        phone2: '',
        phone3: '',
        billing_address: '',
        address: '',
        vat_number: '',
        credit_limit_sum: '',
        credit_limit_days: '',
        retail_margin: '',
        edrpou: '',
        base_warehouse_main_supplier: null,
        credit_limit_currency: {
            "additional": null,
            "id": 2,
            "simbol": "грн",
        },
        nds_key: null,
        contractor_type: null,
        business_type: null,
        how_show_quantity: {
            id: 'INTERVAL>10',
            name: 'Если остаток меньше или равно 10 - отображается точное кол - во, если больше 10 - отображается ">10"'
        },
    },
    is_valid: false,
    touched: {},
    error: {},
    error_double: {},
}
const iniAddress = {
    expanded: true,
    values: {
        id: null,
        auto_address: true,
        address: '',
        house: '',
        flat: '',
        area: null,
        region: null,
        point: null,
        street: null,
        area_hand: '',
        region_hand: '',
        point_hand: '',
        street_hand: '',
        confirmed: false,
        disabled: false,
        name: '',
        lat: 0,
        lon: 0,
        comment: '',
        placement: '',
        deliveries: [],
        customers: [],
    },
    is_valid: false,
    touched: {},
    error: {},
    error_double: {},
}
export const TabCreatePartner = (props) => {
    const classes = useStyles();
    const translate = useTranslate();

    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const [form, setForm] = React.useState({
        partner: iniPartner,
        customers: {[new Date().getTime()]: iniCustomers},
        address: {[new Date().getTime()]: iniAddress},
    });

    const [dataForAlarm, setDataForAlarm] = React.useState(null);
    const [partnerAddress, setPartnerAddress] = React.useState(null);
    const [partnerCustomers, setPartnerCustomers] = React.useState(null);
    const [areas, setAreas] = React.useState([]);
    const [pricingProperty, setPricingProperty] = React.useState([]);
    const [iniPricingProperty, setIniPricingProperty] = React.useState([]);
    const [resultSave, setResultSave] = React.useState(null);
    const [alarmCustomerAddress, setAlarmCustomerAddress] = useState([]);


    useEffect(() => {
        /*async function getCustomerPricingProperty() {

        }*/
        async function areaData() {
            // You can await here
            let req = getRequestOverpass('getAreas', '', '', '')
            const getAreas = await nominatimOpenStreetMap(req);
            let arr = []
            if (getAreas) {
                getAreas.forEach(it => {
                    if (it?.tags['ISO3166-2']) {
                        if (it.tags['ISO3166-2'].indexOf('UA') >= 0) {
                            arr.push({
                                id: it.id,
                                name: it.tags.name,
                                name_ori: it.tags.name,
                                iso: it.tags['ISO3166-2'],
                                tag: it.tags
                            })
                        }
                    }
                })
                arr.sort(function (a, b) {
                    if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
                    if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
                    return 0;
                })
            }
            setAreas(arr)
        }

        getCustomerPricingProperty(setPricingProperty)
        areaData();
    }, []);// eslint-disable-line

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [activeStep]);

    useEffect(() => {
        if (resultSave?.ok) {
            setActiveStep(0)
            setForm({
                partner: iniPartner,
                customers: {[new Date().getTime()]: iniCustomers},
                address: {[new Date().getTime()]: iniAddress},
            });
            setDataForAlarm(null);
            setPartnerAddress(null);
            setPartnerCustomers(null);
            //setAreas([]);
            setPricingProperty([]);
            setIniPricingProperty([]);
            setResultSave(null);
            //window.location.reload()
        }
    }, [activeStep]);// eslint-disable-line

    useEffect(() => {
        console.log('pricingProperty=====!!!=====>', pricingProperty)
        if (pricingProperty) {
            let ini = []
            pricingProperty.forEach(it => {
                ini.push({id: it.id, value: null, name: it.name, slug: it.slug})
            })
            setIniPricingProperty(ini)
            let customerAll = JSON.parse(JSON.stringify(form.customers))
            Object.values(customerAll).map((it, ind) => {
                it.values.customer_pricing_property = ini
            })
            setForm(form => ({
                ...form,
                customers: customerAll,
            }));
        }
    }, [pricingProperty]);// eslint-disable-line

    useEffect(() => {
        if (form.partner.values.id) {
            dataProvider
                .getList('partnerAddress', {
                    filter: {partner: form.partner.values.id.id},
                    pagination: {page: 1, perPage: 300},
                    sort: {field: 'id', order: 'DESC'},
                }).then((response) => {
                const {data = []} = response
                //console.log('response.data=1==========>', data);
                /*data.sort(function (a, b) {
                    if (b.name[locale] && a.name[locale])
                        return b.name[locale] > a.name[locale] ? -1 : 1
                    return 0
                })*/
                setPartnerAddress(data)
            });
            dataProvider
                .getMany('zCustomers', {
                    ids: form.partner.values.id.customers,
                    pagination: {page: 1, perPage: 300},
                    sort: {field: 'id', order: 'DESC'},
                }).then((response) => {
                const {data = []} = response
                console.log('zCustomers==========>', data);
                setPartnerCustomers(data)
            });
        } else {
            setPartnerAddress(null)
        }
    }, [form.partner.values.id]);// eslint-disable-line

    function isValidStep(step) {
        let res = false
        switch (step) {
            case 0: //Partner
                if (form.partner.is_valid) res = true
                break
            case 1: //Customers
                /*if (Object.entries(form.customers).length === 0) {
                    res = form.partner.values.id ? true : false
                } else {
                    res = true
                    Object.values(form.customers).forEach((item) => {
                        if (item.is_valid === false) res = false
                    })
                }*/
                res = true
                Object.values(form.customers).forEach((item) => {
                    if (item.is_valid === false) res = false
                })
                break
            case 2: //Address
                res = true
                Object.values(form.address).forEach((item) => {
                    if (item.is_valid === false) res = false
                })
                if (alarmCustomerAddress.length > 0) res = false
                break
            case 3: //Save
                res = form.partner.is_valid
                Object.values(form.customers).forEach((item) => {
                    if (item.is_valid === false) res = false
                })
                Object.values(form.address).forEach((item) => {
                    if (item.is_valid === false) res = false
                })
                if (alarmCustomerAddress.length > 0) res = false
                break
            default:
                res = true
                break
        }

        return res
    }

    const handleNext = () => {
        if (activeStep < 3) setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    /*const handleReset = () => {
        setActiveStep(0);
    };*/

    const handleSetForm = (snap, index, name, val) => {
        if (index) {
            setForm(form => ({
                ...form,
                [snap]: {
                    ...form[snap],
                    [index]: {
                        ...form[snap][index],
                        values: {
                            ...form[snap][index].values,
                            [name]: val,
                        },
                    }
                }
            }));
        } else {
            setForm(form => ({
                ...form,
                [snap]: {
                    ...form[snap],
                    values: {
                        ...form[snap].values,
                        [name]: val,
                    },
                },
            }));
        }
    }

    const handleTest = async (dt) => {
        const {value} = dt
        let res = null
        if (value.length > 2)
            res = await getUserSearch(setDataForAlarm, dt);

        let error_double
        if (dt.index) {
            error_double = JSON.parse(JSON.stringify(form[dt.snap][dt.index].error_double))
            if (res) {
                error_double[dt.name] = translate('This field already exists')
            } else delete error_double[dt.name]
            setForm(form => ({
                ...form,
                [dt.snap]: {
                    ...form[dt.snap],
                    [dt.index]: {
                        ...form[dt.snap][dt.index],
                        error_double: error_double,
                    }
                }
            }));
        } else {
            let error_double = JSON.parse(JSON.stringify(form[dt.snap].error_double))
            if (res) {
                error_double[dt.name] = translate('This field already exists')
            } else delete error_double[dt.name]

            setForm(form => ({
                ...form,
                [dt.snap]: {
                    ...form[dt.snap],
                    error_double: error_double,
                },
            }));
        }

        console.log('TEST=res=========>', res)
    };
    const handleChangeSel = (snap, index, name, v) => {
        //console.log(index, name, '=====>', v)
        let val = v;
        handleSetForm(snap, index, name, val)
        console.log(snap, index, name, '=====>', val)
    };

    console.log(isValidStep(0), isValidStep(1), 'FORM=====>', form)

    const handleChangeText = (snap, index, name, v, countDigit = 0) => {
        let val = v.target.value
        if (countDigit) val = v.target.value.replace(/\D/g, '')
        handleSetForm(snap, index, name, val)
        // console.log(snap, index, name, '=====>', val)
    };
    const handleChangeCheck = (snap, index, name, v) => {
        //console.log(index, name, '=====>', v)
        let val = v.target.checked;
        handleSetForm(snap, index, name, val)
        //console.log(snap, index, name, '=====>', val)
    };


    return (
        <div className={classes.root}>
            <AlarmDialog
                dataForAlarm={dataForAlarm}
                setDataForAlarm={setDataForAlarm}
                handleSetForm={handleSetForm}
            />
            {/*<Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>*/}
            {/*<Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>*/}
            <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector/>}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            style={{
                                cursor: isValidStep(index - 1) && 'pointer',
                            }}
                            onClick={(event) => {
                                if (isValidStep(index - 1))
                                    setActiveStep(index)
                            }}
                            StepIconComponent={ColorlibStepIcon}
                        >
                            <span style={{
                                fontWeight: isValidStep(index) && "bold",
                                color: isValidStep(index) && "#2196f3"
                            }}>
                                {label}
                            </span>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
            <div>
                {activeStep === 0 &&
                    <Partner
                        {...props}
                        form={form}
                        setForm={setForm}
                        handleChangeSel={handleChangeSel}
                        handleChangeText={handleChangeText}
                        handleTest={handleTest}
                        handleSetForm={handleSetForm}
                        setPartnerAddress={setPartnerAddress}
                        partnerAddress={partnerAddress}
                        setPartnerCustomers={setPartnerCustomers}
                        // partnerCustomers={partnerCustomers}
                        setActiveStep={setActiveStep}
                    />
                }
                {activeStep === 1 &&
                    <Customer
                        {...props}
                        form={form}
                        setForm={setForm}
                        handleChangeSel={handleChangeSel}
                        handleChangeText={handleChangeText}
                        iniCustomers={iniCustomers}
                        handleTest={handleTest}
                        iniPricingProperty={iniPricingProperty}
                        pricingProperty={pricingProperty}
                    />
                }
                {activeStep === 2 &&
                    <Address
                        {...props}
                        form={form}
                        setForm={setForm}
                        handleChangeCheck={handleChangeCheck}
                        handleChangeSel={handleChangeSel}
                        handleChangeText={handleChangeText}
                        iniAddress={iniAddress}
                        handleTest={handleTest}
                        partnerAddress={partnerAddress}
                        setPartnerAddress={setPartnerAddress}
                        partnerCustomers={partnerCustomers}
                        areas={areas}
                        handleSetForm={handleSetForm}
                        alarmCustomerAddress={alarmCustomerAddress}
                        setAlarmCustomerAddress={setAlarmCustomerAddress}
                    />
                }
                {activeStep === 3 &&
                    <SavePartner
                        {...props}
                        form={form}
                        partnerAddress={partnerAddress}
                        setResultSave={setResultSave}
                        resultSave={resultSave}
                        setActiveStep={setActiveStep}
                    />
                }

                {resultSave?.ok
                    ? null
                    :
                    <div style={{marginTop: 32}}>
                        <Button
                            disabled={activeStep === 0}
                            variant="contained"
                            color="primary"
                            onClick={handleBack}
                            className={classes.button}
                        >
                            {translate('Back')}
                        </Button>
                        {activeStep < steps.length - 1 &&
                            <Button
                                disabled={!isValidStep(activeStep)}
                                variant="contained"
                                color="primary"
                                onClick={handleNext}
                                className={classes.button}
                            >
                                {translate('Next')}
                            </Button>
                        }

                        {/*<Button
                            style={{marginLeft: 48}}
                            //disabled={!isValidStep(activeStep)}
                            variant="contained"
                            color="primary"
                            onClick={(event) => {
                                setActiveStep(3)
                            }}
                            //className={classes.button}
                        >
                            {translate('!!! ТОЛЬКО ДЛЯ ОТЛАДКИ SAVE !!!')}
                        </Button>*/}
                    </div>
                }
            </div>
        </div>
    );
}
