import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {CustomerList} from "./CustomerList";
import {CustomerEdit} from "./CustomerEdit";
import {CustomerCreate} from "./CustomerCreate";

const resource = {
    list: CustomerList,
    create: CustomerCreate,
    edit: CustomerEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;