import * as React from 'react';
// import {Admin, ListGuesser, EditGuesser} from 'react-admin';
// import {Admin, createMuiTheme} from 'react-admin';
import {Admin, EditGuesser, ListGuesser, useTranslate} from 'react-admin';
import {Route} from "react-router-dom";
import dataProvider from "./utils/dataProvider";
// import dataProviderFake from "./utils/dataProviderFake";
// import simpleRestProvider from 'ra-data-simple-rest';
import i18nProvider from './utils/i18nProvider';
import MyLayout from "./layout/MyLayout";
import Dashboard from "./Dashboard";

import {Resource} from "@react-admin/ra-rbac";

/*-------COGNITO------------*/
// import {AuthProvider} from 'ra-cognito';
import {buildAuthProvider} from "react-admin-amplify";
import Amplify from 'aws-amplify';
import authProvider from './utils/authProvider';

import UniversalView from './pagesUniversal/universalView'

// import PermIdentity from "@material-ui/core/SvgIcon/SvgIcon";
//import universal from "./universal";
import {tableConfig} from "./tablesConfig/tableConfig";
import {apiPathConfig} from "./utils/config";
import discountProperty from "./pagesZenit/discountProperty";
import customer_z from "./pagesZenit/customer_z";
import discountPropertyValue from "./pagesZenit/discountPropertyValue";
import currency_z from "./pagesZenit/currency_z";
import customer_property from "./pagesZenit/customer_property";
import discount_rules from "./pagesZenit/discount_rules";
import product_property from "./pagesZenit/product_property";
import discount_relationship from "./pagesZenit/discount_relationship";
import product_z from "./pagesZenit/product_z";
import brand_z from "./pagesZenit/brand_z";
import price_row_z from "./pagesZenit/price_row_z";

import test_price from "./pagesZenit/test_price";
import createPartner from "./pagesZenit/createPartner";

import gift_pool from "./pagesZenit/gift_pool";
import partner from "./pagesZenit/partner";
import zCustomers from "./pagesZenit/zCustomers";

import dFeatureType from "./pages/dFeatureType";
import dCustomers from "./pages/dCustomers";
import dProducts from "./pages/dProducts";

import dProductsGroup from "./pages/dProductsGroup";
import dProductsGroupTk from "./pages/dProductsGroupTk";

import dOrders from "./pages/dOrders";
import dPrompts from "./pages/dPrompts";
import dPromptIndustries from "./pages/dPromptIndustries";
import dPromptTypes from "./pages/dPromptTypes";

import dInterfaces from "./pages/dInterfaces";
import dUsersInterfaces from "./pages/dUsersInterfaces";
import dUser from "./pages/dUser";

import dConfig from "./pages/dConfig";
import dProcessing from "./pages/dProcessing";
import dLoadPrice from "./pages/dLoadPrice";
import dCountries from "./pages/dCountries";
import dMenuItems from "./pages/dMenuItems";
import dBlogArticle from "./pages/dBlogArticle";

import dManager from "./pages/dManager";
import dCompanyBranch from "./pages/dCompanyBranch";
import dCurrencies from "./pages/dCurrencies";
import dMessenger from "./pages/dMessenger";
import dPhoneMessenger from "./pages/dPhoneMessenger";
import dSuppliers from "./pages/dSuppliers";
import dExternalSuppliers from "./pages/dExternalSuppliers";
import dBrands from "./pages/dBrands";
import dNews from "./pages/dNews";
import dSeoKeyWords from "./pages/dSeoKeyWords";
import dSizeChart from "./pages/dSizeChart";
import dColor from "./pages/dColor";
import dUnitsOfMeasurement from "./pages/dUnitsOfMeasurement";

import dPromotionBadge from "./pages/dPromotionBadge";
import dPromotionalGood from "./pages/dPromotionalGood";
import dPromotion from "./pages/dPromotion";
import dDiscountConditionsDocument from "./pages/dDiscountConditionsDocument";

import dPaymentProvider from "./pages/dPaymentProvider";
import dPayment from "./pages/dPayment";



import JsonLocalization from "./pages/localization/JsonLocalization";
import edit_sheduler from "./pagesZenit/edit_sheduler";

if (apiPathConfig.getCognito) {
    const amplifyConfig = {
        Auth: {
            //storage: ICognitoStorage,
            //cookieStorage: ICookieStorageData,
            /*cookieStorage: {
                domain: "localhost",
                path: "/",
                expires: 365,
                secure: true
            },*/
            //?? identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab', //REQUIRED - Amazon Cognito Identity Pool ID
            region: process.env.REACT_APP_COGNITO_REGION, //'us-east-1',
            userPoolId: process.env.REACT_APP_COGNITO_POOL_ID, //'us-east-1_naqZRfsYi',
            userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID, //'2t04thphs1r35np5gf367v4hdd',
            /*oauth: {
                   //domain: 'https://cc-react-admin.auth.eu-central-1.amazoncognito.com',
                   scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                   redirectSignIn: 'http://localhost:3000/',
                   redirectSignOut: 'http://localhost:3000/',
                   responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
               }*/
        },
    };

    Amplify.configure(amplifyConfig);
}

/*
const cookieStorage = new CookieStorage(amplifyConfig.Auth.cookieStorage);
// cookie that is set before Cognito redirect to prevent infinite loop if authorization fails due to other reason than "No current user"
const redirectedFromAuthorize = cookieStorage.getItem("redirected-from-authorize");
*/

/*class MyAuthProvider2 extends AuthProvider {
  stop() {
    // ...будет использован для rabbit.stop()
  }
}*/

const showDefault = {
    list: ListGuesser,
    edit: EditGuesser,
}

const customRoutes = [
    <Route exact path="/json-localizations" component={JsonLocalization}/>,
]

//  https://gitlab.stemsc.com/iam-trade/tmp/keyloack-react-admin-auth
const App = () => {
    const translate = useTranslate();
    return (
        <Admin
            authProvider={apiPathConfig.getCognito() ?
                buildAuthProvider({authGroups: ["Admins", "admin"]}) :
                authProvider}
            // authProvider={authProvider}
            //authProvider={buildAuthProvider({authGroups: ["Admins", "admin"]})}

            // dataProvider={dataProviderFake}
            dataProvider={dataProvider}

            // dataProvider={simpleRestProvider(process.env.REACT_APP_API_URL)}
            locale="en"
            //theme={myTheme}
            i18nProvider={i18nProvider}
            layout={MyLayout}
            title="iAM-Trade Admin"
            dashboard={Dashboard}
            customRoutes={customRoutes}
            //loginPage={Login}
            disableTelemetry
        >
            {tableConfig.tablesList?.map((item, index) => {

                if (item.data.fields_data)
                    if (item.tableHandler)
                        return (
                            <Resource key={index} name={item.table} {...item.tableHandler}/>
                        )
                    else
                        return (
                            <Resource key={index} name={item.table} {...UniversalView}/>
                        )


                return (
                    <Resource key={index} name={item.table} {...showDefault}/>
                )
            })
            }

            {/*<Resource name={'universal'} {...universal} options={{label: 'Tickets'}}/>*/}

            {/*===================ADMIN====================================*/}
            <Resource name={'dUser'} {...dUser} options={{label: 'User'}}/>
            <Resource name={'dInterfaces'} {...dInterfaces} options={{label: 'Interfaces'}}/>
            <Resource name={'dUsersInterfaces'} {...dUsersInterfaces} options={{label: 'Users Interfaces'}}/>

            {/*===================E-TRADE ADMIN DEMO ====================================*/}

            {/*------------Классификаторы + CONFIG-----------------------*/}
            <Resource name={'dConfig'} {...dConfig} options={{label: 'Config'}}/>
            <Resource name={'dProcessing'} {...dProcessing} options={{label: 'Processing'}}/>
            <Resource name={'dLoadPrice'} {...dLoadPrice} options={{label: 'Load Price'}}/>

            <Resource name={'dMenuItems'} {...dMenuItems} options={{label: 'Menu Items'}}/>
            <Resource name={'dBlogArticle'} {...dBlogArticle} options={{label: 'Blog Article'}}/>

            <Resource name={'dCountries'} {...dCountries} options={{label: 'Countries'}}/>
            <Resource name={'dSuppliers'} {...dSuppliers} options={{label: 'Suppliers'}}/>
            <Resource name={'dExternalSuppliers'}  {...dExternalSuppliers} options={{label: 'External Suppliers'}}/>
            <Resource name={'dCurrencies'} {...dCurrencies} options={{label: 'Currencies'}}/>

            <Resource name={'dPriceTypes'} options={{label: 'Price Types'}}/>
            <Resource name={'dSuppliersWarehouses'} options={{label: 'Suppliers Warehouses'}}/>
            <Resource name={'dHoliday'} options={{label: 'Holiday'}}/>
            <Resource name={'dAvailableLanguage'} options={{label: 'Available Language'}}/>
            <Resource name={'dCompanyPhone'} options={{label: 'Company Phones'}}/>
            <Resource name={'dCompanyEmails'} options={{label: 'Company Emails'}}/>


            <Resource name={'dMessenger'} {...dMessenger} options={{label: 'Messenger'}}/>
            <Resource name={'dCompanyMessenger'} options={{label: 'Company Messenger'}}/>
            <Resource name={'dCompanyBranchWorkingHours'} options={{label: 'Company Branch Working Hours'}}/>
            <Resource name={'dCompanyBranchPhone'} options={{label: 'Company Branch Phone'}}/>
            <Resource name={'dCompanyBranch'} {...dCompanyBranch} options={{label: 'Company Branch'}}/>
            <Resource name={'dPhoneMessenger'} {...dPhoneMessenger} options={{label: 'Phone Messenger'}}/>
            <Resource name={'dManager'} {...dManager} options={{label: 'Manager'}}/>
            <Resource name={'dManagerCustomer'} options={{label: 'Managers Customer'}}/>
            <Resource name={'dCurrencyRates'} options={{label: 'Currency Rates'}}/>
            <Resource name={'dCurrencyCrossRates'} options={{label: 'Currency Cross Rates'}}/>

            <Resource name={'dSuppliersWarehouses'} options={{label: 'Suppliers Warehouses'}}/>
            <Resource name={'dWarehouseStock'} options={{label: 'Warehouse Stock'}}/>
            {/*------------COMMON-----------------------*/}
            <Resource name={'dFeatureType'} {...dFeatureType} options={{label: 'Product characteristic'}}/>
            <Resource name={'dFeatureValidValue'} options={{label: 'Feature Valid Value'}}/>
            <Resource name={'dProductsFeatureValue'} options={{label: 'Products Feature Value'}}/>
            <Resource name={'dProductsGroupFeatureType'} options={{label: 'Products Group Feature Type'}}/>
            <Resource name={'dProductsGroupRelations'} options={{label: 'Products Group Relations'}}/>

            <Resource name={'dBrands'} {...dBrands} options={{label: 'Brands'}}/>
            <Resource name={'dBrandImage'} options={{label: 'Brand Image'}}/>

            <Resource name={'dCustomers'} {...dCustomers} options={{label: 'Customers'}}/>
            <Resource name={'dProducts'} {...dProducts} options={{label: 'Products'}}/>
            <Resource name={'dPersonalDiscountsByProduct'} options={{label: 'Personal Discounts By Product'}}/>
            <Resource name={'dRelatedProducts'} options={{label: 'Related Products'}}/>

            <Resource name={'dProductsGroup'} {...dProductsGroup} options={{label: 'Categories'}}/>
            <Resource name={'dProductsGroupTk'} {...dProductsGroupTk} options={{label: 'Categories'}}/>

            <Resource name={'dVariantProductImage'} options={{label: 'Variant Product Image'}}/>
            <Resource name={'dVariantProduct'} options={{label: 'Variant Product'}}/>
            <Resource name={'dCharacteristicProduct'} options={{label: 'Characteristic Product'}}/>
            <Resource name={'dProductToProductGroup'} options={{label: 'Other Product Groups'}}/>


            <Resource name={'dProductsPrice'} options={{label: 'Products Price'}}/>

            <Resource name={'dPrompts'} {...dPrompts} options={{label: 'Prompts'}}/>
            <Resource name={'dPromptIndustries'} {...dPromptIndustries} options={{label: 'Prompt Industries'}}/>
            <Resource name={'dPromptTypes'} {...dPromptTypes} options={{label: 'Prompt Types'}}/>

            <Resource name={'dOrders'} {...dOrders} options={{label: 'Orders'}}/>
            <Resource name={'dOrdersForChart'} options={{label: 'Orders for Chart'}}/>

            <Resource name={'dNews'} {...dNews} options={{label: 'News'}}/>
            <Resource name={'dNewsType'} options={{label: 'News Type'}}/>

            <Resource name={'dSeoKeyWords'} {...dSeoKeyWords} options={{label: 'News Seo Keys'}}/>

            <Resource name={'dSizeChart'} {...dSizeChart} options={{label: 'Size Chart'}}/>
            <Resource name={'dSizeSpecification'} options={{label: 'Size Specification'}}/>
            <Resource name={'dSizeProduct'} options={{label: 'Size Product'}}/>

            <Resource name={'dColor'} {...dColor} options={{label: 'Color'}}/>
            <Resource name={'dVariantsColors'} options={{label: 'Variant Color'}}/>
            <Resource name={'dUnitsOfMeasurement'} {...dUnitsOfMeasurement} options={{label: 'Units Of Measurement'}}/>

            <Resource name={'dPromotion'} {...dPromotion} options={{label: 'Promotion'}}/>
            <Resource name={'dPromotionBadge'} {...dPromotionBadge} options={{label: 'Promotion Badge'}}/>
            <Resource name={'dDiscountConditionsDocument'} {...dDiscountConditionsDocument} options={{label: 'Discount Document'}}/>
            <Resource name={'dPromotionalGood'} {...dPromotionalGood} options={{label: 'Promotional Good'}}/>

            <Resource name={'dPaymentProvider'} {...dPaymentProvider} options={{label: 'Payment Provider'}}/>
            <Resource name={'dPayment'} {...dPayment} options={{label: 'Payments'}}/>

            {/*=======================ZENIT====================================*/}
            <Resource name={'zCustomers'} {...zCustomers} options={{label: 'Customers'}}/>
            <Resource name={'dCustomerPhone'} options={{label: translate('Customer Phone')}}/>
            <Resource name={'dCustomerUser'} options={{label: translate('Customer User')}}/>
            <Resource name={'partner'} {...partner} options={{label: 'Partner'}}/>
            <Resource name={'partnerAddress'}  options={{label: 'Partner Address'}}/>
            <Resource name={'delivery'}  options={{label: 'Delivery'}}/>

            <Resource name={'customer_z'} {...customer_z} options={{label: 'Customer'}}/>
            <Resource name={'product_property'} {...product_property} options={{label: 'Product Property'}}/>
            <Resource name={'customer_property'} {...customer_property} options={{label: translate('Customer property')}}/>
            <Resource name={'discountProperty'} {...discountProperty} options={{label: 'Discount Property'}}/>
            <Resource name={'discountPropertyValue'} {...discountPropertyValue}
                      options={{label: 'Discount Property Value'}}/>
            <Resource name={'currency_z'} {...currency_z} options={{label: 'Currency'}}/>
            <Resource name={'discount_relationship'} {...discount_relationship}
                      options={{label: 'Discount Relationship'}}/>

            <Resource name={'discount_rules'} {...discount_rules} options={{label: 'Discount Rules'}}/>
            <Resource name={'brand_discount_by_rule'} options={{label: 'Brand Discount By Rule'}}/>
            <Resource name={'brand_z'} {...brand_z} options={{label: 'Brands'}}/>
            <Resource name={'price_row_z'} {...price_row_z} options={{label: 'Price Row'}}/>
            <Resource name={'product_discount_by_rule'} options={{label: 'Product Discount By Rule'}}/>
            <Resource name={'product_z'} {...product_z} options={{label: 'Product'}}/>
            <Resource name={'discount_values_by_rule'} options={{label: 'Discount Value By Rule'}}/>
            <Resource name={'complicated_discount_by_rule'} options={{label: 'Complicated Discount By Rule'}}/>
            <Resource name={'customer_discount_by_rule'} options={{label: 'customer_discount_by_rule'}}/>

            <Resource name={'gift_pool'} {...gift_pool} options={{label: 'Bonuses'}}/>
            <Resource name={'gift'} options={{label: 'Bonus'}}/>

            <Resource name={'test_price'} {...test_price} options={{label: 'Test Price'}}/>
            <Resource name={'createPartner'} {...createPartner} options={{label: 'Create client'}}/>
            <Resource name={'edit_sheduler'} {...edit_sheduler} options={{label: 'Матрица складов'}}/>

        </Admin>
    )
};
/*some changes*/

export default App;
