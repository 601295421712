import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {DiscountRulesList} from "./DiscountRulesList";
import {DiscountRulesEdit} from "./DiscountRulesEdit";
import {DiscountRulesCreate} from "./DiscountRulesCreate";

const resource = {
    list: DiscountRulesList,
    create: DiscountRulesCreate,
    edit: DiscountRulesEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;