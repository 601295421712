import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    useTranslate,
    //useLocale,
    BooleanInput, AutocompleteInput, ReferenceInput, useLocale,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
// import {validateEmail} from "../utils/components/validateTable";
import {DateInputComp, ImageInputComp, NumberInputFloatComp} from "../../_common/CompReact";
import {MyTranslatableInputsRich} from "../../_common/MyTranslatableInputsRich";

const BlockEditImg = props => {
    const translate = useTranslate();
    //const {related_id} = props
    //const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box mb="1em" flex={1} display="flex" alignItems={"top"}>
                <Box flex={1} alignItems={"top"} mr="1em"
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <Box p={'1em'}>
                        <NumberInputFloatComp
                            variant={"outlined"}
                            fullWidth
                            source="image_resolution"
                            label={translate('Image resolution')}
                        />
                    </Box>
                    <ImageInputComp
                        maxSize={700000}
                        heightImg={180}
                        source={'image'}
                        // label={`${'New'} ${'image'}`}
                        label={'Image -'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Image</b> here (700kb max)</p>}
                    />
                </Box>

                <Box flex={1} alignItems={"top"}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <Box p={'1em'}>
                        <NumberInputFloatComp
                            variant={"outlined"}
                            fullWidth
                            source="banner_desktop_resolution"
                            label={translate('Banner desktop resolution')}
                        />
                    </Box>
                    <ImageInputComp
                        maxSize={700000}
                        heightImg={180}
                        source={'banner_desktop'}
                        // label={`${'New'} ${'image'}`}
                        label={'Banner Desktop'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Banner Desktop</b> here (700kb max)</p>}
                    />
                </Box>
            </Box>
            <Box mb="1em" flex={1} display="flex" alignItems={"top"}>
                <Box flex={1} alignItems={"top"} mr="1em"
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <Box p={'1em'}>
                        <NumberInputFloatComp
                            variant={"outlined"}
                            fullWidth
                            source="banner_handset_resolution"
                            label={translate('Banner handset resolution')}
                        />
                    </Box>
                    <ImageInputComp
                        maxSize={700000}
                        heightImg={180}
                        source={'banner_handset'}
                        // label={`${'New'} ${'image'}`}
                        label={'Banner Handset - '}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Banner Handset</b> here (700kb max)</p>}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <Box p={'1em'}>
                        <NumberInputFloatComp
                            variant={"outlined"}
                            fullWidth
                            source="banner_tablet_resolution"
                            label={translate('Banner tablet resolution')}
                        />
                    </Box>
                    <ImageInputComp
                        maxSize={700000}
                        heightImg={180}
                        source={'banner_tablet'}
                        // label={`${'New'} ${'image'}`}
                        label={'Banner Tablet'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Banner Tablet</b> here (700kb max)</p>}
                    />
                </Box>
            </Box>
        </Box>
    )
}
const BlockEdit = props => {
    const translate = useTranslate();
    //const {related_id} = props
    const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source="main_news" label={translate('Main News')} defaultValue={false}/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source="archive" label={translate('Archive')} defaultValue={false}/>
                </Box>
                <Box flex={2} alignItems={"top"}>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'name'}
                        label={translate('Name')}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'annotation'}
                        label={translate('Annotation')}
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('News Type')}
                        source={'type'}
                        reference={'dNewsType'}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'url_repr'}
                        label={translate('Url Repr')}
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <DateInputComp
                        variant={"outlined"}
                        source={"published"} label={"Published"}
                        //validate={validateReq}
                        fullWidth/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <DateInputComp
                        variant={"outlined"}
                        source={"published_to"} label={"Published To"}
                        //validate={validateReq}
                        fullWidth/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <DateInputComp
                        variant={"outlined"}
                        source={"notification"} label={"Notification"}
                        //validate={validateReq}
                        fullWidth/>
                </Box>
                <Box flex={1}>
                    <NumberInputFloatComp
                        variant={"outlined"}
                        fullWidth
                        source="priority"
                        label={translate('priority')}
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"} mt={'-4em'}>
                <Box flex={1}>
                    <MyTranslatableInputsRich
                        //prompt={promptData.find(x => x.field === 'description')}
                        fullWidth
                        source={'HTML'}
                        label={translate('HTML')}
                    />
                </Box>
            </Box>
        </Box>
    )
}
export const NewsEditCreate = props => {//Не работает

    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                //initialValues={{source: localStorage.getItem('username')}}
                defaultValue={{source: localStorage.getItem('username')}}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        related_id={id}
                        //promptData={promptData}
                    />
                </FormTab>
                <FormTab label={translate('Media')}>
                    <BlockEditImg
                        {...props}
                        related_id={id}
                        //promptData={promptData}
                    />
                </FormTab>
                <FormTab label={translate('Description')}>
                    <Box p="1em" fullWidth>
                        <Box display="grid" alignItems={"top"} mt={'-2em'}>
                            <Box flex={1}>
                                <MyTranslatableInputsRich
                                    //prompt={promptData.find(x => x.field === 'description')}
                                    source={'body_email'}
                                    label={'Body Email'}
                                    fullWidth
                                />
                            </Box>
                            <Box flex={1} alignItems={"top"}>
                                <MyTranslatableInputsRich
                                    //prompt={promptData.find(x => x.field === 'description')}
                                    source={'body_push'}
                                    label={'Body Push'}
                                    fullWidth
                                />
                            </Box>
                        </Box>
                    </Box>

                </FormTab>
                {/*{id &&
                    <FormTab label={translate('Prices')}>
                        <Box p="1em" display="flex" fullWidth>
                            <Prices
                                {...props}
                                product_id={id}
                            />
                        </Box>
                    </FormTab>
                }
                {id &&
                    <FormTab label={translate('Variants')}>
                        <Box p="1em" display="flex" fullWidth>
                            <Variants
                                {...props}
                                product_id={id}
                            />
                        </Box>
                    </FormTab>
                }*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default NewsEditCreate
