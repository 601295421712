import * as React from 'react';
import {Box} from "@material-ui/core";

import {canAccess2} from "../../utils/canAccess2";

// import {useTranslate} from "react-admin";
import {CleanCache, GetGoogleMerchantAll, TranslateProduct} from "../../_common/CompForApi/utilsAPI";
import {MyTranslateIcons} from "../../icons/MyTranslateIcons";


export const Processing = props => {

    //const translate = useTranslate();

    return (
        <Box display={'flex'}>
            <Box sx={{width: 250}} display={'grid'} alignItems={'top'}
                 style={{margin: 16, border: '1px solid #ccc', borderRadius: 16, padding: 16, alignContent: "start"}}
            >
                <h4 style={{textAlign: "center"}}>Cache</h4>
                <CleanCache label={'Clear Cache'}/>

            </Box>

            <Box sx={{width: 250}} display={'grid'}
                 style={{
                     margin: 16,
                     border: '1px solid #ccc',
                     borderRadius: 16,
                     padding: 16,
                     textAlign: "left",
                     alignContent: "start"
                 }}
            >
                <h4 style={{textAlign: "center"}}>Market</h4>

                {localStorage.getItem('google_merchant_id') && canAccess2({
                    resource: 'googleMerchant',
                    action: 'list'
                })
                    ? <GetGoogleMerchantAll selectedIds={[]} label={'Create Google merchant'} action='create'/>
                    : false
                }
                {localStorage.getItem('google_merchant_id') && canAccess2({
                    resource: 'googleMerchant',
                    action: 'list'
                })
                    ? <GetGoogleMerchantAll selectedIds={[]} label={'Delete Google merchant'} action='delete'/>
                    : false
                }

            </Box>
            <Box sx={{width: 250}} display={'grid'} alignItems={'top'}
                 style={{margin: 16, border: '1px solid #ccc', borderRadius: 16, padding: 16, alignContent: "start"}}
            >
                <h4 style={{textAlign: "center"}}>Translate + SEO</h4>

                {canAccess2({
                    resource: 'translateOnBackEnd',
                    action: 'list'
                })
                    ? <TranslateProduct label={'Generate'} icon={<MyTranslateIcons/>}/>
                    : false
                }

            </Box>
        </Box>
    )
};