import * as React from 'react';
import {
    // useNotify,
    useRedirect,
    // useRefresh,
} from 'react-admin';

import EditComponentAdmin from "../../_common/EditComponentAdmin";
import PartnerEditCreate from "./PartnerEditCreate";

export const PartnerEdit = props => {
    const {location} = props;
    const {search} = location;
    let customerId = '';
    if(search){
        let url = new URL('https://test/' + search);
        customerId = url.searchParams.get('customerId')
    }

    const redirect = useRedirect();
    /*const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = (data) => {
        notify(`Saved data`);
        redirect(pathname)
        refresh();
    };*/

    return (
        <EditComponentAdmin
            {...props}
            title={''}
            onSuccess={() => redirect('list', props.basePath)}
            //onSuccess={() => customerId ? props.history.goBack() : redirect('list', props.basePath)}
            // onSuccess={onSuccess}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <PartnerEditCreate customerId={customerId}/>
        </EditComponentAdmin>
    )
};