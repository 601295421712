import * as React from 'react';
import {Box, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {fetchJson as httpClient} from "../../utils/keycloak";
//import {Toolbar as MUIToolbar} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import config from "../../utils/config";

import {useEffect} from "react";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useNotify, useTranslate} from "react-admin";
import {formatSpacerNumber} from "../../_common/CompFormat/utils";

import Alert from '@material-ui/lab/Alert';


const GetGifts = (props) => {
    const {result, product_id} = props;
    const {gift_choices} = result;

    if (gift_choices && gift_choices.length > 0) {
        let res = gift_choices.filter((x) => x.purchased_product_id === product_id)
        if (res.length > 0)
            return (
                <Box display={'grid'}>
                    {res.map((it, ind) => {
                        return (
                            <a
                                href={`/#/gift_pool/${it.gif_pool_id}`}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                {it.gif_pool_name}
                            </a>
                        )
                    })
                    }
                </Box>
            )
        else return null
    } else return null;
}
const TblGift = (props) => {
    const {result} = props;
    const {gift_choices} = result;
    const translate = useTranslate();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="spanning table" style={{width: '100%'}}>
                <TableHead>
                    <TableRow>
                        <TableCell align={'left'} style={{fontWeight: "bold"}}>{translate('Bonus')}</TableCell>
                        <TableCell align={'center'} style={{fontWeight: "bold"}}>{translate('Product')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gift_choices && gift_choices.length > 0 && gift_choices.map((it, index) => {
                        const {gifts, gif_pool_id, gif_pool_name} = it
                        return (
                            <TableRow key={index}>
                                <TableCell>
                                    <a
                                        href={`/#/gift_pool/${gif_pool_id}`}
                                        target={"_blank"}
                                        rel="noreferrer"
                                    >
                                        {gif_pool_name}
                                    </a>
                                </TableCell>
                                <TableCell>
                                    {gifts.length > 0 && it.gifts.map((it2, index2) => {
                                            return (
                                                <Box display={'flex'} key={index2}>
                                                    <Box flex={2}>
                                                        <span>{it2.gift_product_name}</span>
                                                    </Box>
                                                    {it2.price_all_currencies &&
                                                        it2.price_all_currencies.map((it3, index3) => {
                                                            let pr = Object.entries(it3)
                                                            return (
                                                                <Box flex={1}
                                                                     textAlign={'right'}>{`${pr[0][1]} ${pr[0][0]}`}</Box>)
                                                        })
                                                    }
                                                </Box>
                                            )
                                        }
                                    )}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>

    )
}

const TextDialog = (props) => {
    const {openDialog: data = false, handleCloseDialog} = props;
    const translate = useTranslate();
    //console.log('TEXT===============>', it)
    if (!data) return null
    return (
        <Dialog
            open={data}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><span
                style={{color: '#3f51b5', fontSize: 16}}>{translate(`History`)}</span></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{width: 540}}>

                    <TableContainer component={Paper}>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'left'}
                                               style={{fontWeight: "bold"}}>{translate(`Applied Rule`)}</TableCell>
                                    <TableCell align={'left'}
                                               style={{fontWeight: "bold"}}>{translate(`Absorbed Rule`)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((it, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <a
                                                    style={{textDecoration: "none"}}
                                                    href={`/#/discount_rules/${it.applied_rule_id}`}
                                                    target={"_blank"}
                                                    rel="noreferrer"
                                                >
                                                    {`${it.applied_rule_name}${'\u00a0'}(${it.relation})`}
                                                </a>
                                            </TableCell>
                                            <TableCell>
                                                {it.absorbed_rule_id
                                                    ?
                                                    <a
                                                        style={{textDecoration: "line-through"}}
                                                        href={`/#/discount_rules/${it.absorbed_rule_id}`}
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                    >
                                                        {`${it.absorbed_rule_name}`}
                                                    </a>
                                                    : '---'
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const TestOrder = props => {

    const {
        result,
        setResult,
        openDialog,
        setOpenDialog,
        totalPrice,
        setTotalPrice,
        orderId,
        setOrderId,
        error,
        setError,
    } = props

    const translate = useTranslate();
    const notify = useNotify();

    /*// let ini = JSON.parse(localStorage.getItem("test_price"));
    // if(!ini) ini = iniFilter;
    const [result, setResult] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [totalPrice, setTotalPrice] = React.useState(null);

    const [orderId, setOrderId] = React.useState(null);
    const [error, setError] = React.useState(null);*/

    useEffect(() => {
        if (result) {
            const {product_prices = {}} = result || {}
            let dt = {
                base_price: {name: 'Amount (base price)'},
                regular_price: {name: 'Amount (regular price)'},
                price_with_order_discounts: {name: 'Total order including discounts'},
            }
            product_prices.forEach(it => {
                const {result_price_all_currencies = {}} = it || {}
                Object.entries(result_price_all_currencies).forEach((it2, ind) => {
                    let quantity = it2[1].total_sum / it2[1].price_with_order_discounts
                    if (dt.base_price[it2[0]]) dt.base_price[it2[0]] += it2[1].base_price * quantity
                    else dt.base_price[it2[0]] = it2[1].base_price * quantity
                    if (dt.regular_price[it2[0]]) dt.regular_price[it2[0]] += it2[1].regular_price * quantity
                    else dt.regular_price[it2[0]] = it2[1].regular_price * quantity
                    if (dt.price_with_order_discounts[it2[0]]) dt.price_with_order_discounts[it2[0]] += it2[1].price_with_order_discounts * quantity
                    else dt.price_with_order_discounts[it2[0]] = it2[1].price_with_order_discounts * quantity
                })
            })
            setTotalPrice(dt);
            //console.log('regular_price====================>', dt)
        }
    }, [result]);// eslint-disable-line

    const getData = async (orderId) => {
        setResult(null)
        // const url = config.apiAuthUrl + `api/get-order-pricing-data/${orderId}/?show_relation_history=true`
        const url = config.apiUrl + `api/get-order-pricing-data/${orderId}/`
        //const url = config.apiAuthUrl + `api/react-admin/main/Order/${orderId}`
        // ??? 500 ???  const url = config.apiAuthUrl + `api/react-admin/main/Order/`
        try {
            const response = await httpClient(`${url}`)
            if (response.status >= 200 && response.status <= 300) {
                const {json: dt} = response
                const {message} = dt || {}
                //console.log('response=========>', dt);
                setResult(dt);
                if (message) {
                    //alert(response.json.message)
                    notify(JSON.stringify(message), {type: 'info', autoHideDuration: 8000,});
                }
            } else {

            }
        } catch (err) {
            const {body} = err
            const {detail} = body
            if (detail) setError(detail)
            notify(JSON.stringify(err), {type: 'error',});
        } finally {
            //console.log('finally=======>', 'finally');
        }
    }

    const handleClick = () => {
        if (orderId) {
            setError(null)
            getData(orderId);
        }
    }

    const handleChangeId = (event) => {
        if (error) setError(null)
        setOrderId(event.target.value)
    }

    const handleClickOpenDialog = (event, data) => {
        event.preventDefault();
        event.stopPropagation();
        setOpenDialog(data);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    function getHistory(data, text = true) {
        let store_history = '';
        data.forEach((it, index) => {
            if (text) {
                store_history += `${it.applied_rule_name}${'\u00a0'}(${it.relation})`;
                if (it.absorbed_rule_id) store_history += ` ==> ${it.absorbed_rule_name}`;
                store_history += '\n'
            } else {
                store_history += <div>
                    store_history += <span key={index}>{`${it.applied_rule_name}${'\u00a0'}(${it.relation})`}</span>
                    if(it.absorbed_rule_id) store_history += <span key={index}>{`${it.absorbed_rule_name}`}</span>
                    store_history += </div>
            }
        })
        return store_history
    }

    //const {total_sum = {}} = result || {}
console.log('orderId===========>', orderId)
    return (
        <Box
            sx={{width: '100%'}}
            //     style={{margin: 16, border: '1px solid #ccc', borderRadius: 4, backgroundColor: "white", padding: 16}}
        >
            <TextDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
            />
            <h3>{translate(`Test Order`)}</h3>
            <Box display="flex" alignItems={"center"} mb={4}>
                <Box width={'12em'} mr={"1em"}>
                    <TextField
                        size={"small"}
                        id="outlined-basic"
                        label="Order Id"
                        variant="outlined"
                        type={"number"}
                        onChange={(event) => handleChangeId(event)}
                        value={orderId}
                    />
                </Box>
                <Box width={'12em'} mr={"1em"}>
                    <Button
                        disabled={!orderId}
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        fullWidth
                    >
                        {translate('Test')}
                    </Button>
                </Box>
                <Box width={'14em'} mr={"1em"}>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={(event) => {
                            setResult(null)
                            setError(null)
                        }}
                        fullWidth
                    >
                        {translate('Clear Result')}
                    </Button>
                </Box>
                {/*<Box flex={1}>
                </Box>*/}
            </Box>

            {error &&
                <Alert severity='error'>{translate('Order')} {orderId}: {error}</Alert>
            }

            {result &&
                <>
                    <h4>{translate('Order')} <span style={{color: '#2196f3'}}>{orderId}</span></h4>
                    {totalPrice && Object.values(totalPrice).map((it, ind) => {
                        return (
                            <h4 key={`t_${ind}`} style={{marginTop: -16}}>
                                {translate(`${it.name}`)}: (
                                {Object.entries(it).map((it2, ind) => {
                                    return (
                                        it2[0] === 'name'
                                            ? null
                                            :
                                            <span key={`s_${ind}`}
                                                  style={{fontWeight: it2[0] === 'UAH' ? 'bold' : 'normal'}}>
                                                    {`${formatSpacerNumber(it2[1])} ${it2[0]}, `}
                                            </span>
                                    )
                                })}
                                )</h4>
                        )
                    })
                    }


                    <TableContainer component={Paper}>
                        <Table aria-label="spanning table" style={{width: '100%'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'left'}
                                               style={{fontWeight: "bold"}}>{translate('Product')}</TableCell>
                                    <TableCell align={'right'}
                                               style={{fontWeight: "bold"}}>{translate('Base price')}</TableCell>
                                    <TableCell align={'right'}
                                               style={{fontWeight: "bold"}}>{translate('Fix price')}</TableCell>
                                    <TableCell align={'right'}
                                               style={{fontWeight: "bold"}}>{translate('Regular price')}</TableCell>
                                    <TableCell align={'right'}
                                               style={{fontWeight: "bold"}}>{translate('Price with order discounts')}</TableCell>
                                    <TableCell align={'left'}
                                               style={{fontWeight: "bold"}}>{translate('Regular discounts')}</TableCell>
                                    <TableCell align={'left'}
                                               style={{fontWeight: "bold"}}>{translate('Order discounts')}</TableCell>
                                    <TableCell align={'center'}
                                               style={{fontWeight: "bold"}}>{translate('Bonus')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.product_prices && result.product_prices.map((it, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Box display={'grid'}>
                                                <span>{it.product.name}</span>
                                                <span>{it.product.article}</span>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'} style={{textAlign: "right"}}>
                                                {Object.values(it.result_price_all_currencies).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    return (
                                                        <span
                                                            key={`base_price${index2}`}>{`${it2.base_price}${'\u00a0'}${it2.currency.name}`}</span>
                                                    )
                                                })
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'} style={{textAlign: "right"}}>
                                                {Object.values(it.result_price_all_currencies).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    return (
                                                        <span
                                                            key={`fix_price${index2}`}>{`${it2.fix_price}${'\u00a0'}${it2.currency.name}`}</span>
                                                    )
                                                })
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'} style={{textAlign: "right"}}>
                                                {Object.values(it.result_price_all_currencies).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    return (
                                                        <span
                                                            key={`regular${index2}`}>{`${it2.regular_price}${'\u00a0'}${it2.currency.name}`}</span>
                                                    )
                                                })
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'} style={{textAlign: "right"}}>
                                                {Object.values(it.result_price_all_currencies).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    return (
                                                        <span
                                                            key={`>price_discounts${index2}`}>{`${it2.price_with_order_discounts}${'\u00a0'}${it2.currency.name}`}</span>
                                                    )
                                                })
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'}>
                                                {Object.values(it.regular_discounts.applied_discounts_list).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    let fraze;
                                                    if (it2.use_fix_price) {
                                                        fraze = `${it2.fix_price}${'\u00a0'}${it2.fix_price_currency_iso3}`
                                                    } else {
                                                        fraze = `${it2.discount}${'\u00a0'}${'%'}`
                                                    }
                                                    return (
                                                        <a key={`regular_discounts${index2}`}
                                                           href={`/#/discount_rules/${it2.rule_id}`}
                                                           target={"_blank"}
                                                           rel="noreferrer"
                                                        >
                                                            {`${it2.rule_name} (${fraze})`}</a>
                                                    )
                                                })
                                                }
                                                {it.regular_discounts.applied_discounts_list.length > 0 &&
                                                    <span style={{
                                                        fontWeight: "bold",
                                                        marginTop: 4
                                                    }}>Total discount {it.regular_discounts.total_discount.discount}{'\u00a0'}%</span>
                                                }
                                                {it.regular_discounts.store_history.length > 0 &&
                                                    <span
                                                        style={{cursor: 'pointer', color: "blue"}}
                                                        title={getHistory(it.regular_discounts.store_history)}
                                                        onClick={(event) => {
                                                            handleClickOpenDialog(event, it.regular_discounts.store_history)
                                                        }}
                                                    >
                                                        {translate('History')}
                                                    </span>
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'}>
                                                {Object.values(it.order_discounts.applied_discounts_list).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    let fraze;
                                                    if (it2.use_fix_price) {
                                                        fraze = `${it2.fix_price}${'\u00a0'}${it2.fix_price_currency_iso3}`
                                                    } else {
                                                        fraze = `${it2.discount}${'\u00a0'}${'%'}`
                                                    }
                                                    return (
                                                        <a key={`order_discounts${index2}`}
                                                           href={`/#/discount_rules/${it2.rule_id}`}
                                                           target={"_blank"}
                                                           rel="noreferrer"
                                                        >
                                                            {`${it2.rule_name} (${fraze})`}</a>
                                                    )
                                                })
                                                }
                                                {it.order_discounts.applied_discounts_list.length > 0 &&
                                                    <span style={{
                                                        fontWeight: "bold",
                                                        marginTop: 4
                                                    }}>Total discount {it.order_discounts.total_discount.discount}{'\u00a0'}%</span>
                                                }
                                                {it.order_discounts.store_history.length > 0 &&
                                                    <span
                                                        style={{cursor: 'pointer', color: "blue"}}
                                                        title={getHistory(it.order_discounts.store_history)}
                                                        onClick={(event) => {
                                                            handleClickOpenDialog(event, it.order_discounts.store_history)
                                                        }}
                                                    >{translate('History')}</span>
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            <GetGifts result={result} product_id={it.product.id}/>
                                        </TableCell>
                                    </TableRow>))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {result.gift_choices && result.gift_choices.length > 0 &&
                        <>
                            <h4>{translate('BONUSES')}</h4>
                            <TblGift result={result}/>
                        </>
                    }
                </>
            }

        </Box>
    )
};