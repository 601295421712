import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    NumberField, useTranslate, NumberInput, BooleanInput, FormDataConsumer,
} from 'react-admin';
import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {BooleanFieldComp, DateFieldComp, NumberInputFloatComp} from "../../_common/CompReact";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateReq} from "../../_constants/validateTable";
import {FloatNumber} from "../../_common/CompFormat/utils";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {discount_rule_id, fix_price} = props;
    const translate = useTranslate();

    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});

    return (
        <>
            <RowForm
                {...props}
                initialValues={{discount_rule_id: discount_rule_id}}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <ReferenceInput source="brand_id" reference="brand_z"
                                label={translate('Brand')}
                                validate={validateReq}>
                    <AutocompleteInput resettable optionText="name" fullWidth/>
                </ReferenceInput>
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                        if (!formData.use_fix_price)
                            return (
                                <NumberInput source={'discount'} label={translate('Discount')} style={{maxWidth: 120}}
                                             validate={validateReq}/>
                            )
                        else return null
                    }}
                </FormDataConsumer>
                {fix_price &&
                    <BooleanInput source="use_fix_price" label={translate('Use Fix Price')} defaultValue={false}/>
                }
                {fix_price &&
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            if (formData.use_fix_price)
                                return (
                                    <div style={{display: "flex"}}>
                                        <NumberInputFloatComp source="fix_price" label={translate('Fix Price')}
                                                              validate={validateReq}
                                                              style={{maxWidth: 160}}
                                        />
                                        <div style={{marginLeft: 16, maxWidth: 100}}>
                                            <ReferenceInput source="fix_price_currency_id" reference="currency_z"
                                                            defaultValue={980}
                                                            label={translate('Currency')}
                                                            validate={validateReq}
                                                            fullWidth
                                            >
                                                <AutocompleteInput resettable optionText="name" fullWidth/>
                                            </ReferenceInput>
                                        </div>
                                    </div>
                                )
                            else return null
                        }}
                    </FormDataConsumer>
                }
            </RowForm>
        </>
    )
};

const ColumnsForList = (resource, classes, fix_price) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        brand_id: <ReferenceField source="brand_id" reference="brand_z" label={translate('Brand')}
                                  link={false}
                                  sortBy={'brand_z:name'}>
            <TextField source="name"/>
        </ReferenceField>,
        discount: <NumberField source="discount" label={translate('Discount')}/>,

        use_fix_price: <BooleanFieldComp source="use_fix_price" label={translate("Use Fix Price")}
                                         textAlign={"center"}/>,
        fix_price:
            <div label={'Fix Price'}>
                <FloatNumber source={`fix_price`} label={translate('fix_price')}/>{' '}
                <ReferenceField source="fix_price_currency_id" reference="currency_z"
                                label={translate('Fix Price Currency')}
                                link={false}
                                sortBy={'currency_z:name'}>
                    <TextField source="name"/>
                </ReferenceField>
            </div>,
        created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
    }

    if (!fix_price) {
        delete columns.use_fix_price
        delete columns.fix_price
        delete columns.fix_price_currency_id
    }

    return columns;
};

const BrandsByRule = props => {
    // const {customer_id, record, isSave = true} = props;
    const {discount_rule_id, slug} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    let fix_price = true;
    if (slug === 'order.price_of_week'
        || slug === 'product.brands')
        fix_price = false;


    const columns = useSelectedColumns({
        preferences: `${'brands_bu_rule'}_${slug}_list.columns`,
        columns: ColumnsForList('brands_bu_rule', classes, fix_price),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "fit-content", minWidth: 500, marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Brand Discount By Rule"
                reference="brand_discount_by_rule"
                target="discount_rule_id"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} fix_price={fix_price}
                                              discount_rule_id={discount_rule_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} fix_price={fix_price}
                                            discount_rule_id={discount_rule_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default BrandsByRule;