import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    TextInput, useTranslate, SelectInput,
    FormDataConsumer, BooleanInput,
} from 'react-admin';

import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
import {
    DateFieldComp,
    DisabledField,
    TextFieldButton
} from "../../_common/CompReact";
//import useMyStyles from "../utils/useMyStyles";
//import {useSelector} from "react-redux";
//import {useForm} from "react-final-form";
import {useEffect} from "react";
// import {config} from "../utils/config";
import {validateFirstName, validateReq} from "../../_constants/validateTable";
import {choicesRuleType} from "../../_constants/choices";
import {useState} from "react";
import CustomerPropertyValue from "./CustomerPropertyValue";
import ProductPropertyValue from "./ProductPropertyValue";
import DiscountPropertyValue2 from "./DiscountPropertyValue2";

//import {validateReq} from "../utils/components/validateTable";
//import get from "lodash/get";
/*
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
*/
function getTbl(rule_type) {
    let tbl = '';
    switch (rule_type) {
        case 'customer':
            tbl = 'Customer Property Value';
            break;
        case 'product':
            tbl = 'Product Property Value';
            break;
        case 'order':
            tbl = '';
            break;
        case 'complicated_rule':
            tbl = '';
            break;
        default:
            tbl = '';
    }
    return tbl;

}

const BlockEditCreate = props => {

    const {formData} = props;
    const translate = useTranslate();
    // const formEdit = useForm();
    //const classes = useMyStyles();

    const [tableForProperty, setTableForProperty] = useState('');

    useEffect(() => {
        if (formData.rule_type) {
            setTableForProperty(getTbl(formData.rule_type));
        } else {
            setTableForProperty('');
        }
    }, [formData.rule_type]);// eslint-disable-line

    return (
        <Box p="1em" fullWidth>

            {formData.protected &&
                <Box display="flex" alignItems={"center"}>
                    <Box flex={1} mr={'2em'} mb={'20px'}>
                        <DisabledField type={'text'} text={'YES'} label={translate('Protected')}/>
                    </Box>
                </Box>
            }

            {!formData.protected && formData.rule_type === "customer" &&
                /*show_on_creation_customer_process*/
                <Box display="flex" alignItems={"center"}>
                     <BooleanInput
                         source={'show_on_creation_customer_process'}
                         label={translate('Show on creation customer process')}
                         defaultValue={false}
                     />
                </Box>
            }
            <Box display="flex" alignItems={"center"}>
                {formData.protected
                    ?
                    <Box flex={2} mr={'2em'} mb={'20px'}>
                        <DisabledField type={'text'} text={formData.name} label={translate('Name')}/>
                    </Box>
                    :
                    <Box flex={2} mr={'2em'}>
                        <TextInput source={`name`} label={translate('Name')} validate={validateFirstName}
                                   fullWidth/>
                    </Box>
                }
                {formData.id
                    ?
                    <Box flex={1} mr={'2em'} mb={'20px'}>
                        <DisabledField type={'text'} text={formData.slug} label={'Slug'}/>
                    </Box>
                    :
                    <Box flex={1} mr={'2em'}>
                        {/*<TextInput source={`slug`} label={translate('Slug')}
                                   fullWidth/>*/}
                    </Box>
                }
                {formData.id
                    ?
                    <Box flex={1} mr={'2em'} mb={'20px'}>
                        <DisabledField type={'text'} text={formData.rule_type} label={translate('Rule Type')}/>
                    </Box>
                    :
                    <Box flex={1} mr={'2em'}>
                        <SelectInput source={'rule_type'} choices={choicesRuleType}
                                     label={translate('Rule Type')}
                                     disableValue="protected"
                                     validate={validateReq} fullWidth/>
                    </Box>
                }
            </Box>

            {formData.id //&& !formData.protected
                ?
                <>
                    <Typography variant="h6">{translate(`Discount Property Value`)}</Typography>
                    <Box display="flex" alignItems={"center"}>
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                return (
                                    <DiscountPropertyValue2
                                        {...props}
                                        discount_property_id={formData.id}
                                        dataProtected = {formData.protected}
                                        //formData={formData}
                                    />
                                )
                            }}
                        </FormDataConsumer>
                    </Box>
                    <Typography variant="h6">{`${translate(tableForProperty)}`}</Typography>
                    <Box display="flex" alignItems={"center"}>
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                //const {customer_id: customerFormId} = formData;
                                //console.log('tableForProperty===========>', tableForProperty)
                                if (tableForProperty === 'Customer Property Value')
                                    return (
                                        <CustomerPropertyValue
                                            {...props}
                                            //customerFormId={customerFormId}
                                            discount_property_id={formData.id}
                                            tableForRule={tableForProperty}
                                            formData={formData}
                                            dataProtected = {formData.protected}
                                        />
                                    )
                                else if (tableForProperty === 'Product Property Value')
                                    return (
                                        <ProductPropertyValue
                                            {...props}
                                            //customerFormId={customerFormId}
                                            discount_property_id={formData.id}
                                            tableForRule={tableForProperty}
                                            formData={formData}
                                            dataProtected = {formData.protected}
                                        />
                                    )
                                else return null;
                            }}
                        </FormDataConsumer>
                    </Box>
                </>
                :
                formData.protected
                    ?
                    <Typography variant="subtitle1" color={"error"}>
                        {translate('PROTECTED RECORDS. Creation of related tables prohibited')}
                    </Typography>
                    :
                    <Typography variant="subtitle1" color={"error"}>
                        {translate('Editing of related tables will be available after record creation')}
                    </Typography>
            }
        </Box>
    )
}
export const DiscountRulesCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    // const classes = useMyStyles();
    const {record} = props;
    const {id, protected: protectRec} = record;
    const translate = useTranslate();
    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)

    /*
        const [countBooking, setCountBooking] = React.useState(0);
        const [arBooking, setArBooking] = React.useState([]);
        const getBooking = async (customer_id) => {
            const url = apiPathConfig.getApiUrl()
            let report_url = `booking/admin/booking`;
            let filter = `?customer_id=${customer_id}&is_invoiced=false&is_paid=false`;
            try {
                const response = await httpClient(`${url}${report_url}${filter}`);
                console.log(response);
                if (response.status >= 200 &&  response.status <= 300) {
                    setCountBooking(response.json.count);
                    setArBooking(response.json.results);
                    } else {

                    }
            } catch (err) {
                console.log('err=======>', err);
            } finally {
                //console.log('finally=======>', 'finally');
            }
        }
        useEffect(() => {
            getBooking(customer_id);
        }, []);// eslint-disable-line
    */

    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box mr="2.5em">
                            <Typography variant="subtitle2" gutterBottom>{translate('Name')}</Typography>
                            <TextFieldButton
                                //itsOriText={record => (`${record.last_name}`)}
                                //copyClipBoard={true}
                                source="name"
                                //source2="f_name"
                                //source3="l_name"
                                backgroundColor={'#eee'}
                            />
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{translate('Created')}{`: `}
                                <DateFieldComp source="created_at" variant="subtitle2" fullWidth/>
                            </div>
                            <div>{translate('Updated')}{`: `}
                                <DateFieldComp source="updated_at" variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <TabbedForm
                {...props}
                /*toolbar={<PostEditDeleteActions goBack={true} saveAndClose={'/invoice'} reload={true}/>}*/
                toolbar={<PostEditDeleteActions goBack={true} saveAndClose={'/discountProperty'}
                                                isSave={!protectRec} del={!protectRec}/>}
                //redirect={redirect_url}
            >
                <FormTab label="Common">
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            return (
                                <BlockEditCreate formData={formData} {...props}/>
                            )
                        }}
                    </FormDataConsumer>
                </FormTab>

                {/*{id &&
                    <FormTab label="History">
                        <HistoryListForFilter {...props} table={'invoice'} invoice_id={id} fullWidth/>
                    </FormTab>
                }*/
                }
            </TabbedForm>
        </>
    )
};

export default DiscountRulesCreate
