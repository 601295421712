import * as React from 'react';

import {
    useTranslate,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import {DateFieldComp} from "../../_common/CompReact";

// const PostEditActions = ({ basePath, data, resource }) => (
const FootherEdit = ({source}) => {
    const translate = useTranslate();
    return (
    <Box display="flex" alignItems={"center"} p="1em">
        <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
            <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                <Typography variant="subtitle2"
                            gutterBottom>{translate('Source')}: <b>{source}</b></Typography>
            </Box>
        </Box>
        <Box flex={1} align={'end'}>
            <Box display="grid">
                <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
            </Box>
        </Box>
    </Box>
)};
export default FootherEdit;
