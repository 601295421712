import * as React from "react";
// import React, { useMemo, CSSProperties } from 'react';
import {useMediaQuery} from '@material-ui/core';
import Welcome from "./Welcome";

// import {formatSpacerNumber} from "../_common/utils";

import OrderChart from "./OrderChart";
import PendingOrders from "./PendingOrders";
//import {usePermissions} from "@react-admin/ra-rbac";


import {useGetList, useListContext, useTranslate} from 'react-admin';
import {canAccess2} from "../utils/canAccess2";
import OriNewCustomers from "./OriNewCustomers";
import OriNewProducts from "./OriNewProducts";
import CardWithIcon from "./CardWithIcon";

// import DollarIcon from "@material-ui/icons/AttachMoney";
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';


const styles = {
    grid: {display: 'grid'},
    leftColSm: {flex: 1},
    rightColSm: {flex: 1},

    flex: {display: 'flex'},
    flexColumn: {display: 'flex', flexDirection: 'column'},
    leftCol: {flex: 1, marginRight: '0.5em'},
    rightCol: {flex: 1, marginLeft: '0.5em'},
    singleCol: {marginTop: '1em', marginBottom: '1em'},
};

const Spacer = () => <span style={{width: '1em'}}/>;
//const VerticalSpacer = () => <span style={{ height: '1em' }} />;

export const Total = (props) => {
    // const { data, isLoading, total } = useListContext()
    const {total} = useListContext()
    return (
        <span>{total}</span>
    );
}
const DashboardInfo = () => {
    const translate = useTranslate();

    const isSmall = useMediaQuery((theme) =>
        theme.breakpoints.down('sm')
    );

    /*const {data: Order} = useGetList('Order', {
        //filter: { date_gte: aMonthAgo.toISOString() },
        //sort: { field: 'date', order: 'DESC' },
        pagination: {page: 1, perPage: 50},
    });*/

    //{data: customers, total, loaded}

    const {data: Orders, total: totalOrder} = useGetList('Order',
        {page: 1, perPage: 10},
         {field: 'updated', order: 'DESC'},
        // {is_new_customer: true},
        //{},
    );
    const {total: totalBasket} = useGetList('ShoppingCart',
        {page: 1, perPage: 1},
        // {field: 'updated', order: 'DESC'},
        // {is_new_customer: true},
        //{},
    );

    /*const products = useGetList('dProducts',
        {page: 1, perPage: 20},
        {field: 'updated', order: 'DESC'},
        {is_populate: true},
        {},
    );*/

    // const countProducts = get(listProducts, 'cachedRequests.pagination.total', null);
    //let tbl = useSelector((state) => state.admin.resources['Products'])
    //const {data: new_users_by_month} = tbl || {};


    return (
        /*<Card>
            <Title title="Welcome to the administration!!!" />
            <CardContent>Coming soon</CardContent>
        </Card>*/
        <div style={{padding: isSmall && 8}}>
            <div style={styles.flexColumn}>
                <Welcome/>
                {canAccess2({resource: 'Dashboard', action: 'list'}) &&
                    <div style={isSmall ? styles.grid : styles.flex}>
                        <div style={isSmall ? styles.leftColSm : styles.leftCol}>
                            <div style={styles.flex}>
                                <CardWithIcon
                                    to="/#/Order"
                                    icon={FormatListNumberedIcon}
                                    title={translate('Orders')}
                                    subtitle={totalOrder}
                                />
                                {/*<MonthlyRevenue value={`${formatSpacerNumber('12345.99')} ${'$'}`}/>*/}
                                <Spacer/>
                                <CardWithIcon
                                    to="/#/ShoppingCart"
                                    icon={ShoppingCartIcon}
                                    title={translate('Basket')}
                                    subtitle={totalBasket}
                                />
                                {/*<NbNewOrders value={170}/>*/}
                            </div>
                            <div style={styles.singleCol}>
                                <OrderChart orders={[]}/>
                            </div>
                            <div style={styles.singleCol}>
                                <PendingOrders orders={Object.values(Orders)}/>
                            </div>
                        </div>
                        <div style={isSmall ? styles.rightColSm : styles.rightCol}>
                            <div style={styles.flex}>
                                <OriNewProducts/>
                                <Spacer/>
                                <OriNewCustomers/>
                                {/*<NewCustomers />*/}
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
};

export default DashboardInfo