import * as React from 'react';
import PropTypes from 'prop-types';
//import Tabs from '@mui/material/Tabs';
//import Tab from '@mui/material/Tab';
import {Tabs, Tab, Box, Typography} from "@material-ui/core";
//import {validateReq} from "../utils/validateTable";
import {useEffect} from "react";
import FeatureValidValue from "./FeatureValidValue";
import ProductsFeatureValue from "./ProductsFeatureValue";

import {useLocale, useTranslate} from "react-admin";
import ProductsGroupFeatureType from "./ProductsGroupFeatureType";
import HeaderRelationTable from "../../_common/Headers/HeaderRelationTable";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const FeatureTypesRelation = (props) => {

    const locale = useLocale();
    const translate = useTranslate();
    const {related_id, record} = props;
    const {name} = record;


    const feature_types_relation = window.localStorage.getItem('feature_types_relation');
    const [value, setValue] = React.useState(feature_types_relation ? parseInt(feature_types_relation) : 0);

    useEffect(() => {
        // let ppp = JSON.parse(window.localStorage.getItem('history_flight_dynamic_seach'));
        // window.localStorage.setItem('history_flight_dynamic_seach', JSON.stringify(ppp));
        // window.localStorage.removeItem('history_flight_dynamic_seach');
        window.localStorage.setItem('feature_types_relation', value);
    }, [value]);// eslint-disable-line

    console.log('==&&&&===========>', props)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}} style={{border: '1px solid #ccc', borderRadius: 8, zIndex: 2}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}
                 style={{backgroundColor: 'aliceblue', borderRadius: '8px 8px 0 0 '}}>
                {/*<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">*/}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // indicatorColor="primary"
                    // textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label={translate('Values')} {...a11yProps(0)} />
                    <Tab label={translate('Products')} {...a11yProps(1)} />
                    <Tab label={translate('Categories')} {...a11yProps(2)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{width: "fit-content"}}>
                <HeaderRelationTable  name={name[locale] ? name[locale] : ''} par={
                    translate(`Possible values for  property " "
                     of a product, allowing for customizable product specifications.`)
                }/>
                <FeatureValidValue
                    {...props}
                    related_id={related_id}
                />
            </TabPanel>
            <TabPanel value={value} index={1} style={{width: "fit-content"}}>
                <HeaderRelationTable name={name[locale] ? name[locale] : ''} par={
                    translate(`List of Products for which a value has been set for " "
                    characteristic.`)
                }/>
                <ProductsFeatureValue
                    {...props}
                    related_id={related_id}
                />
            </TabPanel>
            <TabPanel value={value} index={2} style={{width: "fit-content"}}>
                <HeaderRelationTable par={
                    'For products within these categories, corresponding property values must be assigned.'
                }/>
                <ProductsGroupFeatureType
                    {...props}
                    related_id={related_id}
                />
            </TabPanel>
        </Box>

    );
}
