import * as React from 'react';
import {
    Title,
    TabbedForm,
    FormTab,
    useLocale,
    useTranslate,
    TextInput,
    BooleanInput,
    AutocompleteInput,
    ReferenceInput,
    useGetList, useGetManyReference,
    //useListContext,
    //useEditContext,
} from 'react-admin';
import {Box, useMediaQuery} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";

import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
import {NumberInputFloatComp} from "../../_common/CompReact";
import {MyTranslatableInputsRich} from "../../_common/MyTranslatableInputsRich";
import {useEffect, useState} from "react";
import Variants from "./Variants";
//import {MyEditVariantImg} from "./Variants";
import Prices from "./Prices";
import Seo from "../../_common/Seo";
import PersonalDiscountsByProduct from "./PersonalDiscountsByProduct"

import {Market} from "./Market";
import AdditionalProductsGroup from "./AdditionalProductsGroup";
import Characteristic from "./Characteristic";
import {MyTranslateIcons} from "../../icons/MyTranslateIcons";
import {MyAiIcons} from "../../icons/MyAiIcons";
import {AiProductForImage, TranslateProduct} from "../../_common/CompForApi/utilsAPI";
import {MyImgForSlider} from "../../_common/MyImgForSlider";
import {getPromptForTable} from "../../_common/CompFormat/utils";
import {BaseTab} from "./BaseTab";
import FootherEdit from "../../_common/Headers/FootherEdit";
import config from "../../utils/config";
// import TranslateIcon from '@material-ui/Translate';
// import GTranslateIcon from '@material-ui/GTranslate';
//import FormControlLabel from '@material-ui/core/FormControlLabel';

//----------------------------------------------------------------------
const MyEditVariantImg = (props) => {
    const {productId} = props
    const translate = useTranslate();

    const [variantData, setVariantData] = useState([]);
    const [variantSelData, setVariantSelData] = useState(null);

    const {data: dataVariant = {}, loaded: loadedVariant} = useGetManyReference(
        'dVariantProduct',
        'product',
        productId,
        {page: 1, perPage: 100},
        {},
        {},
    );
    useEffect(() => {
        if (loadedVariant) {
            let tmp = Object.values(dataVariant)
            if (tmp.length > 0) {
                const variant = localStorage.getItem('selectedVariantIdForProducts')
                let res = tmp.find(x => x.id === parseInt(variant))
                if (res) setVariantSelData(res)
                else {
                    setVariantSelData(tmp[0])
                    localStorage.setItem('selectedVariantIdForProducts', tmp[0].id)
                }

            }
            setVariantData(tmp)
        }
    }, [dataVariant, loadedVariant]);// eslint-disable-line

    if (variantData.length < 1)
        return (
            <Box mr={"1em"} style={{border: '1px solid #ddd', borderRadius: 16,}} width={'100%'}>
                {loadedVariant && <h4 style={{textAlign: "center"}}>{translate('Article Variants Not Found')}</h4>}
            </Box>
        )

    return (
        <Box display={variantData.length > 1 ? "flex" : "grid"} alignItems={"top"} width={'100%'}>
            {variantData.length > 10 &&
                <Box
                    display="grid"
                    alignItems={"top"}
                    flex={1}
                    mr={"1em"}
                    p={'1em'}
                    style={{
                        border: '1px solid #ddd',
                        borderRadius: 16,
                    }}
                >
                    <div style={{display: "grid", justifyItems: "center"}}>
                        <h5>{translate('Article Variants')}</h5>
                        {variantData.map((it, ind) => {
                            return (
                                <span
                                    onClick={(e) => {
                                        setVariantSelData(variantData[ind])
                                        localStorage.setItem('selectedVariantIdForProducts', it.id)
                                    }}
                                    key={ind}
                                    style={{
                                        cursor: "pointer",
                                        fontWeight: it.id === variantSelData.id && 'bold',
                                        color: '#2196f3',
                                        maxHeight: 24
                                    }}
                                >
                                    {it.article}
                                </span>
                            )
                        })
                        }
                    </div>
                </Box>
            }
            <Box
                display="flex"
                alignItems={"top"}
                flex={4}
                fullWidth
                style={{
                    border: '1px solid #ddd',
                    borderRadius: 16,
                }}
            >
                {variantSelData &&
                    <MyImgForSlider
                        resourceImg={'dVariantProductImage'}
                        targetImg={'variant'}
                        //oneVariant={variantData.length === 1 ? variantData[0].article : null}
                        variantData={variantData}
                        variantSelData={variantSelData}
                        setVariantSelData={setVariantSelData}
                    />
                }
            </Box>
        </Box>
    )
}
//----------------------------------------------------------------------
const BlockEdit = props => {
    const translate = useTranslate();
    const locale = useLocale();
    const {promptData, productId, productGroup, isSmall} = props
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box style={{display: isSmall ? "grid" : "flex"}} alignItems={"top"} mb={'16px'}>
                {productId &&
                    <Box display="flex" alignItems={"top"} flex={5} mr={"1em"}>
                        <MyEditVariantImg
                            productId={productId}
                        />
                    </Box>
                }
                <Box
                    display="grid" alignItems={"top"} flex={5}
                    style={{
                        maxWidth: !productId && 600,
                        //marginTop: 82,
                        // border: '1px solid #ddd',
                        // borderRadius: 16,
                    }}
                >
                    <Box flex={1}>
                        <BooleanInput source={'hidden_for_user'} label={translate('Hidden For User')}
                                      defaultValue={false}
                                      fullWidth/>
                    </Box>
                    <Box flex={1}>
                        <MyTranslatableInputs
                            prompt={promptData.find(x => x.field === 'name')}
                            fullWidth
                            source={'name'}
                            label={translate('Name')}
                        />
                    </Box>
                    <Box display="flex" alignItems={"top"}>
                        <Box flex={4} mr={"1em"}>
                            <TextInput
                                variant={"outlined"}
                                source={'article'}
                                label={translate('Article')}
                                fullWidth
                            />
                        </Box>
                        <Box flex={6}>
                            <ReferenceInput
                                label={translate('Brand Ref')}
                                source={'brand_ref'}
                                reference={'dBrands'}
                                //filter={{question$id: id}}
                            >
                                <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                                   variant={"outlined"} fullWidth/>
                            </ReferenceInput>
                        </Box>
                    </Box>
                    {/*<Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            source={'article'}
                            label={translate('Article')}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        <MyTranslatableInputs
                            fullWidth
                            source={'brand'}
                            label={translate('Brand')}
                        />
                    </Box>*/}
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            source={'sku'}
                            label={translate('SKU')}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        <MyTranslatableInputs
                            fullWidth
                            source={'name_slug'}
                            label={translate('Name Slug')}
                            // isTranslate={false}
                            slug={'name'}
                        />
                    </Box>
                    <Box flex={1}>
                        <ReferenceInput
                            label={translate('Main Product Group')}
                            source={'product_group'}
                            reference={'dProductsGroup'}
                            //filter={{question$id: id}}
                        >
                            <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                               variant={"outlined"} fullWidth/>
                        </ReferenceInput>
                    </Box>

                    {productId &&
                        <Box flex={1}>
                            <AdditionalProductsGroup
                                {...props}
                                product_id={productId}
                                productGroup={productGroup}
                            />
                        </Box>
                    }

                </Box>
            </Box>

            <Box display="flex" alignItems={"top"} style={{marginTop: -48}}>
                <MyTranslatableInputsRich
                    prompt={promptData.find(x => x.field === 'description')}
                    source={'description'}
                    label={translate('Description')}
                    fullWidth
                />
            </Box>

        </Box>
    )
}

const BlockEdit2 = props => {
    const translate = useTranslate();
    const locale = useLocale();
    //const {productId} = props
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source={'is_new'} label={translate('Is new')} defaultValue={false}/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source={'is_offer'} label={translate('Is offer')} defaultValue={false}/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source={'is_populate'} label={translate('Is populate')} defaultValue={false}/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source={'is_sale'} label={translate('Is sale')} defaultValue={false}/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source={'is_own_manufacturing'} label={translate('Is own manufacturing')}
                                  defaultValue={false}/>
                </Box>
                <Box flex={1}>
                    <BooleanInput source={'massive'} label={translate('Massive')} defaultValue={false} fullWidth/>
                </Box>


            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'article_clean'}
                        label={translate('Article Clean')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <ReferenceInput
                        label={translate('Size_chart')}
                        source={'size_chart'}
                        reference={'dSizeChart'}
                        //filter={{product: productId}}
                    >
                        <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'brand'}
                        label={translate('Brand')}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'brand_clean'}
                        label={translate('Brand Clean')}
                        isTranslate={false}
                        //slug={'name'}
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'index'}
                        label={translate('Index')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <TextInput
                        variant={"outlined"}
                        source={'min_quantity_to_receive_discount'}
                        label={translate('Min Quantity To Receive Discount')}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'country_str'}
                        label={translate('Country')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <TextInput
                        variant={"outlined"}
                        source={'guarantees'}
                        label={translate('Guarantees')}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <NumberInputFloatComp
                        variant={"outlined"}
                        source={'packing_ratio'}
                        label={translate('Packing Ratio')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <NumberInputFloatComp
                        variant={"outlined"}
                        source={'secondary_packing_ratio'}
                        label={translate('Secondary Packing Ratio')}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <NumberInputFloatComp
                        variant={"outlined"}
                        source={'transport_packing_ratio'}
                        label={translate('Transport Packing Ratio')}
                        fullWidth
                    />
                </Box>
            </Box>
        </Box>
    )
}

export const ProductEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/

    const translate = useTranslate();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });
    const {record, resource} = props;
    const {id, product_group, name} = record || {};
    const [promptData, setPromptData] = useState([]);


    const {data: dataPrompts, loading: isLoading} = useGetList(
        "dPrompts",
        {page: 1, perPage: 100},
        {field: 'id', order: 'ASC'},
    );

    useEffect(() => {
        if (dataPrompts) {
            let dt = getPromptForTable(dataPrompts, resource)
            setPromptData(dt)
            //console.log(resource, 'dt=============>',dt)
        }
    }, [dataPrompts, isLoading]);// eslint-disable-line

    // document.title = `iAM-Trade : Products : ${id ? name.en : ''}`
    return (
        <Box>
            <Title
                title={`Products : ${id ? name.en : ''}`}
                preferenceKey="my title"
            />
            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    {id &&
                        <Box display={'flex'}>
                            <TranslateProduct
                                isStatus={true}
                                style={{float: 'right'}}
                                label={'Translate + SEO'}
                                icon={<MyTranslateIcons/>}
                                id={id}
                            />
                            <AiProductForImage
                                isStatus={true}
                                style={{float: 'right'}}
                                label={'AI for image'}
                                icon={<MyAiIcons/>}
                                id={id}
                            />
                        </Box>
                    }
                    <BlockEdit
                        {...props}
                        promptData={promptData}
                        productId={id}
                        productGroup={product_group}
                        isSmall={isSmall}
                    />
                </FormTab>
                <FormTab label={translate('Other')}>
                    <BlockEdit2
                        {...props}
                        promptData={promptData}
                        productId={id}
                    />
                </FormTab>
                {id && config.permission === 'swisslinker' &&
                    <FormTab label={translate('Prices')}>
                        <Box display="grid" alignItems={"top"} fullWidth>
                            <Box flex={1} mb={"1em"}
                                 style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                            >
                                <h4 style={{margin: 0}}>{translate("Prices")}</h4>
                                <Prices
                                    {...props}
                                    product_id={id}
                                />
                            </Box>
                            <Box flex={1}
                                 style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                            >
                                <h4 style={{margin: 0}}>{translate("Personal Discounts")}</h4>
                                <PersonalDiscountsByProduct
                                    {...props}
                                    product_id={id}
                                />
                            </Box>
                        </Box>
                    </FormTab>
                }
                {id && config.permission === 'swisslinker' &&
                    <FormTab label={translate('Variants')}>
                        {/*<Box p="1em" display="flex" fullWidth>
                            <Variants
                                {...props}
                                product_id={id}
                            />
                        </Box>*/}
                        <Box display="grid" alignItems={"top"} fullWidth>
                            <Box flex={1} mb={"1em"}
                                 style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                            >
                                <h4 style={{margin: 0}}>{translate("Variants")}</h4>
                                <Variants
                                    {...props}
                                    product_id={id}
                                />
                            </Box>
                            <Box flex={1}
                                 style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                            >
                                <h4 style={{margin: 0}}>{translate("Characteristic")}</h4>
                                <Characteristic
                                    {...props}
                                    product_id={id}
                                />
                            </Box>
                        </Box>
                    </FormTab>
                }
                {id && localStorage.getItem('google_merchant_id') && config.permission === 'swisslinker' &&
                    <FormTab label={translate('Market')}>
                        {/*<BlockEditMarket
                            {...props}
                            id={id}
                        />*/}
                        <Market
                            {...props}
                            id={id}
                        />
                    </FormTab>
                }
                {id && config.permission === 'swisslinker' &&
                    <FormTab label={translate('Seo')}>
                        <Seo
                            {...props}
                            resource={'dProducts'}
                            id={id}
                            promptData={promptData}
                        />
                    </FormTab>
                }
            </TabbedForm>

            {id &&
                <Box p="2em">
                    <h3>{translate('Related table')}</h3>
                    <BaseTab {...props} related_id={id}/>
                </Box>
            }

            {id && <FootherEdit source={record.source}/>}

        </Box>
    )
};

export default ProductEditCreate
