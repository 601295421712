import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {DiscountPropertyList} from "./DiscountPropertyList";
import {DiscountPropertyEdit} from "./DiscountPropertyEdit";
import {DiscountPropertyCreate} from "./DiscountPropertyCreate";

const resource = {
    list: DiscountPropertyList,
    create: DiscountPropertyCreate,
    edit: DiscountPropertyEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;