import * as React from 'react';
import {
    Box, Grid, Typography,
} from "@material-ui/core";

import {
    useTranslate
} from "react-admin";

export const PreviewCustomers = props => {
    const {
        form,
    } = props

    const translate = useTranslate();

    // let customers = form.customers

    return (
        <Box p="1em" fullWidth>
            {Object.entries(form.customers).map((item, index) => {
                let it = item[1].values
                let ind = item[0]
                let count = Object.values(form.customers).length
                return (
                    <Grid container key={ind} style={{paddingBottom: 16}}>
                        <Grid item xs={12} style={{backgroundColor: 'lightgray'}}>
                            <Typography variant="body" color="textPrimary">
                                <b>{translate("Customer")} {index+1}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} p={1} style={{display: "grid"}}>
                            <Typography variant="body" color="textSecondary">
                                {translate("Name")}: <b>{it.name}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Official name")}: <b>{it.official_name}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Code from the accounting system")}: <b>{it.item_code_in_accounting_system}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Client code")}: <b>{it.client_code}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Main warehouse")}: <b>{it.base_warehouse_main_supplier?.name}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Phone")}: <b>{it.phone}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Physical address")}: <b>{it.billing_address}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Legal address")}: <b>{it.address}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Tax number")}: <b>{it.tax_number}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("EDRPOU")}: <b>{it.edrpou}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("VAT identification number")}: <b>{it.vat_number}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("VAT payer")}: <b>{it.nds_key?.name}</b>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} p={1} style={{display: "grid"}}>
                            <Typography variant="body" color="textSecondary">
                                {translate("Credit limit amount")}: <b>{it.credit_limit_sum}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Credit limit currency")}: <b>{it.credit_limit_currency?.simbol}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Credit limit term (days)")}: <b>{it.credit_limit_days}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Retail margin")}: <b>{it.retail_margin}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Contractor type")}: <b>{it.contractor_type?.name}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("Business type")}: <b>{it.business_type?.name}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {translate("How to show quantity")}: <b>{it.how_show_quantity?.name}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {`${translate("Phone")} 2`}: <b>{it.phone2}</b>
                            </Typography>
                            <Typography variant="body" color="textSecondary">
                                {`${translate("Phone")} 3`}: <b>{it.phone3}</b>
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="body" color="textPrimary">
                                <b>{translate("Settings for pricing")}</b>
                            </Typography>
                        </Grid>
                        {it.customer_pricing_property && it.customer_pricing_property.map((it2, ind2) => {
                            if (it2)
                                return (
                                    <Grid item xs={12} md={12}>
                                        <Typography variant="body" color="textSecondary">
                                            {it2.name}: <b>{it2.value?.name}</b>
                                        </Typography>
                                    </Grid>
                                )
                            else return null
                        })
                        }
                        <Grid item xs={12}>
                            <Typography variant="body" color="textPrimary">
                                <b>{translate("Customer users")}</b>
                            </Typography>
                        </Grid>
                        <Typography variant="body" color="textSecondary">
                            {translate('User')}: <b>{it.user_id ? it.user_id.email : it.user_email}</b>
                        </Typography>
                    </Grid>
                )
            })
            }
        </Box>
    )
}
