import React from 'react';
import {useRecordContext, useUpdate} from "react-admin";

let timer = 0
export const EditTextFieldComp = (props) => {
    const {resource, source, type, maxWidth = 50} = props
    const [update, {loading, error}] = useUpdate();
    const record = useRecordContext(props);

    const [value, setValue] = React.useState(record[source]);
    const handleChange = (event) => {
        // console.log('handleFocus=============>', event.target.value);
        setValue(event.target.value)
        event.preventDefault();
        event.stopPropagation();

        clearTimeout(timer)
        timer = setTimeout(function () {
            if (event.target.value) {
                const data = record
                data[source] = event.target.value
                update(resource, record.id, data, record)
            }
        }, 500);

    }
    const handleSkip = (event) => {
        event.preventDefault();
        event.stopPropagation();
        return false
    }

    return (
        <div onClick={handleSkip} onBlur={handleSkip}>
            <input
                style={{
                    padding: 6,
                    border: '1px solid #ddd',
                    color: loading
                        ? 'green'
                        : error && 'red',
                    width: maxWidth,
                    borderRadius: 2
            }}
                type={type}
                onBlur={handleSkip}
                // onFocus={handleFocus}
                onChange={handleChange}
                value={value}
            />
        </div>
    )
}