import React, {useState, useEffect} from "react";

import "react-tabulator/lib/styles.css"; // default theme
import "react-tabulator/css/bootstrap/tabulator_bootstrap.min.css"; // use Theme(s)

import {ReactTabulator} from "react-tabulator";
import {
    Box,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    InputLabel,
    LinearProgress,
    Select
} from "@mui/material";
import Button from "@material-ui/core/Button";
import {config, localesConfig} from "../../utils/config";
import {getTranslate, getTranslateMany} from "../../_common/CompForApi/utilsAPI";
import {copyToS3bucketLocales} from "../../_common/Transform";

const Langs = localesConfig.locales;// ['fr', 'en'];
const DefaultLangs = localesConfig.defaultLocale;

async function fetchData(dirOnS3bucket) {
    const data = {};

    // Функция для выполнения гет-запроса
    async function fetchDataForLang(lang) {
        const url = `${config.apiForS3bucketLocalesRead}${dirOnS3bucket}/${lang}.json?timeStamp=${Date.now()}`;
        try {
            const response = await fetch(url);
            if (!response.ok) {
                data[lang] = {};
                throw new Error(`Error fetching data for ${lang}: ${response.statusText}`);
            }

            const json = await response.json();
            data[lang] = json;
        } catch (error) {
            console.error('ERROR======>', error.message);
        }
    }

    // Выполняем гет-запросы для каждого языка
    await Promise.all(Langs.map(lang => fetchDataForLang(lang)));
    console.log('data==========>', data)
    return data;
}

function iniFields(Langs) {
    let ini = {}
    Langs.forEach(it => {
        ini[it] = true
    })
    return ini
}

function iniColumns(Langs, fields, handleDelete, handleTranslate) {
    let editableColumns = []
    editableColumns.push(
        {
            title: "Key",
            field: "key",
            width: 150,
            editor: "input",
            headerFilter: "input",
        }
    )
    Langs.forEach(it => {
        if (fields[it])
            editableColumns.push(
                {
                    title: it,
                    field: it,
                    editor: "input",
                    headerFilter: "input",
                    // resizableRows: true,
                    // resizableColumns: true,
                }
            )
    })
    editableColumns.push(
        {
            title: "AI",
            headerSort: false,
            formatter: 'buttonTick',
            width: 80,
            align: "center",
            cellClick: function (e, cell) {
                handleTranslate(e, cell)
            }
        },
    )
    editableColumns.push(
        {
            title: "DEL",
            // ??? sortable: false,
            headerSort: false,
            formatter: "buttonCross",
            width: 80,
            align: "center",
            cellClick: function (e, cell) {
                handleDelete(e, cell)
            },
        },
    )
    /*
        editableColumns.push(
            {
                title: "AI",
                headerSort: false,
                formatter: {
                    function(cell, formatterParams, onRendered) { //plain text value
                        return "<i class='fa fa-print'></i>";
                    }
                },
                width: 80,
                hozAlign: "center",
                cellClick: function (e, cell) {
                    alert("Printing row data for: " + cell.getRow().getData().key)
                }
            },
        )
        editableColumns.push(
            {
                title: "",
                // ??? sortable: false,
                headerSort: false,
                formatter: "reactComponent",
                formatterParams: {
                    component: ({cell}) => {
                        const data = cell.getData();
                        const handleClick = () => {
                            console.log(data);
                        };
                        return <button onClick={handleClick} style={{height: 30, width: 40}}>AI</button>;
                    },
                },
            }
        )
    */
    return editableColumns
}

// Editable Example:

const JsonLocalization = () => {

    const handleDelete = (e, cell) => {
        const row = cell.getRow(); // get row object
        const dt = row.getData(); // get row data
        // console.log(dt.key, 'dt========>', dt); // print name and age
        row.delete(); // delete row
        let tmp = JSON.parse(JSON.stringify(data))
        console.log('tmp========>', tmp);
        let ind = tmp.findIndex(x => x.key === dt.key)
        if (tmp) {
            tmp.splice(ind, 1); // начиная с индекса ind, удалить 1 элемент
            setData(tmp)
        }
        //let tmp = JSON.parse(JSON.stringify(currentText))
    };
    const handleTranslate = async (e, cell) => {
        setProgres(true)
        const row = cell.getRow(); // get row object
        const dt = row.getData(); // get row data
        if (dt.key) {
            let tmp = JSON.parse(JSON.stringify(data))
            let ind = tmp.findIndex(x => x.key === dt.key)
            if (ind >= 0) {
                if (dt[DefaultLangs]) {
                    let arrLangs = []
                    Object.entries(fields).forEach(it => {
                        if (it[0] !== DefaultLangs && it[1]) arrLangs.push(it[0])
                    })
                    let res = await getTranslate(dt[DefaultLangs], arrLangs)
                    console.log('res===>', res)
                    if (typeof res === "object") {
                        arrLangs.forEach((it) => {
                            if (it !== DefaultLangs) {
                                tmp[ind][it] = res[it].name
                            }
                        })
                    }
                    setData(tmp)
                }
            }
        }
        setProgres(false)
        //let tmp = JSON.parse(JSON.stringify(currentText))
    };

    const [dirOnS3bucket, setDirOnS3bucket] = React.useState('site');
    const [progres, setProgres] = React.useState(false);
    const [fields, setFields] = React.useState(iniFields(Langs));
    const [data, setData] = useState([]);
    let editableColumns = iniColumns(Langs, fields, handleDelete, handleTranslate)
    // const [editableColumns, setEditableColumns] = useState(iniColumns(Langs, handleDelete));

    const handleChangeFields = (event) => {
        setFields({...fields, [event.target.name]: event.target.checked});
        // let ind = editableColumns.findIndex(x => x.field === event.target.name)
        // editableColumns.splice(ind, 1);
        editableColumns = iniColumns(Langs, fields, handleDelete)
        setData([])
        setTimeout(function () {
            setData(data)
        }, 1);

    };

    useEffect(() => {
        // Пример использования
        if (dirOnS3bucket) {
            fetchData(dirOnS3bucket).then(result => {
                const resultArray = [];
                // Итерируем по каждому ключу внутри локализаций
                for (const key in result[DefaultLangs]) {
                    const item = {key};
                    //console.log(key, 'item======>', item);

                    // Добавляем перевод для каждой локализации
                    for (const lang in result) {
                        item[lang] = result[lang][key];
                    }

                    // Добавляем объект в итоговый массив
                    resultArray.push(item);
                }

                console.log('resultArray======>', resultArray);
                setData(resultArray)
            });
        }
    }, [dirOnS3bucket])

    // let ref = null;
    //
    // const columns = [
    //     {title: "Key", field: "key", width: 150},
    //     {title: "en", field: "en"},
    //     {title: "fr", field: "fr"}
    // ];
    //
    // const rowClick = (e, row) => {
    //     // console.log("ref table: ", this.ref.current); // this is the Tabulator table instance
    //     // console.log("rowClick id: ${row.getData().id}", row, e);
    //     this.setState({selectedName: row.getData().name});
    // };

    /*const save = () => {
        console.log('data===>', data)
        const resultObject = data.reduce((acc, item) => {
            const {key, fr, en} = item;
            acc.fr[key] = fr;
            acc.en[key] = en;
            return acc;
        }, {en: {}, fr: {}});
        console.log('translationsObject===>', resultObject)
    }*/
    const save = async () => {
        console.log('data===>', data)
        let resObject = {}
        Langs.forEach(it => {
            resObject[it] = {}
        })
        const resultObject = data.reduce((acc, item) => {
            const {key} = item;
            Langs.forEach(it => {
                acc[it][key] = item[it];
            })
            return acc;
        }, resObject);

        let res
        for (const it of Langs) {
            res = await copyToS3bucketLocales(dirOnS3bucket,`${it}.json`, JSON.stringify(resultObject[it]))
            console.log('RESULT UPDATE=======>', res)
        }
        //copyToS3bucketLocales('ru.json', {table: 'Стол', test: 'Тест'})

    }
    const addRow = () => {
        let tmp = JSON.parse(JSON.stringify(data))
        //tmp.unshift({key: '', en: '', fr: ""});
        tmp.unshift({});
        setData(tmp);
    }

    const translateAll = async () => {
        setProgres(true)
        let arrLangs = []
        Object.entries(fields).forEach(it => {
            if (it[0] !== DefaultLangs && it[1]) arrLangs.push(it[0])
        })
        let isTranslate = true
        let tmp = JSON.parse(JSON.stringify(data))
        while (isTranslate) {
            let dt = {}
            let i = 0
            tmp.forEach(it => {
                let fl = 0
                arrLangs.forEach(x => {
                    if (it[x] && it[x].length > 0) fl += 1
                })
                if (fl === 0 && i < 60 && it.key && it[DefaultLangs]) {
                    //console.log(it.key,'============>',it[DefaultLangs])
                    i += 1
                    dt[it.key] = it[DefaultLangs]
                }
            })
            if (i > 0) {
                let res = await getTranslateMany(dt, arrLangs)
                if (typeof res === "object") {
                    Object.entries(res).forEach(it => {
                        Object.entries(it[1]).forEach(it2 => {
                            let ind = tmp.findIndex(x => x.key === it2[0])
                            if (ind >= 0) {
                                tmp[ind][it[0]] = it2[1]
                            }
                        })
                    })
                    setData(tmp);
                }
            } else isTranslate = false
        }
        setData(tmp);
        // tmp.unshift({});
        setProgres(false)
    }

    const clearAll = () => {
        setProgres(false)
        setProgres(true)
        let arrLangs = []
        Object.entries(fields).forEach(it => {
            if (it[0] !== DefaultLangs && it[1]) arrLangs.push(it[0])
        })
        let tmp = JSON.parse(JSON.stringify(data))
        tmp.forEach(it => {
            arrLangs.forEach(x => {
                it[x] = ''
            })
        })
        setData(tmp);
        setProgres(false)
    }

    const workloadsTableOptions = {
        index: "id",
        layout: "fitColumns",
        pagination: true,
        paginationSize: 20,
        placeholder: "No Workload Available",

        movableColumns: true,
        resizableRows: true,
        resizableColumns: true,
    };

    console.log('editableColumns======>', editableColumns)

    return (
        <div>
            <Box display={'flex'}>
                <h3>Localization</h3>
                <FormControl variant="outlined" style={{marginLeft: 16, marginTop: 14, minWidth: 150}}>
                    <InputLabel htmlFor="filled-age-native-simple">Dir On S3bucket</InputLabel>
                    <Select
                        size={"small"}
                        native
                        value={dirOnS3bucket}
                        onChange={(event) => {
                            console.log('event=====>', event.target.value)
                            setDirOnS3bucket(event.target.value)
                        }}
                        label="Dir On S3bucket"
                        inputProps={{
                            name: 'dirOnS3bucket',
                            id: 'filled-age-native-simple',
                        }}
                    >
                        <option value={'site'}>Site</option>
                        <option value={'builder'}>Builder</option>
                    </Select>
                </FormControl>
            </Box>

            <FormGroup row>
                {Langs.map((it, index) => {
                    return (
                        <FormControlLabel
                            key={index}
                            control={
                                <Checkbox
                                    checked={fields[it]}
                                    onChange={handleChangeFields}
                                    name={it}
                                    color="primary"
                                />
                            }
                            label={it}
                        />
                    )
                })
                }
            </FormGroup>
            <div style={{marginBottom: 16}}>
                <Button
                    style={{float: "right"}}
                    variant="contained"
                    color="default"
                    onClick={(event) => {
                        save(event)
                    }}
                    //fullWidth
                >
                    Save
                </Button>

                <Button
                    variant="contained"
                    color="default"
                    onClick={(event) => {
                        addRow(event)
                    }}
                    //fullWidth
                >
                    Add Row
                </Button>
                <Button
                    style={{marginLeft: 16}}
                    variant="contained"
                    color="default"
                    onClick={(event) => {
                        translateAll(event)
                    }}
                    //fullWidth
                >
                    Translate All
                </Button>
                <Button
                    style={{marginLeft: 16}}
                    variant="contained"
                    color="default"
                    onClick={(event) => {
                        clearAll(event)
                    }}
                    //fullWidth
                >
                    Clear All
                </Button>
                {/*<button onClick={() => save()}>Save</button>
                <button onClick={() => addRow()}>addRow</button>*/}
            </div>
            {progres && <LinearProgress color="secondary"/>}
            <ReactTabulator
                columns={editableColumns}
                //hiddenColumns={hiddenColumns}

                data={data}
                //footerElement={<span>Save</span>}
                options={workloadsTableOptions}
                tooltips={true}
                // ??? cellEdited={(cell) => console.log('cellEdited===>', cell)}
                // ??? dataChanged={(newData) => console.log('dataChanged===>', newData)}
            />
        </div>
    );
}

export default JsonLocalization;
