import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, TextField,
    useLocale,
    // TextInput,
    useTranslate,
    //ReferenceField,
    ReferenceInput,
    AutocompleteInput, ReferenceField,
} from 'react-admin';

// import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
// import {RowForm} from "@react-admin/ra-editable-datagrid";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import useMyStyles from "../../utils/useMyStyles";
// import {universalTopFilters} from "../universalView/universalTopFilters";
// import {CardContent, useMediaQuery} from "@material-ui/core";
import {CardContent, useMediaQuery} from "@material-ui/core";
import CardComp from "../../_common/Asside/CardComp";
import {
    BooleanFieldComp,
    //CustomerInfo,
    DateFieldComp, FilterDataAside, ImageInfo,
    //NumberInputFloatComp,
    //UserInfo,
} from "../../_common/CompReact";
// import {validateFirstName, validateReq} from "../utils/components/validateTable";
// import {Box} from "@mui/material";
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import CroppedLongField from "../../_common/CroppedLongField";
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import MySimpleList from "../../_common/MySimpleList";

const universalTopFilters = (resource, locale, translate) => {
    let dt = [];
    dt.push(<ReferenceInput source="type" reference="dNewsType" label={translate('News Type')} alwaysOn>
        <AutocompleteInput
            optionText={`name[${locale}]`}
            resettable emptyText={'All'}/>
    </ReferenceInput>);
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                <FilterLiveSearch/>
                <TrueFalseFilters field={'show'} label={'Show'}/>
                <FilterDataAside fields={'updated'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};

/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/

/*
id: 3
additional: {}

---HTML: {}
---annotation: {}
---archive: false

---image:null
---image_resolution: 2500
---banner_desktop: null
---banner_desktop_resolution:2500
---banner_handset: null
---banner_handset_resolution:2500
---banner_tablet: null
---banner_tablet_resolution: 2500

---body_email: {}
---body_push: {}
---main_news: false
---name: {}
---notification:null
---priority: 0
---published: "2024-01-03"
---published_to: null
---type: null
---url_repr: {}

source: "admin"
updated: "2024-01-03T18:09:59.541549"
 */
const ColumnsForList = (resource, locale, classes, isSmall) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        // prompt: <TextFieldBold source={`prompt`} label={translate('Prompt')} cellClassName={classes.prompt_name}/>,
        image: <ImageInfo src={'image'} type={'img'} label={translate('Img')}/>,
        type:
            <ReferenceField
                link={false}
                label={translate('News Type')}
                source={'type'}
                reference={'dNewsType'}
                //filter={{question$id: id}}
            >
                <TextField source={`name[${locale}]`}/>
            </ReferenceField>,

        name: <TextField source={`name[${locale}]`}
                         label={translate('Name')}/>,
        main_news: <BooleanFieldComp source="main_news" label={translate("Main News")}
                                     textAlign={"center"}/>,
        archive: <BooleanFieldComp source="archive" label={translate("Archive")}
                                   textAlign={"center"}/>,
        priority: <NumberField source={`priority`}
                               label={translate('Priority')}/>,

        published: <DateFieldComp source="published" label={translate('Published')}
                                  textAlign={'center'} showTime={false}
                                  locale={'us'} cellClassName={classes.date_st}/>,

        published_to: <DateFieldComp source="published_to" label={translate('Published To')}
                                     textAlign={'center'} showTime={false}
                                     locale={'us'} cellClassName={classes.date_st}/>,

        notification: <DateFieldComp source="notification" label={translate('Notification')}
                                     textAlign={'center'} showTime={false}
                                     locale={'us'} cellClassName={classes.date_st}/>,

        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')}
                                textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

export const NewsList = props => {
    const classes = useMyStyles();
    const {resource} = props;

    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });
    const translate = useTranslate();

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });
    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: ["nb_views"],
    });

    return (
        <ListComponentAdmin
            {...props}
            //hasCreate={true}
            title={''}
            actions={<TableActions
                preference={`${resource}${"list.columns"}`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                //select={false}
                create={true}
                filter={true}
            />}
            is_bulkActionButtons={true}
            filters={universalTopFilters(resource, locale, translate)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
};