import * as React from 'react';
import {
    //EditButton,
    //List,
    RecordContextProvider,
    useListContext, useLocale,
    //useReference,
    useGetManyReference, FilterLiveSearch,
    useTranslate, NumberField,
    ReferenceField,
    TextField,
    useRecordContext,
} from 'react-admin';
//import inflection from 'inflection';
import {
    Grid,
    Card,
    CardMedia,
    CardContent,
    CardActions,
    Typography,
    Box,
} from '@mui/material';

import TableActions from "../../_common/TableActions";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import {Link} from "react-router-dom";
import {formatSpacerNumber} from "../../_common/CompFormat/utils";
import {MyTree} from "../../_common/MyTree";
import {DateFieldComp, ImageInfo, TextFieldBold} from "../../_common/CompReact";
import CroppedLongField from "../../_common/CroppedLongField";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import useMyStyles from "../../utils/useMyStyles";
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import get from "lodash/get";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {commonTrueFalse} from "../../_constants/choices";
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import {useMediaQuery} from "@material-ui/core";
//import {useSelector} from "react-redux";
import {useEffect} from "react";
import SelectAndCreateOtherTable from "../../_common/SelectAndCreateOtherTable";
import CardCompWidth from "../../_common/Asside/CardCompWidth";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
//import {Link} from "react-router-dom";
import dataProvider from "../../utils/dataProvider";
import InputLabel from "@material-ui/core/InputLabel";
import {FormControl} from "@material-ui/core";


const Aside = (props) => {
    const {handleChangeParent, parentFilter, table, locale, setFeatureTypeVal, featureTypeVal} = props

    const [type, setType] = React.useState('');
    const [featureTypes, setFeatureTypes] = React.useState([]);

    const [typeValues, setTypeValues] = React.useState([]);

    useEffect(() => {
        dataProvider
            .getList('dFeatureType', {
                filter: {},
                pagination: {page: 1, perPage: 300},
                sort: {field: 'id', order: 'DESC'},
            }).then((response) => {
            const {data = []} = response
            //console.log('response.data=1==========>', data);
            data.sort(function (a, b) {
                if (b.name[locale] && a.name[locale])
                    return b.name[locale] > a.name[locale] ? -1 : 1
                return 0
            })
            setFeatureTypes(data)
        });
    }, []);// eslint-disable-line

    const handleType = (event) => {
        setType(event.target.value)
        setFeatureTypeVal('')
        dataProvider
            .getList('dFeatureValidValue', {
                filter: {feature_type: event.target.value},
                pagination: {page: 1, perPage: 300},
                sort: {field: 'id', order: 'DESC'},
            }).then((response) => {
            const {data = []} = response
            //console.log('response.data==2=========>', data);
            data.sort(function (a, b) {
                if (b.valid_value[locale] && a.valid_value[locale])
                    return b.valid_value[locale] > a.valid_value[locale] ? -1 : 1
                return 0
            })
            setTypeValues(data)
        });
    }

    return (
        <CardCompWidth id="aside_card">
            <CardContent>
                <FilterLiveSearch/>
                <FormControl variant="outlined" size={"small"} style={{marginTop: 12}} fullWidth>
                    <InputLabel id="feature-type-label">Product characteristics</InputLabel>
                    <Select
                        label={'Product characteristics'}
                        labelId="feature-type-label"
                        id="feature-type"
                        value={type}
                        onChange={handleType}
                    >
                        <MenuItem value={''}>{'None'}</MenuItem>
                        {featureTypes.map((it) => {
                            return (
                                <MenuItem value={it.id}
                                          key={it.id}>{it.name[locale] ? it.name[locale] : 'undef'}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <FormControl variant="outlined" size={"small"} style={{marginTop: 12}} fullWidth>
                    <InputLabel id="feature-values-label">Value Characteristics</InputLabel>
                    <Select
                        disabled={type ? false : true}
                        label={'Value Characteristics'}
                        labelId="feature-values-label"
                        id="feature-values"
                        value={featureTypeVal}
                        onChange={(event) => {
                            setFeatureTypeVal(event.target.value)
                        }}
                    >
                        {typeValues.map((it) => {
                            return (
                                <MenuItem value={it.id}
                                          key={it.id}>{it.valid_value[locale] ? it.valid_value[locale] : 'undef'}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <MyTree
                    handleChangeParent={handleChangeParent}
                    parentFilter={parentFilter}
                    table={'categoriesForProducts'}
                    //disabledClick={'dont_end'}
                />
                {table &&
                    <>
                        <TrueFalseFilters field={'hidden_for_user'} label={'Hidden for user'}/>
                        <TrueFalseFilters field={'is_populate'} label={'Is populate'}/>
                        <TrueFalseFilters field={'is_sale'} label={'Is sale'}/>
                    </>
                }
            </CardContent>
        </CardCompWidth>
    )
};

const TopFilters = (props, resource, locale) => {

    let dt = [];

    /*dt.push(
        <ReferenceInput
            source="product_group_related"
            reference="dProductsGroup"
            alwaysOn label={'Categories'}
        >
            <AutocompleteInput
                debounce={250}
                optionText={`name[${locale}]`}
                resettable emptyText={'All'}
            />
        </ReferenceInput>
    );*/

    return dt;
}

const OneRecordContextProvider = (props) => {
    const {record, locale} = props;
    // const {data: dataVariant=[], loaded: loadedVariant, total: totalVariant} = useGetManyReference(
    const {total: totalVariant = 0} = useGetManyReference(
        'dVariantProduct',
        'product',
        record.id,
        {},
        {},
        {},
    );

    let ava = "https://amiel.club/uploads/posts/2022-03/1647759888_27-amiel-club-p-kartinki-dlya-internet-magazina-odezhdi-31.jpg"
    //let ava = 'https://cdn.vectorstock.com/i/1000x1000/35/51/coming-soon-background-with-image-space-area-vector-32773551.webp'
    let opacity = 0.1;

    const {additional} = record
    const {images, prices} = additional
    if (images && images.length > 0) {
        ava = images[0].image
        let main_img = images.find(x => x.is_main === true)
        if (main_img) ava = main_img.image
        if (ava.indexOf('http') !== 0)
            ava = 'http://demo.iam-trade.ch/files_media/' + ava;
        opacity = 1;
    }
    let price = '';
    if (prices && prices.length > 0) {
        // console.log('prices=======>', prices[0])
        price = `${prices[0].price_type__currency__simbol} ${formatSpacerNumber(prices[0].base_price)}`
    }

    return (
        <RecordContextProvider key={record.id} value={record}>
            <Grid
                key={record.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                item
            >
                <Card>
                    <CardMedia
                        component={Link}
                        to={`/dProducts/${record.id}`}
                        //color={'red'}
                        // style={{opacity: (loaded && product_group && product_group.image) ? 1 : 0.1}}
                        // image={`${(loaded && product_group && product_group.image) ? product_group.image : ava}`}
                        style={{opacity: opacity}}
                        image={ava}
                        sx={{height: 320}}
                        // sx={{ width: 120 }}
                    />
                    <CardContent
                        sx={{
                            paddingBottom: '0.5em',
                            maxHeight: 80,
                            height: 80,
                            overflowY: 'hidden'
                        }}>
                        <a
                            style={{
                                color: 'currentColor',
                                textDecoration: 'none',
                            }}
                            href={`#/dProducts/${record.id}`}
                        >
                            <Typography
                                // variant="h5"
                                sx={{fontWeight: 'bold'}}
                                variant="body1"
                                align="left"
                            >
                                {/*{inflection.humanize(record.name[locale])}*/}
                                {record.article}
                            </Typography>
                            <Typography
                                sx={{lineHeight: 1.2}}
                                variant="subtitle1"
                                align="left"
                            >
                                {/*{inflection.humanize(record.name[locale])}*/}
                                {record.name[locale]}
                            </Typography>
                        </a>
                    </CardContent>
                    <CardActions
                        sx={{
                            justifyContent: 'space-between',
                            '.MuiCardActions-spacing': {
                                display: 'flex',
                                justifyContent: 'space-between',
                            },
                        }}
                    >
                        <span>{`Variants: ${totalVariant}`}</span>
                        <span>{price}</span>
                    </CardActions>
                </Card>
            </Grid>
        </RecordContextProvider>
    )
}
const GetImageFromArr = (props) => {
    const {src, type = 'img'} = props
    const record = useRecordContext();
    let imgName = null
    let images = get(record, src, null)
    if (images && images[0] !== undefined) {
        imgName = get(images[0], 'small_img', null)
        if (imgName) return <ImageInfo link={imgName} type={type}/>
        imgName = get(images[0], 'medium_img', null)
        if (imgName) return <ImageInfo link={imgName} type={type}/>
        imgName = get(images[0], 'image', null)
        if (imgName) return <ImageInfo link={imgName} type={type}/>
        return null

    } else {
        return null
    }
    // console.log('record=====>', record)

}

const GetPrice = (props) => {
    const record = useRecordContext(props);
    const {additional = {}} = record || {};
    const {prices = null} = additional || {};
    if (prices && prices.length > 0) {
        let min = 1000000;
        let max = 0;
        let indMin = 0;
        let indMax = 0;
        prices.forEach((it, ind) => {
            if (min > it.base_price) {
                min = it.base_price
                indMin = ind
            }
            if (max < it.base_price) {
                max = it.base_price
                indMax = ind
            }

        })
        return (
            <Box style={{display: 'grid'}}>
                <span title={prices.length > 1 && indMax !== indMin ? 'min' : 'min/max'}>
                    {prices[indMin].price_type__currency__simbol}{'\u00A0'}{formatSpacerNumber(prices[indMin].base_price)}
                </span>
                {prices.length > 1 && indMax !== indMin &&
                    <span title={'max'}>
                        {prices[indMax].price_type__currency__simbol}{'\u00A0'}{formatSpacerNumber(prices[indMax].base_price)}
                    </span>
                }
            </Box>
        )

    }
    return null
}
const GetStock = (props) => {
    //const locale = useLocale();
    const record = useRecordContext(props);
    const {additional = {}} = record || {};
    const {stock = null} = additional || {};
    if (stock && stock.length > 0) {
        let sum = 0
        stock.forEach((it) => sum += it.quantity)
        return (
            <span>{sum}</span>
        )

    }
    return null
}
const GetCategories = (props) => {
    const locale = useLocale();
    const record = useRecordContext(props);
    const {additional = {}} = record || {};
    const {groups = null} = additional || {};

    if (groups && groups.length > 0) {
        let st = []
        groups.forEach((it) => {
            st.push(typeof it.product_group__name === 'string'
                ? it.product_group__name
                : it.product_group__name[locale]
            )
        })
        return (
            <Box style={{display: 'grid', borderTop: '1px solid #ccc'}}>
                <span>{st.join(', ')}</span>
            </Box>
        )

    }
    return null
}
const GetColumnsFeature = (props) => {
    const locale = useLocale();
    const {ind} = props;
    const record = useRecordContext(props);
    const {additional = {}} = record || {};
    const {features = null} = additional || {};

    if (features && features[ind]) {
        return (
            <SelectAndCreateOtherTable
                textAlign={'center'}

                referenceForFeatureSave={'dProductsFeatureValue'}
                featureTypeId={features[ind].feature_type_id}
                dataForFeatureSave={{
                    "id": features[ind].id,
                    "additional": null,
                    //"updated": "2024-04-10T16:10:31.347871",
                    "source": localStorage.getItem('username'),
                    "product": record.id,
                    "feature_type": features[ind].feature_type_id,
                    //"value": 142
                }}
                oriName={typeof features[ind].value__valid_value === "string"
                    ? features[ind].value__valid_value
                    : features[ind].value__valid_value[locale]
                        ? features[ind].value__valid_value[locale]
                        : features[ind].value__valid_value?.en}
                reference="dFeatureValidValue"
                //source="correct_answer"
                fieldsForMenu={typeof features[ind].value__valid_value === "string"
                    ? `valid_value`
                    : `valid_value.${locale}`
                }
                filter={{feature_type: features[ind].feature_type_id}}
            />
        )
    }
    return null
}
const ColumnsForList = (resource, locale, classes) => {
    const translate = useTranslate();
    // const record = useRecordContext(props)

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        hidden_for_user:
            <SelectAndSaveChoices
                label={'A'}
                source="hidden_for_user"
                statuses={[
                    {id: true, name: 'Hidden on site'},
                    {id: false, name: 'Show on site'},
                ]}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
                circle={'inv'}
            />,
        images: <GetImageFromArr src={'additional.images'} label={translate('Image')}/>,
        product_group:
            <Box display={"grid"} label={translate('Categories')} sortable={false}>
                <ReferenceField
                    sortable={false}
                    link={true}
                    label={translate('Categories')}
                    source={'product_group'}
                    reference={'dProductsGroup'}
                    //filter={{question$id: id}}
                >
                    <TextField source={`name[${locale}]`}/>
                </ReferenceField>
                <GetCategories/>
            </Box>,
        name:
            <Box display={"grid"} label={translate('Name')} sortBy={`article`} cellClassName={classes.productName}>
                <Box display={"inline"}>
                    <TextFieldBold source={`article`} label={translate('Article')}/> {' ('}
                    <TextFieldBold source={`brand[${locale}]`} label={translate('Brand')}/>{')'}
                </Box>
                <CroppedLongField long={40} source={`name[${locale}]`} label={translate('Name')}/>
            </Box>,
        price: <GetPrice
            headerClassName={classes.colorPriceAndStock}
            cellClassName={classes.colorPriceAndStock}
            textAlign={"right"} label={translate('Prices (min/max)')}
        />,
        stock: <GetStock
            headerClassName={classes.colorPriceAndStock}
            cellClassName={classes.colorPriceAndStock}
            textAlign={"right"} label={translate('Stock')}
        />,
        is_new:
            <SelectAndSaveChoices
                label={'Is new'}
                source="is_new"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
                circle={'not inv'}
            />,
        is_offer:
            <SelectAndSaveChoices
                label={'Is offer'}
                source="is_offer"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
                circle={'not inv'}
            />,
        is_populate:
            <SelectAndSaveChoices
                label={'Is populate'}
                source="is_populate"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
                circle={'not inv'}
            />,
        is_sale:
            <SelectAndSaveChoices
                label={'Is sale'}
                source="is_sale"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={true}
                color={'green'}
                circle={'not inv'}
            />,
        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')}
                                textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }

    return columns;
};

const CategoryTable = (props) => {
    const {columns, locale, classes, data, loading} = props
    //console.log('props=====%%%%========>', props)

    let dt = null
    if(data) dt = Object.values(data)
    if (dt && dt.length>0) {
        const {additional} = dt[0]
        const {features} = additional
        return (
            <DatagridComponentAdmin {...props} rowClick="edit">
                {columns}
                {loading === false && features && features.map((it, ind) => {
                    let nameType = typeof it.feature_type__name === 'string'
                        ? it.feature_type__name
                        : it.feature_type__name[locale]
                    let nameCategory = typeof it.category_name === 'string'
                        ? it.category_name
                        : it.category_name[locale]

                    return <GetColumnsFeature
                        textAlign={'center'}
                        headerClassName={it.from === "product" ? classes.colorFeatureProduct : classes.colorFeatureCategory}
                        cellClassName={it.from === "product" ? classes.colorFeatureProduct : classes.colorFeatureCategory}
                        // headerClassName={classes.featureTypeColumn}
                        // cellClassName={classes.featureTypeColumn}
                        key={ind} ind={ind}
                        label={
                            it.from
                                ? it.from === "product"
                                    ? `${nameType}`
                                    : `${nameType} (${nameCategory})`
                                : `${nameType} (not from)`
                        }
                    />
                })
                }
                {/*{!featureType && parentFilter &&
                            <TextField soutce={'ttt'} label={'Feature Type'}/>
                        }*/}
            </DatagridComponentAdmin>
        )

    } else {
        return null
    }
}
const ProductList = ({...props}) => {

    const classes = useMyStyles();
    const {resource} = props;
    const locale = useLocale();
    //const translate = useTranslate();

    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    const [parentFilter, setParentFilter] = React.useState('');
    const [featureTypeVal, setFeatureTypeVal] = React.useState('');
    const [nameCategory, setNameCategory] = React.useState('');
    const [table, setTable] = React.useState(true);

    const handleTableOrGrid = (par) => {
        setTable(par)
    }
    const handleChangeParent = (id, label) => {
        setParentFilter(id)
        setNameCategory(label)
    }

    // let dProducts = useSelector((state) => state.admin.resources.dProducts.data)

    useEffect(() => {
        let dt = localStorage.getItem('categoriesForProducts')
        if (dt) {
            dt = JSON.parse(dt)
            setParentFilter(dt.id)
            setNameCategory(dt.label)
            // expanded: expanded,
        }
    }, []);// eslint-disable-line

    /*
    useEffect(() => {
        if (dProducts) {
            let res = null
            Object.values(dProducts).every(function (it, ind) {
                if (featureTypeVal && it.additional.features.find(x => x.value_id === featureTypeVal)) {
                    res = it
                } else if (parentFilter && it.product_group === parentFilter) {
                    res = it
                } else if (parentFilter && it.additional.groups.find(x => x.product_group_id === parentFilter)) {
                    res = it
                }

                if (res) return false
                else return true
            });
            if (res) setFeatureType(res.additional.features ? res.additional.features : null)
            else setFeatureType(null)
        } else setFeatureType(null)

    }, [dProducts]);// eslint-disable-line

    useEffect(() => {
        if (parentFilter) setFeatureType(null)
    }, [parentFilter]);// eslint-disable-line
    */

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes),
        omit: ["is_new", "is_offer", "is_populate", "is_sale", "source", "updated"]
    });

    return (
        <div class={'productTable'}>
            {/*<LinearProgress color="secondary"/>*/}
            <ListComponentAdmin
                {...props}
                //component={'div'}
                title={`Products ${nameCategory ? '(' + nameCategory + ')' : ''}`}
                actions={<TableActions
                    handleTableOrGrid={handleTableOrGrid}
                    table={table}
                    create={true}
                    select={table ? true : false}
                    preference={`${resource}list.columns`}
                    columns={ColumnsForList(resource, locale, classes)}
                    //permissions={permissions}
                    asideFilter={false}
                    // filter={false}
                    //exportCsv={true}
                    //exporterFile={exporterPassenger}
                    //sortButton={[`name.${locale}`, 'updated', 'id']}
                    /*handlerForAllTable={localStorage.getItem('google_merchant_id') && canAccess2({
                        resource: 'googleMerchant',
                        action: 'list'
                    })
                        ? <GetGoogleMerchantAll label={'Create Google merchant'} action='create'/>
                        : false
                    }*/
                    /*handlerForAllTable2={localStorage.getItem('google_merchant_id') && canAccess2({
                        resource: 'googleMerchant',
                        action: 'list'
                    })
                        ? <GetGoogleMerchantAll label={'Delete Google merchant'} action='delete'/>
                        : false
                    }*/
                />}
                filters={TopFilters(props, resource, locale)}
                filter={{
                    show_features: featureTypeVal || parentFilter ? 'Y' : '',
                    product_group_related: parentFilter,
                    feature_type_val: featureTypeVal
                }}
                //filterDefaultValues={{viewed: true}}
                aside={
                    <Aside
                        locale={locale}
                        handleChangeParent={handleChangeParent}
                        parentFilter={parentFilter}
                        table={table}
                        featureTypeVal={featureTypeVal}
                        setFeatureTypeVal={setFeatureTypeVal}
                    />
                }
                is_bulkActionButtons={table ? true : false}

                sort={{field: 'id', order: 'ASC'}}
                perPage={24}
                //listBase={true}
            >
                {table && isSmall === false
                    ?
                    <CategoryTable
                        columns={columns}
                        locale={locale}
                        classes={classes}
                    />
                    :
                    <CategoryGrid {...props}/>
                }
            </ListComponentAdmin>
        </div>
    );
}

const CategoryGrid = ({...props}) => {
    const {data, isLoading} = useListContext();
    const locale = useLocale();
    if (isLoading) {
        return null;
    }
    return (
        <Grid container spacing={2} sx={{mt: 0, padding: '16px'}}>
            {Object.values(data).map(record => (
                <OneRecordContextProvider record={record} locale={locale}/>
            ))}
        </Grid>
    );
};

export default ProductList;
