// import Brands from "./Brands/Brands";
import {commonTrueFalse} from "../_constants/choices";

const toCamel = (s) => {
    s = s[0].toUpperCase() + s.slice(1, s.length);
    return s.replace(/([-_][a-z])/ig, ($1) => {
        return $1.toUpperCase()
            // .replace('-', '')
            .replace('_', '');
    });
};
/*
const toSnake = (s) => {
    return s[0].toLowerCase() +
        s.slice(1, s.length).replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
};
*/

export const JustText = (str, flex = 1) => {
    return {
        name: str,
        type: 'text',
        show_type: 'TextField',
        edit_type: 'TextInput',
        read_only: false,
        flex: flex,
    };
};
export const JustInt = (str, flex = 1) => {
    return {
        name: str,
        type: 'int',
        show_type: 'NumberField',
        edit_type: 'NumberInput',
        read_only: false,
        flex: flex,
    };
};
export const JustFloat = (str, flex = 1) => {
    return {
        name: str,
        type: 'float',
        show_type: 'NumberField',
        edit_type: 'NumberInputFloatComp',
        read_only: false,
        flex: flex,
    };
};

export const JustBool = (str, flex = 1) => {
    return {
        name: str,
        type: 'bool',
        show_type: 'SelectAndSaveChoices',
        edit_type: 'BooleanInput',
        type_ext: {choices: commonTrueFalse, reference: 'Brands', width: '3em'},
        read_only: false,
        flex: flex,
    };
};
export const JustTranslText = (str, flex = 1) => {
    return {
        name: str,
        type: 'text',
        show_type: 'TextField',
        edit_type: 'TranslatableInputs',
        type_ext: {locale: true},
        read_only: false,
        flex: flex,
    };
};
export const JustRef = (name, ref = '', flex = 1, optionText = 'name', locale = true) => {
    if (ref === '') ref = toCamel(name) + 's';
    return {
        name: name,
        type: 'text',
        show_type: 'ReferenceField',
        edit_type: 'ReferenceInput',
        type_ext: {reference: ref, optionText: optionText, locale: locale},
        read_only: false,
        flex: flex,
    };
};
export const JustRow = (str, menu = 'Unknown', data = {}, base_url='api/react-admin/main/') => {
    return {
        table: str,
        base_url: base_url + str,
        create: true,
        delete: true,
        menu: menu,
        data: data,
    };
};
