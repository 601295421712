import * as React from 'react';
import {
    Create, useRedirect,
} from 'react-admin';

import PromotionalGoodEditCreate from "./PromotionalGoodEditCreate";
import PostEditActions from "../../_common/PostEditActions";

export const PromotionalGoodCreate = props => {

    const {location} = props;
    const {search} = location;

    //const redirect = useRedirect()

    let idSource = '';
    //let bookingId = '';
    if(search){
        let url = new URL('https://test/' + search);
        idSource = url.searchParams.get('idSource')
    }

    const redirect = useRedirect()

    return (
        <Create
            {...props}
            title={''}
            //transform={(event) => copyToS3bucket(resource, event)}
            onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            //onSuccess={() => props.history.goBack()}
            //onSuccess={onSuccessData}
            actions={<PostEditActions/>}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <PromotionalGoodEditCreate idSource={idSource}/>
        </Create>
    )
};