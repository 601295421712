//keycloak init options
// import config from "./config";
// import * as Keycloak from "keycloak-js";
// import {HttpError} from "ra-core";
import {HttpError} from 'react-admin';
import {createHeadersFromOptions} from "ra-core/lib/dataProvider/fetch";
import {apiPathConfig} from "./config";
// import {Auth} from 'aws-amplify';
// import {logout} from "ra-cognito/dist/AuthProvider";


export const fetchJson = async (url, options = {}) => {
    /*Auth.currentSession().then(res => {
        let accessToken = res.getAccessToken()
        let jwt = accessToken.getJwtToken()
        //You can print them to see the full objects
        // console.log(`myAccessToken===>: ${JSON.stringify(accessToken)}`)
        // console.log(`myJwt===>: ${jwt}`)
    })*/
    /*const ttt = await Auth.currentSession();
    console.log('===========>', ttt.accessToken);
    //console.log('===========>', ttt.accessToken.getJwtToken());*/

    /*const ttt = await Auth.currentAuthenticatedUser();
    const groups = ttt.signInUserSession.accessToken.payload['cognito:groups'];
    //console.log('TOKEN=========>', groups)
    if (groups === undefined || groups.indexOf('Admins') < 0) {
        logout();
        return Promise.reject(
            new HttpError(
                `${"This GROUP is not allowed access"}`,
                401,
                null
            )
        );
    }*/
    let token;
    if (apiPathConfig.getAuth()) {
        if (apiPathConfig.getCognito()) {
            const clientId = process.env.REACT_APP_COGNITO_CLIENT_ID;
            const user = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.LastAuthUser`);
            // const token = localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${user}.idToken`);
            token = 'Bearer ' + localStorage.getItem(`CognitoIdentityServiceProvider.${clientId}.${user}.accessToken`);
            //console.log("Cognito====== token = ",token);
        } else {
            if (apiPathConfig.getAuth()) token = 'Token ' + JSON.parse(localStorage.getItem('auth')).key;
            //console.log("AUTH====== token = ",token);
        }
    }

    if (!options.headers) {
        options.headers = new Headers({Accept: "application/json"});
    }
    if (apiPathConfig.getAuth()) options.headers.set("Authorization", token);

    const requestHeaders = createHeadersFromOptions(options);

    return fetch(url, {...options, headers: requestHeaders})
        .then(response =>
            response.text().then(text => ({
                status: response.status,
                statusText: response.statusText,
                headers: response.headers,
                body: text,
            }))
        )
        .then(({status, statusText, headers, body}) => {
            let json;
            try {
                json = JSON.parse(body);
            } catch (e) {
                // not json, no big deal
            }
            console.log('status keylock===> ', status)
            if (status < 200 || status >= 300) {
                // debugger
                return Promise.reject(
                    new HttpError(
                        `${JSON.stringify(json)}` || statusText,
                        status,
                        json
                    )
                );
            }
            return Promise.resolve({status, headers, body, json});
        });
};

