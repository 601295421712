import * as React from 'react';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import {FilterList, FilterListItem} from 'react-admin';

const TrueFalseFilters = (props) => {
    const {field, label} = props;

    return (
        <FilterList
                    label={`${label}`}
                    icon={<CheckCircleOutlineIcon/>}
                >
                    <FilterListItem
                        label={'No'}
                        value={{
                            [field]: false,
                        }}
                    />
                    <FilterListItem
                        label={'YES'}
                        value={{
                            [field]: true,
                        }}
                    />
                </FilterList>
    )
}

export default TrueFalseFilters
