import * as React from 'react';
//import { Box, Card, CardActions, Button, Typography } from '@mui/material';
import {Box, Card, CardActions, Button, Typography} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
import CodeIcon from '@material-ui/icons/Code';
import {useTranslate} from 'react-admin';

import publishArticleImage from './welcome_illustration.svg';

const styles = {
    card: {
        background: `linear-gradient(to right, #8975fb 0%, #746be7 35%), linear-gradient(to bottom, #8975fb 0%, #6f4ceb 50%), #6f4ceb`,
        color: '#fff',
        padding: '20px',
        marginTop: 2,
        marginBottom: '1em',
        borderRadius: 12
    },
    cardAction: {
        padding: {xs: 0, xl: null},
        flexWrap: {xs: 'wrap', xl: null},
        '& a': {
            marginTop: {xs: '1em', xl: null},
            marginLeft: {xs: '0!important', xl: null},
            marginRight: {xs: '1em', xl: null},
        },
    },
    button: {
        fontWeight: 'bold',
        color: "white",
        background: 'rgb(79, 60, 201)',
        //background: 'linear-gradient(to right, #8975FB 0%, #746BE7 35%), linear-gradient(to bottom, #8975FB 0%, #6F4CEB 50%), #6F4CEB'
    },
};

const Welcome = (props) => {
    const {simple = true} = props;
    const translate = useTranslate();

    let tit1 = "Welcome to the iAM-Trade Admin"
    let subtit1 = "This is the admin of a one-stop shop. Here you can explore and modify the data. All the tools of our administrator are at your service."
    /*if (simple) {
        tit1 = "Welcome to the iAM-Trade Admin"
        subtit1 = ""
    }*/
    return (
        <Card
            style={styles.card}
            /*sx={{
                background: theme =>
                    theme.palette.mode === 'dark'
                        ? '#535353'
                        : `linear-gradient(to right, #8975fb 0%, #746be7 35%), linear-gradient(to bottom, #8975fb 0%, #6f4ceb 50%), #6f4ceb`,

                color: '#fff',
                padding: '20px',
                marginTop: 2,
                marginBottom: '1em',
            }}*/
        >
            <Box display="flex">
                <Box flex="1">
                    <Typography variant="h5" component="h2" gutterBottom>
                        {translate(tit1)}
                    </Typography>
                    <Box maxWidth="40em">
                        <Typography variant="body1" component="p" gutterBottom>
                            {translate(subtit1)}
                        </Typography>
                    </Box>
                    {!simple &&
                        <CardActions
                            style={styles.cardAction}
                            /*sx={{
                                padding: { xs: 0, xl: null },
                                flexWrap: { xs: 'wrap', xl: null },
                                '& a': {
                                    marginTop: { xs: '1em', xl: null },
                                    marginLeft: { xs: '0!important', xl: null },
                                    marginRight: { xs: '1em', xl: null },
                                },
                            }}*/
                        >
                            <Button
                                style={styles.button}
                                variant="contained"
                                href="https://marmelab.com/react-admin"
                                startIcon={<HomeIcon/>}
                            >
                                {translate('REACT-ADMIN SITE')}
                            </Button>
                            <Button
                                style={styles.button}
                                variant="contained"
                                href="https://github.com/marmelab/react-admin/tree/master/examples/demo"
                                startIcon={<CodeIcon/>}
                            >
                                {translate('SOURCE FOR THIS DEMO')}
                            </Button>
                        </CardActions>
                    }
                </Box>
                <Box
                    display={{xs: 'none', sm: 'none', md: 'block'}}
                    sx={{
                        background: `url(${publishArticleImage}) top right / cover`,
                        marginLeft: 'auto',
                    }}
                    width="16em"
                    height="9em"
                    overflow="hidden"
                />
            </Box>
        </Card>
    );
};

export default Welcome;
