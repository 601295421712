import * as React from 'react';
import PropTypes from 'prop-types';
import {useInput} from 'ra-core';
// import { TextFieldProps } from '@material-ui/core/TextField';
// import {InputHelperText, ResettableTextField, sanitizeInputRestProps} from "react-admin";
// import JSONInput from 'react-json-editor-ajrm';
// import locale from 'react-json-editor-ajrm/locale/en';
import Button from "@material-ui/core/Button";
import {MyAiIcons} from "../icons/MyAiIcons";
import {getJsonData, getPromptRequest, getTranslate} from "./CompForApi/utilsAPI";
import {FormDataConsumer} from "react-admin";
import CircularProgress from "@material-ui/core/CircularProgress";
import {JsonInput as RAJsonInput} from "react-admin-json-view";
import {useSelector} from "react-redux";
import {Alert} from "@mui/material";
import {MyTranslateIcons} from "../icons/MyTranslateIcons";
import get from "lodash/get";
import {useForm} from "react-final-form";
import {localesConfig} from "../utils/config";

// import ResettableTextField from './ResettableTextField';
// import InputHelperText from './InputHelperText';
// import sanitizeInputRestProps from './sanitizeInputRestProps';

const JsonInput = (props) => {
    const {
        currentLocale,
        label,
        resource,
        promptData,
        // format,
        // helperText,
        // onBlur,
        // onFocus,
        // onChange,
        // options,
        // parse,
        // resource,
        // source,
        // validate,
        formData,
        ...rest
    } = props;

    const {
        id,
        input,
        // isRequired,
        // meta: { error, submitError, touched },
    } = useInput({
        // format,
        // onBlur,
        // onChange,
        // onFocus,
        // parse,
        // resource,
        // source,
        // type: 'text',
        // validate,
        ...rest,
    });

    const formEdit = useForm();
    const tbl = useSelector((state) => state.admin.resources[resource])
    //const {promptData} = props

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState('');
    const [loadingTr, setLoadingTr] = React.useState(false);
    const [errorTr, setErrorTr] = React.useState('');

    const prompt = promptData.find(x => x.field === props.sourceForPrompt)

    const handleAskAi = async (formData) => {
        const {data} = tbl
        console.log('tbl==========>', tbl)
        const productData = data[id]
        const {additional} = productData

        //const prompt = promptData.find(x => x.field === props.source)

        // const keysToGet = ['name_en', 'description_en', 'article', 'sku'];
        // const neededData = Object.fromEntries(keysToGet.map(key => [key, formData[key]]))
        const neededData = getJsonData(prompt, formData)

        // debugger
        setLoading(true)
        setError('');
        setLoadingTr(false)
        setErrorTr('');
        //JSON.stringify(formData)
        // let res = await getPromptRequest(prompt.prompt, {
        let res = await getPromptRequest(prompt, {
            ...neededData,
            ...additional
        }, "gpt-4")
        setLoading(false)

        //console.log('res', res)
        // debugger
        // console.log('res==AI==========>', res)
        formEdit.change(`${props.sourceForPrompt}`, {})
        try {
            const parsedData = JSON.parse(res);
            console.log('parsedData=AI====>', parsedData)
            //debugger
            localesConfig.languages.forEach((it) => {
                //console.log(props.sourceForPrompt,'parsedData=AI====>', it.locale)
                // console.log(`${props.sourceForPrompt}.${it.locale}`,'res==AI==========>', parsedData)
                formEdit.change(`${props.sourceForPrompt}.${it.locale}`, parsedData)
            })
            onChangeInput(parsedData)
        } catch (error) {
            console.error('Error parsing JSON:', error);
            setError(' err');
        }

    }

    const handleTranslate = async (formData) => {
        // debugger
        setLoading(false)
        setError('');
        setLoadingTr(true)
        setErrorTr('');
        //JSON.stringify(formData)
        // let res = await getPromptRequest(prompt.prompt, {
        let arrLocales = []
        let objData = {}
        if (currentLocale === localesConfig.defaultLocale) {
            objData = formData[props.sourceForPrompt][localesConfig.defaultLocale]
            localesConfig.languages.forEach((it) => {
                if (it.locale !== localesConfig.defaultLocale)
                    arrLocales.push(it.locale)
            })
        } else {
            objData = formData[props.sourceForPrompt][currentLocale]
            arrLocales = [currentLocale]
        }
        let parsedData = await getTranslate(objData, arrLocales)
        setLoadingTr(false)

        console.log('parsedData==TR==========>', parsedData)
        // debugger
        if (parsedData) {
            arrLocales.forEach((it) => {
                let res = get(parsedData, `${it}.name`, null)
                if (res) formEdit.change(`${props.sourceForPrompt}.${it}`, res)
                else setErrorTr(' err');
            })
            //onChangeInput(res)
        } else {
            console.error('Error parsing JSON:', error);
            setErrorTr(' err');
        }

    }

    const {
        // value,
        onChange: onChangeInput
    } = input

    // const newObj = {};
    // if (value) {
    //     for (const key in value) {
    //         if (typeof value[key] === "string") {
    //             newObj[key] = value[key].replace(/['"]+/g, "");
    //         } else {
    //             newObj[key] = value[key];
    //         }
    //     }
    // }
    //
    // console.log('value==value==>', value)
    // console.log('value==newObj==>', newObj)
    return (<div>
        <h4>{label}</h4>
        {!prompt &&
            <Alert severity='warning'><a href={'#/dPrompts'}>PROMPT</a> not found</Alert>
        }
        <FormDataConsumer>
            {({formData, ...rest}) => {
                //console.log('formData======>', formData)
                return (
                    <>
                        {currentLocale === localesConfig.defaultLocale &&
                            <Button
                                onClick={() => handleAskAi(formData)}
                                color="inherit"
                                startIcon={<MyAiIcons/>}
                                disabled={loadingTr || loading || !prompt}
                                endIcon={loading ? <CircularProgress color="inherit" size={20}/> : null}
                            >Create All{error ? <span style={{'color': "red"}}>{' - '}{error}</span> : null}
                            </Button>
                        }
                        <Button
                            onClick={() => handleTranslate(formData)}
                            color="inherit"
                            startIcon={<MyTranslateIcons/>}
                            disabled={loadingTr || loading || !prompt}
                            endIcon={loadingTr ? <CircularProgress color="inherit" size={20}/> : null}
                        >
                            {currentLocale === localesConfig.defaultLocale
                                ? 'Translate All'
                                : `Translate ${currentLocale}`
                            }{errorTr ? <span style={{'color': "red"}}>{' - '}{errorTr}</span> : null}
                        </Button>
                    </>
                )
            }}
        </FormDataConsumer>
        {/*<JSONInput*/}
        {/*    id={`json-field-id-${id}`}*/}
        {/*    placeholder={value ? value : {}}*/}
        {/*    // theme="light_mitsuketa_tribute"*/}
        {/*    // error={!!(touched && (error || submitError))}*/}
        {/*    colors={{*/}
        {/*        string: "#DAA520" // overrides theme colors with whatever color value you want*/}
        {/*    }}*/}
        {/*    locale={locale}*/}
        {/*    height='550px'*/}
        {/*    width='500px'*/}
        {/*    onChange={(v) => {*/}
        {/*        const {jsObject} = v*/}
        {/*        onChangeInput(jsObject)*/}
        {/*    }}*/}
        {/*/>*/}
        <RAJsonInput
            //source={`${props.source}.${currentLocale}`}
            source={props.source}
            // validate={[required()]}
            jsonString={false} // Set to true if the value is a string, default: false
            reactJsonOptions={{
                // Props passed to react-json-view
                name: label,
                collapsed: false,
                enableClipboard: true,
                displayDataTypes: false,
            }}
        />
        {/*<ResettableTextField*/}
        {/*    id={id}*/}
        {/*    {...input}*/}
        {/*    label={*/}
        {/*        label !== '' &&*/}
        {/*        label !== false && (*/}
        {/*            <FieldTitle*/}
        {/*                label={label}*/}
        {/*                source={source}*/}
        {/*                resource={resource}*/}
        {/*                isRequired={isRequired}*/}
        {/*            />*/}
        {/*        )*/}
        {/*    }*/}
        {/*    error={!!(touched && (error || submitError))}*/}
        {/*    helperText={*/}
        {/*        <InputHelperText*/}
        {/*            touched={touched}*/}
        {/*            error={error || submitError}*/}
        {/*            helperText={helperText}*/}
        {/*        />*/}
        {/*    }*/}
        {/*    {...options}*/}
        {/*    {...sanitizeInputRestProps(rest)}*/}
        {/*/>*/}
    </div>);
};

JsonInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    options: PropTypes.object,
    resource: PropTypes.string,
    source: PropTypes.string,
};

JsonInput.defaultProps = {
    options: {},
};

export default JsonInput;
