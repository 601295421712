import * as React from 'react';
import {
    AutocompleteInput,
    FilterLiveSearch,
    NumberField, ReferenceField, ReferenceInput,
    TextField,
    useLocale,
    useTranslate,
} from 'react-admin';
import {CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../../index.css';
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
import {BooleanFieldComp, DateFieldComp, FilterDataAside} from "../../_common/CompReact";
import CardComp from "../../_common/Asside/CardComp";
import CroppedLongField from "../../_common/CroppedLongField";
//import get from 'lodash/get';
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import MySimpleList from "../../_common/MySimpleList";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {choicesExternalSupplier} from "../../_constants/choices";

const Aside = (props) => {
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch/>
                <TrueFalseFilters field={'auto_export_orders'} label={'Auto export orders'}/>
                <FilterDataAside fields={'updated'} name={'update'}/>
                {/*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*/}
                {/*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*/}
                {/*<TrueFalseFilters field={'is_load'} label={'Exported to Xero'}/>*/}
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
            </CardContent>
        </CardComp>
    )
};
const TopFilters = (resource, locale) => {
    let dt = [];
    dt.push(<ReferenceInput source="main_warehouse" reference="dSuppliersWarehouses" alwaysOn>
        <AutocompleteInput
            optionText={`${'name'}[${locale}]`}
            resettable emptyText={'All main warehouse'}/>
    </ReferenceInput>);
    dt.push(<ReferenceInput source="price_type" reference="dPriceTypes" alwaysOn>
        <AutocompleteInput optionText="name" resettable emptyText={'All price type'}/>
    </ReferenceInput>);

    return dt;
}

const ColumnsForList = (resource, locale, classes, isSmall) => {
    /*
    {
    "additional": null,
    "id": 4,
    "updated": "2024-05-10T19:24:33.632000",
    "source": "admin",
    "supplier": "BDroppy",
    "main_warehouse": 1,
    "price_type": 1,
    "auth_data": "{}",
    "auto_export_orders": true
}
     */

    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        supplier:
        <SelectAndSaveChoices
                label={'Supplier'}
                source="supplier"
                statuses={choicesExternalSupplier}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'8em'}
                // edit={true}
            />,
        main_warehouse:
            <ReferenceField
                link={false} sortable={false}
                label={translate('Main warehouse')}
                source={'main_warehouse'}
                reference={'dSuppliersWarehouses'}
            >
                <TextField source={`${'name'}[${locale}]`}/>
            </ReferenceField>,
        price_type:
            <ReferenceField
                link={false} sortable={false}
                label={translate('Price type')}
                source={'price_type'}
                reference={'dPriceTypes'}
            >
                <TextField source={`${'name'}`}/>
            </ReferenceField>,

        auth_data: <TextField source={'auth_data'}  label={translate('Auth data')}/>,
        auto_export_orders: <BooleanFieldComp source="auto_export_orders" label={translate("Auto export orders")} textAlign={"center"}/>,

        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

export const ExternalSuppliersList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                permissions={permissions}
                asideFilter={false}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
            is_bulkActionButtons={true}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}