import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {ProductList} from "./ProductList";
import {ProductEdit} from "./ProductEdit";
import {ProductCreate} from "./ProductCreate";

const resource = {
    list: ProductList,
    create: ProductCreate,
    edit: ProductEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;