import * as React from 'react';
import {Typography} from "@material-ui/core";
import {Box} from "@mui/material";

const HeaderRelationTable = ({par, name = '', mb = null, mt = null}) => {
    return (
        <Box
            style={{
                marginTop: mt && mt,
                marginBottom: mb && mb,
            }}

        >
            <Typography
                component="span"
                variant="body2"
            >
                {name
                    ? par.replace('" "', `"${name}"`)
                    : par
                }
            </Typography>
        </Box>
    )
}
export default HeaderRelationTable;
