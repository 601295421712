import React from 'react';
import PropTypes from 'prop-types';
//import {makeStyles} from '@material-ui/core/styles';
//import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {TestPrice} from "./TestPrice";
import {useTranslate} from "react-admin";
import {TestOrder} from "./TestOrder";

//import Alert from '@material-ui/lab/Alert';

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

/*const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}));*/

export const TabsPrice = (props) => {
    //const classes = useStyles();
    //const {type} = props;
    const [value, setValue] = React.useState(0);
    const translate = useTranslate();

    const iniFilter = {
        customer: null,
        product: [
            {product: null, quantity: null, check: true, del: false},
            {product: null, quantity: null, check: true, del: false},
            /*{product: null, quantity: null, check: false, del: false},*/
        ]
    }
    const [result, setResult] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [totalPrice, setTotalPrice] = React.useState(null);

    const [result2, setResult2] = React.useState(null);
    const [openDialog2, setOpenDialog2] = React.useState(false);
    const [totalPrice2, setTotalPrice2] = React.useState(null);

    //test-price
    const [filterState, setFilterState] = React.useState(iniFilter);
    const [dataForSearch, setDataForSearch] = React.useState(null);
    const [customerName, setCustomerName] = React.useState(null);

    //test-order
    const [orderId, setOrderId] = React.useState(null);
    const [error, setError] = React.useState(null);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}}
             style={{margin: 16, border: '1px solid #ccc', borderRadius: 4, zIndex: 2, backgroundColor: "white"}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}
                 style={{backgroundColor: '#efefef', borderRadius: '4px 4px 0 0 '}}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label={translate(`Test Price`)} {...a11yProps(0)} />
                    <Tab label={translate(`Test Order`)} {...a11yProps(1)} />
                    {/*<Tab label="Invoices" {...a11yProps(2)} />*/}
                    {/*<Tab label="Travel credit" {...a11yProps(3)} />*/}
                </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
                <TestPrice
                    result={result}
                    setResult={setResult}
                    openDialog={openDialog}
                    setOpenDialog={setOpenDialog}
                    totalPrice={totalPrice}
                    setTotalPrice={setTotalPrice}
                    filterState={filterState}
                    setFilterState={setFilterState}
                    dataForSearch={dataForSearch}
                    setDataForSearch={setDataForSearch}
                    setCustomerName={setCustomerName}
                    customerName={customerName}
                />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <TestOrder
                    result={result2}
                    setResult={setResult2}
                    openDialog={openDialog2}
                    setOpenDialog={setOpenDialog2}
                    totalPrice={totalPrice2}
                    setTotalPrice={setTotalPrice2}

                    orderId={orderId}
                    setOrderId={setOrderId}
                    error={error}
                    setError={setError}

                />
            </TabPanel>
            {/*<TabPanel value={value} index={2}>
                </TabPanel>*/}
            {/*<TabPanel value={value} index={3}>
                    <TravelCredit/>
                </TabPanel>*/}
        </Box>
    );
}