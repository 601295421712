import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    useTranslate,
    TextInput,
    AutocompleteInput,
    ReferenceInput,
    useLocale,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

import {validateEmail} from "../../_constants/validateTable";
import {ImageInputComp} from "../../_common/CompReact";


const BlockEdit = props => {
    // const{id} = props
    const translate = useTranslate();
    const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box flex={1} display="flex" alignItems={"top"}  style={{height: 400}}>
                <Box flex={1} display={'grid'}>
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            fullWidth
                            source={'name'}
                            label={translate('Name')}
                        />
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            fullWidth
                            source={'email'}
                            label={translate('Email')}
                            validate={validateEmail}
                        />
                    </Box>
                    <Box display={'flex'} flex={1}>
                        <Box flex={1} mr={"1em"}>
                            <TextInput
                                variant={"outlined"}
                                source={'phone'}
                                label={translate('Phone')}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1}>
                            <TextInput
                                variant={"outlined"}
                                fullWidth
                                source={'internal_phone'}
                                label={translate('Internal Phone')}
                            />
                        </Box>
                    </Box>
                    <Box display={'flex'} flex={1}>
                        <Box flex={1} mr={"1em"}>
                            <TextInput
                                variant={"outlined"}
                                source={'skype'}
                                label={translate('Skype')}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1}>
                            <TextInput
                                variant={"outlined"}
                                source={'position'}
                                label={translate('Position')}
                                fullWidth
                            />
                        </Box>
                    </Box>
                    <Box flex={1}>
                        <ReferenceInput
                            label={translate('Parent')}
                            source={'parent'}
                            reference={'dManager'}
                            //filter={{question$id: id}}
                        >
                            <AutocompleteInput optionText={`name`} resettable
                                               variant={"outlined"} fullWidth/>
                        </ReferenceInput>
                    </Box>
                </Box>

                <Box flex={1} ml={'1em'} alignItems={"top"}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={250}
                        source={'image'}
                        // label={`${'New'} ${'image'}`}
                        label={''}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your Image here (<b>500kb max</b>)</p>}
                    />
                </Box>

            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('User')}
                        source={'user'}
                        reference={'dUser'}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`username`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}>
                    <ReferenceInput
                        label={translate('Branch')}
                        source={'branch'}
                        reference={'dCompanyBranch'}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`name[${locale}]`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        fullWidth
                        source={'item_code_in_accounting_system'}
                        label={translate('Item Code In Accounting System')}
                    />
                </Box>
                <Box flex={1}>
                </Box>
            </Box>

        </Box>
    )
}
export const ManagerEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                    />
                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default ManagerEditCreate
