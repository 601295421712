import PermIdentity from '@material-ui/icons/PermIdentity';
import {PaymentProviderList} from "./PaymentProviderList";
import {PaymentProviderCreate} from "./PaymentProviderCreate";
import {PaymentProviderEdit} from "./PaymentProviderEdit";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: PaymentProviderList,
    create: PaymentProviderCreate,
    edit: PaymentProviderEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;