import * as React from 'react';
import {BuildListComponent} from "./BuildListComponent";
import {tableConfig} from "../../tablesConfig/tableConfig";


export const universalColumnsForList = (locale, classes, resource) => {
    let tmpObject = {}
    const params = tableConfig.tablesList.find(x => x.table === resource)
    if (!params) return ({id: <h5>Table not found</h5>});
    const {data} = params;
    const {fields_data} = data;
    if (fields_data)
        fields_data.forEach((it, ind) => {
            it.dataTab.forEach((it2, ind2) => {
                it2.box.forEach((it3, ind3) => {
                    tmpObject[it3.name] = BuildListComponent(it3, locale)
                })
            })
        })

    return tmpObject
};


