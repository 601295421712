import * as React from 'react';
import {
    Create, useRedirect
} from 'react-admin';

import UniversalEditCreate from "./UniversalEditCreate";
import PostEditActions from "../../_common/PostEditActions";
import {copyToS3bucket} from "../../_common/Transform";

export const UniversalCreate = props => {
    const {location, resource} = props;
    const {search} = location || {};
    let idSource = '';
    if(search){
        idSource = search.split('idSource=')[1] ? search.split('=')[1] : '';
    }
    const redirect = useRedirect()

    return (
        <Create {...props}
            // transform={(event) => transform(resource, event)}
            transform={(event) => copyToS3bucket(resource, event)}
            title={''}
            onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            // onSuccess={() => props.history.goBack()}
            actions={<PostEditActions/>}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
                <UniversalEditCreate idSource={idSource}/>
        </Create>
    )
};