import PermIdentity from '@material-ui/icons/PermIdentity';
import {CompanyBranchList} from "./CompanyBranchList";
import {CompanyBranchCreate} from "./CompanyBranchCreate";
import {CompanyBranchEdit} from "./CompanyBranchEdit";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: CompanyBranchList,
    create: CompanyBranchCreate,
    edit: CompanyBranchEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;