import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    //AutocompleteInput,
    //ReferenceInput,
    NumberField, useTranslate, useLocale, NumberInput, ReferenceInput, AutocompleteInput, ReferenceField,
    //TextInput,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp} from "../../_common/CompReact";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
import {FloatNumber} from "../../_common/CompFormat/utils";
import {useMemo} from "react";
// import {useForm} from "react-final-form";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;

    return data;
}
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {product_id} = props;
    const translate = useTranslate();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    product: product_id,
                    source2: localStorage.getItem('username'),
                }}
                transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>

                <ReferenceInput
                    label={translate('Variant')}
                    source={'variant'}
                    reference={'dVariantProduct'}
                    filter={{product: product_id}}
                    //validate={validateReq}
                >
                    <AutocompleteInput optionText={`${'article'}`} resettable fullWidth/>
                </ReferenceInput>,

                {/*<NumberInput source="size" label={translate('Size')} fullWidth/>,*/}
                <ReferenceInput
                    label="Size"
                    reference="dSizeProduct"
                    source={'size'}
                    filter={{product: product_id}}
                    //validate={validateReq}
                >
                    <AutocompleteInput
                        optionText={useMemo(() => {
                            return ((record) => `${record?.additional.size_specification.value}`)
                        }, [])}
                        resettable fullWidth
                    />
                </ReferenceInput>

                {/*<NumberInput source="characteristic" label={translate('Characteristic')} validate={validateReq} fullWidth/>,*/}
                <ReferenceInput
                    label={translate('Characteristic')}
                    source={'characteristic'}
                    reference={'dCharacteristicProduct'}
                    filter={{product: product_id}}
                    //validate={validateReq}
                >
                    <AutocompleteInput
                        // optionText={`${'barcode'}`}
                        optionText={useMemo(() => {
                            return ((record) => `#${record?.id}: ${record?.additional.variant.article}, ${record?.barcode}`)
                        }, [])}
                        resettable
                        fullWidth
                    />

                </ReferenceInput>,

                <NumberInput source="base_price" label={translate('Base Price')} validate={validateReq} fullWidth/>,
                <ReferenceInput
                    label={translate('Price Type')}
                    source={'price_type'}
                    reference={'dPriceTypes'}
                    //filter={{product_group: `-34`}}
                    validate={validateReq}
                >
                    <AutocompleteInput optionText={`${'name'}`} resettable fullWidth/>
                </ReferenceInput>,

            </RowForm>
        </>
    )
};

const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,

        variant: /*<NumberField source="variant" label={translate('Variant')}/>,*/
            <ReferenceField source="variant" reference="dVariantProduct"
                            label={translate('Variant')} link={false}
            >
                <TextField source={`${'article'}`}/>
            </ReferenceField>,
        size:
            <ReferenceField source="size" reference="dSizeProduct"
                            label={translate('Size')} link={false}
            >
                <TextField source={`${'additional.size_specification.value'}`}/>
            </ReferenceField>,
        /*size: <NumberField source="size" label={translate('Size')}/>,*/

        characteristic: /*<NumberField source="characteristic" label={translate('Characteristic')}/>,*/
        <ReferenceField source="characteristic" reference="dCharacteristicProduct"
                            label={translate('Characteristic')} link={false}
            >
                <>
                    #<TextField source={`${'id'}`}/>{': '}
                    <TextField source={`${'additional.variant.article'}`}/>{', '}
                    <TextField source={`${'barcode'}`}/>
                </>
            </ReferenceField>,

        base_price: <FloatNumber source={`base_price`} label={translate('Base Price')}
                                 textAlign={"right"}/>,
        price_type: /*<NumberField source="price_type" label={translate('Price Type')}/>,*/
            <ReferenceField source="price_type" reference="dPriceTypes"
                            label={translate('Price Type')} link={false}
            >
                <TextField source={`${'name'}`}/>
            </ReferenceField>,

        source: <TextField source="source" label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                                cellClassName={classes.date_st} showTime={true}/>,

    }
    return columns;
};

const Prices = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const {product_id, source} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'PricesRelation'}list.columns`,
        columns: ColumnsForList('PricesRelation', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Products Price"
                reference="dProductsPrice"
                target="product"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                              source={source} product_id={product_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            source={source} product_id={product_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default Prices;