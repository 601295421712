import PermIdentity from '@material-ui/icons/PermIdentity';
import {ConfigCreate} from "./ConfigCreate";
import {ConfigEdit} from "./ConfigEdit";
import {ConfigList} from "./ConfigList";


// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: ConfigList,
    create: ConfigCreate,
    edit: ConfigEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;