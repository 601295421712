import * as React from 'react';
import {
    useLocale,
    TextInput,
    useTranslate,
    // ReferenceInput,
    // AutocompleteInput,
    //EmailField,
    BooleanInput,
} from 'react-admin';

// import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
// import {RowForm} from "@react-admin/ra-editable-datagrid";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
// import {universalTopFilters} from "../universalView/universalTopFilters";
// import {CardContent, useMediaQuery} from "@material-ui/core";
import {
    BooleanFieldComp, CustomerInfo, ImageInputComp,
} from "../../_common/CompReact";
// import {validateFirstName, validateReq} from "../utils/components/validateTable";
// import {Box} from "@mui/material";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {RowForm} from "@react-admin/ra-editable-datagrid";
import {validateReq} from "../../_constants/validateTable";
import TableActions from "../../_common/TableActions";
import {copyToS3bucket} from "../../_common/Transform";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    data = copyToS3bucket('dPhoneMessenger', data)
    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const translate = useTranslate();

    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    source2: localStorage.getItem('username'),
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <ImageInputComp
                        maxSize={500000}
                        heightImg={90}
                        source={'image'}
                        // label={`${'New'} ${'image'}`}
                        label={''}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Image</b> (500kb max)</p>}
                    />

                <TextInput source="name" label={translate('Name')}
                                      validate={validateReq}
                                      fullWidth
                />
                <BooleanInput source="is_visible"
                              label={translate('Is visible')} defaultValue={false}/>
            </RowForm>
        </>
    )
};

export const Messenger = ({permissions, ...props}) => {
    const classes = useMyStyles();
    // const {resource} = props;
    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();
    const translate = useTranslate();


    return (
        <ListComponentAdmin
            {...props}
            style={{maxWidth: 900}}
            //hasCreate={true}
            actions={<TableActions
                create={true}
                select={false}
                filter={false}
            />}
            title={''}
            is_bulkActionButtons={true}
            sort={{field: 'id', order: 'DESC'}}
        >
            <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable
                    createForm={<ListEditForm submitOnEnter={false}
                                              locale={locale} {...props}/>}
                    editForm={<ListEditForm submitOnEnter={false}
                                            locale={locale}  {...props}/>}
                    //rowStyle={postRowStyle}
                >
                    <CustomerInfo label={translate('Name')}
                            cellClassName={classes.avatar_name_phone_st}
                            type={'img'}
                            name={'name'} src={'image'}
                            sortBy={'name'}/>
                    <BooleanFieldComp source="is_visible" label={translate("Is visible")}
                                      textAlign={"center"}/>,
                </MyEditableDatagridForReference>
        </ListComponentAdmin>
    )
};