import DefaultTable from '../DefaultTable/DefaultClass'
import {commonTrueFalse} from "../../_constants/choices";

class Products extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                name: 'product_group',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'ProductsGroup', optionText: 'name', locale: true},
            },
            {
                name: 'brand_ref',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Brands', optionText: 'name', locale: true},
            }
        ]

        this.filters_aside = [
            {
                name: 'hidden_for_user',
                type: "TrueFalseFilters",
                parameters: [],
            },
            {
                name: 'is_new',
                type: "TrueFalseFilters",
                parameters: [],
            },
            {
                name: 'is_offer',
                type: "TrueFalseFilters",
                parameters: [],
            },
            {
                name: 'is_populate',
                type: "TrueFalseFilters",
                parameters: [],
            },
            {
                name: 'is_sale',
                type: "TrueFalseFilters",
                parameters: [],
            },
            {
                name: 'massive',
                type: "TrueFalseFilters",
                parameters: [],
            },
        ]
        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]

        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'name',
                                type: 'text',
                                show_type: 'TextFieldBold',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                read_only: false
                            },
                            {
                                name: 'name_slug',
                                type: 'text',
                                show_type: 'TextFieldBold',
                                edit_type: 'TranslatableInputs',
                                slug: 'name',
                                type_ext: {locale: true},
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'brand',
                                type: 'text',
                                show_type: 'TextFieldBold',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                read_only: false
                            },
                            {
                                name: 'brand_clean',
                                type: 'text',
                                show_type: 'TextFieldBold',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'product_group',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'ProductsGroup', optionText: 'name', locale: true},
                                read_only: false
                            },
                            {
                                name: 'brand_ref',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Brands', optionText: 'name', locale: true},
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'hidden_for_user',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'BooleanInput',
                                type_ext: {choices: commonTrueFalse, reference: 'Brands', width: '3em'},
                                read_only: false
                            },
                            {
                                name: 'massive',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'BooleanInput',
                                type_ext: {choices: commonTrueFalse, reference: 'Brands', width: '3em'},
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'index',
                                type: 'bool',
                                show_type: 'NumberField',
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                            {
                                name: 'min_quantity_to_receive_discount',
                                type: 'bool',
                                show_type: 'NumberField',
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'secondary_packing_ratio',
                                type: 'bool',
                                show_type: 'NumberField',
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                            {
                                name: 'size_chart',
                                type: 'bool',
                                show_type: 'NumberField',
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                            {
                                name: 'sku',
                                type: 'bool',
                                show_type: 'NumberField',
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                            {
                                name: 'term',
                                type: 'bool',
                                show_type: 'NumberField',
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                            {
                                name: 'transport_packing_ratio',
                                type: 'bool',
                                show_type: 'NumberField',
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'article',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                //type_ext: {locale: true},
                                read_only: false
                            },
                            {
                                name: 'article_clean',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                //type_ext: {locale: true},
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'country_str',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                //type_ext: {locale: true},
                                read_only: false
                            },
                            {
                                name: 'guarantees',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                //type_ext: {locale: true},
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'hs_codes',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                //type_ext: {locale: true},
                                read_only: false
                            },
                            {
                                name: 'product_kit',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                //type_ext: {locale: true},
                                read_only: false
                            },
                            {
                                name: 'uuid',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                //type_ext: {locale: true},
                                read_only: false
                            },
                        ]
                    },
                ]
            },
            {
                tab: "DESCRIPTION",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'description',
                                type: 'text',
                                show_type: 'TextFieldBold',
                                edit_type: 'TranslatableInputsRich',
                                type_ext: {locale: true, multiline: true},
                                read_only: false
                            },
                        ]
                    },
                ]
            },
            {
                tab: "Units of Measure",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'height',
                                type: 'float',
                                show_type: 'TextField',
                                edit_type: 'NumberInputFloatComp',
                                read_only: false,
                            },
                            {
                                name: 'length',
                                type: 'float',
                                show_type: 'TextField',
                                edit_type: 'NumberInputFloatComp',
                                read_only: false,
                            },
                            {
                                name: 'packing_ratio',
                                type: 'float',
                                show_type: 'TextField',
                                edit_type: 'NumberInputFloatComp',
                                read_only: false,
                            },
                            {
                                name: 'volume',
                                type: 'float',
                                show_type: 'TextField',
                                edit_type: 'NumberInputFloatComp',
                                read_only: false,
                            },
                            {
                                name: 'weight',
                                type: 'float',
                                show_type: 'TextField',
                                edit_type: 'NumberInputFloatComp',
                                read_only: false,
                            },
                            {
                                name: 'width',
                                type: 'float',
                                show_type: 'TextField',
                                edit_type: 'NumberInputFloatComp',
                                read_only: false,
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'multiplicity',
                                type: 'bool',
                                show_type: 'NumberField',
                                edit_type: 'NumberInput',
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: []
                    },
                ]
            },
            {
                tab: "Characteristics",
                dataTab: [
                    {
                        box: []
                    },
                ]
            },
            {
                tab: "Specification",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'specification',
                                type: 'text',
                                show_type: 'TextFieldBold',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                read_only: false
                            },
                        ]
                    },
                ]
            },
            {
                tab: "Additionally",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'is_new',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'BooleanInput',
                                type_ext: {choices: commonTrueFalse, reference: 'Brands', width: '3em'},
                                read_only: false
                            },
                            {
                                name: 'is_offer',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'BooleanInput',
                                type_ext: {choices: commonTrueFalse, reference: 'Brands', width: '3em'},
                                read_only: false
                            },
                            {
                                name: 'is_populate',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'BooleanInput',
                                type_ext: {choices: commonTrueFalse, reference: 'Brands', width: '3em'},
                                read_only: false
                            },
                            {
                                name: 'is_sale',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'BooleanInput',
                                type_ext: {choices: commonTrueFalse, reference: 'Brands', width: '3em'},
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: []
                    },
                    {
                        box: []
                    },
                ]
            },
            {
                tab: "Other",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'product_care',
                                type: 'text',
                                show_type: 'TextFieldBold',
                                edit_type: 'TranslatableInputs',
                                type_ext: {locale: true},
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: []
                    },
                    {
                        box: []
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]

        this.related_table = [
            {
                name: 'PersonalDiscountsByProduct',
                filters: 'product',
            },
            {
                name: 'ProductToProductGroup',
                filters: 'product',
            },
            {
                name: 'PromotionDiscountByProduct',
                filters: 'product',
            },
            {
                name: 'RelatedProducts',
                filters: 'product',
            },
            {
                name: 'AssortimentMarkupByQuantity',
                filters: 'product',
            },
            {
                name: 'ComponentGood',
                filters: 'product',
            },
            {
                name: 'MonthsOfActiveSales',
                filters: 'product',
            },
            {
                name: 'Product2TDCharacteristics',
                filters: 'product',
            },
            {
                name: 'PriceRows',
                filters: 'product',
            },
        ]

    }
}

export default Products