import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    useTranslate,
    TextInput, useGetList, SelectInput,
} from 'react-admin';
import {Box} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";

import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
// import {validateEmail} from "../utils/components/validateTable";
import {useEffect, useState} from "react";
import {getPromptForTable} from "../../_common/CompFormat/utils";
import {choicesSizeSystem} from "../../_constants/choices";
import SizeSpecification from "./SizeSpecification";
import FootherEdit from "../../_common/Headers/FootherEdit";

const BlockEdit = props => {
    const translate = useTranslate();
    const {related_id} = props
    const {promptData} = props;
    // const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={'2em'}>
                    <SelectInput
                        variant={"outlined"}
                        source={'size_system'}
                        choices={choicesSizeSystem}
                        resettable
                        label={translate('Size System')}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        source={'item_code_in_accounting_system'}
                        label={translate('Item code in accounting system')}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <MyTranslatableInputs
                        prompt={promptData.find(x => x.field === 'name')}
                        fullWidth
                        source={'name'}
                        label={translate('Name')}
                    />
                </Box>
            </Box>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <MyTranslatableInputs
                        prompt={promptData.find(x => x.field === 'description')}
                        fullWidth
                        source={'description'}
                        label={translate('Description')}
                    />
                </Box>
            </Box>
            {related_id &&
                <Box display="grid" alignItems={"top"}>
                    <Box flex={1} mb={"1em"}
                         style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                    >
                        <h4 style={{margin: 0}}>{translate("Size Specification")}</h4>
                        <SizeSpecification related_id={related_id}/>
                    </Box>
                </Box>
            }
        </Box>
    )
}
export const SizeChartEditCreate = props => {//Не работает

    const translate = useTranslate();
    const {record, resource} = props;
    const {id} = record || {};

    const [promptData, setPromptData] = useState([]);
    const {data: dataPrompts, loading: isLoading} = useGetList(
        "dPrompts",
        {page: 1, perPage: 100},
        {field: 'id', order: 'ASC'},
    );

    useEffect(() => {
        if (dataPrompts) {
            let dt = getPromptForTable(dataPrompts, resource)
            setPromptData(dt)
        }
    }, [dataPrompts, isLoading]);// eslint-disable-line


    return (
        <Box>

            <TabbedForm
                {...props}
                //initialValues={{source: localStorage.getItem('username')}}
                defaultValue={{source: localStorage.getItem('username')}}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        related_id={id}
                        promptData={promptData}
                    />
                </FormTab>
                {/*{id &&
                    <FormTab label={translate('Seo')}>
                        <Seo
                            {...props}
                            resource={'dMenuItems'}
                            id={id}
                            promptData={promptData}
                        />
                    </FormTab>
                }*/}
                {/*<FormTab label={translate('Media')}>
                    <BlockEditImg
                        {...props}
                        related_id={id}
                        //promptData={promptData}
                    />
                </FormTab>*/}
            </TabbedForm>

            {id && <FootherEdit source={record.source}/>}

        </Box>
    )
};

export default SizeChartEditCreate
