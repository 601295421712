import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    useTranslate, useLocale, AutocompleteInput,
    TextInput,
} from 'react-admin';

import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {DateFieldComp} from "../../_common/CompReact";
import {choicesDefaultLanguage} from "../../_constants/choices";

const transform = (data) => {
    data.source = data.source2;
    data.name = data.name2;
    delete data.source2;
    delete data.name2;
    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {country_id, record} = props;
    const translate = useTranslate();

    console.log('props=========>', props)

    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    country: country_id,
                    source2: localStorage.getItem('username'),
                    name2: record.name,
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <TextInput
                    source={"name2"} label={translate("Name")}
                    validate={validateReq} fullWidth
                    style={{minWidth: 90}}
                />
                {/*<SelectInput
                    optionText="name"
                    optionValue="code"
                    source={'code'}
                    choices={choicesDefaultLanguage}
                    label={translate('Code')}
                    //variant={"outlined"}
                    validate={validateReq}
                    fullWidth resettable
                />*/}
                <AutocompleteInput
                        optionText="name"
                        optionValue="code"
                        source={'code'}
                        choices={choicesDefaultLanguage}
                        label={translate('Code')}
                        //variant={"outlined"}
                        fullWidth resettable
                    />
            </RowForm>
        </>
    )
};

const AvailableLanguage = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {country_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Available Language ()"
                reference="dAvailableLanguage"
                target="country"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              country_id={country_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            country_id={country_id}/>}
                    //rowStyle={postRowStyle}
                >
                    <TextField source='name' label={translate('Name')}/>
                    <TextField source='code' label={translate('Code')}/>
                    <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                   locale={'us'} cellClassName={classes.date_st}/>
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default AvailableLanguage;