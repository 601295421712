import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    NumberField, useTranslate,
    SelectInput,
} from 'react-admin';
import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {BooleanFieldComp, DateFieldComp} from "../../_common/CompReact";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateReq} from "../../_constants/validateTable";
import {Box} from "@material-ui/core";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {choicesDefaultRelation} from "../../_constants/choices";
import {compareIndex} from "../discount_relationship/DiscountRelationship";
import {useSelector} from "react-redux";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {current_discount_rule_id, formData} = props;
    const translate = useTranslate();

    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{current_discount_rule_id: current_discount_rule_id}}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <SelectInput source={'relation'} choices={choicesDefaultRelation}
                             label={translate('Relation')} validate={validateReq} fullWidth/>

                <ReferenceInput source="related_discount_rule_id" reference="discount_rules"
                                filter={{index_lt: formData.index, rule_type: formData.rule_type}}
                                label={translate('Relaited Discount Rule')}
                                validate={validateReq}>
                    <AutocompleteInput resettable optionText="name" fullWidth/>
                </ReferenceInput>
                <ReferenceField source="related_discount_rule_id" reference="discount_rules"
                                label={translate('Active')}
                                link={false}
                                sortBy={'discount_rules:active'}>
                    <Box><BooleanFieldComp source="active" label={translate("Active")} textAlign={"center"}/>
                    </Box>
                </ReferenceField>

                <ReferenceField source="related_discount_rule_id" reference="discount_rules"
                                label={translate('Date From')}
                                link={false}
                                sortBy={'discount_rules:date_from'}>
                    <Box><DateFieldComp source="date_from" label={translate('Date From')} textAlign={'center'}
                                        showTime={false}
                                        locale={'us'} cellClassName={classes.date_st}/>
                    </Box>
                </ReferenceField>
                <ReferenceField source="related_discount_rule_id" reference="discount_rules"
                                label={translate('Date To')}
                                link={false}
                                sortBy={'discount_rules:date_to'}>
                    <Box><DateFieldComp source="date_to" label={translate('Date To')} textAlign={'center'}
                                        showTime={false}
                                        locale={'us'} cellClassName={classes.date_st}/>
                    </Box>
                </ReferenceField>

            </RowForm>
        </>
    )
};

const ColumnsForList = (resource, classes) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        relation:
            <SelectAndSaveChoices
                label={translate('Relation')}
                source="relation"
                statuses={choicesDefaultRelation}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                //edit={true}
                width={'10em'}
            />,
        related_discount_rule_id:
            <ReferenceField source="related_discount_rule_id" reference="discount_rules"
                            label={`${translate('Relaited Discount Rule')} (index)`}
                            link={true}
                            sortBy={'discount_rules:name'}>
                <Box><TextField source="name"/> (<TextField source="index"/>)</Box>
            </ReferenceField>,
        active:
            <ReferenceField source="related_discount_rule_id" reference="discount_rules"
                            label={translate('Active')}
                            link={false}
                            sortBy={'discount_rules:active'}>
                <Box><BooleanFieldComp source="active" label={translate("Active")} textAlign={"center"}/>
                </Box>
            </ReferenceField>,
        date_from:
            <ReferenceField source="related_discount_rule_id" reference="discount_rules"
                            label={translate('Date From')}
                            link={false}
                            sortBy={'discount_rules:date_from'}>
                <Box><DateFieldComp source="date_from" label={translate('Date From')} textAlign={'center'}
                                    showTime={false}
                                    locale={'us'} cellClassName={classes.date_st}/>
                </Box>
            </ReferenceField>,
        date_to:
            <ReferenceField source="related_discount_rule_id" reference="discount_rules"
                            label={translate('Date To')}
                            link={false}
                            sortBy={'discount_rules:date_to'}>
                <Box><DateFieldComp source="date_to" label={translate('Date To')} textAlign={'center'} showTime={false}
                                    locale={'us'} cellClassName={classes.date_st}/>
                </Box>
            </ReferenceField>,
        created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
    }
    return columns;
};

const DiscountRelationShip = props => {
    // const {customer_id, record, isSave = true} = props;
    const {current_discount_rule_id, formData} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    // console.log('=======>', invoiceBooking);

    const columns = useSelectedColumns({
        preferences: `${'discount_relationship2'}list.columns`,
        columns: ColumnsForList('discount_relationship2', classes),
        omit: ["nb_views"],
    });

    let discount_rules = useSelector((state) => state.admin.resources.discount_rules.data)
    if (discount_rules) discount_rules = Object.values(discount_rules);

    const postRowStyle = (record, index) => ({
        // backgroundColor: (record?.current_discount_rule_id & 1) && '#efe',
        //backgroundColor: compareIndex(record) && '#efe',
        backgroundColor: compareIndex(record, discount_rules) && '#fff0f0',
        // display: "table-cell",
    });

    return (
        <div style={{width: "fit-content", minWidth: 500, marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Discount Relation Ship"
                reference="discount_relationship"
                target="current_discount_rule_id"
                //filters={current_discount_rule_id: discount_rule_id}
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    rowStyle={postRowStyle}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false}
                                              current_discount_rule_id={current_discount_rule_id} formData={formData}/>}
                    editForm={<ListEditForm submitOnEnter={false}
                                            current_discount_rule_id={current_discount_rule_id} formData={formData}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default DiscountRelationShip;