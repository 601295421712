export const transUk = {
    myLocal: {
        columns: 'Columns',
        hide_filter: 'Pre-Filters', //'Hide Filter',
        show_filter: 'Pre-Filters', //'Show Filter',
    },
    dbFields: {},

    "Code": "Код",

    "Show on creation customer process": "Відображати на формі створення клієнта",
    "Partners delivery address": "Адреса доставки партнера",
    "Partners delivery addresses": "Адреси доставки партнера",
    "Select a user from the list": "Вибрати користувача зі списку",
    "Enter new user": "Ввести нового користувача",
    "Go to client": "Перейти до клієнта",
    "This field already exists": "Таке поле вже існує",
    "Client saved": "Клієнт успішно збережений",
    "Create new client": "Створити нового клієнта",
    "Delivery Routes": "Маршрути доставки",
    "Need to specify an address for the counterparty": "Потрібно вказати адресу для контрагента",
    "Delete": "Видалити",
    "Route": "Маршрут",
    "Routes": "Мршрути",
    "Auto": "Авто.",
    "Create client": "Створення клієнта",
    "Partner from list": "Вибрати партнера зі списку",
    "New customer": "Новий контрагент",
    "New customers": "Нові контрагенти",
    "New partner": "Створити нового партнера",
    "Create Partner": "Створити партнера",
    "Save": "Зберегти",
    "Accounting phone": "Телефон бухгалтерії",
    "Warehouse phone": "Телефон складу",
    "Accounting email": "Електронна пошта бухгалтерії",
    "Partner information": "Інформація про партнера",
    "Partner customers": "Контрагенти партнера",
    "Partner address": "Адреси партнера",
    "Delivery": "Доставка",
    "Create partner": "Створити партнера",
    "Create customers": "Створити контрагентів",
    "Customers for partner": "Створення контрагентів для партнера",
    "Add customer": "Додати ще одного контрагента",
    "Add address": "Додати ще одну адресу",
    "Settings for pricing": "Налаштування ціноутворення",
    "Common data": "Загальні дані",
    "Customer users": "Користувачі контрагента",
    "Back": "Назад",
    "Next": "Далі",
    "Old address": "Раніше додані адреси",
    "Address for partner": "Адреси для партнера",
    "Save partners data": "Зберегти дані партнера",
    "Save clients data": "Зберегти дані клієнта",
    "New address": "Нова адреса",
    "Select from list": "Обрати зі списку",
    "this value already exists": "таке значення вже існує",

    "Customer property": "Свойства контрагента",

    "Is Active": "Активний",
    "Is Superuser": "Суперкористувач",
    "Is Staff": "Персонал",
    "First Name": "Им'я",
    "Last Name": "Призвище",
    "Partner": "Партнер",
    "Partners": "Партнери",
    "User": "Користувач",
    "Users": "Користувачі",
    "Create user": "Створити користувача",
    "Categories": "Категорії",
    "Password": "Пароль",
    "Change password": "Змінити пароль",
    "Confirm password": "Підтвердити пароль",
    "The password is required (length > 5)": "Потрібно ввести пароль (довжина > 5)",
    "Password confirmation error": "Помилка підтвердження пароля",
    //-----------------------
    "Phone": "Телефон",
    "Phones": "Телефони",
    "Official name": "Офіційне найменування",
    "Customer name": "Найменування контрагента",
    "Code from the accounting system": "Код з облікової системи",
    "Client code": "Код клієнта",
    "Main warehouse": "Основний склад",
    "Legal address": "Юридична адреса",
    "Physical address": "Фізична адреса",
    "Credit limit currency": "Валюта кред. ліміту",
    "Credit limit term (days)": "Термін кред. ліміту (днів)",
    "Credit limit amount": "Сума кред. ліміту",
    "Contractor type": "Тип контрагенту",
    "Business type": "Вид діяльності",
    "VAT payer": "Платник ПДВ",
    "EDRPOU": "ЄДРПОУ",
    "Tax number": "ІПН",
    "VAT identification number": "Ід. номер ПДВ",
    "How to show quantity": "Як показувати наявність",
    "Retail margin": "Роздрібна націнка",
    //-----------------------
    "Manager": "Менеджер",
    "Sales representative SNG": "ТП СНГ",
    "Sales representative Ino": "ТП іномарки",

    "Confirm": "Підтверждено",
    "Disable": "Відключено",
    "Address": "Адреса",
    "Saved Address": "Сохраненный адрес",
    "Address lat / lon": "Адрес lat / lon",
    "Area": "Область",
    "Region": "Район",
    "Point": "Населений пункт",
    "Street": "Вулиця",
    "House": "Будинок",
    "Flat": "Квартира",
    "Deliveries": "Доставки",
    "Comment": "Коментар",
    "Placement": "Розташування",
    "Partner Address": "Адрес партнера",

    "Check coordinates on the map": "Проверить координаты на карте",
    "Fill in coordinates manually": "Заполнить координаты вручную ",
    "Functionality under development": "Функционал в разработке",
    "Automatic address filling": "Автоматическое заполнение адреса",//"Автоматичне заповнення адреси",
    //-----------------------

    ignore: 'игнорировать',

    "Amount (base price)": "Сумма (базовая цена)",
    "Amount (regular price)": "Сумма (регулярная цена)",
    "Total order including discounts": "Итого заказ с учетом скидок",

    add: 'добавить',
    absorb: 'поглощать',
    absorbed_by: 'поглощен (кем/чем)',
    maximum: 'максимум',
    minimum: 'минимум',

    'Active': 'Активный',
    Additionally: 'Дополнительно',
    'Applied Rule': 'Применяемое правило',
    'Absorbed Rule': 'Заменяемое правило',

    'Base price': 'Базовая цена',
    BONUSES: 'БОНУСЫ',
    Bonuses: 'Бонусы',
    'Bonus': 'Бонус',
    'bonus': 'бонус',
    Brands: 'Бренды',
    Brand: 'Бренд',
    brand_ref: 'Бренд (справочник)',
    Brand_ref: 'Бренд (справочник)',
    BrandsSynonyms: 'Синонимы бренда',
    Brandssynonyms: 'Синонимы бренда',
    BrandsReplacement: 'Замены брендов в прайсах поставщиков',
    Brandsreplacement: 'Замены брендов в прайсах поставщиков',
    BrandImage: 'Изображение бренда',
    Brandimage: 'Изображение бренда',
    brands_country: 'Страна бренда',
    'Brands By Rule': 'Бренды по правилам',

    'complicated_rule': 'сложное правило',
    'customer': 'контрагент',
    'Clear Result': 'Очистить результат',
    COMMON: 'ОБЩИЕ',
    Common: 'Общие',
    Characteristics: 'Характеристики',
    Customers: 'Контрагенти',
    Customer: 'Контрагент',
    CREATED: 'СОЗДАНО',
    Created: 'Создано',
    created: 'создано',
    CREATE: 'СОЗДАНО',
    Create: 'Создано',
    create: 'создано',
    Currencies: 'Валюта',
    Currency: 'Валюта',
    'Customer Property Value': 'Значение Свойств Контрагента',
    'Current Discount Rule': 'Текущее Правило Скидки',
    'Customer Discount By Rule': 'Скидка клиента по правилу',
    'Complicated Discount By Rule': 'Сложная скидка по правилу',

    DESCRIPTION: 'ОПИСАНИЕ',
    description: 'описание',
    Description: 'Описание',
    DiscountCustomerBrand: 'Значение дисконта',
    DiscountcustomerBrand: 'Значение дисконта',
    Discount: 'Скидка',
    "Discount Property": 'Свойства Скидок',
    "Discount property": 'Свойства скидок',
    'Discount Rules': 'Правила Скидок',
    'Discount By Rule': 'Скидка по правилу',
    'Discount Property Value': 'Значение Свойств Скидок',
    'Discount property value': 'Значение свойств скидок',
    'Discount Relationship': 'Отношения Скидок',
    'Discount relationship': 'Отношения скидок',
    'Discount Relation Ship': 'Отношения скидок',
    'Default Discount': 'Скидки по умолчанию',
    'Default Relation': 'Отношение по умолчанию',
    'Date From': 'Дата с',
    'Date To': 'Дата по',

    'Earlier': 'Ранее',
    'Export Customer Properties': 'Экспорт свойств контрагента',

    'For All Products': 'Для Всех Товаров',
    'Fix price': 'Фикс. цена',

    'Go Back': 'Вернуться',

    hidden_for_user: 'скрывать для пользователя',
    'History': 'История',

    massive: 'габаритный',

    'Last Rule': 'Последнее правило',

    // NO: 'НЕТ',
    // YES: 'ДА',
    // No: 'Нет',
    // Yes: 'Да',

    name: 'найменування ',
    Name: 'Найменування ',

    'Order discounts': 'Скидка на заказ',
    original_brand: 'оригинальный бренд',
    Other: 'Другие',
    'Min.amount of non-prom. item': 'Мин.сумма неакц-го товара',
    'Min.order amount': 'Мин.сумма заказа',
    'Min. amount': 'Мин. сумма',

    'Order Quantity': 'Кол-во (заказ)',
    'order': 'заказ',
    'Order': 'Заказ',

    'Quantity': 'Кол-во',
    'quantity': 'Кол-во',

    Prefixes: 'Префикс',
    'Price with order discounts': 'Цена со скидкой при заказе',
    'PRODUCTS': 'ПРОДУКТЫ',
    Protected: 'Защищено',
    protected: 'защищено',
    Products: 'Товары',
    Product: 'Товар',
    'Products By Rule': 'Товары по правилам',
    'Product Property': 'Свойства Товара',
    'Product Property Value': 'Значения Свойств Товара',
    'Price Currency': 'Валюта Цены',
    'Price': 'Цена',
    'product': 'товар',

    'Price Of Week': 'Цена недели',
    'Price of week': 'Цена недели',

    'Regular discounts': 'Регулярные скидки',
    'Regular price': 'Регулярная цена',
    'Related Table': "Доп. Таблицы",
    "Related table": 'Доп. таблицы',
    'Rule type': 'Тип правила',
    'Rule Type': 'Тип Правила',
    'Rate': 'курс',
    'Relaited Discount Rule': 'Правило Связанных Скидок',
    'Relation': 'Отношение',

    // "Brand_ref": 'Бренд (справочник)',
    Index: "Индекс",
    index: "индекс",
    is_new: 'Новый',
    is_offer: 'Акция',
    is_populate: 'Популярный',
    is_sale: 'Распродажа',

    Specification: 'Спецификация',
    sku: 'ску',
    source: 'источник',
    Suffixes: 'Суфикс',
    'Save & Close': 'Сохранить и выйти',
    'Search': 'Поиск',

    'Test': "Проверка",
    'Test Price': 'Проверка цены',
    'Test Order': 'Проверка заказа',
    Today: 'Сегодня',
    'This week': 'Эта неделя',
    'This month': 'Этот месяц',

    Updated: 'Изменено',
    updated: 'изменено',
    Update: 'Изменено',
    update: 'изменено',
    "Units of Measure": 'Еденицы измерения',
    uuid: 'uuid',

    'PROTECTED RECORDS. Creation of related tables prohibited': 'ЗАЩИЩЕННЫЕ ЗАПИСИ. Создание сопутствующих таблиц запрещено',
    'Editing of related tables will be available after record creation': "Редактирование сопутствующих таблиц буде доступно после создания записи",
    remove_alternate_parent: "Видалити альтернативний зв'язок з %{parent_name}",
    replace_alternate_to_parent: "Зробити %{parent_name} прямим батьком і зробити зв'язок з %{alternate_parent_name} альтернативним",
    replace_parent: "Зробити %{parent_name} прямим батьком та видалити зв'язок із %{alternate_parent_name}",
    remove_parent: "Видалити прямий зв'язок із %{parent_name}",
    make_alternative: "Зробити %{parent_name} альтернативним батьком",
    make_direct: "Зробити %{parent_name} прямим батьком",
    undo_action: 'Скасувати дію'

}
