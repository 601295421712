import * as React from 'react';
import {
    //TextField,
    ReferenceManyField,
    //AutocompleteInput,
    //ReferenceInput,
    //NumberField,
    useTranslate, useLocale,
    BooleanInput,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {BooleanFieldComp, CustomerInfo, DateFieldComp, ImageInputComp} from "../../_common/CompReact";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
// import {validateReq} from "../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
// import {useForm} from "react-final-form";
// import get from 'lodash/get';
import CroppedLongField from "../../_common/CroppedLongField";
import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
import {copyToS3bucket} from "../../_common/Transform";
import {Box} from "@material-ui/core";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    data = copyToS3bucket('dFeatureValidValue', data)
    return data;
}

const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {related_id} = props;
    const translate = useTranslate();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    feature_type: related_id,
                    source2: localStorage.getItem('username'),
                }}
                transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                {/*<NumberField source="id" headerClassName={classes.headerShortWidth}/>*/}
                <Box style={{display: "grid"}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={90}
                        source={'icon'}
                        // label={`${'New'} ${'image'}`}
                        label={''}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Icon</b> (500kb max)</p>}
                    />

                    <MyTranslatableInputs
                        fullWidth
                        source={'valid_value'}
                        label={translate('Valid value')}
                    />
                </Box>

                <BooleanInput source={'is_hide_in_filters_list'}
                              label={translate('Is hide in filters list')}
                              defaultValue={false} fullWidth/>

                <BooleanInput source={'is_hide_on_product_page'}
                              label={translate('Is hide on product page')}
                              defaultValue={false} fullWidth/>

                {/*<TextInput source="url" label={translate('Url')} />*/}
            </RowForm>
        </>
    )
};
const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        // id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        valid_value:
            <CustomerInfo
                label={translate('Valid value')}
                cellClassName={classes.avatar_name_phone_st}
                type={'img'}
                name={'valid_value'} src={'icon'}
                sortBy={'valid_value'}
                locale={locale}
            />,
        /*valid_value: <TextField source={`valid_value[${locale}]`}
                                label={translate('Valid value')}/>,*/
        is_hide_in_filters_list: <BooleanFieldComp source="is_hide_in_filters_list"
                                                   label={translate('Is hide in filters list')}/>,
        is_hide_on_product_page: <BooleanFieldComp source="is_hide_on_product_page"
                                                   label={translate('Is hide on product page')}/>,
        /*source: /!*<TextField source="source" label={translate('Source')}/>,*!/
            <CroppedLongField source="source" long={10} label={translate('Source')}/>,*/
        updated:
            <Box style={{display: "grid"}} label={translate('Updated')} sortby={'updated'} textAlign={'center'}>
                <CroppedLongField source="source" long={10} label={translate('Source')}/>
                <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                               cellClassName={classes.date_st} showTime={true}/>
            </Box>,

    }
    return columns;
};

const FeatureValidValue = props => {

    const {related_id} = props;
    const locale = useLocale();
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'FeatureValidValue'}list.columns`,
        columns: ColumnsForList('FeatureValidValue', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Feature Valid Value"
                reference="dFeatureValidValue"
                target="feature_type"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                              related_id={related_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            related_id={related_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default FeatureValidValue;