import DefaultTable from '../DefaultTable/DefaultClass'
import {JustBool, JustFloat, JustText, JustTranslText} from "../tablePattern";

class DiscountGroups extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
 /*           {
                name: 'DiscountGroups',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'DiscountGroups', optionText: 'name', locale: true},
            },
*/        ]


        this.filters_aside = []

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]


        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            JustTranslText('name'),
                            JustFloat('customer_discount'),
                        ]
                    },
                    {
                        box: [
                            JustText('item_code_in_accounting_system'),
                            JustBool('use_discount_by_brands'),
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]
    }
}

export default DiscountGroups