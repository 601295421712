//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, ReferenceField,
    SimpleList,
    TextField,
    useLocale,
    useTranslate,
    // ReferenceInput,
    // AutocompleteInput,
    // EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {Box, CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../../index.css';
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
//import {exporterPassenger} from "../utils/exporterFile";
import {BooleanFieldComp, DateFieldComp, FilterDataAside, TextFieldBold} from "../../_common/CompReact";
import CardComp from "../../_common/Asside/CardComp";
import CroppedLongField from "../../_common/CroppedLongField";

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    // const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch/>
                <FilterDataAside fields={'updated'} name={'update'}/>
                {/*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*/}
                {/*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*/}
                {/*<TrueFalseFilters field={'is_load'} label={'Exported to Xero'}/>*/}
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
            </CardContent>
        </CardComp>
    )
};

const TopFilters = (resource, locale) => {
    let dt = [];
    /*dt.push(
        <ReferenceInput
            source="product_group"
            reference="dProductsGroup"
            alwaysOn label={'Categories'}
        >
            <AutocompleteInput
                optionText={`${'name'}[${locale}]`}
                resettable
                emptyText={'All Categories'}/>
        </ReferenceInput>
    );*/
    /*
    dt.push(<ReferenceInput source="prompt_type_id" reference="dPromptTypes" alwaysOn>
        <AutocompleteInput optionText="code" resettable emptyText={'All Types'}/>
    </ReferenceInput>);*/

    return dt;
}

const ColumnsForList = (resource, locale, classes, isSmall) => {
    const translate = useTranslate();
    let columns = {}
    if (isSmall) {
        columns = {
            // id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
            // prompt: <TextFieldBold source={`prompt`} label={translate('Prompt')} cellClassName={classes.prompt_name}/>,
            name:
                <Box className={classes.smallListBox}>
                    <span className={classes.smallListName}>{translate('Name')}: </span>
                    <TextField source={`name[${locale}]`} label={translate('Name')}/>
                </Box>,
            original_brand:
                <Box className={classes.smallListBox}>
                    <span className={classes.smallListName}>{translate('Original Brand')}: </span>
                    <BooleanFieldComp source="original_brand" label={translate("Original Brand")}
                                      textAlign={"center"}/>
                </Box>,
            synonyms_search:
                <Box className={classes.smallListBox}>
                    <span className={classes.smallListName}>{translate('Synonyms Search')}: </span>
                    <TextField source={`synonyms_search`} label={translate('Synonyms Search')}/>
                </Box>,
            brands_country:
                <Box className={classes.smallListBox}>
                    <span className={classes.smallListName}>{translate('Brands Country')}: </span>
                    <TextField source={`brands_country`} label={translate('Brands Country')}/>
                </Box>,
            uuid:
                <Box className={classes.smallListBox}>
                    <span className={classes.smallListName}>{translate('Uuid')}: </span>
                    <TextField source={`uuid`} label={translate('Uuid')} cellClassName={classes.long_id_uuid}/>
                </Box>,
            ext_id:
                <Box className={classes.smallListBox}>
                    <span className={classes.smallListName}>{translate('Ext Id')}: </span>
                    <TextField source={`ext_id`} label={translate('Ext Id')}/>
                </Box>,

        }
    } else {
        columns = {
            id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
            // prompt: <TextFieldBold source={`prompt`} label={translate('Prompt')} cellClassName={classes.prompt_name}/>,
            name: <TextFieldBold source={`name[${locale}]`} label={translate('Name')}/>,
            product_group:
                <ReferenceField
                    sortable={false}
                    link={true}
                    label={translate('Categories')}
                    source={'product_group'}
                    reference={'dProductsGroup'}
                    //filter={{question$id: id}}
                >
                    <TextField source={`name[${locale}]`}/>
                </ReferenceField>,
            original_brand: <BooleanFieldComp source="original_brand" label={translate("Original Brand")}
                                              textAlign={"center"}/>,
            synonyms_search: <TextField source={`synonyms_search`} label={translate('Synonyms Search')}/>,
            brands_country: <TextField source={`brands_country`} label={translate('Brands Country')}/>,
            uuid: <TextField source={`uuid`} label={translate('Uuid')} cellClassName={classes.long_id_uuid}/>,
            ext_id: <TextField source={`ext_id`} label={translate('Ext Id')}/>,

            source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
            updated: <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                    locale={'us'} cellClassName={classes.date_st}/>,
        }
    }
    return columns;
};

export const BrandList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const translate = useTranslate();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                permissions={permissions}
                asideFilter={false}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
            is_bulkActionButtons={true}
        >
            {isSmall
                ?
                <SimpleList
                    rowClick={false}
                    primaryText={<># <TextField source={`id`}/></>}
                    secondaryText={
                        <Box>
                            <CroppedLongField source="source" long={10} label={translate('Source')}/>
                            {', '}
                            <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'}
                                           showTime={true}
                                           locale={'us'} cellClassName={classes.date_st}/>
                        </Box>
                    }
                    tertiaryText={
                        <Box className={classes.smallListBlock}>
                            {columns}
                        </Box>
                    }
                    // linkType={false}//{record => record.canEdit ? "edit" : "show"}
                    linkType={'edit'}
                    rowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}