import {useEffect, useState} from "react";
import {useCreate, useDelete, useGetManyReference, useTranslate, useUpdate} from "react-admin";
import {Box, Checkbox, TextField as TextFieldMaterial} from "@material-ui/core";
import * as React from "react";
import {useNotify} from "ra-core";
import get from "lodash/get";
import {copyToS3bucketIm} from "./Transform";
import {FormControlLabel, LinearProgress} from "@mui/material";
import InsertPhotoIcon from "@material-ui/icons/InsertPhoto";
import SaveIcon from '@mui/icons-material/Save';
// import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import Button from "@material-ui/core/Button";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

//import AddToPhotosIcon from "@material-ui/icons/AddToPhotos";
import AddToPhotosIcon from '@mui/icons-material/AddAPhoto';
import WallpaperIcon from '@mui/icons-material/Wallpaper';

import {removeBackground} from "./CompForApi/utilsAPI";

const MyUpdateImg2 = (props) => {
    const {
        handleActionImg,
        variantId, dateSelImg,
        // setDateSelImg,
        countImg, resourceImg, targetImg
    } = props

    const notify = useNotify();
    //const refresh = useRefresh();
    const translate = useTranslate();

    const [progress, setProgress] = useState(false);
    const [filePreview, setFilePreview] = useState(null);
    // const [create, {error, data}] = useCreate();
    const [create, {errorCreate, data: dataCreate}] = useCreate();
    const [update, {error: errorUpdate, data: dataUpdate}] = useUpdate();
    const [deleteOne, {error: errorDelete, data: dataDelete}] = useDelete();

    // const { data, isLoading, error, refetch } = useGetOne('dVariantProduct', record.id);

    const iniForm = {
        image: '',
        description: '',
        is_main: false,
        sort: 1,
        source: localStorage.getItem('username'),
        [targetImg]: variantId,//28,
    }
    const [form, setForm] = useState(iniForm);


    useEffect(() => {
        if (dataCreate) {
            handleActionImg('Create', dataCreate)
        } else if (errorCreate) notify(JSON.stringify(errorCreate) + ' !!!', 'error');
    }, [errorCreate, dataCreate]);// eslint-disable-line

    useEffect(() => {
        if (dataUpdate) {
            handleActionImg('Update', dataUpdate)
        } else if (errorUpdate) notify(JSON.stringify(errorUpdate) + ' !!!', 'error');
    }, [errorUpdate, dataUpdate]);// eslint-disable-line

    useEffect(() => {
        if (dataDelete) {
            handleActionImg('Delete', dataDelete)
        } else if (errorDelete) notify(JSON.stringify(errorDelete) + ' !!!', 'error');
    }, [errorDelete, dataDelete]);// eslint-disable-line

    //console.log('form=====>', form)
    useEffect(() => {
        if (dateSelImg?.id) {
            let img = get(dateSelImg, 'image', null)
            setFilePreview(img);
            setForm(dateSelImg)
        } else {
            setFilePreview(null);
            setForm(iniForm)
            //document.getElementById('fileselect2').click()
        }
    }, [dateSelImg]);// eslint-disable-line

    /*const handleClearImg = async event => {
        setForm({
            ...form,
            image: ''
        })
        setFilePreview(null)
    }*/

    const handleRefresh = event => {
        if (dateSelImg?.id) {
            let img = get(dateSelImg, 'image', null)
            setFilePreview(img);
            setForm(dateSelImg)
        }
    }
    const handleRemoveBackground = async event => {
        setProgress(true)
        let res = await removeBackground(dateSelImg.image, notify)
        setProgress(false)
        // console.log('removeBackground==============>', rrr)
        if (res) {
            setFilePreview(res)
            setForm({
                ...form,
                image: res
            })

        } else notify('ERROR Remove Background', 'error');
    }
    const onFileChange = event => {
        // Update the state
        let val;
        let name = event.target.name
        switch (event.target.name) {
            case 'is_main':
                val = event.target.checked;
                break;
            case 'sort':
                val = event.target.value;
                break;
            case 'description':
                val = event.target.value;
                break;
            case 'image':
                const file = event.target.files[0];
                if (file && file.type !== undefined) {
                    val = file;
                    // setSelectedFile(file);
                    setFilePreview(URL.createObjectURL(file));
                }
                break;
            default:
                return
        }
        setForm({
            ...form,
            [name]: val
        })
    };

    const handleSaveImg = async (event) => {
        let res = await copyToS3bucketIm('dProduct', form)
        //console.log('RES===============>', res)
        let tmp = JSON.parse(JSON.stringify(form))
        tmp.image = res
        console.log('dateSelImg=========>', dateSelImg)
        if (tmp.id) {
            update(resourceImg, tmp.id, tmp, dateSelImg)
            // setDateSelImg(null)
        } else {
            create(resourceImg, tmp)
            /*setDateSelImg(null)
            setTimeout(function () {
                refresh();
            }, 1000);*/
        }

    }

    const handleDeleteImg = event => {
        console.log('dateSelImg=========>', dateSelImg)
        if (form.id) {
            deleteOne(resourceImg, form.id, dateSelImg)
            /*setDateSelImg(null)
            setTimeout(function () {
                refresh();
            }, 500);*/
        }

    }

    return (
        <>
            <input
                hidden={true}
                type="file"
                id="fileselect2"
                name="image"
                onChange={onFileChange}
                accept={"image/gif, image/jpeg, image/png"}
            />
            <Box display="grid" alignItems={"center"}>
                <Box display="flex" alignItems={"center"} p={'0 0 1em 0'}>
                    <Box flex={1}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    //style={{'padding': "0px"}}
                                    checked={form.is_main}
                                    name={'is_main'}
                                    onChange={onFileChange}
                                    label={translate("Is main")}
                                />
                            }
                            label={`Is${'\u00a0'}main`}
                        />

                    </Box>
                    <Box flex={3} mr={"1em"}>
                        <TextFieldMaterial
                            disabled={resourceImg === 'dVariantProductImage' ? true : false}
                            fullWidth
                            value={form.description}
                            size={"small"}
                            label={translate("Image Description")}
                            variant="outlined"
                            name={'description'}
                            onChange={onFileChange}
                        />
                    </Box>
                    <Box flex={1}>
                        <TextFieldMaterial
                            fullWidth
                            value={form.sort}
                            size={"small"}
                            label={translate("Sort")}
                            variant="outlined"
                            type={"number"}
                            name={'sort'}
                            onChange={onFileChange}
                        />
                    </Box>
                </Box>
                <Box display="flex" alignItems={"top"} p={'1em'}
                     style={{
                         //justifyContent: 'center',
                         minHeight: 270,
                         border: '1px solid #ddd',
                         borderRadius: 16,
                     }}
                >
                    <Box display="grid" alignItems={"top"} style={{marginRight: 8}}>
                        {dateSelImg?.id &&
                            <div title={'Refresh'} style={{zIndex: 1,}}>
                                <SettingsBackupRestoreIcon
                                    onClick={handleRefresh}
                                    style={{
                                        cursor: "pointer",
                                        // position: "absolute",
                                        // top: 176,
                                        // right: 32,
                                        fontSize: 32,
                                        color: '#2196f3',
                                        backgroundColor: 'white',
                                        // marginRight: 8,
                                        // zIndex: 1,
                                    }}
                                />
                            </div>
                        }

                        <div title={'Select Image'} style={{zIndex: 1,}}>
                            <InsertPhotoIcon
                                title={'Change Image'}
                                onClick={() => {
                                    document.getElementById('fileselect2').click()
                                }}
                                style={{
                                    cursor: "pointer",
                                    // position: "absolute",
                                    // top: 140,
                                    // right: 32,
                                    fontSize: 32,
                                    color: '#2196f3',
                                    backgroundColor: 'white',
                                    // marginRight: 8,
                                    //zIndex: 1,
                                }}
                            />
                        </div>
                        {/*{filePreview &&
                        <RemoveCircleOutlineIcon
                            onClick={handleClearImg}
                            style={{
                                cursor: "pointer",
                                // position: "absolute",
                                // top: 176,
                                // right: 32,
                                fontSize: 32,
                                color: 'red',
                                zIndex: 1,
                            }}
                        />
                    }*/}
                        {filePreview && dateSelImg?.id && typeof form?.image === 'string' &&
                            <div title={'Remove Background'} style={{zIndex: 1,}}>
                                <WallpaperIcon
                                    onClick={handleRemoveBackground}
                                    style={{
                                        cursor: "pointer",
                                        // position: "absolute",
                                        // top: 176,
                                        // right: 32,
                                        fontSize: 32,
                                        color: '#2196f3',
                                        backgroundColor: 'white'
                                        // zIndex: 1,
                                    }}
                                />
                            </div>
                        }
                    </Box>
                    {filePreview &&
                        <img
                            //title={}
                            style={{marginLeft: 0}}
                            loading="lazy"
                            height={270}
                            src={filePreview}
                            alt='new img'
                        />}

                </Box>
                {progress && <LinearProgress color="secondary"/>}

                <Box display="flex" alignItems={"center"} p={'0.5em 0 0 0'}
                     style={{
                         minHeight: '2.5em',
                         justifyContent: countImg > 1 && form?.id && "space-between",
                     }}
                >
                    {filePreview &&
                        <Button
                            //variant="contained"
                            //color={"default"}
                            style={{color: "dodgerblue", fontWeight: "bold"}}
                            onClick={handleSaveImg}
                            startIcon={<SaveIcon/>}
                        >
                            {form?.id ? 'Save' : 'Save New Image'}
                        </Button>
                    }

                    {form?.id &&
                        <Button
                            disabled={!form?.id}
                            style={{color: "red"}}
                            //variant="contained"
                            color={"default"}
                            onClick={handleDeleteImg}
                        >
                            Delete Image
                        </Button>
                    }
                </Box>

            </Box>
        </>
    )
}

export const MyImgForSlider = (props) => {
    const {
        setVariantSelData,
        variantSelData,
        variantData,
        resourceImg,
        targetImg
    } = props
    //const {source} = props;

    const [start, setStart] = useState(false);
    const [dataImg, setDataImg] = useState([]);
    const [dateSelImg, setDateSelImg] = useState(null);

    // const record = useRecordContext(props);

    // const [getAllImgForVariant, {data, loaded}] = useGetManyReference();
    const {data = {}, loaded} = useGetManyReference(
        resourceImg,
        targetImg,
        variantSelData.id,
        {page: 1, perPage: 100},
        {},
        {},
    );

    useEffect(() => {
        setStart(false)
    }, [variantSelData]);// eslint-disable-line

    useEffect(() => {
        // console.log(loaded, 'dataImg===STart==111===>', data)
        if (loaded && !start) {
            let tmp = Object.values(data)
            tmp = tmp.sort(function (a, b) {
                if (a.sort === b.sort) return 0
                if (a.sort > b.sort) {
                    return 1;
                } else return -1;
            });
            // console.log(loaded, 'dataImg===STart=====>', tmp)
            setDataImg(tmp)
            if (tmp.length > 0) setDateSelImg(tmp[0])
            else setDateSelImg({})
            setTimeout(function () {
                setStart(true)
            }, 1500);

        }
    }, [data, loaded]);// eslint-disable-line

    const handleActionImg = (act, data2) => {
        // console.log(`==${act}=========>`, data2)
        let tmp = JSON.parse(JSON.stringify(dataImg))
        let ind = tmp.findIndex(x => x.id === data2.id)
        switch (act) {
            case 'Create':
                tmp.push(data2)
                setDateSelImg(data2)
                break;
            case 'Update':
                if (ind >= 0) {
                    tmp[ind] = data2
                    setDateSelImg(data2)
                }
                break;
            case 'Delete':
                if (ind >= 0) tmp.splice(ind, 1); // начиная с индекса ind, удалить 1 элемент
                if (tmp.length > 0) setDateSelImg(tmp[0])
                else setDateSelImg(null)
                break;
            default:
                break
        }
        tmp = tmp.sort(function (a, b) {
            if (a.sort === b.sort) return 0
            if (a.sort > b.sort) {
                return 1;
            } else return -1;
        });
        setDataImg(tmp)
    }

    return (
        <Box display={'grid'} p={'1em'} width={'100%'}>
            {/*{oneVariant &&
                <h4 style={{marginTop: 0}}>{'Article Variants'}:
                    <b style={{color: '#2196f3', marginLeft: 12}}>{oneVariant}</b>
                </h4>
            }*/}
            {variantData && variantData.length <= 10 &&
                <Box display={'flex'} mb={'1em'} width={'100%'}
                     style={{
                         flexWrap: "wrap",
                         paddingBottom: '1em',
                         borderBottom: '1px solid #ddd'
                     }}
                >
                    <h4 style={{marginTop: 0, marginBottom: 0}}>{'Variants'}: </h4>
                    {variantData.map((it, ind) => {
                        return (
                            <span
                                onClick={(e) => {
                                    setVariantSelData(variantData[ind])
                                    localStorage.setItem('selectedVariantIdForProducts', it.id)
                                }}
                                key={ind}
                                style={{
                                    cursor: "pointer",
                                    fontWeight: it.id === variantSelData.id && 'bold',
                                    color: '#2196f3',
                                    marginLeft: 16,
                                    //maxHeight: 24
                                }}
                            >
                                    {it.article}
                                </span>
                        )
                    })
                    }
                </Box>
            }
            <MyUpdateImg2
                handleActionImg={handleActionImg}
                resourceImg={resourceImg}
                targetImg={targetImg}
                countImg={dataImg.length}
                variantId={variantSelData.id}
                dateSelImg={dateSelImg}
                setDateSelImg={setDateSelImg}
            />

            <Box display={'flex'} mt={'1em'} style={{flexWrap: "wrap"}}>
                <AddToPhotosIcon
                    style={{fontSize: '60px', color: 'gray', cursor: "pointer", marginRight: 8}}
                    onClick={(e) => {
                        setDateSelImg({})
                        document.getElementById('fileselect2').click()
                    }}
                />
                {dataImg.length > 0 && dataImg.map((it, ind) => {
                    return (
                        <div key={ind}
                             style={{
                                 maxHeight: 54,
                                 padding: 2,
                                 margin: 4,
                                 borderRadius: 2,
                                 border: it.id === dateSelImg?.id && '1px solid #2196f3',
                             }}
                        >
                            <img
                                onClick={(e) => {
                                    setDateSelImg(it)
                                }}
                                src={it.image}
                                alt={it.image}
                                height={50}
                                style={{
                                    cursor: "pointer",
                                    // borderBottom: it.is_main && '3px solid blue',
                                    borderBottom: it.is_main && '3px solid #2196f3',
                                    // margin: 2,
                                    borderRadius: 2
                                }}
                            />
                        </div>
                    )
                })
                }
            </Box>
        </Box>
    )
}
