import React from 'react';
import { getMutationMode, useTranslate } from 'react-admin';
import {Tooltip, IconButton} from '@material-ui/core';
import ContentSave from '@material-ui/icons/Save';

export const SaveRowButtonCustom = ({
    dirty,
    handleSubmit,
    invalid,
    mutationMode,
    quitEditMode,
    saving,
    undoable,
}) => {
    const translate = useTranslate();
    const onClick = (evt) => {
        evt.stopPropagation();
        evt.preventDefault();

        if (dirty && invalid) {
            return;
        }

        handleSubmit();
        const mode = getMutationMode(mutationMode, undoable);

        if (mode === 'undoable') {
            quitEditMode();
        }
    };

    const label = translate('ra.action.save', {
        _: 'ra.action.save',
    });
    // const label = 'Save'

    return (
        <Tooltip title={label}>
            <IconButton
                aria-label={label}
                disabled={saving}
                onClick={onClick}
                // size="small"
                color="primary"
            >
                <ContentSave style={{fontSize: 48}}/>
            </IconButton>
            {/*<Button
                variant="contained"
                disabled={saving}
                onClick={onClick}
                color="primary"
                startIcon={<ContentSave/>}
            >
                Save
            </Button>*/}
        </Tooltip>
    );
};

