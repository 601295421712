import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    //AutocompleteInput,
    //ReferenceInput,
    NumberField, useTranslate, useLocale,
    ReferenceInput, AutocompleteInput, ReferenceField,
    TextInput,
    //TextInput,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp} from "../../_common/CompReact";

import {useSelectedColumns} from "@react-admin/ra-preferences";
//import {validateReq} from "../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
import {useMemo} from "react";
// import {useForm} from "react-final-form";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;

    return data;
}
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {product_id} = props;
    const translate = useTranslate();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    product: product_id,
                    source2: localStorage.getItem('username'),
                }}
                transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>,

                <ReferenceInput
                    label={translate('Variant')}
                    source={'variant'}
                    reference={'dVariantProduct'}
                    filter={{product: product_id}}
                    //validate={validateReq}
                >
                    <AutocompleteInput optionText={`${'article'}`} resettable fullWidth/>
                </ReferenceInput>
                <ReferenceInput
                    label="Size Products"
                    reference="dSizeProduct"
                    source={'size'}
                    filter={{product: product_id}}
                    //validate={validateReq}
                >
                    <AutocompleteInput
                        optionText={useMemo(() => {
                            return ((record) => `${record?.additional.size_specification.value}`)
                        }, [])}
                        resettable fullWidth
                    />
                </ReferenceInput>

                {/*<NumberInput source="size" label={translate('Size')} fullWidth/>,*/}
                <TextInput source="barcode" label={translate('Barcode')} fullWidth/>
            </RowForm>
        </>
    )
};

const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,

        variant: /*<NumberField source="variant" label={translate('Variant')}/>,*/
            <ReferenceField source="variant" reference="dVariantProduct"
                            label={translate('Variant')} link={false}
            >
                <TextField source={`${'article'}`}/>
            </ReferenceField>,

        size:
            <ReferenceField source="size" reference="dSizeProduct"
                            label={translate('Size')} link={false}
            >
                <TextField source={`${'additional.size_specification.value'}`}/>
                {/*<ReferenceField source="size_specification" reference="dSizeSpecification"
                                label={translate('Variant')} link={false}
                >
                    <TextField source={`${'value'}`}/>
                </ReferenceField>*/}
            </ReferenceField>,

        /*size: <NumberField source="size" label={translate('Size')}/>,*/
        barcode: <TextField source="barcode" label={translate('Barcode')}/>,

        source: <TextField source="source" label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                                cellClassName={classes.date_st} showTime={true}/>,

    }
    return columns;
};

const Characteristic = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const {product_id, source} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'dCharacteristicProduct'}list.columns`,
        columns: ColumnsForList('dCharacteristicProduct', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Characteristic Product"
                reference="dCharacteristicProduct"
                target="product"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                              source={source} product_id={product_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            source={source} product_id={product_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default Characteristic;