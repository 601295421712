import React, {useEffect, useMemo} from 'react';
import Box from '@material-ui/core/Box';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {sortBy} from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import {useNotify,} from 'ra-core';
import InputLabel from '@material-ui/core/InputLabel';
import {Checkbox, FormControl, FormControlLabel} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {
    createDataEditWorkingHours,
    default_result,
    deleteDataEditWorkingHours,
    editDataEditWorkingHours,
    getData,
    getDataEditWorkingHours,
    updateStoragePriority
} from './Data';
import DeleteIcon from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import DialogContentText from '@material-ui/core/DialogContentText';
import {MD5} from 'object-hash';
import MaskedInput from 'react-text-mask';
// import PropTypes from 'prop-types';
// import Input from '@material-ui/core/Input';
// import Input from "@material-ui/core/Input";

export const SelectStorage = (props) => {

    const {
        result,
        setResult,
        setSelectStorage
    } = props;


    const storages = useMemo(() => sortBy(result.storages_all, [function (o) {
        return o.name.toLowerCase();
    }]), [result]);

    const [storage, setStorage] = React.useState('');

    const handleChange = async (event) => {
        setStorage(event.target.value);
        setSelectStorage(event.target.value);
        await getData(setResult, event.target.value);
    };


    const useStyles = makeStyles((theme) => ({
        formControl: {
            margin: theme.spacing(1),
            minWidth: '50%',
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="demo-select-small-label">Склад</InputLabel>
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={storage}
                // label="Склад"
                onChange={handleChange}
                variant='filled'
            >
                <MenuItem value="">
                    <em>None</em>
                </MenuItem>
                {
                    result && storages.map((item, index) => {
                        return (
                            <MenuItem key={item.id}
                                      value={item.id}
                            >
                                {item.name}
                            </MenuItem>
                        );
                    })
                }
            </Select>
        </FormControl>
    );
};

export const StoragePriorityEditDialog = (props) => {
    const {openStoragePriorityEditDialog, setStoragePriorityEditDialog, setResult, selectStorage} = props;

    const handleSave = async () => {
        console.log(openStoragePriorityEditDialog);
        if (openStoragePriorityEditDialog) {
            await updateStoragePriority(
                openStoragePriorityEditDialog.storage_id,
                openStoragePriorityEditDialog.priority,
                openStoragePriorityEditDialog.show_mode
            );
        }

        await getData(setResult, selectStorage);

        setStoragePriorityEditDialog(false);
    };


    const handleClose = async () => {
        setStoragePriorityEditDialog(false);

    };


    const handleChangeData = (event) => {

        setStoragePriorityEditDialog({
            ...openStoragePriorityEditDialog,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        });

    };

    return (
        <div>
            <Dialog
                open={openStoragePriorityEditDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>{'Настройка склада'}: {openStoragePriorityEditDialog ? openStoragePriorityEditDialog.warehouse_name : ''}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <TextField
                            autoComplete={'off'}
                            defaultValue={openStoragePriorityEditDialog ? openStoragePriorityEditDialog.priority : 0}
                            onChange={handleChangeData}
                            name="priority"
                            label={'Приоритет'}
                            style={{width: '100%'}}
                            variant='filled'
                            size={'small'}
                        ></TextField>

                        <br></br>
                        <br></br>

                        <InputLabel id="demo-select-small-label2">Режим отображения в поиске</InputLabel>
                        <Select defaultValue={openStoragePriorityEditDialog ? openStoragePriorityEditDialog.show_mode : ''}
                                autoComplete={'off'}
                                onChange={handleChangeData}
                                name={'show_mode'}
                                labelId={'demo-select-small-label2'}
                                style={{width: '100%'}}
                                variant='filled'
                                size={'small'}
                        >

                            <MenuItem key={1}
                                      value={'ALL'}
                            >
                                Видят все
                            </MenuItem>
                            <MenuItem key={2}
                                      value={'ADMIN_AND_ANALYST'}
                            >
                                Видят Админ, Аналитики
                            </MenuItem>
                            <MenuItem key={3}
                                      value={'ADMIN_AND_MANAGER_AND_ANALYST'}
                            >
                                Видят Админ, Менеджеры, Аналитики
                            </MenuItem>
                            <MenuItem key={2}
                                      value={'NOONE'}
                            >
                                Не видит никто в поиске
                            </MenuItem>
                        </Select>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}
                            variant='contained'
                    >
                        Отмена
                    </Button>
                    <Button onClick={handleSave}
                            color="primary"
                            autoFocus
                            variant='contained'
                    >
                        Сохранить
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export const WorkingHoursEditDialog = (props) => {

    const notify = useNotify();

    const {resultWorkingHoursEdit, setResultWorkingHoursEdit, setOkWorkingHoursEdit} = props;

    const [formWorkingHoursEdit, setFormWorkingHoursEdit] = React.useState(null);

    const handleClose = () => {
        setResultWorkingHoursEdit(null);

    };

    const handleSendData = () => {
        if (formWorkingHoursEdit.id) {
            editDataEditWorkingHours(setOkWorkingHoursEdit, formWorkingHoursEdit).then(function () {
                setResultWorkingHoursEdit(null);

            }).catch(function (err) {
                notify(`Ошибка ${JSON.stringify(err.body)}`, {type: 'error', autoHideDuration: 60000});

            });

        } else {
            createDataEditWorkingHours(setOkWorkingHoursEdit, formWorkingHoursEdit).then(function () {
                setResultWorkingHoursEdit(null);

            }).catch(function (err) {
                notify(`Ошибка ${JSON.stringify(err.body)}`, {type: 'error', autoHideDuration: 60000});

            });
        }

    };


    const handleChangeData = (event) => {
        setFormWorkingHoursEdit({
            ...formWorkingHoursEdit,
            [event.target.name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value
        });

    };

    useEffect(() => {
        if (resultWorkingHoursEdit) {
            setFormWorkingHoursEdit({
                wd_monday_hour: resultWorkingHoursEdit.wd_monday_hour,
                wd_tuesday_hour: resultWorkingHoursEdit.wd_tuesday_hour,
                wd_wednesday_hour: resultWorkingHoursEdit.wd_wednesday_hour,
                wd_thursday_hour: resultWorkingHoursEdit.wd_thursday_hour,
                wd_friday_hour: resultWorkingHoursEdit.wd_friday_hour,
                wd_saturady_hour: resultWorkingHoursEdit.wd_saturady_hour,
                wd_sunday_hour: resultWorkingHoursEdit.wd_sunday_hour,

                wd_monday: resultWorkingHoursEdit.wd_monday,
                wd_tuesday: resultWorkingHoursEdit.wd_tuesday,
                wd_wednesday: resultWorkingHoursEdit.wd_wednesday,
                wd_thursday: resultWorkingHoursEdit.wd_thursday,
                wd_friday: resultWorkingHoursEdit.wd_friday,
                wd_saturady: resultWorkingHoursEdit.wd_saturady,
                wd_sunday: resultWorkingHoursEdit.wd_sunday,

                wd_monday_storage_start_work_time: resultWorkingHoursEdit.wd_monday_storage_start_work_time,
                wd_tuesday_storage_start_work_time: resultWorkingHoursEdit.wd_tuesday_storage_start_work_time,
                wd_wednesday_storage_start_work_time: resultWorkingHoursEdit.wd_wednesday_storage_start_work_time,
                wd_thursday_storage_start_work_time: resultWorkingHoursEdit.wd_thursday_storage_start_work_time,
                wd_friday_storage_start_work_time: resultWorkingHoursEdit.wd_friday_storage_start_work_time,
                wd_saturady_storage_start_work_time: resultWorkingHoursEdit.wd_saturady_storage_start_work_time,
                wd_sunday_storage_start_work_time: resultWorkingHoursEdit.wd_sunday_storage_start_work_time,

                wd_monday_warehouse_acceptance_delivery_hours: resultWorkingHoursEdit.wd_monday_warehouse_acceptance_delivery_hours,
                wd_tuesday_warehouse_acceptance_delivery_hours: resultWorkingHoursEdit.wd_tuesday_warehouse_acceptance_delivery_hours,
                wd_wednesday_warehouse_acceptance_delivery_hours: resultWorkingHoursEdit.wd_wednesday_warehouse_acceptance_delivery_hours,
                wd_thursday_warehouse_acceptance_delivery_hours: resultWorkingHoursEdit.wd_thursday_warehouse_acceptance_delivery_hours,
                wd_friday_warehouse_acceptance_delivery_hours: resultWorkingHoursEdit.wd_friday_warehouse_acceptance_delivery_hours,
                wd_saturady_warehouse_acceptance_delivery_hours: resultWorkingHoursEdit.wd_saturady_warehouse_acceptance_delivery_hours,
                wd_sunday_warehouse_acceptance_delivery_hours: resultWorkingHoursEdit.wd_sunday_warehouse_acceptance_delivery_hours,

                id: resultWorkingHoursEdit.id ? resultWorkingHoursEdit.id : null,
                delivery_time_calculated_from_begin_warehouse_operation: resultWorkingHoursEdit.delivery_time_calculated_from_begin_warehouse_operation,
                supplier_warehouse_id: resultWorkingHoursEdit.supplier_warehouse_id,
                supplier_warehouse: resultWorkingHoursEdit.supplier_warehouse_id,
                // supplier_warehouse_name: resultWorkingHoursEdit.supplier_warehouse_name,
                warehouse_acceptance_id: resultWorkingHoursEdit.warehouse_acceptance_id,
                warehouse_acceptance: resultWorkingHoursEdit.warehouse_acceptance_id,
                // warehouse_acceptance_name: resultWorkingHoursEdit.warehouse_acceptance_name,
            });
        }

    }, [resultWorkingHoursEdit]);


    const input_time_mask = [/[0-2]/, /\d/, ':', /[0-5]/, /\d/];

    function show_mask(value) {
        if (value === '00:00') {
            return value;
        } else {
            return value;
        }
    }


    const default_value_mask_edit = '00:00';


    function handleMaskEditClick(event) {
        const value = event.target.value;
        if (value === '00:00') {
            event.target.value = '';
        }

        // console.log(event);
    }


    function handleMaskEditOnBlur(event) {
        if (event.target.value === '') {
            event.target.value = default_value_mask_edit;
        }

    }


    const input_time_mask_style = {
        width: '100%',
        backgroundColor: 'lightgray',
        borderStyle: 'none',
        border: '0',
        fontSize: '1.2em',
        height: '1.4em',
        // borderBottom: '',
        borderColor: 'lightgray'
    };

    return (
        <React.Fragment>
            <Dialog
                fullScreen
                open={resultWorkingHoursEdit}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {'Редактор расписания'} <br/>

                    <dev style={{fontWeight: 'bold'}}>
                        {resultWorkingHoursEdit ? resultWorkingHoursEdit.supplier_warehouse__name : ''} - {resultWorkingHoursEdit ? resultWorkingHoursEdit.warehouse_acceptance__name : ''}
                    </dev>

                </DialogTitle>

                {
                    formWorkingHoursEdit
                        ?
                        <DialogContent>
                            <FormControlLabel label="Время доставки рассчитывать с момента начала работы склада"
                                              control={
                                                  <Checkbox defaultChecked={formWorkingHoursEdit.delivery_time_calculated_from_begin_warehouse_operation}
                                                            size='small'
                                                            onChange={handleChangeData}
                                                            name={'delivery_time_calculated_from_begin_warehouse_operation'}
                                                  />
                                              }
                            />

                            <TableContainer>
                                <Table size={'small'}
                                       stickyHeader={true}
                                       key={'500000000000000000000000000000'}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{border: 'none'}}></TableCell>
                                            <TableCell style={{border: 'none'}}>Начало работы склада</TableCell>
                                            <TableCell style={{border: 'none'}}>Окончание приема заказа</TableCell>
                                            <TableCell style={{border: 'none'}}>Время доставки между складами в часах</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow hover={true}>
                                            <TableCell style={{border: 'none'}}>
                                                <FormControlLabel label="Понедельник"
                                                                  control={
                                                                      <Checkbox defaultChecked={formWorkingHoursEdit.wd_monday}
                                                                                size='small'
                                                                                onChange={handleChangeData}
                                                                                name={'wd_monday'}
                                                                      />
                                                                  }
                                                />
                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>
                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_monday_storage_start_work_time)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_monday_storage_start_work_time'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />

                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>
                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_monday_hour)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_monday_hour'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />

                                            </TableCell>


                                            <TableCell style={{border: 'none'}}>

                                                <input
                                                    value={formWorkingHoursEdit.wd_monday_warehouse_acceptance_delivery_hours}
                                                    onChange={handleChangeData}
                                                    name={'wd_monday_warehouse_acceptance_delivery_hours'}
                                                    style={input_time_mask_style}
                                                    defaultValue={'0'}
                                                    type={'number'}
                                                />


                                            </TableCell>

                                        </TableRow>

                                        <TableRow hover={true}>
                                            <TableCell style={{border: 'none'}}>
                                                <FormControlLabel label="Вторник"
                                                                  control={
                                                                      <Checkbox defaultChecked={formWorkingHoursEdit.wd_tuesday}
                                                                                size='small'
                                                                                onChange={handleChangeData}
                                                                                name={'wd_tuesday'}
                                                                      />
                                                                  }
                                                />
                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>
                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_tuesday_storage_start_work_time)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_tuesday_storage_start_work_time'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />


                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>

                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_tuesday_hour)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_tuesday_hour'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />

                                            </TableCell>


                                            <TableCell style={{border: 'none'}}>

                                                <input
                                                    value={formWorkingHoursEdit.wd_tuesday_warehouse_acceptance_delivery_hours}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_tuesday_warehouse_acceptance_delivery_hours'}
                                                    style={input_time_mask_style}
                                                    defaultValue={'0'}
                                                    type={'number'}
                                                />


                                            </TableCell>


                                        </TableRow>

                                        <TableRow hover={true}>
                                            <TableCell style={{border: 'none'}}>
                                                <FormControlLabel label="Среда"
                                                                  control={
                                                                      <Checkbox defaultChecked={formWorkingHoursEdit.wd_wednesday}
                                                                                size='small'
                                                                                onChange={handleChangeData}
                                                                                name={'wd_wednesday'}
                                                                          // value={formWorkingHoursEdit.wd_wednesday}
                                                                      />
                                                                  }
                                                />
                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>
                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_wednesday_storage_start_work_time)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_wednesday_storage_start_work_time'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />


                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>

                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_wednesday_hour)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_wednesday_hour'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />

                                            </TableCell>


                                            <TableCell style={{border: 'none'}}>

                                                <input
                                                    value={formWorkingHoursEdit.wd_wednesday_warehouse_acceptance_delivery_hours}
                                                    onChange={handleChangeData}
                                                    name={'wd_wednesday_warehouse_acceptance_delivery_hours'}
                                                    style={input_time_mask_style}
                                                    defaultValue={'0'}
                                                    type={'number'}
                                                />

                                            </TableCell>


                                        </TableRow>

                                        <TableRow hover={true}>
                                            <TableCell style={{border: 'none'}}>
                                                <FormControlLabel label="Четверг"
                                                                  control={
                                                                      <Checkbox defaultChecked={formWorkingHoursEdit.wd_thursday}
                                                                                size='small'
                                                                                onChange={handleChangeData}
                                                                                name={'wd_thursday'}
                                                                      />
                                                                  }
                                                />
                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>
                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    value={show_mask(formWorkingHoursEdit.wd_thursday_storage_start_work_time)}
                                                    onChange={handleChangeData}
                                                    name={'wd_thursday_storage_start_work_time'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />


                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>

                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_thursday_hour)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_thursday_hour'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />

                                            </TableCell>


                                            <TableCell style={{border: 'none'}}>

                                                <input
                                                    value={formWorkingHoursEdit.wd_thursday_warehouse_acceptance_delivery_hours}
                                                    onChange={handleChangeData}
                                                    name={'wd_thursday_warehouse_acceptance_delivery_hours'}
                                                    style={input_time_mask_style}
                                                    defaultValue={'0'}
                                                    type={'number'}
                                                />


                                            </TableCell>


                                        </TableRow>

                                        <TableRow hover={true}>
                                            <TableCell style={{border: 'none'}}>
                                                <FormControlLabel label="Пятница"
                                                                  control={
                                                                      <Checkbox defaultChecked={formWorkingHoursEdit.wd_friday}
                                                                                size='small'
                                                                                onChange={handleChangeData}
                                                                                name={'wd_friday'}
                                                                          // value={formWorkingHoursEdit.wd_friday}
                                                                      />
                                                                  }
                                                />
                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>

                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_friday_storage_start_work_time)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_friday_storage_start_work_time'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />


                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>

                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_friday_hour)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_friday_hour'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />

                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>

                                                <input
                                                    value={formWorkingHoursEdit.wd_friday_warehouse_acceptance_delivery_hours}
                                                    onChange={handleChangeData}
                                                    name={'wd_friday_warehouse_acceptance_delivery_hours'}
                                                    style={input_time_mask_style}
                                                    defaultValue={'0'}
                                                    type={'number'}
                                                />


                                            </TableCell>

                                        </TableRow>

                                        <TableRow hover={true}>
                                            <TableCell style={{border: 'none'}}>
                                                <FormControlLabel label="Суббота"
                                                                  control={
                                                                      <Checkbox defaultChecked={formWorkingHoursEdit.wd_saturady}
                                                                                size='small'
                                                                                onChange={handleChangeData}
                                                                                name={'wd_saturady'}
                                                                      />
                                                                  }
                                                />
                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>

                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_saturady_storage_start_work_time)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_saturady_storage_start_work_time'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />


                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>

                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_saturady_hour)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_saturady_hour'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />

                                            </TableCell>


                                            <TableCell style={{border: 'none'}}>

                                                <input
                                                    value={formWorkingHoursEdit.wd_saturady_warehouse_acceptance_delivery_hours}
                                                    onChange={handleChangeData}
                                                    name={'wd_saturady_warehouse_acceptance_delivery_hours'}
                                                    style={input_time_mask_style}
                                                    defaultValue={'0'}
                                                    type={'number'}
                                                />


                                            </TableCell>


                                        </TableRow>

                                        <TableRow hover={true}>
                                            <TableCell style={{border: 'none'}}>
                                                <FormControlLabel label="Воскресенье"
                                                                  control={
                                                                      <Checkbox defaultChecked={formWorkingHoursEdit.wd_sunday}
                                                                                size='small'
                                                                                onChange={handleChangeData}
                                                                                name={'wd_sunday'}
                                                                      />
                                                                  }
                                                />
                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>

                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_sunday_storage_start_work_time)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_sunday_storage_start_work_time'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />


                                            </TableCell>

                                            <TableCell style={{border: 'none'}}>

                                                <MaskedInput
                                                    mask={input_time_mask}
                                                    value={show_mask(formWorkingHoursEdit.wd_sunday_hour)}
                                                    onChange={handleChangeData}
                                                    onClick={handleMaskEditClick}
                                                    onBlur={handleMaskEditOnBlur}
                                                    name={'wd_sunday_hour'}
                                                    style={input_time_mask_style}
                                                    defaultValue={default_value_mask_edit}
                                                    guide={true}
                                                    showMask={true}
                                                    type={'text'}
                                                />

                                            </TableCell>


                                            <TableCell style={{border: 'none'}}>

                                                <input
                                                    value={formWorkingHoursEdit.wd_sunday_warehouse_acceptance_delivery_hours}
                                                    onChange={handleChangeData}
                                                    name={'wd_sunday_warehouse_acceptance_delivery_hours'}
                                                    style={input_time_mask_style}
                                                    defaultValue={'0'}
                                                    type={'number'}
                                                />


                                            </TableCell>


                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <DialogActions>
                                <Button onClick={handleClose}
                                        variant='contained'
                                >Отмена</Button>

                                <Button onClick={handleSendData}
                                        color='primary'
                                        autoFocus
                                        variant='contained'
                                >
                                    Сохранить
                                </Button>
                            </DialogActions>
                        </DialogContent>
                        : null
                }

            </Dialog>
        </React.Fragment>
    );
};


export const SheduleForm = (props) => {
    const [result, setResult] = React.useState(default_result);
    const [resultWorkingHoursEdit, setResultWorkingHoursEdit] = React.useState(null);
    const [okWorkingHoursEdit, setOkWorkingHoursEdit] = React.useState(null);
    const [openStoragePriorityEditDialog, setStoragePriorityEditDialog] = React.useState(null);
    const [selectStorage, setSelectStorage] = React.useState(null);

    const handleClickShowData = async () => {
        await getData(setResult, selectStorage);
    };


    const handleClickEditWorkingHours = async (data) => {
        if (data) {
            await getDataEditWorkingHours(setResultWorkingHoursEdit, data.working_hours_table_id);
        } else {
            setResultWorkingHoursEdit(true);
        }
    };


    const handleClickCreateWorkingHours = (item) => {

        setResultWorkingHoursEdit(item);

    };


    async function getGrid() {
        if (okWorkingHoursEdit) {
            return handleClickShowData(setResult);
        }
    }


    const handleClickDeleteSheduleCell = async (item_id) => {
        if (confirm('Вы уверены ?')) {
            await deleteDataEditWorkingHours(item_id);
            await getGrid();
        }

    };


    const handleStoragePriorityEditDialog = async (data) => {
        setStoragePriorityEditDialog(data);


    };

    useEffect(() => {
        getGrid().then(() => {
        });

    }, [okWorkingHoursEdit]); // eslint-disable-line


    useEffect(() => {
        handleClickShowData();
    }, []); // eslint-disable-line


    function get_text_show_mode(show_mode) {
        if (show_mode === 'ALL') {
            return 'Видят все';
        } else if (show_mode === 'ADMIN_AND_ANALYST') {
            return 'Видят Админ, Аналитики';
        } else if (show_mode === 'ADMIN_AND_MANAGER_AND_ANALYST') {
            return 'Видят Админ, Менеджеры, Аналитики';
        } else if (show_mode === 'NOONE') {
            return 'Не видит никто в поиске';
        } else {
            return 'Не определено';
        }


    }

    return (
        <>
            <WorkingHoursEditDialog resultWorkingHoursEdit={resultWorkingHoursEdit}
                                    setResultWorkingHoursEdit={setResultWorkingHoursEdit}
                                    setOkWorkingHoursEdit={setOkWorkingHoursEdit}
            />

            <StoragePriorityEditDialog
                setStoragePriorityEditDialog={setStoragePriorityEditDialog}
                openStoragePriorityEditDialog={openStoragePriorityEditDialog}
                setResult={setResult}
                selectStorage={selectStorage}
            ></StoragePriorityEditDialog>

            <Box style={{margin: 5}}>
                <FormControl style={{width: '50%'}}
                             size="small"
                >
                    <SelectStorage result={result}
                                   setResult={setResult}
                                   setSelectStorage={setSelectStorage}
                        // style={{minWidth: '200px'}}
                    ></SelectStorage>
                    {/*<Button variant="contained"*/}
                    {/*        onClick={async () => {*/}
                    {/*            await handleClickShowData(setResult);*/}
                    {/*        }}*/}
                    {/*>Получить*/}
                    {/*</Button>*/}

                </FormControl>

            </Box>

            <Box
                // style={{overflowY: 'scroll', overflowX: 'auto', maxHeight: '60vh'}}

            >
                <TableContainer style={{maxHeight: '75vh'}}
                                key={'300000000000000000000000'}
                >
                    <Table aria-label="spanning table"
                           key={'10000000000000000000000000'}
                    >
                        <TableBody key={'20000000000000000000000000000000'}>
                            {
                                result && result.grid.map((item, index) => {

                                        return (
                                            <>
                                                <TableRow key={MD5([item.working_hours_table_id, 'asasdascvxcrtfty', item.id])}>
                                                    {
                                                        item.row.map((item, index) => {
                                                            if (item.working_hours.length > 0) {
                                                                return (
                                                                    <TableCell key={MD5([item.working_hours_table_id, 'ckerdvasdvredffcv', index])}
                                                                               align={'left'}
                                                                               style={{
                                                                                   border: '1px solid black',
                                                                                   minWidth: '250px',
                                                                                   verticalAlign: 'top',
                                                                                   fontFamily: 'monospace'
                                                                               }}

                                                                               onClick={async (event) => {
                                                                                   await handleClickEditWorkingHours(item);
                                                                               }}
                                                                    >
                                                                        <div style={{display: 'flex'}}

                                                                        >
                                                                            <div style={{flex: 5}}>
                                                                                <div style={{
                                                                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                                                                    fontSize: '1.2em',
                                                                                    fontWeight: 'bold'
                                                                                }}
                                                                                >
                                                                                    {item.supplier_warehouse__name}
                                                                                </div>
                                                                                <div>
                                                                                    <div style={{display: 'flex', alignItems: 'center'}}>

                                                                                        Приоритет: <div style={{fontWeight: 'bolder'}}>{item.supplier_warehouse_priority_selection}</div>
                                                                                        <SettingsIcon cursor={'pointer'}
                                                                                                      onClick={async (event) => {
                                                                                                          event.stopPropagation();
                                                                                                          await handleStoragePriorityEditDialog({
                                                                                                                  storage_id: item.supplier_warehouse_id,
                                                                                                                  priority: item.supplier_warehouse_priority_selection,
                                                                                                                  warehouse_name: item.supplier_warehouse__name,
                                                                                                                  show_mode: item.supplier_warehouse__show_mode,
                                                                                                              }
                                                                                                          );
                                                                                                      }}
                                                                                                      style={{marginLeft: '0.5em'}}
                                                                                        ></SettingsIcon>
                                                                                    </div>
                                                                                    {/*<br></br>*/}
                                                                                    <div style={item.supplier_warehouse__show_mode === 'NOONE' ? {backgroundColor: 'lightcoral'} : {}}>
                                                                                        {get_text_show_mode(item.supplier_warehouse__show_mode)}
                                                                                    </div>

                                                                                </div>

                                                                                <br></br>
                                                                                <div style={{
                                                                                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                                                                    fontSize: '1.2em',
                                                                                    fontWeight: 'bold'
                                                                                }}
                                                                                >
                                                                                    {item.warehouse_acceptance__name}
                                                                                </div>

                                                                                {/*<div>*/}
                                                                                {/*    /!*<SettingsIcon cursor={'pointer'}*!/*/}
                                                                                {/*    /!*              onClick={async (event) => {*!/*/}
                                                                                {/*    /!*                  event.stopPropagation();*!/*/}
                                                                                {/*    /!*                  await handleStoragePriorityEditDialog({*!/*/}
                                                                                {/*    /!*                          storage_id: item.warehouse_acceptance_id,*!/*/}
                                                                                {/*    /!*                          priority: item.warehouse_acceptance_priority_selection,*!/*/}
                                                                                {/*    /!*                          warehouse_name: item.warehouse_acceptance_name,*!/*/}
                                                                                {/*    /!*                      }*!/*/}
                                                                                {/*    /!*                  );*!/*/}
                                                                                {/*    /!*              }}*!/*/}

                                                                                {/*    /!*></SettingsIcon>*!/*/}
                                                                                {/*    [Приоритет: {item.warehouse_acceptance_priority_selection}]*/}
                                                                                {/*</div>*/}

                                                                            </div>
                                                                            <div style={{flex: 1, textAlign: 'right'}}>
                                                                                <DeleteIcon style={{
                                                                                    cursor: 'pointer'
                                                                                }}
                                                                                            onClick={async (event) => {
                                                                                                event.stopPropagation();
                                                                                                await handleClickDeleteSheduleCell(item.working_hours_table_id);
                                                                                            }}


                                                                                ></DeleteIcon>
                                                                            </div>
                                                                        </div>


                                                                        <div>

                                                                            <br/>
                                                                            {/*<br/>*/}
                                                                            <div>
                                                                                С начала работы: {item.delivery_time_calculated_from_begin_warehouse_operation ? 'Да' : 'Нет'}
                                                                            </div>
                                                                            <br/>
                                                                        </div>

                                                                        {
                                                                            item.working_hours.map((item, index) => {
                                                                                    if (item.enabled) {
                                                                                        return (
                                                                                            <div key={index}
                                                                                            >
                                                                                                {item.name} | {item.storage_start_work_time} | {item.hour} | {item.warehouse_acceptance_delivery_hours}ч.
                                                                                            </div>

                                                                                        );
                                                                                    } else {
                                                                                        return (
                                                                                            <div key={index}
                                                                                                 style={{backgroundColor: 'lightcoral'}}
                                                                                            >
                                                                                                {item.name} | {item.storage_start_work_time} | {item.hour} | {item.warehouse_acceptance_delivery_hours}ч.
                                                                                            </div>

                                                                                        );
                                                                                    }
                                                                                }
                                                                            )
                                                                        }
                                                                    </TableCell>
                                                                );
                                                            } else {
                                                                return (
                                                                    <TableCell key={MD5([item.working_hours_table_id, 'xcehdvsgvffd', index])}
                                                                               align={'left'}
                                                                               style={{
                                                                                   border: '1px solid black',
                                                                                   backgroundColor: 'lightgray',
                                                                                   verticalAlign: 'top',
                                                                                   minWidth: '250px',
                                                                                   fontFamily: 'monospace'
                                                                               }}
                                                                               onClick={async (event) => {
                                                                                   await handleClickCreateWorkingHours(item);
                                                                               }}
                                                                    >
                                                                        <div>
                                                                            {/*<div>{item.working_hours_table_id}</div>*/}
                                                                            <div style={{
                                                                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                                                                fontSize: '1.2em',
                                                                                fontWeight: 'bold'
                                                                            }}
                                                                            >{item.supplier_warehouse__name}
                                                                            </div>
                                                                            <div>
                                                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                                                    Приоритет: <div style={{fontWeight: 'bolder'}}>{item.supplier_warehouse_priority_selection}</div>
                                                                                    <SettingsIcon cursor={'pointer'}
                                                                                                  onClick={async (event) => {
                                                                                                      event.stopPropagation();
                                                                                                      await handleStoragePriorityEditDialog({
                                                                                                              storage_id: item.supplier_warehouse_id,
                                                                                                              priority: item.supplier_warehouse_priority_selection,
                                                                                                              warehouse_name: item.supplier_warehouse__name,
                                                                                                              show_mode: item.supplier_warehouse__show_mode,
                                                                                                          }
                                                                                                      );

                                                                                                  }}
                                                                                                  style={{marginLeft: '0.5em'}}
                                                                                    ></SettingsIcon>
                                                                                </div>

                                                                                <div style={item.supplier_warehouse__show_mode === 'NOONE' ? {backgroundColor: 'lightcoral'} : {}}>
                                                                                    {get_text_show_mode(item.supplier_warehouse__show_mode)}
                                                                                </div>

                                                                            </div>

                                                                            <br></br>
                                                                            <div style={{
                                                                                fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                                                                                fontSize: '1.2em',
                                                                                fontWeight: 'bold'
                                                                            }}
                                                                            >
                                                                                {item.warehouse_acceptance__name}
                                                                            </div>

                                                                            {/*<div>[Приоритет: {item.warehouse_acceptance_priority_selection}]</div>*/}
                                                                        </div>

                                                                        <br/>
                                                                        <br/>
                                                                        <br/>
                                                                        <br/>
                                                                        {/*<Divider></Divider>*/}
                                                                        Нет расписания

                                                                    </TableCell>
                                                                );
                                                            }

                                                        })
                                                    }


                                                </TableRow>
                                            </>
                                        )
                                            ;
                                    }
                                )
                            }

                        </TableBody>

                    </Table>
                </TableContainer>
            </Box>
        </>
    );
};
