import PermIdentity from '@material-ui/icons/PermIdentity';
import {CurrenciesList} from "./CurrenciesList";
import {CurrenciesCreate} from "./CurrenciesCreate";
import {CurrenciesEdit} from "./CurrenciesEdit";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: CurrenciesList,
    create: CurrenciesCreate,
    edit: CurrenciesEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;