import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {GiftPoolList} from "./GiftPoolList";
import {GiftPoolEdit} from "./GiftPoolEdit";
import {GiftPoolCreate} from "./GiftPoolCreate";

const resource = {
    list: GiftPoolList,
    create: GiftPoolCreate,
    edit: GiftPoolEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;