import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    useTranslate,
    TextInput,
    AutocompleteInput,
    ReferenceInput,
    useLocale, BooleanInput, SelectInput,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

//import {validateReq} from "../utils/components/validateTable";
import SuppliersWarehouses from "./SuppliersWarehouses";
import {choicesDiscountAlgorithm} from "../../_constants/choices";
// import TranslateIcon from '@material-ui/Translate';
// import GTranslateIcon from '@material-ui/GTranslate';

//import { JsonSchemaForm } from "@react-admin/ra-json-schema-form";


const BlockEdit = props => {
    const {id} = props
    const translate = useTranslate();
    const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('User Country')}
                        source={'user_country'}
                        reference={'dCountries'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`name[${locale}]`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Main Warehouse')}
                        source={'main_warehouse'}
                        reference={'dSuppliersWarehouses'}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`name[${locale}]`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}/>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'name'}
                        label={translate('Name')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'name_abbr'}
                        label={translate('Name Abbr')}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        source={'name_repr'}
                        label={translate('Name Repr')}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'discount'}
                        label={translate('Discount')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <SelectInput
                        source={'discount_algorithm'} choices={choicesDiscountAlgorithm}
                        label={translate('Discount Algorithm')}
                        variant={"outlined"} fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        source={'discount_interval'}
                        label={translate('Discount interval')}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'item_code_in_accounting_system'}
                        label={translate('Item code in accounting system')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source="control_balance_qty" label={translate('Control Balance Qty')}
                                  defaultValue={false}/>
                </Box>
                <Box flex={1}>
                    <BooleanInput source="virtual" label={translate('virtual')} defaultValue={false}/>
                </Box>
            </Box>

            {id &&
                <Box display="grid" alignItems={"top"}>
                    <Box flex={1} mb={"1em"}
                         style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                    >
                        <h4 style={{margin: 0}}>{translate("Suppliers Warehouses")}</h4>
                        <SuppliersWarehouses supplier_id={id}/>
                    </Box>
                </Box>
            }


        </Box>
    )
}
export const SuppliersEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                    />
                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default SuppliersEditCreate
