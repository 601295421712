/*
import {Layout} from 'react-admin';
import MyAppBar from "./MyAppBar";
import {MyMenu} from "./MyMenu";

const MyLayout = props => {

    return (<Layout
        {...props}
        appBar={MyAppBar}
        menu={MyMenu}
    />)
};

export default MyLayout
*/
import {Layout} from 'react-admin';
import MyAppBar from "./MyAppBar";
import {MyMenu} from "./MyMenu";
import {AppLocationContext, Breadcrumb, ResourceBreadcrumbItems} from "@react-admin/ra-navigation";
import {useEffect} from "react";

let timer = 0;
const MyLayout = ({children, ...rest}) => {

    useEffect(() => {
        // if (config.permission === 'zenit' || window.innerWidth > 1200) {
        if (window.innerWidth > 1200) {
            clearInterval(timer)
            timer = setInterval(() => {
                // console.log('====!!!!=========>', window.innerWidth)
                let div = document.getElementById("main-content");
                if (div) div.style.maxWidth = '' + (window.innerWidth - 300) + "px";
            }, 2000);
        }
    }, []);// eslint-disable-line

    return (
        <AppLocationContext>
            <Layout
                {...rest}
                appBar={MyAppBar}
                menu={MyMenu}
            >
                <Breadcrumb hasDashboard>
                    <ResourceBreadcrumbItems/>
                </Breadcrumb>
                {children}
            </Layout>
        </AppLocationContext>
    )
};

export default MyLayout

