import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    useTranslate,
    TextInput, useGetList,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
// import {validateEmail} from "../utils/components/validateTable";
import {useEffect, useState} from "react";
import {getPromptForTable} from "../../_common/CompFormat/utils";

const BlockEdit = props => {
    const translate = useTranslate();
    const {promptData} = props;
    // const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={'2em'}>
                    <MyTranslatableInputs
                        prompt={promptData.find(x => x.field === 'full_name')}
                        fullWidth
                        source={'full_name'}
                        label={translate('Full Name')}
                    />
                </Box>
                <Box flex={1} mr={'2em'}>
                    <TextInput
                        variant={"outlined"}
                        source={'code'}
                        label={translate('Code')}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <MyTranslatableInputs
                        prompt={promptData.find(x => x.field === 'symbolic_notation')}
                        fullWidth
                        source={'symbolic_notation'}
                        label={translate('Symbolic notation')}
                    />
                </Box>
            </Box>
        </Box>
    )
}
export const UnitsOfMeasurementEditCreate = props => {//Не работает

    const translate = useTranslate();
    const {record, resource} = props;
    const {id} = record || {};

    const [promptData, setPromptData] = useState([]);
    const {data: dataPrompts, loading: isLoading} = useGetList(
        "dPrompts",
        {page: 1, perPage: 100},
        {field: 'id', order: 'ASC'},
    );

    useEffect(() => {
        if (dataPrompts) {
            let dt = getPromptForTable(dataPrompts, resource)
            setPromptData(dt)
        }
    }, [dataPrompts, isLoading]);// eslint-disable-line


    return (
        <Box>

            <TabbedForm
                {...props}
                //initialValues={{source: localStorage.getItem('username')}}
                defaultValue={{source: localStorage.getItem('username')}}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        related_id={id}
                        promptData={promptData}
                    />
                </FormTab>
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default UnitsOfMeasurementEditCreate
