import * as React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Typography,
} from "@material-ui/core";

import {
    useNotify,
    useTranslate
} from "react-admin";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {useEffect} from "react";
import validate from "validate.js";
import Button from "@material-ui/core/Button";
import {GetArrayFieldFromResource} from "./Partner";
import {EditCustomers} from "./EditCustomers";

const schema = {
    name: {
        presence: {allowEmpty: false, message: 'is required'},
        // email: true,
        length: {
            minimum: 2,
            maximum: 256,
        },
    },
    base_warehouse_main_supplier: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    how_show_quantity: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    contractor_type: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    business_type: {
        presence: {allowEmpty: false, message: 'is required'},
    },
    /*item_code_in_accounting_system:{
        presence: {allowEmpty: false, message: 'is required'},
    },*/
    /*credit_limit_currency: {
        presence: {allowEmpty: false, message: 'is required'},
    },*/
    /*official_name: {
        presence: {allowEmpty: false, message: 'is required'},
        // email: true,
        length: {
            minimum: 2,
            maximum: 256,
        },
    },*/
    /*client_code: {
        presence: {allowEmpty: false, message: 'is required'},
        // email: true,
        length: {
            minimum: 2,
            maximum: 256,
        },
    },*/
    /*address: {
        presence: {allowEmpty: false, message: 'is required'},
        // email: true,
        length: {
            minimum: 2,
            maximum: 256,
        },
    },*/

    user_email: {
        presence: {allowEmpty: false},
        email: true,
        length: {
            minimum: 6,
            maximum: 128,
        },
    },

};

let pause = false
export const Customer = props => {

    const {
        // handleChangeSel,
        // handleChangeText,
        form,
        setForm,
        iniCustomers,
        pricingProperty,
        iniPricingProperty,
    } = props


    const translate = useTranslate();
    const notify = useNotify();

    useEffect(() => {
        if (!pause) {
            pause = true
            setTimeout(function () {
                pause = false
            }, 1);
            let customerAll = JSON.parse(JSON.stringify(form.customers))
            Object.entries(customerAll).map((item, ind) => {
                // let index = item[0]
                item[1].error = validate(item[1].values, schema);
                if (item[1].values.user_id) {
                    if(item[1].error && item[1].error?.user_email)
                            delete item[1].error.user_email
                }
                item[1].error = item[1].error ? item[1].error : {}
                item[1].error_double = item[1].error_double ? item[1].error_double : {}
                let pricing_property = true
                item[1].values.customer_pricing_property.map((it2) => {
                    if (!it2.value) pricing_property = false
                })
                item[1].is_valid = Object.keys(item[1].error).length === 0 &&
                Object.keys(item[1].error_double).length === 0 &&
                pricing_property ? true : false
            })
            //customerAll.is_valid = validateAll > 0 ? false : true
            setForm(form => ({
                ...form,
                customers: customerAll
            }));
        }

    }, [form.customers]); // eslint-disable-line

    const handleChangeAccordion = (index) => (event, newExpanded) => {
        setForm(form => ({
            ...form,
            customers: {
                ...form.customers,
                [index]: {
                    ...form.customers[index],
                    expanded: newExpanded
                }
            }
        }));
    };
    const handleDelCustomer = (event, index) => {
        // console.log('index===========>', index)
        let tmp = JSON.parse(JSON.stringify(form.customers))
        delete tmp[index]
        setForm(form => ({
            ...form,
            customers: tmp
        }));
    };
    const handleAddCustomer = (event) => {
        // let index = form.customers.length
        let cust = iniCustomers
        cust.values.customer_pricing_property = iniPricingProperty ? iniPricingProperty : []
        let index = new Date().getTime();
        setForm(form => ({
            ...form,
            customers: {
                ...form.customers,
                [index]: cust
            }
        }));
    };

    return (
        <Box
            display="grid"
            sx={{width: '100%'}}
            class="accordeon"
        >
            <h3>{translate(`Customers for partner`)}:{' '}
                {form.partner.values.id ? form.partner.values.id.name : form.partner.values.name}</h3>
            {form.partner.values.id &&
                <Box alignItems={"start"} mt={1}>
                    <GetArrayFieldFromResource
                        resource={'zCustomers'}
                        field={'name'}
                        id={form.partner.values.id.customers}
                    />
                </Box>
            }

            {Object.entries(form.customers).map((item, ind) => {
                let index = item[0]
                let name = item[1].values.name
                let valid = item[1].is_valid
                return (
                    <Accordion
                        // expanded={expanded === 'panel1'}
                        expanded={form.customers[index].expanded}
                        onChange={handleChangeAccordion(index)}
                    >
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                          expandIcon={<ExpandMoreIcon/>}>
                            <Typography variant="h6" color={!valid ? "error" : "textPrimary"}>
                                {`${translate(`New customer`)}: ${name}`}
                            </Typography>

                        </AccordionSummary>
                        <AccordionDetails>
                            <Box display={'grid'} style={{padding: 16, width: '100%'}}>
                                {Object.entries(form.customers).length > 1 &&
                                    <Box mt={-3} mb={-2} style={{width: '100%', textAlign: "right"}}>
                                        <Button
                                            style={{marginRight: 16, color: "red"}}
                                            //disabled={!isValidStep(activeStep)}
                                            variant="contained"
                                            color="error"
                                            onClick={(event) => handleDelCustomer(event, index)}
                                            //className={classes.button}
                                        >
                                            {translate('Delete')}
                                        </Button>
                                    </Box>
                                }

                                <EditCustomers
                                    {...props}
                                    index={index}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                )
            })
            }

            <Box>
                <Button
                    style={{width: '100%', color: "blue"}}
                    //disabled={!isValidStep(activeStep)}
                    variant="contained"
                    color="error"
                    onClick={(event) => handleAddCustomer(event)}
                    //className={classes.button}
                >
                    {translate('Add customer')}
                </Button>
            </Box>
        </Box>
    )
};