import PermIdentity from '@material-ui/icons/PermIdentity';
import {MenuItemsCreate} from "./MenuItemsCreate";
import {MenuItemsEdit} from "./MenuItemsEdit";
import {MenuItemsList} from "./MenuItemsList";


// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: MenuItemsList,
    create: MenuItemsCreate,
    edit: MenuItemsEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;