import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    NumberField, useTranslate, useLocale,
    ReferenceField, AutocompleteInput, ReferenceInput,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp} from "../../_common/CompReact";

// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
// import {validateReq} from "../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
// import {useForm} from "react-final-form";
//import get from 'lodash/get';
import CroppedLongField from "../../_common/CroppedLongField";
import {validateReq} from "../../_constants/validateTable";

/*const transform = (data) => {
    data.article = data.article2;
    delete data.article2;
    data.index = data.index2;
    delete data.index2;
    data.variant_color = data.variant_color2;
    delete data.variant_color2;
    data.source = data.source2;
    delete data.source2;


    return data;
}*/
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {related_id} = props;
    const translate = useTranslate();
    const locale = useLocale();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    product: related_id,
                    source: localStorage.getItem('username'),
                }}
                // transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <ReferenceInput
                    label={translate('Related Product')}
                    source={'product_related'}
                    reference={'dProducts'}
                    //filter={{question$id: id}}
                    validate={validateReq}
                >
                    <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                       fullWidth/>
                </ReferenceInput>

            </RowForm>
        </>
    )
};
const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        feature_type:
            <ReferenceField
                label={translate('Related Product')}
                source={'product_related'}
                reference={'dProducts'}
                //link={false}
            >
                <TextField source={`name[${locale}]`}/>
            </ReferenceField>,

        source: /*<TextField source="source" label={translate('Source')}/>,*/
            <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
            /*cellClassName={classes.date_st}*/ showTime={true}/>,

    }
    return columns;
};

const ProductsFeatureValue = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const {related_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'RelatedProducts'}productslist.columns`,
        columns: ColumnsForList('RelatedProducts', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="RelatedProducts"
                reference="dRelatedProducts"
                target="product"

            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                              related_id={related_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            related_id={related_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default ProductsFeatureValue;