import * as React from 'react';
import {Box, Checkbox, TextField} from "@material-ui/core";
import Asynchronous from "../../_common/AsynchronousRequests";
import Button from "@material-ui/core/Button";
import {fetchJson as httpClient} from "../../utils/keycloak";
//import {Toolbar as MUIToolbar} from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import config from "../../utils/config";

import {useEffect} from "react";
import {canAccess2} from "../../utils/canAccess2";

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useTranslate} from "react-admin";
import {formatSpacerNumber} from "../../_common/CompFormat/utils";


const GetGifts = (props) => {
    const {result, product_id} = props;
    const {gift_choices} = result;

    if (gift_choices && gift_choices.length > 0) {
        let res = gift_choices.filter((x) => x.purchased_product_id === product_id)
        if (res.length > 0)
            return (
                <Box display={'grid'}>
                    {res.map((it, ind) => {
                        return (
                            <a
                                href={`/#/gift_pool/${it.gif_pool_id}`}
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                {it.gif_pool_name}
                            </a>
                        )
                    })
                    }
                </Box>
            )
        else return null
    } else return null;
}
const TblGift = (props) => {
    const {result} = props;
    const {gift_choices} = result;
    const translate = useTranslate();

    return (
        <TableContainer component={Paper}>
            <Table aria-label="spanning table" style={{width: '100%'}}>
                <TableHead>
                    <TableRow>
                        <TableCell align={'left'} style={{fontWeight: "bold"}}>{translate('Bonus')}</TableCell>
                        <TableCell align={'center'} style={{fontWeight: "bold"}}>{translate('Product')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {gift_choices && gift_choices.length > 0 && gift_choices.map((it, index) => {
                        const {gifts, gif_pool_id, gif_pool_name} = it
                        return (
                            <TableRow key={index}>
                                <TableCell>
                                    <a
                                        href={`/#/gift_pool/${gif_pool_id}`}
                                        target={"_blank"}
                                        rel="noreferrer"
                                    >
                                        {gif_pool_name}
                                    </a>
                                </TableCell>
                                <TableCell>
                                    {gifts.length > 0 && it.gifts.map((it2, index2) => {
                                            return (
                                                <Box display={'flex'} key={index2}>
                                                    <Box flex={2}>
                                                        <span>{it2.gift_product_name}</span>
                                                    </Box>
                                                    {it2.price_all_currencies &&
                                                        it2.price_all_currencies.map((it3, index3) => {
                                                            let pr = Object.entries(it3)
                                                            return (
                                                                <Box flex={1}
                                                                     textAlign={'right'}>{`${pr[0][1]} ${pr[0][0]}`}</Box>)
                                                        })
                                                    }
                                                </Box>
                                            )
                                        }
                                    )}
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>

    )
}

const TextDialog = (props) => {
    const {openDialog: data = false, handleCloseDialog} = props;
    const translate = useTranslate();
    //console.log('TEXT===============>', it)
    if (!data) return null
    return (
        <Dialog
            open={data}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title"><span
                style={{color: '#3f51b5', fontSize: 16}}>{translate(`History`)}</span></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{width: 540}}>

                    <TableContainer component={Paper}>
                        <Table aria-label="spanning table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'left'}
                                               style={{fontWeight: "bold"}}>{translate(`Applied Rule`)}</TableCell>
                                    <TableCell align={'left'}
                                               style={{fontWeight: "bold"}}>{translate(`Absorbed Rule`)}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data.map((it, index) => {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell>
                                                <a
                                                    style={{textDecoration: "none"}}
                                                    href={`/#/discount_rules/${it.applied_rule_id}`}
                                                    target={"_blank"}
                                                    rel="noreferrer"
                                                >
                                                    {`${it.applied_rule_name}${'\u00a0'}(${it.relation})`}
                                                </a>
                                            </TableCell>
                                            <TableCell>
                                                {it.absorbed_rule_id
                                                    ?
                                                    <a
                                                        style={{textDecoration: "line-through"}}
                                                        href={`/#/discount_rules/${it.absorbed_rule_id}`}
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                    >
                                                        {`${it.absorbed_rule_name}`}
                                                    </a>
                                                    : '---'
                                                }
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export const TestPrice = props => {

    const {
        result,
        setResult,
        openDialog,
        setOpenDialog,
        totalPrice,
        setTotalPrice,
        filterState,
        setFilterState,
        dataForSearch,
        setDataForSearch,
        setCustomerName,
        customerName,
    } = props

    const translate = useTranslate();

    /*const iniFilter = {
        customer: null,
        product: [
            {product: null, quantity: null, check: true, del: false},
            {product: null, quantity: null, check: true, del: false},
            /!*{product: null, quantity: null, check: false, del: false},*!/
        ]
    }

    // let ini = JSON.parse(localStorage.getItem("test_price"));
    // if(!ini) ini = iniFilter;
    const [result, setResult] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [totalPrice, setTotalPrice] = React.useState(null);

    const [filterState, setFilterState] = React.useState(iniFilter);
    const [dataForSearch, setDataForSearch] = React.useState(null);*/

    /*useEffect(() => {
        setFilterState(iniFilter)
    }, []);*/

    useEffect(() => {
        console.log('filterState=======>', filterState)

        let dt = [];
        filterState.product.forEach(it => {
            if (!it.del && it.check && it.product) {
                dt.push({
                    "price_row_id": it.product,
                    "quantity": it.quantity ? it.quantity : 1
                })
            }
        })
        if (dt.length > 0 && filterState.customer) setDataForSearch(dt);
        else setDataForSearch(null);
        //localStorage.setItem('test_price', JSON.stringify(filterState))
    }, [filterState]); // eslint-disable-line

    useEffect(() => {
        if (result) {
            const {product_prices = {}} = result || {}
            let dt = {
                base_price: {name: 'Amount (base price)'},
                regular_price: {name: 'Amount (regular price)'},
                price_with_order_discounts: {name: 'Total order including discounts'},
            }
            product_prices.forEach(it => {
                const {result_price_all_currencies = {}} = it || {}
                Object.entries(result_price_all_currencies).forEach((it2, ind) => {
                    let quantity = it2[1].total_sum / it2[1].price_with_order_discounts
                    if (dt.base_price[it2[0]]) dt.base_price[it2[0]] += it2[1].base_price * quantity
                    else dt.base_price[it2[0]] = it2[1].base_price * quantity
                    if (dt.regular_price[it2[0]]) dt.regular_price[it2[0]] += it2[1].regular_price * quantity
                    else dt.regular_price[it2[0]] = it2[1].regular_price * quantity
                    if (dt.price_with_order_discounts[it2[0]]) dt.price_with_order_discounts[it2[0]] += it2[1].price_with_order_discounts * quantity
                    else dt.price_with_order_discounts[it2[0]] = it2[1].price_with_order_discounts * quantity
                })
            })
            setTotalPrice(dt);
            //console.log('regular_price====================>', dt)
        }
        //localStorage.setItem('test_price', JSON.stringify(filterState))
    }, [result]);// eslint-disable-line

    const getData = async (customer, data) => {
        //const url = `https://ecs.zenit-auto.com/pricing/full-prices/${customer}/`;
        //const url = `https://api-dev.zenit-auto.com/api-public/pricing/full-prices/${customer}/`;
        // const url = `${config.apiPrices}api/pricing/full-prices/${customer}/?show_relation_history=true`
        const url = `${config.apiPrices}api/pricing/admin/full-prices/${customer}?show_relation_history=true` //api/pricing/full-prices-by-price-rows

        try {
            //const response = await httpClient(`${url}${report_url}`);
            const response = await httpClient(`${url}`, {
                method: 'post',
                body: JSON.stringify(data)
                /*body: JSON.stringify({
                    b64: base64
                })*/
            })
            if (response.status >= 200 && response.status <= 300) {
                const {json: dt} = response
                const {message} = dt || {}
                //console.log('response=========>', dt);
                setResult(dt);
                if (message) {
                    //alert(response.json.message)
                    //notify(message, {type: 'info', autoHideDuration: 8000,});
                }
            } else {

            }
        } catch (err) {
            console.log('err=======>', err);
            // notify(err, {type: 'info',});
        } finally {
            //console.log('finally=======>', 'finally');
        }
    }

    const handleClick = () => {
        if (dataForSearch && dataForSearch.length > 0) getData(filterState.customer, dataForSearch);
    }

    const handleAddRows = () => {
        let pr = filterState.product;
        pr.push({product: null, quantity: null, check: true, del: false});
        setFilterState({
            ...filterState,
            product: pr
        });
    }
    const handleDelRows = (e, index) => {
        let pr = filterState.product;
        pr[index].del = true
        setFilterState({
            ...filterState,
            product: pr
        });
    }

    const handleChangeFilterValue = (index, name, v) => {
        //console.log(index, name, '=====>', v)
        let val;
        if (name === 'quantity') {
            val = v.target.value;
        } else if (name === 'product') {
            val = v;
        } else if (name === 'check') {
            val = v.target.checked;
        } else if (name === 'customer') {
            val = v;
            setFilterState({
                ...filterState,
                [name]: val
            });
            return true;
        } else return true;
        let pr = filterState.product;
        pr[index][name] = val
        setFilterState({
            ...filterState,
            //[name]: {...filterState[name], value: val}
            product: pr
        });
        console.log(index, name, '=====>', val)
    };

    const handleClickOpenDialog = (event, data) => {
        event.preventDefault();
        event.stopPropagation();
        setOpenDialog(data);
    };
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    function getHistory(data, text = true) {
        let store_history = '';
        data.forEach((it, index) => {
            if (text) {
                store_history += `${it.applied_rule_name}${'\u00a0'}(${it.relation})`;
                if (it.absorbed_rule_id) store_history += ` ==> ${it.absorbed_rule_name}`;
                store_history += '\n'
            } else {
                store_history += <div>
                    store_history += <span key={index}>{`${it.applied_rule_name}${'\u00a0'}(${it.relation})`}</span>
                    if(it.absorbed_rule_id) store_history += <span key={index}>{`${it.absorbed_rule_name}`}</span>
                    store_history += </div>
            }
        })
        return store_history
    }

    //const {total_sum = {}} = result || {}

    return (
        <Box
            sx={{width: '100%'}}
            //     style={{margin: 16, border: '1px solid #ccc', borderRadius: 4, backgroundColor: "white", padding: 16}}
        >
            <TextDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
            />

            <h3>{translate(`Test Price`)} {' '}
                {canAccess2({resource: 'Swager', action: 'list'}) &&
                    <a
                        style={{fontWeight: 'normal', textDecoration: "none"}}
                        href={config.apiSwager}
                        target={"_blank"}
                        rel="noreferrer"
                    >(To Swager)</a>
                }
            </h3>
            <Box display="flex" alignItems={"center"} mb={4}>
                <Box flex={2} mr={"1em"}>
                    <Asynchronous
                        setCustomerName={setCustomerName}
                        setValue={(value) => handleChangeFilterValue(0, 'customer', value)}
                        domain={config.apiPrices}
                        url={'api/pricing/admin/customer'}
                        getOptionLabel={(option) => option.name} label={translate('Customer')}/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <Button
                        disabled={!dataForSearch}
                        variant="contained"
                        color="primary"
                        onClick={handleClick}
                        fullWidth
                    >
                        {translate('Test')}
                    </Button>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={(event) => {
                            setResult(null)
                        }}
                        fullWidth
                    >
                        {translate('Clear Result')}
                    </Button>
                </Box>
                {/*<Box flex={1}>
                </Box>*/}
            </Box>

            {filterState.product && filterState.product.map((it, index) => {
                if (it.del) return null;
                else
                    return (
                        <Box display="flex" alignItems={"center"} mt={1} sx={{width: '800px'}} key={index}>
                            <Box mr={"1em"}>
                                <Checkbox
                                    style={{'padding': "0px"}}
                                    checked={it.check}
                                    onChange={(event) => handleChangeFilterValue(index, 'check', event)}
                                    name={'check'}
                                />
                            </Box>
                            <Box flex={6} mr={"1em"}>
                                <Asynchronous
                                    //value={filterState.product[index].product}
                                    // setValue={(value) => handleChangeFilterValue(index, 'product', value)}
                                    // url={'api/pricing/admin/product'}
                                    // outputField='ext_id'
                                    // getOptionLabel={(option) => option.name} label={'Product'}
                                    domain={config.apiPrices}
                                    setValue={(value) => handleChangeFilterValue(index, 'product', value)}
                                    url={'api/pricing/admin/price_row'}
                                    // outputField='product.ext_uuid'
                                    outputField='id'
                                    searchField={'product_search'}
                                    getOptionLabel={(option) => option.product.name} label={translate('Product')}
                                />

                            </Box>
                            <Box flex={2} mr={"1em"}>
                                <TextField
                                    fullWidth
                                    value={it.quantity}
                                    size={"small"}
                                    label={translate("Quantity")}
                                    variant="outlined"
                                    type={"number"}
                                    name={'quantity'}
                                    onChange={(event) => handleChangeFilterValue(index, 'quantity', event)}
                                />
                            </Box>
                            <Box flex={2}>
                                {index > 0 &&
                                    <Button
                                        variant="contained"
                                        color="default"
                                        onClick={(event) => handleDelRows(event, index)}
                                        //fullWidth
                                    >
                                        DEL
                                    </Button>
                                }
                            </Box>
                        </Box>
                    )
            })
            }

            <Box flex={1} mt={"1em"}>
                <Button
                    //variant="contained"
                    color="primary"
                    onClick={handleAddRows}
                >
                    Add Rows
                </Button>
            </Box>

            {result &&
                <>
                    <h4 style={{color: '#2196f3'}}>{customerName?.name}</h4>
                    <h4>{translate('PRODUCTS')} <span style={{fontSize: 14}}>(Time = {result.ellapsed_time})</span></h4>
                    {totalPrice && Object.values(totalPrice).map((it, ind) => {
                        return (
                            <h4 key={`t_${ind}`} style={{marginTop: -16}}>
                                {translate(`${it.name}`)}: (
                                {Object.entries(it).map((it2, ind) => {
                                    return (
                                        it2[0] === 'name'
                                            ? null
                                            :
                                            <span key={`s_${ind}`}
                                                  style={{fontWeight: it2[0] === 'UAH' ? 'bold' : 'normal'}}>
                                                    {`${formatSpacerNumber(it2[1])} ${it2[0]}, `}
                                            </span>
                                    )
                                })}
                                )</h4>
                        )
                    })
                    }


                    <TableContainer component={Paper}>
                        <Table aria-label="spanning table" style={{width: '100%'}}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align={'left'}
                                               style={{fontWeight: "bold"}}>{translate('Product')}</TableCell>
                                    <TableCell align={'right'}
                                               style={{fontWeight: "bold"}}>{translate('Base price')}</TableCell>
                                    <TableCell align={'right'}
                                               style={{fontWeight: "bold"}}>{translate('Fix price')}</TableCell>
                                    <TableCell align={'right'}
                                               style={{fontWeight: "bold"}}>{translate('Regular price')}</TableCell>
                                    <TableCell align={'right'}
                                               style={{fontWeight: "bold"}}>{translate('Price with order discounts')}</TableCell>
                                    <TableCell align={'left'}
                                               style={{fontWeight: "bold"}}>{translate('Regular discounts')}</TableCell>
                                    <TableCell align={'left'}
                                               style={{fontWeight: "bold"}}>{translate('Order discounts')}</TableCell>
                                    <TableCell align={'center'}
                                               style={{fontWeight: "bold"}}>{translate('Bonus')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {result.product_prices && result.product_prices.map((it, index) => (
                                    <TableRow key={index}>
                                        <TableCell>
                                            <Box display={'grid'}>
                                                <span>{it.product.name}</span>
                                                <span>{it.product.article}</span>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'} style={{textAlign: "right"}}>
                                                {Object.values(it.result_price_all_currencies).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    return (
                                                        <span
                                                            key={`base_price${index2}`}>{`${it2.base_price}${'\u00a0'}${it2.currency.name}`}</span>
                                                    )
                                                })
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'} style={{textAlign: "right"}}>
                                                {Object.values(it.result_price_all_currencies).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    return (
                                                        <span
                                                            key={`fix_price${index2}`}>{`${it2.fix_price}${'\u00a0'}${it2.currency.name}`}</span>
                                                    )
                                                })
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'} style={{textAlign: "right"}}>
                                                {Object.values(it.result_price_all_currencies).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    return (
                                                        <span
                                                            key={`regular${index2}`}>{`${it2.regular_price}${'\u00a0'}${it2.currency.name}`}</span>
                                                    )
                                                })
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'} style={{textAlign: "right"}}>
                                                {Object.values(it.result_price_all_currencies).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    return (
                                                        <span
                                                            key={`>price_discounts${index2}`}>{`${it2.price_with_order_discounts}${'\u00a0'}${it2.currency.name}`}</span>
                                                    )
                                                })
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'}>
                                                {Object.values(it.regular_discounts.applied_discounts_list).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    let fraze;
                                                    if (it2.use_fix_price) {
                                                        fraze = `${it2.fix_price}${'\u00a0'}${it2.fix_price_currency_iso3}`
                                                    } else {
                                                        fraze = `${it2.discount}${'\u00a0'}${'%'}`
                                                    }
                                                    return (
                                                        <a key={`regular_discounts${index2}`}
                                                           href={`/#/discount_rules/${it2.rule_id}`}
                                                           target={"_blank"}
                                                           rel="noreferrer"
                                                        >
                                                            {`${it2.rule_name} (${fraze})`}</a>
                                                    )
                                                })
                                                }
                                                {it.regular_discounts.applied_discounts_list.length > 0 &&
                                                    <span style={{
                                                        fontWeight: "bold",
                                                        marginTop: 4
                                                    }}>Total discount {it.regular_discounts.total_discount.discount}{'\u00a0'}%</span>
                                                }
                                                {it.regular_discounts.store_history.length > 0 &&
                                                    <span
                                                        style={{cursor: 'pointer', color: "blue"}}
                                                        title={getHistory(it.regular_discounts.store_history)}
                                                        onClick={(event) => {
                                                            handleClickOpenDialog(event, it.regular_discounts.store_history)
                                                        }}
                                                    >
                                                        {translate('History')}
                                                    </span>
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box display={'grid'}>
                                                {Object.values(it.order_discounts.applied_discounts_list).map((it2, index2) => {
                                                    //console.log('it2====>', it2)
                                                    let fraze;
                                                    if (it2.use_fix_price) {
                                                        fraze = `${it2.fix_price}${'\u00a0'}${it2.fix_price_currency_iso3}`
                                                    } else {
                                                        fraze = `${it2.discount}${'\u00a0'}${'%'}`
                                                    }
                                                    return (
                                                        <a key={`order_discounts${index2}`}
                                                           href={`/#/discount_rules/${it2.rule_id}`}
                                                           target={"_blank"}
                                                           rel="noreferrer"
                                                        >
                                                            {`${it2.rule_name} (${fraze})`}</a>
                                                    )
                                                })
                                                }
                                                {it.order_discounts.applied_discounts_list.length > 0 &&
                                                    <span style={{
                                                        fontWeight: "bold",
                                                        marginTop: 4
                                                    }}>Total discount {it.order_discounts.total_discount.discount}{'\u00a0'}%</span>
                                                }
                                                {it.order_discounts.store_history.length > 0 &&
                                                    <span
                                                        style={{cursor: 'pointer', color: "blue"}}
                                                        title={getHistory(it.order_discounts.store_history)}
                                                        onClick={(event) => {
                                                            handleClickOpenDialog(event, it.order_discounts.store_history)
                                                        }}
                                                    >{translate('History')}</span>
                                                }
                                            </Box>
                                        </TableCell>
                                        <TableCell align={"center"}>
                                            <GetGifts result={result} product_id={it.product.id}/>
                                        </TableCell>
                                    </TableRow>))
                                }
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {result.gift_choices && result.gift_choices.length > 0 &&
                        <>
                            <h4>{translate('BONUSES')}</h4>
                            <TblGift result={result}/>
                        </>
                    }
                </>
            }

        </Box>
    )
};