import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, TextField,
    useLocale,
    // TextInput,
    useTranslate,
    ReferenceField,
} from 'react-admin';

// import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
// import {RowForm} from "@react-admin/ra-editable-datagrid";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import useMyStyles from "../../utils/useMyStyles";
// import {universalTopFilters} from "../universalView/universalTopFilters";
// import {CardContent, useMediaQuery} from "@material-ui/core";
import {CardContent} from "@material-ui/core";
import CardComp from "../../_common/Asside/CardComp";
import {
    DateFieldComp,
    TextFieldBold,
    //NumberInputFloatComp,
    //UserInfo,
} from "../../_common/CompReact";
// import {validateFirstName, validateReq} from "../utils/components/validateTable";
// import {Box} from "@mui/material";
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import CroppedLongField from "../../_common/CroppedLongField";

const universalTopFilters = (resource, locale) => {
    let dt = [];
    /*dt.push(<ReferenceInput source="user_country" reference="Countries" alwaysOn>
        <AutocompleteInput
            optionText={`name_${locale}`}
            resettable emptyText={'All'}/>
    </ReferenceInput>);
    dt.push(<ReferenceInput source="discount_group" reference="DiscountGroups" alwaysOn>
        <AutocompleteInput
            optionText={`name_${locale}`}
            resettable emptyText={'All'}/>
    </ReferenceInput>);*/
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                <FilterLiveSearch/>

            </CardContent>
        </CardComp>
    )
};

/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/

/*
adding_orders_external_accounting_system: false
additional: {}
api_price_upload_unique_art_brand: false
article_name_process_mode: "PRICE_PRODUCTS_CROSS"
base_price_type: null
check_assortiment_markup_by_quantity: false
+++ company_name: {}
control_balance_under_order: false
convert_discounts_to_buyers_currency: true
default_mode_show_quantity: "EXACTLY"
delivery_mode: "directly"
discount_mode: "discount_sum"
enable_upload_price_trace: false
expiration_reserve_time: null
favicon: ""
get_settings_from_main_customer: false
google_credential: ""
google_merchant_id: 5298584529
html_meta_tag_root_body_h1: ""
html_meta_tag_root_description: ""
html_meta_tag_root_text: ""
html_meta_tag_root_title: ""
id: 1
image_watermark: ""
inactive_user_token_lifetime: 0
laximo_allow_noncyrillic_name_blank: false
logo_large: ""
logo_middle: ""
logo_small: ""
+++ main_country: 1
+++ main_currency: 2
+++ main_customer: 1
+++ main_lang: "en"
main_manager_default: null
+++ main_supplier: 1
manager_default: null
max_recursive_iterations: 10
min_retail_price_type: null
+++ national_currency: 2
new_user_manager: null
notification_image: ""
price_mode: "standard"
primary_branch: 1
primary_phone: 1
product_as_service: null
project_jira_code: ""
recommended_retail_price_type: null
resolution_product_cards_heigth: 145
resolution_product_cards_width: 490
resolution_product_large_heigth: 1067
resolution_product_large_width: 1600
resolution_product_lists_heigth: 145
resolution_product_lists_width: 145
resolution_product_mobiles_heigth: 150
resolution_product_mobiles_width: 376
show_all_search_result: false
show_zero_balance_under_order: false
source: "admin"
updated: "2023-11-29T16:35:47.955912"
use_available_storages_by_discount_group_in_searching: false
use_contracts: false
use_customer_currency_in_order: false
use_discount_by_supplier_brand: false
use_search_by_article: true
use_search_by_cross: true
use_search_by_description: false
use_search_by_name: true
user_activation_mode: "manual"
 */
const ColumnsForList = (resource, locale, classes) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        // prompt: <TextFieldBold source={`prompt`} label={translate('Prompt')} cellClassName={classes.prompt_name}/>,
        name: <TextFieldBold source={`company_name[${locale}]`} label={translate('Company Name')}/>,

        main_country:
            <ReferenceField source="main_country" reference="dCountries"
                            link={false} label={translate('Main Country')}>
                <TextField source={`name[${locale}]`}/>
            </ReferenceField>,
        primary_phone:
                <ReferenceField source="primary_phone" reference="dCompanyPhone"
                            link={false} label={translate('Primary Phone')}>
                <TextField source={`number`}/>
            </ReferenceField>,
        main_customer:
            <ReferenceField source="main_customer" reference="dCustomers"
                            link={false} label={translate('Main Customer')}>
                <TextField source={`name`}/>
            </ReferenceField>,
        main_supplier:
            <ReferenceField source="main_supplier" reference="dSuppliers"
                            link={false} label={translate('Main Supplier')}>
                <TextField source={`name`}/>
            </ReferenceField>,
        main_lang:
            <TextField source={`main_lang`} label={translate('Main Lang')}/>,
        main_currency:
            <ReferenceField source="main_currency" reference="dCurrencies"
                            link={false} label={translate('Main Currency')}>
                <TextField source={`abbr`}/>
            </ReferenceField>,

        // main_manager_default: null
        // manager_default: null
        // max_recursive_iterations: 10
        // min_retail_price_type: null
        // national_currency: 2


        /*is_hide_in_filters_list: <BooleanFieldComp source="is_hide_in_filters_list"
                                                   label={translate("Is hide in filters list")} textAlign={"center"}/>,*/


        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }
    return columns;
};

export const ConfigList = props => {
    const classes = useMyStyles();
    const {resource} = props;

    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });
    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes),
        omit: ["nb_views"],
    });

    return (
        <ListComponentAdmin
            {...props}
            //hasCreate={true}
            title={''}
            actions={<TableActions
                preference={`${resource}${"list.columns"}`}
                columns={ColumnsForList(resource, locale, classes)}
                //select={false}
                create={false}
                filter={true}
            />}
            is_bulkActionButtons={false}
            filters={universalTopFilters(resource, locale)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
        >
            <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                {columns}
            </DatagridComponentAdmin>
        </ListComponentAdmin>
    )
};