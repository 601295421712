import PermIdentity from '@material-ui/icons/PermIdentity';


import {SheduleForm} from './Main';

const resource = {
    list: SheduleForm,
    icon: PermIdentity,
};

export default resource;