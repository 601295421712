import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    FormDataConsumer, TextInput, useTranslate
} from 'react-admin';

import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
import {DateFieldComp, TextFieldButton} from "../../_common/CompReact";
//import useMyStyles from "../utils/useMyStyles";

//import {useForm} from "react-final-form";
// import {useEffect} from "react";
// import {config} from "../utils/config";
import {validateFirstName} from "../../_constants/validateTable";
import RelatedGift from "./RelatedGift";
/*
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
*/
const BlockEditCreate = props => {

    //const formEdit = useForm();
    const {record} = props;
    const translate = useTranslate();
    const {id, is_load = false} = record;

    /*useEffect(() => {
        if (card_id === null) {
            formEdit.change('card_id', parseInt(config.bookmanCardID))
        }
    }, []);// eslint-disable-line*/


    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"center"}>
                    <Box flex={1} mr={'2em'}>
                        <TextInput source={`name`} label={translate('Name')} validate={validateFirstName}
                                   fullWidth/>
                    </Box>
                <Box flex={1} mr={'2em'}/>
                <Box flex={1} mr={'2em'}/>
                <Box flex={1} mr={'2em'}/>
            </Box>

            {id
                ?
                <>
                    <Typography variant="h6">Bonuses</Typography>
                    <Box display="flex" alignItems={"center"}>
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                //const {customer_id: customerFormId} = formData;
                                return (
                                    <RelatedGift
                                        {...props}
                                        gift_pool_id={id}
                                        formData={formData}
                                        record={record}
                                        isSave={!is_load}
                                    />
                                )
                            }}
                        </FormDataConsumer>
                    </Box>
                </>
                :
                <Typography variant="subtitle1" color={"error"}>For further work fill in the Name field
                    and save</Typography>
            }
        </Box>
    )
}
export const GiftPoolEditCreate = props => {//Не работает
    // const classes = useMyStyles();
    const {record} = props;
    const {id} = record;
    const translate = useTranslate();
    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)

    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box mr="2.5em">
                            <Typography variant="subtitle2" gutterBottom>Name</Typography>
                            <TextFieldButton
                                //itsOriText={record => (`${record.last_name}`)}
                                //copyClipBoard={true}
                                source="name"
                                //source2="f_name"
                                //source3="l_name"
                                backgroundColor={'#eee'}
                            />
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{translate('Created')}{`: `}
                                <DateFieldComp source="created_at" variant="subtitle2" fullWidth/>
                            </div>
                            <div>{translate('Updated')}{`: `}
                                <DateFieldComp source="updated_at" variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <TabbedForm
                {...props}
                /*toolbar={<PostEditDeleteActions goBack={true} saveAndClose={'/invoice'} reload={true}/>}*/
                toolbar={<PostEditDeleteActions
                    goBack={true}
                    saveAndClose={'/gift_pool'}
                    //reload={true}
                />
                }
                //redirect={redirect_url}
            >
                <FormTab label="Common">
                    <BlockEditCreate {...props}/>
                </FormTab>
                {/*{id &&
                    <FormTab label="History">
                        <HistoryListForFilter {...props} table={'invoice'} invoice_id={id} fullWidth/>
                    </FormTab>
                }*/}
            </TabbedForm>
        </>
    )
};

export default GiftPoolEditCreate
