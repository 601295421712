import * as React from 'react';
/*import {
    useRedirect,
} from 'react-admin';*/

import EditComponentAdmin from "../../_common/EditComponentAdmin";
import FeatureTypeEditCreate from "./FeatureTypeEditCreate";
import {useRedirect} from "react-admin";

export const FeatureTypeEdit = props => {
    const {location} = props;
    const {search} = location || {};
    let idSource = '';
    if (search) {
        idSource = search.split('idSource=')[1] ? search.split('=')[1] : '';
    }
    const redirect = useRedirect()

    return (
        <EditComponentAdmin
            {...props}
            //transform={(event) => transform(resource, event)}
            title={''}
            //editDialog={idSource}
            onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            /*onSuccess={() => idSource
                ? redirect(`/locating/${idSource}/2`)
                : redirect('list', props.basePath)
            }*/
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <FeatureTypeEditCreate idSource={idSource}/>
        </EditComponentAdmin>
    )
};