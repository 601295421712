import * as React from 'react';

import {
    ListButton,
    TopToolbar,
} from 'react-admin';

// const PostEditActions = ({ basePath, data, resource }) => (
const PostEditActions = ({ basePath }) => (
    <TopToolbar>
        <ListButton basePath={basePath} />
        {/*<ListButton basePath={basePath} label="Back" icon={<ChevronLeft />} />*/}
        {/*<ShowButton basePath={basePath} record={data} />*/}
        {/* Add your custom actions */}
        {/*<Button color="primary" onClick={customAction}>Custom Action</Button>*/}
    </TopToolbar>
);
export default PostEditActions;
