import PermIdentity from '@material-ui/icons/PermIdentity';
import {UserList} from "./UserList";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: UserList,
    // create: BaseListIdNameCreate,
    // edit: BaseListIdNameEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;