import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {PartnerList} from "./PartnerList";
import {PartnerEdit} from "./PartnerEdit";
import {PartnerCreate} from "./PartnerCreate";

const resource = {
    list: PartnerList,
    create: PartnerCreate,
    edit: PartnerEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;