import DefaultTable from '../DefaultTable/DefaultClass'

class CurrencyForeignName extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                name: 'currency',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Currencies', optionText: 'name', locale: true},
            }
        ]

        this.filters_aside = []
        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]

        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'currency',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Currencies', optionText: 'name', locale: true},

                                //formTab: 'Common2222',
                                read_only: false
                            },
                            {
                                name: 'name',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                read_only: false
                            },
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]

    }
}

export default CurrencyForeignName