import * as React from 'react';

import {
    Datagrid,
    BulkDeleteButton,
} from 'react-admin';

import {EditableDatagrid} from "@react-admin/ra-editable-datagrid";
import {canAccess2} from "../utils/canAccess2";

const MyEditableDatagridForReference = (props) => {
    const {
        canEdit = true,
        //resource,
        children,
        hasBulkActions = true,
        hasBulkActionsRowForm = true,
        noDelete = false,
    } = props;
    const {selectedIds} = props

    //console.log('props=====>', props)
    // const canEditResource = canAccess2({resource: `${resource}`, action: 'edit'});
    // const canDeleteResource = canAccess2({resource: `${resource}`, action: 'delete'});

    if (canEdit)
        return (
            <>
                {!noDelete &&
                    hasBulkActions &&
                    hasBulkActionsRowForm &&
                    selectedIds &&
                    selectedIds.length > 0 &&
                    <BulkDeleteButton
                        {...props}
                        style={{float: "left"}}
                        label={`Delete Marked (${selectedIds.length})`}
                        //icon={false}
                        undoable={false} //Подтверждение удаления
                    />
                }
                <EditableDatagrid {...props}
                                  empty={false}
                                  hasBulkActions={hasBulkActions}
                                  noDelete={noDelete}
                >
                    {children}
                </EditableDatagrid>
            </>
        )
    else
        return (
            <Datagrid>
                {children}
            </Datagrid>
        )

};

export default MyEditableDatagridForReference;
