import * as React from 'react';
import Box from '@mui/material/Box';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {TreeView} from '@mui/x-tree-view/TreeView';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import {useEffect} from "react";
import {useGetList, useLocale, useTranslate} from "react-admin";
import Button from "@mui/material/Button";
import {useMediaQuery} from "@material-ui/core";

import HighlightOffIcon from '@material-ui/icons/HighlightOff';


export const MyTree = props => {

    const {table, handleChangeParent, disabledClick = '-', parentFilter} = props
    const locale = useLocale();
    const translate = useTranslate();

    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });

    const [expanded, setExpanded] = React.useState([]);
    const [oldExpanded, setOldExpanded] = React.useState([]);
    const [data, setData] = React.useState(null);

    const {data: dataCategories, loaded: loadedCategories} = useGetList(
        "dProductsGroup",
        {page: 1, perPage: 2000},
        //{field: 'id', order: 'ASC'},
    );

    useEffect(() => {
        let dt = localStorage.getItem(table)
        if (dt) {
            dt = JSON.parse(dt)
            setExpanded(dt.expanded)
            // expanded: expanded,
        }
    }, []);// eslint-disable-line

    useEffect(() => {
        if (loadedCategories) {
            //console.log('dataCategories=========>', dataCategories)
            setData(Object.values(dataCategories))
        }
    }, [dataCategories]);// eslint-disable-line

    const handleExpandClick = () => {
        // setExpanded(expanded.length === 0 ? oldExpanded : [],);
        setExpanded((oldExpanded2) =>
            oldExpanded2.length === 0 ? oldExpanded : [],
        );
    };
    const handleToggle = (event, nodeIds) => {
        setExpanded(nodeIds);
        setOldExpanded(nodeIds);
    };

    const handleClick = (event, id, label) => {
        event.preventDefault();
        event.stopPropagation();
        if (table) {
            localStorage.setItem(table,
                JSON.stringify({
                    id: id,
                    label: label,
                    expanded: expanded,
                })
            )
        }
        handleChangeParent(id, label, expanded)
    }

    const ItemLabel = (props) => {
        const {id, label, position = 'dont_end'} = props
        return (
            <div
                style={{
                    opacity: disabledClick === position ? 0.5 : 1,
                    fontWeight: parentFilter === id && 'bold'
                }}
                onClick={(event) => {
                    if (disabledClick !== position) handleClick(event, id, label)
                }}
            >
                <span>{`${position === 'end' ? '\u00b7\u00a0' : ''}${label}`}</span>
                {parentFilter === id && id &&
                    <HighlightOffIcon
                        style={{float: "inline-end"}}
                        onClick={(event) => {
                            handleClick(event, '', 'All Categories')
                        }}
                    />
                }
            </div>
        )
    };

    const Chldren = props => {
        const {id} = props
        return (
            data.filter(x => x.parent === id).map(it => {

                    if (data.filter(x => x.parent === it.id).length > 0)
                        return (
                            <TreeItem nodeId={it.id} key={it.id}
                                      label={<ItemLabel label={it[`name`][`${locale}`]} id={it.id}/>}
                            >
                                <Chldren id={it.id}/>
                            </TreeItem>
                        )
                    else
                        return (
                            <TreeItem
                                nodeId={it.id} key={it.id}
                                label={<ItemLabel position={'end'} label={it[`name`][`${locale}`]} id={it.id}/>}
                            />
                        )
                }
            )
        )
    }

    if (data === null) return null

    return (
        <div
            style={{
                maxHeight: isSmall && 550,
                overflowY: isSmall && 'auto',
            }}

        >

            <Box sx={{minHeight: 180, flexGrow: 1, maxWidth: 300}}>
                <Box sx={{mb: 1}}>
                    <Button onClick={handleExpandClick}>
                        {expanded.length === 0 ? 'Expand all' : 'Collapse all'}
                    </Button>
                </Box>
                <TreeView
                    aria-label="file system navigator"
                    defaultCollapseIcon={<KeyboardArrowDownIcon/>}
                    //defaultExpandIcon={<ChevronRightIcon />}
                    defaultExpandIcon={<KeyboardArrowRightIcon/>}
                    expanded={expanded}
                    onNodeToggle={handleToggle}
                >
                    <TreeItem nodeId={0}
                              label={<ItemLabel label={translate('All Categories')} id={''}/>}
                    />
                    {data.filter(x => x.parent === null).map(it => {
                        // console.log('it--------->', it)
                        if (data.filter(x => x.parent === it.id).length > 0)
                            return (
                                <TreeItem nodeId={it.id} key={it.id}
                                          label={<ItemLabel label={it[`name`][`${locale}`]} id={it.id}/>}
                                >
                                    <Chldren id={it.id}/>
                                </TreeItem>
                            )
                        else
                            return (
                                <TreeItem
                                    nodeId={it.id} key={it.id}
                                    label={<ItemLabel  position={'end'} label={it[`name`][`${locale}`]} id={it.id}/>}
                                />
                            )
                    })}
                </TreeView>
            </Box>
        </div>
    )
        ;
}
