import DefaultTable from '../DefaultTable/DefaultClass'
// import {commonTrueFalse, commonYesNo} from "../../utils/choices";

class PromotionDiscountByProduct extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                name: 'product',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Products', optionText: 'name', locale: true},
            },
            {
                name: 'discount_groups',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {},
            }
        ]


        this.filters_aside = []

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]


        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'discount_groups',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'DiscountGroups', optionText: 'name', locale: true},
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'product',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Products', optionText: 'name', locale: true},
                                read_only: false,
                                flex: 1,
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'source',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                read_only: false,
                            },
                        ],
                    },
                    {
                        box: [
                            {
                                name: 'value',
                                type: 'float',
                                show_type: 'NumberField',
                                edit_type: 'NumberInputFloatComp',
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'fix_price',
                                type: 'float',
                                show_type: 'NumberField',
                                edit_type: 'NumberInputFloatComp',
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'fix_price_currency', //??????????????????????????????
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Currencies', optionText: 'name', locale: true},
                                read_only: false,
                            },
                        ],
                    },
                    {
                        box: [
                            {
                                name: 'on_sale',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                read_only: false,
                            },

                           {
                                name: 'variant',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                read_only: false,
                            },

                            {
                                name: 'characteristic',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                read_only: false,
                            },
                            {
                                name: 'size',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                read_only: false,
                            },

                        ]

                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]


    }
}

export default PromotionDiscountByProduct