import PermIdentity from '@material-ui/icons/PermIdentity';
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


import {UniversalList} from "./UniversalList";
import {UniversalEdit} from "./UniversalEdit";
import {UniversalCreate} from "./UniversalCreate";

const resource = {
    list: UniversalList,
    create: UniversalCreate,
    edit: UniversalEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;