import PermIdentity from '@material-ui/icons/PermIdentity';
import {PromotionBadgeList} from "./PromotionBadgeList";
import {PromotionBadgeCreate} from "./PromotionBadgeCreate";
import {PromotionBadgeEdit} from "./PromotionBadgeEdit";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: PromotionBadgeList,
    create: PromotionBadgeCreate,
    edit: PromotionBadgeEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;