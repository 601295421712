import * as React from 'react';
import {
    useTranslate,
    // TextInput,
} from 'react-admin';
// import {JsonInput as RAJsonInput} from "react-admin-json-view";
import JsonInput from "./JsonInput";
import {Box, FormControl, InputLabel, Select} from "@mui/material";
import {localesConfig} from "../utils/config";
// import useMyStyles from "../utils/useMyStyles";

const Seo = props => {
    // const {customer_id, record, isSave = true} = props;
    const {promptData, resource} = props
    const translate = useTranslate();
    const [currentLocale, setCurrentLocale] = React.useState(localesConfig.defaultLocale);
    // const locale = useLocale();
    // const {product_id, source} = props;
    // const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    // const columns = useSelectedColumns({
    //     preferences: `${'VariantRelation'}list.columns`,
    //     columns: ColumnsForList('VariantRelation', classes, locale),
    //     omit: ["nb_views"],
    // });

    return (
        <Box p="1em" fullWidth>
            <FormControl variant="outlined" style={{ marginTop: 8, minWidth: 150}}>
                <InputLabel htmlFor="filled-age-native-simple">Language</InputLabel>
                <Select
                    size={"small"}
                    native
                    value={currentLocale}
                    onChange={(event) => {
                        console.log('event=====>', event.target.value)
                        setCurrentLocale(event.target.value)
                    }}
                    label="Language"
                    inputProps={{
                        name: 'currentLocale',
                        id: 'filled-age-native-simple',
                    }}
                >
                    {localesConfig.languages.map((it) => {
                        return (
                            <option value={it.locale}>{it.name}</option>
                        )
                    })
                    }
                </Select>
            </FormControl>
            <Box display="flex" alignItems={"top"} maxWidth={'1400px'}>
                <Box flex={1} mr={"1em"}>
                    <JsonInput
                        currentLocale={currentLocale}
                        resource={resource}
                        promptData={promptData}
                        variant={"outlined"}
                        source={`json_ld.${currentLocale}`}
                        sourceForPrompt={'json_ld'}
                        label={translate('Json LD')}
                        fullWidth
                        {...props}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <JsonInput
                        currentLocale={currentLocale}
                        resource={resource}
                        promptData={promptData}
                        variant={"outlined"}
                        source={`meta_tags.${currentLocale}`}
                        sourceForPrompt={'meta_tags'}
                        label={translate('Meta tags')}
                        fullWidth
                        {...props}
                    />
                </Box>
            </Box>
            {/*<RAJsonInput*/}
            {/*    source="json_ld"*/}
            {/*    // validate={[required()]}*/}
            {/*    jsonString={false} // Set to true if the value is a string, default: false*/}
            {/*    reactJsonOptions={{*/}
            {/*        // Props passed to react-json-view*/}
            {/*        name: translate('Json LD'),*/}
            {/*        collapsed: false,*/}
            {/*        enableClipboard: true,*/}
            {/*        displayDataTypes: false,*/}
            {/*    }}*/}
            {/*/>*/}

            {/*<RAJsonInput*/}
            {/*    source="meta_tags"*/}
            {/*    // validate={[required()]}*/}
            {/*    jsonString={false} // Set to true if the value is a string, default: false*/}
            {/*    reactJsonOptions={{*/}
            {/*        // Props passed to react-json-view*/}
            {/*        name: translate('Meta tags'),*/}
            {/*        collapsed: false,*/}
            {/*        enableClipboard: true,*/}
            {/*        displayDataTypes: false,*/}
            {/*    }}*/}
            {/*/>*/}

            {/*<TextInput*/}
            {/*            variant={"outlined"}*/}
            {/*            source={'meta_tags'}*/}
            {/*            label={translate('Meta tags')}*/}
            {/*            fullWidth*/}
            {/*        />*/}
        </Box>
    )
}
export default Seo;