//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, ReferenceField,
    TextField,
    useLocale,
    useTranslate,
    // EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {Box, CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../../index.css';
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
//import {exporterPassenger} from "../utils/exporterFile";
import {BooleanFieldComp, DateFieldComp, FilterDataAside} from "../../_common/CompReact";
import CardComp from "../../_common/Asside/CardComp";
import CroppedLongField from "../../_common/CroppedLongField";
//import get from 'lodash/get';
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import MySimpleList from "../../_common/MySimpleList";

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    // const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch/>
                <TrueFalseFilters field={'cashback'} label={'Cashback'}/>
                <FilterDataAside fields={'updated'} name={'update'}/>
                {/*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*/}
                {/*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*/}
                {/*<TrueFalseFilters field={'is_load'} label={'Exported to Xero'}/>*/}
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
            </CardContent>
        </CardComp>
    )
};

const TopFilters = (resource, locale) => {
    let dt = [];
    /*dt.push(<ReferenceInput source="industry_id" reference="dPromptIndustries" alwaysOn>
        <AutocompleteInput
            optionText={`code`}
            resettable emptyText={'All Codes'}/>
    </ReferenceInput>);
    dt.push(<ReferenceInput source="prompt_type_id" reference="dPromptTypes" alwaysOn>
        <AutocompleteInput optionText="code" resettable emptyText={'All Types'}/>
    </ReferenceInput>);*/

    return dt;
}

const ColumnsForList = (resource, locale, classes, isSmall) => {
    /*
    ---cashback: false
    ---discount: "0.00"
    ---discount_conditions_document: null
    ---fix_price: "0.00"
    ---fix_price_currency: null
    id: 1
    ---product: 12
    ---promotion: null
    ---quantity: 1
     */

    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        promotion:
            <ReferenceField
                label={translate('Promotion')}
                source={'promotion'}
                reference={'dPromotion'}
            >
                <TextField source={`${'name'}[${locale}]`}/>
            </ReferenceField>,
        discount_conditions_document:
            <ReferenceField
                label={translate('Discount conditions document')}
                source={'discount_conditions_document'}
                reference={'dDiscountConditionsDocument'}
            >
                <Box display={'grid'}>
                    <TextField source={`document_number`}/>
                    <DateFieldComp source="document_date" textAlign={'center'} showTime={false}
                                locale={'us'} cellClassName={classes.date_st}/>
                </Box>
            </ReferenceField>,
        product:
            <ReferenceField
                label={translate('Product')}
                source={'product'}
                reference={'dProducts'}
            >
                <TextField source={`${'name'}[${locale}]`}/>
            </ReferenceField>,
        quantity: <NumberField source={'quantity'} label={translate('Quantity')}/>,
        discount: <NumberField source={'discount'} label={translate('Discount')}/>,
        fix_price: <NumberField source={'fix_price'} label={translate('Fix price')}/>,
        fix_price_currency:
            <ReferenceField
                label={translate('Fix price currency')}
                source={'fix_price_currency'}
                reference={'dCurrencies'}
            >
                <TextField source={`abbr`}/>
            </ReferenceField>,

        cashback: <BooleanFieldComp source="cashback" label={translate("Cashback")} textAlign={"center"}/>,
        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

export const PromotionalGoodList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                permissions={permissions}
                asideFilter={false}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
            is_bulkActionButtons={true}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}