import DefaultTable from '../DefaultTable/DefaultClass'
import {commonYesNo} from "../../_constants/choices";

class CarBrands extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                /*name: 'brand',
                type: "TextInput",
                alwaysOn: true,
                parameters: {},*/
            }
        ]

        this.filters_aside = [
            /*{
                name: 'show',
                type: "FilterList",
                parameters: ["Y", "N"],
            },*/
            {
                name: 'show',
                type: "DynamicFiltersChoices",
                parameters: {referenceChoice: commonYesNo, name: 'name'},
            },
            {
                name: 'popular',
                type: "DynamicFiltersChoices",
                parameters: {referenceChoice: commonYesNo, name: 'name'},
            },
        ]

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]


        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'brand',
                                type: 'text',
                                show_type: 'TextFieldBold',
                                edit_type: 'TextInput',
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'synonym',
                                type: 'text',
                                show_type: 'TextFieldBold',
                                edit_type: 'TextInput',
                                read_only: false,
                                flex: 1,
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'show',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'SelectChoices',
                                type_ext: {choices: commonYesNo, reference: 'Currencies', width: '3em'},
                                read_only: false
                            },
                            {
                                name: 'popular',
                                type: 'bool',
                                show_type: 'SelectAndSaveChoices',
                                edit_type: 'SelectChoices',
                                type_ext: {choices: commonYesNo, reference: 'Currencies', width: '3em'},
                                read_only: false
                            },
                            {
                                name: 'sort_desktop',
                                type: 'date',
                                // show_type: 'NumberField',
                                show_type: 'EditFieldFromList',
                                type_ext: {width: "50px"},
                                edit_type: 'NumberInput',
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'sort_mobile',
                                type: 'date',
                                // show_type: 'NumberField',
                                show_type: 'EditFieldFromList',
                                type_ext: {width: "50px"},
                                edit_type: 'NumberInput',
                                read_only: false,
                                flex: 1,
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'car_type',
                                type: 'text',
                                edit_type: 'TextInput',
                                show_type: 'TextField',
                                read_only: false
                            },
                            {
                                name: 'url_part',
                                type: 'text',
                                edit_type: 'TextInput',
                                show_type: 'TextField',
                                read_only: false
                            }
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]

    }
}

export default CarBrands