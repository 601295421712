import * as React from 'react';
import {Box} from "@material-ui/core";
import ExcelToJsonConverter from './ExcelToJsonConverter'

export const LoadPrice = props => {

    //const translate = useTranslate();

    return (
        <Box display={'flex'} p={'2em'} style={{width: '100%'}}>
            <ExcelToJsonConverter/>
        </Box>
    )
};