import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, TextField,
    useLocale,
    // TextInput,
    useTranslate,
    // ReferenceInput,
    // AutocompleteInput,
    //EmailField,
    ReferenceField, ReferenceInput, AutocompleteInput,
} from 'react-admin';

// import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
// import {RowForm} from "@react-admin/ra-editable-datagrid";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import useMyStyles from "../../utils/useMyStyles";
// import {universalTopFilters} from "../universalView/universalTopFilters";
// import {CardContent, useMediaQuery} from "@material-ui/core";
import {CardContent, useMediaQuery} from "@material-ui/core";
import CardComp from "../../_common/Asside/CardComp";
import {
    BooleanFieldComp, CustomerInfo,
    DateFieldComp,
    FilterDataAside,
    //NumberInputFloatComp,
    //UserInfo,
} from "../../_common/CompReact";
// import {validateFirstName, validateReq} from "../utils/components/validateTable";
// import {Box} from "@mui/material";
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import {Box} from "@mui/material";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import CroppedLongField from "../../_common/CroppedLongField";
import MySimpleList from "../../_common/MySimpleList";

const universalTopFilters = (resource, locale) => {
    let dt = [];
    dt.push(<ReferenceInput source="user_country" reference="Countries" alwaysOn>
        <AutocompleteInput
            optionText={`name[${locale}]`}
            resettable emptyText={'All'}/>
    </ReferenceInput>);
    dt.push(<ReferenceInput source="discount_group" reference="DiscountGroups" alwaysOn>
        <AutocompleteInput
            optionText={`name[${locale}]`}
            resettable emptyText={'All'}/>
    </ReferenceInput>);
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                <FilterLiveSearch/>
                <TrueFalseFilters field={'is_new_customer'} label={'Is New Customer'}/>
                <FilterDataAside fields={'updated'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};

/*
const ListEditForm = (props) => {
    // const {classes, locale} = props;
    const {classes} = props;
    const translate = useTranslate();
    return (
        <RowForm {...props}>
            <NumberField source="id" headerClassName={classes.headerShortWidth}/>
            <TextInput source={`prompt`} label={translate('Prompt')} validate={validateFirstName} fullWidth/>
            <ReferenceInput source="industry_id" reference="dPromptIndustries" label={translate('Industry')}
                            validate={validateReq}>
                <AutocompleteInput resettable optionText="code" fullWidth/>
            </ReferenceInput>
            <ReferenceInput source="prompt_type_id" reference="dPromptTypes" label={translate('Type')}
                            validate={validateReq}>
                <AutocompleteInput resettable optionText="code" fullWidth/>
            </ReferenceInput>
            {/!*<DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'} showTime={false}
                           locale={'us'} cellClassName={classes.date_st}/>
            <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'} showTime={false}
                           locale={'us'} cellClassName={classes.date_st}/>*!/}
        </RowForm>
    )
};
*/

/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/

const ColumnsForList = (resource, locale, classes, isSmall) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        name: <CustomerInfo label={translate('Name')} name={'name'} src={'avatar'} sortBy={'name'}/>,

        is_new_customer: <BooleanFieldComp source="is_new_customer" label={translate("New Customer")}
                                           textAlign={"center"}/>,

        phone: <TextField source={`phone`} label={translate('Phone')}/>,
        user_country:
            <ReferenceField source="user_country" reference="Countries" link={false}>
                <TextField source={`name[${locale}]`}/>
            </ReferenceField>,
        address: <TextField source={`address[${locale}]`} label={translate('Address')}/>,
        discount_group:
            <ReferenceField source="discount_group" reference="DiscountGroups" link={false}
                            label={'Discount Groups'}>
                <TextField source={`name[${locale}]`}/>
            </ReferenceField>,

        discount: <TextField source={`discount`} label={translate('Discount')}/>,

        credit_limit_sum:
            <Box flex={'flex'} label={translate('Credit Limit Sum')}>
                <TextField source={`credit_limit_sum`} label={translate('Credit Limit Sum')}/>{' '}
                <ReferenceField source="credit_limit_currency" reference="Currencies" link={false}>
                    <TextField source="abbr"/>
                </ReferenceField>
            </Box>,

        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                                locale={'us'} cellClassName={classes.date_st} showTime={true}/>,
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

export const Customers = ({permissions, ...props}) => {
    const classes = useMyStyles();
    const {resource} = props;

    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });
    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: ["nb_views"],
    });

    // document.title = `iAM-Trade : Customers`
    return (
        <ListComponentAdmin
            {...props}
            //hasCreate={true}
            title={''}
            actions={<TableActions
                preference={`${resource}${"list.columns"}`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                permissions={permissions}
                //select={false}
                create={true}
                filter={true}
            />}
            is_bulkActionButtons={true}
            filters={universalTopFilters(resource, locale)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
};