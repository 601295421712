/*[out:json][timeout:25];
// собираем данные о районах Харьковской области
area["ISO3166-2"="UA-63"]->.searchArea;
(
// выбираем районы в пределах области
relation["admin_level"="6"](area.searchArea);
);
// выводим результат
out body;
>;
//out skel qt;
//out tags;*/

//area["ISO3166-2"="UA-59"]->.searchArea;

/*[out:json][timeout:50];
// Получаем данные о населенных пунктах Чугуевского района
area["name"="Чугуївський район"]->.searchArea;
(
// Выбираем населенные пункты в пределах района
node["place"](area.searchArea);
way["place"](area.searchArea);
relation["place"](area.searchArea);
);
// Выводим результаты
out body;
>;
//out skel qt;*/

// ------------Улиці-----------------------------------
/*[out:json][timeout:100];
// Получаем данные о границах города Волчанск
area["name"="Вовчанськ"]->.vovchansk;
(
// Выбираем все улицы в пределах города Волчанск
way["highway"]["name"](area.vovchansk);
relation["highway"]["name"](area.vovchansk);
);
// Выводим результаты
out body;
>;
//out center;
//out skel qt;*/

/*
[out:json][timeout:25];
{{geocodeArea:Черкаська Лозова}}->.searchArea;
(
way["highway"](area.searchArea);
relation["highway"](area.searchArea);
);
out body;
>;
*/

/*
УЛИЦЫ
[out:json][timeout:25];
area["name"="Черкаська Лозова"]->.searchArea;
(
way(area.searchArea)["highway"]["name"];
relation(area.searchArea)["highway"]["name"];
);
out body;
>;
*/

/*
[out:json][timeout:25];
// собираем данные о городах и маленьких городах в Харьковской области
area["ISO3166-2"="UA-63"]->.searchArea;
(
node["place"="city"](area.searchArea);
way["place"="city"](area.searchArea);
relation["place"="city"](area.searchArea);
node["place"="town"](area.searchArea);
way["place"="town"](area.searchArea);
relation["place"="town"](area.searchArea);
);
// выводим результаты
out body;
>;
*/

// --------------------------------------
export function getRequestOverpass(type, iso, name, nameEn) {
    let str = ''
    switch (type) {
        case 'getAreas':
            str = requestOverpass.getAreas
            break
        case 'getRegions':
            str = requestOverpass.getRegions.replace('###ISO###', iso)
            break
        case 'getPointsFromArea':
            str = requestOverpass.getPointsFromArea.replace('###ISO###', iso)
            break
        case 'getPoints':
            str = requestOverpass.getPoints.replace('###NAME###', name)
            break
        case 'getStreets':
            str = requestOverpass.getStreets.replace('###NAME###', name)
            // str = str.replaceAll('###NAME_EN###', nameEn)
            //console.log(name, 'getStreets=================>', str)
            break
        default:
            break
    }
    return str

}

/*
export const requestOverpass = {
    getAreas: '[out:json][timeout:25];\n' +
        '    // собираем данные об областях\n' +
        'area["ISO3166-1"="UA"]->.ukraine;\n' +
        '(\n' +
        '  relation["admin_level"="4"](area.ukraine);\n' +
        ');\n' +
        'out tags;',
    getRegions: '[out:json][timeout:25];\n' +
        '    // собираем данные о районах Харьковской области\n' +
        '    area["ISO3166-2"="###ISO###"]->.searchArea;\n' +
        '    (\n' +
        '    // выбираем районы в пределах области\n' +
        '    relation["admin_level"="6"](area.searchArea);\n' +
        '    );\n' +
        '    // выводим результат\n' +
        '    out body;\n' +
        '    >;\n' +
        '    //out skel qt;\n' +
        '    //out tags;',
    getPoints: '[out:json][timeout:50];\n' +
        '    // Получаем данные о населенных пунктах Чугуевского района\n' +
        '    area["name"="###NAME###"]->.searchArea;\n' +
        '    (\n' +
        '    // Выбираем населенные пункты в пределах района\n' +
        '    node["place"](area.searchArea);\n' +
        '    way["place"](area.searchArea);\n' +
        '    relation["place"](area.searchArea);\n' +
        '    );\n' +
        '    // Выводим результаты\n' +
        '    out body;\n' +
        '    >;\n' +
        '    //out skel qt;',
    getPointsFromArea: '[out:json][timeout:25];\n' +
        '// собираем данные о городах и маленьких городах в Харьковской области\n' +
        'area["ISO3166-2"="###ISO###"]->.searchArea;\n' +
        '(\n' +
        'node["place"="city"](area.searchArea);\n' +
        'way["place"="city"](area.searchArea);\n' +
        'relation["place"="city"](area.searchArea);\n' +
        'node["place"="town"](area.searchArea);\n' +
        'way["place"="town"](area.searchArea);\n' +
        'relation["place"="town"](area.searchArea);\n' +
        ');\n' +
        '// выводим результаты\n' +
        'out body;\n' +
        '>;',
    getStreets: '[out:json][timeout:25];\n' +
        'area["name"="###NAME###"]->.searchArea;\n' +
        '(\n' +
        'way(area.searchArea)["highway"]["name"];\n' +
        'relation(area.searchArea)["highway"]["name"];\n' +
        ');\n' +
        'out body;\n' +
        '>;',
    getStreets__1: // geocodeArea- отдельній запрос
        '[out:json][timeout:25];\n' +
        '{{geocodeArea: 1447647}}->.searchArea;\n' +
        '(\n' +
        'way["highway"](area.searchArea);\n' +
        'relation["highway"](area.searchArea);\n' +
        ');\n' +
        'out body;\n' +
        '>;',
    getStreets__2: '[out:json][timeout:100];\n' +
        '    // Получаем данные о границах города Волчанск\n' +
        '    area["name"="###NAME###"]->.###NAME_EN###;\n' +
        '    (\n' +
        '    // Выбираем все улицы в пределах города Волчанск\n' +
        '    way["highway"]["name"](area.###NAME_EN###);\n' +
        '    relation["highway"]["name"](area.###NAME_EN###);\n' +
        '    );\n' +
        '    // Выводим результаты\n' +
        '    out body;\n' +
        '    >;\n' +
        '    //out center;\n' +
        '    //out skel qt;'
}*/

export const requestOverpass = {
    getAreas: '[out:json][timeout:25];\n' +
        'area["ISO3166-1"="UA"]->.ukraine;\n' +
        '(\n' +
        '  relation["admin_level"="4"](area.ukraine);\n' +
        ');\n' +
        'out tags;',
    getRegions: '[out:json][timeout:25];\n' +
        '    area["ISO3166-2"="###ISO###"]->.searchArea;\n' +
        '    (\n' +
        '    relation["admin_level"="6"](area.searchArea);\n' +
        '    );\n' +
        '    out body;\n' +
        '    >;',
    getPoints: '[out:json][timeout:50];\n' +
        '    area["name"="###NAME###"]->.searchArea;\n' +
        '    (\n' +
        '    node["place"](area.searchArea);\n' +
        '    way["place"](area.searchArea);\n' +
        '    relation["place"](area.searchArea);\n' +
        '    );\n' +
        '    out body;\n' +
        '    >;',
    getPointsFromArea: '[out:json][timeout:25];\n' +
        'area["ISO3166-2"="###ISO###"]->.searchArea;\n' +
        '(\n' +
        'node["place"="city"](area.searchArea);\n' +
        'way["place"="city"](area.searchArea);\n' +
        'relation["place"="city"](area.searchArea);\n' +
        'node["place"="town"](area.searchArea);\n' +
        'way["place"="town"](area.searchArea);\n' +
        'relation["place"="town"](area.searchArea);\n' +
        ');\n' +
        'out body;\n' +
        '>;',
    getStreets: '[out:json][timeout:25];\n' +
        'area["name"="###NAME###"]->.searchArea;\n' +
        '(\n' +
        'way(area.searchArea)["highway"]["name"];\n' +
        'relation(area.searchArea)["highway"]["name"];\n' +
        ');\n' +
        'out body;\n' +
        '>;',
    getStreets__1: // geocodeArea- отдельній запрос
        '[out:json][timeout:25];\n' +
        '{{geocodeArea: 1447647}}->.searchArea;\n' +
        '(\n' +
        'way["highway"](area.searchArea);\n' +
        'relation["highway"](area.searchArea);\n' +
        ');\n' +
        'out body;\n' +
        '>;',
    getStreets__2: '[out:json][timeout:100];\n' +
        '    // Получаем данные о границах города Волчанск\n' +
        '    area["name"="###NAME###"]->.###NAME_EN###;\n' +
        '    (\n' +
        '    // Выбираем все улицы в пределах города Волчанск\n' +
        '    way["highway"]["name"](area.###NAME_EN###);\n' +
        '    relation["highway"]["name"](area.###NAME_EN###);\n' +
        '    );\n' +
        '    // Выводим результаты\n' +
        '    out body;\n' +
        '    >;\n' +
        '    //out center;\n' +
        '    //out skel qt;'
}