import * as React from 'react';
import {Box, Button} from '@mui/material';
import {Avatar} from "@material-ui/core";
import CustomerIcon from '@material-ui/icons/PersonAdd';
import {Link} from 'react-router-dom';
import {
    ListBase,
    //WithListContext,
    SimpleList, TextField,
    useTranslate,
} from 'react-admin';
import {subDays} from 'date-fns';

import CardWithIcon from './CardWithIcon';
import { stringify } from 'query-string';
import {Total} from "./DashboardInfo";


const OriNewCustomers = () => {
    const translate = useTranslate();

    const aMonthAgo = subDays(new Date(), 30);
    aMonthAgo.setDate(aMonthAgo.getDate() - 30);
    aMonthAgo.setHours(0);
    aMonthAgo.setMinutes(0);
    aMonthAgo.setSeconds(0);
    aMonthAgo.setMilliseconds(0);

    return (
        <ListBase
            resource="dCustomers"
            basePath={'/dCustomers'}
            filter={{
                is_new_customer: true,
                //update_gte: aMonthAgo.toISOString(),
            }}
            sort={{field: 'updated', order: 'DESC'}}
            perPage={24}
            disableSyncWithLocation
        >
            <CardWithIcon
                to={{
                    pathname: '/dCustomers',
                    search: stringify({
                        filter: JSON.stringify({is_new_customer: true}),
                    }),
                }}
                //to="/#/dCustomers"
                icon={CustomerIcon}
                title={translate('New Customers')}
                subtitle={
                    /*<WithListContext render={({ total }) => <>{total}</>} />*/
                    <Total/>
                }
            >
                <SimpleList
                    primaryText={<TextField source="name"/>}
                    secondaryText={<TextField source="phone"/>}
                    leftAvatar={customer => (
                        <Avatar
                            variant="rounded"
                            src={`${customer.avatar}?size=32x32`}
                            alt={`${customer.name}`}
                            sx={{
                                bgcolor: 'background.paper',
                            }}
                        />
                    )}
                />
                <Box flexGrow={1}>&nbsp;</Box>
                <Button
                    sx={{borderRadius: 0}}
                    component={Link}
                    to="/dCustomers"
                    size="small"
                    color="primary"
                >
                    <Box p={1} sx={{color: 'primary.main'}}>
                        {translate('All Customers')}
                    </Box>
                </Button>
            </CardWithIcon>
        </ListBase>
    );
};

export default OriNewCustomers;
