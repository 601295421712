import * as React from 'react';
import {
    NumberField, TextField,
    useLocale,
    // TextInput,
    useTranslate,
} from 'react-admin';

// import {EditableDatagrid, RowForm} from "@react-admin/ra-editable-datagrid";
// import {RowForm} from "@react-admin/ra-editable-datagrid";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import useMyStyles from "../../utils/useMyStyles";
// import {universalTopFilters} from "../universalView/universalTopFilters";
// import {CardContent, useMediaQuery} from "@material-ui/core";
import {CardContent, useMediaQuery} from "@material-ui/core";
import CardComp from "../../_common/Asside/CardComp";
import {
    DateFieldComp, FilterDataAside,
    //NumberInputFloatComp,
    //UserInfo,
} from "../../_common/CompReact";
// import {validateFirstName, validateReq} from "../utils/components/validateTable";
// import {Box} from "@mui/material";
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import CroppedLongField from "../../_common/CroppedLongField";
import MySimpleList from "../../_common/MySimpleList";

const universalTopFilters = (resource, locale) => {
    let dt = [];
    /*dt.push(<ReferenceInput source="parent" reference="dMenuItems" alwaysOn>
        <AutocompleteInput
            optionText={`name[${locale}]`}
            resettable emptyText={'All'}/>
    </ReferenceInput>);*/
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch/>
                <TrueFalseFilters field={'archive'} label={'Archive'}/>
                <TrueFalseFilters field={'show_in_header'} label={'Show in header'}/>
                <TrueFalseFilters field={'show_in_footer'} label={'Show in footer'}/>*/}
                <FilterDataAside fields={'updated'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};

/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/

/*
additional: {}
description: {en: ""}
---id: 4
---item_code_in_accounting_system: "1827"
---name: {en: "Ski Length"}
---size_system: "EU"
---source: "admin"
---updated: "2024-01-11T15:41:15.889537"
 */
const ColumnsForList = (resource, locale, classes, isSmall) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        // prompt: <TextFieldBold source={`prompt`} label={translate('Prompt')} cellClassName={classes.prompt_name}/>,
        name: <TextField source={`name[${locale}]`} label={translate('Name')}/>,
        size_system: <TextField source={`size_system`} label={translate('Size System')}/>,
        item_code_in_accounting_system: <TextField source={`item_code_in_accounting_system`}
                                                   label={translate('Item code in accounting system')}/>,


        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

export const SizeChartList = props => {
    const classes = useMyStyles();
    const {resource} = props;

    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    })

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });
    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: ["nb_views"],
    });

    return (
        <ListComponentAdmin
            {...props}
            //hasCreate={true}
            title={''}
            actions={<TableActions
                preference={`${resource}${"list.columns"}`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                //select={false}
                create={true}
                filter={true}
            />}
            is_bulkActionButtons={true}
            filters={universalTopFilters(resource, locale, isSmall)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
};