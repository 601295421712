import * as React from 'react';
import {
    BooleanInput,
    ReferenceInput,
    AutocompleteInput,
    TabbedForm,
    FormTab,
    TextInput, useTranslate, SelectInput, NumberInput,
    FormDataConsumer,
} from 'react-admin';

import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
import {
    DateFieldComp,
    DateInputComp,
    DisabledField,
    NumberInputFloatComp,
    TextFieldButton
} from "../../_common/CompReact";
//import useMyStyles from "../utils/useMyStyles";
import {useSelector} from "react-redux";
import {useForm} from "react-final-form";
import {useEffect} from "react";
// import {config} from "../utils/config";
import {validateFirstName, validateReq} from "../../_constants/validateTable";
import {choicesDefaultRelation, choicesRuleType} from "../../_constants/choices";
import {useState} from "react";
import BrandsByRule from "./BrandsByRule";
import ProductsByRule from "./ProductsByRule";
//import useMyStyles from "../utils/useMyStyles";
import DiscountByRule from "./DiscountByRule";
import DiscountRelationShip from "./DiscountRelationShip";
import ComplicatedDiscountByRule2 from "./ComplicatedDiscountByRule2";
import CustomerDiscountByRule from "./CustomerDiscountByRule";
import {PriceOfWeek} from "./PriceOfWeekTab";
//import {validateReq} from "../utils/components/validateTable";
//import get from "lodash/get";
/*
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
*/
function getTbl(rule_type, slug) {
    let tbl = '';
    switch (rule_type) {
        case 'customer':
            switch (slug) {
                case 'customer.customers':
                    tbl = 'Customer Discount By Rule';
                    break;
                default:
                    tbl = 'Discount By Rule';
            }
            break;
        case 'product':
            switch (slug) {
                case 'product.products':
                    tbl = 'Products By Rule';
                    break;
                case 'product.brands':
                    tbl = 'Brands By Rule';
                    break;
                default:
                    tbl = 'Discount By Rule';
            }
            break;
        case 'order':
            switch (slug) {
                case 'order.price_of_week':
                    tbl = 'Price Of Week';
                    break;
                case 'order.discount_from_sum_of_order':
                    tbl = 'Products By Rule';
                    break;
                case 'order.discount_from_quantity_of_goods':
                    tbl = 'Products By Rule';
                    break;
                default:
                    tbl = '';
            }
            break;
        case 'complicated_rule':
            tbl = 'Complicated Discount By Rule';
            break;
        case 'bonus':
            tbl = 'Products By Rule';
            break;
        default:
            tbl = '';
    }
    return tbl;

}

const BlockEditCreate = props => {

    const {formData, setFormDataAll} = props;
    const translate = useTranslate();
    const formEdit = useForm();
    //const classes = useMyStyles();

    const [ruleType, setRuleType] = useState('start');
    const [tableForRule, setTableForRule] = useState('');
    const [slugForRule, setSlugForRule] = useState('');

    let discountProperty = useSelector((state) => state.admin.resources.discountProperty.data)
    // console.log('discountProperty=======>', Object.entries(discountProperty))
    //console.log('discountProperty=======>', Object.values(discountProperty))
    // const {id, customer_id, total_amount, is_load = false, card_id = null} = record;
    useEffect(() => {
        setFormDataAll(formData);
    }, [formData]);// eslint-disable-line

    useEffect(() => {
        if (ruleType === 'start') setRuleType('start2');
        else formEdit.change('discount_property_id', null);

        if (formData.rule_type) {
            setRuleType(formData.rule_type);
            //formEdit.change('card_id', parseInt(config.bookmanCardID))
        } else {
            setRuleType('start2')
        }
    }, [formData.rule_type]);// eslint-disable-line

    useEffect(() => {
        if (formData.discount_property_id && discountProperty) {
            let rez = Object.values(discountProperty).find(x => x.id === formData.discount_property_id)
            if (rez) {
                setTableForRule(getTbl(formData.rule_type, rez.slug))
                setSlugForRule(rez.slug)
            } else {

                //setTableForRule('');
            }
        } else {
            setTableForRule('');
            setSlugForRule('')
        }
        if (formData.rule_type !== undefined && formData.rule_type === 'complicated_rule') {
            setTableForRule(getTbl(formData.rule_type, ''))
            setSlugForRule('')
        }


    }, [formData.discount_property_id, discountProperty]);// eslint-disable-line

    //console.log('slugForRule=============>', slugForRule)
    return (

        <Box p="1em" fullWidth>

            <Box display="flex" alignItems={"center"}>
                <Box flex={1} mr={'2em'}>
                    {/**/}
                    <TextInput source={`name`} label={translate('Name')} validate={validateFirstName}
                               fullWidth/>
                </Box>
                <Box flex={1} mr={'2em'} display={'grid'}>
                    <BooleanInput source="active" label={translate('Active')} defaultValue={false}/>
                    <BooleanInput source="last_rule" style={{marginTop: -32}}
                                  label={translate('Last Rule')} defaultValue={false}/>
                </Box>
                {formData.id
                    ?
                    <Box flex={1} mr={'2em'} mb={'20px'}>
                        <DisabledField type={'text'} text={formData.rule_type} label={translate('Rule Type')}/>
                    </Box>
                    :
                    <Box flex={1} mr={'2em'}>
                        <SelectInput source={'rule_type'} choices={choicesRuleType}
                                     label={translate('Rule Type')}
                                     resettable
                                     validate={validateReq} fullWidth/>
                    </Box>
                }
                {formData.rule_type !== "complicated_rule"
                    ?
                    formData.id
                        ?

                        <Box flex={1} mb={'20px'}>
                            <DisabledField type={'field'} idField={formData.discount_property_id}
                                           resourceField={'discountProperty'} field={'name'}
                                           label={translate('Discount Property')}/>
                        </Box>
                        :
                        <Box flex={1}>
                            <ReferenceInput source="discount_property_id" reference="discountProperty"
                                            filter={{rule_type: ruleType}}
                                            label={translate('Discount property')}
                                            resettable
                                            validate={validateReq}>
                                <AutocompleteInput resettable optionText="name" fullWidth/>
                            </ReferenceInput>
                        </Box>
                    : <Box flex={1}/>
                }
            </Box>

            <Box display="flex" alignItems={"center"}>
                <Box flex={1} mr={'2em'}>
                    <DateInputComp source={"date_from"} label={translate("Date From")}
                                   fullWidth/>
                </Box>
                <Box flex={1} mr={'2em'}>
                    <DateInputComp source={"date_to"} label={translate("Date To")}
                                   fullWidth/>
                </Box>
                <Box flex={1} mr={'2em'}>
                    <SelectInput source={'default_relation'} choices={choicesDefaultRelation} resettable
                                 label={translate('Default Relation')} fullWidth/>
                </Box>
                <Box flex={1}>
                    <NumberInput source={'index'} label={translate('Index')} validate={validateReq}
                                 fullWidth/>
                </Box>
            </Box>

            {formData.rule_type === 'order' &&
                slugForRule !== 'order.discount_from_quantity_of_goods' &&
                <Box display="flex" alignItems={"center"}>
                    <Box flex={1} mr={'2em'}>
                        <NumberInputFloatComp
                            source="order_sum"
                            label={translate(`${
                                slugForRule === 'order.price_of_week' //'order.discount_from_sum_of_order' 
                                    ? 'Min.amount of non-prom. item'
                                    : 'Min.order amount'
                            }`)}
                            validate={validateReq}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} mr={'2em'}>
                        <ReferenceInput source="order_currency_id" reference="currency_z"
                                        defaultValue={980}
                                        label={translate('Currency')}
                                        resettable
                                        validate={validateReq}>
                            {/*<SelectInput  fullWidth/>*/}
                            <AutocompleteInput resettable optionText="name" fullWidth/>
                        </ReferenceInput>
                    </Box>
                    <Box flex={1} mr={'2em'}>
                        <BooleanInput source="for_all_products" label={translate('For All Products')}
                                      defaultValue={false}/>
                    </Box>
                    <Box flex={1}>
                        <NumberInput source={'default_discount'} label={translate('Default Discount')}
                                     validate={validateReq} fullWidth/>
                    </Box>
                </Box>
            }

            {formData.id
                ?
                <>
                    <Typography
                        variant="h6">{`${translate('Discount Relation Ship')} (current index ${formData.index})`}</Typography>
                    <Box display="grid" alignItems={"center"}>
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                return (
                                    <DiscountRelationShip
                                        {...props}
                                        //customerFormId={customerFormId}
                                        current_discount_rule_id={formData.id}
                                        tableForRule={'Discount Relation Ship'}
                                        formData={formData}
                                    />
                                )
                            }}
                        </FormDataConsumer>
                    </Box>
                    <Typography variant="h6">{`${translate(tableForRule)}`}</Typography>
                    <Box display="grid" alignItems={"center"}>
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                //const {customer_id: customerFormId} = formData;
                                if (tableForRule === 'Brands By Rule')
                                    return (
                                        <BrandsByRule
                                            {...props}
                                            slug={slugForRule}
                                            //customerFormId={customerFormId}
                                            discount_rule_id={formData.id}
                                            tableForRule={tableForRule}
                                            formData={formData}
                                        />
                                    )
                                else if (tableForRule === 'Products By Rule')
                                    return (
                                        <ProductsByRule
                                            {...props}
                                            //customerFormId={customerFormId}
                                            slug={slugForRule}
                                            discount_rule_id={formData.id}
                                            rule_type={formData.rule_type}
                                            tableForRule={tableForRule}
                                            formData={formData}
                                        />
                                    )
                                else if (tableForRule === 'Discount By Rule')
                                    return (
                                        <DiscountByRule
                                            {...props}
                                            //customerFormId={customerFormId}
                                            discount_rule_id={formData.id}
                                            rule_type={formData.rule_type}
                                            tableForRule={tableForRule}
                                            formData={formData}
                                        />
                                    )
                                else if (tableForRule === 'Customer Discount By Rule')
                                    return (
                                        <CustomerDiscountByRule
                                            {...props}
                                            //customerFormId={customerFormId}
                                            slug={slugForRule}
                                            discount_rule_id={formData.id}
                                            //rule_type={formData.rule_type}
                                            tableForRule={tableForRule}
                                            formData={formData}
                                        />
                                    )
                                else if (tableForRule === 'Complicated Discount By Rule')
                                    return (
                                        <ComplicatedDiscountByRule2
                                            {...props}
                                            //customerFormId={customerFormId}
                                            discount_rule_id={formData.id}
                                            tableForRule={tableForRule}
                                            formData={formData}
                                        />
                                    )
                                else if (tableForRule === 'Price Of Week')
                                    return (
                                        <PriceOfWeek
                                            {...props}
                                            rule_type={formData.rule_type}
                                            slug={slugForRule}
                                            discount_rule_id={formData.id}
                                            tableForRule={tableForRule}
                                            formData={formData}
                                        />
                                    )
                                else return null;
                            }}
                        </FormDataConsumer>
                    </Box>
                </>
                :
                <Typography variant="subtitle1" color={"error"}>
                    {translate('Editing of related tables will be available after record creation')}
                </Typography>
            }
        </Box>
    )
}
export const DiscountRulesCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    // const classes = useMyStyles();
    const {record} = props;
    const {id, is_load = false} = record;
    const translate = useTranslate();

    const [formDataAll, setFormDataAll] = useState({});

    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)

    /*
        const [countBooking, setCountBooking] = React.useState(0);
        const [arBooking, setArBooking] = React.useState([]);
        const getBooking = async (customer_id) => {
            const url = apiPathConfig.getApiUrl()
            let report_url = `booking/admin/booking`;
            let filter = `?customer_id=${customer_id}&is_invoiced=false&is_paid=false`;
            try {
                const response = await httpClient(`${url}${report_url}${filter}`);
                console.log(response);
                if (response.status >= 200 &&  response.status <= 300) {
                    setCountBooking(response.json.count);
                    setArBooking(response.json.results);
                    } else {

                    }
            } catch (err) {
                console.log('err=======>', err);
            } finally {
                //console.log('finally=======>', 'finally');
            }
        }
        useEffect(() => {
            getBooking(customer_id);
        }, []);// eslint-disable-line
    */

    console.log('formDataAll=======>', formDataAll);
    return (
        <div>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box mr="2.5em">
                            <Typography variant="subtitle2" gutterBottom>{translate('Name')}</Typography>
                            <TextFieldButton
                                //itsOriText={record => (`${record.last_name}`)}
                                //copyClipBoard={true}
                                source="name"
                                //source2="f_name"
                                //source3="l_name"
                                backgroundColor={'#eee'}
                            />
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{translate('Created')}{`: `}
                                <DateFieldComp source="created_at" variant="subtitle2" fullWidth/>
                            </div>
                            <div>{translate('Updated')}{`: `}
                                <DateFieldComp source="updated_at" variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <TabbedForm
                {...props}
                /*toolbar={<PostEditDeleteActions goBack={true} saveAndClose={'/invoice'} reload={true}/>}*/
                toolbar={<PostEditDeleteActions goBack={true} saveAndClose={'/discount_rules'} isSave={!is_load}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate("Common")}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            return (
                                <BlockEditCreate formData={formData} {...props} setFormDataAll={setFormDataAll}/>
                            )
                        }}
                    </FormDataConsumer>
                </FormTab>

                {/*{id &&
                    <FormTab label="History">
                        <HistoryListForFilter {...props} table={'invoice'} invoice_id={id} fullWidth/>
                    </FormTab>
                }*/
                }
            </TabbedForm>
            {/*<ComplicatedDiscountByRule
                {...props}
                //customerFormId={customerFormId}
                discount_rule_id={formDataAll.id}
                tableForRule={'ComplicatedDiscountByRule'}
                formData={formDataAll}
            />*/}
        </div>

    )
};

export default DiscountRulesCreate
