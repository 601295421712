import PermIdentity from '@material-ui/icons/PermIdentity';
import {PhoneMessenger} from "./PhoneMessenger";
// import {UniversalEdit} from "../universalView/UniversalEdit";
// import {UniversalCreate} from "../universalView/UniversalCreate";

// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: PhoneMessenger,
    // create: UniversalCreate,
    // edit: UniversalEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;