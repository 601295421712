import PermIdentity from '@material-ui/icons/PermIdentity';
import {ManagerList} from "./ManagerList";
import {ManagerCreate} from "./ManagerCreate";
import {ManagerEdit} from "./ManagerEdit";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: ManagerList,
    create: ManagerCreate,
    edit: ManagerEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;