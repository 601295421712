import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    useTranslate, TextInput, BooleanInput,
    NumberInput,
} from 'react-admin';
import {Box, useMediaQuery} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";

import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
import {FeatureTypesRelation} from "./FeatureTypesRelation";
import {validateReq} from "../../_constants/validateTable";
import FootherEdit from "../../_common/Headers/FootherEdit";
import SectionNameInEdit from "../../_common/Headers/SectionNameInEdit";


const BlockEdit = props => {
    const translate = useTranslate();
    const {related_id, isSmall} = props
    // const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>

            <Box style={{display: isSmall ? 'grid' : 'flex'}} display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'name'}
                        label={translate('Name')}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <TextInput
                        validate={validateReq}
                        variant={"outlined"}
                        source={'url'}
                        label={translate('Url')}
                        fullWidth
                    />
                </Box>
            </Box>

            <SectionNameInEdit name={'Display Options'}/>

            <Box style={{display: isSmall ? 'grid' : 'flex'}} alignItems={"center"}>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source={'required'}
                                  label={translate('Required')}
                                  defaultValue={false} fullWidth/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source={'is_pricing'}
                                  label={translate('Is pricing')}
                                  defaultValue={false} fullWidth/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source={'many_values'}
                                  label={translate('Many values')}
                                  defaultValue={false} fullWidth/>
                </Box>
                <Box flex={1} alignItems={"center"}>
                </Box>
            </Box>

            <Box style={{display: isSmall ? 'grid' : 'flex'}} alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source={'is_hide_in_filters_list'}
                                  label={translate('Hide in filters list')}
                                  defaultValue={false} fullWidth/>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <NumberInput
                        validate={validateReq}
                        variant={"outlined"}
                        source={'sort_filter_listing_page'}
                        label={translate('Order in listing')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput source={'is_hide_on_product_page'}
                                  label={translate('Hide on product page')}
                                  defaultValue={false} fullWidth/>
                </Box>
                <Box flex={1}>
                    <NumberInput
                        validate={validateReq}
                        variant={"outlined"}
                        source={'sort_product_page'}
                        label={translate('Order in product page')}
                        fullWidth
                    />
                </Box>

                {/*<Box flex={1}>
                    <BooleanInput source={'required'}
                                  label={translate('Required')}
                                  defaultValue={false} fullWidth/>
                </Box>*/}
            </Box>

            {related_id &&
                <FeatureTypesRelation
                    {...props}
                    related_id={related_id}
                />
            }
        </Box>
    )
}
export const FeatureTypeEditCreate = props => {//Не работает

    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });

    return (
        <Box>

            <TabbedForm
                {...props}
                //initialValues={{source: localStorage.getItem('username')}}
                defaultValue={{source: localStorage.getItem('username')}}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        related_id={id}
                        isSmall={isSmall}
                        //promptData={promptData}
                    />
                </FormTab>
            </TabbedForm>

            {id && <FootherEdit source={record.source}/>}

        </Box>
    )
};

export default FeatureTypeEditCreate
