import PermIdentity from '@material-ui/icons/PermIdentity';
import {CustomersList} from "./CustomersList";
import {CustomersCreate} from "./CustomersCreate";
import {CustomersEdit} from "./CustomersEdit";

// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: CustomersList,
    create: CustomersCreate,
    edit: CustomersEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;