import PermIdentity from '@material-ui/icons/PermIdentity';
import {ColorCreate} from "./ColorCreate";
import {ColorEdit} from "./ColorEdit";
import {ColorList} from "./ColorList";


// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: ColorList,
    create: ColorCreate,
    edit: ColorEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;