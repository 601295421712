import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    //AutocompleteInput,
    //ReferenceInput,
    NumberField, useTranslate, useLocale,
    ReferenceInput, AutocompleteInput, ReferenceField,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp, NumberInputFloatComp} from "../../_common/CompReact";

import {useSelectedColumns} from "@react-admin/ra-preferences";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
// import {useMemo} from "react";
//import {FloatNumber} from "../_common/utils";
// import {useForm} from "react-final-form";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;

    return data;
}
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {product_id} = props;
    const translate = useTranslate();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    //product_related: product_id,
                    product: product_id,
                    source2: localStorage.getItem('username'),
                }}
                transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>,

                <ReferenceInput
                    label={translate('Customer')}
                    source={'customer'}
                    reference={'dCustomers'}
                    filter={{product: product_id}}
                    //validate={validateReq}
                >
                    <AutocompleteInput optionText={`${'name'}`} resettable fullWidth/>
                </ReferenceInput>

                <NumberInputFloatComp source="fix_price" label={translate('Fix price')}/>

                <ReferenceInput
                    label={translate('Currency')}
                    source={'fix_price_currency'}
                    reference={'dCurrencies'}
                    //filter={{country: id}}
                >
                    <AutocompleteInput optionText={`abbr`} resettable fullWidth/>
                </ReferenceInput>,

                <NumberInputFloatComp source="value" label={translate('Value')} fullWidth/>
            </RowForm>
        </>
    )
};

const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,

        customer: /*<NumberField source="variant" label={translate('Variant')}/>,*/
            <ReferenceField source="customer" reference="dCustomers"
                            label={translate('Customer')}
            >
                <TextField source={`${'name'}`}/>
            </ReferenceField>,

        fix_price: <TextField source="fix_price" label={translate('Fix price')}/>,
        fix_price_currency:
            <ReferenceField
                label={translate('Currency')}
                source={'fix_price_currency'}
                reference={'dCurrencies'}
            >
                <TextField source={`${'abbr'}`}/>
            </ReferenceField>,

        value: <TextField source="value" label={translate('Value')}/>,

        source: <TextField source="source" label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                                cellClassName={classes.date_st} showTime={true}/>,

    }
    return columns;
};

const PersonalDiscountsByProduct = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const {product_id, source} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'dPersonalDiscountsByProduct'}list.columns`,
        columns: ColumnsForList('dPersonalDiscountsByProduct', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Personal Discounts By Product"
                reference="dPersonalDiscountsByProduct"
                target="product"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                              source={source} product_id={product_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            source={source} product_id={product_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default PersonalDiscountsByProduct;