import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    FormDataConsumer, TextField, useTranslate
} from 'react-admin';

import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
import {DateFieldComp, TextFieldBold} from "../../_common/CompReact";
//import useMyStyles from "../utils/useMyStyles";

//import {useForm} from "react-final-form";
// import {useEffect} from "react";
// import {config} from "../utils/config";
import CustomerProperty from "./CustomerProperty";
//import {validateReq} from "../utils/components/validateTable";
//import get from "lodash/get";
/*
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";
*/
const BlockEditCreate = props => {

    //const formEdit = useForm();
    const {record} = props;
    const {id, is_load = false} = record;
    const translate = useTranslate();

    /*useEffect(() => {
        if (card_id === null) {
            formEdit.change('card_id', parseInt(config.bookmanCardID))
        }
    }, []);// eslint-disable-line*/


    return (
        <Box p="1em" fullWidth>
            {id
                ?
                <>
                    <Typography variant="h6">{translate('Customer property')}</Typography>
                    <Box display="flex" alignItems={"center"}>
                        <FormDataConsumer>
                            {({formData, ...rest}) => {
                                //const {customer_id: customerFormId} = formData;
                                return (
                                    <CustomerProperty
                                        {...props}
                                        //customerFormId={customerFormId}
                                        customer_id={id}
                                        formData={formData}
                                        record={record}
                                        isSave={!is_load}
                                    />
                                )
                            }}
                        </FormDataConsumer>
                    </Box>
                </>
                :
                <Typography variant="subtitle1" color={"error"}>For further work fill in the CUSTOMER field
                    and save</Typography>
            }
        </Box>
    )
}
export const CustomerEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    // const classes = useMyStyles();
    const {record} = props;
    const {id} = record;
    const translate = useTranslate();
    // const locale = useLocale();
    //console.log('idSource=============>', idSource, record)

    /*
        const [countBooking, setCountBooking] = React.useState(0);
        const [arBooking, setArBooking] = React.useState([]);
        const getBooking = async (customer_id) => {
            const url = apiPathConfig.getApiUrl()
            let report_url = `booking/admin/booking`;
            let filter = `?customer_id=${customer_id}&is_invoiced=false&is_paid=false`;
            try {
                const response = await httpClient(`${url}${report_url}${filter}`);
                console.log(response);
                if (response.status >= 200 &&  response.status <= 300) {
                    setCountBooking(response.json.count);
                    setArBooking(response.json.results);
                    } else {

                    }
            } catch (err) {
                console.log('err=======>', err);
            } finally {
                //console.log('finally=======>', 'finally');
            }
        }
        useEffect(() => {
            getBooking(customer_id);
        }, []);// eslint-disable-line
    */

    return (
        <>
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="grid" flex={3} mr="2em" alignItems={"center"}>
                        <Box>
                            {/*<Typography variant="subtitle2" gutterBottom>Name</Typography>*/}
                            <TextFieldBold source="name"/>
                        </Box>
                        <Box>
                            {/*<Typography variant="subtitle2" gutterBottom>Uuid</Typography>*/}
                            uuid:{' '}
                            <TextField source="ext_uuid"/>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>{translate(`Created`)}{': '}
                                <DateFieldComp source="created_at" variant="subtitle2" fullWidth/>
                            </div>
                            <div>{translate(`Updated`)}{': '}
                                <DateFieldComp source="updated_at" variant="subtitle2" fullWidth/>
                            </div>
                        </Box>
                    </Box>
                </Box>
            }
            <TabbedForm
                {...props}
                /*toolbar={<PostEditDeleteActions goBack={true} saveAndClose={'/invoice'} reload={true}/>}*/
                toolbar={<PostEditDeleteActions
                    goBack={true}
                    saveAndClose={'/customer_z'}
                    isSave={false}
                    del={true}
                />
                }
                //redirect={redirect_url}
            >
                <FormTab label="Common">
                    <BlockEditCreate {...props}/>
                </FormTab>
                {/*{id &&
                    <FormTab label="History">
                        <HistoryListForFilter {...props} table={'invoice'} invoice_id={id} fullWidth/>
                    </FormTab>
                }*/}
            </TabbedForm>
        </>
    )
};

export default CustomerEditCreate
