import * as React from 'react';
/*import {
    useRedirect,
} from 'react-admin';*/

import EditComponentAdmin from "../../_common/EditComponentAdmin";
import BlogArticleEditCreate from "./BlogArticleEditCreate";
// import {useNotify, useRefresh} from "react-admin";
import {copyToS3bucket} from "../../_common/Transform";
//import {ConfigCreate} from "./ConfigCreate";

export const BlogArticleEdit = props => {
    const {resource} = props;
    //const redirect = useRedirect()
    // const notify = useNotify();
    // const refresh = useRefresh();

    /*const onSuccess = (data) => {
        notify(`Saved data`);
        //redirect(pathname)
        refresh();
    };*/

    return (
        <EditComponentAdmin
            {...props}
            transform={(event) => copyToS3bucket(resource, event)}
            title={''}
            //editDialog={idSource}
            //onSuccess={onSuccess}
            // onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            /*onSuccess={() => idSource
                ? redirect(`/locating/${idSource}/2`)
                : redirect('list', props.basePath)
            }*/
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <BlogArticleEditCreate/>
        </EditComponentAdmin>
    )
};