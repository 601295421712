import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    TextInput,
    useTranslate,
    AutocompleteInput, ReferenceInput,
    useLocale, BooleanInput, SelectInput, useGetList,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp, DateInputComp, NumberInputFloatComp} from "../../_common/CompReact";
import {choicesPromotionType} from "../../_constants/choices";
import {validateReq} from "../../_constants/validateTable";
import {useEffect, useState} from "react";
import {getPromptForTable} from "../../_common/CompFormat/utils";

//import {validateReq} from "../utils/components/validateTable";

//import { JsonSchemaForm } from "@react-admin/ra-json-schema-form";

const BlockEdit = props => {
    const translate = useTranslate();
    const locale = useLocale();
    // const formEdit = useForm();
    //const {promptData} = props;

    return (
        <Box p="1em" fullWidth>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput
                        source={'active'} label={translate('Active')}
                        defaultValue={false}
                        //fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput
                        source={'for_all_products'}
                        label={translate('For all products')}
                        defaultValue={false}
                        //fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <BooleanInput
                        source={'cashback_all_product'}
                        label={translate('Cashback all product')}
                        defaultValue={false}
                        //fullWidth
                    />
                </Box>
                <Box flex={1}>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Promotion')}
                        source={'promotion'}
                        reference={'dPromotion'}
                        variant={"outlined"}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                           fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}>
                    <SelectInput
                        variant={"outlined"}
                        source={'promotion_type'} choices={choicesPromotionType}
                        label={translate('Promotion type')} validate={validateReq} fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        source="document_number" label={translate('Document Number')}
                        variant={"outlined"}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <DateInputComp source={"document_date"} label={translate("Document Date")}
                                   variant={"outlined"} fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <DateInputComp source={"date_from"} label={translate("Date From")}
                                   variant={"outlined"} fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <DateInputComp source={"date_to"} label={translate("Date To")}
                                   variant={"outlined"} fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <NumberInputFloatComp
                        source="quantity_all_product"
                        label={translate('Quantity all product')}
                        variant={"outlined"}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <NumberInputFloatComp
                        source="discount_all_product"
                        label={translate('Discount all product')}
                        variant={"outlined"}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <NumberInputFloatComp
                        source="sum_of_order" label={translate('Sum of order')}
                        variant={"outlined"}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <ReferenceInput
                            label={translate('Currency Order')}
                            source={'currency_order'}
                            reference={'dCurrencies'}
                            variant={"outlined"}
                            //filter={{country: id}}
                        >
                            <AutocompleteInput optionText={`abbr`} resettable fullWidth/>
                        </ReferenceInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={2} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Product Gift')}
                        source={'product_gift'}
                        reference={'dProducts'}
                        variant={"outlined"}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                           fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={2} display={'flex'}>
                    <Box flex={1} mr={"1em"}>
                        <NumberInputFloatComp
                            source="product_gift_price" label={translate('Fix price')}
                            variant={"outlined"}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        <ReferenceInput
                            label={translate('Currency')}
                            source={'product_gift_price_currency'}
                            reference={'dCurrencies'}
                            variant={"outlined"}
                            //filter={{country: id}}
                        >
                            <AutocompleteInput optionText={`abbr`} resettable fullWidth/>
                        </ReferenceInput>
                    </Box>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        fullWidth
                        source={'document_uuid'}
                        label={translate('Document UUID')}
                    />
                </Box>
            </Box>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        fullWidth
                        source={'remark'}
                        label={translate('Remark')}
                    />
                </Box>
            </Box>
        </Box>
    )
}
export const DiscountConditionsDocumentEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const {record, resource} = props;
    const {id} = record || {};
    const [promptData, setPromptData] = useState([]);


    const {data: dataPrompts, loading: isLoading} = useGetList(
        "dPrompts",
        {page: 1, perPage: 100},
        {field: 'id', order: 'ASC'},
    );

    useEffect(() => {
        if (dataPrompts) {
            let dt = getPromptForTable(dataPrompts, resource)
            setPromptData(dt)
            //console.log(resource, 'dt=============>',dt)
        }
    }, [dataPrompts, isLoading]);// eslint-disable-line


    return (
        <Box>

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                        promptData={promptData}
                    />
                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default DiscountConditionsDocumentEditCreate
