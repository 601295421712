import PermIdentity from '@material-ui/icons/PermIdentity';
import {SeoKeyWords} from "./SeoKeyWords";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: SeoKeyWords,
    // create: BaseListIdNameCreate,
    // edit: BaseListIdNameEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;