import React from 'react';
import {useTranslate} from 'react-admin';
import {Tooltip, IconButton} from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

export const CancelEditButtonCustom = ({
                                           cancel,
                                           ...props
                                       }) => {
    const translate = useTranslate();
    return (
        <Tooltip
            title={translate('ra.action.cancel', {
                _: 'ra.action.cancel',
            })}
        >
            <IconButton
                onClick={cancel}
                //size="small"
                {...props}
            >
                <CancelIcon style={{fontSize: 48}}/>
            </IconButton>
            {/*<Button
                variant="contained"
                onClick={cancel} size="small" {...props}
                color="primary"
                startIcon={<CancelIcon/>}
            >
                Close
            </Button>*/}
        </Tooltip>
    );
};


export default CancelEditButtonCustom;
