import PermIdentity from '@material-ui/icons/PermIdentity';
import {DiscountConditionsDocumentList} from "./DiscountConditionsDocumentList";
import {DiscountConditionsDocumentCreate} from "./DiscountConditionsDocumentCreate";
import {DiscountConditionsDocumentEdit} from "./DiscountConditionsDocumentEdit";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: DiscountConditionsDocumentList,
    create: DiscountConditionsDocumentCreate,
    edit: DiscountConditionsDocumentEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;