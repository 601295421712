import React, {useEffect} from 'react'
import {useCreate, useLocale, useQuery, useUpdate} from 'react-admin';
import {cloneDeep, get, map} from 'lodash';
import CategoryDndItem from './component/CategoryDndItem';
import {TreeView} from '@mui/x-tree-view/TreeView';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import useGetContext from '../../_hooks/useGetContext';
import {CategoriesTreeContext} from '../_providers/CategoriesTreeProvider';

export default function CategoriesDnd() {
  const locale = useLocale();

  const [updateCategory] = useUpdate();
  const [create] = useCreate('dProductsGroupRelations');

  const { tree, refetchTree} = useGetContext(CategoriesTreeContext)

  const handleDrop = async (source, target, {isDirect = false, isAlternative}) => {

    const cloneTree = cloneDeep(tree);

    const parent = get(cloneTree, target?.parentPath);

    parent.sub_items = [source, ...parent.sub_items]

    if (isDirect) {
      await updateCategory('dProductsGroup', source?.product_group_id, {parent: target?.product_group_id}, {})
    }

    if (isAlternative) {
      await create('dProductsGroupRelations', {
        product_group_parent: target?.product_group_id,
        product_group_child: source?.product_group_id
      } )
    }

    refetchTree()
  }

  return <TreeView
    onFocusCapture={e => e.stopPropagation()}
    disableSelection
    disabledItemsFocusable
    defaultCollapseIcon={<KeyboardArrowDownIcon/>}
    defaultExpandIcon={<KeyboardArrowRightIcon/>}
  >
    {map(tree, (categoryItem, key) => <CategoryDndItem
      handleDrop={handleDrop}
      parentPath={[key]}
      key={key}
      dragItem={categoryItem}/>)}
  </TreeView>
}