export const PermissionZenit = [//REACT_APP_API_URL=https://api-dev.zenit-auto.com/
    {
        role: 'Superuser',
        permissions: [
            {action: "*", resource: "Swager"}, //Link to Swager

            // {action: "*", resource: "dPromotion"},
            // {action: "*", resource: "dPromotionBadge"},
            // {action: "*", resource: "dDiscountConditionsDocument"},
            // {action: "*", resource: "dPromotionalGood"},

            {action: "*", resource: "dProducts"},
            {action: "*", resource: "dProductsGroup"},
            {action: "*", resource: "dProductsGroupTk"},
            {action: "*", resource: "dFeatureType"},

            {action: "*", resource: "zCustomers"},
            {action: "*", resource: "partner"},
            {action: "*", resource: "partnerAddress"},

            {action: "*", resource: "dUser"},
            {action: "*", resource: "dInterfaces"},
            {action: "*", resource: "dUsersInterfaces"},

            {action: "*", resource: "related_table"},
            {action: "*", resource: "customer_z"},
            {action: "*", resource: "discountProperty"},
            {action: "*", resource: "discountPropertyValue"},
            {action: "*", resource: "currency_z"},
            {action: "*", resource: "customer_property"},
            {action: "*", resource: "product_property"},
            {action: "*", resource: "discount_relationship"},
            {action: "*", resource: "discount_rules"},
            {action: "*", resource: "brand_discount_by_rule"},
            {action: "*", resource: "brand_z"},
            {action: "*", resource: "price_row_z"},
            {action: "*", resource: "price_row_z"},

            {action: "*", resource: "product_discount_by_rule"},
            {action: "*", resource: "product_z"},
            {action: "*", resource: "discount_values_by_rule"},
            {action: "*", resource: "complicated_discount_by_rule"},
            {action: "*", resource: "customer_discount_by_rule"},
            {action: "*", resource: "gift_pool"},
            {action: "*", resource: "gift"},
            {action: "*", resource: "test_price"},
            {action: "*", resource: "createPartner"},
            {action: "*", resource: "edit_sheduler"},
        ]
    },
    {
        role: 'AdminTest',
        permissions: [
            {action: "*", resource: "Swager"}, //Link to Swager

            // {action: "*", resource: "dPromotion"},
            // {action: "*", resource: "dPromotionBadge"},
            // {action: "*", resource: "dDiscountConditionsDocument"},
            // {action: "*", resource: "dPromotionalGood"},

            {action: "*", resource: "dProducts"},
            {action: "*", resource: "dProductsGroup"},
            {action: "*", resource: "dFeatureType"},

            {action: "*", resource: "zCustomers"},
            {action: "*", resource: "partner"},
            {action: "*", resource: "partnerAddress"},

            {action: "*", resource: "dUser"},
            {action: "*", resource: "dInterfaces"},
            {action: "*", resource: "dUsersInterfaces"},

            {action: "*", resource: "related_table"},
            {action: "*", resource: "customer_z"},
            {action: "*", resource: "discountProperty"},
            {action: "*", resource: "discountPropertyValue"},
            {action: "*", resource: "currency_z"},
            {action: "*", resource: "customer_property"},
            {action: "*", resource: "product_property"},
            {action: "*", resource: "discount_relationship"},
            {action: "*", resource: "discount_rules"},
            {action: "*", resource: "brand_discount_by_rule"},
            {action: "*", resource: "brand_z"},
            {action: "*", resource: "price_row_z"},
            {action: "*", resource: "product_discount_by_rule"},
            {action: "*", resource: "product_z"},
            {action: "*", resource: "discount_values_by_rule"},
            {action: "*", resource: "complicated_discount_by_rule"},
            {action: "*", resource: "customer_discount_by_rule"},
            {action: "*", resource: "gift_pool"},
            {action: "*", resource: "gift"},
            {action: "*", resource: "test_price"},
            {action: "*", resource: "createPartner"},
            {action: "*", resource: "edit_sheduler"},

        ]
    },
    {
        role: 'Admin',
        permissions: [
            {action: "*", resource: "Swager"}, //Link to Swager

            // {action: "*", resource: "dPromotion"},
            // {action: "*", resource: "dPromotionBadge"},
            // {action: "*", resource: "dDiscountConditionsDocument"},
            // {action: "*", resource: "dPromotionalGood"},

            {action: "*", resource: "dProducts"},
            {action: "*", resource: "dProductsGroup"},
            {action: "*", resource: "dFeatureType"},

            {action: "*", resource: "zCustomers"},
            {action: "*", resource: "partner"},
            {action: "*", resource: "partnerAddress"},

            {action: "*", resource: "dUser"},
            {action: "*", resource: "dInterfaces"},
            {action: "*", resource: "dUsersInterfaces"},

            {action: "*", resource: "related_table"},
            {action: "*", resource: "customer_z"},
            {action: "*", resource: "discountProperty"},
            {action: "*", resource: "discountPropertyValue"},
            {action: "*", resource: "currency_z"},
            {action: "*", resource: "customer_property"},
            {action: "*", resource: "product_property"},
            {action: "*", resource: "discount_relationship"},
            {action: "*", resource: "discount_rules"},
            {action: "*", resource: "brand_discount_by_rule"},
            {action: "*", resource: "brand_z"},
            {action: "*", resource: "price_row_z"},
            {action: "*", resource: "product_discount_by_rule"},
            {action: "*", resource: "product_z"},
            {action: "*", resource: "discount_values_by_rule"},
            {action: "*", resource: "complicated_discount_by_rule"},
            {action: "*", resource: "customer_discount_by_rule"},
            {action: "*", resource: "gift_pool"},
            {action: "*", resource: "gift"},
            {action: "*", resource: "test_price"},
            {action: "*", resource: "createPartner"},
            {action: "*", resource: "edit_sheduler"},

        ]
    },
    /*{
        role: 'Admins',
        permissions: [
            {action: "", resource: "Dashboard"},//Запрещен
            {action: ['list', 'edit', 'delete'], resource: "booking"},
            {action: "*", resource: "*"}
            {action: ['list'], resource: "*"},
            {action: "*", resource: "locating"},
            {action: "*", resource: "questions"},
            {action: "*", resource: "answers"},
        ]
    },*/

    {
        role: 'Main Manager',
        permissions: [
            {action: "*", resource: "zCustomers"},
            {action: "*", resource: "partner"},
            {action: "*", resource: "partnerAddress"},
            // {action: "*", resource: "edit_sheduler"},

            // {action: "*", resource: "dProducts"},
            // {action: "*", resource: "dProductsGroup"},
            // {action: "*", resource: "dFeatureType"},

            {action: ['list','create'], resource: "dUser"},
            // {action: "*", resource: "dInterfaces"},
            // {action: "*", resource: "dUsersInterfaces"},

            {action: "*", resource: "createPartner"},
            {action: "*", resource: "test_price"},
            // {action: "*", resource: "gift_pool"},
            {action: "*", resource: "customer_z"},
            //{action: "*", resource: "product_z"},
            // {action: "*", resource: "discountProperty"},
            // {action: "*", resource: "discount_rules"},

            //Доп. Товары
            // {action: "*", resource: "related_table"},
            // {action: "*", resource: "discount_relationship"},
            // {action: "*", resource: "product_property"},
            // {action: "*", resource: "customer_property"},
            // {action: "*", resource: "discountPropertyValue"},
            // {action: "*", resource: "currency_z"},
            // {action: "*", resource: "brand_z"},
            // {action: "*", resource: "price_row_z"},

            //Сопутствующие запросы
            {action: "*", resource: "brand_discount_by_rule"},
            {action: "*", resource: "product_discount_by_rule"},

            {action: "*", resource: "discount_values_by_rule"},
            {action: "*", resource: "complicated_discount_by_rule"},
            {action: "*", resource: "customer_discount_by_rule"},
            {action: "*", resource: "gift"},
        ]
    },
    {
        role: 'Analyst',
        permissions: [
            // {action: "*", resource: "zCustomers"},
            // {action: "*", resource: "partner"},
            // {action: "*", resource: "partnerAddress"},
            // {action: "*", resource: "edit_sheduler"},

            {action: "*", resource: "dProducts"},
            {action: "*", resource: "dProductsGroup"},
            {action: "*", resource: "dFeatureType"},

            // {action: "*", resource: "dUser"},
            // {action: "*", resource: "dInterfaces"},
            // {action: "*", resource: "dUsersInterfaces"},

            // {action: "*", resource: "createPartner"},
            {action: "*", resource: "test_price"},
            // {action: "*", resource: "gift_pool"},
            // {action: "*", resource: "customer_z"},
            {action: "*", resource: "product_z"},
            // {action: "*", resource: "discountProperty"},
            // {action: "*", resource: "discount_rules"},

            //Доп. Товары
            {action: "*", resource: "related_table"},
            {action: "*", resource: "discount_relationship"},
            {action: "*", resource: "product_property"},
            {action: "*", resource: "customer_property"},
            {action: "*", resource: "discountPropertyValue"},
            {action: "*", resource: "currency_z"},
            {action: "*", resource: "brand_z"},
            {action: "*", resource: "price_row_z"},

            //Сопутствующие запросы
            {action: "*", resource: "brand_discount_by_rule"},
            {action: "*", resource: "product_discount_by_rule"},
            {action: "*", resource: "discount_values_by_rule"},
            {action: "*", resource: "complicated_discount_by_rule"},
            {action: "*", resource: "customer_discount_by_rule"},
            {action: "*", resource: "gift"},
        ]
    },

    {
        role: 'Supervisor',
        permissions: [
            {action: "*", resource: "zCustomers"},
            {action: "*", resource: "partner"},
            {action: "*", resource: "partnerAddress"},
            // {action: "*", resource: "edit_sheduler"},

            // {action: "*", resource: "dProducts"},
            // {action: "*", resource: "dProductsGroup"},
            // {action: "*", resource: "dFeatureType"},

            {action: ['list','create'], resource: "dUser"},
            // {action: "*", resource: "dInterfaces"},
            // {action: "*", resource: "dUsersInterfaces"},

            {action: "*", resource: "createPartner"},
            {action: "*", resource: "test_price"},
            // {action: "*", resource: "gift_pool"},
            {action: "*", resource: "customer_z"},
            //{action: "*", resource: "product_z"},
            // {action: "*", resource: "discountProperty"},
            // {action: "*", resource: "discount_rules"},

            //Доп. Товары
            // {action: "*", resource: "related_table"},
            // {action: "*", resource: "discount_relationship"},
            // {action: "*", resource: "product_property"},
            // {action: "*", resource: "customer_property"},
            // {action: "*", resource: "discountPropertyValue"},
            // {action: "*", resource: "currency_z"},
            // {action: "*", resource: "brand_z"},
            // {action: "*", resource: "price_row_z"},

            //Сопутствующие запросы
            {action: "*", resource: "brand_discount_by_rule"},
            {action: "*", resource: "product_discount_by_rule"},

            {action: "*", resource: "discount_values_by_rule"},
            {action: "*", resource: "complicated_discount_by_rule"},
            {action: "*", resource: "customer_discount_by_rule"},
            {action: "*", resource: "gift"},
        ]
    },

    {
        role: 'Sales Representative',
        permissions: [
            {action: ['list'], resource: "zCustomers"},
            {action: ['list'], resource: "partner"},
            {action: ['list'], resource: "partnerAddress"},
            // {action: "*", resource: "edit_sheduler"},

            // {action: "*", resource: "dProducts"},
            // {action: "*", resource: "dProductsGroup"},
            // {action: "*", resource: "dFeatureType"},

            {action: ['list','create'], resource: "dUser"},
            // {action: "*", resource: "dInterfaces"},
            // {action: "*", resource: "dUsersInterfaces"},

            {action: "*", resource: "createPartner"},
            {action: "*", resource: "test_price"},
            // {action: "*", resource: "gift_pool"},
            // {action: "*", resource: "customer_z"},
            //{action: "*", resource: "product_z"},
            // {action: "*", resource: "discountProperty"},
            // {action: "*", resource: "discount_rules"},

            //Доп. Товары
            // {action: "*", resource: "related_table"},
            // {action: "*", resource: "discount_relationship"},
            // {action: "*", resource: "product_property"},
            // {action: "*", resource: "customer_property"},
            // {action: "*", resource: "discountPropertyValue"},
            // {action: "*", resource: "currency_z"},
            // {action: "*", resource: "brand_z"},
            // {action: "*", resource: "price_row_z"},

            //Сопутствующие запросы
            {action: "*", resource: "brand_discount_by_rule"},
            {action: "*", resource: "product_discount_by_rule"},

            {action: "*", resource: "discount_values_by_rule"},
            {action: "*", resource: "complicated_discount_by_rule"},
            {action: "*", resource: "customer_discount_by_rule"},
            {action: "*", resource: "gift"},
        ]
    },
    {
        role: 'Manager',
        permissions: [
            {action: ['list'], resource: "zCustomers"},
            {action: ['list'], resource: "partner"},
            {action: ['list'], resource: "partnerAddress"},
            // {action: "*", resource: "edit_sheduler"},

            // {action: "*", resource: "dProducts"},
            // {action: "*", resource: "dProductsGroup"},
            // {action: "*", resource: "dFeatureType"},

            {action: ['list','create'], resource: "dUser"},
            // {action: "*", resource: "dInterfaces"},
            // {action: "*", resource: "dUsersInterfaces"},

            {action: "*", resource: "createPartner"},
            {action: "*", resource: "test_price"},
            // {action: "*", resource: "gift_pool"},
            // {action: "*", resource: "customer_z"},
            //{action: "*", resource: "product_z"},
            // {action: "*", resource: "discountProperty"},
            // {action: "*", resource: "discount_rules"},

            //Доп. Товары
            // {action: "*", resource: "related_table"},
            // {action: "*", resource: "discount_relationship"},
            // {action: "*", resource: "product_property"},
            // {action: "*", resource: "customer_property"},
            // {action: "*", resource: "discountPropertyValue"},
            // {action: "*", resource: "currency_z"},
            // {action: "*", resource: "brand_z"},
            // {action: "*", resource: "price_row_z"},

            //Сопутствующие запросы
            {action: "*", resource: "brand_discount_by_rule"},
            {action: "*", resource: "product_discount_by_rule"},

            {action: "*", resource: "discount_values_by_rule"},
            {action: "*", resource: "complicated_discount_by_rule"},
            {action: "*", resource: "customer_discount_by_rule"},
            {action: "*", resource: "gift"},
        ]
    },
    {
        role: 'Logistician',
        permissions: [
            // {action: ['list'], resource: "zCustomers"},
            // {action: ['list'], resource: "partner"},
            // {action: ['list'], resource: "partnerAddress"},
            {action: "*", resource: "edit_sheduler"},

            // {action: "*", resource: "dProducts"},
            // {action: "*", resource: "dProductsGroup"},
            // {action: "*", resource: "dFeatureType"},

            // {action: ['list'], resource: "dUser"},
            // {action: "*", resource: "dInterfaces"},
            // {action: "*", resource: "dUsersInterfaces"},

            // {action: "*", resource: "createPartner"},
            // {action: "*", resource: "test_price"},
            // {action: "*", resource: "gift_pool"},
            // {action: "*", resource: "customer_z"},
            //{action: "*", resource: "product_z"},
            // {action: "*", resource: "discountProperty"},
            // {action: "*", resource: "discount_rules"},

            //Доп. Товары
            // {action: "*", resource: "related_table"},
            // {action: "*", resource: "discount_relationship"},
            // {action: "*", resource: "product_property"},
            // {action: "*", resource: "customer_property"},
            // {action: "*", resource: "discountPropertyValue"},
            // {action: "*", resource: "currency_z"},
            // {action: "*", resource: "brand_z"},
            // {action: "*", resource: "price_row_z"},

            //Сопутствующие запросы
            {action: "*", resource: "brand_discount_by_rule"},
            {action: "*", resource: "product_discount_by_rule"},

            {action: "*", resource: "discount_values_by_rule"},
            {action: "*", resource: "complicated_discount_by_rule"},
            {action: "*", resource: "customer_discount_by_rule"},
            {action: "*", resource: "gift"},
        ]
    },
];
