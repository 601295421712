import * as React from 'react';
import {
    //TextField,
    ReferenceManyField,
    NumberField, useTranslate,
    TextInput, Datagrid,
} from 'react-admin';
import {
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp, TextFieldBold} from "../../_common/CompReact";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateFirstName} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";

const transformDiscountPropertyValue = (data) => {
    console.log('Transform========>', data)

    data.name = data.name1;
    //data.slug = data.slug1;

    delete data.name1;
    //delete data.slug1;

    return data;
}

const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {discount_property_id, record} = props;
    const translate = useTranslate();

    console.log('=props========>', props)

    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    discount_property_id: discount_property_id,
                    name1: record.name,
                    //slug1: record.slug
                }}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
                transform={transformDiscountPropertyValue}

            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <TextInput source={`name1`} label={translate('Name')} validate={validateFirstName} fullWidth/>
                {/*<TextInput source={`slug1`} label={translate('Slug')} validate={validateFirstName} fullWidth/>*/}
            </RowForm>
        </>
    )
};

const ColumnsForList = (resource, classes) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        name: <TextFieldBold source={`name`} label={translate('Name')}/>,
        /*slug: <TextField source={`slug`} label={translate('Slug')}/>,*/
        created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
        updated_at:
            <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                           locale={'us'} cellClassName={classes.date_st}/>,
    }
    return columns;
};

const CustomerPropertyValue = props => {
    // const {customer_id, record, isSave = true} = props;
    const {discount_property_id, dataProtected} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    // console.log('=======>', invoiceBooking);

    const columns = useSelectedColumns({
        preferences: `${'discountPropertyValue2'}list.columns`,
        columns: ColumnsForList('discountPropertyValue2', classes),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "fit-content", minWidth: 500, marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Discount Property Value"
                reference="discountPropertyValue"
                target="discount_property_id"
            >
                {dataProtected
                    ?
                    <Datagrid
                        //hasBulkActions={false}
                        //noDelete={true}
                        classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    >
                        {columns}
                    </Datagrid>
                    :
                    <MyEditableDatagridForReference
                        //hasBulkActions={false}
                        //noDelete={true}
                        classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                        mutationMode="pessimistic" //Подтвердить удаление
                        undoable
                        createForm={<ListEditForm submitOnEnter={false}
                                                  discount_property_id={discount_property_id}/>}
                        editForm={<ListEditForm submitOnEnter={false}
                                                discount_property_id={discount_property_id}/>}
                        //rowStyle={postRowStyle}
                    >
                        {columns}
                    </MyEditableDatagridForReference>
                }
            </ReferenceManyField>

        </div>
    )
}
export default CustomerPropertyValue;