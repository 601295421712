import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    useTranslate, useLocale,
    AutocompleteInput, ReferenceInput, ReferenceField
} from 'react-admin';

import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {DateFieldComp, NumberInputFloatComp} from "../../_common/CompReact";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {currency_id} = props;
    const translate = useTranslate();

    console.log('props=========>', props)

    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    base_currency: currency_id,
                    source2: localStorage.getItem('username'),
                    //name2: record.name,
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <ReferenceInput
                    label={translate('Currency')}
                    source={'convertible_currency'}
                    reference={'dCurrencies'}
                    //filter={{question$id: id}}
                >
                    <AutocompleteInput optionText={`abbr`} resettable
                                       fullWidth/>
                </ReferenceInput>
                <NumberInputFloatComp
                    // variant={"outlined"}
                    source={"cross_rate"} label={translate("Cross Rates")}
                    validate={validateReq} fullWidth
                    style={{minWidth: 90}}
                />
            </RowForm>
        </>
    )
};

const CurrencyCrossRates = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {currency_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Currency Cross Rates"
                reference="dCurrencyCrossRates"
                target="base_currency"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              currency_id={currency_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            currency_id={currency_id}/>}
                    //rowStyle={postRowStyle}
                >
                    <ReferenceField
                        link={false}
                        label={translate('Currency')}
                        source={'convertible_currency'}
                        reference={'dCurrencies'}
                        //filter={{question$id: id}}
                    >
                        <TextField source={`abbr`}/>
                    </ReferenceField>,
                    <TextField source='cross_rate' label={translate('Cross Rate')}/>
                    <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                   locale={'us'} cellClassName={classes.date_st}/>
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default CurrencyCrossRates;