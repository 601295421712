import * as React from 'react';
import {
    FormDataConsumer,
    useTranslate,
} from 'react-admin';
import {localesConfig} from "../utils/config";

import {useForm} from "react-final-form";


import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
//import FilledInput from '@mui/material/FilledInput';

import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
// import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
// import TextField from '@mui/material/TextField';

import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import get from 'lodash/get';


import {MyTranslateIcons} from "../icons/MyTranslateIcons";
import {styled} from "@mui/material/styles";
import {LinearProgress} from "@mui/material";
import {useEffect} from "react";
import {MyAiIcons} from "../icons/MyAiIcons";
// import TranslateIcon from '@material-ui/Translate';
// import GTranslateIcon from '@material-ui/GTranslate';
import {getPrompt, getTranslate, getTransliteral} from "./CompForApi/utilsAPI";

/*export const getGoogleMerchant = async (id) => {
    let url = `${config.apiUrl}google-merchant/create/${id}`;
    try {
        //const response = await httpClient(`https://demo.iam-trade.ch/google-merchant/create/${id}/`);
        const response = await httpClient(url);

        if (response.status >= 200 && response.status <= 300) {
            let res = get(response, 'json.result', null)
            //console.log('PROMPT=======>', res);
            return res ? 'OK Google merchant' : 'Error Request'
        } else {
            return 'Error Request'
        }
    } catch (err) {
        return 'Error Request'
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}

export const getPrompt = async (prompt, formData) => {
    //const url = apiPathConfig.getApiUrl()

    //let fraze = 'Generate a company description based on this json data, '
    let fraze = 'Based on this json data, '
    let jsonData = {}
    let arrFields = prompt.info_fields.replace(/\s/g, '').split(',')
    arrFields.forEach(it => {
        let dt = get(formData, it, '')
        if (dt) jsonData[it] = dt;
    })

    let data = {
        "model": "gpt-3.5-turbo",
        "response_example": "The result should be in json format. Like: {result: string}",
        "prompt": `${prompt.prompt} ${fraze} ${JSON.stringify(jsonData)}`//"Generate a company description based on this json data, "
    }
    let report_url = `https://integration.iam-trade.ch/api-public/integration/ai/custom-prompt`;
    try {
        const response = await httpClient(`${report_url}`, {
            method: 'post',
            body: JSON.stringify(data)
        });
        if (response.status >= 200 && response.status <= 300) {
            let res = get(response, 'json.result', null)
            let answer = '';
            console.log('res=======>', res);
            if (res && res.result) {
                console.log('PROMPT=======>', res.result);
                answer = typeof res.result === "string" ? res.result : JSON.stringify(res.result)
            }
            //res.result === "string" ? res.result : JSON.stringify(res.result)
            return answer
        } else {
            return null
        }
    } catch (err) {
        return null
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}

export const getTransliteral = async (oriText, arrLocale) => {
    let tmp = {};
    arrLocale.forEach(lang => {
        // if (lang !== 'en') {
        let it = get(oriText, `${lang}`, '')
        // console.log('IT=======>', it);
        if (it) {
            tmp[lang] = {}
            tmp[lang].name = slugify(it, {
                separator: '_',
                lowercase: true,
                // replacement: '_',  // replace spaces with replacement character, defaults to `-`
                // remove: undefined, // remove characters that match regex, defaults to `undefined`
                // lower: false,      // convert to lower case, defaults to `false`
                // strict: false,     // strip special characters except replacement, defaults to `false`
                // locale: 'fr',      // language code of the locale to use
                // trim: true         // trim leading and trailing replacement chars, defaults to `true`
            })
        } else {
            tmp[lang] = {}
            tmp[lang].name = '';
        }
        // }
    })

    //console.log('oriText=========>', oriText)
    return tmp
}
export const getTranslate = async (text, locale) => {
    //const url = apiPathConfig.getApiUrl()
    let data = {
        "model": "gpt-3.5-turbo",
        "languages": locale,
        "obj_for_translate": {"name": text}
    }
    let report_url = `https://integration.iam-trade.ch/api-public/integration/ai/translate`;
    try {
        const response = await httpClient(`${report_url}`, {
            method: 'post',
            body: JSON.stringify(data)
        });
        if (response.status >= 200 && response.status <= 300) {
            let res = get(response, 'json.result', null)
            // console.log('response=======>', res);
            return res
        } else {
            return null
        }
    } catch (err) {
        return null
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}*/

export const StyledIconButton = styled(IconButton)(
    ({theme}) => ({
        marginTop: 22,
        '& .MuiSvgIcon-root': {
            //border: 0,
            color: '#2196f3', //'blue',//'gray',
        },
    })
);
export const StyledSelect = styled(Select)(
    ({theme}) => ({
        marginTop: 22,
        // '& .MuiOutlinedInput-notchedOutline': {
        '& fieldset': {
            border: 0,
        },
        '& .MuiSelect-select': {
            //border: 0,
            color: 'green',//'gray',
            width: 20,
            backgroundColor: 'unset',
        },
        '& .MuiSvgIcon-root': {
            //border: 0,
            color: 'green',//'gray',
        },
    })
);

export const MyTranslatableInputs = props => {
    return (
        <FormDataConsumer>
            {({formData, ...rest}) => {
                return (
                    <Block
                        {...props}
                        formData={formData}
                    />
                )
            }}
        </FormDataConsumer>
    )
}

const Block = props => {
    const {
        formData,
        source,
        label,
        fullWidth = false,
        placeholder = false,
        isTranslate = true,
        slug = false,
        prompt = null
    } = props
    const translate = useTranslate();
    //const locale = useLocale();
    const formEdit = useForm();

    const [currentLocale, setCurrentLocale] = React.useState(localesConfig.defaultLocale);
    const [currentText, setCurrentText] = React.useState(null);
    const [progress, setProgress] = React.useState(false);
    const [flag, setFlag] = React.useState(1);


    // console.log('formData======>', formData)
    // console.log(source, 'SOURCE=1==================>', currentText);
    // console.log(currentLocale, 'SOURCE=2==================>', currentText[currentLocale]);

    useEffect(() => {
        let tmp
        //formEdit.change(source, null)
        if (formData.id) tmp = get(formData, source, {})
        else tmp = {}

        let newFormData = {}
        Object.entries(tmp).forEach((it) => {
            if (localesConfig.locales.indexOf(it[0]) < 0) {
                it[1] = ''
            }
            newFormData[it[0]] = it[1]
        })

        localesConfig.locales.forEach(it => {
            if (tmp[it] === undefined) newFormData[it] = ''
        })
        //if (slug) formEdit.change(slug, tmp)
        formEdit.change(source, newFormData)
        setCurrentText(newFormData)

    }, []);// eslint-disable-line

    const handleClickTranslate = async () => {
        setProgress(true)
        let arrLocale = [currentLocale]
        if (currentLocale === localesConfig.defaultLocale) arrLocale = localesConfig.locales
        let res;
        // if (slug) res = await getTransliteral(formData[slug], arrLocale)
        if (slug) res = await getTransliteral(formData[slug], arrLocale)
        else res = await getTranslate(currentText[localesConfig.defaultLocale], arrLocale)
        setProgress(false)
        if (res) {
            let tmp = JSON.parse(JSON.stringify(currentText))
            localesConfig.locales.forEach(lang => {
                if (slug || lang !== localesConfig.defaultLocale) {
                    let it = get(res, `${lang}.name`, '')
                    // console.log('IT=======>', it);
                    if (it) {
                        tmp[lang] = it
                        //setCurrentText(tmp)
                        //formEdit.change(source, tmp)
                    }
                }
            })
            setCurrentText(tmp)
            formEdit.change(source, tmp)
        }
        formEdit.change('flagCountTranslate', flag + 1)
        setFlag(flag + 1)
    }

    const handleClickPrompt = async () => {
        let def = get(formData, source, null)
        setProgress(true)
        //JSON.stringify(formData)
        let res = await getPrompt(prompt, formData)
        setProgress(false)
        if (res) {
            localesConfig.locales.forEach(lang => {
                if (lang === localesConfig.defaultLocale) {
                    def[lang] = res
                } else {
                    def[lang] = '';
                }
            })
            setCurrentText(def)
            formEdit.change(source, def)
        }
        formEdit.change('flagCountTranslate', flag + 1)
        setFlag(flag + 1)
    }

    const handleChangeText = (event) => {
        // console.log('Text=============>', event.target.value)
        let tmp = JSON.parse(JSON.stringify(currentText))
        tmp[currentLocale] = event.target.value;
        setCurrentText(tmp)
        formEdit.change(source, tmp)
    }
    const handleChangeLocale = (event) => {
        //console.log('Locale=============>', event.target.value)
        setCurrentLocale(event.target.value)
    }


    // console.log(currentLocale, 'SOURCE===================>', currentText)
    if (currentText === null) return null
    return (
        <FormControl
            variant="outlined"
            // variant="filled"
            fullWidth={fullWidth}
            sx={{marginTop: 1, marginBottom: 3}}
        >
            <InputLabel htmlFor="translate-button">{translate(label)}</InputLabel>
            <OutlinedInput // FilledInput
                size={"small"}
                placeholder={placeholder ? placeholder : translate(label)}
                multiline
                sx={{alignItems: 'start'}}
                onChange={handleChangeText}
                id="translate-button"
                value={currentText[currentLocale]}
                startAdornment={
                    <InputAdornment position="start" sx={{marginLeft: '-14px'}}>
                        <StyledSelect
                            labelId="translate-select-label"
                            id="translate-select"
                            value={currentLocale}
                            onChange={handleChangeLocale}
                            autoWidth
                            //label="Age"
                        >
                            {localesConfig.locales.map(it => {
                                return (<MenuItem key={it} value={it}>{it}</MenuItem>)
                            })}
                        </StyledSelect>
                    </InputAdornment>
                }
                endAdornment={
                    <InputAdornment position="end">
                        <div>
                            {prompt && currentLocale === localesConfig.defaultLocale
                                ?
                                <StyledIconButton
                                    aria-label="toggle password visibility2"
                                    onClick={handleClickPrompt}
                                    edge="end"
                                    style={{marginTop: slug && 16}}
                                >
                                    <MyAiIcons/>
                                </StyledIconButton>
                                : null
                            }
                            {isTranslate
                                ?
                                <StyledIconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickTranslate}
                                    edge="end"
                                    style={{marginTop: slug && 16}}
                                >
                                    <MyTranslateIcons/>
                                    {slug &&
                                        <span
                                            style={{
                                                color: 'red',
                                                position: "absolute",
                                                fontSize: 12,
                                                top: 28,
                                                left: 9
                                            }}
                                        >slug</span>
                                    }
                                </StyledIconButton>
                                : null
                            }
                        </div>
                    </InputAdornment>
                }
                label={label}
            />
            {progress && <LinearProgress color="secondary"/>}
        </FormControl>
    )
}