import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    NumberField, useTranslate, useLocale,
    ReferenceField, AutocompleteInput, ReferenceInput, BooleanInput,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
    // CreateButton,
    // EditRowButton,
    // DeleteRowButton,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {BooleanFieldComp, DateFieldComp, getDt, NumberInputFloatComp} from "../../_common/CompReact";

// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
// import {validateReq} from "../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
// import {useForm} from "react-final-form";
//import get from 'lodash/get';
import CroppedLongField from "../../_common/CroppedLongField";
// import {validateReq} from "../utils/components/validateTable";
import {Box} from "@material-ui/core";
import {useMemo} from "react";

/*const transform = (data) => {
    data.article = data.article2;
    delete data.article2;
    data.index = data.index2;
    delete data.index2;
    data.variant_color = data.variant_color2;
    delete data.variant_color2;
    data.source = data.source2;
    delete data.source2;


    return data;
}*/
const ListEditForm = (props) => {
    // const classes = useMyStyles();
    const {related_id} = props;
    const translate = useTranslate();
    const locale = useLocale();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    promotion: related_id,
                    source: localStorage.getItem('username'),
                }}
                // transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                {/*<NumberField source="id" headerClassName={classes.headerShortWidth}/>*/}
                <ReferenceInput
                    label={translate('Discount')}
                    source={'discount_conditions_document'}
                    reference={'dDiscountConditionsDocument'}
                    // variant={"outlined"}
                    //filter={{question$id: id}}
                >
                    <AutocompleteInput
                        optionText={useMemo(() => {
                            return ((record) => `${record?.document_number} (${getDt(record?.document_date)})`)
                        }, [])}
                        // optionText={`${'name'}[${locale}]`}
                        resettable
                        fullWidth
                    />
                </ReferenceInput>
                <ReferenceInput
                    label={translate('Product')}
                    source={'product'}
                    reference={'dProducts'}
                    // variant={"outlined"}
                    //filter={{question$id: id}}
                >
                    <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                       fullWidth/>
                </ReferenceInput>
                <NumberInputFloatComp
                    source="quantity" label={translate('Quantity')}
                    // variant={"outlined"}
                    fullWidth
                />
                <NumberInputFloatComp
                    source="discount" label={translate('Discount')}
                    // variant={"outlined"}
                    fullWidth
                />
                <Box display={'grid'}>
                    <NumberInputFloatComp
                        style={{marginBottom: -24}}
                        source="fix_price" label={translate('Fix price')}
                        // variant={"outlined"}
                        fullWidth
                    />
                    <ReferenceInput
                        style={{marginBottom: -24}}
                        label={translate('Currency')}
                        source={'fix_price_currency'}
                        reference={'dCurrencies'}
                        // variant={"outlined"}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`abbr`} resettable fullWidth/>
                    </ReferenceInput>
                </Box>

                <BooleanInput
                    source={'cashback'} label={''}
                    defaultValue={false}
                    //fullWidth
                />

            </RowForm>
        </>
    )
};
const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        discount_conditions_document:
            <ReferenceField
                label={translate('Discount conditions document')}
                source={'discount_conditions_document'}
                reference={'dDiscountConditionsDocument'}
            >
                <Box display={'grid'}>
                    <TextField source={`document_number`}/>
                    <DateFieldComp source="document_date" textAlign={'center'} showTime={false}
                                   locale={'us'} cellClassName={classes.date_st}/>
                </Box>
            </ReferenceField>,
        product:
            <ReferenceField
                label={translate('Product')}
                source={'product'}
                reference={'dProducts'}
            >
                <TextField source={`${'name'}[${locale}]`}/>
            </ReferenceField>,
        quantity: <NumberField source={'quantity'} label={translate('Quantity')}/>,
        discount: <NumberField source={'discount'} label={translate('Discount')}/>,
        fix_price:
            <Box display={"grid"} textAlign={"right"} sortBy={'fix_price'} label={translate('Fix price')}>
                <NumberField source={'fix_price'} label={translate('Fix price')}/>
                <ReferenceField
                    label={translate('Fix price currency')}
                    source={'fix_price_currency'}
                    reference={'dCurrencies'}
                    link={false}
                >
                    <TextField source={`abbr`}/>
                </ReferenceField>
            </Box>,

        cashback: <BooleanFieldComp source="cashback" label={translate("Cashback")} textAlign={"center"}/>,

        source:
            <Box display={'grid'} label={translate('Updated')}>
                <CroppedLongField source="source" long={8} label={translate('Source')}/>
                <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                    /*cellClassName={classes.date_st}*/ showTime={true}/>,
            </Box>
    }
    return columns;
};

const PromotionalGood = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const {related_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'PromotionalGood'}relaited.columns`,
        columns: ColumnsForList('dPromotionalGood', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Promotional Good"
                reference="dPromotionalGood"
                target="promotion"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                              related_id={related_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            related_id={related_id}/>}
                    //rowStyle={postRowStyle}
                    /*actions={
                        <>
                            <EditRowButton/>
                            <DeleteRowButton/>
                        </>
                    }*/
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default PromotionalGood;