//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, ReferenceField,
    TextField,
    useLocale,
    useTranslate,
    // EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../../index.css';
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
//import {exporterPassenger} from "../utils/exporterFile";
import {BooleanFieldComp, DateFieldComp, FilterDataAside, TextFieldBold} from "../../_common/CompReact";
import CardComp from "../../_common/Asside/CardComp";
import CroppedLongField from "../../_common/CroppedLongField";
//import get from 'lodash/get';
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {choicesPromotionType} from "../../_constants/choices";
import MySimpleList from "../../_common/MySimpleList";

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    // const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch/>
                <TrueFalseFilters field={'active'} label={'Active'}/>
                <FilterDataAside fields={'updated'} name={'update'}/>
                {/*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*/}
                {/*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*/}
                {/*<TrueFalseFilters field={'is_load'} label={'Exported to Xero'}/>*/}
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
            </CardContent>
        </CardComp>
    )
};

const TopFilters = (resource, locale) => {
    let dt = [];
    /*dt.push(<ReferenceInput source="industry_id" reference="dPromptIndustries" alwaysOn>
        <AutocompleteInput
            optionText={`code`}
            resettable emptyText={'All Codes'}/>
    </ReferenceInput>);
    dt.push(<ReferenceInput source="prompt_type_id" reference="dPromptTypes" alwaysOn>
        <AutocompleteInput optionText="code" resettable emptyText={'All Types'}/>
    </ReferenceInput>);*/

    return dt;
}

const ColumnsForList = (resource, locale, classes, isSmall) => {
    /*
{
    "id": 1,
    ===---"document_number": "1",
    ===---"document_date": "2024-02-23T00:00:00",
    ==="document_uuid": null,
    ===---"date_from": "2024-02-24",
    ===---"date_to": "2024-02-29",
    ===---"active": false,
    ===---"promotion_type": "GOODS_AS_A_GIFT",
    ===---"product_gift_price": "0.00",
    ===---"for_all_products": false,
    ===---"cashback_all_product": false,
    ==="remark": "",
    ===---"promotion": null,
    ===---"product_gift": null,
    ===---"product_gift_price_currency": null,

    ===---"discount_all_product": "0.00",
    ====---"quantity_all_product": 0,
    ===---"currency_order": null
    ===---"sum_of_order": "0.00",
}
     */

    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        document_number: <TextFieldBold source={`document_number`} label={translate('Document Number')}/>,
        document_date: <DateFieldComp source="document_date" label={translate('Document Date')}
                                      textAlign={'center'} showTime={false}
                                locale={'us'} cellClassName={classes.date_st}/>,
        promotion:
            <ReferenceField
                label={translate('Promotion')}
                source={'promotion'}
                reference={'dPromotion'}
            >
                <TextField source={`${'name'}[${locale}]`}/>
            </ReferenceField>,
        promotion_type:
            <SelectAndSaveChoices
                label={translate('Promotion Type')}
                source="promotion_type"
                statuses={choicesPromotionType}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                //edit={true}
                width={'12em'}
            />,
        date_from:
            <DateFieldComp source="date_from" label={translate('Date From')} textAlign={'center'}
                           showTime={false}
                           locale={'us'} cellClassName={classes.date_st}/>,
        date_to:
            <DateFieldComp source="date_to" label={translate('Date To')} textAlign={'center'}
                           showTime={false}
                           locale={'us'} cellClassName={classes.date_st}/>,

        sum_of_order: <NumberField source={'sum_of_order'} label={translate('Sum of order')}/>,
        currency_order:
            <ReferenceField
                label={translate('Currency Order')}
                source={'currency_order'}
                reference={'dCurrencies'}
            >
                <TextField source={`abbr`}/>
            </ReferenceField>,

        quantity_all_product: <NumberField source={'quantity_all_product'} label={translate('Quantity all product')}/>,
        discount_all_product: <NumberField source={'discount_all_product'} label={translate('Discount all product')}/>,

        product_gift:
            <ReferenceField
                label={translate('Product Gift')}
                source={'product_gift'}
                reference={'dProducts'}
            >
                <TextField source={`${'name'}[${locale}]`}/>
            </ReferenceField>,
        product_gift_price: <NumberField source={'product_gift_price'} label={translate('Fix price')}/>,
        product_gift_price_currency:
            <ReferenceField
                label={translate('Fix price currency')}
                source={'product_gift_price_currency'}
                reference={'dCurrencies'}
            >
                <TextField source={`abbr`}/>
            </ReferenceField>,

        active: <BooleanFieldComp source="active" label={translate("Active")} textAlign={"center"}/>,
        for_all_products: <BooleanFieldComp source="for_all_products" label={translate("For all products")} textAlign={"center"}/>,
        cashback_all_product: <BooleanFieldComp source="cashback_all_product" label={translate("Cashback all product")} textAlign={"center"}/>,
        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

export const DiscountConditionsDocumentList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes,isSmall),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                permissions={permissions}
                asideFilter={false}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
            is_bulkActionButtons={true}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}