import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    useTranslate,
    TextInput,
    AutocompleteInput,
    ReferenceInput,
    SimpleFormIterator,
    ArrayInput, FormDataConsumer,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

import {validateReq} from "../../_constants/validateTable";
// import {useForm} from "react-final-form";
// import {useEffect} from "react";
// import {useSelector} from "react-redux";


const BlockEdit = props => {
    const translate = useTranslate();
    //const formEdit = useForm();
    //const {formData} = props
    //const locale = useLocale();
    // const formEdit = useForm();

    /*let resources = useSelector((state) => state.admin.resources.dPromptTypes.data)

    useEffect(() => {
        if (formData.prompt_type_id) {
            let type = Object.values(resources).find(x => x.id === formData.prompt_type_id)
            if (type) {
                //console.log(formData.prompt_type_id, 'formData.prompt_type=====>', type)
                //setPromptTypeCode(type.code)
                if (type.code === 'lambda_ai_processing') formEdit.change('isProcessing', true)
                else formEdit.change('isProcessing', false)
            }
        } else {
            //setPromptTypeCode(null)
            formEdit.change('isProcessing', false)
        }
    }, [formData.prompt_type_id, resources]);// eslint-disable-line*/

    return (
        <>
            <Box p="1em" fullWidth>
                <Box display="flex" alignItems={"top"}>
                    <Box flex={1}>
                        <TextInput source={`prompt`} variant={"outlined"} multiline={true}
                                   label={translate('Prompt')} validate={validateReq} fullWidth/>
                    </Box>
                </Box>

                <Box display="flex" alignItems={"top"}>
                    <Box flex={1}>
                        <TextInput source={`response_example`} variant={"outlined"} multiline={true}
                                   label={translate('Response Example')} fullWidth/>
                    </Box>
                </Box>
                <Box display="flex" alignItems={"top"}>
                    <Box flex={1} mr={"1em"}>
                        <ReferenceInput
                            label={translate('Industry')}
                            source={'industry_id'}
                            reference={'dPromptIndustries'}
                            //filter={{question$id: id}}
                        >
                            <AutocompleteInput optionText={`${'code'}`} resettable
                                               variant={"outlined"} validate={validateReq} fullWidth/>
                        </ReferenceInput>
                    </Box>
                    <Box flex={1} alignItems={"top"}>
                        <ReferenceInput
                            label={translate('Type')}
                            source={'prompt_type_id'}
                            reference={'dPromptTypes'}
                            //filter={{question$id: id}}
                        >
                            <AutocompleteInput optionText={`${'code'}`} resettable
                                               variant={"outlined"} validate={validateReq} fullWidth/>
                        </ReferenceInput>
                    </Box>
                </Box>

                <Box display="flex" alignItems={"top"}>
                        <ArrayInput source="config.fields" label={'Config'}>
                            <SimpleFormIterator
                                //addButton={<Button style={{color: 'blue'}}>Add Route, Flight Number and Ticket Class</Button>}
                                // className={classes.SimpleFormIterator}
                                className={"SimpleFormIterator"}
                                disableReordering={true}
                                inline={true}
                                fullWidth={true}
                                //disableAdd={ticket_type === 'rtn' && legs && legs.length > 0}
                                //disableRemove={ticket_type === 'multi_city' && legs && legs.length < 3}
                            >
                                <TextInput source="table" label={'Tabled'} helperText={false}
                                           variant={"outlined"}
                                           fullWidth/>
                                <TextInput source="field" label={'Field'} helperText={false}
                                           variant={"outlined"}
                                           fullWidth/>
                                <TextInput source="info_fields" label={'Info Fields'} helperText={false}
                                           variant={"outlined"} style={{width: '400px'}} multiline/>
                            </SimpleFormIterator>
                        </ArrayInput>
                </Box>
            </Box>
        </>
    )
}
export const ProductEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            // debugger
                            //let payment_type = get(formData, 'payment_type', null)
                            //if (payment_type === 'miles_only') return null;
                            return (
                                <BlockEdit
                                    {...props}
                                    formData={formData}
                                />
                            )
                        }}
                    </FormDataConsumer>

                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated_at" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default ProductEditCreate
