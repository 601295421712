import * as React from 'react';
import {
    Box, TableBody, TableCell, TableRow, Typography,
} from "@material-ui/core";

import {
    useTranslate
} from "react-admin";
import {GetArrayFieldFromResource, GetFieldFromResource} from "./Partner";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import {CircleTrueFalse} from "./PartnerData";

export const PreviewAddress = props => {
    const {
        form,
        partnerAddress,
    } = props

    const translate = useTranslate();

    console.log('partnerAddress=========>', partnerAddress)

    return (
        <Box p="1em" fullWidth>
            <TableContainer>
                <Table aria-label="spanning table" style={{width: '100%'}}>
                    <TableHead>
                        <TableRow>
                            <TableCell align={'center'} style={{padding: 6}}>
                                <Typography variant="body" color="textPrimary">
                                    {translate('Auto')}
                                </Typography>
                            </TableCell>
                            <TableCell align={'left'} style={{padding: 6}}>
                                <Typography variant="body" color="textPrimary">
                                    {translate('Name')}
                                </Typography>
                            </TableCell>
                            <TableCell align={'left'} style={{padding: 6}}>
                                <Typography variant="body" color="textPrimary">
                                    {translate('Partners delivery address')}
                                </Typography>
                            </TableCell>
                            <TableCell align={'center'} style={{padding: 6}}>
                                <Typography variant="body" color="textPrimary">
                                    {translate('Confirm')}
                                </Typography>
                            </TableCell>
                            <TableCell align={'center'} style={{padding: 6}}>
                                <Typography variant="body" color="textPrimary">
                                    {translate('Disable')}
                                </Typography>
                            </TableCell>
                            <TableCell align={'left'} style={{padding: 6}}>
                                <Typography variant="body" color="textPrimary">
                                    {translate('Customers')}
                                </Typography>
                            </TableCell>
                            <TableCell align={'left'} style={{padding: 6}}>
                                <Typography variant="body" color="textPrimary">
                                    {translate('Routes')}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {partnerAddress && partnerAddress.map((it, ind) => {
                            return (
                                <TableRow key={it.id}>
                                    <TableCell align={'center'} style={{padding: 4, textAlign: "-webkit-center"}}>
                                        <CircleTrueFalse item={it.auto_address}/>
                                    </TableCell>
                                    <TableCell align={'left'} style={{padding: 4}}>
                                        <Typography variant="body" color="textPrimary">
                                            {it.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align={'left'} style={{padding: 4}}>
                                        <Typography variant="body" color="textPrimary">
                                            {it.address}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align={'center'} style={{padding: 4, textAlign: "-webkit-center"}}>
                                        <CircleTrueFalse item={it.confirmed}/>
                                    </TableCell>
                                    <TableCell align={'center'} style={{padding: 4, textAlign: "-webkit-center"}}>
                                        <CircleTrueFalse item={it.disabled}/>
                                    </TableCell>
                                    <TableCell align={'left'} style={{padding: 4}}>
                                        <Box display={'grid'}>
                                            <GetArrayFieldFromResource
                                                resource={'zCustomers'}
                                                field={'name'}
                                                id={it.customers}
                                                type={'text'}
                                            />
                                            <Box display={'flex'} style={{flexWrap: 'wrap', paddingLeft: 8}}>
                                                {it.customer_cross && it.customer_cross.length > 0 &&
                                                    it.customer_cross.map((it3, ind3) => {
                                                        return (
                                                            <b key={it3.id} style={{marginRight: 8, color: "green"}}>
                                                                {it3.name}
                                                                {(it.customer_cross.length - 1) > ind3 ? ', ' : ''}
                                                            </b>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell align={'left'} style={{padding: 4}}>
                                        <GetArrayFieldFromResource
                                            resource={'delivery'}
                                            field={'name'}
                                            id={it.deliveries}
                                            type={'text'}
                                        />
                                    </TableCell>
                                </TableRow>
                            )
                        })
                        }
                        {form.partner.values.id &&
                            <TableRow style={{backgroundColor: '#c8e3b8'}}>
                                <TableCell colSpan={7}><h3 style={{margin: 0}}>{translate('New address')}</h3>
                                </TableCell>
                            </TableRow>
                        }
                        {Object.values(form.address).map((it2, ind2) => {
                            return (
                                <TableRow key={ind2}>
                                    <TableCell align={'center'} style={{padding: 4, textAlign: "-webkit-center"}}>
                                        <CircleTrueFalse item={it2.auto_address}/>
                                    </TableCell>
                                    <TableCell align={'left'} style={{padding: 4}}>
                                        <Typography variant="body" color="textPrimary">
                                            {it2.values.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align={'left'} style={{padding: 4}}>
                                        <Typography variant="body" color="textPrimary">
                                            {it2.values.address}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align={'center'} style={{padding: 4, textAlign: "-webkit-center"}}>
                                        <CircleTrueFalse item={it2.values.confirmed}/>
                                    </TableCell>
                                    <TableCell align={'center'} style={{padding: 4, textAlign: "-webkit-center"}}>
                                        <CircleTrueFalse item={it2.values.disabled}/>
                                    </TableCell>
                                    <TableCell align={'left'} style={{padding: 4}}>
                                        <Box display={'flex'} style={{flexWrap: 'wrap'}}>
                                            {it2.values.customers && it2.values.customers.length > 0 &&
                                                it2.values.customers.map((it3, ind3) => {
                                                    return (
                                                        <span key={it3.id} style={{
                                                            marginRight: 8,
                                                            color: it3?.new && "green",
                                                            fontWeight: it3?.new && "bold"
                                                        }}>
                                                            {it3.name}
                                                            {(it2.values.customers.length - 1) > ind3 ? ', ' : ''}
                                                        </span>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </TableCell>
                                    <TableCell align={'left'} style={{padding: 4}}>
                                        <Box display={'flex'} style={{flexWrap: 'wrap'}}>
                                            {it2.values.deliveries && it2.values.deliveries.length > 0 &&
                                                it2.values.deliveries.map((it3, ind3) => {
                                                    return (
                                                        <span key={it3.id}>
                                                            {it3.name}[{it3.time_dispatch}][{it3.additional?.week_days}]
                                                            {(it2.values.deliveries.length - 1) > ind3 ? ', ' : ''}
                                                        </span>
                                                    )
                                                })
                                            }
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )
                        })

                        }
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    )
}
