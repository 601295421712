import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    useTranslate,
    TextInput,
    //useLocale,
    BooleanInput, AutocompleteInput, ReferenceInput, useLocale,
} from 'react-admin';
import {Box, Typography, useMediaQuery} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

//import {validateReq} from "../utils/components/validateTable";
import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
import {MyImgForSlider} from "../../_common/MyImgForSlider";
import {MyTranslatableInputsRich} from "../../_common/MyTranslatableInputsRich";
// import TranslateIcon from '@material-ui/Translate';
// import GTranslateIcon from '@material-ui/GTranslate';

//import { JsonSchemaForm } from "@react-admin/ra-json-schema-form";


const BlockEdit = props => {
    const {id, isSmall} = props
    const translate = useTranslate();
    const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>

            <Box style={{display: isSmall ? "grid" : "flex"}}  alignItems={"top"} mb={'16px'}>
                {id &&
                    <Box
                        display="flex"
                        alignItems={"top"}
                        flex={5} mr={"1em"}
                        style={{border: '1px solid #ddd', borderRadius: 16}}
                    >
                        <MyImgForSlider
                            resourceImg={'dBrandImage'}
                            targetImg={'brand'}
                            //oneVariant={variantData.length === 1 ? variantData[0].article : null}
                            variantData={null}
                            variantSelData={{id: id}}
                            setVariantSelData={null}
                        />
                    </Box>
                }
                <Box
                    display="grid" alignItems={"top"} flex={5}
                    style={{
                        maxWidth: !id && 600,
                        //marginTop: 82,
                        // border: '1px solid #ddd',
                        // borderRadius: 16,
                    }}
                >
                    <Box flex={1}>
                        <BooleanInput source={'original_brand'} label={translate('Original Brand')}
                                      defaultValue={false}
                                      fullWidth/>
                    </Box>
                    <Box flex={1}>
                        <ReferenceInput
                            label={translate('Product Group')}
                            source={'product_group'}
                            reference={'dProductsGroup'}
                            //filter={{question$id: id}}
                        >
                            <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                               variant={"outlined"} fullWidth/>
                        </ReferenceInput>
                    </Box>
                    <Box flex={1}>
                        <MyTranslatableInputs
                            // prompt={promptData.find(x => x.field === 'name')}
                            fullWidth
                            source={'name'}
                            label={translate('Name')}
                        />
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            source={'name_clean'}
                            label={translate('Name Clean')}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            source={'ext_id'}
                            label={translate('Ext Id')}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            source={'synonyms_search'}
                            label={translate('Synonyms Search')}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            source={'brands_country'}
                            label={translate('Brands Country')}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        <TextInput
                            variant={"outlined"}
                            source={'uuid'}
                            label={translate('Uuid')}
                            fullWidth
                        />
                    </Box>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={3}>
                    <MyTranslatableInputsRich
                        //prompt={promptData.find(x => x.field === 'description')}
                        source={'seo_description'}
                        label={translate('Seo Description')}
                        fullWidth
                    />
                </Box>
            </Box>

            {/*<Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}
                     style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                >
                    <h4 style={{margin: 0}}>{translate("Holiday")}</h4>
                    <Holiday country_id={id}/>
                </Box>

            </Box>*/}

        </Box>
    )
}
export const BrandEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });

    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                        isSmall={isSmall}
                    />
                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default BrandEditCreate
