import * as React from 'react';
import {
    ReferenceManyField,
    NumberField, useTranslate, NumberInput, ReferenceInput, AutocompleteInput, FormDataConsumer,
    // ArrayInput,
    // SimpleFormIterator,
    // TextInput,
    ReferenceArrayInput,
    AutocompleteArrayInput, useRecordContext, ReferenceArrayField,
    SingleFieldList, ChipField, Datagrid, TextField, ArrayField, ReferenceField,
} from 'react-admin';
import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
import {useForm} from "react-final-form";
import {useEffect} from "react";
import {DateFieldComp, TextFieldBold} from "../../_common/CompReact";
import {Box} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useSelector} from "react-redux";
import get from 'lodash/get';
import TableHead from "@material-ui/core/TableHead";

/*
"id": 0,
  "condition": {},
  "created_at": "2023-09-12T13:54:43.034Z",
  "updated_at": "2023-09-12T13:54:43.034Z",
  "discount_rule_id": 0,
  "discount": 0,
  "use_fix_price": true,
  "fix_price": 0,
  "fix_price_currency_id": 0
*/
const transform = (data) => {
    console.log('Transform========>', data)
    /*data.condition = {};
    data.arrDt.forEach((it, index) => {
        let ind = `cond_${index}`;
        if (!it.del) {
            if (data[ind] !== undefined) {
                let slug = data[ind].slug
                data.condition[slug] = data[ind].discount_property_value_id
            }
        }
        delete data[ind];
    })*/

    data.condition = [];
    data.arrDt.forEach((it, index) => {
        let ind = `cond_${index}`;
        if (!it.del) {
            if (data[ind] !== undefined) {
                data.condition.push({
                    id: data[ind].discount_property_id,
                    slug: data[ind].slug,
                    value: data[ind].discount_property_value_id,
                    resource: data[ind].discount_property_value_table
                })
            }
        }
        delete data[ind];
    })

    delete data.arrDt;
    return data;
}

const elDt = {del: false}

const SelDiscountProp = (props) => {
    //const classes = useMyStyles();
    const {formData, arrDt, setArrDt} = props;
    const translate = useTranslate();
    const formEdit = useForm();

    let currencyProps = useSelector((state) => state.admin.resources.discountProperty.data)

    const [flag, setFlag] = React.useState(0);

    const handleDelRows = (e, index) => {
        // JSON.stringify для преобразования объектов в JSON.
        // JSON.parse для преобразования JSON обратно в объект.
        let pr1 = JSON.parse(JSON.stringify(arrDt));
        pr1[index].del = true
        console.log(index, 'arrDt====>', pr1)
        setArrDt(pr1);
        setFlag(flag + 1)
    }
    const handleAddRows = () => {
        let pr2 = JSON.parse(JSON.stringify(arrDt));//arrDt;
        pr2.push(elDt);
        setArrDt(pr2);
        setFlag(flag + 1)
    }
    const handleDiscountProperty = (e, index) => {
        //let discProp = `cond_${index}.discount_property_id`
        let slug = `cond_${index}.slug`
        let discPropVal = `cond_${index}.discount_property_value_id`
        let discPropValTable = `cond_${index}.discount_property_value_table`
        if (e) {
            let res = Object.values(currencyProps).find(x => x.id === e)
            if (res) {
                formEdit.change(discPropVal, []);
                formEdit.change(slug, res.slug);
                switch (res.slug) {
                    case 'customer.customers':
                        formEdit.change(discPropValTable, 'customer_z');
                        break;
                    case 'product.brands':
                        formEdit.change(discPropValTable, 'brand_z');
                        break;
                    default:
                        formEdit.change(discPropValTable, 'discountPropertyValue');
                        break;
                }
            } else {
                formEdit.change(discPropValTable, null);
                formEdit.change(discPropVal, []);
                formEdit.change(slug, null);
            }
        } else {
            formEdit.change(discPropValTable, null);
            formEdit.change(discPropVal, []);
            formEdit.change(slug, null);
        }
        console.log('currencyProps=====>', Object.values(currencyProps))
        console.log(index, 'handleDiscountProperty=====>', e)
    }


    useEffect(() => {
        formEdit.change('arrDt', arrDt);
    }, [arrDt]);// eslint-disable-line

    useEffect(() => {
        console.log('formData=====>', formData)
        if (formData.condition) {
            let arr = [];
            formData.condition.forEach((it, index) => {
                    let discProp = `cond_${index}.discount_property_id`
                    let slug = `cond_${index}.slug`
                    let discPropVal = `cond_${index}.discount_property_value_id`
                    let discPropValTable = `cond_${index}.discount_property_value_table`
                    formEdit.change(discPropVal, it.value);
                    formEdit.change(slug, it.slug);
                    formEdit.change(discProp, it.id);
                    formEdit.change(discPropValTable, it.resource);
                    console.log('START==========>', it)
                    arr.push(elDt);
            })
            formEdit.change('condition', null);
            setArrDt(arr);
        }

    }, [formData]);// eslint-disable-line

    //console.log('arrDt======>', arrDt)
    return (
        <Box display={"grid"}>
            {arrDt.map((it, index) => {
                let discProp = `cond_${index}.discount_property_id`
                let discPropVal = `cond_${index}.discount_property_value_id`
                //let discPropValTable = `cond_${index}.discount_property_value_table`
                let discount_property_value_table = null;
                let discount_property_id = null;
                if (
                    formData[`cond_${index}`] !== undefined &&
                    formData[`cond_${index}`].discount_property_value_table !== undefined
                ) {
                    discount_property_value_table = formData[`cond_${index}`].discount_property_value_table;
                    discount_property_id = formData[`cond_${index}`].discount_property_id;
                }

                console.log('discount_property_value_table=======>', discount_property_value_table)
                if (!it.del)
                    return (
                        <Box key={index} display={"flex"} style={{marginTop: -16}}>
                            <Box mr={'1em'} maxWidth={'300px'}>
                                <ReferenceInput
                                    source={discProp}
                                    reference="discountProperty"
                                    label={translate('Discount property')}
                                    filter={{use_in_complicated_rule: 1}}
                                    onChange={(event) => handleDiscountProperty(event, index)}
                                    validate={validateReq}
                                >
                                    <AutocompleteInput resettable optionText="name" fullWidth/>
                                </ReferenceInput>
                            </Box>
                            <Box mr={'1em'} maxWidth={'400px'} minWidth={'400px'}>
                                {discount_property_value_table &&
                                    <ReferenceArrayInput
                                        perPage={100}
                                        source={discPropVal} reference={discount_property_value_table}
                                        label={translate('Discount property value222')}
                                        filter={{
                                            discount_property_id: discount_property_id
                                    }}
                                        validate={validateReq}
                                    >
                                        <AutocompleteArrayInput resettable optionText="name" fullWidth/>
                                    </ReferenceArrayInput>
                                }
                            </Box>
                            <Box style={{marginTop: 8}}>
                                {index > 0 &&
                                    <Button
                                        variant="contained"
                                        color="default"
                                        onClick={(event) => handleDelRows(event, index)}
                                        //fullWidth
                                    >
                                        DEL
                                    </Button>
                                }
                            </Box>
                        </Box>
                    )
                else return null
            })
            }

            <Box flex={1} mt={"-1em"}>
                <Button
                    //variant="contained"
                    color="primary"
                    onClick={handleAddRows}
                >
                    Add Rows
                </Button>
            </Box>

        </Box>
        /*<ArrayInput source="legs"
                    label={'Add Travel date, Route, Flight Number for Multi City'}>
            <SimpleFormIterator
                //addButton={<Button style={{color: 'blue'}}>Add Route, Flight Number and Ticket Class</Button>}
                // className={classes.SimpleFormIterator}
                className={"SimpleFormIterator"}
                disableReordering={true}
                inline={true}
                fullWidth={true}
                //disableAdd={ticket_type === 'rtn' && legs && legs.length > 0}
                //disableRemove={ticket_type === 'multi_city' && legs && legs.length < 3}
            >
                <TextInput source="from_place" label={'From Place'}
                           validate={validateReq} fullWidth/>
                <TextInput source="to_place" label={'To Place'}
                           validate={validateReq} fullWidth/>
                <TextInput source="flight_number"
                           validate={validateReq}
                           label={'Flight Number'}
                           fullWidth/>
            </SimpleFormIterator>
        </ArrayInput>*/
    )
}


const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {discount_rule_id} = props;
    const translate = useTranslate();

    const [arrDt, setArrDt] = React.useState([elDt]);


    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{discount_rule_id: discount_rule_id}}
                mutationOptions={{onSuccess: handleSuccess}}
                transform={transform}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <Box display={"grid"}>
                    <NumberInput source={'discount'} label={translate('Discount')} style={{maxWidth: 120}}
                                 validate={validateReq}/>
                    {/*<ReferenceInput source={'slug22'} reference="conditions"
                                label={translate('Property Presentation')}
                                //filter={{discount_property_id: filter2}}
                                            validate={validateReq}>
                                <AutocompleteInput resettable optionText="name" fullWidth/>
                            </ReferenceInput>*/}
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            return (
                                <SelDiscountProp
                                    formData={formData}
                                    arrDt={arrDt}
                                    setArrDt={setArrDt}
                                    discount_rule_id={discount_rule_id}
                                    {...props}
                                />
                            )
                        }}
                    </FormDataConsumer>
                </Box>
            </RowForm>
        </>
    )
};
const DiscountPropertyValue = (props) => {
    //const {source, title, titleText = false} = props;
    const record = useRecordContext(props);
    let resource = get(record, 'resource', '');
    return (
        <ReferenceArrayField reference={resource} source="value" link={false}>
            <SingleFieldList>
                <ChipField source="name" link={false}/>
            </SingleFieldList>
        </ReferenceArrayField>
    )
}

const DatagridHeader = () => (
    <TableHead/>
);

const ColumnsForList = (resource, classes) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        condition: /*<GetProperty source="condition" label={translate('Condition')}/>,*/
            <ArrayField source="condition" label={'Condition'}>
                <Datagrid bulkActionButtons={false} header={<DatagridHeader/>}>
                    <Box label={'Discount Property'}  minWidth={'200px'} maxWidth={'200px'} flex={'grid'}>
                        <ReferenceField source="id" reference="discountProperty" link={true}>
                            <TextFieldBold source="name"/>
                        </ReferenceField>
                        <Box>(<TextField source="slug"/>)</Box>
                    </Box>
                    <Box minWidth={'300px'} maxWidth={'300px'} label={'Discount Property Value'} style={{padding: 4, textAlign: "left"}}>
                        <DiscountPropertyValue/>
                    </Box>
                    <Box maxWidth={'1px'}/>
                </Datagrid>
            </ArrayField>,
        discount: <NumberField source="discount" label={translate('Discount')}/>,
        created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
    }
    return columns;
};

const ComplicatedDiscountByRule2 = props => {
    // const {customer_id, record, isSave = true} = props;
    const {discount_rule_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    // console.log('=======>', invoiceBooking);

    const columns = useSelectedColumns({
        preferences: `${'complicated_discount_by_rule'}list.columns`,
        columns: ColumnsForList('complicated_discount_by_rule', classes),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "fit-content", minWidth: 500, marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="complicated_discount_by_rule"
                reference="complicated_discount_by_rule"
                target="discount_rule_id"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false}
                                              discount_rule_id={discount_rule_id}/>}
                    editForm={<ListEditForm submitOnEnter={false}
                                            discount_rule_id={discount_rule_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default ComplicatedDiscountByRule2;