import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    NumberField, useTranslate, useLocale,
    ReferenceField, AutocompleteInput, ReferenceInput, FormDataConsumer,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp} from "../../_common/CompReact";

import {useSelectedColumns} from "@react-admin/ra-preferences";
// import {validateReq} from "../../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
// import {useForm} from "react-final-form";
//import get from 'lodash/get';
import CroppedLongField from "../../_common/CroppedLongField";
import {useForm} from "react-final-form";
import {useEffect, useState} from "react";
import {validateReq} from "../../_constants/validateTable";

const Form = (props) => {

    //const classes = useMyStyles();
    const {formData} = props;
    const translate = useTranslate();
    const locale = useLocale();
    const formEdit = useForm();

    const [featureType, setFeatureType] = useState(0);
    const [flag, setFlag] = useState(0);

    useEffect(() => {
        if(formData.feature_type) setFeatureType(formData.feature_type)
        else  setFeatureType(0)
        if(flag > 0) formEdit.change('value', null)
        setFlag(flag+1)
    }, [formData.feature_type]);// eslint-disable-line

    return (
        <>
            <ReferenceInput
                label={translate('Feature Valid Value')}
                source={'value'}
                reference={'dFeatureValidValue'}
                filter={{feature_type: featureType}}
                validate={validateReq}
            >
                <AutocompleteInput optionText={`${'valid_value'}[${locale}]`} resettable
                                   fullWidth/>
            </ReferenceInput>
        </>
    )
}

const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {related_id} = props;
    const translate = useTranslate();
    const locale = useLocale();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    product: related_id,
                    source: localStorage.getItem('username'),
                }}
                // transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <ReferenceInput
                    label={translate('Product characteristic')}
                    source={'feature_type'}
                    reference={'dFeatureType'}
                    //filter={{question$id: id}}
                    validate={validateReq}
                >
                    <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                       fullWidth/>
                </ReferenceInput>
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                        return (
                            <Form  {...props} formData={formData}/>
                        )
                    }}
                </FormDataConsumer>


            </RowForm>
        </>
    )
};
const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        feature_type:
            <ReferenceField source="feature_type" reference="dFeatureType"
                            label={translate('FeatureType')} link={false}
            >
                <TextField source={`name[${locale}]`}/>
            </ReferenceField>,
        value:
            <ReferenceField source="value" reference="dFeatureValidValue"
                            label={translate('Feature Valid Value')} link={false}
            >
                <TextField source={`valid_value[${locale}]`}/>
            </ReferenceField>,
        source: /*<TextField source="source" label={translate('Source')}/>,*/
            <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                                /*cellClassName={classes.date_st}*/ showTime={true}/>,

    }
    return columns;
};

const ProductsFeatureValue = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const {related_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'ProductsFeatureValue'}productslist.columns`,
        columns: ColumnsForList('ProductsFeatureValue', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={96}
                fullWidth
                label="Products Feature Value"
                reference="dProductsFeatureValue"
                target="product"

            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                              related_id={related_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            related_id={related_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default ProductsFeatureValue;