import * as React from 'react';
import Button from '@mui/material/Button';
//import { Link } from 'react-router-dom';
import {useTranslate, useRecordContext, Link} from 'react-admin';
import { stringify } from 'query-string';

import products from '../../pages/dProducts';

const LinkToRelatedProducts = () => {
    const record = useRecordContext();
    const translate = useTranslate();
    if (!record) return null;
    return (
        <Button
            size="small"
            color="primary"
            component={Link}
            to={{
                pathname: '/dProducts',
                search: stringify({
                    filter: JSON.stringify({ product_group_related: record.id }),
                }),
            }}
            sx={{ display: 'inline-flex', alignItems: 'center' }}
        >
            <products.icon sx={{ paddingRight: '0.5em' }} />
            {translate('List of product')}
        </Button>
    );
};

export default LinkToRelatedProducts;
