import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    useLocale, useTranslate,
} from 'react-admin';
/*import {
    TextInput,
    SimpleForm,
} from 'react-admin';*/
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";
import {BuildEditComponent} from "./BuildEditComponent";
import {tableConfig} from "../../tablesConfig/tableConfig";
import {BaseTab} from "./BaseTab";
//import {BasicTabs} from "../_common/BaseTab";

const freeTables = {
    dProducts: 'Products',
    dProductsGroup: 'ProductsGroup',
    dCustomers: 'Customers',
}
export const UniversalEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const {record, idSource, resource} = props;
    const {id} = record || {};
    const locale = useLocale();

    let res = resource;
    if (freeTables[resource] !== undefined && freeTables[resource]) res = freeTables[resource]

    const data = tableConfig.tablesList.find(x => x.table === res)
    if (!data) return (<h5>Table not found</h5>);
    const {data: params} = data;
    const {fields_data, related_table = []} = params;

    console.log('fields_list=============>', fields_data)
    return (
        <Box>

            {/*{id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={3} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="h6" gutterBottom>ID: <b>{record.id}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <Typography variant="subtitle2" gutterBottom>Source: <b>{record.source}</b></Typography>
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }*/}

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={idSource ? true : false}/>}
                initialValues={{source: localStorage.getItem('username'),}}
                //redirect={redirect_url}
            >
                {fields_data.filter(x => x.tab !== 'DEFAULT').map((it, ind) => {
                    return (
                        <FormTab label={it.tab}>
                            <Box p="1em" fullWidth>
                                {it.dataTab.map((it2, ind2) => {
                                    return (
                                        <Box display="flex" alignItems={"top"}>
                                            {it2.box.map((it3, ind3) => {
                                                return (
                                                    <Box flex={it3.flex !== undefined ? it3.flex : 1}
                                                         mr={ind3 >= it3.length - 1 ? "3em" : "1em"}>
                                                        {BuildEditComponent(it3, locale, idSource)}
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    )
                                })}
                            </Box>
                        </FormTab>
                    )
                })}
            </TabbedForm>
            {related_table.length > 0 &&
                <Box p="2em">
                    <h3>{translate('Related table')}</h3>
                    <BaseTab related_table={related_table}  {...props}/>
                </Box>
            }
            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default UniversalEditCreate
