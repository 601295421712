import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    AutocompleteInput,
    ReferenceInput,
    ReferenceField,
    NumberField, useTranslate, FormDataConsumer, useRecordContext,
} from 'react-admin';

//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
    // DeleteWithConfirmIconButton,
    // EditRowButton,
} from "@react-admin/ra-editable-datagrid";

import useMyStyles from "../../utils/useMyStyles";
import {DateFieldComp} from "../../_common/CompReact";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
import {useForm} from "react-final-form";
import {useEffect, useState} from "react";
import get from 'lodash/get';
import {Box} from "@mui/material";

/*const transform = (data) => {
    //console.log(data.payment_type,'Transform========>', data)
    // localStorage.removeItem('fdsBookingForm');
    data.travel_date = data.tmp_travel_date;
    data.route = data.tmp_route;
    data.flight_number = data.tmp_flight_number;
    delete data.tmp_travel_date;
    delete data.tmp_route;
    delete data.tmp_flight_number;
    return data;
}*/
const SelDiscountProp = (props) => {
    //const classes = useMyStyles();
    const {formData, setFilter2, filter2} = props;
    const translate = useTranslate();
    const formEdit = useForm();

    useEffect(() => {
        if (filter2 >= 0) {
            if (formData.discount_property_id) {
                setFilter2(formData.discount_property_id)
            } else {
                setFilter2(0);
            }
            formEdit.change('discount_property_value_id', null)
        } else setFilter2(0);
    }, [formData.discount_property_id]);// eslint-disable-line

    return (
        <ReferenceInput source="discount_property_id" reference="discountProperty"
                        label={translate('Discount property')}
                        filter={{rule_type: 'product', protected: false}}
                        validate={validateReq}
        >
            <AutocompleteInput resettable optionText="name" fullWidth/>
        </ReferenceInput>
    )
}

const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {product_id} = props;
    const translate = useTranslate();

    const [filter2, setFilter2] = useState(-1);

    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{product_id: product_id}}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                {/*<ReferenceInput source="discount_property_id" reference="discountProperty"
                                label={translate('Discount property')}
                                filter={{rule_type: 'product', protected: false}}
                                validate={validateReq}>
                    <AutocompleteInput resettable optionText="name" fullWidth/>
                </ReferenceInput>*/}
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                        return (
                            <SelDiscountProp formData={formData} setFilter2={setFilter2} filter2={filter2} {...props}/>
                        )
                    }}
                </FormDataConsumer>
                <ReferenceInput source="discount_property_value_id" reference="discountPropertyValue"
                                label={translate('Discount property value')}
                                filter={{discount_property_id: filter2}}
                                validate={validateReq}>
                    <AutocompleteInput resettable optionText="name" fullWidth/>
                </ReferenceInput>

            </RowForm>
        </>
    )
};
const GetInfo = (props) => {
    // const {type} = props;
    const record = useRecordContext(props);
    let name = get(record, 'name', null);
    let protectedReq = get(record, 'protected', null);

    return(
        <Box display={'grid'}>
            {protectedReq && <span style={{color: "red"}}>Protected</span>}
            <span>{name}</span>
        </Box>
    )
}
const ColumnsForList = (resource, classes) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        /*customer_id: <ReferenceField source="customer_id" reference="customer_z" label={translate('Customer')}
                                     link={true}
                                     sortBy={'customer_z:name'}>
            <TextField source="name"/>
        </ReferenceField>,*/
        discount_property_id: <ReferenceField source="discount_property_id" reference="discountProperty"
                                              label={translate('Discount property')}
                                              link={true}
                                              sortBy={'discountProperty:name'}>
            {/*<TextField source="name"/>*/}
            <GetInfo/>
        </ReferenceField>,
        discount_property_value_id: <ReferenceField source="discount_property_value_id"
                                                    reference="discountPropertyValue"
                                                    label={translate('Discount property value')}
                                                    link={true}
                                                    sortBy={'discountPropertyValue:name'}>
            <TextField source="name"/>
        </ReferenceField>,

        created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                                   locale={'us'} cellClassName={classes.date_st}/>,
    }
    return columns;
};

/*const CustomAction = () => (
    <>
        <EditRowButton/>
        <DeleteWithConfirmIconButton mutationMode="undoable"/>
    </>
);*/
const ProductProperty = props => {
    // const {customer_id, record, isSave = true} = props;
    const {product_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)
    // console.log('=======>', invoiceBooking);

    const columns = useSelectedColumns({
        preferences: `${'product_property2'}list.columns`,
        columns: ColumnsForList('product_property2', classes),
        omit: ["nb_views"],
    });

      /*const postRowStyle = (record, index) => ({
        backgroundColor: record?.ext_id && 'red',
        // display: "table-cell",
    });*/

    return (
        <div style={{width: "fit-content", minWidth: 500, marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Product Property"
                reference="product_property"
                target="product_id"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //rowStyle={postRowStyle}
                    //actions={<CustomAction/>}
                    // hasBulkActions={(record) => record.ext_id === null}
                    // noDelete={(record) => record.ext_id === null}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false}
                                              product_id={product_id}/>}
                    editForm={<ListEditForm submitOnEnter={false}
                                            product_id={product_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default ProductProperty;