//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
import {
    AutocompleteInput,
    ChipField, EmailField,
    FilterLiveSearch,
    NumberField, ReferenceArrayField, ReferenceField, ReferenceInput,
    SimpleList, SingleFieldList,
    TextField,
    useLocale,
    useTranslate,
} from 'react-admin';
import {Box, CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../../index.css';
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
//import {exporterPassenger} from "../utils/exporterFile";
import {DateFieldComp, FilterDataAside} from "../../_common/CompReact";
import CardComp from "../../_common/Asside/CardComp";
// import Chip from '@mui/material/Chip';
import CroppedLongField from "../../_common/CroppedLongField";

const Aside = (props) => {
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch/>
                <FilterDataAside fields={'created_at'} name={'Created'}/>
                <FilterDataAside fields={'updated_at'} name={'Updated'}/>
                {/*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*/}
                {/*<TrueFalseFilters field={'is_load'} label={'Exported to Xero'}/>*/}
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
            </CardContent>
        </CardComp>
    )
};


const TopFilters = (resource, locale) => {
    const translate = useTranslate();
    let dt = [];
    dt.push(<ReferenceInput source="manager" reference="dManager" alwaysOn
                            label={translate('Manager')}>
            <AutocompleteInput optionText="name" resettable emptyText={'All'}/>
        </ReferenceInput>);
    dt.push(<ReferenceInput source="sales_representative_1" reference="dManager" alwaysOn
                            label={translate('Sales representative SNG')}>
            <AutocompleteInput optionText="name" resettable emptyText={'All'}/>
        </ReferenceInput>);
    dt.push(<ReferenceInput source="sales_representative_2" reference="dManager" alwaysOn
                            label={translate('Sales representative Ino')}>
            <AutocompleteInput optionText="name" resettable emptyText={'All'}/>
        </ReferenceInput>);
    /*dt.push(<ReferenceInput source="customer_id" reference="customer" alwaysOn>
            <AutocompleteInput optionText="name" resettable emptyText={'All customers'}/>
        </ReferenceInput>);*/
    return dt;
}

/*const GetCustomers = () => {
    const record = useRecordContext();
    console.log('record=====>', record)
    const {data, loaded, error} = useGetOne('zCustomers', record);

    console.log('data=====>', data)
    if (loaded) {
        console.log('data=====>', data)
        if (error) return 'Error'
        // eslint-disable-next-line react/jsx-no-undef
        return <Chip label={data.name}/>

    }
    return null

}*/

const ColumnsForList = (resource, locale, classes) => {

    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        name: <TextField source={'name'} label={translate('Name')}/>,
        manager:
            <ReferenceField source="manager" reference="dManager" label={translate('Manager')}
                            link={true}
                // sortBy={'discountProperty:name'}
            >
                <TextField source="name"/>
            </ReferenceField>,
        email: <EmailField source={'email'} label={translate('Email')}/>,
        phone: <TextField source={'phone'} label={translate('Phone')}/>,
        customers:
            <ReferenceArrayField reference="zCustomers" source="customers" label={translate('Customers')}>
                <SingleFieldList>
                    <ChipField source={'name'}/>
                </SingleFieldList>
            </ReferenceArrayField>,
        /*<ArrayField source="customers" label={translate('Customers')}>
                <SingleFieldList linkType={false}>
                    <GetCustomers/>
                </SingleFieldList>
            </ArrayField>,*/

        sales_representative_1:
            <ReferenceField source="sales_representative_1" reference="dManager"
                            label={translate('Sales representative SNG')}
                            link={true}
                // sortBy={'discountProperty:name'}
            >
                <TextField source="name"/>
            </ReferenceField>,
        sales_representative_2:
            <ReferenceField source="sales_representative_2" reference="dManager"
                            label={translate('Sales representative Ino')}
                            link={true}
                // sortBy={'discountProperty:name'}
            >
                <TextField source="name"/>
            </ReferenceField>,
        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'}
                                cellClassName={classes.date_st}/>,
    }
    return columns;
};

export const PartnerList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes)}
                permissions={permissions}
                // asideFilter={true}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
        >
            {isSmall
                ?
                <SimpleList
                    rowClick={false}
                    primaryText={<TextField source="name"/>}
                    secondaryText={null}
                    tertiaryText={
                        <Box
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '16px',
                                display: 'grid',
                            }}
                        >
                            {columns}
                        </Box>
                    }
                    linkType={false}//{record => record.canEdit ? "edit" : "show"}
                    rowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}