import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    useTranslate,
    AutocompleteInput, ReferenceInput,
    useLocale, BooleanInput, SelectInput, TextInput,
} from 'react-admin';
import {Box} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {useMemo} from "react";
import FootherEdit from "../../_common/Headers/FootherEdit";
import {validateReq} from "../../_constants/validateTable";
import {choicesExternalSupplier} from "../../_constants/choices";

//import {validateReq} from "../utils/components/validateTable";


const BlockEdit = props => {
    const translate = useTranslate();
    const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"center"}>
                <Box flex={1} mr={"1em"}>
                    <SelectInput
                        variant={"outlined"}
                        source={'supplier'}
                        choices={choicesExternalSupplier}
                        validate={validateReq}
                        label={translate('Supplier')} resettable fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <BooleanInput
                        source={'auto_export_orders'} label={translate('Auto export orders')}
                        defaultValue={false}
                        //fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Main warehouse')}
                        source={'main_warehouse'}
                        reference={'dSuppliersWarehouses'}
                        variant={"outlined"}
                        //filter={{question$id: id}}
                        validate={validateReq}
                    >
                        <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                                           fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}>
                    <ReferenceInput
                        label={translate('Price type')}
                        source={'price_type'}
                        reference={'dPriceTypes'}
                        variant={"outlined"}
                        //filter={{question$id: id}}
                        validate={validateReq}
                    >
                        <AutocompleteInput
                            optionText={useMemo(() => {
                                return ((record) => `${record?.name}`)
                            }, [])}
                            resettable
                            fullWidth
                        />
                    </ReferenceInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"center"}>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        multiline={true}
                        source={'auth_data'}
                        label={translate('Auth data')}
                        fullWidth
                    />
                </Box>
            </Box>
        </Box>
    )
}
export const ExternalSuppliersEditCreate = props => {//Не работает
    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                    />
                </FormTab>
            </TabbedForm>

            {id && <FootherEdit source={record.source}/>}

        </Box>
    )
};

export default ExternalSuppliersEditCreate
