// import {commonTrueFalse, currencyRounding} from "../../utils/choices";
/*
        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                        ]
                    },
                    {
                        box: [
                        ]
                    },
                    {
                        box: [
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]
 */
class DefaultTable {
    constructor(props) {


        this.default_filters_aside = [

            {
                name: 'updated',
                type: "FilterDataAside",
                parameters: [],
            },
            {
                name: 'source',
                type: "FilterList",
                parameters: ["admin", "boris"],
            },
        ]

        this.default_fields_data = [
            {
                tab: "DEFAULT",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'id',
                                type: 'int',
                                show_type: 'TextField',
                                edit_type: 'TextField',
                                read_only: true
                            },
                            {
                                name: 'updated',
                                type: 'date',
                                show_type: 'DateField',
                                edit_type: 'DateField',
                                read_only: false
                            },
                            {
                                name: 'source',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextField',
                                read_only: false
                            },
                        ]
                    },
                ]
            },
        ]
    }
}

export default DefaultTable