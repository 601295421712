import PermIdentity from '@material-ui/icons/PermIdentity';
import {FeatureTypeCreate} from "./FeatureTypeCreate";
import {FeatureTypeEdit} from "./FeatureTypeEdit";
import {FeatureTypeList} from "./FeatureTypeList";


// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: FeatureTypeList,
    create: FeatureTypeCreate,
    edit: FeatureTypeEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;