import PermIdentity from '@material-ui/icons/PermIdentity';
import {PromotionList} from "./PromotionList";
import {PromotionCreate} from "./PromotionCreate";
import {PromotionEdit} from "./PromotionEdit";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: PromotionList,
    create: PromotionCreate,
    edit: PromotionEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;