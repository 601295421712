import * as React from 'react';
import {
    ReferenceManyField,
    useTranslate, useLocale,
    TextField,
    TextInput,
    BooleanInput,
    ReferenceInput,
    AutocompleteInput,
    ReferenceField, UrlField,
} from 'react-admin';

import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {BooleanFieldComp} from "../../_common/CompReact";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    data.name = data.name2;
    delete data.name2;
    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {branch_id, record} = props;
    const translate = useTranslate();
    //  MainMessenger
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    branch: branch_id,
                    source2: localStorage.getItem('username'),
                    name2: record.name,
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                {/*<NumberInputFloatComp source="number" label={translate('Number')}
                                      validate={validateReq}
                                      fullWidth
                />*/}
                <TextInput source="name2" label={translate('Contact')}
                                      validate={validateReq}
                                      fullWidth
                />
                <ReferenceInput
                    label={translate('Messenger')}
                    source={'messenger'}
                    reference={'dMessenger'}
                    //filter={{country: id}}
                >
                    <AutocompleteInput optionText={`name`} resettable fullWidth/>
                </ReferenceInput>

                <BooleanInput source="is_visible"
                              label={translate('Is visible')} defaultValue={false}/>
            </RowForm>
        </>
    )
};

const CompanyMessenger = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {branch_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Company Messenger"
                reference="dCompanyMessenger"
                target="branch"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              branch_id={branch_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            branch_id={branch_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {/*<NumberField source='number' label={translate('Name')}/>*/}
                    <UrlField source='name' label={translate('Contact')}/>
                    <ReferenceField source="messenger" reference="dMessenger"
                                         link={false} label={translate('Messenger')}>
                            <TextField source="name"/>
                    </ReferenceField>
                    <BooleanFieldComp source="is_visible" label={translate("Is visible")}
                                      textAlign={"center"}/>,
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default CompanyMessenger;