import PermIdentity from '@material-ui/icons/PermIdentity';
import {ExternalSuppliersList} from "./ExternalSuppliersList";
import {ExternalSuppliersCreate} from "./ExternalSuppliersCreate";
import {ExternalSuppliersEdit} from "./ExternalSuppliersEdit";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: ExternalSuppliersList,
    create: ExternalSuppliersCreate,
    edit: ExternalSuppliersEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;