import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    useTranslate,
    TextInput,
    AutocompleteInput,
    ReferenceInput,
    useLocale,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

//import {validateReq} from "../utils/components/validateTable";
import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
import Holiday from "./Holidays";
import AvailableLanguage from "./AvailableLanguage";
// import TranslateIcon from '@material-ui/Translate';
// import GTranslateIcon from '@material-ui/GTranslate';

//import { JsonSchemaForm } from "@react-admin/ra-json-schema-form";


const BlockEdit = props => {
    const {id} = props
    const translate = useTranslate();
    const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        // prompt={promptData.find(x => x.field === 'name')}
                        fullWidth
                        source={'name'}
                        label={translate('Name')}
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    {id &&
                        <ReferenceInput
                            label={translate('Default Language')}
                            source={'default_language'}
                            reference={'dAvailableLanguage'}
                            filter={{country: id}}
                        >
                            <AutocompleteInput optionText={`${'name'}`} resettable
                                               variant={"outlined"} fullWidth/>
                        </ReferenceInput>
                    }
                </Box>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Default Price Type')}
                        source={'default_price_type'}
                        reference={'dPriceTypes'}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}>
                    <ReferenceInput
                        label={translate('Base Warehouse')}
                        source={'base_warehouse_main_supplier'}
                        reference={'dSuppliersWarehouses'}
                        //filter={{question$id: id}}
                    >
                        <AutocompleteInput optionText={`name_abbr[${locale}]`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'iso_code_2_symbols'}
                        label={translate('Iso code 2 symbols')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'iso_code_3_symbols'}
                        label={translate('Iso code 3 symbols')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'iso_code_numeric'}
                        label={translate('Iso code numeric')}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        source={'item_code_in_accounting_system'}
                        label={translate('Item code in accounting system')}
                        fullWidth
                    />
                </Box>
            </Box>

            {id &&
                <Box display="flex" alignItems={"top"}>
                    <Box flex={1} mr={"1em"}
                         style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                    >
                        <h4 style={{margin: 0}}>{translate("Holiday")}</h4>
                        <Holiday country_id={id}/>
                    </Box>
                    <Box flex={1}
                         style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                    >
                        <h4 style={{margin: 0}}>{translate("Available Language")}</h4>
                        <em style={{margin: 0}}>{translate("Relogin after updates language list")}</em>
                        <AvailableLanguage country_id={id}/>
                    </Box>
                </Box>
            }


        </Box>
    )
}
export const CountriesEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                    />
                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default CountriesEditCreate
