import React, {useEffect, useState} from "react";
import * as XLSX from "xlsx";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Box} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
// import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
// import get from "lodash/get";
import {choicesFieldsForExcel} from "../../_constants/choices";
import Button from "@material-ui/core/Button";

import Input from '@material-ui/core/Input';
import Chip from '@material-ui/core/Chip';

// import {makeStyles, useTheme} from '@material-ui/core/styles';
import {makeStyles} from '@material-ui/core/styles';
import {useNotify} from "ra-core";
import {loadPrice} from "../../_common/CompForApi/utilsAPI";
import Alert from "@material-ui/lab/Alert";
import {Checkbox, FormControlLabel} from "@mui/material";
// import {LinearProgress} from "@mui/material";

const useStyles = makeStyles((theme) => ({
    formControl: {
        //margin: theme.spacing(1),
        minWidth: 120,
        //maxWidth: 120,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

function ExcelToJsonConverter() {
    const classes = useStyles();
    //const theme = useTheme();
    const notify = useNotify();
    const [progress, setProgress] = useState(false);
    const [file, setFile] = useState(null);
    const [jsonData, setJsonData] = useState(null);
    const [header, setHeader] = useState([]);
    const [tab, setTab] = useState(0);
    const [listTab, setListTab] = useState([]);

    const [formFields, setFormFields] = useState(null);
    const [alarm, setAlarm] = useState('');

    const [ignorRow, setIgnorRow] = useState({});
    const [ignorAllRows, setIgnorAllRows] = useState(false);

    useEffect(() => {
        if (file) handleConvert({})
    }, [file]);// eslint-disable-line

    const handleTab = (event) => {
        setTab(event.target.value)
        handleConvert(event, event.target.value)
    }


    const handleLoadPrice = (event) => {
        console.log('formFields=======>', formFields)
        setAlarm(null)
        let isRequiredFields = false
        let arrFields = []
        Object.values(formFields).forEach(it => {
            arrFields = arrFields.concat(it)
        })
        if (arrFields.indexOf('category') >= 0 && arrFields.indexOf('name') >= 0) isRequiredFields = true

        if (isRequiredFields) {
            let res = {}
            jsonData.forEach((it, ind) => {
                if (!ignorRow[ind]) {
                    let tmp = {properties: []}
                    Object.entries(it).forEach(it2 => {
                        // console.log(it2[0], 'formFields=======>', it2[1])
                        if (formFields[it2[0]] !== undefined) {
                            formFields[it2[0]].forEach(it3 => {
                                if (it3 === 'properties') {
                                    tmp.properties.push(
                                        {
                                            name: it2[0],
                                            values: [
                                                {
                                                    value: it2[1],
                                                    id: it2[1]
                                                }
                                            ],
                                            id: it2[0]
                                        }
                                    )
                                } else if (it3 === 'category') {
                                    tmp[it3] = {
                                        id: it2[1],
                                        name: it2[1]
                                    }
                                } else tmp[it3] = it2[1]
                            })
                            // tmp[formFields[it2[0]]] = it2[1]
                        }
                    })
                    res[ind] = tmp
                }
            })
            console.log('res=======>', res)
            loadPrice(res, notify)
        } else {
            setAlarm('It is necessary to specify "category", "name" for columns')
            setTimeout(function () {
                setAlarm(null)
            }, 10000);
        }
    }

    const handleChangeFormExcel = (event) => {
        setFormFields({
            ...formFields,
            [event.target.name]: event.target.value
        })
    }

    const handleIgnorAllRows = (event) => {
        //console.log('========================>', event.target.checked)
        if (event.target.checked) {
            let i = 0
            let ign = {}
            while (i < jsonData.length) {
                ign[i++] = event.target.checked
            }
            setIgnorRow(ign)
        } else {
            setIgnorRow({})
        }
        setIgnorAllRows(event.target.checked)
    }
    const handleIgnorRow = (event, ind) => {
        //console.log('========================>', event.target.checked)
        setIgnorRow({
            ...ignorRow,
            [ind]: event.target.checked
        })
    }

    const handleConvert = (event, numList = 0) => {
        if (file) {
            setProgress(true)
            const reader = new FileReader();
            reader.onload = (e) => {
                const data = e.target.result;
                const workbook = XLSX.read(data, {type: "binary"});
                const sheetName = workbook.SheetNames[numList];
                const worksheet = workbook.Sheets[sheetName];

                setTab(numList)
                setListTab(workbook.SheetNames)

                const json = XLSX.utils.sheet_to_json(worksheet);
                let head = []
                let indHead = 0
                let countCol = 0
                if (json && json.length > 0) {
                    json.forEach((it, ind) => {
                        let lenIt = Object.keys(it).length
                        if (lenIt > countCol) {
                            countCol = lenIt
                            indHead = ind
                        }
                    })
                    Object.keys(json[indHead]).forEach((it, ind) => {
                        head.push(it)
                    })
                    setHeader(head)
                    setJsonData(json);
                }
                setIgnorRow({})
                setIgnorAllRows(false)
                setFormFields(null)
                console.log(numList, 'head=============>', head)
                console.log('json=============>', json)
                // setJsonData(JSON.stringify(json, null, 2));
                setProgress(false)
            };
            reader.readAsBinaryString(file);
        }
    };


    return (
        <Box display={'grid'} component={Paper} style={{width: '100%'}}>
            <Box m={'1em'}>
                <input
                    type="file"
                    accept=".xls,.xlsx"
                    onChange={(e) => setFile(e.target.files[0])}
                />
                {/*<button onClick={handleConvert}>Convert</button>*/}
            </Box>
            <Box m={'1em'} display={'flex'}>
                <FormControl variant="outlined" size={"small"} style={{width: 256}}>
                    <InputLabel id="demo-simple-select-label">Name list in Excel </InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={tab}
                        onChange={handleTab}
                        label={'Name list in Excel'}
                    >
                        {listTab.map((it, ind) => {
                            return (
                                <MenuItem value={ind} key={it}>{it}</MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <Button
                    style={{marginLeft: 24}}
                    disabled={!formFields}
                    variant="contained"
                    color="primary"
                    onClick={handleLoadPrice}
                    //fullWidth
                >
                    Load Price
                </Button>
            </Box>

            {/*<Box>
                {progress && <LinearProgress color="secondary"/>}
                <pre>{jsonData}</pre>
            </Box>*/}

            <Box m={'1em'}>
                {progress &&
                    <h4 style={{marginBottom: 0, color: "blue"}}>Loading...</h4>
                }
                {header.length > 0 && jsonData && !progress &&
                    <h4 style={{marginBottom: 0}}>{listTab[tab]} ({jsonData.length})</h4>
                }
                {alarm && <Alert severity='warning'>{alarm}</Alert>}
                <TableContainer>
                    <Table aria-label="spanning table" style={{width: '100%'}}>
                        <TableHead>
                            <TableRow>
                                {header.length > 0 &&
                                    <TableCell align={'center'}>
                                        <FormControlLabel
                                            //style={{marginBottom: -24}}
                                            control={
                                                <Checkbox
                                                    checked={ignorAllRows}
                                                    onChange={(event) => {
                                                        handleIgnorAllRows(event)
                                                    }}
                                                    //name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            //label="Table"
                                        />
                                    </TableCell>
                                }
                                {header.length > 0 && header.map((it, index) => {
                                    return (
                                        <TableCell align={'left'}
                                                   style={{fontWeight: "bold", minWidth: 80, verticalAlign: "bottom"}}
                                                   key={index}>
                                            <Box display={'grid'}>
                                                {it}
                                                <FormControl variant="outlined" size={"small"}
                                                             className={classes.formControl}>
                                                    {/*<InputLabel id="demo-simple-select-label">Age</InputLabel>*/}
                                                    <Select
                                                        multiple
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={formFields && formFields[it] !== undefined ? formFields[it] : []}
                                                        name={it}
                                                        onChange={handleChangeFormExcel}
                                                        input={<Input id="select-multiple-chip"/>}
                                                        renderValue={(selected) => (
                                                            <div className={classes.chips}>
                                                                {selected.map((value) => (
                                                                    /*<em key={value}>{value}</em>*/
                                                                    <Chip key={value} label={value}
                                                                          className={classes.chip}/>
                                                                ))}
                                                            </div>
                                                        )}
                                                    >
                                                        {choicesFieldsForExcel.map((it2, ind2) => {
                                                            let res = true
                                                            if (it2.id !== 'properties' && formFields) {
                                                                Object.entries(formFields).forEach(it3 => {
                                                                    if (it3[1].indexOf(it2.id) >= 0
                                                                        && it3[0] !== it) {
                                                                        res = false
                                                                    }
                                                                })
                                                            }
                                                            if (res)
                                                                return (
                                                                    <MenuItem value={it2.id}>{it2.name}</MenuItem>
                                                                )
                                                            else return null
                                                        })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </TableCell>
                                    )
                                })
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {header.length > 0 && jsonData && jsonData.map((it, index) => {
                                if (index < 1000)
                                    return (
                                        <TableRow key={index} style={{opacity: ignorRow[index] ? 0.3 : 1}}>
                                            <TableCell align={'center'}>
                                                <FormControlLabel
                                                    //style={{marginBottom: -24}}
                                                    control={
                                                        <Checkbox
                                                            checked={ignorRow[index] ? true : false}
                                                            onChange={(event) => {
                                                                handleIgnorRow(event, index)
                                                            }}
                                                            //name="checkedB"
                                                            color="primary"
                                                        />
                                                    }
                                                    //label="Table"
                                                />
                                            </TableCell>
                                            {header.map((it2, index2) => {
                                                return (
                                                    <TableCell align={'left'} key={index2}>
                                                        {it[it2] !== undefined ? it[it2] : ''}
                                                    </TableCell>
                                                )
                                            })
                                            }
                                            {/*{Object.values(it).map((it, index2) => {
                                                return (
                                                    <TableCell align={'left'} key={index2}>
                                                        {it}
                                                    </TableCell>
                                                )
                                            })
                                            }*/}
                                        </TableRow>
                                    )
                                else return null
                            })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

        </Box>
    );
}

export default ExcelToJsonConverter;