import PermIdentity from '@material-ui/icons/PermIdentity';
import {NewsCreate} from "./NewsCreate";
import {NewsEdit} from "./NewsEdit";
import {NewsList} from "./NewsList";


// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: NewsList,
    create: NewsCreate,
    edit: NewsEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;