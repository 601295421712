import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    useTranslate,
    SelectInput,
    AutocompleteInput, ReferenceInput, useLocale, TextInput, FormDataConsumer, BooleanInput,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
//import {choicesDefaultLanguage} from "../utils/choices";
// import {validateEmail} from "../utils/components/validateTable";
import {ImageInputComp} from "../../_common/CompReact";
import {BaseTab} from "./BaseTab";
import {validateEmail, validateReq} from "../../_constants/validateTable";
import {MyTranslatableInputsRich} from "../../_common/MyTranslatableInputsRich";
import {useEffect} from "react";
import {useForm} from "react-final-form";
import config from "../../utils/config";
import {fetchJson as httpClient} from "../../utils/keycloak";
import {choicesDiscountMode, choicesPriceMode} from "../../_constants/choices";

const BlockEditImg = props => {
    // const translate = useTranslate();
    //const {related_id} = props
    // const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box mb="1em" flex={1} display="flex" alignItems={"top"}>
                <Box flex={1} alignItems={"top"} mr="1em"
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'logo_large'}
                        /*label={`${'New'} ${'image'}`}*/
                        label={'Large logo - used in the header of the site'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Large logo</b> here (500kb max)</p>}
                    />
                </Box>

                <Box flex={1} alignItems={"top"}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'logo_middle'}
                        // label={`${'New'} ${'image'}`}
                        label={'Medium logo - used in the site footer and on static pages'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Medium logo</b> here (500kb max)</p>}
                    />
                </Box>
            </Box>

            <Box mb="1em" flex={1} display="flex" alignItems={"top"}>
                <Box flex={1} alignItems={"top"} mr="1em"
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'logo_small'}
                        // label={`${'New'} ${'image'}`}
                        label={'Small logo - auxiliary photo'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Small logo</b> here (500kb max)</p>}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'notification_image'}
                        // label={`${'New'} ${'image'}`}
                        label={'Notification image'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Notification image</b> here (500kb max)</p>}
                    />
                </Box>
            </Box>

            <Box mb="1em" flex={1} display="flex" alignItems={"top"}>
                <Box flex={1} alignItems={"top"} mr="1em"
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'favicon'}
                        // label={`${'New'} ${'image'}`}
                        label={'Favicon - displayed by the browser in the tab before the page title'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Favicon</b> here (500kb max)</p>}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}
                     style={{border: '1px solid #ddd', borderRadius: 16}}>
                    <ImageInputComp
                        maxSize={500000}
                        heightImg={180}
                        source={'image_watermark'}
                        // label={`${'New'} ${'image'}`}
                        label={'Image watermark'}
                        accept="image/*"
                        //validate={required()}
                        defaultValue={''}
                        placeholder={<p>Drop your <b>Image watermark</b> (500kb max)</p>}
                    />
                </Box>
            </Box>

        </Box>
    )
}
const BlockEditPrice = props => {
    const translate = useTranslate();
    // const {formData} = props
    // const locale = useLocale();

    return (
        <Box p="1em" fullWidth>

            <Box display="flex" alignItems={"top"}>
                <Box flex={'auto'} mr={"1em"}>
                    <BooleanInput
                        source="use_discount_by_supplier_brand"
                        label={translate('Use discount by supplier brand')}
                        defaultValue={false}
                    />
                </Box>
                <Box flex={'auto'}>
                    <BooleanInput
                        source="convert_discounts_to_buyers_currency"
                        label={translate('Convert discounts to buyers currency')}
                        defaultValue={false}
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <SelectInput
                        // optionText="name"
                        // optionValue="code"
                        source={`price_mode`}
                        choices={choicesPriceMode}
                        label={translate('Price mode')}
                        variant={"outlined"}
                        validate={validateReq}
                        fullWidth resettable
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <SelectInput
                        // optionText="name"
                        // optionValue="code"
                        source={`discount_mode`}
                        choices={choicesDiscountMode}
                        label={translate('Discount mode')}
                        variant={"outlined"}
                        validate={validateReq}
                        fullWidth resettable
                    />
                </Box>
                <Box flex={1}>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Base price type')}
                        source={'base_price_type'}
                        reference={'dPriceTypes'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Min retail price type')}
                        source={'min_retail_price_type'}
                        reference={'dPriceTypes'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}>
                    <ReferenceInput
                        label={translate('Recommended retail price type')}
                        source={'recommended_retail_price_type'}
                        reference={'dPriceTypes'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`${'name'}`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>

        </Box>
    )
}

const BlockEdit = props => {
    const translate = useTranslate();
    const {formData} = props
    const locale = useLocale();
    const formEdit = useForm();

    const [flag, setFlag] = React.useState(0);
    //const [mainCountry, setMainCountry] = React.useState(formData?.main_country);
    const [availableLang, setAvailableLang] = React.useState([]);


    useEffect(() => {
        console.log('formData=====1==============>', formData.main_country);
        const getData = async (main_country) => {
            let url = `${config.apiUrl}api/react-admin/main/AvailableLanguage?country=${main_country}&limit=100&ordering=-id&skip=0`
            try {
                const response = await httpClient(url);
                if (response.status >= 200 && response.status <= 300) {
                    const {json} = response
                    const {results} = json || {}
                    console.log('results================>', results)
                    setAvailableLang(results)
                } else {
                }
            } catch (err) {
            } finally {
            }
        }

        if (formData.main_country) {
            getData(formData.main_country)
        } else {
            setAvailableLang([])
        }

        if (flag > 0) formEdit.change('main_lang', null)
        setFlag(flag + 1)

    }, [formData.main_country]);// eslint-disable-line

    return (
        <Box p="1em" fullWidth>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'google_merchant_id'}
                        label={translate('Google Merchant ID')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <TextInput
                        validate={validateEmail}
                        variant={"outlined"}
                        source={'email_sender'}
                        label={translate('Email Sender')}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'company_name'}
                        label={translate('Company Name')}
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <TextInput
                        variant={"outlined"}
                        source={'region'}
                        label={translate('Region')}
                        fullWidth
                    />
                </Box>
            </Box>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'site_domain'}
                        label={translate('Site Domain')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <ReferenceInput
                        label={translate('Primary Phone')}
                        source={'primary_phone'}
                        reference={'dCompanyPhone'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`number`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Main Country')}
                        source={'main_country'}
                        reference={'dCountries'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`name[${locale}]`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1} alignItems={"top"}>
                    {/*<ReferenceInput
                        label={translate('Main Lang')}
                        source={'main_lang'}
                        reference={'dAvailableLanguage'}
                        filter={{country: mainCountry}}
                        validate={validateReq}
                    >
                        <AutocompleteInput optionText={`${'name'}`} optionValue={'code'}
                                           resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>*/}

                    <SelectInput
                        optionText="name"
                        optionValue="code"
                        source={`main_lang`}
                        choices={availableLang}
                        label={translate('Main Lang')}
                        variant={"outlined"}
                        validate={validateReq}
                        fullWidth resettable
                    />
                    {/*<AutocompleteInput
                        optionText="name"
                        optionValue="code"
                        source={`main_lang`}
                        choices={choicesDefaultLanguage}
                        label={translate('Main Lang')}
                        variant={"outlined"}
                        fullWidth resettable
                    />*/}
                </Box>
            </Box>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Main Customer')}
                        source={'main_customer'}
                        reference={'dCustomers'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`name`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <ReferenceInput
                        label={translate('Main Supplier')}
                        source={'main_supplier'}
                        reference={'dSuppliers'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`name`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Main Currency')}
                        source={'main_currency'}
                        reference={'dCurrencies'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`abbr`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1} alignItems={"top"}>
                    <ReferenceInput
                        label={translate('National Currency')}
                        source={'national_currency'}
                        reference={'dCurrencies'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`abbr`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Primary Branch')}
                        source={'primary_branch'}
                        reference={'dCompanyBranch'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`name[${locale}]`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>

                </Box>
                <Box flex={1}>
                    <ReferenceInput
                        label={translate('Main Manager Default')}
                        source={'main_manager_default'}
                        reference={'dManager'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`name`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <ReferenceInput
                        label={translate('Manager Default')}
                        source={'manager_default'}
                        reference={'dManager'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`name`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
                <Box flex={1}>
                    <ReferenceInput
                        label={translate('New User Manager')}
                        source={'new_user_manager'}
                        reference={'dManager'}
                        //filter={{country: id}}
                    >
                        <AutocompleteInput optionText={`name`} resettable
                                           variant={"outlined"} fullWidth/>
                    </ReferenceInput>
                </Box>
            </Box>

            {/*<Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'project_jira_code'}
                        label={translate('Project jira code')}
                        fullWidth
                    />
                </Box>
                <Box flex={1}/>
            </Box>*/}
        </Box>
    )
}

export const ConfigEditCreate = props => {//Не работает

    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                //initialValues={{source: localStorage.getItem('username')}}
                defaultValue={{source: localStorage.getItem('username')}}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            return (
                                <BlockEdit
                                    {...props}
                                    related_id={id}
                                    formData={formData}
                                    //promptData={promptData}
                                />
                            )
                        }}
                    </FormDataConsumer>

                </FormTab>
                <FormTab label={translate('Pricing')}>
                    <FormDataConsumer>
                        {({formData, ...rest}) => {
                            return (
                                <BlockEditPrice
                                    {...props}
                                    related_id={id}
                                    formData={formData}
                                    //promptData={promptData}
                                />
                            )
                        }}
                    </FormDataConsumer>

                </FormTab>
                <FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        //prompt={promptData.find(x => x.field === 'description')}
                        source={'company_description'}
                        label={translate('Company Description')}
                        fullWidth
                    />
                </FormTab>

                <FormTab label={translate('Media')}>
                    <BlockEditImg
                        {...props}
                        related_id={id}
                        //promptData={promptData}
                    />
                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        prompt={promptData.find(x => x.field === 'description')}
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>
                {id &&
                    <FormTab label={translate('Prices')}>
                        <Box p="1em" display="flex" fullWidth>
                            <Prices
                                {...props}
                                product_id={id}
                            />
                        </Box>
                    </FormTab>
                }
                {id &&
                    <FormTab label={translate('Variants')}>
                        <Box p="1em" display="flex" fullWidth>
                            <Variants
                                {...props}
                                product_id={id}
                            />
                        </Box>
                    </FormTab>
                }*/}
            </TabbedForm>

            {id &&
                <Box p="2em">
                    <h3>{translate('Related table')}</h3>
                    <BaseTab {...props} config_id={id}/>
                </Box>
            }

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default ConfigEditCreate
