import * as React from 'react';
import {
    Create,
    //useCreate,
    useRedirect
} from 'react-admin';

import PostEditActions from "../../_common/PostEditActions";
import OrderEditCreate from "./OrderEditCreate";

export const OrderCreate = props => {
    const {location} = props;
    const {search} = location;

    const redirect = useRedirect()

    let idSource = '';
    if(search){
        let url = new URL('https://test/' + search);
        idSource = url.searchParams.get('idSource')
    }

    return (
        <Create {...props}
            title={''}
            onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            //onSuccess={() => props.history.goBack()}
            //onSuccess={onSuccessData}
            actions={<PostEditActions/>}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
                <OrderEditCreate idSource={idSource}/>
        </Create>
    )
};