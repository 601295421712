import OrderIcon from '@material-ui/icons/AttachMoney';
import OrderList from "./OrderList";
import {OrderEdit} from "./OrderEdit";
import {OrderCreate} from "./OrderCreate";

//import OrderList from './OrderList';
//import OrderEdit from './OrderEdit';

const resource = {
    list: OrderList,
    edit: OrderEdit,
    create: OrderCreate,
    icon: OrderIcon,
};
export default resource;