import { useContext } from 'react';

export default function useGetContext(
	reactContext
) {
	const context = useContext(reactContext);

	if (!context) return {};

	return context;
}
