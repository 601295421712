import * as React from 'react';
import {
    TextField,
    ReferenceManyField,
    useTranslate, useLocale,
    NumberField, TextInput,
} from 'react-admin';


import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
// import {validateReq} from "../utils/components/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {NumberInputFloatComp} from "../../_common/CompReact";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;

    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {related_id} = props;
    const translate = useTranslate();
    // const locale = useLocale();

    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    size_chart: related_id,
                    source2: localStorage.getItem('username'),
                    // name2: record.name,
                    // country2: record.country2
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <TextInput
                        variant={"outlined"}
                        source={'value'}
                        label={translate('Value')}
                        fullWidth
                    />
                <TextInput
                        variant={"outlined"}
                        source={'url'}
                        label={translate('Url')}
                        fullWidth
                    />
                <NumberInputFloatComp
                        variant={"outlined"}
                        source={'index'}
                        label={translate('Index')}
                        fullWidth
                    />

            </RowForm>
        </>
    )
};

const SizeSpecification = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {related_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Size Specification"
                reference="dSizeSpecification"
                target="size_chart"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable

                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              related_id={related_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            related_id={related_id}/>}
                    //rowStyle={postRowStyle}
                >

                    <TextField source={`value`} label={translate('Value')}/>
                    <TextField source={`url`} label={translate('Url')}/>
                    <NumberField source={`index`} label={translate('Index')}/>

                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default SizeSpecification;