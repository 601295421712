import React, {createContext, useEffect, useMemo, useState} from 'react';
import {useQuery} from 'react-admin';
import {isEmpty} from 'lodash';

export const CategoriesTreeContext=
  createContext({
    flattenTree: {},
    tree: [],
    refetchTree: null
  });

export default function CategoriesTreeProvider({children}) {
  const { data, loading, error, refetch } = useQuery({
    type: 'postRaw',
    resource: 'api/get-product-group-tree2/',
    payload: { data: {max_item_tree_level: 1000} }
  });

  const [tree, setTree] = useState([]);

  useEffect(() => {
    setTree(data?.tree);
  }, [data])

  const flattenCategories = (items, parentId = null, flatMap = {}) => {
    items.forEach((item) => {
      const { sub_items, direct_parent, alternate, ...rest } = item;

      if (!flatMap[item.id]) {
        flatMap[item.id] = {
          ...rest,
          id: item.id,
          name: item.name,
          parent_id: item.parent_id,
          alternate: item.alternate,
          alternative_parents: item.alternate ? [parentId] : []
        };
      } else {
        if (item.alternate) {
          flatMap[item.id].alternative_parents.push(parentId);
        }
      }
      if (sub_items && sub_items.length > 0) {
        flattenCategories(sub_items, item.id, flatMap);
      }
    });

    return flatMap;
  }


  const transformTree = useMemo(() => {
    if (isEmpty(tree)) return  {}

    return flattenCategories(tree)
  }, [tree])

  const handleRefetchTree = () => {
    setTimeout(() => {
      refetch()
    }, 1000)
  }


  const contextValues = useMemo(
    () => ({
      flattenTree: transformTree,
      tree: tree,
      refetchTree: handleRefetchTree
    }),
    [transformTree, tree, refetch]
  );

  return (
    <CategoriesTreeContext.Provider value={contextValues}>
      {children}
    </CategoriesTreeContext.Provider>
  );}