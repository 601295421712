import * as React from 'react';
import {
    useRedirect
} from 'react-admin';

import EditComponentAdmin from "../../_common/EditComponentAdmin";
import OrderEditCreate from "./OrderEditCreate";

export const OrderEdit = props => {
    const redirect = useRedirect();
    const {location} = props;
    const {search} = location;
    let idSource = '';
    if(search){
        let url = new URL('https://test/' + search);
        idSource = url.searchParams.get('customerId')
    }
    /*
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = (data) => {
        notify(`Saved data`);
        redirect(pathname)
        refresh();
    };*/

    return (
        <EditComponentAdmin
            style={{maxWidth: '60em'}}
            {...props}
            title={''}
            //onSuccess={() => props.history.goBack()}
            onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            // onSuccess={onSuccess}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <OrderEditCreate idSource={idSource}/>
        </EditComponentAdmin>
    )
};