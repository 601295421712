import PermIdentity from '@material-ui/icons/PermIdentity';
import {BrandList} from "./BrandList";
import {BrandCreate} from "./BrandCreate";
import {BrandEdit} from "./BrandEdit";
// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: BrandList,
    create: BrandCreate,
    edit: BrandEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;