import * as React from 'react';

import {
    List, ListBase,
} from 'react-admin';

import {usePermissions} from "@react-admin/ra-rbac";
import PostPagination from "./PostPagination";
import PostBulkActionButtons from "./PostBulkActionButtons";
import {canAccess2} from "../utils/canAccess2";

const ListComponentAdmin = (props) => {
    const {
        handlerForCheckBox = false,
        handlerForCheckBox2 = false,
        handlerForCheckBox3 = false,
        exporterlabel = false,
        exporterFile = false,
        children,
        resource,
        is_bulkActionButtons = true,
        pagination = true,
        listBase = false,
    } = props;
    const {loading} = usePermissions();
    if (loading) return null;
    const canDelete =
        canAccess2({resource: `${resource}`, action: 'delete'});

    if (listBase)
        return (
            <ListBase {...props}
                //perPage={50}
                      empty={false}
            >
                {children}
            </ListBase>)
    else
        return (
            <List {...props}
                  perPage={24}
                  empty={false}
                  pagination={pagination ? <PostPagination/> : false}
                  bulkActionButtons={
                      canDelete && is_bulkActionButtons &&
                      <PostBulkActionButtons
                          canDelete={canDelete}
                          exporterlabel={exporterlabel}
                          exporterFile={exporterFile}
                          handlerForCheckBox={handlerForCheckBox}
                          handlerForCheckBox2={handlerForCheckBox2}
                          handlerForCheckBox3={handlerForCheckBox3}
                      />
                  }
            >
                {children}
            </List>
        )
};
export default ListComponentAdmin;
