import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    useTranslate,
    TextInput,
    AutocompleteInput,
    ReferenceInput,
    // useLocale,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

import {validateEmailReq} from "../../_constants/validateTable";
import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
import {NumberInputFloatComp} from "../../_common/CompReact";
// import CompanyBranchPhone from "./CompanyBranchPhone";
// import CompanyBranchWorkingHours from "./CompanyBranchWorkingHours";
import {BaseTab} from "./BaseTab";

const BlockEdit = props => {
    // const {id} = props
    const translate = useTranslate();
    //const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'name'}
                        label={translate('Name')}
                    />
                </Box>
                <Box flex={1}>
                    <MyTranslatableInputs
                        fullWidth
                        source={'address'}
                        label={translate('Address')}
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        fullWidth
                        source={'email'}
                        label={translate('E-Mail')}
                        validate={validateEmailReq}
                    />
                </Box>
                <Box flex={1} display={'flex'}>
                    <Box flex={1} mr={"1em"}>
                        <ReferenceInput
                            label={translate('Primary number')}
                            source={'primary_number'}
                            reference={'dCompanyBranchPhone'}
                            //filter={{question$id: id}}
                        >
                            <AutocompleteInput optionText={`number`} resettable
                                               variant={"outlined"} fullWidth/>
                        </ReferenceInput>
                    </Box>
                    <Box flex={1}>
                        <NumberInputFloatComp
                            variant={"outlined"}
                            source={'item_code_in_accounting_system'}
                            label={translate('Item code in accounting system')}
                            fullWidth
                        />
                    </Box>
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'lat'}
                        label={translate('Lat')}
                        fullWidth
                    />

                </Box>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'lon'}
                        label={translate('Lon')}
                        fullWidth
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                </Box>
                <Box flex={1}>
                </Box>
            </Box>

            {/*<Box display="grid" alignItems={"top"} maxWidth={'1000px'}>
                <Box flex={1} mb={"1em"}
                     style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                >
                    <h4 style={{margin: 0}}>{translate("Company Branch Phone")}</h4>
                    <CompanyBranchPhone branch_id={id}/>
                </Box>
                <Box flex={1}
                     style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                >
                    <h4 style={{margin: 0}}>{translate("Company Branch Working Hours")}</h4>
                    <CompanyBranchWorkingHours branch_id={id}/>
                </Box>
            </Box>*/}

        </Box>
    )
}
export const CompanyBranchEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                initialValues={{config: 1, source: localStorage.getItem('username'),}}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                    />
                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box p="2em">
                    <h3>{translate('Related table')}</h3>
                    <BaseTab {...props} branch_id={id}/>
                </Box>
            }


            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default CompanyBranchEditCreate
