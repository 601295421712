import React from 'react';
import {
  AutocompleteInput,
  NumberField, ReferenceField,
  ReferenceInput,
  ReferenceManyField, TextField,
  useEditContext,
  useLocale,
  useTranslate
} from 'react-admin';
import useMyStyles from '../../../utils/useMyStyles';
import {useSelectedColumns} from '@react-admin/ra-preferences';
import PostPagination from '../../../_common/PostPagination';
import MyEditableDatagridForReference from '../../../_common/MyEditableDatagridForReference';
import {RowForm} from '@react-admin/ra-editable-datagrid';

function ListEditForm(props) {
  const classes = useMyStyles();
  const translate = useTranslate();
  const locale = useLocale();

  const {record} = useEditContext();

  return (
    <>
      <RowForm
        {...props}
        initialValues={{
          product_group_parent: record?.id
        }}
        mutationOptions={{onSuccess: () => {}}}
      >
        <NumberField source="id" headerClassName={classes.headerShortWidth}/>

        <ReferenceInput
          label={translate('Category')}
          source={'product_group_child'}
          reference={'dProductsGroup'}
        >
          <AutocompleteInput optionText={`${'name'}[${locale}]`} resettable
                             variant={"outlined"} fullWidth/>
        </ReferenceInput>

      </RowForm>
    </>
  )
};

function ColumnsForList(resource, classes, locale) {
  const translate = useTranslate();

  const columns = {
    id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
    feature_type:
      <ReferenceField source="product_group_child" reference="dProductsGroup"
                      label={translate('Category')}
      >
        <TextField source={`name[${locale}]`}/>
      </ReferenceField>,
  }

  return columns;
};

export default function AlternativeChildrenCategory() {
  const locale = useLocale();
  const classes = useMyStyles();

  const columns = useSelectedColumns({
    preferences: `${'AlternativeParentsCategory'}categoryList.columns`,
    columns: ColumnsForList('AlternativeParentsCategory', classes, locale),
    omit: ["nb_views"],
  });

  return (
    <div style={{width: "100%", marginBottom: 16}}>
      <ReferenceManyField
        pagination={<PostPagination/>}
        perPage={12}
        fullWidth
        label=""
        reference="dProductsGroupRelations"
        target="product_group_parent"
      >
        <MyEditableDatagridForReference
          classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
          mutationMode="pessimistic"
          undoable
          createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                    related_id={1}/>}
          editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                  related_id={1}/>}
        >
          {columns}
        </MyEditableDatagridForReference>
      </ReferenceManyField>

    </div>
  )
}