//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField,
    TextField,
    useLocale,
    useTranslate, ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    // EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../../index.css';
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
//import {exporterPassenger} from "../utils/exporterFile";
import {BooleanFieldComp, DateFieldComp, FilterDataAside, TextFieldBold} from "../../_common/CompReact";
import CardComp from "../../_common/Asside/CardComp";
import CroppedLongField from "../../_common/CroppedLongField";
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import MySimpleList from "../../_common/MySimpleList";

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    // const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch/>
                <TrueFalseFilters field={'control_balance_qty'} label={'Control qty'}/>
                <TrueFalseFilters field={'virtual'} label={'Virtual'}/>
                <FilterDataAside fields={'updated'} name={'update'}/>
                {/*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*/}
            </CardContent>
        </CardComp>
    )
};

const TopFilters = (resource, locale) => {
    const translate = useTranslate();
    let dt = [];
    dt.push(<ReferenceInput source="user_country" reference="dCountries" label={translate('User Country')} alwaysOn>
        <AutocompleteInput
            optionText={`name[${locale}]`}
            resettable emptyText={'All Countries'}/>
    </ReferenceInput>);
    /*dt.push(<ReferenceInput source="prompt_type_id" reference="dPromptTypes" alwaysOn>
        <AutocompleteInput optionText="code" resettable emptyText={'All Types'}/>
    </ReferenceInput>);*/

    return dt;
}

const ColumnsForList = (resource, locale, classes, isSmall) => {
    /*
additional: {}
---control_balance_qty: false
---discount: "0.00"
---discount_algorithm: "BY_DISCOUNT_SUPPLIER_CUSTOMER"
discount_interval: null
id: 1
item_code_in_accounting_system: "Main"
---main_warehouse: 1
---name: "Main"
name_abbr: "Main"
name_repr: "Main"
---source: ""
---updated: "2023-12-08T19:43:21.083216"
---user_country: 1
---virtual: false
     */
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        // prompt: <TextFieldBold source={`prompt`} label={translate('Prompt')} cellClassName={classes.prompt_name}/>,
        name: <TextFieldBold source={`name`} label={translate('Name')}/>,
        user_country:
            <ReferenceField
                link={false}
                label={translate('User Country')}
                source={'user_country'}
                reference={'dCountries'}
                //filter={{question$id: id}}
            >
                <TextField source={`name[${locale}]`}/>
            </ReferenceField>,
        main_warehouse:
            <ReferenceField
                link={false}
                label={translate('Main Warehouse')}
                source={'main_warehouse'}
                reference={'dSuppliersWarehouses'}
                //filter={{question$id: id}}
            >
                <TextField source={`name[${locale}]`}/>
            </ReferenceField>,

        discount: <TextField source={`discount`} label={translate('Discount')}/>,
        discount_algorithm: <TextField source={`discount_algorithm`} label={translate('Discount Algorithm')}/>,
        discount_interval: <TextField source={`discount_interval`} label={translate('Discount Interval')}/>,

        control_balance_qty: <BooleanFieldComp source="control_balance_qty" label={translate("Control Balance Qty")}
                                         textAlign={"center"}/>,
        virtual: <BooleanFieldComp source="virtual" label={translate("Virtual")}
                                         textAlign={"center"}/>,
        source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

export const SuppliersList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                permissions={permissions}
                asideFilter={false}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
            is_bulkActionButtons={true}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}