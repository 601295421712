import * as React from "react";
import { useRecordContext } from 'react-admin';
import get from 'lodash/get';

const CroppedLongField = (props) => {
    const { source, long } = props;
    const record = useRecordContext(props);
    let sr = get(record, source, null)
    const value = sr !== null ? sr.slice(0, long) : null
    return (
        <span {...props} title={sr}>{value}{sr !== value && '...'}</span>
    );
}

export default CroppedLongField;