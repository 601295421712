import * as React from 'react';
import {TextField as TextFieldMaterial} from "@material-ui/core";
import {
    TextField,
    ReferenceManyField,
    //AutocompleteInput,
    //ReferenceInput,
    NumberField, useTranslate, useLocale, NumberInput,
    TextInput,
    //SingleFieldList,
    useRecordContext, ReferenceField, AutocompleteInput,
    ReferenceInput,
    // SimpleFormIterator,
    // SimpleForm,
    // ImageInput,
    // ImageField, useRedirect,
    useCreate,
    // useCreateController, Title,
    useNotify, useRefresh, useGetManyReference, useUpdate, useDelete,
} from 'react-admin';

// import {ReferenceManyToManyInput} from '@react-admin/ra-relationships';
//import {useFormContext} from "react-hook-form";

import {
    //EditableDatagrid,
    RowForm,
    // useCreateRowContext,
    // useEditRowContext
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
// import {DateFieldComp} from "../_common/CompReact";

// import {Box, Button, Grid} from "@material-ui/core";
// import {apiPathConfig} from "../utils/config";
// import {fetchJson as httpClient} from "../utils/keycloak";
// import Alert from '@material-ui/lab/Alert';
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import PostPagination from "../../_common/PostPagination";
// import {useForm} from "react-final-form";
import get from 'lodash/get';
// import CroppedLongField from "../_common/CroppedLongField";
import {Box} from "@mui/material";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import {useEffect, useState} from "react";
import {copyToS3bucketIm} from "../../_common/Transform";
import {Checkbox} from "@material-ui/core";

import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos';

import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';

const transform = (data) => {
    data.article = data.article2;
    delete data.article2;
    data.index = data.index2;
    delete data.index2;
    data.variant_color = data.variant_color2;
    delete data.variant_color2;
    data.source = data.source2;
    delete data.source2;


    return data;
}
const ListEditForm = (props) => {
    const classes = useMyStyles();
    const {product_id, record} = props;
    const translate = useTranslate();
    const locale = useLocale();

    //const record = useRecordContext(props);
    //const refresh = useRefresh();
    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };
    //const filterToQuery = searchText => ({for_invoice: `${searchText}`});
    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    product: product_id,
                    source2: localStorage.getItem('username'),
                    article2: record.article,
                    index2: record.index,
                    variant_color2: record.variant_color,
                }}
                transform={transform}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                <NumberField source="id" headerClassName={classes.headerShortWidth}/>
                <TextInput source="article2" label={translate('Article')} validate={validateReq}/>
                <NumberInput source="index2" label={translate('Index')} validate={validateReq}/>
                {/*<NumberInput source="variant_color2" label={translate('Variant Color')} validate={validateReq}/>*/}
                <ReferenceInput
                    source="variant_color2"
                    reference="VariantColor"
                    label={translate('Variant Color')}
                    link={false}
                >
                    {/*<MyVariantColor source={`rgb_code`}/>*/}
                    <AutocompleteInput
                        optionText={`name[${locale}]`}
                        resettable
                        //variant={"outlined"}
                        fullWidth
                    />
                </ReferenceInput>,

            </RowForm>
        </>
    )
};

const MyUpdateImg = (props) => {
    const {variantId, dateSelImg, setDateSelImg} = props

    const notify = useNotify();
    const refresh = useRefresh();
    const translate = useTranslate();

    const [filePreview, setFilePreview] = useState(null);
    // const [create, {error, data}] = useCreate();
    const [create, {error}] = useCreate();
    const [update, {error: error2}] = useUpdate();
    const [deleteOne, {error: error3}] = useDelete();

    // const { data, isLoading, error, refetch } = useGetOne('dVariantProduct', record.id);

    const iniForm = {
            image: '',
            //image_description: '',
            is_main: false,
            sort: 1,
            source: localStorage.getItem('username'),
            variant: variantId,//28,
        }
    const [form, setForm] = useState(iniForm);


    useEffect(() => {
        if (error) notify(JSON.stringify(error) + ' !!!', 'error');
    }, [error]);// eslint-disable-line
    useEffect(() => {
        if (error) notify(JSON.stringify(error) + ' !!!', 'error');
    }, [error2]);// eslint-disable-line
    useEffect(() => {
        if (error) notify(JSON.stringify(error) + ' !!!', 'error');
    }, [error3]);// eslint-disable-line

    useEffect(() => {
        if (dateSelImg?.id) {
            let img = get(dateSelImg, 'image', null)
            setFilePreview(img);
            setForm(dateSelImg)
        } else {
            setFilePreview(null);
            setForm(iniForm)
        }
    }, [dateSelImg]);// eslint-disable-line

    const handleClearImg = event => {
        setForm({
            ...form,
            image: ''
        })
        setFilePreview(null)
    }
    const onFileChange = event => {
        // Update the state
        let val;
        let name = event.target.name
        switch (event.target.name) {
            case 'is_main':
                val = event.target.checked;
                break;
            case 'sort':
                val = event.target.value;
                break;
            case 'image_description':
                val = event.target.value;
                break;
            case 'image':
                console.log('IMG===========>')
                const file = event.target.files[0];
                if (file && file.type !== undefined) {
                    val = file;
                    // setSelectedFile(file);
                    setFilePreview(URL.createObjectURL(file));
                    console.log('IMG===========>', URL.createObjectURL(file))
                }
                break;
            default:
                return
        }
        setForm({
            ...form,
            [name]: val
        })
    };

    const handleSaveImg = async (event) => {
        let res = await copyToS3bucketIm('dProduct', form)
        //console.log('RES===============>', res)
        let tmp = JSON.parse(JSON.stringify(form))
        tmp.image = res
        console.log('dateSelImg=========>', dateSelImg)
        if (tmp.id) {
            update('dVariantProductImage', tmp.id, tmp, dateSelImg)
            setDateSelImg(null)
        } else {
            create('dVariantProductImage', tmp)
            setDateSelImg(null)
            setTimeout(function () {
                refresh();
            }, 1000);
        }

    }

    const handleDeleteImg = event => {
        console.log('dateSelImg=========>', dateSelImg)
        if (form.id) {
            deleteOne('dVariantProductImage', form.id, dateSelImg)
            setDateSelImg(null)
            setTimeout(function () {
                refresh();
            }, 500);
        }

    }

    /*if (error) {
        return <p>ERROR</p>;
    }*/

    console.log('FORM=========>', form)


    return (
        <>
            <input
                hidden={true}
                type="file"
                id="fileselect"
                name="image"
                onChange={onFileChange}
                accept={"image/gif, image/jpeg, image/png"}
            />
            <Box display="grid" alignItems={"center"}>
                <Box display="flex" alignItems={"center"} p={'0 0 1em 0'}>
                    <Box mr={"1em"}>
                        <Checkbox
                            style={{'padding': "0px"}}
                            checked={form.is_main}
                            name={'is_main'}
                            onChange={onFileChange}
                            label={translate("Is main")}
                        />
                    </Box>
                    <Box flex={3} mr={"1em"}>
                        <TextFieldMaterial
                            disabled={true}
                            fullWidth
                            value={form.image_description}
                            size={"small"}
                            label={translate("Image Description")}
                            variant="outlined"
                            name={'image_description'}
                            onChange={onFileChange}
                        />
                    </Box>
                    <Box flex={1}>
                        <TextFieldMaterial
                            fullWidth
                            value={form.sort}
                            size={"small"}
                            label={translate("Sort")}
                            variant="outlined"
                            type={"number"}
                            name={'sort'}
                            onChange={onFileChange}
                        />
                    </Box>
                </Box>
                <Box display="flex" alignItems={"center"} p={'1em'}
                     style={{
                         minHeight: 132,
                         border: '1px solid #ddd',
                         borderRadius: 16,
                     }}
                >
                    <InsertPhotoIcon
                        onClick={() => {
                            document.getElementById('fileselect').click()
                        }}
                        style={{
                            cursor: "pointer",
                            position: "absolute",
                            top: 140,
                            right: 32,
                            fontSize: 32,
                            color: '#2196f3',
                        }}
                    />
                    {filePreview &&
                        <RemoveCircleOutlineIcon
                            onClick={handleClearImg}
                            style={{
                                cursor: "pointer",
                                position: "absolute",
                                top: 176,
                                right: 32,
                                fontSize: 32,
                                color: 'red',
                            }}
                        />
                    }

                    {filePreview && <img loading="lazy" width={200} src={filePreview} alt='new img'/>}
                </Box>

                <Box display="flex" alignItems={"center"} p={'1em 0 0 0'}
                     style={{
                         justifyContent: "space-between",
                     }}
                >
                    <Button
                        //variant="contained"
                        color={"default"}
                        onClick={handleSaveImg}
                    >
                        {form?.id ? 'Update Image' : 'Create Image'}
                    </Button>
                    <Button
                        disabled={!form?.id}
                        style={{color: "red"}}
                        //variant="contained"
                        color={"default"}
                        onClick={handleDeleteImg}
                    >
                        Delete Image
                    </Button>
                </Box>

            </Box>
        </>
    )
}

const MyImageDialog = (props) => {
    const {
        variantData,
        setDateSelImg,
        dateSelImg,
        variantId,
    } = props;
    const translate = useTranslate();

    const handleCloseDialog = (event) => {
        event.stopPropagation();
        setDateSelImg(null)
    };

    return (
        <Dialog
            open={dateSelImg !== null}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <>
                    <span
                        style={{color: '#3f51b5', fontSize: 16}}>
                        {translate('Article variant')}: <b>{variantData.article}</b>
                    </span>
                    {/*<Box style={{
                            width: 30,
                            height: 30,
                            borderRadius: '50%',
                            border: '1px solid black',
                            backgroundColor: variantData.rgb_code,
                        }}/>
                    </>*/}
                </>
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" style={{width: 480}}>
                    <MyUpdateImg
                        {...props}
                        variantId={variantId}
                        dateSelImg={dateSelImg}
                        setDateSelImg={setDateSelImg}
                    />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDialog} color="primary" autoFocus>
                    CLOSE
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const MyAllImg = (props) => {

    const {record} = props
    //const {source} = props;
    const [dataImg, setDataImg] = useState([]);
    const [dateSelImg, setDateSelImg] = useState(null);

    // const record = useRecordContext(props);

    const {data = {}, loaded} = useGetManyReference(
        'dVariantProductImage',
        'variant',
        record.id,
        {page: 1, perPage: 100},
        {},
        {},
    );

    useEffect(() => {
        if (loaded) {
            let tmp = Object.values(data)
            tmp = tmp.sort(function (a, b) {
                if (a.sort === b.sort) return 0
                if (a.sort > b.sort) {
                    return 1;
                } else return -1;
            });
            setDataImg(Object.values(tmp))
        }
    }, [data, loaded]);// eslint-disable-line

    return (
        <Box display={'flex'}>
            <MyImageDialog
                variantData={record}
                setDateSelImg={setDateSelImg}
                variantId={record.id}
                dateSelImg={dateSelImg}
            />
            <AddToPhotosIcon
                style={{fontSize: '36px', color: '#2196f3', cursor: "pointer"}}
                onClick={(e) => {
                    setDateSelImg({})
                }}
            />
            {dataImg.length > 0 && dataImg.map(it => {
                return (
                    <img
                        onClick={(e) => {
                            setDateSelImg(it)
                        }}
                        src={it.image}
                        alt={it.image}
                        height={50}
                        style={{
                            borderBottom: it.is_main && '3px solid #2196f3',
                            margin: 4,
                            borderRadius: 2,
                            cursor: "pointer",
                        }}
                    />
                )
            })
            }
        </Box>
    )
}

const MyVariantColor = (props) => {
    const {source} = props;
    const record = useRecordContext(props);
    let color = get(record, source, null);
    return (
        <Box style={{width: 30, height: 30, borderRadius: '50%', border: '1px solid black', backgroundColor: color}}/>
    )
}

const ColumnsForList = (resource, classes, locale) => {
    const translate = useTranslate();

    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        images: <MyAllImg label={translate('Images')}/>,
        /*<ReferenceManyField label="Images" reference="dVariantProductImage" target="variant" link={false}>
            <SingleFieldList link={false}>
                {/!*<ChipField source="small_img"/>*!/}
                {/!*<ImageField
                    sx={{ '& img': { maxWidth: 50, maxHeight: 50, objectFit: 'contain' } }}
                    source="image"
                    title="image"
                />*!/}
                <MyImageField
                    link={false}
                    source="image"
                    title="image"
                />
            </SingleFieldList>
        </ReferenceManyField>,*/
        article: <TextField source="article" label={translate('Article')}/>,
        index: <NumberField source="index" label={translate('Index')}/>,
        variant_color:
        /*<NumberField source="variant_color" label={translate('Variant Color')}/>,*/
            <ReferenceField source="variant_color" reference="VariantColor"
                            label={translate('Variant Color')} link={false}
            >
                <MyVariantColor source={`rgb_code`}/>
            </ReferenceField>,
        /*source: <CroppedLongField source="source" long={10} label={translate('Source')}/>,
        updated: <DateFieldComp source="updated" label={translate('Updated')} textAlign={'center'}
                                cellClassName={classes.date_st} showTime={true}/>,*/

    }
    return columns;
};

const Variants = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const {product_id, source} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    const columns = useSelectedColumns({
        preferences: `${'VariantRelation'}list.columns`,
        columns: ColumnsForList('VariantRelation', classes, locale),
        omit: ["nb_views"],
    });

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Variants"
                reference="dVariantProduct"
                target="product"
            >
                <MyEditableDatagridForReference
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale}
                                              source={source} product_id={product_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}
                                            source={source} product_id={product_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default Variants;