//import * as React from 'react';
import {fetchJson as httpClient} from "../../utils/keycloak";

import slugify from '@sindresorhus/slugify';
import get from 'lodash/get';
import config, {localesConfig} from "../../utils/config";

import {useCallback, useEffect, useState} from 'react';
//import DownloadIcon from '@material-ui/icons/GetApp';
import {
    //fetchRelatedRecords,
    useNotify,
    useListContext,
} from 'ra-core';
import {Button} from "@material-ui/core";
import DownloadIcon from "@material-ui/icons/GetApp";
// import moment from "moment";
import {Confirm, useTranslate} from "react-admin";
// import {Box, Step, StepButton, Stepper} from "@mui/material";
import {Box} from "@mui/material";
import moment from "moment";

const defaultIcon = <DownloadIcon/>;


export const getCustomerPricingProperty = async (setData) => {
    //const url = apiPathConfig.getApiUrl()
    let url = `${config.apiPrices}api/pricing/admin/get-customer-creation-properties-view`;
    try {
        const response = await httpClient(url);
        if (response.status >= 200 && response.status <= 300) {
            let res = get(response, 'json', [])
            // console.log('CustomerPricingProperty==!!!=====>', res);
            setData(res)
            return res
        } else {
            return null
        }
    } catch (err) {
        console.log('err=======>', err);
        return null
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}

export const createPartner = async (data, setData) => {
    //const url = apiPathConfig.getApiUrl()
    let report_url = `${config.apiUrl}api/create-partner-customers/`;
    try {
        const response = await httpClient(`${report_url}`, {
            method: 'post',
            body: JSON.stringify(data)
        });
        if (response.status >= 200 && response.status <= 300) {
            // let res = get(response, 'json.result', null)
            console.log('createPartner==!!!=====>', response.json.partner_id);
            setData({ok: 'Client saved', partner_id:  response.json?.partner_id})
            return true
        } else {
            setData({error: "ERROR STATUS SAVE"})
            return null
        }
    } catch (err) {
        console.log('err=======>', err.message);
        setData({error: err.message})
        //console.log('err=======>', Promise.reject(err));
        return null

    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}

export const getUserSearch = async (setForm, data) => {
    const {resource, value: vl, source, type = ''} = data
    let value = vl
    if (type === 'phone') {
        value = vl.replace(/\D/g, '')
    }
    let url
    switch (resource) {
        case 'user':
            url = `${config.apiUrl}api/react-admin/auth/User?search=${value}&limit=30&skip=0`;
            break
        case 'partner':
            url = `${config.apiUrl}api/react-admin/main/Partner?search=${value}&limit=30&skip=0`;
            break
        case 'customers':
            url = `${config.apiUrl}api/react-admin/main/Customers?search=${value}&limit=30&skip=0`;
            // https://dev-api.zenit-auto.com/api/react-admin/main/Customers?limit=24&ordering=-id&search=%D0%91%D0%BE%D1%80%D0%B8%D1%81&skip=0
            // https://dev-api.zenit-auto.com/api/react-admin/main/Customers?search=35-2024%D0%90&limit=30
            break
        default:
            return null
    }
    try {
        const response = await httpClient(url);
        if (response.status >= 200 && response.status <= 300) {
            let results = get(response, 'json.results', null)
            if (results && results.length > 0) {
                let res2 = []
                results.forEach(it => {
                    if (type === 'phone') {
                        if (it[source].replace(/\D/g, '') === value.replace(/\D/g, '')) res2.push(it)
                    } else {
                        if (it[source].replace(/\s+/g, ' ').trim().toUpperCase() === value.replace(/\s+/g, ' ').trim().toUpperCase()) res2.push(it)
                    }

                })
                if (res2.length > 0) {
                    let dt = null
                    dt = {
                        ...data,
                        data: res2,
                    }
                    //console.log('getUserSearch=======>', dt);
                    setForm(dt)
                    return dt
                } else return null
            } else return null
        } else return null
    } catch (err) {
        return null
        //console.log('err=======>', err);
    } finally {
    }
}
// =========================================================
export const CleanCache = (props) => {
    const {
        onClick,
        label,
        icon = false,
    } = props;

    const {resource} = useListContext(props);

    const notify = useNotify();
    const translate = useTranslate();

    const urlTranslate = 'api/clear-cache';
    const frTitle = `${translate('Clear Cache').toUpperCase()}`
    const frContent = `${translate('Are you sure you want to Clear Cache ?')}`
    const okRequest = `${translate('Start Clear Cache')}`

    const getData = async () => {
        let url = `${config.apiUrl}${urlTranslate}`;
        try {
            const response = await httpClient(url);

            if (response.status >= 200 && response.status <= 300) {
                notify(okRequest, {type: 'success', autoHideDuration: 5000,})
            } else {
                notify(translate('Error Request'), {type: 'error', autoHideDuration: 5000,});
            }
        } catch (err) {
            notify(translate('Error Request'), {type: 'error', autoHideDuration: 5000,});
        } finally {
        }
    }

    const [dialog, setDialog] = useState(false);
    const handleDialogClose = () => setDialog(false);
    const handleConfirm = () => {
        setDialog(false)
        getData();
    };

    const handleClick = useCallback(
        event => {
            setDialog(true)
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [notify, onClick, resource]
    );

    return (
        <>
            <Confirm
                isOpen={dialog}
                //loading={isLoading}
                title={frTitle}
                content={frContent}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
            <Button
                // style={{marginLeft: selectedIds.length > 0 && 24, marginTop: selectedIds.length > 0 && -4}}
                color={"primary"}
                onClick={handleClick}
                //label={label}
            >
                {icon ? icon : null} <Box ml={'4px'}>{label}</Box>
            </Button>
        </>
    );
};

/*{
    "expireAt": {
        "N": "1706639663"
    },
    "SK": {
        "S": "Products#1#json_ld#1706553263"
    },
    "PK": {
        "S": "demo"
    },
    "status": {
        "S": "in_queue"
    }
}*/
function getStatusValue(data) {
    let res = {}
    data.forEach((it) => {
        let st = get(it, 'status', {})
        st = get(st, 'S', null)
        let sk = get(it, 'SK', {})
        sk = get(sk, 'S', null)
        if (sk) {
            sk = sk.split('#')
            if (sk[1] !== undefined) {
                if (res[sk[1]] !== undefined) {
                    if (res[sk[1]][sk[2]] !== undefined) {
                        if (res[sk[1]][sk[2]].tm < sk[3]) {
                            res[sk[1]][sk[2]].st = st
                            res[sk[1]][sk[2]].tm = sk[3]
                        }
                    } else {
                        res[sk[1]][sk[2]] = {}
                        res[sk[1]][sk[2]].st = st
                        res[sk[1]][sk[2]].tm = sk[3]
                    }
                } else {
                    res[sk[1]] = {}
                    res[sk[1]][sk[2]] = {}
                    res[sk[1]][sk[2]].st = st
                    res[sk[1]][sk[2]].tm = sk[3]
                }
            }
        }
    })
    let stop = true
    Object.values(res).forEach((it) => {
        Object.values(it).forEach((it2) => {
            //console.log('Stop============>', it2.st)
            if (it2.st !== "success" && it2.st !== "error") stop = false
        })
    })

    return {stop: stop, res: res}
}

export const TranslateProduct = (props) => {
    const {
        isStatus = false,
        id = 'all',
        type = 'Products',
        name = 'Product',
        onClick,
        label,
        icon = false,
    } = props;

    const {resource} = useListContext(props);

    const notify = useNotify();
    const translate = useTranslate();
    const [status, setStatus] = useState(0);
    const [resultStatus, setResultStatus] = useState({});

    useEffect(() => {
        if (isStatus) getStatus()
    }, []);// eslint-disable-line

    useEffect(() => {
        if (isStatus) {
            setTimeout(() => {
                getStatus()
            }, 10000);
        }
    }, [status]);// eslint-disable-line

    let urlTranslate;
    let frTitle;
    let frContent;
    let okRequest;

    if (id === 'all') {
        urlTranslate = 'api/ai-processing/translate/all';
        frTitle = `${translate('Translate (all)').toUpperCase()}`
        frContent = `${translate('Are you sure you want to translate all ?')}`
        okRequest = `${translate('Start translate for ALL')}`
    } else if (id === 'keywords') {
        urlTranslate = `api/ai-processing/translate/keywords`;
        frTitle = translate(`Generate this ${name}`).toUpperCase()
        frContent = translate(`Are you sure you want to generate this ${name}?`)
        okRequest = translate(`Start generate for this ${name}`)
    } else {
        urlTranslate = `api/ai-processing/translate/${type}:${id}`;
        frTitle = translate(`Translate this ${name}`).toUpperCase()
        frContent = translate(`Are you sure you want to translate this ${name}?`)
        okRequest = translate(`Start translate for this ${name}`)
    }

    const getStatus = async () => {
        let tenant = localStorage.getItem('auth')
        if (tenant) {
            tenant = JSON.parse(tenant)
            tenant = get(tenant, 'tenant', null)
            if (tenant) {
                let url = `https://demo-server.swiss-linker.ch/jobstatus?pk=${tenant}&sk=${type}%23${id}`;
                // let url = `https://j7qmeo6gqk.execute-api.eu-central-1.amazonaws.com/Dev/jobstatus?pk=demo&sk=Products%23${id}`
                try {
                    const response = await httpClient(url);
                    if (response.status >= 200 && response.status <= 300) {
                        let res = get(response.json, 'Items', null)
                        res = getStatusValue(res)
                        setResultStatus(res.res)
                        if (res.stop) setStatus(0)
                        else if (status > 0) setStatus(status + 1)
                        // notify('STATUS: OK Request', {type: 'success', autoHideDuration: 5000,})
                    } else {
                        notify(translate('STATUS: Error Request'), {type: 'error', autoHideDuration: 15000,});
                        if (status > 0) setStatus(status + 1)
                    }
                } catch (err) {
                    if (status > 0) setStatus(status + 1)
                    notify(translate('STATUS: Error Request'), {type: 'error', autoHideDuration: 15000,});
                } finally {
                }
            }
        }
    }
    const getData = async () => {
        let url = `${config.apiUrl}${urlTranslate}`;
        try {
            //const response = await httpClient(`https://demo.iam-trade.ch/google-merchant/create/${id}/`);
            const response = await httpClient(url);

            if (response.status >= 200 && response.status <= 300) {
                notify(okRequest, {type: 'success', autoHideDuration: 5000,})
            } else {
                notify(translate('Error Request'), {type: 'error', autoHideDuration: 5000,});
            }
        } catch (err) {
            notify(translate('Error Request'), {type: 'error', autoHideDuration: 5000,});
        } finally {
        }
    }

    const [dialog, setDialog] = useState(false);
    const handleDialogClose = () => setDialog(false);
    const handleConfirm = async () => {
        setDialog(false)
        await getData();
        setStatus(1)
        if (isStatus) await getStatus();
    };

    const handleClick = useCallback(
        event => {
            setDialog(true)
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [notify, onClick, resource]
    );

    return (
        <Box display={"flex"}>
            <Confirm
                isOpen={dialog}
                //loading={isLoading}
                title={frTitle}
                content={frContent}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />

            <Box mr={"3em"} style={{minWidth: 200}}>
                <Button
                    // style={{marginLeft: selectedIds.length > 0 && 24, marginTop: selectedIds.length > 0 && -4}}
                    color={"primary"}
                    onClick={handleClick}
                    //label={label}
                >
                    {icon ? icon : null} <Box ml={'4px'}>{label}</Box>
                </Button>
            </Box>

            {isStatus && resultStatus[id] !== undefined &&
                Object.entries(resultStatus[id]).map((it, index) => {
                    return (
                        <Box mr={'2em'} p={'4px'}
                             key={index}
                             style={{
                                 verticalAlign: "middle",
                                 textAlign: "center",
                                 width: 200,
                                 border: '1px solid #ddd',
                                 backgroundColor: it[1].st === 'success'
                                     ? "lightgreen"
                                     : it[1].st === 'error' ? "red" : "lightgray",
                                 // color: it[1].st === 'success' ? "white": "black",
                                 borderRadius: 4,

                             }}
                        >
                            <span><b>{it[0]}:</b> {it[1].st}<br/>{moment(parseInt(it[1].tm * 1000)).format("D-MMM-YY, H:mm:ss")}</span>
                        </Box>
                    )
                })
            }
        </Box>
    );
};

export const AiProductForImage = (props) => {
    const {
        // isStatus = false,
        id = 'all',
        // type = 'Products',
        // name = 'Product',
        onClick,
        label,
        icon = false,
    } = props;

    const {resource} = useListContext(props);

    const notify = useNotify();
    const translate = useTranslate();
    // const [status, setStatus] = useState(0);
    // const [resultStatus, setResultStatus] = useState({});

    /*useEffect(() => {
        if (isStatus) getStatus()
    }, []);// eslint-disable-line

    useEffect(() => {
        if (isStatus) {
            setTimeout(() => {
                getStatus()
            }, 10000);
        }
    }, [status]);// eslint-disable-line*/

    let urlTranslate = 'api/ai-processing/fill-data';
    let frTitle = `${translate('AI for IMAGE').toUpperCase()}`
    let frContent = `${translate('Are you sure you want to make AI for IMAGE ?')}`
    let okRequest = `${translate('Start AI for IMAGE')}`

    /*const getStatus = async () => {
        let tenant = localStorage.getItem('auth')
        if (tenant) {
            tenant = JSON.parse(tenant)
            tenant = get(tenant, 'tenant', null)
            if (tenant) {
                let url = `https://demo-server.swiss-linker.ch/jobstatus?pk=${tenant}&sk=${type}%23${id}`;
                // let url = `https://j7qmeo6gqk.execute-api.eu-central-1.amazonaws.com/Dev/jobstatus?pk=demo&sk=Products%23${id}`
                try {
                    const response = await httpClient(url);
                    if (response.status >= 200 && response.status <= 300) {
                        let res = get(response.json, 'Items', null)
                        res = getStatusValue(res)
                        setResultStatus(res.res)
                        if (res.stop) setStatus(0)
                        else if (status > 0) setStatus(status + 1)
                        // notify('STATUS: OK Request', {type: 'success', autoHideDuration: 5000,})
                    } else {
                        notify(translate('STATUS: Error Request'), {type: 'error', autoHideDuration: 15000,});
                        if (status > 0) setStatus(status + 1)
                    }
                } catch (err) {
                    if (status > 0) setStatus(status + 1)
                    notify(translate('STATUS: Error Request'), {type: 'error', autoHideDuration: 15000,});
                } finally {
                }
            }
        }
    }*/
    const getData = async () => {
        let url = `${config.apiUrl}${urlTranslate}`;
        try {
            // const response = await httpClient(url);
            const response = await httpClient(`${url}`, {
                method: 'post',
                body: JSON.stringify({
                    "ids": [id],
                    "model": "Products"
                })
            });
            if (response.status >= 200 && response.status <= 300) {
                notify(okRequest, {type: 'success', autoHideDuration: 5000,})
            } else {
                notify(translate('Error Request'), {type: 'error', autoHideDuration: 5000,});
            }
        } catch (err) {
            notify(translate('Error Request'), {type: 'error', autoHideDuration: 5000,});
        } finally {
        }
    }

    const [dialog, setDialog] = useState(false);
    const handleDialogClose = () => setDialog(false);
    const handleConfirm = async () => {
        setDialog(false)
        await getData();
        // setStatus(1)
        // if (isStatus) await getStatus();
    };

    const handleClick = useCallback(
        event => {
            setDialog(true)
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [notify, onClick, resource]
    );

    return (
        <Box display={"flex"}>
            <Confirm
                isOpen={dialog}
                //loading={isLoading}
                title={frTitle}
                content={frContent}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />

            <Box mr={"3em"} style={{minWidth: 200}}>
                <Button
                    // style={{marginLeft: selectedIds.length > 0 && 24, marginTop: selectedIds.length > 0 && -4}}
                    color={"primary"}
                    onClick={handleClick}
                    //label={label}
                >
                    {icon ? icon : null} <Box ml={'4px'}>{label}</Box>
                </Button>
            </Box>

            {/*{isStatus && resultStatus[id] !== undefined &&
                Object.entries(resultStatus[id]).map((it, index) => {
                    return (
                        <Box mr={'2em'} p={'4px'}
                             key={index}
                             style={{
                                 verticalAlign: "middle",
                                 textAlign: "center",
                                 width: 200,
                                 border: '1px solid #ddd',
                                 backgroundColor: it[1].st === 'success'
                                     ? "lightgreen"
                                     : it[1].st === 'error' ? "red" : "lightgray",
                                 // color: it[1].st === 'success' ? "white": "black",
                                 borderRadius: 4,

                             }}
                        >
                            <span><b>{it[0]}:</b> {it[1].st}<br/>{moment(parseInt(it[1].tm * 1000)).format("D-MMM-YY, H:mm:ss")}</span>
                        </Box>
                    )
                })
            }*/}
        </Box>
    );
};


export const getProductsGroup = async (id) => {
    let url = `${config.apiUrl}api/react-admin/main/ProductsGroup?limit=100`;
    try {
        //const response = await httpClient(`https://demo.iam-trade.ch/google-merchant/create/${id}/`);
        const response = await httpClient(url);

        if (response.status >= 200 && response.status <= 300) {
            /*let res = get(response, 'json.kind', null)
            return res ? 'OK Google merchant' : 'Error Request'*/
            return response
        } else {
            return null
        }
    } catch (err) {
        return null
        //console.log('err=======>', err);
    } finally {
    }
}


export const GetGoogleMerchantAll = (props) => {
    const {
        action = 'create',
        onClick,
        label,
        icon = false,
        //exporter: customExporter,
    } = props;
    const {
        //exporter: exporterFromContext,
        resource,
        selectedIds = [],
    } = useListContext(props);

    //const exporter = customExporter || exporterFromContext;
    const notify = useNotify();
    // const refresh = useRefresh();
    const translate = useTranslate();

    let urlGoogleMerchant = 'google-merchant/export-all-products';
    let frTitle = `${translate('Google Merchant (Create)').toUpperCase()}`
    let frContent = `${translate('Are you sure you want to add all products to Google Merchant?')}`
    let okRequest = `${translate('All products are added to google merchant')}`
    if (action === 'delete') {
        urlGoogleMerchant = 'google-merchant/delete/__all__';
        frTitle = `${translate('Google Merchant (Delete)').toUpperCase()}`
        frContent = `${translate('Are you sure you want to remove all products from google merchant?')}`
        okRequest = `${translate('All products are removed from Google Merchant')}`
    }

    const getData = async () => {
        let url = `${config.apiUrl}${urlGoogleMerchant}`;
        try {
            //const response = await httpClient(`https://demo.iam-trade.ch/google-merchant/create/${id}/`);
            const response = await httpClient(url);

            if (response.status >= 200 && response.status <= 300) {
                notify(okRequest, {type: 'info', autoHideDuration: 3000,})
                /*let res = get(response, 'json.kind', null)
                res
                    ? notify(okRequest, {type: 'info', autoHideDuration: 3000,})
                    : notify(translate('Error Request'), {type: 'error', autoHideDuration: 3000,})*/
            } else {
                notify(translate('Error Request'), {type: 'error', autoHideDuration: 3000,});
            }
        } catch (err) {
            notify(translate('Error Request'), {type: 'error', autoHideDuration: 3000,});
            //console.log('err=======>', err);
        } finally {
            //console.log('finally=======>', 'finally');
            //refresh();
        }
    }

    /*useEffect(() => {
         if (result) {
         }
     }, [result]);// eslint-disable-line*/

    const [dialog, setDialog] = useState(false);
    const handleDialogClose = () => setDialog(false);
    const handleConfirm = () => {
        setDialog(false)
        getData();
    };

    const handleClick = useCallback(
        event => {
            setDialog(true)
            if (typeof onClick === 'function') {
                onClick(event);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [notify, onClick, resource, selectedIds]
    );

    return (
        <>
            <Confirm
                isOpen={dialog}
                //loading={isLoading}
                title={frTitle}
                content={frContent}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
            <Button
                style={{marginLeft: selectedIds.length > 0 && 24, marginTop: selectedIds.length > 0 && -4}}
                color={"primary"}
                onClick={handleClick}
                label={label}
            >
                {icon ? defaultIcon : null}{label}
            </Button>
        </>
    );
};


export const getGoogleMerchantInfo = async (id, notify = false) => {
    let url = `${config.apiUrl}google-merchant/info/${id}`;
    try {
        //const response = await httpClient(`https://demo.iam-trade.ch/google-merchant/create/${id}/`);
        const response = await httpClient(url);

        if (response.status >= 200 && response.status <= 300) {
            let res = get(response, 'json.entries', null)
            notify && notify('OK REQUEST', 'success'); //info success error
            return res ? res : null
        } else {
            notify && notify('ERROR REQUEST', 'error'); //info success error
            return null
        }
    } catch (err) {
        notify && notify(err, 'error');
        return null
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}

export const getGoogleMerchant = async (act, id, notify) => {
    let url = `${config.apiUrl}google-merchant/create/${id}`;
    let okReq = 'Товар успешно размещен. Информация обновится через несколько минут';
    switch (act) {
        case 'create':
            okReq = 'The product has been successfully LOCATED. Information will be updated in a few minutes.';
            url = `${config.apiUrl}google-merchant/create/${id}`;
            break;
        case 'delete':
            okReq = 'The product has been successfully REMOVED. Information will be updated in a few minutes.';
            url = `${config.apiUrl}google-merchant/delete/${id}`;
            break;
        default:
            okReq = 'The product has been successfully LOCATED. Information will be updated in a few minutes.';
            url = `${config.apiUrl}google-merchant/create/${id}`;
            break
    }

    try {
        //const response = await httpClient(`https://demo.iam-trade.ch/google-merchant/create/${id}/`);
        const response = await httpClient(url);

        if (response.status >= 200 && response.status <= 300) {
            /*let res = get(response, 'json.kind', null)
            return res ? 'OK Google merchant' : 'Error Request'*/
            notify(okReq, 'success'); //info success error
            return okReq
        } else {
            notify('ERROR REQUEST', 'error');
            return 'Error Request'
        }
    } catch (err) {
        notify(err, 'error');
        return 'Error Request'
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}

export const getPromptRequest = async (
    promptInfo,
    jsonData,
    model = "gpt-3.5-turbo",
) => {
    console.log('promptInfo==========>', promptInfo)
    console.log('jsonData==========>', jsonData)
    const {prompt = '', response_example = ''} = promptInfo || {}
    //let fraze = 'Generate a company description based on this json data, '
    let fraze = 'Based on this json data, '
    let example = response_example ? response_example : '{result: ...}'

    let data = {
        "model": model,
        // "response_example": "The result should be in json format. Like: {result: ...}",
        "response_example": `The result should be in json format. Like: ${example}`,
        "prompt": `${prompt} ${fraze} ${JSON.stringify(jsonData)}`//"Generate a company description based on this json data, "
    }

    //let report_url = `${config.apiPrompt}api-public/integration/ai/custom-prompt`;
    let report_url = `${config.apiPrompt}integration/ai/custom-prompt`;
    try {
        const response = await httpClient(`${report_url}`, {
            method: 'post',
            body: JSON.stringify(data)
        });
        if (response.status >= 200 && response.status <= 300) {
            let res = get(response, 'json.result', null)
            let answer = '';
            console.log('res=======>', res);
            if (res && res.result) {
                console.log('PROMPT=======>', res.result);
                // answer = typeof res.result === "string" ? res.result : JSON.stringify(res.result)
                try {
                    return typeof res.result === "string" ? res.result : JSON.stringify(res.result)
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    // setError('Try again');
                }
            }
            try {
                return JSON.stringify(res)
            } catch (error) {
                console.error('Error parsing2 JSON:', error);
                // setError('Try again');
            }
            //res.result === "string" ? res.result : JSON.stringify(res.result)
            return answer
        } else {
            return null
        }
    } catch (err) {
        return null
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}

export function getJsonData(prompt, formData) {
    let jsonData = {}
    let arrFields = prompt.info_fields
        ? prompt.info_fields.replace(/\s/g, '').split(',')
        : []
    arrFields.forEach(it => {
        let dt = get(formData, it, '')
        let dtLocale = get(dt, localesConfig.defaultLocale, '')
        if (dtLocale) jsonData[it] = dtLocale;
        else if (dt) jsonData[it] = dt;
    })
    return jsonData
}

export const getPrompt = async (prompt, formData) => {
    //const url = apiPathConfig.getApiUrl()
    let jsonData = getJsonData(prompt, formData)

    /*let jsonData = {}
    let arrFields = prompt.info_fields.replace(/\s/g, '').split(',')
    arrFields.forEach(it => {
        let dt = get(formData, it, '')
        let dtLocale = get(dt, localesConfig.defaultLocale, '')
        if (dtLocale) jsonData[it] = dtLocale;
        else if (dt) jsonData[it] = dt;
    })*/
    return getPromptRequest(prompt, jsonData, 'gpt-3.5-turbo')

}

export const getTransliteral = async (oriText, arrLocale) => {
    let tmp = {};
    //console.log('IT oriText=======>', oriText);
    arrLocale.forEach(lang => {
        // if (lang !== 'en') {
        let it = get(oriText, `${lang}`, '')
        // console.log('IT=======>', it);
        if (it) {
            tmp[lang] = {}
            tmp[lang].name = slugify(it, {
                separator: '-',
                lowercase: true,
                // replacement: '_',  // replace spaces with replacement character, defaults to `-`
                // remove: undefined, // remove characters that match regex, defaults to `undefined`
                // lower: false,      // convert to lower case, defaults to `false`
                // strict: false,     // strip special characters except replacement, defaults to `false`
                // locale: 'fr',      // language code of the locale to use
                // trim: true         // trim leading and trailing replacement chars, defaults to `true`
            })
        } else {
            tmp[lang] = {}
            tmp[lang].name = '';
        }
        // }
    })

    //console.log('oriText=========>', oriText)
    return tmp
}
export const getTranslateMany = async (obj_text, locale) => {
    //const url = apiPathConfig.getApiUrl()
    let data = {
        "model": "gpt-3.5-turbo",
        "languages": locale,
        "obj_for_translate": obj_text
    }
    let report_url = `${config.apiTranslate}api-public/integration/ai/translate`;
    try {
        const response = await httpClient(`${report_url}`, {
            method: 'post',
            body: JSON.stringify(data)
        });
        if (response.status >= 200 && response.status <= 300) {
            let res = get(response, 'json.result', null)
            // console.log('response=======>', res);
            return res
        } else {
            return null
        }
    } catch (err) {
        return null
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}
export const getTranslate = async (text, locale) => {
    //const url = apiPathConfig.getApiUrl()
    let data = {
        "model": config.gpt, //"gpt-3.5-turbo",
        "languages": locale,
        "obj_for_translate": {"name": text}
    }
    let report_url = `${config.apiTranslate}api-public/integration/ai/translate`;
    try {
        const response = await httpClient(`${report_url}`, {
            method: 'post',
            body: JSON.stringify(data)
        });
        if (response.status >= 200 && response.status <= 300) {
            let res = get(response, 'json.result', null)
            // console.log('response=======>', res);
            return res
        } else {
            return null
        }
    } catch (err) {
        return null
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}

export const loadPrice = async (data, notify) => {
    let report_url = `${config.apiUrl}upload_data/products`;
    try {
        const response = await httpClient(`${report_url}`, {
            method: 'post',
            body: JSON.stringify(data)
        });
        if (response.status >= 200 && response.status <= 300) {
            notify('Price list is fully loaded', 'success'); //info success error
            return 'Price list is fully loaded'
        } else {
            notify('ERROR Load Price', 'error');
            return 'Error Request'
        }
    } catch (err) {
        notify(err, 'error');
        return 'Error Request'
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}

export const removeBackground = async (url, notify) => {
    //const url = apiPathConfig.getApiUrl()
    let data = {
        "url": url,
    }
    let report_url = `https://integration.swiss-linker.ch/api/integration/remove-bg`;
    try {
        const response = await httpClient(`${report_url}`, {
            method: 'post',
            body: JSON.stringify(data)
        });
        if (response.status >= 200 && response.status <= 300) {
            let res = get(response, 'json', null)
            const {url, status} = res
            //console.log('response=======>', res);
            if (status) {
                return url
            } else {
                return null
            }
        } else {
            return null
        }
    } catch (err) {
        return null
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}

export const nominatimOpenStreetMapLatLon = async (form) => {
    //const url = apiPathConfig.getApiUrl()
    /*let data = {
        //'accept-language': 'ua',
        //'country': 'Україна',
        'country': 'Ukraine',
        'state': '*',
        'addressdetails': 1,

        'limit': 10,
        // 'format': 'geojson',
        'format': 'json',
    }
    let dt = []
    Object.entries(data).forEach((it) =>{
        dt.push(`${it[0]}=${it[1]}`)
    })
    const params = dt.join('&')*/

    let url = `https://nominatim.openstreetmap.org/search?q=${form.area_st}+${form.point_st}+${form.street_st}+${form.house}&format=json`
    url = encodeURI(url)

    try {
        /*const response = await httpClient(`${url}`, {
            method: 'post',
            body: JSON.stringify(data)
        });*/
        const response = await httpClient(url);
        if (response.status >= 200 && response.status <= 300) {
            let res = get(response, 'json', null)
            return res
        } else {
            return null
        }
    } catch (err) {
        return null
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}
export const nominatimOpenStreetMap = async (requestOverpass) => {
    //const url = apiPathConfig.getApiUrl()
    /*let data = {
        //'accept-language': 'ua',
        //'country': 'Україна',
        'country': 'Ukraine',
        'state': '*',
        'addressdetails': 1,

        'limit': 10,
        // 'format': 'geojson',
        'format': 'json',
    }
    let dt = []
    Object.entries(data).forEach((it) =>{
        dt.push(`${it[0]}=${it[1]}`)
    })
    const params = dt.join('&')*/

    //let url = encodeURI(`https://nominatim.openstreetmap.org/search?${params}`)
    //let report_url = `https://nominatim.openstreetmap.org/search`;
    // let url = 'https://nominatim.openstreetmap.org/search?q=17+Strada+Pictor+Alexandru+Romano%2C+Bukarest&format=geojson'
    let url = 'https://overpass-api.de/api/interpreter?data=[out:json];area[name="Київ"][admin_level=4];way(area)["highway"]["name:uk"];out tags;'
    url = `https://overpass-api.de/api/interpreter?data=${requestOverpass}`
    url = encodeURI(url)

    try {
        /*const response = await httpClient(`${url}`, {
            method: 'post',
            body: JSON.stringify(data)
        });*/
        const response = await httpClient(url);
        if (response.status >= 200 && response.status <= 300) {
            let res = get(response, 'json', null)
            const {elements} = res || []
            // console.log('response=======>', elements);
            // let result = elements.filter(x => x.tags && x.tags.name && x.tags.admin_level === '6')
            let result = elements.filter(x => x.tags && x.tags.name)
            return result
        } else {
            return null
        }
    } catch (err) {
        return null
        //console.log('err=======>', err);
    } finally {
        //console.log('finally=======>', 'finally');
        //refresh();
    }
}
