import * as React from 'react';
/*import {
    useRedirect,
} from 'react-admin';*/

import EditComponentAdmin from "../../_common/EditComponentAdmin";
import ProductsGroupEditCreate from "./ProductsGroupEditCreate";
import {useRedirect} from "react-admin";
import {copyToS3bucket} from "../../_common/Transform";

export const ProductsGroupEdit = props => {
    const {location, resource} = props;
    const {search} = location || {};
    let idSource = '';
    if (search) {
        idSource = search.split('idSource=')[1] ? search.split('=')[1] : '';
    }
    const redirect = useRedirect()

    return (
        <EditComponentAdmin
            {...props}
            transform={(event) => copyToS3bucket(resource, event)}
            title={' '}
            //editDialog={idSource}
            onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            /*onSuccess={() => idSource
                ? redirect(`/locating/${idSource}/2`)
                : redirect('list', props.basePath)
            }*/
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <ProductsGroupEditCreate idSource={idSource}/>
        </EditComponentAdmin>
    )
};