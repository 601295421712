//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, ReferenceField,
    SimpleList,
    TextField,
    useLocale, useTranslate,
    // EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {Box, CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../../index.css';
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
//import {exporterPassenger} from "../utils/exporterFile";
import {BooleanFieldComp, DateFieldComp, FilterDataAside} from "../../_common/CompReact";
import CardComp from "../../_common/Asside/CardComp";
import {choicesDefaultRelation, choicesRuleType} from "../../_constants/choices";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {FloatNumber} from "../../_common/CompFormat/utils";

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    // const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch/>
                <FilterDataAside fields={'created_at'} name={'created'}/>
                <FilterDataAside fields={'updated_at'} name={'updated'}/>
                {/*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*/}
                {/*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*/}
                {/*<TrueFalseFilters field={'is_load'} label={'Exported to Xero'}/>*/}
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
            </CardContent>
        </CardComp>
    )
};

const TopFilters = (resource, locale) => {
    let dt = [];
    /*dt.push(<ReferenceInput source="project_id" reference="projects" alwaysOn>
            <AutocompleteInput optionText="name" resettable emptyText={'All progects'}/>
        </ReferenceInput>);*/
    /*dt.push(<ReferenceInput source="customer_id" reference="customer" alwaysOn>
            <AutocompleteInput optionText="name" resettable emptyText={'All customers'}/>
        </ReferenceInput>);*/
    return dt;
}

const ColumnsForList = (resource, locale, classes) => {

    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        index: <NumberField source="index" label={translate('Index')}/>,
        name: <TextField source={'name'} label={translate('Name')}/>,
        active: <BooleanFieldComp source="active" label={translate("Active")} textAlign={"center"}/>,
        last_rule: <BooleanFieldComp source="last_rule" label={translate("Last Rule")} textAlign={"center"}/>,
        date_from: <DateFieldComp source="date_from" label={translate('Date From')} textAlign={'center'}
                                  cellClassName={classes.date_st}/>,
        date_to: <DateFieldComp source="date_to" label={translate('Date To')} textAlign={'center'}
                                cellClassName={classes.date_st}/>,
        rule_type:
            <SelectAndSaveChoices
                label={translate('Rule type')}
                source="rule_type"
                statuses={choicesRuleType}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                //edit={true}
                width={'12em'}
            />,
        discount_property_id:
            <ReferenceField source="discount_property_id" reference="discountProperty"
                            label={translate('Discount property')}
                            link={true}
                            sortBy={'discountProperty:name'}>
                <TextField source="name"/>
            </ReferenceField>,
        default_relation:
            <SelectAndSaveChoices
                label={translate('Default Relation')}
                source="default_relation"
                statuses={choicesDefaultRelation}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                //edit={true}
                width={'10em'}
            />,
        // index: <EditCommentFieldFromList source={"index"} label={translate('Index')} component={'textarea'} long={'5px'}/>,
        order_sum: <FloatNumber source={`order_sum`} label={translate('Min. amount')}/>,
        order_currency_id:
            <ReferenceField source="order_currency_id" reference="currency_z" label={translate('Currency')}
                            link={true}
                            sortBy={'discountProperty:name'}>
                <TextField source="name"/>
            </ReferenceField>,
        for_all_products: <BooleanFieldComp source="for_all_products" label={translate("For All Products")}
                                            textAlign={"center"}/>,
        default_discount: <NumberField source="default_discount" label={translate('Default Discount')}/>,
        created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
    }
    return columns;
};

export const DiscountRulesList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes)}
                permissions={permissions}
                // asideFilter={true}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
        >
            {isSmall
                ?
                <SimpleList
                    rowClick={false}
                    primaryText={<TextField source="name"/>}
                    secondaryText={null}
                    tertiaryText={
                        <Box
                            style={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '16px',
                                display: 'grid',
                            }}
                        >
                            {columns}
                        </Box>
                    }
                    linkType={false}//{record => record.canEdit ? "edit" : "show"}
                    rowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}