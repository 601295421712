import React, {useRef, useState, useEffect, useMemo} from 'react';
import {useMultiDrag, useMultiDrop} from 'react-dnd-multi-backend';
import {Box, MenuList, Typography, MenuItem, Stack, IconButton, Popover} from '@mui/material';
import {isEmpty, isFunction, join, map} from 'lodash';
import {TreeItem} from '@mui/x-tree-view/TreeItem';
import {useTranslate} from 'react-admin';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useCategoriesTreeActions from '../../../../_hooks/useCategoriesTreeActions';
import useGetContext from '../../../../_hooks/useGetContext';
import {CategoriesTreeContext} from '../../../_providers/CategoriesTreeProvider';

function CategoryDndItem({dragItem, parentPath = [], handleDrop, type = 'newCategory', parentCategoryItem}) {
  const translate = useTranslate();

  const {flattenTree, refetchTree} = useGetContext(CategoriesTreeContext)

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElSettings, setAnchorElSettings] = useState(null);

  const popperRef = useRef(null);

  const [didDropItem, setDidDropItem] = useState(null);

  const {
    handleRemoveAlternateParent,
    handleUpdateRelations,
    removeDirectParent,
    replaceDirectToAlternateParent,
  } = useCategoriesTreeActions();


  const [[{ isDragging }, dragRef, dragPreview]] = useMultiDrag({
    type: 'category',
    item: {...dragItem, parentPath, type},
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  });


  const [[{ canDrop, isOver }, dropRef]] = useMultiDrop({
    accept: ['category'],
    drop: (item, monitor) => {
      const isCanDrop = monitor.canDrop();
      const isOver = monitor.isOver();
      if (!isOver || !isCanDrop) return;

      setDidDropItem(item);

    },
    canDrop: (item, monitor) => {
      return dragItem?.id !== item?.id
    },
    collect: (monitor) => {
      return {
        canDrop: monitor.canDrop(),
        isOver: monitor.isOver({ shallow: true }),
      };
    },
  });

  useEffect(() => {
    if (popperRef.current) {
      setAnchorEl(popperRef.current);
    }
  }, [popperRef]);

  const handleClosePopover = () => {
    setDidDropItem(null);
  }

  const finallyDropItem = ({isDirect, isAlternative}) => () => {
    handleDrop(didDropItem, {...dragItem, parentPath, type}, {isDirect, isAlternative});
    handleClosePopover()
  }

  const handleOpenSettings = (event) => {
    event.stopPropagation();
    setAnchorElSettings(event.currentTarget);
  }

  const handleCloseSettings = (event) => {
    event.stopPropagation();
    setAnchorElSettings(null);
  }

  const directParentCategory = useMemo(() => {
    const directItem = flattenTree?.[dragItem?.product_group_parent_id];

    if (!directItem) return;

    return directItem;

  }, [dragItem, flattenTree])

  const handleReplaceDirectToAlternate = (event) => {
    event.stopPropagation();

    if (isEmpty(parentCategoryItem) || isEmpty(directParentCategory))

     handleUpdateRelations(
      dragItem, parentCategoryItem, true
    )

     handleUpdateRelations(
      dragItem, directParentCategory, false
    )

    if (isFunction(refetchTree)) {
      refetchTree()
    }
  }

  const removeAlternateParent = (event) => {
    event.stopPropagation();

    handleRemoveAlternateParent({...dragItem, parentPath, type}, parentCategoryItem);

    if (isFunction(refetchTree)) {
      refetchTree()
    }
  }

  const updateRelations = (event) => {
    event.stopPropagation();

    handleUpdateRelations(dragItem, parentCategoryItem, true)

    if (isFunction(refetchTree)) {
      refetchTree()
    }
  }

  const handleRemoveDirectParent = (event) => {
    event.stopPropagation();

    removeDirectParent(dragItem);

    if (isFunction(refetchTree)) {
      refetchTree()
    }
  }

  const handleReplaceDirectToAlternateParent = (event) => {
    event.stopPropagation();

    replaceDirectToAlternateParent(dragItem, parentCategoryItem);

    if (isFunction(refetchTree)) {
      refetchTree()
    }
  }


  return (
    <TreeItem
      ref={dropRef}
      onFocusCapture={e => e.stopPropagation()}
      label={
        <Box>
          <Box
            sx={{ opacity: isDragging ? 0.8 : 1, cursor: 'grab', backgroundColor: isOver ? '#ddd' : 'inherit' }}
            ref={dragRef}>
            <Stack direction='row' alignItems='center' spacing={2}>
              <Typography variant='subtitle1' color={dragItem?.alternate ? 'primary' : 'inherit'}>
                {dragItem?.name}
              </Typography>

              {!isEmpty(parentCategoryItem)
                ? <IconButton onClick={handleOpenSettings}>
                <MoreVertIcon/>
              </IconButton>
                : null}

              <Popover
                open={Boolean(anchorElSettings)}
                anchorEl={anchorElSettings}
                onClose={handleCloseSettings}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <MenuList>
                  {dragItem?.alternate && !isEmpty(parentCategoryItem)
                    ? <MenuItem
                    onClick={removeAlternateParent}>
                      {translate('remove_alternate_parent', {parent_name: parentCategoryItem?.name})}
                  </MenuItem>
                    : null}

                  {dragItem?.alternate
                  && !isEmpty(parentCategoryItem)
                  && !isEmpty(directParentCategory)
                    ? <React.Fragment>
                        <MenuItem onClick={handleReplaceDirectToAlternate}>
                          {translate('replace_alternate_to_parent', {
                            parent_name: parentCategoryItem?.name,
                            alternate_parent_name: directParentCategory?.name
                          })}
                        </MenuItem>

                        <MenuItem onClick={updateRelations}>
                          {translate('replace_parent', {
                            parent_name: parentCategoryItem?.name,
                            alternate_parent_name: directParentCategory?.name
                          })}
                        </MenuItem>
                      </React.Fragment>
                    : null}

                  {
                    !dragItem?.alternate && !isEmpty(parentCategoryItem)
                      ? <MenuItem onClick={handleRemoveDirectParent}>
                        {translate('remove_parent', {parent_name: parentCategoryItem?.name})}
                      </MenuItem>
                      : null
                  }

                  {
                    !dragItem?.alternate && !isEmpty(parentCategoryItem)
                      ? <MenuItem onClick={handleReplaceDirectToAlternateParent}>
                        {translate('make_alternative', {parent_name: parentCategoryItem?.name})}
                      </MenuItem>
                      : null
                  }


                  <MenuItem onClick={handleCloseSettings}>{translate('undo_action')}</MenuItem>
                </MenuList>
              </Popover>
            </Stack>
          </Box>

          <Box sx={{position: 'relative'}} ref={popperRef}>
            {anchorEl ? (
              <Popover
                open={Boolean(didDropItem)}
                anchorEl={anchorEl}
                onClose={handleClosePopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                <MenuList>
                  <MenuItem
                    onClick={finallyDropItem(
                      {isDirect: true, isAlternative: false})
                  }>
                    {translate('make_direct', {parent_name: dragItem?.name})}
                  </MenuItem>

                  <MenuItem onClick={finallyDropItem(
                    {isDirect: false, isAlternative: true})
                  }>
                    {translate('make_alternative', {parent_name: dragItem?.name})}
                  </MenuItem>

                  <MenuItem onClick={handleClosePopover}>{translate('undo_action')}</MenuItem>
                </MenuList>
              </Popover>
            ) : null}
          </Box>
        </Box>
      }
      nodeId={join(parentPath, '-')}>

      {!isEmpty(dragItem?.sub_items) ? map(dragItem?.sub_items, (childItem, key) => (
        <CategoryDndItem
          parentCategoryItem={{...dragItem, parentPath, type}}
          handleDrop={handleDrop}
          key={key}
          dragItem={childItem}
          parentPath={[...parentPath, 'sub_items', key]} />
      )) : null}
    </TreeItem>
  )
}

export default React.memo(CategoryDndItem)
