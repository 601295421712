import DefaultTable from '../DefaultTable/DefaultClass'

class Suffixes extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                name: 'brand',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Brands', optionText: 'name', locale: true},
            }
        ]
        this.filters_aside = [
            /*{
                name: 'original_brand',
                type: "TrueFalseFilters",
                parameters: [],
            },*/
        ]

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]

        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'brand',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Brands', optionText: 'name', locale: true},
                                //formTab: 'Common2222',
                                read_only: false
                            },
                            {
                                name: 'name',
                                type: 'text',
                                show_type: 'TextFieldBold',
                                edit_type: 'TextInput',
                                //type_ext: {locale: true},
                                read_only: false
                            },
                        ]
                    },
                    {
                        box: [
                            {
                                name: 'name_clean',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: 'TextInput',
                                //type_ext: {locale: true},
                                read_only: false
                            },
                            {
                                name: 'uuid',
                                type: 'text',
                                show_type: 'TextField',
                                edit_type: '',
                                read_only: false
                            },
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]

        /*this.related_table = [
            {
                name: 'CurrencyRates',
                filters: 'currency', //Фильтруем 'CurrencyRates' по этому полю (currency = Currencies.id)
            },
            {
                name: 'CurrencyFilter',
                filters: 'currency', //Фильтруем 'CurrencyFilter' по этому полю (currency = Currencies.id)
            },
            {
                name: 'CurrencyCrossRates',
                filters: 'base_currency', //Фильтруем 'CurrencyCrossRates' по этому полю (base_currency = Currencies.id)
            },
            {
                name: 'CurrencyForeignName',
                filters: 'currency', //Фильтруем 'CurrencyForeignName' по этому полю (currency = Currencies.id)
            },
        ]*/

    }
}

export default Suffixes