import * as React from 'react';
import {
    //EditButton,
    useRecordContext,
    TopToolbar,
    CreateButton, useLocale,
    // Button
} from 'react-admin';

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles_old from "../../utils/useMyStyles";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {universalColumnsForList} from "./universalColumnsForList";

// import AddIcon from "@material-ui/icons/Add";
// import {useHistory} from "react-router-dom";

/*const ButtonEditComp = props => {
    const record = useRecordContext();
    //console.log('record====>', record)
    return (
        <EditButton label={''} to={`/investment-pay/${record.id}`} {...props}/>
    )
}*/

const TableActions = props => {
    const {idSource, resourceTbl, filters} = props;
    // const history = useHistory();
    return (
        <TopToolbar fullWidth={true}>
            <CreateButton
                label={`Create New ${resourceTbl}`}
                to={`/${resourceTbl}/create/?idSource=${filters}-${idSource}`}
                //{...props}
            />
            {/*<Button
                label={'Create New Investment Pay'}
                onClick={(event) => history.push(`/investment-pay/create/?idInv=${idInv}`)}
            >
                <AddIcon/>
            </Button>*/}
        </TopToolbar>
    )
};
const RelatedTable = props => {

    //const {filterName, filterInvestmentName} = props;
    const classes = useMyStyles_old();
    const {resourceTbl, parameters} = props;
    const {filters} = parameters;

    const record = useRecordContext();
    const locale = useLocale();
    const columns = useSelectedColumns({
        preferences: `${resourceTbl}list.columns`,
        columns: universalColumnsForList(locale, classes, resourceTbl),
        omit: ["nb_views"],
    });
    console.log(resourceTbl,'record===========>', record)
    return (
        <>
            {/*<CreateButton to={`/teams/create/?idStUp=${record.id}`} {...props}/>*/}
            <ListComponentAdmin
                {...props}
                title={''}
                resource={resourceTbl}
                hasCreate={false}
                exporter={false}
                filter={{[filters]: record.id}}
                label={' '}
                sort={{field: 'id', order: 'DESC'}}
                mt={"-32px"}
                actions={<TableActions {...props} idSource={record.id} filters={filters}/>}
                //bulkActionButtons={false}
            >
                <DatagridComponentAdmin {...props} rowClick={(id) => `/${resourceTbl}/${id}/?idSource=${filters}-${record.id}`}>
                    {/*<ButtonEditComp label={''}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            </ListComponentAdmin>
        </>
    )
}

export default RelatedTable;
