import * as React from 'react';
import {
    Box, Button, FormControlLabel, Switch,
    TextField, Typography,
    useMediaQuery
} from "@material-ui/core";

import {
    useLocale,
    useTranslate
} from "react-admin";
import Asynchronous2 from "../../_common/AsynchronousRequests2";
import {LinearProgress} from "@mui/material";
import {useEffect, useMemo, useState} from "react";
import {SelectAddress} from "./SelectAddress";


export const EditAddress = props => {
    const {
        // selectedAddress,
        // setProcessing,
        processing,
        // partnerCustomers,
        handleChangeSel,
        handleChangeText,
        handleChangeCheck,
        form,
        // setForm,
        // iniCustomers,
        index,
        handleSetForm,
        mixCustomers,
    } = props

    const translate = useTranslate();
    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('sm');
    });

    const arrAddress = ['street', 'house', 'flat', 'point', 'region', 'area', 'placement']
    const arrAddressHand = ['street_hand', 'house', 'flat', 'point_hand', 'region_hand', 'area_hand', 'placement']

    const formEl = form.address[index]
    const [alarmMessage, setAlarmMessage] = useState(false);
    const [strAddress, setStrAddress] = useState(formEl.values.address);

    useEffect(() => {
        handleSetForm('address', index, 'address', strAddress)
    }, [strAddress]); // eslint-disable-line

    useEffect(() => {
        console.log(index, 'CHANGE====!!!=====>')
        let address = arrAddressHand
        if (formEl.values.auto_address) address = arrAddress
        let arr = []
        //let count = 0
        address.forEach(x => {
            if (formEl.values[x]) {
                //count += 1
                if(typeof formEl.values[x] === "object") arr.push(formEl.values[x].name)
                else arr.push(formEl.values[x])
            }
        })
        let adr = arr.join(', ')
        setStrAddress(adr)
    }, [formEl.values]); // eslint-disable-line

    const handleClickLatLon = async () => {
        setAlarmMessage(true)
        setTimeout(function () {
            setAlarmMessage(false)
        }, 3000);
        /*setProcessing(true)
        let res = await nominatimOpenStreetMapLatLon(formData)
        setProcessing(false)
        console.log('LAT/LON=====>', res)
        if (res && res[0]) {
            const {lat, lon} = res[0]
            formEdit.change('lat', lat)
            formEdit.change('lon', lon)
        }*/
    }

    console.log(index, 'ADDRESS============>', form.address[index])
    return (
        <Box p="1em" fullWidth>
            <Box mt={'-1em'} display="grid" alignItems={"top"}>
                <Typography variant="body" color="textPrimary">
                    <b>{translate("Common data")}</b>
                </Typography>
            </Box>
            <Box mt={1} display="grid" alignItems={"top"}>
                <Box mt={1} display="flex" alignItems={"top"}>
                    <Box flex={1}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={form.address[index].values.auto_address}
                                    onChange={(event) => handleChangeCheck('address', index, "auto_address", event)}
                                    color="primary"
                                    name="auto_address"
                                    inputProps={{'aria-label': 'primary checkbox'}}
                                />
                            }
                            label={translate('Automatic address filling')}
                        />

                    </Box>
                </Box>
                <Box mt={2} mb={2} display="grid" alignItems={"top"}>
                    <Box flex={1}>
                        <Typography variant="body" style={{fontSize: 16}}>
                            <b>{translate('Partners delivery address')}:</b> {strAddress}
                        </Typography>
                    </Box>
                </Box>
                {form.address[index].values.auto_address
                    ?
                    <>
                        {processing && <LinearProgress color="secondary"/>}
                        <SelectAddress
                            {...props}
                            index={index}
                        />
                        <Box mt={2} display="flex" alignItems={"center"}>
                            <Box flex={1} mr={'1em'}>
                            </Box>
                            <Box flex={1} mr={'1em'}>
                                {/*<Button
                                disabled={!(formData.area && formData.point && formData.street && formData.house)}
                                style={{
                                    backgroundColor: '#3f51b5',
                                    color: 'white',
                                    padding: 12,
                                    marginTop: -19,
                                    opacity: formData.area && formData.point && formData.street && formData.house ? 1 : 0.5
                                }}
                                // color={"primary"}
                                onClick={handleClickLatLon}
                                label={'Get Lat/Lon'}
                                fullWidth
                            >
                                {'Отримати координати'}
                            </Button>*/}
                            </Box>
                            <Box flex={1} mr={'1em'}>
                                <TextField
                                    fullWidth
                                    label={translate("House")}
                                    value={form.address[index].values.house}
                                    //name={'name'}
                                    onChange={(event) => handleChangeText('address', index, 'house', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.address[index].error?.house}
                                />
                            </Box>
                            <Box flex={1}>
                                <TextField
                                    fullWidth
                                    label={translate("Flat")}
                                    value={form.address[index].values.flat}
                                    //name={'name'}
                                    onChange={(event) => handleChangeText('address', index, 'flat', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.address[index].error?.flat}
                                />
                            </Box>
                        </Box>
                    </>
                    :
                    <>
                        <Box mt={2} display="flex" alignItems={"top"}>
                            <Box flex={1} mr={'1em'}>
                                <TextField
                                    fullWidth
                                    label={translate("Area")}
                                    value={form.address[index].values.area_hand}
                                    //name={'name'}
                                    onChange={(event) => handleChangeText('address', index, 'area_hand', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.address[index].error?.area_hand}
                                />
                            </Box>
                            <Box flex={1} mr={'1em'}>
                                <TextField
                                    fullWidth
                                    label={translate("Region")}
                                    value={form.address[index].values.region_hand}
                                    //name={'name'}
                                    onChange={(event) => handleChangeText('address', index, 'region_hand', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.address[index].error?.region_hand}
                                />
                            </Box>
                            <Box flex={1} mr={'1em'}>
                                <TextField
                                    fullWidth
                                    label={translate("Point")}
                                    value={form.address[index].values.point_hand}
                                    //name={'name'}
                                    onChange={(event) => handleChangeText('address', index, 'point_hand', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.address[index].error?.point_hand}
                                />
                            </Box>
                            <Box flex={1}>
                                <TextField
                                    fullWidth
                                    label={translate("Street")}
                                    value={form.address[index].values.street_hand}
                                    //name={'name'}
                                    onChange={(event) => handleChangeText('address', index, 'street_hand', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.address[index].error?.street_hand}
                                />
                            </Box>
                        </Box>
                        <Box mt={2} display="flex" alignItems={"center"}>
                            <Box flex={1} mr={'1em'}/>
                            <Box flex={1} mr={'1em'}/>
                            <Box flex={1} mr={'1em'}>
                                <TextField
                                    fullWidth
                                    label={translate("House")}
                                    value={form.address[index].values.house}
                                    //name={'name'}
                                    onChange={(event) => handleChangeText('address', index, 'house', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.address[index].error?.house}
                                />
                            </Box>
                            <Box flex={1}>
                                <TextField
                                    fullWidth
                                    label={translate("Flat")}
                                    value={form.address[index].values.flat}
                                    //name={'name'}
                                    onChange={(event) => handleChangeText('address', index, 'flat', event)}
                                    size={"small"}
                                    variant="outlined"
                                    //type={"number"}
                                    error={form.address[index].error?.flat}
                                />
                            </Box>
                        </Box>
                    </>
                }

                <Box mt={2} display="flex" alignItems={"top"}>
                    <Box flex={1} mr={'1em'}>
                        <TextField
                            fullWidth
                            label={translate("Name")}
                            value={form.address[index].values.name}
                            //name={'name'}
                            onChange={(event) => handleChangeText('address', index, 'name', event)}
                            size={"small"}
                            variant="outlined"
                            //type={"number"}
                            error={form.address[index].error?.name}
                        />
                    </Box>
                    <Box display="flex" flex={1}>
                        <Box flex={1}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={form.address[index].values.confirmed}
                                        onChange={(event) => handleChangeCheck('address', index, "confirmed", event)}
                                        color="primary"
                                        name="confirmed"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label={translate('Confirm')}
                            />
                        </Box>
                        <Box flex={1} mr={'1em'}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={form.address[index].values.disabled}
                                        onChange={(event) => handleChangeCheck('address', index, "disabled", event)}
                                        color="primary"
                                        name="disabled"
                                        inputProps={{'aria-label': 'primary checkbox'}}
                                    />
                                }
                                label={translate('Disable')}
                            />

                        </Box>
                    </Box>
                </Box>

                <Box mt={2} display="flex" alignItems={"top"}>
                    <Box display="flex" flex={1} mr={'1em'}>
                        <Box flex={1} mr={'1em'}>
                            <Button
                                //disabled={!(formData.area && formData.point && formData.street && formData.house)}
                                style={{
                                    backgroundColor: alarmMessage ? "lightgray" : '#3f51b5',
                                    color: 'white',
                                    padding: 11,
                                    // marginTop: 7,
                                    fontSize: 11,
                                    //opacity: formData.area && formData.point && formData.street && formData.house ? 1 : 0.5
                                }}
                                // color={"primary"}
                                onClick={handleClickLatLon}
                                label={'Get Lat/Lon'}
                                fullWidth
                            >
                                {alarmMessage
                                    ? <span style={{color: 'red'}}>{translate('Functionality under development')}</span>
                                    : <span>{translate('Check coordinates on the map')}</span>
                                }

                            </Button>
                        </Box>
                        <Box flex={1}>
                            <Button
                                //disabled={!(formData.area && formData.point && formData.street && formData.house)}
                                style={{
                                    backgroundColor: alarmMessage ? "lightgray" : '#3f51b5',
                                    color: 'white',
                                    padding: 11,
                                    // marginTop: 7,
                                    fontSize: 11,
                                    //opacity: formData.area && formData.point && formData.street && formData.house ? 1 : 0.5
                                }}
                                // color={"primary"}
                                onClick={handleClickLatLon}
                                label={'Get Lat/Lon'}
                                fullWidth
                            >
                                {alarmMessage
                                    ? <span style={{color: 'red'}}>{translate('Functionality under development')}</span>
                                    : <span>{translate('Fill in coordinates manually')}</span>
                                }
                            </Button>
                        </Box>
                    </Box>
                    <Box display="flex" flex={1}>
                        <Box flex={1} mr={'1em'}>
                            <TextField
                                type={"number"}
                                fullWidth
                                label={translate("Lat")}
                                value={form.address[index].values.lat}
                                //name={'name'}
                                onChange={(event) => handleChangeText('address', index, 'lat', event)}
                                size={"small"}
                                variant="outlined"
                                //type={"number"}
                                error={form.address[index].error?.lat}
                            />
                        </Box>
                        <Box flex={1}>
                            <TextField
                                type={"number"}
                                fullWidth
                                label={translate("Lon")}
                                value={form.address[index].values.lon}
                                //name={'name'}
                                onChange={(event) => handleChangeText('address', index, 'lon', event)}
                                size={"small"}
                                variant="outlined"
                                //type={"number"}
                                error={form.address[index].error?.lon}
                            />
                        </Box>
                    </Box>
                </Box>

                <Box mt={2} display="flex" alignItems={"top"}>
                    <Box flex={1}>
                        <Asynchronous2
                            multiple={true}
                            label={translate('Customers')}
                            value={form.address[index].values.customers}
                            setValue={(value) => handleChangeSel('address', index, 'customers', value)}
                            // domain={config.apiPrices}
                            //url={'api/react-admin/main/Delivery'}
                            choices={mixCustomers}
                            // outputField='id'
                            //searchField={'search'}
                            getOptionLabel={(option) => `${option.name}`}
                            error={form.address[index].error?.customers}
                        />
                    </Box>
                </Box>

                <Box mt={2} display="flex" alignItems={"top"}>
                    <Box flex={1}>
                        <Asynchronous2
                            multiple={true}
                            label={translate('Delivery Routes')}
                            value={form.address[index].values.deliveries}
                            setValue={(value) => handleChangeSel('address', index, 'deliveries', value)}
                            // domain={config.apiPrices}
                            url={'api/react-admin/main/Delivery'}
                            // outputField='id'
                            searchField={'search'}
                            getOptionLabel={(option) => `${option.name}[${option?.time_dispatch}][${option.additional?.week_days}]`}
                            error={form.address[index].error?.deliveries}
                        />
                    </Box>
                </Box>

                <Box mt={2} display="flex" alignItems={"top"}>
                    <Box flex={1} mr={'1em'}>
                        <TextField
                            multiline={true}
                            fullWidth
                            label={translate("Comment")}
                            value={form.address[index].values.comment}
                            //name={'name'}
                            onChange={(event) => handleChangeText('address', index, 'comment', event)}
                            size={"small"}
                            variant="outlined"
                            //type={"number"}
                            error={form.address[index].error?.comment}
                        />
                    </Box>
                    <Box flex={1}>
                        <TextField
                            multiline={true}
                            fullWidth
                            label={translate("Placement")}
                            value={form.address[index].values.placement}
                            //name={'name'}
                            onChange={(event) => handleChangeText('address', index, 'placement', event)}
                            size={"small"}
                            variant="outlined"
                            //type={"number"}
                            error={form.address[index].error?.placement}
                        />
                    </Box>
                </Box>
            </Box>

        </Box>
    )

}
