import * as React from 'react';
import PropTypes from 'prop-types';
//import Tabs from '@mui/material/Tabs';
//import Tab from '@mui/material/Tab';
import {Tabs, Tab, Box, Typography} from "@material-ui/core";
/*import {
    FormTab,
    TextInput,
} from 'react-admin';*/
//import {validateReq} from "../utils/validateTable";
// import {localesConfig} from "../utils/config";
import {useTranslate} from "react-admin";
import ProductsFeatureValue from "./ProductsFeatureValue";
import RelatedProducts from "./RelatedProducts";
import WarehouseStock from "./WarehouseStock";
import SizeProduct from "./SizeProduct";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const BaseTab = (props) => {

    const {related_id} = props;
    const translate = useTranslate();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}} style={{border: '1px solid #ccc', borderRadius: 8, zIndex: 2}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}
                 style={{backgroundColor: 'aliceblue', borderRadius: '8px 8px 0 0 '}}>
                {/*<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">*/}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // indicatorColor="primary"
                    // textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label={translate('Related Products')} {...a11yProps(0)} />
                    <Tab label={translate('Products Feature Value')} {...a11yProps(1)} />
                    <Tab label={translate('Warehouse Stock')} {...a11yProps(2)} />
                    <Tab label={translate('Size Product')} {...a11yProps(3)} />

                </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{width: "fit-content"}}>
                <RelatedProducts {...props} related_id={related_id}/>
            </TabPanel>

            <TabPanel value={value} index={1} style={{width: "fit-content"}}>
                <ProductsFeatureValue
                    {...props}
                    related_id={related_id}
                />
            </TabPanel>

            <TabPanel value={value} index={2} style={{width: "fit-content"}}>
                <WarehouseStock {...props} related_id={related_id}/>
            </TabPanel>

            <TabPanel value={value} index={3} style={{width: "fit-content"}}>
                <SizeProduct {...props} related_id={related_id}/>
            </TabPanel>
        </Box>
    );
}
