import DefaultTable from '../DefaultTable/DefaultClass'
import {
    JustBool,
    JustInt,
    JustRef,
    JustText,
    // JustTranslText
} from "../tablePattern";

class Customers extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                name: 'DiscountGroups',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'DiscountGroups', optionText: 'name', locale: true},
            },
        ]


        this.filters_aside = [{
                name: 'is_new_customer',
                type: "TrueFalseFilters",
                parameters: [],
            },]

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]


        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            JustText('name'),
                            JustText('official_name'),
                            //JustTranslText('official_name'),
                            JustText('phone'),
                        ]
                    },
                    {
                        box: [
                            JustText('item_code_in_accounting_system'),
                            JustText('edrpou'),
                        ]
                    },
                    {
                        box: [
                            JustText('contractor_type'),
                            JustText('tax_number'),
                            JustText('nds_key'),
                        ]
                    },
                    {
                        box: [
                            JustText('address'),
                            JustText('delivery_address'),
                            JustText('billing_address'),
                            // JustTranslText('address'),
                            // JustTranslText('delivery_address'),
                            // JustTranslText('billing_address'),
                        ]
                    },
                    {
                        box: [
                            JustRef('discount_group'),
                            JustText('discount'),
                        ]
                    },
                    {
                        box: [
                            JustText('retail_margin'),
                            JustText('discount_source'),
                            JustText('discount_interval'),
                        ]
                    },
                    {
                        box: [
                            JustText('credit_limit_sum'),
                            JustText('credit_limit_days'),
                        ]
                    },
                    {
                        box: [
                            JustInt('quantity'),
                            JustText('share_of_the_cost'),
                        ]
                    },
                    {
                        box: [
                            JustText('how_show_quantity'),
                            JustBool('is_new_customer'),
                            JustBool('dropshipping'),
                        ]
                    },
                    {
                        box: [
                            JustText('personal_account'),
                            JustBool('show_token_for_user'),
                            JustBool('show_base_price_type'),
                        ]
                    },
                    {
                        box: [
                            JustBool('show_min_retail_price_type'),
                            JustBool('show_recommended_retail_price_type'),
                        ]
                    },
                    {
                        box: [
                            JustRef('user_country', 'Countries'),
                            JustRef('credit_limit_currency', 'Currencies'),
                            JustRef('base_warehouse_main_supplier', 'SuppliersWarehouses'),
                        ]
                    },
                    {
                        box: [
                            JustText('manager'),
                            JustText('main_manager'),
                            JustText('delivery_branch'),
                        ]
                    },
                    {
                        box: [
                            JustRef('region'),
                            JustRef('price_type', '', 1, 'name', false),
                        ]
                    },
                    {
                        box: [
                            JustText('default_contract'),
                            JustText('default_bonus_contract'),
                            JustText('payment_qr_code'),
                        ]
                    },
                    {
                        box: [
                            JustText('uuid'),
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]
    }
}

export default Customers