import * as React from 'react';
import {
    TabbedForm,
    FormTab,
    //useLocale,
    useTranslate,
    TextInput,
    BooleanInput, SelectInput,
} from 'react-admin';
import {Box, Typography} from "@material-ui/core";
import PostEditDeleteActions from "../../_common/PostEditDeleteActions";
//import {validateSumaRec} from "../utils/validateTable";
import {DateFieldComp} from "../../_common/CompReact";

//import {validateReq} from "../utils/components/validateTable";
import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
import {currencyRounding} from "../../_constants/choices";
import CurrencyRates from "./CurrencyRates";
import CurrencyCrossRates from "./CurrencyCrossRates";
// import TranslateIcon from '@material-ui/Translate';
// import GTranslateIcon from '@material-ui/GTranslate';

//import { JsonSchemaForm } from "@react-admin/ra-json-schema-form";


const BlockEdit = props => {
    const{id} = props
    const translate = useTranslate();
    //const locale = useLocale();
    // const formEdit = useForm();

    return (
        <Box p="1em" fullWidth>
            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <MyTranslatableInputs
                        // prompt={promptData.find(x => x.field === 'name')}
                        fullWidth
                        source={'name'}
                        label={translate('Name')}
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        fullWidth
                        source={'simbol'}
                        label={translate('Symbol')}
                    />
                </Box>
                <Box flex={1} mr={"1em"}>
                    <TextInput
                        variant={"outlined"}
                        source={'abbr'}
                        label={translate('Abbr')}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        variant={"outlined"}
                        source={'iso_code'}
                        label={translate('Iso code')}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}>
                    <SelectInput source={'rounding'} choices={currencyRounding} resettable variant={"outlined"}
                                 label={translate('Rounding')} fullWidth/>
                </Box>
                <Box flex={1}  mr={"1em"}>
                    <BooleanInput source={'hidden'} label={translate('Hidden')}
                                      defaultValue={false}
                                      fullWidth/>
                </Box>
                <Box flex={1} mr={"1em"}/>
                <Box flex={1}/>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={"1em"}
                     style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                >
                    <h4 style={{margin: 0}}>{translate("Currency Rates")}</h4>
                    <CurrencyRates currency_id={id}/>
                </Box>
                <Box flex={1}
                     style={{border: '1px solid #ddd', borderRadius: 8, padding: '1em'}}
                >
                    <h4 style={{margin: 0}}>{translate("Currency Cross Rates")}</h4>
                    <CurrencyCrossRates currency_id={id}/>
                </Box>
            </Box>
            CurrencyRates

        </Box>
    )
}
export const CurrenciesEditCreate = props => {//Не работает
    /*const configureQuill = quill => quill.getModule('toolbar').addHandler('image', function (value) {
        this.quill.format('image', value)
    });*/
    const translate = useTranslate();
    const {record} = props;
    const {id} = record || {};

    return (
        <Box>

            <TabbedForm
                {...props}
                toolbar={<PostEditDeleteActions goBack={false}/>}
                //redirect={redirect_url}
            >
                <FormTab label={translate('Common')}>
                    <BlockEdit
                        {...props}
                        id={id}
                    />
                </FormTab>
                {/*<FormTab label={translate('Description')}>
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={'Description'}
                        fullWidth
                    />
                </FormTab>*/}
            </TabbedForm>

            {id &&
                <Box display="flex" alignItems={"center"} p="1em">
                    <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                        <Box flex={1} style={{display: "flex"}} mr={'2em'}>
                            <Typography variant="subtitle2"
                                        gutterBottom>{translate('Source')}: <b>{record.source}</b></Typography>
                        </Box>
                    </Box>
                    <Box flex={1} align={'end'}>
                        <Box display="grid">
                            <div>Updated: <DateFieldComp source="updated" variant="subtitle2" fullWidth/></div>
                        </Box>
                    </Box>
                </Box>
            }

        </Box>
    )
};

export default CurrenciesEditCreate
