import PermIdentity from '@material-ui/icons/PermIdentity';
import {SizeChartCreate} from "./SizeChartCreate";
import {SizeChartEdit} from "./SizeChartEdit";
import {SizeChartList} from "./SizeChartList";


// import {
//     ShowGuesser,
//     // EditGuesser
// } from 'react-admin';


// import {BaseListIdNameEdit} from "./BaseListIdNameEdit";
// import {BaseListIdNameCreate} from "./BaseListIdNameCreate";

const resource = {
    list: SizeChartList,
    create: SizeChartCreate,
    edit: SizeChartEdit,
    // show: ShowGuesser,
    icon: PermIdentity,
};

export default resource;