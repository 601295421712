import * as React from 'react';

import {
    DeleteButton,
    SaveButton,
    Toolbar, useNotify, useRedirect, useTranslate,
} from 'react-admin';
import {usePermissions} from "@react-admin/ra-rbac";
import BackButton, {Reload} from "./BackButton";
import {canAccess2} from "../utils/canAccess2";

const PostEditDeleteActions = (
    {
        reload = false,
        del = true,
        saveAndClose = '',
        action = 'all',
        goBack = false,
        isSave = true,
        ...props
    }) => {
    const {resource} = props;
    const {loading} = usePermissions();

    const translate = useTranslate();
    const redirect = useRedirect()
    const notify = useNotify();

    if (loading) return null;
    // const canDelete = canAccess({permissions, resource: 'tickets.delete', action: 'delete'})
    // const canDelete = canAccess({permissions, resource: `${resource}`, action: 'delete'})
    const canDelete = canAccess2({resource: `${resource}`, action: 'delete'})
    const canEdit = canAccess2({resource: `${resource}`, action: 'edit'})

    //console.log('resource=====>', canDelete, resource, props);
    if (action === 'withoutButtons') return null;
    return (
        <Toolbar {...props} style={{display: "flex", justifyContent: "space-between"}}>
            {/*{resource === 'users'
                ?
                <SaveButton
                    submitOnEnter={true}
                    transform={data => {
                        delete data.password
                        return ({...data})
                    }}/>
                :
                <SaveButton
                    submitOnEnter={true}
                    //onClick={() => alert('Saving...')}
                />
            }*/}
            {canEdit && isSave && <SaveButton/>}
            {canEdit && isSave && saveAndClose &&
                    <SaveButton
                    label={translate('Save & Close')}
                    onSuccess={data => {
                        notify(`Saved data`);
                        redirect(saveAndClose)
                    }}
                    submitOnEnter={true}
                    //onClick={() => alert('Saving...')}
                />
            }
            {reload &&
                <Reload/>
            }
            {goBack && <BackButton name={translate('Go Back')}/>}
            {del && canDelete && action !== 'create' && <DeleteButton/>}
        </Toolbar>
    );
}

export default PostEditDeleteActions;
