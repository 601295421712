import * as React from 'react';
import {
    AutocompleteInput, ReferenceInput,
    TextInput,
    BooleanInput,
    //TranslatableInputs,
    NumberInput,
    SelectInput, TextField, DateField,
    ImageInput,
    ImageField, useTranslate,
} from 'react-admin';
//import RichTextInput from "ra-input-rich-text";
// import {Box} from "@material-ui/core";
//import {Box, Typography} from "@material-ui/core";
import {DateInputComp, GetFieldById, ImageFieldComp, NumberInputFloatComp} from "../../_common/CompReact";
//import {localesConfig} from "../utils/config";
import {Box, Typography} from "@material-ui/core";
//import {toolbarRichTextInput} from "../utils/choices";
import {MyTranslatableInputs} from "../../_common/MyTranslatableInputs";
import {MyTranslatableInputsRich} from "../../_common/MyTranslatableInputsRich";

export const BuildEditComponent = (data, locale, idSource = '') => {
    const translate = useTranslate();
    const {name: source, edit_type, type_ext, label=source, slug = false} = data;
    let lab = translate(label);

    const {reference, optionText, locale: loc, choices = ['Not found'], multiline = false} = type_ext || {};
    let arr_source = [];
    if (idSource) arr_source = idSource.split('-');

    if (edit_type) {
        switch (edit_type) {
            case 'TextField':
                return (
                    <TextField source={source} label={lab} fullWidth/>
                );
            case 'DateField':
                return (
                    <DateField source={source} label={lab} fullWidth/>
                );
            case 'DateInputComp':
                return (
                    <DateInputComp source={source} label={lab} fullWidth/>
                );
            //------------------------------------------------------------
            case 'TextInput':
                return (
                    <TextInput source={source} label={lab} multiline={multiline} variant={"outlined"} fullWidth/>
                );
            case 'BooleanInput':
                return (
                    <BooleanInput source={source} label={lab} defaultValue={false} fullWidth/>
                );
            case 'NumberInput':
                return (
                    <NumberInput source={source} label={lab} variant={"outlined"} fullWidth/>
                )
            case 'NumberInputFloatComp':
                return (
                    <NumberInputFloatComp source={source} lab={source} variant={"outlined"} fullWidth/>
                );
            case 'TranslatableInputs':
                return (
                    <MyTranslatableInputs
                        fullWidth
                        source={source}
                        label={lab}
                        slug={slug}
                    />
                    /*<TranslatableInputs
                        locales={localesConfig.locales}
                        defaultLocale={locale}
                    >
                        <TextInput source={source} lab={source} multiline={multiline} fullWidth/>
                    </TranslatableInputs>*/
                )
            case 'TranslatableInputsRich':
                return (
                    <MyTranslatableInputsRich
                        source={'description'}
                        label={lab}
                        fullWidth
                    />
                    /*<TranslatableInputs
                        locales={localesConfig.locales}
                        defaultLocale={locale}
                    >
                        <RichTextInput
                             label={lab}
                            //configureQuill={configureQuill}
                            toolbar={toolbarRichTextInput}
                            source={source}
                            //validate={required()}
                            fullWidth
                        />
                    </TranslatableInputs>*/
                )
            case 'SelectChoices':
                return (
                    <SelectInput source={source} choices={choices} label={lab} variant={"outlined"} fullWidth/>
                )

            case 'ReferenceInput':
                if (arr_source[0] === source && arr_source[1] > 0) {
                    return (
                        <Typography variant="subtitle2" gutterBottom>
                            {`${arr_source[0]}: `}
                            <GetFieldById id={arr_source[1]} resource={reference}
                                          field={loc ? `${optionText}[${locale}]` : `${optionText}`}/>
                            <Box hidden><NumberInput source={arr_source[0]} defaultValue={arr_source[1]}/></Box>
                        </Typography>
                    )
                } else
                    return (
                        <ReferenceInput
                            label={lab}
                            source={source}
                            reference={reference}
                            //filter={{question$id: id}}
                        >
                            <AutocompleteInput optionText={loc ? `${optionText}[${locale}]` : `${optionText}`} resettable
                                              variant={"outlined"} fullWidth/>
                        </ReferenceInput>
                    )

            case 'ImageInput':
                return (
                    <Box display="flex" alignItems={"top"} p={'1em 0 1em 0'}>
                        <Box flex={1} p={'1em'}>
                            <ImageInput
                                maxSize={500000}
                                source={source}
                                label={`${'New'} ${source}`}
                                accept="image/*"
                                //validate={required()}
                                defaultValue={''}
                                placeholder={<p>Drop your {source} here (<b>500kb max</b>)</p>}>
                                {/*<ImageFieldComp source={source} height={240} width={'auto'} itsOriSrc={true}/>*/}
                                <ImageField source={'src'} title="title"/>
                            </ImageInput>
                        </Box>
                        <Box flex={1} p={'1em'} display="grid" align={'right'}>
                            <Box mb={'0.5em'}>{`${'Current'} ${source}`}</Box>
                            <Box><ImageFieldComp source={source} height={240} width={'auto'}/></Box>
                        </Box>
                    </Box>
                )

            default:
                return (
                    <TextInput source={source} label={source} multiline={multiline} fullWidth/>
                );
        }
    }
};
