import * as React from 'react';
import PropTypes from 'prop-types';
//import Tabs from '@mui/material/Tabs';
//import Tab from '@mui/material/Tab';
import {Tabs, Tab, Box, Typography} from "@material-ui/core";
/*import {
    FormTab,
    TextInput,
} from 'react-admin';*/
//import {validateReq} from "../utils/validateTable";
// import {localesConfig} from "../utils/config";
import ProductsByRule from "./ProductsByRule";
import BrandsByRule from "./BrandsByRule";
import {useEffect} from "react";
import {useTranslate} from "react-admin";

// import {useTranslate} from "react-admin";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export const PriceOfWeek = (props) => {

    const translate = useTranslate();
    const {discount_rule_id, tableForRule, formData, rule_type, slug} = props;

    const discount_rule_tab = window.localStorage.getItem('discount_rule_tab');
    const [value, setValue] = React.useState(discount_rule_tab ? parseInt(discount_rule_tab) : 0);

    useEffect(() => {
        // let ppp = JSON.parse(window.localStorage.getItem('history_flight_dynamic_seach'));
        // window.localStorage.setItem('history_flight_dynamic_seach', JSON.stringify(ppp));
        // window.localStorage.removeItem('history_flight_dynamic_seach');
        window.localStorage.setItem('discount_rule_tab', value);
    }, [value]);// eslint-disable-line

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}} style={{border: '1px solid #ccc', borderRadius: 8, zIndex: 2}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}
                 style={{backgroundColor: 'aliceblue', borderRadius: '8px 8px 0 0 '}}>
                {/*<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">*/}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // indicatorColor="primary"
                    // textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label={translate('Products By Rule')} {...a11yProps(0)} />
                    <Tab label={translate('Brands By Rule')} {...a11yProps(1)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{width: "fit-content"}}>
                <ProductsByRule
                    {...props}
                    slug={slug}
                    discount_rule_id={discount_rule_id}
                    rule_type={rule_type}
                    tableForRule={tableForRule}
                    formData={formData}
                />
            </TabPanel>
            <TabPanel value={value} index={1} style={{width: "fit-content"}}>
                <BrandsByRule
                    {...props}
                    //customerFormId={customerFormId}
                    slug={slug}
                    discount_rule_id={discount_rule_id}
                    tableForRule={tableForRule}
                    formData={formData}
                />
            </TabPanel>
        </Box>

    );
}
