import * as React from 'react';

import {
    BulkDeleteButton,
    BulkExportButton,
} from 'react-admin';
import {Fragment} from "react";

// React-admin предоставляет три компонента, которые вы можете использовать в bulkActionButtons:
// <BulkDeleteButton>, <BulkUpdateButton>и <BulkExportButton>
const PostBulkActionButtons = (
    {
        resource,
        canDelete,
        handlerForCheckBox,
        handlerForCheckBox2,
        handlerForCheckBox3,
        exporterlabel,
        exporterFile,
        ...props
    }
) => {
    //console.log('PostBulkActionButtons===========>', props.selectedIds)
    return (
        <Fragment style={{marginLeft: 24}}>
            {/*{labelHandlerForCheckBox && handlerForCheckBox &&
            <BulkExportButton
            //onSubmit={()=> {console.log('PostBulkActionButtons===========>', props.selectedIds) }}
                onClick={()=> {console.log('PostBulkActionButtons===1========>', props.selectedIds) }}
                exporter={handlerForCheckBox}
                label={labelHandlerForCheckBox}
                style={{marginLeft: 24}}
                icon={false}
            />
        }*/}
            {exporterlabel && exporterFile &&
                <BulkExportButton exporter={exporterFile} label={exporterlabel} style={{marginLeft: 24}}/>
            }

            {handlerForCheckBox && handlerForCheckBox}
            {handlerForCheckBox2 && handlerForCheckBox2}
            {handlerForCheckBox3 && handlerForCheckBox3}
            {canDelete &&
                <BulkDeleteButton
                    {...props}
                    style={{marginLeft: 24}}
                    undoable={false} //Подтверждение удаления
                />
            }
        </Fragment>
    )
};
export default PostBulkActionButtons;
