import * as React from 'react';
import {
    ReferenceManyField,
    useTranslate, useLocale,
    BooleanInput,
    TextField,
    TextInput,
} from 'react-admin';

import {
    RowForm,
} from "@react-admin/ra-editable-datagrid";
import useMyStyles from "../../utils/useMyStyles";
import {validateReq} from "../../_constants/validateTable";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {BooleanFieldComp, DateFieldComp} from "../../_common/CompReact";
import CroppedLongField from "../../_common/CroppedLongField";

const transform = (data) => {
    data.source = data.source2;
    delete data.source2;
    return data;
}
const ListEditForm = (props) => {
    //const classes = useMyStyles();
    const {config_id} = props;
    const translate = useTranslate();

    return (
        <>
            <RowForm
                {...props}
                initialValues={{
                    config: config_id,
                    source2: localStorage.getItem('username'),
                }}
                transform={transform}
                //mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
            >
                {/*<NumberInputFloatComp source="number" label={translate('Number')}
                                      validate={validateReq}
                                      fullWidth
                />*/}
                <TextInput source="number" label={translate('Number')}
                                      validate={validateReq}
                                      fullWidth
                />

                <BooleanInput source="is_visible"
                              label={translate('Is visible')} defaultValue={false}/>
            </RowForm>
        </>
    )
};

const CompanyPhone = props => {
    // const {customer_id, record, isSave = true} = props;
    const locale = useLocale();
    const translate = useTranslate();
    const {config_id} = props;
    const classes = useMyStyles();
    // const redirect = useRedirect()
    //const refresh = useRefresh();

    // let invoiceBooking = useSelector((state) => state.admin.resources.invoice_booking)

    return (
        <div style={{width: "100%", /*minWidth: 500,*/ marginBottom: 16}}>
            <ReferenceManyField
                //pagination={<PostPagination/>}
                perPage={12}
                fullWidth
                label="Company Phone"
                reference="dCompanyPhone"
                target="config"
            >
                <MyEditableDatagridForReference
                    hasBulkActions={true}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    //noDelete={customer_id !== customerFormId}
                    //mutationMode="pessimistic" //Подтвердить удаление
                    //undoable
                    createForm={<ListEditForm submitOnEnter={false} locale={locale} {...props}
                                              config_id={config_id}/>}
                    editForm={<ListEditForm submitOnEnter={false} locale={locale}  {...props}
                                            config_id={config_id}/>}
                    //rowStyle={postRowStyle}
                >
                    {/*<NumberField source='number' label={translate('Number')}/>*/}
                    <TextField source='number' label={translate('Number')} textAlign={'right'}/>
                    <BooleanFieldComp source="is_visible" label={translate("Is visible")}
                                      textAlign={"center"}/>,

                    <CroppedLongField source="source" long={10} label={translate('Source')}/>
                    <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'} showTime={true}
                                   locale={'us'} cellClassName={classes.date_st}/>
                </MyEditableDatagridForReference>
            </ReferenceManyField>

        </div>
    )
}
export default CompanyPhone;