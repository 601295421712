import * as React from 'react';
/*import {
    useRedirect,
} from 'react-admin';*/

import EditComponentAdmin from "../../_common/EditComponentAdmin";
import PromotionBadgeEditCreate from "./PromotionBadgeEditCreate";
import {useRedirect} from "react-admin";
import {copyToS3bucket} from "../../_common/Transform";

export const PromotionBadgeEdit = props => {
    const {location, resource} = props;
    const {search} = location;
    let idSource = '';
    if(search){
        let url = new URL('https://test/' + search);
        idSource = url.searchParams.get('customerId')
    }
    const redirect = useRedirect()

    return (
        <EditComponentAdmin
            {...props}
            transform={(event) => copyToS3bucket(resource, event)}
            title={''}
            //editDialog={idSource}
            onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            /*onSuccess={() => idSource
                ? redirect(`/locating/${idSource}/2`)
                : redirect('list', props.basePath)
            }*/
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <PromotionBadgeEditCreate idSource={idSource}/>
        </EditComponentAdmin>
    )
};