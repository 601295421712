import * as React from 'react';
import {
    Create,
} from 'react-admin';

import CustomersEditCreate from "./CustomersEditCreate";
import PostEditActions from "../../_common/PostEditActions";
import {transformCustomers} from "./CustomersEdit";

export const CustomersCreate = props => {

    const {location} = props;
    const {search} = location;

    //const redirect = useRedirect()

    let idSource = '';
    //let bookingId = '';
    if(search){
        let url = new URL('https://test/' + search);
        idSource = url.searchParams.get('idSource')
        //bookingId = url.searchParams.get('bookingId')
    }


    return (
        <Create
            {...props}
            transform={(event) => transformCustomers(event)}
            title={''}
            // onSuccess={() => idSource ? props.history.goBack() : redirect('list', props.basePath)}
            //onSuccess={() => props.history.goBack()}
            //onSuccess={onSuccessData}
            actions={<PostEditActions/>}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <CustomersEditCreate idSource={idSource}/>
        </Create>
    )
};