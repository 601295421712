import {makeStyles} from "@material-ui/core";
//https://stackoverflow.com/questions/56720432/how-to-style-the-header-of-react-admin-datagrid
const useMyStyles = makeStyles({
    overflowWrap: {overflowWrap: "anywhere", minWidth: "88px"},
    minWidthDate: {minWidth: "200px"},
    truncateText: {
        overflow: 'hidden',
        display: "-webkit-box",
        "-webkit-line-clamp": "3",
        "-webkit-box-orient": "vertical",
        lineHeight: "1.3em",
        height: "3.9em"
    },
    headerButtonEdiWidth: {
        maxWidth: '48px',
        padding: "6px 12px",
    },
    headerShortWidthBooking: {
        minWidth: 50,
        maxWidth: 50,
        padding: "6px 12px",
    },
    headerShortWidth: {
        //maxWidth: '28px',
        padding: "6px 12px",
    },
    headerImageWidth: {
        width: '64px',
        padding: "6px 12px",
        textAlign: "center"
    },

    headerCell: {fontWeight: 'bold', verticalAlign: "bottom"},
    /*headerCell: {
        // backgroundColor: '#def2ff',
        // color: 'white',
        fontWeight: 'bold',
        //textAlign: "center",
        lineHeight: "normal",
        // padding: '6px 16px 6px 16px',
        padding: '6px 8px 6px 8px',
        verticalAlign: "bottom",
        border: '1px solid #eee',
    },
    row: {
        '&:nth-of-type(odd)': {
            backgroundColor: '#fefefe',
        },
        '&: td': {textAlign: "center"},
        /!*'&:last-child td, &:last-child th': {
          border: 0,
        },*!/
    },
    rowCell: {padding: '6px 8px 6px 8px', border: '1px solid #eee'},*/



    headerCellPostShow: {
        // backgroundColor: '#def2ff',
        // color: 'white',
        //fontWeight: 'bold',
        //textAlign: "center",
        lineHeight: "normal",
        // padding: '6px 16px 6px 16px',
        padding: '6px 8px 6px 8px',
        verticalAlign: "bottom",
        border: '1px solid #eee',
    },
    headerCellBorder: {
        // backgroundColor: '#def2ff',
        // color: 'white',
        fontWeight: 'bold',
        //textAlign: "center",
        lineHeight: "normal",
        // padding: '6px 16px 6px 16px',
        padding: '6px 8px 6px 8px',
        verticalAlign: "bottom",
        border: '1px solid #eee',
    },
    // rowCell: {padding: '6px 16px 6px 16px'},
    rowCellBorder: {padding: '6px 8px 6px 8px', border: '1px solid #eee'},

    rowCellCenter: {textAlign: "center"},
    rowCellLeft: {textAlign: "left"},
    rowCellRight: {textAlign: "right"},

    /*=======BOOKING=====================*/
    /*SimpleFormIterator: {???
        display: "inline-flex",
        '&: li': {
            color: "red",
            '&: section': {display: "flex"},
        },

    },*/

    prompt_name: {maxWidth: 500},
    alarmField: {color: "red"},
    booking_title: {
        borderTop: '1px solid darkgrey',
        width: '100%',
        paddingTop: '8px'
    },
    cost_show_field: {
        backgroundColor: 'aliceblue',
        padding: '4px 16px',
        borderRadius: 4,
    },
    cost_show_field_calc: {
        marginTop: -22,
        backgroundColor: 'aliceblue',
        padding: '4px 16px',
        borderRadius: 4,
    },
    /*============================*/
    colorCost: {backgroundColor: "antiquewhite", textAlign: "right"},
    borderLeftRed: {borderLeft: "4px solid red"},
    razd: {backgroundColor: "red", width: '4px', minWidth: '4px', padding: 0},

    textRight: {textAlign: "right"},
    textCenter: {textAlign: "center"},
    long_id_uuid: {
        maxWidth: '140px',
        textAlign: "left",
        overflowWrap: "break-word", /*перенос по буквам*/
        //overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        //textOverflow: 'ellipsis',
    },
    clOverflowWrap: {
        maxWidth: '70px',
        overflowWrap: "break-word", /*перенос по буквам*/
        //overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        //textOverflow: 'ellipsis',
    },
    long_name: {
        maxWidth: '350px',
        textAlign: "left",
        //overflowWrap: "break-word", /*перенос по буквам*/
        //overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        //textOverflow: 'ellipsis',
    },
    avatar_name_phone_st: {
        minWidth: '184px',
    },
    customer_st: {
        // width: '100px',
        maxWidth: '180px',
        overflowWrap: "break-word",
        /*maxWidth: '140px',
        textAlign: "left",
        whiteSpace: 'nowrap', /!* Запрещаем перенос строк *!/
        overflow: 'hidden', /!* Обрезаем все, что не помещается в область *!/
        textOverflow: 'ellipsis',
        fontSize: '12px',*/
    },
    remark_st: {
        maxWidth: '300px',
    },
    project_st: {
        // width: '100px',
        minWidth: '120px',
        maxWidth: '120px',
        textAlign: "left",
        whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        textOverflow: 'ellipsis',
        fontSize: '12px',
    },
    invoice: {
        minWidth: 160,
        maxWidth: 160,
        textAlign: "center",
    },
    load_paid_st: {
        minWidth: 80,
        maxWidth: 80,
        textAlign: "center",
    },
    date_st: {
        minWidth: 90,
        maxWidth: 90,
        textAlign: "center",
    },
    date_route_st: {
        minWidth: 90,
        textAlign: "center",
    },
    airline_st: {
        minWidth: 90,
        maxWidth: 90,
        fontSize: '11px',
    },
    slug_st: {
        minWidth: 150,
        maxWidth: 150,
        fontSize: '10px',
    },
    costHead_st: {
        // width: 120,
        minWidth: 100,
        maxWidth: 100,
        backgroundColor: "antiquewhite",
        textAlign: "right",
    },
    priceHead_st: {
        // width: 120,
        minWidth: 100,
        maxWidth: 100,
        //backgroundColor: "antiquewhite",
        textAlign: "right",
    },
    cost_st: {
        // width: 120,
        minWidth: 80,
        maxWidth: 80,
        backgroundColor: "antiquewhite",
        textAlign: "right",
    },
    price_st: {
        // width: 120,
        minWidth: 80,
        maxWidth: 80,
        textAlign: "right",
    },
    sales_price_st: {
        // minWidth: '86px'
        textAlign: "right",
    },
    quantity_st: {
        minWidth: 50,
        maxWidth: 50,
        // minWidth: '64px'
        textAlign: "right",
    },
    taxes_st: {
        // minWidth: '64px'
        textAlign: "right",
    },
    other_fee_st: {
        // minWidth: '64px'
        textAlign: "right",
    },
    total_st: {
        // minWidth: '74px'
        //backgroundColor: "antiquewhite",
        textAlign: "right",
    },

    currency_st: {
        // minWidth: '64px'
        textAlign: "center",
    },
    passenger_title_st: {
        textAlign: "left",
    },
    passenger_st: {
        minWidth: 100,
        maxWidth: 100,
        textAlign: "left",
    },
    pnr_st: {
        minWidth: '148px', /*'259px',*/
        maxWidth: '148px',
        // maxWidth: '259px',
        textAlign: "left",
        overflowWrap: "break-word",
        /*whiteSpace: 'nowrap',*/ /* Запрещаем перенос строк */
        /*overflow: 'hidden',*/ /* Обрезаем все, что не помещается в область */
        /*textOverflow: 'ellipsis',*/
    },
    ticket_number_st: {
        minWidth: '142px',
        maxWidth: '142px',
        // maxWidth: '142px',
        textAlign: "left",
    },
    route_st: {
        minWidth: '123px',
        maxWidth: '123px',
        // maxWidth: '123px',
        textAlign: "left",
        overflowWrap: "break-word",
    },
    ticket_type_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "center",
    },
    flight_number_st: {
        minWidth: '121px',
        maxWidth: '121px',
        textAlign: "left",
    },
    ticket_class_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    miles_only_st: {
        // minWidth: 'px',
        // maxWidth: 'px',
        textAlign: "center",
    },
    notes_st: {
        minWidth: '64px',
        maxWidth: '64px',
        whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        textOverflow: 'ellipsis',
        textAlign: "left",
    },
    supplier: {
        minWidth: '90px',
        maxWidth: '90px',
        // whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        // overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        // textOverflow: 'ellipsis',
        textAlign: "left",
    },
    booking_ref: {
        minWidth: '140px',
        maxWidth: '140px',
        // whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        // overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        // textOverflow: 'ellipsis',
        textAlign: "left",
    },
    program_title_st: {
        minWidth: '97px',
        maxWidth: '97px',
        textAlign: "left",
    },
    account_name_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    account_number_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    credit_card: {
        minWidth: '50px',
        maxWidth: '50px',
        textAlign: "left",
        overflowWrap: "anywhere",
    },
    username_st: {
        minWidth: '90px',
        maxWidth: '90px',
        textAlign: "left",
        overflowWrap: "anywhere",
    },
    password_st: {
        minWidth: '80px',
        maxWidth: '80px',
        textAlign: "left",
    },
    dob_st: {
        minWidth: '64px',
        maxWidth: '64px',
        whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        textOverflow: 'ellipsis',
        textAlign: "left",
    },
    phone_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    cc_for_booking_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    cvv_cvc_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    exp_st: {
        minWidth: '69px',
        // maxWidth: '69px',
        textAlign: "left",
    },
    email_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    account_email_password_st: {
        minWidth: '64px',
        // maxWidth: '64px',
        textAlign: "left",
    },
    supplier_notes_st: {
        minWidth: '64px',
        maxWidth: '64px',
        whiteSpace: 'nowrap', /* Запрещаем перенос строк */
        overflow: 'hidden', /* Обрезаем все, что не помещается в область */
        textOverflow: 'ellipsis',
        textAlign: "left",
    },
    paid_st: {
        textAlign: "center",
    },


    smallListBlock: {
        border: '1px solid #ccc',
        borderRadius: '4px',
        padding: '16px',
        display: 'grid',
    },
    smallListName: {
        marginRight: 16,
        fontSize: 14,
        fontWeight: "bold",
    },
    smallListBox: {
        display: "flex"
    },

    featureTypeColumn: {
        backgroundColor: 'aliceblue'
    },

    productName: {
        minWidth: 200
    },

    colorPriceAndStock: {
        backgroundColor: "#f2f2f2"
    },
    colorFeatureProduct: {
        backgroundColor: "#dae9f8"
    },
    colorFeatureCategory: {
        backgroundColor: "#daf2d0"
    },
});

export default useMyStyles;