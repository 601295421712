import * as React from 'react';

import {
    SimpleList, TextField, useTranslate,
} from 'react-admin';
import {Box} from "@mui/material";
import CroppedLongField from "./CroppedLongField";
import {DateFieldComp} from "./CompReact";
import useMyStyles from "../utils/useMyStyles";

// const PostEditActions = ({ basePath, data, resource }) => (
const MySimpleList = (props) => {
    const {columns, postRowStyle} = props;
    const translate = useTranslate();
    const classes = useMyStyles();
    return (
    <SimpleList
        rowClick={false}
        primaryText={<># <TextField source={`id`}/></>}
        secondaryText={
            <Box>
                <CroppedLongField source="source" long={10} label={translate('Source')}/>
                {', '}
                <DateFieldComp source="updated" label={translate('Update')} textAlign={'center'}
                               showTime={true}
                               locale={'us'} cellClassName={classes.date_st}/>
            </Box>
        }
        tertiaryText={
            <Box className={classes.smallListBlock}>
                {columns}
            </Box>
        }
        // linkType={false}//{record => record.canEdit ? "edit" : "show"}
        linkType={'edit'}
        rowStyle={postRowStyle}
    />
        )
};
export default MySimpleList;
