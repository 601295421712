import DefaultTable from '../DefaultTable/DefaultClass'
import {JustBool, JustRef, JustText} from "../tablePattern";

class PriceTypes extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                name: 'Currencies',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Currencies', optionText: 'name', locale: true},
            },
        ]


        this.filters_aside = []

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]


        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            JustText('name'),
                            JustText('item_code_in_accounting_system'),
                        ]
                    },
                    {
                        box: [
                            JustRef('currency', 'Currencies'),
                            JustBool('is_default'),
                            JustBool('disable_conditional_discounts'),
                        ]
                    },
                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]
    }
}

export default PriceTypes