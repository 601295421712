import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, ReferenceField, TextField,
    useLocale,
    useTranslate,
} from 'react-admin';

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import useMyStyles from "../../utils/useMyStyles";
// import {universalTopFilters} from "../universalView/universalTopFilters";
// import {CardContent, useMediaQuery} from "@material-ui/core";
import {CardContent} from "@material-ui/core";
import CardComp from "../../_common/Asside/CardComp";
import {
    DateFieldComp,
    FilterDataAside,
    //NumberInputFloatComp,
    TextFieldBold
} from "../../_common/CompReact";
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import {Box} from "@mui/material";

const universalTopFilters = (resource, locale) => {
    let dt = [];
    // dt.push(<TextInput label="Search" source="q" alwaysOn/>)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!"/>);
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    const translate = useTranslate();
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch/>*/}
                <FilterLiveSearch source={'product_search'} label={translate('Search')}/>
                <FilterDataAside fields={'created_at'} name={'create'}/>
                <FilterDataAside fields={'updated_at'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};

const ColumnsForList = (resource, locale, classes) => {
    /*
    base_price: "547.08"
    created_at: "2023-12-18T09:54:27"
    currency_id: 980
    ext_uuid: null
    id: "fff2531e4f9f1f081fd75e3f56232dde"
    product:
    {id: "49445b380aae11eeae5590b11c3574fe",
    name: "Амортизатор 2101 зад (масло) TANGUN",…
    }
    article: "S82001"
    article_clean: ""
    assortment_group_id: null
    brand_id: "e6e55876622d11e980da8416f902c0f5"
    created_at: "2023-12-15T13:19:57"
    ext_id: 475713084
    id: "49445b380aae11eeae5590b11c3574fe"
    name: "Амортизатор 2101 зад (масло) TANGUN"
    sku: ""
    updated_at: "2023-12-22T15:12:05"
    product_id: "49445b380aae11eeae5590b11c3574fe"
    updated_at: "2023-12-22T15:12:20"
     */
    const translate = useTranslate();
    let columns = {
        id: <NumberField
            source="id"
            //headerClassName={classes.headerShortWidth}
            cellClassName={classes.long_id_uuid}
        />,
        //id: <CroppedLongField  source="id" long={20}/>,

        product:
            <Box label={translate('Product')} sortBy={'product.name'} display={"grid"}>
                <TextFieldBold source={'product.article'} label={translate('article')}/>
                <TextField source={'product.name'} label={translate('Name')}/>
                <TextField source={'product.sku'} label={translate('SKU')}/>
            </Box>,
        base_price: <TextField source={'base_price'} label={translate('Base Price')} textAlign={'right'}/>,
        currency_id:
            <ReferenceField
                source="currency_id"
                reference="currency_z"
                label={translate('Currency')}
                link={false}
                sortBy={'currency:name'}
            >
                <TextField source="name"/>
            </ReferenceField>,
        created_at: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
        updated_at: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                                   cellClassName={classes.date_st}/>,
    }
    return columns;
};

/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/

export const PriceRow_z = ({permissions, ...props}) => {
    const classes = useMyStyles();
    const {resource} = props;
    const locale = useLocale();
    // const translate = useTranslate();

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes),
        omit: ["nb_views"],
    });


    return (
        <ListComponentAdmin
            {...props}
            hasCreate={false}
            title={''}
            actions={<TableActions
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes)}// preference={`${resource}${".list.columns"}`}
                permissions={permissions}
                select={true}
                create={false}
                filter={true}
            />}
            // is_bulkActionButtons={false}
            filters={universalTopFilters(resource, locale)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
            is_bulkActionButtons={true}
        >
            <DatagridComponentAdmin {...props}
                                    classes={{
                                        headerCell: classes.headerCell,
                                        row: classes.row,
                                        rowCell: classes.rowCell
                                    }}
                //noDelete={true}
                // initialRowMode="edit"
            >
                {columns}
            </DatagridComponentAdmin>
        </ListComponentAdmin>
    )
};