import * as React from 'react';
import PropTypes from 'prop-types';
//import Tabs from '@mui/material/Tabs';
//import Tab from '@mui/material/Tab';
import {Tabs, Tab, Box, Typography} from "@material-ui/core";
//import {validateReq} from "../../utils/validateTable";
import {useEffect} from "react";

import {useTranslate} from "react-admin";
import {useNotify} from "ra-core";
import {getGoogleMerchant, getGoogleMerchantInfo} from "../../_common/CompForApi/utilsAPI";
import {LinearProgress, Switch} from "@mui/material";
import Button from "@material-ui/core/Button";
import LoopIcon from "@material-ui/icons/Loop";
import get from "lodash/get";
import {ImageFieldComp} from "../../_common/CompReact";
import {styled} from "@mui/material/styles";


const BlockGoogle = props => {
    const translate = useTranslate();
    // const locale = useLocale();
    // const {record, isLoading} = useEditContext();
    // if (isLoading)  return null;
    const notify = useNotify();
    const {record, id} = props
    const [progress, setProgress] = React.useState(false);
    const [data, setData] = React.useState([]);
    const [reload, setReload] = React.useState(0);
    const [more, setMore] = React.useState({});

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        if (record && id) {
            if (reload > 0) setProgress(true)
            let res = await getGoogleMerchantInfo(id, reload > 0 ? notify : false)
            setData(res ? res : [])
            console.log('BlockEditMarket===========>', res)
            setProgress(false)
        }
    }, [record, reload]);// eslint-disable-line

    const handleClickGoogleMerchantCreate = async (id) => {
        setProgress(true)
        let res = await getGoogleMerchant('create', id, notify)
        console.log('====================>', res)
        setProgress(false)
    }
    const handleClickGoogleMerchantDel = async (id) => {
        setProgress(true)
        let res = await getGoogleMerchant('delete', id, notify)
        console.log('====================>', res)
        setProgress(false)
    }

    const handleMore = (e,idGoogle) => {
        e.preventDefault();
        let ttt = get(more, idGoogle, null)
        setMore({...more, [idGoogle]: ttt ? null : true})
    }
    //if (!data) return null

    return (
        <Box display="grid" alignItems={"center"} p="1em">
            <div style={{height: 16, marginTop: -16}}>
                {progress && <LinearProgress color="secondary"/>}
            </div>
            <Box display="flex" flex={1} mr="2em" alignItems={"center"}>
                <Button
                    variant="contained"
                    // color={resGoogleMerchant === 'Error Request' ? "error" : "primary"}
                    color={"default"}
                    onClick={(event) => {
                        handleClickGoogleMerchantCreate(id)
                    }}
                >
                    {translate('Create Google merchant')}
                </Button>
                <Button
                    style={{marginLeft: 8}}
                    variant="contained"
                    // color={resGoogleMerchant === 'Error Request' ? "error" : "primary"}
                    color={"default"}
                    onClick={(event) => {
                        setReload(reload + 1)
                    }}
                >
                    <LoopIcon style={{color: "green"}}/>
                </Button>
            </Box>

            <Box
                key={id}
                display="flex"
                p="1em"
                mt="1em"
                //mb={'-0.5em'}
                fullWidth
                style={{border: '1px solid #ddd', borderRadius: 8}}
                //justifyContent={en}
            >
                <Box flex={1} mr="2em" alignItems={"bottom"}>
                    <b>Visible</b>
                </Box>
                <Box flex={1} mr="2em" alignItems={"bottom"}>
                    <b>Status</b>
                </Box>
                <Box flex={2} mr="2em" alignItems={"bottom"} textAlign={'center'}>
                    <b>Image</b> {/*style={{textAlign: "center"}}*/}
                </Box>
                <Box flex={5} mr="2em" alignItems={"bottom"}>
                    <b>Title</b>
                </Box>
                <Box flex={2} mr="2em" alignItems={"bottom"}>
                    <b>Data Source</b>
                </Box>
                <Box flex={1} mr="2em" alignItems={"bottom"}>
                    <b>Click</b>
                </Box>
                <Box flex={1} alignItems={"bottom"}>

                </Box>
            </Box>
            {data && data.map((it, index) => {
                //https://merchants.google.com/mc/items/details?a=5298584529
                // &offerId=6ab3dd2f7645410ea84ab5b78865ce00
                // &language=uk
                // &channel=0
                // &feedLabel=AG
                let err = get(it, 'errors.message', null)
                const {product} = it
                const {
                    availability,  //: "in stock"
                    //channel,  //: "online"
                    condition,  //: "new"
                    contentLanguage,  //: "uk"
                    description,  //: "Lorem ipsum"
                    feedLabel,  //: "AG"
                    googleProductCategory,  //: "Smartphones>Consumer Electronics"
                    id: idGoogle,  //: "online:uk:AG:6ab3dd2f7645410ea84ab5b78865ce00-1"
                    imageLink,  //: "https://support.apple.com/library/APPLE/APPLECARE_ALLGEOS/SP875/sp875-sp876-iphone14-pro-promax.png"
                    // kind,  //: "content#product"
                    offerId,  //: "6ab3dd2f7645410ea84ab5b78865ce00-1"
                    // source,  //: "api"
                    // targetCountry,  //: "AG"
                    title,  //: "iPhone 14 Pro Max"
                } = product || {}
                // let toGoogle = `https://merchants.google.com/mc/items/details?a=5298584529&offerId=${offerId}&language=${contentLanguage}&channel=${channel}&feedLabel=${feedLabel}`
                let toGoogle = `https://merchants.google.com/mc/items/details?a=5298584529&offerId=${offerId}&language=${contentLanguage}&channel=0&feedLabel=${feedLabel}`
                if (err || idGoogle === undefined) return (
                    <Box
                        key={id}
                        display="flex"
                        p="1em"
                        mt="0.3em"
                        fullWidth
                        style={{border: '1px solid #ddd', borderRadius: 8}}
                        //justifyContent={en}
                    ><h4>
                        {err ? err : 'Error result'}
                    </h4></Box>
                )

                return (
                    <Box
                        key={id}
                        display="flex"
                        p="1em"
                        mt="0.3em"
                        fullWidth
                        style={{border: '1px solid #ddd', borderRadius: 8}}
                        //justifyContent={en}
                    >
                        <Box display="flex" flex={1} mr="2em" alignItems={"top"}>
                            <Switch
                                checked={true}
                                // onChange={handleChange}
                                color="primary"
                                name="checkedB"
                                inputProps={{'aria-label': 'primary checkbox'}}
                            />
                        </Box>
                        <Box display="flex" flex={1} mr="2em" alignItems={"top"}>
                            <span>Under review</span>
                        </Box>
                        <Box flex={2} mr="2em" alignItems={"top"} textAlign={'center'}>
                            <ImageFieldComp source={imageLink} itsOriSrc={true} height={'auto'} width={'96'}/>
                        </Box>
                        <Box display="grid" flex={5} mr="2em" alignItems={"top"}>
                            <span style={{fontWeight: "bold", color: '#2196f3'}}>{`${condition}, ${availability}`}</span>
                            <span style={{fontWeight: "bold"}}>{`${title}`}</span>
                            <span
                                style={{
                                    maxHeight: !more[idGoogle] && 72,
                                    overflowY: "hidden"
                                }}
                            >
                                {description}
                            </span>
                            <span style={{color: "blue", cursor: "pointer"}}
                                  onClick={(event) => {handleMore(event, idGoogle)}}
                            >
                                {more[idGoogle] ? '<< hide' : 'more >>'}
                            </span>
                        </Box>
                        <Box display="flex" flex={2} mr="2em" alignItems={"top"}>
                            <a href={toGoogle} target={"_blank"} rel="noreferrer">
                                GOOGLE: {googleProductCategory}
                            </a>
                        </Box>
                        <Box display="flex" flex={1} mr="2em" alignItems={"top"}>
                            <span>0</span>
                        </Box>
                        <Box flex={1} alignItems={"top"} textAlign={'right'}>
                            <Box display={'grid'}>
                            <Button
                                style={{marginBottom: '1em'}}
                                variant="contained"
                                // color={"primary"}
                                color={"default"}
                                /*onClick={(event) => {
                                    handleClickGoogleMerchantDel(idGoogle)
                                }}*/
                            >
                                {translate('EDIT')}
                            </Button>
                            <Button
                                style={{color: "red"}}
                                variant="contained"
                                // color={"primary"}
                                //color={"default"}
                                onClick={(event) => {
                                    handleClickGoogleMerchantDel(idGoogle)
                                }}
                            >
                                {translate('Del')}
                            </Button>
                                </Box>
                        </Box>
                    </Box>
                )
            })}

        </Box>
    )
}

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 3}}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Img = styled('img')({
    // height: 32,
    // maxHeight: 32,
    // minHeight: 32,
    verticalAlign: 'middle',
    height: '100%',//"-webkit-fill-available",
    width: "auto",
});
const ImgTab = props => {
    const {src} = props
    return (
        <div style={{
            height: 32,
            maxHeight: 32,
            minHeight: 32
        }}
        >
            <Img
                src={require(`./market/${src}`).default}
                alt={'no image'}
            />
        </div>

    )
}
export const Market = (props) => {

    // const translate = useTranslate();
    //const {record, id} = props

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{width: '100%'}} style={{border: '1px solid #ccc', borderRadius: 8, zIndex: 2}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}
                 style={{backgroundColor: 'aliceblue', borderRadius: '8px 8px 0 0 '}}>
                {/*<Tabs value={value} onChange={handleChange} aria-label="basic tabs example">*/}
                <Tabs
                    value={value}
                    onChange={handleChange}
                    // indicatorColor="primary"
                    // textColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                >
                    <Tab label={<ImgTab src={'google-merchant-logo.png'}/>} {...a11yProps(0)} />
                    <Tab label={<ImgTab src={'amazon-logo.png'}/>} {...a11yProps(1)} />
                    <Tab label={<ImgTab src={'asos-logo.png'}/>} {...a11yProps(2)} />
                    <Tab label={<ImgTab src={'cdiscount-logo.png'}/>} {...a11yProps(3)} />
                    <Tab label={<ImgTab src={'ebay-logo.png'}/>} {...a11yProps(4)} />
                    <Tab label={<ImgTab src={'facebook-marketplace-logo.png'}/>} {...a11yProps(5)} />
                    <Tab label={<ImgTab src={'zalando-logo.png'}/>} {...a11yProps(6)} />
                </Tabs>
            </Box>
            <TabPanel value={value} index={0} style={{width: "100%"}}>
                <BlockGoogle
                    {...props}
                />
            </TabPanel>
            <TabPanel value={value} index={1} style={{width: "fit-content"}}>
            </TabPanel>
            <TabPanel value={value} index={2} style={{width: "fit-content"}}>
            </TabPanel>
            <TabPanel value={value} index={3} style={{width: "fit-content"}}>
            </TabPanel>
            <TabPanel value={value} index={4} style={{width: "fit-content"}}>
            </TabPanel>
            <TabPanel value={value} index={5} style={{width: "fit-content"}}>
            </TabPanel>
            <TabPanel value={value} index={6} style={{width: "fit-content"}}>
            </TabPanel>
        </Box>

    );
}
