import * as React from 'react';
import {AutocompleteInput, ReferenceInput, TextInput} from 'react-admin';
import {tableConfig} from "../../tablesConfig/tableConfig";

const buildTopFiltersComponent = (data, locale) => {
    const {name, type, alwaysOn, parameters} = data;
    const {locale: loc} = parameters || {};
    console.log('type=====>', type)
    if (type) {
        switch (type) {
            case 'TextInput':
                return (
                    <TextInput label={name} source={name} alwaysOn={alwaysOn}/>
                )
            case 'ReferenceInput':
                const {reference, optionText} = parameters || {};
                return (
                    <ReferenceInput source={name} reference={reference} alwaysOn={alwaysOn} label={name}>
                        <AutocompleteInput optionText={loc ? `${optionText}[${locale}]`: optionText} resettable emptyText={'---'}/>
                    </ReferenceInput>
                )
            default:
                return null;
        }
    }
}
export const universalTopFilters = (resource, locale) => {
    let dt = [];

    const params = tableConfig.tablesList.find(x => x.table === resource)
    if (!params) return dt;
    const {data} = params;
    const {filters_top} = data;
    if (filters_top) {
        filters_top.forEach((data, index) => {
            let res = buildTopFiltersComponent(data, locale);
            if (res) dt.push(res)
        })
    }
    // dt.push(<TextInput label="Search" source="q" alwaysOn/>)
    // dt.push(<TextInput label="Brand" source="brand" defaultValue="Hello, World!"/>);
    return dt;
}
