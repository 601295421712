import CategoryIcon from '@material-ui/icons/Bookmark';

import ProductsGroupList from './ProductsGroupList';
import {ProductsGroupCreate} from "./ProductsGroupCreate";
import {ProductsGroupEdit} from "./ProductsGroupEdit";

const resource = {
    list: ProductsGroupList,
    create: ProductsGroupCreate,
    edit: ProductsGroupEdit,
    icon: CategoryIcon,
};
export default resource;

