import * as React from 'react';
import {Card, CardHeader, CardContent} from '@mui/material';//'@mui/material';
import {
    ResponsiveContainer,
    AreaChart,
    Area,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
} from 'recharts';
import {useTranslate} from 'react-admin';
// import { format, subDays, addDays } from 'date-fns';
import {
    // subDays,
    addDays,
    subMonths,
    // startOfMonth
} from 'date-fns';
import {apiPathConfig} from "../utils/config";
import {fetchJson as httpClient} from "../utils/keycloak";

import {useEffect, useState} from "react";

//const lastDay = new Date();
//const lastMonthDays = Array.from({ length: 30 }, (_, i) => subDays(lastDay, i));

//const aMonthAgo = subDays(new Date(), 30);
const aMonthAgo = subMonths(new Date(), 12);
const dateTo = new Date().toISOString();
const dateFrom = subMonths(new Date(), 12).toISOString();

const dateFormatter = (date) =>
    new Date(date).toLocaleDateString();

const OrderChart = (props) => {
    //const {orders} = props;
    //const {data, loaded} = orders;
    const translate = useTranslate();
    const [orders, setOrders] = useState([])

    const getData = async () => {
        //moment(new Date()).format();
        //const dateFrom = subMonths(startOfMonth(new Date()),0).toISOString();
        const url = apiPathConfig.getApiUrl()
        const report_url = `api/reports/orders?order__created__gte=${dateFrom}&order__created__lte=${dateTo}&order__status_id=2`;
        try {
            // const response = await fetch(`${url}${report_url}`);
            const response = await httpClient(`${url}${report_url}`);
            if (response.status >= 200 && response.status <= 300) {
                const {json} = response;
                const {results} = json;
                let newDt = []
                results.forEach(x => {
                    newDt.push({date: Date.parse(x.day), total: x.total})
                })
                setOrders(newDt)
            }
        } catch (err) {
            console.log('err=======>', err);
        } finally {
            console.log('finally=======>', 'finally');
        }
    }

    useEffect(() => {
        getData();
    }, []);// eslint-disable-line


    //console.log(Date.parse(dateTo), 'orders==========>', orders)
    if (orders.length === 0) return null;

    return (
        <Card style={{borderRadius: 12}}>
            <CardHeader title={translate('Month History')}/>
            <CardContent>
                <div style={{width: '100%', height: 300}}>
                    <ResponsiveContainer>
                        <AreaChart data={orders}>
                            <defs>
                                <linearGradient
                                    id="colorUv"
                                    x1="0"
                                    y1="0"
                                    x2="0"
                                    y2="1"
                                >
                                    <stop
                                        offset="5%"
                                        stopColor="#8884d8"
                                        stopOpacity={0.8}
                                    />
                                    <stop
                                        offset="95%"
                                        stopColor="#8884d8"
                                        stopOpacity={0}
                                    />
                                </linearGradient>
                            </defs>
                            <XAxis
                                dataKey="date"
                                name="Date"
                                type="number"
                                scale="time"
                                domain={[
                                    addDays(aMonthAgo, 1).getTime(),
                                    new Date().getTime(),
                                ]}
                                tickFormatter={dateFormatter}
                            />
                            {/*<YAxis dataKey="total" name="Revenue" unit="€"/>*/}
                            <YAxis dataKey="total" name="Revenue" unit="$"/>
                            <CartesianGrid strokeDasharray="3 3"/>
                            <Tooltip
                                cursor={{strokeDasharray: '3 3'}}
                                formatter={(value) =>
                                    new Intl.NumberFormat(undefined, {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(value)
                                }
                                labelFormatter={(label) =>
                                    dateFormatter(label)
                                }
                            />
                            <Area
                                type="monotone"
                                dataKey="total"
                                stroke="#8884d8"
                                strokeWidth={2}
                                fill="url(#colorUv)"
                            />
                        </AreaChart>
                    </ResponsiveContainer>
                </div>
            </CardContent>
        </Card>
    );
};

export default OrderChart;
