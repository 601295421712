import * as React from 'react';
import {createElement} from 'react';
// import { Card, Box, Typography, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import cartouche from './cartouche.png';
import cartoucheDark from './cartoucheDark.png';
import {Box, Card, Divider,  Typography} from "@mui/material";
//import {Link} from "react-router-dom";

/*
const styles = {
    card: {
        minHeight: 52,
        display: 'flex',
        flexDirection: 'column',
        flex: '1',
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        borderRadius: 12
    },
    link: {
        overflow: 'inherit',
        padding: '16px',
        background:
            `url(${cartouche
            }) no-repeat`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& .icon': {
            color: '#dc2440',
        },
        '& a': {
            textDecoration: 'none',
            color: 'inherit',
        },
        '& a:hover': {
            textDecoration: 'none',
            // color: 'inherit',
            color: 'green',
            cursor: 'pointer',
        },
    },
};
*/

const CardWithIcon = (props) => {
    const {icon, title, subtitle, to, children} = props
    return (
        <Card
            //style={styles.card}
            sx={{
                minHeight: 52,
                display: 'flex',
                flexDirection: 'column',
                flex: '1',
                '& a': {
                    textDecoration: 'none',
                    color: 'inherit',
                },
                borderRadius: '12px',
            }}
        >
            <Link to={to}>
                <Box
                    //style={styles.link}
                    sx={{
                        overflow: 'inherit',
                        padding: '16px',
                        background: theme =>
                            `url(${
                                theme.palette.mode === 'dark'
                                    ? cartoucheDark
                                    : cartouche
                            }) no-repeat`,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '& .icon': {
                            color: '#dc2440',
                        },
                    }}
                >
                    <Box width="3em" className="icon" style={{color: "#dc2440"}}>
                        {createElement(icon, {fontSize: 'large'})}
                    </Box>
                    <Box textAlign="right">
                        <Typography color="textSecondary">{title}</Typography>
                        <Typography variant="h5" component="h2" color={'textPrimary'}>
                            {subtitle || ' '}
                        </Typography>
                    </Box>
                </Box>
            </Link>
            {children && <Divider/>}
            {children}
        </Card>
    )
};

export default CardWithIcon;
