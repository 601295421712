import DefaultTable from '../DefaultTable/DefaultClass'
import {JustText} from "../tablePattern";

class PriceRows extends DefaultTable {
    constructor(props) {
        super(props)

        this.filters_top = [
            {
                name: 'product',
                type: "ReferenceInput",
                alwaysOn: true,
                parameters: {reference: 'Products', optionText: 'name', locale: true},
            },
            {
                name: 'source',
                type: 'text',
                alwaysOn: true,
                parameters: {},
            },
        ]


        this.filters_aside = []

        this.filters_aside = [...this.default_filters_aside, ...this.filters_aside]


        this.fields_data = [
            {
                tab: "COMMON",
                dataTab: [
                    {
                        box: [
                            {
                                name: 'product',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Products', optionText: 'name', locale: true},
                                read_only: false,
                                flex: 3,
                            },
                            JustText('storage',2),
                            JustText('sort_row'),
                        ]
                    },
                    {
                        box: [
                            JustText('name_supplier'),
                        ]
                    },
                    {
                        box: [
                            JustText('description'),
                        ]
                    },
                    {
                        box: [
                            JustText('crosscode'),
                            JustText('article'),
                            JustText('article_clean'),
                        ]
                    },
                    {
                        box: [
                            JustText('brand'),
                            JustText('brand_clean'),
                            JustText('brand_base'),
                        ]
                    },
                    {
                        box: [
                            JustText('sku_price'),
                            JustText('sku_products'),
                        ]
                    },
                    {
                        box: [
                            JustText('quantity_str'),
                            JustText('quantity_str_repr'),
                        ]
                    },

                    {
                        box: [
                            JustText('discount_type'),
                            {
                                name: 'base_price',
                                type: 'float',
                                show_type: 'NumberField',
                                edit_type: 'NumberInputFloatComp',
                                read_only: false,
                                flex: 1,
                            },
                            {
                                name: 'currency',
                                type: 'text',
                                show_type: 'ReferenceField',
                                edit_type: 'ReferenceInput',
                                type_ext: {reference: 'Currencies', optionText: 'name', locale: true},
                                read_only: false,
                            },
                        ]
                    },
                    {
                        box: [
                            JustText('quantity'),
                            JustText('quantity_str_repr'),
                            JustText('quantity_int'),
                        ]
                    },
                    {
                        box: [
                            JustText('minimal_order_quantity'),
                            JustText('multiplicity'),
                            JustText('expected_int'),
                            JustText('expected_date'),
                        ]
                    },
                    {
                        box: [
                            JustText('in_acceptance_int'),
                            JustText('weight'),
                            JustText('row_hash'),
                        ]
                    },
                    {
                        box: [
                            JustText('delivery_str'),
                            JustText('delivery_int_1'),
                            JustText('delivery_int_2'),
                        ]
                    },
                    {
                        box: [
                            JustText('unit_of_measurement_str'),
                            JustText('warehouse_price'),
                            JustText('prices_by_price_types_json'),
                            JustText('price_article'),
                        ]
                    },
                    {
                        box: [
                            JustText('supplier_price_group'),
                            JustText('unit_of_measurement'),
                            JustText('characteristic'),
                        ]
                    },
                    {
                        box: [
                            JustText('uuid'),
                        ]
                    },

                ]
            },
        ]
        this.fields_data = [...this.default_fields_data, ...this.fields_data]
    }
}

export default PriceRows