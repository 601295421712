import * as React from 'react';
import {
    useNotify,
    useRedirect, useRefresh,
} from 'react-admin';

import EditComponentAdmin from "../../_common/EditComponentAdmin";
import DiscountRulesEditCreate from "./DiscountRulesEditCreate";

export const transformDiscountRule = (data) => {
    //console.log('Transform========>', data)
    if(data.rule_type !== 'order'){
        data.order_sum = 0;
        data.order_currency_id = null;
        data.for_all_products = false;
        data.default_discount = 0;
    }

    return data;
}

export const DiscountRulesEdit = props => {
    const {location} = props;
    const {search, pathname} = location;
    let customerId = '';
    if(search){
        let url = new URL('https://test/' + search);
        customerId = url.searchParams.get('customerId')
    }
    const redirect = useRedirect();
    const notify = useNotify();
    const refresh = useRefresh();

    const onSuccess = (data) => {
        notify(`Saved data`);
        redirect(pathname)
        refresh();
    };

    return (
        <EditComponentAdmin
            {...props}
            title={''}
            //onSuccess={() => props.history.goBack()}
            //onSuccess={() => customerId ? props.history.goBack() : redirect('list', props.basePath)}
            onSuccess={onSuccess}
            transform={transformDiscountRule}
            //fullWidth
            //   undoable={false} //Подтверждение удаления
            //   actions={<PostEditActions/>}
        >
            <DiscountRulesEditCreate customerId={customerId}/>
        </EditComponentAdmin>
    )
};