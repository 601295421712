//import {Fragment} from 'react';
//import {Box} from "@material-ui/core";
import * as React from 'react';
import {
    AutocompleteInput,
    ReferenceInput,
    FilterLiveSearch,
    NumberField,
    TextField,
    useLocale,
    useTranslate, ReferenceField, useRecordContext,
    // EditButton,
    //SimpleShowLayout,
    //Datagrid,
    //EmailField,
} from 'react-admin';
import {Box, CardContent} from "@material-ui/core";
import {
    useSelectedColumns,
} from "@react-admin/ra-preferences";

import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import {useMediaQuery} from '@material-ui/core';

import '../../index.css';
import DatagridComponentAdmin from "../../_common/DatagridComponentAdmin";
import useMyStyles from "../../utils/useMyStyles";
//import {exporterPassenger} from "../utils/exporterFile";
import {DateFieldComp, FilterDataAside} from "../../_common/CompReact";
import CardComp from "../../_common/Asside/CardComp";
import get from "lodash/get";
import CroppedLongField from "../../_common/CroppedLongField";
import MySimpleList from "../../_common/MySimpleList";

const Aside = (props) => {
    /*const { data, isLoading } = useListContext();*/
    /*const { data, isLoading } = useGetList('startups',
        { page: 1, perPage: 100 },
        { field: 'name', order: 'ASC' },
    );
    if (isLoading) return null;
     */
    /*const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });*/

    // const {resource} = props;
    // console.log('Aside==========>', resource)
    return (
        <CardComp id="aside_card">
            <CardContent>
                {/*<FilterLiveSearch source={'question_en___containsi'} label={'Question'}/>*/}
                <FilterLiveSearch/>
                <FilterDataAside fields={'created_at'} name={'create'}/>
                <FilterDataAside fields={'updated_at'} name={'update'}/>
                {/*<DynamicFiltersChoices
                    reference={titleMrMs}
                    fieldsSearch={'title'}
                    fieldsNameForFilter={'name'}
                />*/}
                {/*<TrueFalseFilters field={'is_paid'} label={'Is paid'}/>*/}
                {/*<TrueFalseFilters field={'is_load'} label={'Exported to Xero'}/>*/}
                {/*<TrueFalseFilters field={'miles_only'} label={'miles only'}/>*/}
            </CardContent>
        </CardComp>
    )
};

const TopFilters = (resource, locale) => {
    let dt = [];
    dt.push(<ReferenceInput source="industry_id" reference="dPromptIndustries" alwaysOn>
        <AutocompleteInput
            optionText={`code`}
            resettable emptyText={'All Codes'}/>
    </ReferenceInput>);
    dt.push(<ReferenceInput source="prompt_type_id" reference="dPromptTypes" alwaysOn>
        <AutocompleteInput optionText="code" resettable emptyText={'All Types'}/>
    </ReferenceInput>);

    return dt;
}
const Config = (props) => {
    //const {source} = props;
    const record = useRecordContext(props);
    //let processing_type = get(record, 'config.processing_type', null);
    let fields = get(record, 'config.fields', null);
    return (
        <Box display={'grid'}>
            {/*{processing_type &&
                <span>{processing_type}</span>
            }*/}
            {fields && fields.map(it => {
                return (
                    <span title={it?.info_fields} style={{display: "grid"}}>
                            <b>{it?.table}:</b> {it?.field}
                        </span>
                )
            })
            }
        </Box>
    )
}
const ColumnsForList = (resource, locale, classes, isSmall) => {

    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        // prompt: <TextFieldBold source={`prompt`} label={translate('Prompt')} cellClassName={classes.prompt_name}/>,
        // prompt: <TextField source={`prompt`} label={translate('Prompt')}/>,
        prompt: <CroppedLongField source="prompt" long={160} label={translate('Prompt')}/>,
        response_example: <CroppedLongField source="response_example" long={160} label={translate('Response Example')}/>,
        industry: <ReferenceField source="industry_id" reference="dPromptIndustries"
                                  label={translate('Industry')} link={false}
        >
            <TextField source={`code`}/>
        </ReferenceField>,
        prompt_type: <ReferenceField source="prompt_type_id" reference="dPromptTypes"
                                     label={translate('Type')} link={false}
        >
            <TextField source={`code`}/>
        </ReferenceField>,
        /*config: <ArrayField source="config.fields" label={'Config'}>
                <SingleFieldList linkType={false}>
                    <Box>
                    <ChipField source="table" size="small" />
                    {/!*<ChipField source="field" size="small" />*!/}
                    </Box>
                </SingleFieldList>
            </ArrayField>,*/
        config: <Config source="config" label={translate('Config')}/>,
        created: <DateFieldComp source="created_at" label={translate('Create')} textAlign={'center'}
                                locale={'us'} cellClassName={classes.date_st}/>,
        updated: <DateFieldComp source="updated_at" label={translate('Update')} textAlign={'center'}
                                locale={'us'} cellClassName={classes.date_st}/>,
    }
    if (isSmall) {
        delete columns.id
        delete columns.source
        delete columns.updated
    }
    return columns;
};

export const PromptsList = ({permissions, ...props}) => {

    const classes = useMyStyles();
    const {resource} = props;

    const locale = useLocale();
    const isSmall = useMediaQuery(function (theme) {
        return theme.breakpoints.down('xs');
    });

    /*const postRowStyle = (record, index) => ({
        backgroundColor: record.nb_views >= 500 ? '#efe' : 'white',
    });*/

    const postRowStyle = (record, index) => ({
        backgroundColor: record?.is_paid && '#efe',
        // display: "table-cell",
    });

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, locale, classes, isSmall),
        omit: ["nb_views"],
    });

    //console.log('==============>', props)
    return (
        <ListComponentAdmin
            {...props}
            //component={'div'}
            title={''}
            actions={<TableActions
                create={true}
                preference={`${resource}list.columns`}
                columns={ColumnsForList(resource, locale, classes, isSmall)}
                permissions={permissions}
                asideFilter={false}
                // filter={false}
                //exportCsv={true}
                //exporterFile={exporterPassenger}
            />}
            filters={TopFilters(resource, locale)}
            //filterDefaultValues={{viewed: true}}
            sort={{field: 'id', order: 'DESC'}}
            aside={<Aside/>}
            is_bulkActionButtons={true}
        >
            {isSmall
                ?
                <MySimpleList
                    columns={columns}
                    postRowStyle={postRowStyle}
                />
                :
                /*<DatagridComponentAdmin {...props} rowClick="edit" expand={<PostShow/>}>*/
                <DatagridComponentAdmin {...props} rowClick="edit" rowStyle={postRowStyle}>
                    {/*<EditButton label={''} cellClassName={classes.headerButtonEdiWidth}/>*/}
                    {columns}
                </DatagridComponentAdmin>
            }
        </ListComponentAdmin>
    )
}