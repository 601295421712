import * as React from 'react';
import {
    ReferenceField, TextField, NumberField, DateField, useTranslate,
} from 'react-admin';
// import {Box} from "@material-ui/core";
//import {Box, Typography} from "@material-ui/core";
import {BooleanFieldComp, ImageFieldComp, TextFieldBold} from "../../_common/CompReact";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {EditCommentFieldFromList} from "../../_common/EditCommentFieldFromList";
//import {localesConfig} from "../utils/config";

export const BuildListComponent = (field, locale) => {
    const translate = useTranslate();
    const {name, show_type, type_ext, type, label=name} = field;
    let lab = translate(label);

    const {reference, optionText, locale: loc, choices = ['Not found'], width = '6em', widthImg=48} = type_ext || {};
    // const defLoc = localesConfig.defaultLocale;
    if (show_type) {
        switch (show_type) {

            case 'EditFieldFromList':
                return (<EditCommentFieldFromList source={name} label={lab} component={type} long={width}/>)
            case 'ReferenceField':
                return (
                    <ReferenceField source={name} reference={reference} label={lab}>
                        <TextField source={loc ? `${optionText}[${locale}]` : `${optionText}`}/>
                    </ReferenceField>
                )
            case 'TextField':
                return (<TextField source={loc ? `${name}[${locale}]` : `${name}`} label={lab}/>)
            case 'TextFieldBold':
                return (<TextFieldBold source={loc ? `${name}[${locale}]` : `${name}`} label={lab}/>)
            case 'NumberField':
                return (<NumberField source={name} label={lab}/>)
            case 'BooleanField':
                return (<BooleanFieldComp source={name} label={lab}/>)
            case 'DateField':
                return (<DateField source={name} label={lab}/>)
            case 'SelectAndSaveChoices':
                return (
                    <SelectAndSaveChoices
                        label={lab}
                        source={name}
                        statuses={choices}
                        resource={reference}
                        textAlign={'center'}
                        showTextValue={true}
                        width={width}
                    />
                )
            case 'ImageField':
                return (
                    <ImageFieldComp
                        sortable={false}
                        // sortBy=""
                        // sortByOrder="DESC"
                        source={name}
                        label={lab}
                        width={widthImg}
                        textAlign={'center'}
                        //headerClassName={classes.headerImageWidth}
                    />
                )
            default:
                return (<TextField source={name} label={lab}/>)
        }
    }
};
