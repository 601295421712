import * as React from 'react';
import {
    Box, Typography,
} from "@material-ui/core";

import {
    useTranslate
} from "react-admin";
//import {GetArrayFieldFromResource, GetFieldFromResource} from "./Partner";
import {GetFieldFromResource} from "./Partner";

export const PreviewPartner = props => {
    const {
        form,
    } = props

    const translate = useTranslate();

    let partner = form.partner.values.id ? form.partner.values.id : form.partner.values

    return (
        <Box p="1em" fullWidth>
            {/*{form.partner.values.id &&
                    <Grid item xs={12} p={1}>
                        <GetArrayFieldFromResource
                            resource={'zCustomers'}
                            field={'name'}
                            id={partner.customers}
                        />
                    </Grid>
                }*/}

            <Box display="flex" alignItems={"start"} mt={-2}>
                <Box flex={1} display="grid" alignItems={"start"} mr={1}>
                    <Typography variant="body" color="textSecondary">
                        {translate("Name")}: <b>{partner.name}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Email")}: <b>{partner.email}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Phone")}: <b>{partner.phone}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Accounting email")}: <b>{partner.accounting_email}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Accounting phone")}: <b>{partner.accounting_phone}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Warehouse phone")}: <b>{partner.warehouse_phone}</b>
                    </Typography>
                </Box>
                <Box flex={1} display="grid" alignItems={"start"} style={{flexWrap: 'wrap'}}>
                    <Typography variant="body" color="textSecondary">
                        {translate("Manager")}:{' '}
                        <b>
                            {form.partner.values.id
                                ?
                                <GetFieldFromResource
                                    resource={'dManager'}
                                    field={'name'}
                                    id={partner.manager}
                                />
                                : <span>{partner.manager?.name}</span>
                            }

                        </b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Sales representative SNG")}:{' '}
                        <b>
                            {form.partner.values.id
                                ?
                                <GetFieldFromResource
                                    resource={'dManager'}
                                    field={'name'}
                                    id={partner.sales_representative_1}
                                />
                                : <span>{partner.sales_representative_1?.name}</span>
                            }
                        </b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Sales representative Ino")}:{' '}
                        <b>
                            {form.partner.values.id
                                ?
                                <GetFieldFromResource
                                    resource={'dManager'}
                                    field={'name'}
                                    id={partner.sales_representative_2}
                                />
                                : <span>{partner.sales_representative_2?.name}</span>
                            }
                        </b>
                    </Typography>
                </Box>
            </Box>


            {/*<Grid container>
                <Grid item xs={12} md={6} p={1} style={{display: "grid"}}>
                    <Typography variant="body" color="textSecondary">
                        {translate("Name")}: <b>{partner.name}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Email")}: <b>{partner.email}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Phone")}: <b>{partner.phone}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Accounting email")}: <b>{partner.accounting_email}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Accounting phone")}: <b>{partner.accounting_phone}</b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Warehouse phone")}: <b>{partner.warehouse_phone}</b>
                    </Typography>
                </Grid>

                <Grid item xs={12} md={6} p={1} style={{display: "grid"}}>
                    <Typography variant="body" color="textSecondary">
                        {translate("Manager")}:{' '}
                        <b>
                            {form.partner.values.id
                                ?
                                <GetFieldFromResource
                                    resource={'dManager'}
                                    field={'name'}
                                    id={partner.manager}
                                />
                                :
                                <Typography variant="body" color="textSecondary">
                                    {partner.manager?.name}
                                </Typography>
                            }
                        </b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Sales representative SNG")}:{' '}
                        <b>
                            {form.partner.values.id
                                ?
                                <GetFieldFromResource
                                    resource={'dManager'}
                                    field={'name'}
                                    id={partner.sales_representative_1}
                                />
                                :
                                <Typography variant="body" color="textSecondary">
                                    {partner.sales_representative_1?.name}
                                </Typography>
                            }
                        </b>
                    </Typography>
                    <Typography variant="body" color="textSecondary">
                        {translate("Sales representative Ino")}:{' '}
                        <b>
                            {form.partner.values.id
                                ?
                                <GetFieldFromResource
                                    resource={'dManager'}
                                    field={'name'}
                                    id={partner.sales_representative_2}
                                />
                                :
                                <Typography variant="body" color="textSecondary">
                                    {partner.sales_representative_2?.name}
                                </Typography>
                            }
                        </b>
                    </Typography>
                </Grid>
            </Grid>*/}
        </Box>
    )
}
