import * as React from 'react';
import {CardContent} from '@material-ui/core';
import {FilterList, FilterListItem, FilterLiveSearch} from 'react-admin';

// import DynamicFilters from "../_common/DynamicFilters";
import CardComp from "../../_common/Asside/CardComp";
import {FilterDataAside} from "../../_common/CompReact";

//import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import DynamicFiltersChoices from "../../_common/DynamicFiltersChoices";
import {tableConfig} from "../../tablesConfig/tableConfig";


const buildAsideComponent = (data, locale) => {
    const {name, type, parameters} = data;
    if (type) {
        switch (type) {
            case 'FilterDataAside':
                return (
                    <FilterDataAside fields={name} name={name}/>
                )
            case 'FilterList':
                return (
                    <FilterList label={name} icon={<CheckCircleOutlineIcon/>}>
                        {parameters.map((param, index) => {
                            let tmpObject = {}
                            tmpObject[name] = param
                            return (
                                <FilterListItem label={param} key={index} value={tmpObject}/>
                            )
                        })
                        }
                    </FilterList>
                )
            case 'TrueFalseFilters':
                return (<TrueFalseFilters field={name} label={name}/>);
            case 'DynamicFiltersChoices':
                return (
                    <DynamicFiltersChoices
                    reference={parameters.referenceChoice}
                    fieldsSearch={name}
                    fieldsNameForFilter={parameters.name}
                />
                )

            default:
                return null;
        }
    }
}

const UniversalAside = (props) => {

    const {resource, locale} = props;
    /*tableConfig.tablesList.map((table, index)=>{

        if(table.table==props.resource) {
            tmpTable = table
        }
    })*/
    const params = tableConfig.tablesList.find(x => x.table === resource)
    if (!params) return ({id: <h5>Table not found</h5>});
    const {data} = params;
    const {filters_aside} = data;

    // console.log('filters_aside========>', filters_aside)
    /*
        <DynamicFilters
        reference={props.resource}
        fieldsSearch={'status'}
        fieldsNameForFilter={'name'}
        />
        */
    return (
        <CardComp id="aside_card">
            <CardContent>
                <FilterLiveSearch/>
                {filters_aside.map((data, index) => {
                        return buildAsideComponent(data, locale);
                    })
                }
            </CardContent>
        </CardComp>
    )
};

export default UniversalAside;
