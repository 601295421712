import * as React from 'react';
import {
    FilterLiveSearch,
    NumberField, TextField,
    useLocale,
    Datagrid,
    useTranslate,
    // ReferenceInput,
    // AutocompleteInput,
    EmailField, BooleanInput, TextInput, FormDataConsumer, PasswordInput,
} from 'react-admin';
// import {RowForm} from "@react-admin/ra-editable-datagrid";
import {useForm} from "react-final-form";
import ListComponentAdmin from "../../_common/ListComponentAdmin";
import TableActions from "../../_common/TableActions";
import useMyStyles from "../../utils/useMyStyles";
import {CardContent} from "@material-ui/core";
import CardComp from "../../_common/Asside/CardComp";
import {
    DateFieldComp,
    FilterDataAside,
    //NumberInputFloatComp,
    UserInfo,
} from "../../_common/CompReact";
// import {Box} from "@mui/material";
import TrueFalseFilters from "../../_common/TrueFalseFilters";
import {commonTrueFalse} from "../../_constants/choices";
import SelectAndSaveChoices from "../../_common/SelectAndSaveChoices";
import {useSelectedColumns} from "@react-admin/ra-preferences";
import Dialog from "@material-ui/core/Dialog";
import RowFormCustom from "../../_common/DependenciesComp/RowFormCustom";
import {Box} from "@mui/material";
import MyEditableDatagridForReference from "../../_common/MyEditableDatagridForReference";
import {validateEmailReq, validateReq} from "../../_constants/validateTable";
import {canAccess2} from "../../utils/canAccess2";
// import {useEffect} from "react";
// import SaveIcon from '@mui/icons-material/Save';
// import PasswordIcon from '@mui/icons-material/Password';
import LockResetIcon from '@mui/icons-material/LockReset';
import {useState} from "react";

const universalTopFilters = (resource, locale) => {
    let dt = [];
    return dt;
}

const UniversalAside = (props) => {
    //const {locale} = props;
    return (
        <CardComp id="aside_card">
            <CardContent>
                <FilterLiveSearch/>
                <TrueFalseFilters field={'is_active'} label={'Is Active'}/>
                <TrueFalseFilters field={'is_superuser'} label={'Is Superuser'}/>
                <TrueFalseFilters field={'is_staff'} label={'Is Staff'}/>
                <FilterDataAside fields={'created_at'} name={'create'}/>
                <FilterDataAside fields={'updated_at'} name={'update'}/>
            </CardContent>
        </CardComp>
    )
};

const transform = (data) => {
    data.username = data.email
    data.additional = data.additional2
    if (data.id) {//Редактировать

    } else {//Создать
        data.additional.user_create = data.additional.password_save
        delete data.additional.password_save
    }
    delete data.password
    delete data.additional2

    return data
}

const ListEditForm = (props) => {//https://overpass-turbo.eu/index.html
    //const classes = useMyStyles();
    const {record, is_save} = props;
    const translate = useTranslate();

    const handleSuccess = (response) => {
        console.log('=response===2========>')
        //refresh();
        //window.location.reload();
    };

    const validateUserCreation = (values) => {
        //console.log('values=========>', values)
        const errors = {};
        if (values.additional2.password_save) {
            if (!values.password || values.password.length < 6) {
                errors.password = translate('The password is required (length > 5)');
            }
            if (!values.additional2.password || values.additional2.password.length < 6) {
                errors.additional2 = {}
                errors.additional2.password = translate('The password is required (length > 5)');
            }

            if (Object.keys(errors).length > 0) return errors

            if (values.additional2.password !== values.password) {
                errors.password = translate('Password confirmation error')
            }
        }
        return errors
    };

    return (
        <Dialog
            //fullWidth={true}
            scroll={'body'}
            classes={{
                paper: 'modal_with_product_list',
            }}
            maxWidth={'md'}
            // onClose={handleDialogCloseCart}
            open={true}
        >
            <RowFormCustom
                {...props}
                is_save={is_save}
                validate={validateUserCreation}
                style={{width: '100%'}}
                hasBulkActions={false}
                initialValues={{
                    username: '',
                    is_active: true,
                    is_superuser: false,
                    is_staff: false,
                    password: '',
                    additional2: {
                        password_save: record?.id ? false : true,
                        password: ''
                    }
                }}
                mutationOptions={{onSuccess: handleSuccess}}
                //onSuccess={handleSuccess}
                transform={transform}
            >
                <FormDataConsumer>
                    {({formData, ...rest}) => {
                        return (
                            <ListEditForm2
                                {...props}
                                formData={formData}
                                record={record}
                            />
                        )
                    }}
                </FormDataConsumer>

            </RowFormCustom>
        </Dialog>
    )
}
const ListEditForm2 = (props) => {
    const {formData, record} = props;
    const {id} = record;
    const formEdit = useForm();
    const translate = useTranslate();

    const [initiallyVisible, setInitiallyVisible] = useState(true);

    const handleSetPassword = () => {
        // console.log('FORM===!!!====>', formData)
        const length = 12;
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+~`|}{[]:;?><,./-=';
        let newPassword = '';
        for (let i = 0; i < length; i++) {
            const randomIndex = Math.floor(Math.random() * charset.length);
            newPassword += charset[randomIndex];
        }

        formEdit.change('additional2.password', newPassword)
        formEdit.change('password',  newPassword)
        navigator.clipboard.writeText(newPassword)
        setInitiallyVisible(true)
    }

    return (
        <Box p="1em" fullWidth>
            {id &&
                <Box display="flex" alignItems={"top"}>
                    <Box flex={1} mr={'1em'}>
                        <BooleanInput
                            // style={{width: 250}}
                            source={'is_active'}
                            label={translate('Is Active')}
                            defaultValue={false}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1} mr={'1em'}>
                        <BooleanInput
                            // style={{width: 250}}
                            source={'is_superuser'}
                            label={translate('Is Superuser')}
                            defaultValue={false}
                            fullWidth
                        />
                    </Box>
                    <Box flex={1}>
                        <BooleanInput
                            // style={{width: 250}}
                            source={'is_staff'}
                            label={translate('Is Staff')}
                            defaultValue={false}
                            fullWidth
                        />
                    </Box>
                </Box>
            }
            <Box display="flex" alignItems={"top"} style={{width: 750}}>
                {/*<Box flex={1} mr={'1em'}>
                            <TextInput
                                multiline={true}
                                // onChange={(event) => handleChange(event)}
                                source={`username`}
                                label={translate('Username')}
                                variant={"outlined"}
                                // validate={validateReq}
                                fullWidth
                            />
                        </Box>*/}
                <Box flex={1}>
                    <TextInput
                        multiline={true}
                        // onChange={(event) => handleChange(event)}
                        source={`email`}
                        label={translate('Email')}
                        variant={"outlined"}
                        validate={validateEmailReq}
                        fullWidth
                    />
                </Box>
            </Box>

            <Box display="flex" alignItems={"top"}>
                <Box flex={1} mr={'1em'}>
                    <TextInput
                        multiline={true}
                        // onChange={(event) => handleChange(event)}
                        source={`first_name`}
                        label={translate('First Name')}
                        variant={"outlined"}
                        // validate={validateReq}
                        fullWidth
                    />
                </Box>
                <Box flex={1}>
                    <TextInput
                        multiline={true}
                        // onChange={(event) => handleChange(event)}
                        source={`last_name`}
                        label={translate('Last Name')}
                        variant={"outlined"}
                        // validate={validateReq}
                        fullWidth
                    />
                </Box>
            </Box>

            {id && formData?.additional2?.password_save !== undefined &&
                <Box display="flex" alignItems={"top"}>
                    <Box flex={1} mr={'1em'}>
                        <BooleanInput
                            style={{width: 200}}
                            source={'additional2.password_save'}
                            label={translate('Change password')}
                            fullWidth
                        />
                    </Box>
                </Box>
            }

            {formData?.additional2?.password_save &&
                <Box display="flex" alignItems={"top"}>
                    <Box flex={1} mr={'1em'} display="flex">
                        <Box flex={10} mr={'1em'}>
                            <PasswordInput
                                initiallyVisible={initiallyVisible}
                                autoComplete={'new-password'}
                                // onChange={(event) => handleChange(event)}
                                source={`additional2.password`}
                                label={translate('Password')}
                                variant={"outlined"}
                                validate={validateReq}
                                fullWidth
                            />
                        </Box>
                        <Box flex={1}>
                            <LockResetIcon
                                style={{
                                    cursor: "pointer",
                                    fontSize: 32,
                                    marginTop: 12,
                                    color: 'blue'
                            }}
                                onClick={handleSetPassword}
                            />
                        </Box>
                    </Box>
                    <Box flex={1}>
                        <PasswordInput
                            initiallyVisible={initiallyVisible}
                            autoComplete={'new-password'}
                            // onChange={(event) => handleChange(event)}
                            source={`password`}
                            label={translate('Confirm password')}
                            variant={"outlined"}
                            validate={validateReq}
                            fullWidth
                        />
                    </Box>
                </Box>
            }

        </Box>
    )

}
/*export const tableFilters = [
    <TextInput label="Search" source="q" alwaysOn/>,
    /!*<BooleanInput label="Only viewed" source="viewed" alwaysOff/>,*!/
];*/
const ColumnsForList = (resource, classes, canEdit) => {
    const translate = useTranslate();
    let columns = {
        id: <NumberField source="id" headerClassName={classes.headerShortWidth}/>,
        name: <UserInfo label={translate('User')}/>,
        username: <TextField source={`username`} label={translate('Username')}/>,
        email: <EmailField source={`email`} label={translate('Email')}/>,
        is_active:
            <SelectAndSaveChoices
                label={translate("Is Active")}
                source="is_active"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={canEdit}
                send_only_this_field={false}
                color={'green'}
                circle={'no inv'}
            />,
        is_superuser:
            <SelectAndSaveChoices
                label={translate("Is Superuser")}
                source="is_superuser"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={canEdit}
                send_only_this_field={false}
                color={'green'}
                circle={'no inv'}
            />,
        is_staff:
            <SelectAndSaveChoices
                label={translate("Is Staff")}
                source="is_staff"
                statuses={commonTrueFalse}
                resource={resource}
                textAlign={'center'}
                showTextValue={true}
                width={'2em'}
                edit={canEdit}
                send_only_this_field={false}
                color={'green'}
                circle={'no inv'}
            />,
        last_login:
            <DateFieldComp source="last_login" label={translate('Last Login')} textAlign={'center'}
                           locale={'us'} cellClassName={classes.date_st}/>,
        date_joined:
            <DateFieldComp source="date_joined" label={translate('Date Joined')} textAlign={'center'}
                           locale={'us'} cellClassName={classes.date_st}/>,

    }
    return columns
}
export const UserList = props => {
    const classes = useMyStyles();
    const {resource} = props;

    // console.log('resource=============>', resource)
    // const {id} = record || {};
    const locale = useLocale();

    const canEdit = canAccess2({resource: `${resource}`, action: 'edit'});
    const canCreate = canAccess2({resource: `${resource}`, action: 'create'});
    const canDelete = canAccess2({resource: `${resource}`, action: 'delete'});

    const columns = useSelectedColumns({
        preferences: `${resource}list.columns`,
        columns: ColumnsForList(resource, classes, canEdit),
        omit: ["nb_views"],
    });
    return (
        <ListComponentAdmin
            {...props}
            //hasCreate={true}
            title={''}
            actions={<TableActions
                preference={`${resource}${".list.columns"}`}
                columns={ColumnsForList(resource, locale, classes)}
                select={true}
                create={true}
                filter={true}
            />}
            //is_bulkActionButtons={false}
            filters={universalTopFilters(resource, locale)}
            sort={{field: 'id', order: 'DESC'}}
            aside={<UniversalAside locale={locale}/>}
        >
            {/*<DatagridComponentAdmin>*/}
            {(canEdit || canCreate)
                ?
                <MyEditableDatagridForReference
                    hasBulkActionsRowForm={false}
                    rowClick={canEdit ? "edit" : false}
                    classes={{headerCell: classes.headerCell, row: classes.row, rowCell: classes.rowCell}}
                    noDelete={!canDelete}
                    mutationMode="pessimistic" //Подтвердить удаление
                    undoable
                    createForm={<ListEditForm submitOnEnter={false} is_save={canCreate}/>}
                    editForm={<ListEditForm submitOnEnter={false} is_save={canEdit}/>}
                    //??? isRowEditable={() => false} // Disable editing for all rows
                    //rowStyle={postRowStyle}
                >
                    {columns}
                </MyEditableDatagridForReference>
                :
                <Datagrid>
                    {columns}
                </Datagrid>
            }
            {/*</DatagridComponentAdmin>*/}
        </ListComponentAdmin>
    )
};